import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as SuccessIcon } from "../../assets/signup/confirmation.svg";
import { ReactComponent as FailureIcon } from "../../assets/signup/failure.svg"; // Update path as necessary
import { colors } from "../../utils/colors";
import { windowHeight, windowWidth } from "../../utils/utils";

const PaymentFailureScreen = () => {
    const navigate = useNavigate();

    const handleConfirmation = () => {
        navigate("/checkout");
    };


    return (
        <Grid
            container
            sx={{
                height: "90vh",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.bg_color,
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    borderRadius: "20px",
                    width: "60%",
                    padding: "40px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                }}
            >
                {/* Conditional Icon Based on Payment Status */}

                <FailureIcon
                    style={{
                        width: windowHeight / 2.5,
                        height: windowHeight / 2.5,
                        marginRight: windowWidth / 40,
                        marginBottom: "20px",
                    }}
                />


                {/* Message Based on Payment Status */}
                <Typography variant="h4" sx={{ mb: 2 }}>
                    {"Paiement Échoué"}
                </Typography>

                <Typography variant="body1" sx={{ mb: 4 }}>
                    {"Il y a eu un problème avec votre paiement. Veuillez réessayer."}
                </Typography>

                {/* Confirmation Button */}
                <CustomButton
                    text="Retour au paiement"
                    outlined={false}
                    onClick={handleConfirmation}
                    sx={{ width: "50%", padding: "10px 0" }}
                />
            </Paper>
        </Grid>
    );
};

export default PaymentFailureScreen;