import { combineReducers } from "redux";
import authReducer from "./authReducer";
import conversationReducer from "./conversationReducer";
import searchReducer from "./searchReducer";
import navbarReducer from "./navbarReducer";
import userReducer from "./userReducer";
import documentReducer from "./documentReducer";
import { RESET_STORE } from "../actions/types"; // Import the RESET_STORE action
import messageReducer from "./messageReducer";
import contractReducer from "./contractReducer";
import signatureReducer from "./signatureReducer";

// Combine all reducers
const appReducer = combineReducers({
  auth: authReducer,
  conversations: conversationReducer,
  search: searchReducer,
  navbar: navbarReducer,
  user: userReducer,
  document: documentReducer,
  messages: messageReducer,
  contract: contractReducer,
  signature: signatureReducer,

});

// Root reducer that resets state when RESET_STORE is dispatched
const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    // Reset the entire state to undefined, triggering the initial state for all reducers
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;