import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Paper,
    Grid,
    Checkbox,
    TextField,
    InputAdornment,
    CardContent
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CustomButton from '../../../components/CustomButton';
import { colors } from '../../../utils/colors';
import AddressFields from './AddressFields';
import UserTypeSelector from './UserTypeSelector';

const BillingForm = ({
    userType,
    formValues,
    acceptTerms,
    isLoading,
    countries,
    planInfo,
    handleUserTypeChange,
    handleInputChange,
    setFormValues,
    setAcceptTerms,
    handleProcessPayment,
    navigate,
    isMobileView
}) => {
    // Get user from Redux state
    const reduxUser = useSelector(state => state.auth.user?.user);

    // Pre-fill form with user data from Redux if available
    useEffect(() => {
        if (reduxUser) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                firstName: reduxUser.first_name || reduxUser.firstName || '',
                lastName: reduxUser.last_name || reduxUser.lastName || '',
                email: reduxUser.email || '',
                phone: reduxUser.phone || ''
            }));
        }
    }, [reduxUser, setFormValues]);

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)'
            }}
        >
            <Box
                sx={{
                    backgroundColor: colors.primary,
                    px: isMobileView ? 1 : 2,
                    pt: 2,
                    color: 'black'
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <AccountBalanceWalletIcon />
                    Informations de facturation
                </Typography>
            </Box>

            <CardContent sx={{ px: isMobileView ? 1 : 3 }}>
                {/* User Type Selector (physique or moral) - Disabled for logged-in users */}
                <UserTypeSelector
                    userType={userType} // Either 'physique' or 'moral'
                    handleUserTypeChange={handleUserTypeChange}
                    disabled={!!reduxUser} // Disable selection if user exists in Redux
                />

                <form>
                    <Grid container spacing={2}>
                        {userType === 'physique' ? (
                            <PhysicalPersonFields
                                formValues={formValues}
                                handleInputChange={handleInputChange}
                                reduxUser={reduxUser}
                            />
                        ) : (
                            <BusinessFields
                                formValues={formValues}
                                handleInputChange={handleInputChange}
                                reduxUser={reduxUser}
                            />
                        )}

                        {/* Common Address Fields */}
                        <AddressFields
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                            countries={countries}
                        />
                    </Grid>

                    <TermsAndConditions
                        acceptTerms={acceptTerms}
                        setAcceptTerms={setAcceptTerms}
                    />

                    <ActionButtons
                        isLoading={isLoading}
                        planInfo={planInfo}
                        handleProcessPayment={handleProcessPayment}
                        navigate={navigate}
                    />
                </form>
            </CardContent>
        </Paper>
    );
};

const PhysicalPersonFields = ({ formValues, handleInputChange, reduxUser }) => (
    <>
        {/* Display user info fields if Redux user doesn't exist, otherwise use hidden fields */}
        {!reduxUser ? (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Nom"
                        name="lastName"
                        value={formValues.lastName || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Prénom"
                        name="firstName"
                        value={formValues.firstName || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Numéro de Téléphone"
                        name="phone"
                        value={formValues.phone || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Email"
                        name="email"
                        value={formValues.email || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
            </>
        ) : (
            // Display the user info in read-only fields when Redux user exists
            <>
                <Grid item xs={12}>
                    <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                            Informations utilisateur
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <PersonIcon color="action" fontSize="small" />
                                    <Typography>{formValues.lastName} {formValues.firstName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <EmailIcon color="action" fontSize="small" />
                                    <Typography>{formValues.email}</Typography>
                                </Box>
                            </Grid>
                            {formValues.phone && (
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <PhoneIcon color="action" fontSize="small" />
                                        <Typography>{formValues.phone}</Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </>
        )}
        <Grid item xs={6}>
            {reduxUser ? (
                // Read-only ICE display for logged-in users
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        ICE
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BadgeIcon color="action" fontSize="small" />
                        <Typography>{formValues.ice || "Non spécifié"}</Typography>
                    </Box>
                </Box>
            ) : (
                // Editable ICE field for non-logged-in users
                <TextField
                    variant="outlined"
                    fullWidth
                    label="ICE (facultatif)"
                    name="ice"
                    value={formValues.ice || ''}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BadgeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            )}
        </Grid>
        <Grid item xs={6}>
            {reduxUser ? (
                // Read-only TVA display for logged-in users
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Numéro de TVA
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ReceiptIcon color="action" fontSize="small" />
                        <Typography>{formValues.tvaNumber || "Non spécifié"}</Typography>
                    </Box>
                </Box>
            ) : (
                // Editable TVA field for non-logged-in users
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Numéro de TVA (facultatif)"
                    name="tvaNumber"
                    value={formValues.tvaNumber || ''}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ReceiptIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            )}
        </Grid>
    </>
);

const BusinessFields = ({ formValues, handleInputChange, reduxUser }) => (
    <>
        <Grid item xs={12}>
            {reduxUser ? (
                // Read-only display for logged-in users
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Dénomination
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon color="action" fontSize="small" />
                        <Typography>{formValues.companyName || "Non spécifié"}</Typography>
                    </Box>
                </Box>
            ) : (
                // Editable field for non-logged-in users
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Dénomination"
                    name="companyName"
                    value={formValues.companyName || ''}
                    required
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BusinessIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            )}
        </Grid>

        {/* Display user info fields if Redux user doesn't exist, otherwise use hidden fields */}
        {!reduxUser ? (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Nom"
                        name="lastName"
                        value={formValues.lastName || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Prénom"
                        name="firstName"
                        value={formValues.firstName || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Email"
                        name="email"
                        value={formValues.email || ''}
                        required
                        onChange={handleInputChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon color="action" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                                '&.Mui-focused fieldset': {
                                    borderColor: colors.primary,
                                },
                            }
                        }}
                    />
                </Grid>
            </>
        ) : (
            // Display the user info in read-only fields when Redux user exists
            <Grid item xs={12}>
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Informations utilisateur
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PersonIcon color="action" fontSize="small" />
                                <Typography>{formValues.lastName} {formValues.firstName}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <EmailIcon color="action" fontSize="small" />
                                <Typography>{formValues.email}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        )}

        <Grid item xs={6}>
            {reduxUser ? (
                // Read-only TVA display for logged-in users
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Numéro de TVA
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ReceiptIcon color="action" fontSize="small" />
                        <Typography>{formValues.tvaNumber || "Non spécifié"}</Typography>
                    </Box>
                </Box>
            ) : (
                // Editable TVA field for non-logged-in users
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Numéro de TVA (facultatif)"
                    name="tvaNumber"
                    value={formValues.tvaNumber || ''}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ReceiptIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            )}
        </Grid>
        <Grid item xs={6}>
            {reduxUser ? (
                // Read-only ICE display for logged-in users
                <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(14, 165, 233, 0.08)', borderRadius: '10px' }}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        ICE
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BadgeIcon color="action" fontSize="small" />
                        <Typography>{formValues.ice || "Non spécifié"}</Typography>
                    </Box>
                </Box>
            ) : (
                // Editable ICE field for non-logged-in users
                <TextField
                    variant="outlined"
                    fullWidth
                    label="ICE"
                    name="ice"
                    value={formValues.ice || ''}
                    required
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BadgeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            )}
        </Grid>
    </>
);

const TermsAndConditions = ({ acceptTerms, setAcceptTerms }) => (
    <Box
        sx={{
            mt: 2,
            p: 2,
            borderRadius: '10px',
            backgroundColor: 'rgba(0,0,0,0.02)'
        }}
    >
        <Grid item xs={12} sx={{}}>
            <Box display="flex" alignItems="center">
                <Checkbox
                    checked={acceptTerms}
                    onChange={e => setAcceptTerms(e.target.checked)}
                    required
                    sx={{
                        color: colors.primary,
                        '&.Mui-checked': {
                            color: colors.primary,
                        },
                    }}
                />
                {/* Optional star icon */}
                <Typography sx={{ color: 'red', marginRight: '5px' }}>*</Typography>
                <Typography variant="body2">
                    J'accepte les{" "}
                    <Typography
                        component="a"
                        variant="body2"
                        href="https://juridia.ma/condition-generales-de-vente/"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            color: colors.primary,
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        conditions générales de vente
                    </Typography>
                </Typography>
            </Box>
        </Grid>
    </Box>
);

const ActionButtons = ({ isLoading, planInfo, handleProcessPayment, navigate }) => (
    <Box display="flex" justifyContent="space-between" mt={3}>
        <CustomButton
            text="Retour aux forfaits"
            outlined={true}
            color="primary"
            onClick={() => {
                window.location.href = "https://juridia.ma/#pricing";
            }}
            sx={{
                width: "48%",
                borderRadius: '10px',
                py: 1.5,
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.05)',
                borderColor: colors.primary,
                '&:hover': {
                    borderColor: colors.primary,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)'
                }
            }}
        />

        <CustomButton
            text={planInfo.planType === 'enterprise' ? "Contacter les ventes" : "Procéder au paiement"}
            type="submit"
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={handleProcessPayment}
            sx={{
                width: "48%",
                borderRadius: '10px',
                py: 1.5,
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold'
            }}
        />
    </Box>
);

export default BillingForm;