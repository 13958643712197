import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    IconButton,
    TextField,
    InputAdornment,
    Tooltip,
    Container,
    CircularProgress,
    alpha
} from '@mui/material';
import { Add, Refresh, Search, PeopleAlt } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '../../../../contexts/SubscriptionProvider';

// Import sub-components
import EmptyState from './EmptyState';
import UsersTable from './UsersTable';
import InviteUserDialog from './dialogs/InviteUserDialog';
import DeleteUserDialog from './dialogs/DeleteUserDialog';
import EditRolesDialog from './dialogs/EditRolesDialog';
import ContactDialog from './dialogs/ContactDialog';
import CustomAlert from './CustomAlert';
import UserLimitInfo from './UserLimitInfo';
import CustomButton from '../../../../components/CustomButton';
import { backendClient } from '../../../../api/backend';


const UserManagement = () => {
    const { t } = useTranslation();
    const { canAddMoreUsers, maxUsers } = useSubscription();
    const [users, setUsers] = useState([]);
    const [openContactDialog, setOpenContactDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openRolesDialog, setOpenRolesDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedRoles, setSelectedRoles] = useState(['user']);
    const currentUser = useSelector((state) => state.auth?.user?.user);
    const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
    const [validationError, setValidationError] = useState('');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [invitingUser, setInvitingUser] = useState(false);
    const user = useSelector(state => state.auth?.user?.user);

    // Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Get domain name for invitations
    const domainName = user?.email ? user.email.split('@')[1] : '';

    // Colors from settings
    const colors = {
        primary: "#0EA5E9",
        primaryLight: "#EAECFF",
        primaryDark: "#3730A3",
        secondary: "#10B981",
        secondaryLight: "#D1FAE5",
        background: "#F9FAFB",
        backgroundAlt: "#F3F4F6",
        surface: "#FFFFFF",
        error: "#EF4444",
        warning: "#F59E0B",
        info: "#3B82F6",
        text: "#1F2937",
        textSecondary: "#6B7280",
        border: "#E5E7EB",
        borderDark: "#D1D5DB",
        disabled: "#9CA3AF",
    };

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await backendClient.get('api/user/organization-users');
                const data = await response.json();
                setUsers(data);
                setFilteredUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
                setAlertInfo({
                    open: true,
                    message: t('error_fetching_users', 'Error fetching users'),
                    severity: 'error'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [refreshTrigger, t]);

    // Filter users when search query changes
    useEffect(() => {
        if (!searchQuery.trim()) {
            setFilteredUsers(users);
            return;
        }

        const query = searchQuery.toLowerCase();
        const filtered = users.filter(user =>
            user.email.toLowerCase().includes(query) ||
            (user.first_name && user.first_name.toLowerCase().includes(query)) ||
            (user.last_name && user.last_name.toLowerCase().includes(query))
        );

        setFilteredUsers(filtered);
        setPage(0); // Reset to first page when filtering
    }, [searchQuery, users]);

    const handleRefresh = () => {
        setRefreshTrigger(prev => prev + 1);
        setSearchQuery('');
    };

    const handleInviteOpen = () => {
        if (!canAddMoreUsers(filteredUsers.length)) {
            // Show upgrade notification instead of allowing invite
            setAlertInfo({
                open: true,
                message: t('max_users_reached', `You've reached the maximum of ${maxUsers} users for your subscription plan. Please upgrade to add more users.`),
                severity: 'warning'
            });
            return;
        }

        setInviteEmail('');
        setSelectedRoles(['user']);
        setValidationError('');
        setOpenInviteDialog(true);
    };

    const handleInviteClose = () => {
        setOpenInviteDialog(false);
    };

    const handleDeleteOpen = (user) => {
        setSelectedUser(user);
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
        setSelectedUser(null);
    };

    const handleRolesOpen = (user) => {
        setSelectedUser(user);
        setSelectedRoles([...user.roles]);
        setOpenRolesDialog(true);
    };

    const handleRolesClose = () => {
        setOpenRolesDialog(false);
        setSelectedUser(null);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleInviteUser = async () => {
        // Validate email
        if (!inviteEmail || !validateEmail(inviteEmail)) {
            setValidationError(t('invalid_email', 'Please enter a valid email address'));
            return;
        }

        // Validate roles
        if (!selectedRoles.length) {
            setValidationError(t('select_at_least_one_role', 'Please select at least one role'));
            return;
        }

        setInvitingUser(true); // Set loading to true before sending the request

        try {
            await backendClient.post('api/user/invite', {
                email: inviteEmail,
                roles: selectedRoles,
            });

            setAlertInfo({
                open: true,
                message: t('invitation_sent_successfully', 'Invitation sent successfully'),
                severity: 'success'
            });
            handleInviteClose();
            handleRefresh();
        } catch (error) {
            console.error('Error inviting user:', error);
            setAlertInfo({
                open: true,
                message: error.message || t('error_inviting_user', 'Error inviting user'),
                severity: 'error'
            });
        } finally {
            setInvitingUser(false); // Set loading back to false after request completes
        }
    };

    const handleDeleteUser = async () => {
        try {
            await backendClient.delete(`api/user/users/${selectedUser.id}`);
            setAlertInfo({
                open: true,
                message: t('user_deleted_successfully', 'User deleted successfully'),
                severity: 'success'
            });
            handleDeleteClose();
            handleRefresh();
        } catch (error) {
            console.error('Error deleting user:', error);
            setAlertInfo({
                open: true,
                message: error.message || t('error_deleting_user', 'Error deleting user'),
                severity: 'error'
            });
        }
    };

    const handleUpdateRoles = async () => {
        try {
            await backendClient.post('api/user/update-roles', {
                user_id: selectedUser.id,
                roles: selectedRoles
            });
            setAlertInfo({
                open: true,
                message: t('roles_updated_successfully', 'User roles updated successfully'),
                severity: 'success'
            });
            handleRolesClose();
            handleRefresh();
        } catch (error) {
            console.error('Error updating roles:', error);
            setAlertInfo({
                open: true,
                message: error.message || t('error_updating_roles', 'Error updating user roles'),
                severity: 'error'
            });
        }
    };

    const handleRoleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedRoles(typeof value === 'string' ? value.split(',') : value);
    };

    const handleCloseAlert = () => {
        setAlertInfo({ ...alertInfo, open: false });
    };

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Check if current user is admin
    const isAdmin = currentUser && currentUser.roles && currentUser.roles.includes('admin');

    if (!isAdmin) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    {t('access_denied', 'Access Denied')}
                </Typography>
                <Typography variant="body1">
                    {t('admin_only_access', 'Only administrators can access user management')}
                </Typography>
            </Box>
        );
    }

    // Available roles for dropdown
    const availableRoles = ['user', 'admin', 'billing_admin'];

    // Gets role display name based on role identifier
    const getRoleDisplayName = (role) => {
        switch (role) {
            case 'admin':
                return t('role_admin', 'Admin');
            case 'billing_admin':
                return t('role_billing_admin', 'Billing Admin');
            case 'user':
                return t('role_user', 'User');
            default:
                return role;
        }
    };


    return (
        <Box sx={{ py: 3 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 3,
                mt: -0.5,
                zIndex: 10,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            mr: 2,
                            p: 1.5,
                            borderRadius: '12px',
                            bgcolor: colors.primaryLight,
                            color: colors.primary,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <PeopleAlt />
                    </Box>
                    <Typography
                        variant="h5"
                        gutterBottom={false}
                        sx={{
                            fontWeight: 600,
                            fontSize: { xs: '1.25rem', sm: '1.5rem' },
                            color: colors.text,
                            m: 0,
                        }}
                    >
                        {t('user_management', 'User Management')}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={t('refresh', 'Refresh')}>
                        <IconButton
                            onClick={handleRefresh}
                            sx={{
                                mr: 1,
                                backgroundColor: alpha(colors.border, 0.5),
                                '&:hover': {
                                    backgroundColor: colors.border
                                }
                            }}
                        >
                            <Refresh fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <TextField
                        placeholder={t('search_users', 'Search users...')}
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                            mr: 2,
                            width: { xs: '120px', sm: '250px' },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                transition: 'all 0.2s',
                                '&:hover': {
                                    boxShadow: '0 0 0 2px rgba(0,0,0,0.05)'
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <CustomButton
                        text={t('invite_user', 'Invite User')}
                        variant="contained"
                        startIcon={<Add />}
                        onClick={handleInviteOpen}
                        sx={{
                            p: 1,
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            transition: 'all 0.2s',
                            '&:hover': {
                                backgroundColor: colors.primary,
                                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                transform: 'translateY(-1px)'
                            }
                        }}
                    />
                </Box>
            </Box>

            {/* User Limit Information */}
            <UserLimitInfo
                maxUsers={maxUsers}
                currentUsers={filteredUsers.length}
                colors={colors}
                onContactClick={() => setOpenContactDialog(true)}
            />

            {/* User List or Loading State */}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                    <CircularProgress sx={{ color: colors.primary }} />
                </Box>
            ) : filteredUsers.length === 0 ? (
                <EmptyState
                    title={searchQuery ? t('no_search_results', 'No search results') : t('no_users', 'No users found')}
                    description={searchQuery
                        ? t('no_users_matching_search', 'No users matching your search criteria')
                        : t('no_users_in_organization', 'There are no users in your organization yet')}
                    icon="people"
                    colors={colors}
                />
            ) : (
                <UsersTable
                    filteredUsers={filteredUsers}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    currentUser={currentUser}
                    onEditRoles={handleRolesOpen}
                    onDeleteUser={handleDeleteOpen}
                    getRoleDisplayName={getRoleDisplayName}

                    colors={colors}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    t={t}
                />
            )}

            {/* Dialogs */}
            <ContactDialog
                open={openContactDialog}
                onClose={() => setOpenContactDialog(false)}
                colors={colors}
                t={t}
            />

            <InviteUserDialog
                open={openInviteDialog}
                setSelectedRoles={setSelectedRoles}
                setValidationError={setValidationError}
                onClose={handleInviteClose}
                inviteEmail={inviteEmail}
                setInviteEmail={setInviteEmail}
                selectedRoles={selectedRoles}
                validationError={validationError}
                onInviteUser={handleInviteUser}
                onRoleChange={handleRoleChange}
                invitingUser={invitingUser}
                availableRoles={availableRoles}
                getRoleDisplayName={getRoleDisplayName}
                colors={colors}
                t={t}
            />

            <DeleteUserDialog
                open={openDeleteDialog}
                onClose={handleDeleteClose}
                onDeleteUser={handleDeleteUser}
                selectedUser={selectedUser}
                colors={colors}
                t={t}
            />

            <EditRolesDialog
                open={openRolesDialog}
                onClose={handleRolesClose}
                selectedUser={selectedUser}
                selectedRoles={selectedRoles}
                onRoleChange={handleRoleChange}
                onUpdateRoles={handleUpdateRoles}
                availableRoles={availableRoles}
                getRoleDisplayName={getRoleDisplayName}
                colors={colors}
                t={t}
            />

            {/* Alert Notification */}
            <CustomAlert
                open={alertInfo.open}
                message={alertInfo.message}
                severity={alertInfo.severity}
                onClose={handleCloseAlert}
            />
        </Box>
    );
};

export default UserManagement;