import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    CircularProgress,
    useTheme,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    alpha
} from '@mui/material';
import {
    Search as SearchIcon,
    PersonAdd as PersonAddIcon
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersByOrganization } from '../../../redux/actions/userAction';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import CustomButton from '../../../components/CustomButton';

const ShareContractModal = ({
    open,
    onClose,
    onShare,
    organizationName,
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const dispatch = useDispatch();
    const actual_user = useSelector((state) => state.auth?.user?.user);
    const { users, loading: reduxLoading } = useSelector((state) => state.user);
    
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userRoles, setUserRoles] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [sharing, setSharing] = useState(false);

    // Available roles for sharing
    const availableRoles = ['viewer', 'editor'];

    useEffect(() => {
        if (open && actual_user?.organization_id) {
            dispatch(fetchUsersByOrganization(actual_user.organization_id));
        }
    }, [open, actual_user, dispatch]);

    // Filter users based on search term
    const filteredUsers = users.filter(
        (user) =>
            user.id !== actual_user?.id && // Exclude the actual user
            (user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleToggle = (user) => {

        setSelectedUsers(prevSelected => {
            const isSelected = prevSelected.some(u => u.id === user.id);
            
            // If user is already selected, remove them
            if (isSelected) {
                // Also remove their role
                const newUserRoles = {...userRoles};
                delete newUserRoles[user.id];
                setUserRoles(newUserRoles);
                
                return prevSelected.filter(u => u.id !== user.id);
            } else {
                // Add user and set default role to 'viewer'
                if (!userRoles[user.id]) {
                    setUserRoles({
                        ...userRoles,
                        [user.id]: 'editor'
                    });
                }
                return [...prevSelected, user];
            }
        });
    };

    const handleRoleChange = (userId, role) => {
        setUserRoles({
            ...userRoles,
            [userId]: role
        });
    };

    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);

        // Google Analytics event for search
        ReactGA.event({
            category: "Share Modal",
            action: "Search User",
            label: searchValue,
        });
    };

    const handleShare = async () => {
        if (selectedUsers.length === 0) return;
    
        setSharing(true);
        try {
            // Previously: 
            // const selectedUsersIds = selectedUsers.map((user) => user.id);
            // await onShare(selectedUsersIds);
            
            // NEW: Map users to include both user_id and role
            const usersWithRoles = selectedUsers.map(user => ({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                role: userRoles[user.id] || 'viewer'
            }));
            
            await onShare(usersWithRoles);
    
            // Google Analytics event for sharing conversation
            ReactGA.event({
                category: "Share Modal",
                action: "Conversation Shared",
                label: `Shared with ${selectedUsers.length} user(s)`,
                value: selectedUsers.length,
            });
    
            setSelectedUsers([]);
            setUserRoles({});
            onClose();
        } catch (error) {
            console.error('Error sharing conversation:', error);
        } finally {
            setSharing(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={!sharing ? onClose : undefined}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {t("share_contract")}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ pb: 2 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3 }}
                >
                    {t("shareContractModal.description", "Select users from your organization to share this conversation with.")}
                </Typography>

                {/* Search bar */}
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t("search_user_placeholder")}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                        sx: {
                            borderRadius: 1.5,
                            bgcolor: alpha(theme.palette.common.black, 0.02),
                            py: 0.5
                        }
                    }}
                    sx={{ mb: 2 }}
                />

                {/* User list */}
                {reduxLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List sx={{
                        maxHeight: 300,
                        overflowY: 'auto',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        borderRadius: 1.5,
                        mb: 2
                    }}>
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => {
                                const isSelected = selectedUsers.some((u) => u.id === user.id);

                                return (
                                    <ListItem
                                        key={user.id}
                                        sx={{
                                            flexDirection: currentLang === "ar" ? "row-reverse" : "row",
                                            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                                            '&:last-child': {
                                                borderBottom: 'none'
                                            },
                                            transition: 'background-color 0.2s',
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.05),
                                            },
                                            bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.05) : 'transparent',
                                            padding: '8px 16px',
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            width: '100%',
                                            cursor: 'pointer',
                                            mb: isSelected ? 1 : 0
                                        }} onClick={() => handleToggle(user)}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main
                                                    }}
                                                >
                                                    {user.first_name ? user.first_name[0] : ""}
                                                    {user.last_name ? user.last_name[0] : ""}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${user.first_name} ${user.last_name}`}
                                                secondary={user.email}
                                                sx={{
                                                    textAlign: currentLang === "ar" ? "right" : "left",
                                                }}
                                            />
                                            <Checkbox
                                                edge={currentLang === "ar" ? "start" : "end"}
                                                sx={{
                                                    marginLeft: currentLang === "ar" ? "auto" : 0,
                                                    marginRight: currentLang !== "ar" ? "auto" : 0,
                                                    color: theme.palette.primary.main
                                                }}
                                                checked={isSelected}
                                                onClick={(e) => {
                                                    // Stop propagation to prevent double-toggling
                                                    e.stopPropagation();
                                                    handleToggle(user);
                                                }}
                                            />
                                        </Box>
                                        
                                        {/* Role selection - only show if user is selected */}
                                        {isSelected && (
                                            <Box sx={{ 
                                                width: '100%', 
                                                pl: 7, 
                                                pr: 2,
                                                pb: 1
                                            }}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel>{t("shareContractModal.role", "Role")}</InputLabel>
                                                    <Select
                                                        value={userRoles[user.id] || 'viewer'}
                                                        label={t("shareContractModal.role", "Role")}
                                                        onChange={(e) => handleRoleChange(user.id, e.target.value)}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        {availableRoles.map((role) => (
                                                            <MenuItem key={role} value={role}>
                                                                {t(`shareContractModal.role${role.charAt(0).toUpperCase() + role.slice(1)}`, role)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </ListItem>
                                );
                            })
                        ) : (
                            <Box sx={{
                                py: 4,
                                display: 'flex',
                                justifyContent: 'center',
                                color: theme.palette.text.secondary
                            }}>
                                <Typography variant="body2">
                                    {t("shareContractModal.noUsersFound", "No users found")}
                                </Typography>
                            </Box>
                        )}
                    </List>
                )}

                {/* Selected users count */}
                {selectedUsers.length > 0 && (
                    <Box sx={{
                        mt: 2,
                        p: 2,
                        bgcolor: alpha(theme.palette.primary.main, 0.05),
                        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <PersonAddIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                        <Typography variant="body2">
                            {t("shareContractModal.selectedUsersCount", { count: selectedUsers.length }, `${selectedUsers.length} user(s) selected for sharing`)}
                        </Typography>
                    </Box>
                )}
            </DialogContent>

            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    onClick={onClose}
                    color="inherit"
                    disabled={sharing}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        color: theme.palette.text.secondary
                    }}
                >
                    {t("cancel")}
                </Button>

                <CustomButton
                    text={sharing ? t("shareContractModal.sharing", "Sharing...") : t("share")}
                    disabled={selectedUsers.length === 0 || sharing}
                    outlined={false}
                    loading={sharing}
                    onClick={handleShare}
                    sx={{ ml: 1 }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ShareContractModal;