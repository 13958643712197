// PaymentScreen.js
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { colors } from '../../utils/colors';

const PaymentScreen = () => {
    const location = useLocation();
    const { htmlContent } = location.state || {};
    const containerRef = useRef(null);

    useEffect(() => {
        if (!htmlContent) return;

        // Create a temporary div to parse the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // Extract form data
        const form = tempDiv.querySelector('form');
        if (!form) return;

        const formAction = form.getAttribute('action');
        const payloadInput = form.querySelector('input[name="payload"]');
        const signatureInput = form.querySelector('input[name="signature"]');

        if (!formAction || !payloadInput || !signatureInput) return;

        // Create a new form programmatically
        const newForm = document.createElement('form');
        newForm.setAttribute('method', 'POST');
        newForm.setAttribute('action', formAction);
        newForm.setAttribute('target', '_self'); // Change from _parent to _self

        const payloadField = document.createElement('input');
        payloadField.setAttribute('type', 'hidden');
        payloadField.setAttribute('name', 'payload');
        payloadField.setAttribute('value', payloadInput.value);
        newForm.appendChild(payloadField);

        const signatureField = document.createElement('input');
        signatureField.setAttribute('type', 'hidden');
        signatureField.setAttribute('name', 'signature');
        signatureField.setAttribute('value', signatureInput.value);
        newForm.appendChild(signatureField);

        // Clear any existing content in the container
        if (containerRef.current) {
            containerRef.current.innerHTML = '';
            containerRef.current.appendChild(newForm);

            // Submit the form with a slight delay to allow rendering
            setTimeout(() => {
                try {
                    newForm.submit();
                    // Hide loading after form submission
                    const loadingElement = document.getElementById('payment-loading');
                    if (loadingElement) {
                        loadingElement.style.display = 'none';
                    }
                } catch (error) {
                    console.error('Form submission error:', error);
                }
            }, 500);
        }
    }, [htmlContent]);

    if (!htmlContent) {
        return (
            <Container component="main" maxWidth="md" sx={{
                backgroundColor: colors.bg_color,
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography variant="h5" color="error">
                    Erreur de chargement du formulaire de paiement
                </Typography>
                <Typography variant="body1" mt={2}>
                    Veuillez revenir en arrière et réessayer.
                </Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md" sx={{
            backgroundColor: colors.bg_color,
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box id="payment-loading" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 4
            }}>
                <CircularProgress color="primary" />
                <Typography variant="h6" mt={2}>
                    Redirection vers la passerelle de paiement...
                </Typography>
            </Box>

            {/* The payment form will be inserted here */}
            <div id="payment-container" ref={containerRef}></div>
        </Container>
    );
};

export default PaymentScreen;