import React, { useState } from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CreateContractDialog from '../dialogs/CreateContractDialog';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton';

const DashboardHeader = ({ title }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleContractCreated = (contractId) => {
        // Navigate to the contract will be handled within the dialog
        // This callback can be used for additional actions if needed
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2.2,
                    pb: 3,
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    sx={{
                        fontWeight: 700,
                        color: theme.palette.text.primary,
                        // letterSpacing: '-0.5px'
                    }}
                >
                    {title}
                </Typography>

                <CustomButton
                    text={t('new_contract')}
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleOpenDialog}
                    sx={{
                        borderRadius: 2,
                        px: 3,
                        py: 1,
                        fontWeight: 600,
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                    }}
                />
            </Box>

            {/* Contract Creation Dialog */}
            <CreateContractDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onSuccess={handleContractCreated}
            />
        </>
    );
};

export default DashboardHeader;