import {
  SET_LOADING_DOCUMENT,
  SET_DOCUMENT,
  SET_IS_MODAL_MOBILE_VISIBLE,
  CLEAR_DOCUMENT,
  SET_SELECTED_CITATION,
  SET_TRANSLATION_IN_PROGRESS,
  SET_TRANSLATION_PROGRESS,
  SET_DOCUMENT_SOURCE_URL
} from '../actions/types'

const initialState = {
  isLoadingDocument: false,
  document: null, // {id (citation document_id), url (from fetchDocumentUrl)}, language
  isModalMobileVisible: false,
  selectedCitation: null, // {page_content, metadata}
  documentSourceUrl: null,
  translationProgress: 0,
  isTranslationInProgress: false
}

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_DOCUMENT:
      return { ...state, isLoadingDocument: action.payload }

    case SET_DOCUMENT:
      return { ...state, document: action.payload }

    case SET_IS_MODAL_MOBILE_VISIBLE:
      return { ...state, isModalMobileVisible: action.payload }

    case SET_SELECTED_CITATION:
      return { ...state, selectedCitation: action.payload }

    case SET_TRANSLATION_PROGRESS:
      return { ...state, translationProgress: action.payload }

    case SET_TRANSLATION_IN_PROGRESS:
      return { ...state, isTranslationInProgress: action.payload }

    case SET_DOCUMENT_SOURCE_URL:
      return { ...state, documentSourceUrl: action.payload }

    case CLEAR_DOCUMENT:
      return initialState

    default:
      return state
  }
}

export default documentReducer
