import React from 'react'
import {
  ListItem,
  Box,
  Typography,
  Chip,
  Tooltip,
  Paper
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSearchDocument,
  setSearchDocumentSourceUrl,
  setSearchIsModalMobileVisible,
  setSearchLoadingDocument,
  setSearchSelectedCitation
} from '../../../redux/actions/searchAction'
import { backendClient } from '../../../api/backend'
import { useSnackbar } from 'notistack'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { usePdfFocus } from '../../../components/pdf-viewer/pdfContext'
import { getCitationSectionTitle, getDocTypeColor, isMobile, windowWidth } from '../../../utils/utils'
import ReactGA from 'react-ga4'
import { colors } from '../../../utils/colors'

const SearchCitation = ({ citation }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { setPdfFocusState } = usePdfFocus()

  const selectedCitation = useSelector(
    state => state.search.searchSelectedCitation
  )

  const isSelected = selectedCitation?.metadata?.source_content === citation?.metadata?.source_content

  const formatDate = dateString => {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}(|T\d{2}:\d{2}:\d{2})$/

    if (isoDateRegex.test(dateString)) {
      const date = new Date(dateString)
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
    }

    const dateParts = dateString.split('/')
    if (dateParts.length === 3) {
      const [day, month, year] = dateParts
      return new Date(`${year}-${month}-${day}`).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
    }

    return '-'
  }

  const dispatch = useDispatch()
  const searchDocument = useSelector(state => state.search.searchDocument)

  const handleCitationClick = async doc => {
    const selectedCitation_ = {
      metadata: doc?.metadata,
      page_content: doc?.text
    }

    dispatch(setSearchSelectedCitation(selectedCitation_))

    if (doc?.metadata?.document_id !== searchDocument?.id) {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME
      const blobName = doc?.metadata?.file_name
      try {
        dispatch(setSearchLoadingDocument(true))
        const data = await backendClient.getDocumentSas(containerName, blobName)
        const response = await fetch(data.blob_url_with_sas)
        const url = response.url
        dispatch(
          setSearchDocument({
            id: doc?.metadata?.document_id,
            url: url,
            language: doc?.metadata?.language
          })
        )
        dispatch(setSearchDocumentSourceUrl(url))
      } catch (error) {
        console.error('Error fetching or processing the document:', error)
        dispatch(setSearchLoadingDocument(false))
        enqueueSnackbar(t('document_fetch_error'), {
          variant: 'error'
        })
      }
    }

    // set setPdfFocusState : citation, document_id, page_number
    setPdfFocusState(prevState => ({
      ...prevState,
      citation: selectedCitation_,
      document_id: selectedCitation_?.metadata?.document_id,
      page_number: selectedCitation_?.metadata?.page_number
    }))

    if (isMobile()) {
      dispatch(setSearchIsModalMobileVisible(true))
    }

    dispatch(setSearchLoadingDocument(false))

    ReactGA.event({
      category: 'Citation',
      action: 'User clicked on a citation',
      label: `Document ID: ${doc.metadata.document_id}, Section Title: ${doc.metadata.section_title}`
    })
  }

  // Truncate text to prevent extremely long entries
  const truncateText = (text, maxLength = 100) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <ListItem
      component={Paper}
      elevation={isSelected ? 2 : 0}
      onClick={() => handleCitationClick(citation)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#ebf8ff' : 'transparent',
        borderLeft: isSelected ? '4px solid #3182ce' : '4px solid transparent',
        borderRadius: 0,
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: isSelected ? '#ebf8ff' : '#f7fafc'
        }
      }}
    >
      {/* Header with title and document type */}
      <Box 
        sx={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1
        }}
      >
        <Tooltip title={citation?.metadata?.long_title} arrow>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 600,
              fontSize: '0.95rem',
              color: '#2d3748',
              maxWidth: '80%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {citation?.metadata?.long_title}
          </Typography>
        </Tooltip>

        <Chip
          label={t(citation.metadata.doc_type)}
          size="small"
          sx={{
            height: 24,
            fontSize: '0.7rem',
            fontWeight: 600,
            color: getDocTypeColor(citation.metadata.doc_type),
            backgroundColor: `${getDocTypeColor(citation.metadata.doc_type)}15`,
            border: `1px solid ${getDocTypeColor(citation.metadata.doc_type)}40`
          }}
        />
      </Box>

      {/* Content section */}
      {citation?.metadata?.section_title?.length > 0 && (
        <Box sx={{ width: '100%', mb: 1.5 }}>
          <Typography
            variant='body2'
            sx={{
              color: '#4a5568',
              fontSize: '0.85rem',
              lineHeight: 1.5,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontStyle: citation?.metadata?.section_title ? 'normal' : 'italic'
            }}
          >
            {citation?.metadata?.section_title && 
              <span style={{ fontWeight: 500, color: '#2d3748' }}>
                {getCitationSectionTitle(citation.metadata.section_title)}
                {': '}
              </span>
            }
            {truncateText(citation.text, 150)}
          </Typography>
        </Box>
      )}

      {/* Footer with metadata */}
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          width: '100%',
          mt: 0.5
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
          <CalendarTodayOutlinedIcon sx={{ color: '#a0aec0', fontSize: '0.85rem', mr: 0.5 }} />
          <Typography
            variant='caption'
            sx={{ color: '#718096', fontSize: '0.75rem', fontWeight: 500 }}
          >
            {formatDate(citation?.metadata?.creation_date)}
          </Typography>
        </Box>

        {citation?.metadata?.page_number && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DescriptionOutlinedIcon sx={{ color: '#a0aec0', fontSize: '0.85rem', mr: 0.5 }} />
            <Typography
              variant='caption'
              sx={{ color: '#718096', fontSize: '0.75rem', fontWeight: 500 }}
            >
              {t('page')} {citation.metadata.page_number}
            </Typography>
          </Box>
        )}
      </Box>
    </ListItem>
  )
}

export default SearchCitation