import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Box, Typography, Paper, Button } from "@mui/material";
import { useSnackbar } from 'notistack';
import { loginWithToken } from "../../redux/actions/authAction";
import { domainConfig, isMobile } from "../../utils/utils";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { colors } from "../../utils/colors";

const ConfirmAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { loading, error, user } = useSelector(state => state.auth);
  const [redirecting, setRedirecting] = useState(false);
  const isMobileView = isMobile();
  const [errorDetails, setErrorDetails] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (token) {
      // Dispatch the login action with the token
      dispatch(loginWithToken(token, navigate))
        .then(() => {
          // If login is successful, show redirecting message for 2 seconds before navigation
          setRedirecting(true);
          // Navigation is handled in the action
        })
        .catch((err) => {
          // Handle specific errors
          setErrorDetails(err);
          enqueueSnackbar(err, {
            variant: 'error',
            autoHideDuration: 5000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          });
        });
    } else {
      const errorMsg = "Token manquant. Veuillez utiliser le lien complet envoyé par email.";
      setErrorDetails(errorMsg);
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      });
    }
  }, [dispatch, token, navigate]);

  // Handle retry
  const handleRetry = () => {
    if (token) {
      setErrorDetails(null);
      dispatch(loginWithToken(token, navigate));
    }
  };

  // Handle back to login
  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: colors.bg_color,
        overflow: 'hidden'
      }}
    >
      <Paper
        elevation={8}
        sx={{
          borderRadius: isMobileView ? '16px' : '24px',
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
          width: '90%',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Box
          sx={{
            width: '100px',
            height: '100px',
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            mb: 3
          }}
        >
          <Logo style={{ width: '80px', height: '80px' }} />
        </Box>

        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
          Confirmation de votre compte
        </Typography>

        {redirecting && (
          <Typography
            variant="body1"
            color="success.main"
            sx={{ mb: 3, textAlign: 'center', fontWeight: 'medium' }}
          >
            Confirmation réussie ! Redirection vers votre espace personnalisé...
          </Typography>
        )}

        {/* Loading indicator or action buttons based on state */}
        {loading ? (
          <>
            <CircularProgress sx={{ color: colors.primary }} />
            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center', color: '#666666' }}>
              Veuillez patienter pendant que nous confirmons votre compte...
            </Typography>
          </>
        ) : (error || errorDetails) && !redirecting ? (
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: colors.primary,
                '&:hover': { backgroundColor: colors.primary_dark }
              }}
              onClick={handleRetry}
            >
              Réessayer
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                color: colors.primary,
                borderColor: colors.primary,
                '&:hover': { borderColor: colors.primary_dark }
              }}
              onClick={handleBackToLogin}
            >
              Retour à la connexion
            </Button>
          </Box>
        ) : redirecting ? (
          <CircularProgress sx={{ color: colors.primary, mt: 2 }} />
        ) : null}
      </Paper>
    </Box>
  );
};

export default ConfirmAccount;