import React from 'react';
import {
    Typography,
    Box,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DiscountIcon from '@mui/icons-material/Discount';
import { colors } from '../../../utils/colors';
import PromoCodeInput from './PromoCodeInput';

const MobilePlanSummary = ({ planInfo, priceInfo, handlePromoCodeValidate }) => {
    const { priceHT, taxAmount, priceTTC, discount, originalPrice } = priceInfo;
    const hasDiscount = discount && discount > 0 && originalPrice > 0;

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)'
            }}
        >
            <Box
                sx={{
                    p: 2,
                    backgroundColor: colors.primary,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    Récapitulatif de votre forfait
                </Typography>
                <img
                    src="https://juridia.ma/wp-content/uploads/2025/03/logo_full.png"
                    alt="Logo"
                    style={{
                        width: 100,
                        height: 'auto'
                    }}
                />
            </Box>

            <Box p={2} pb={0}>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="#333"
                >
                    {planInfo.price === 'Personnalisée'
                        ? 'Prix personnalisé'
                        : `${priceTTC.toFixed(2)} MAD`
                    }
                </Typography>

                {/* Show original price if discount was applied */}
                {(hasDiscount + discount > 0) && (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontSize: '0.9rem',
                            textDecoration: 'line-through',
                            color: '#999'
                        }}
                    >
                        Prix initial: {(priceTTC + discount).toFixed(2)} MAD
                    </Typography>
                )}

                {planInfo.subscriptionType === "annual" &&
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontSize: '0.9rem',
                            mt: 0.5,
                            color: '#555'
                        }}
                    >
                        Pour 12 mois (soit {(priceTTC / 12).toFixed(2)} MAD/mois)
                    </Typography>
                }

                <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    sx={{ mt: 0.5 }}
                >
                    {planInfo.subscriptionType === "annual" ? "Abonnement Annuel" : "Abonnement Mensuel"}
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        display: 'inline-block',
                        backgroundColor: colors.primary,
                        color: 'white',
                        padding: '4px 12px',
                        borderRadius: '12px',
                        fontSize: '0.8rem',
                        mt: 1
                    }}
                >
                    Forfait {planInfo.planType === 'individual' ? 'Individuel' :
                        planInfo.planType === 'professional' ? 'Professionnel' : 'Entreprise'}
                </Typography>
            </Box>

            {planInfo.price !== 'Personnalisée' && (
                <Box>
                    <PromoCodeInput
                        onApply={handlePromoCodeValidate}
                        isMobileView={true}
                    />
                </Box>
            )}


            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="features-content"
                    id="features-header"
                    sx={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                >
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            fontWeight: 'bold'
                        }}
                    >
                        <FeaturedPlayListIcon color="primary" />
                        Fonctionnalités incluses
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="ul" sx={{ pl: 2, m: 0 }}>
                        {planInfo.features && planInfo.features.map((feature, index) => (
                            <Box
                                component="li"
                                key={index}
                                sx={{
                                    padding: '4px 0',
                                    color: '#555'
                                }}
                            >
                                {feature}
                            </Box>
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>

            {planInfo.price !== 'Personnalisée' && (
                <Box p={2}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 1 }}
                    >
                        <Typography color="#555" variant="body2">Total HT</Typography>
                        <Typography fontWeight="500" variant="body2">{priceHT.toFixed(2)} MAD</Typography>
                    </Grid>

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 1 }}
                    >
                        <Typography color="#555" variant="body2">TVA (20%)</Typography>
                        <Typography fontWeight="500" variant="body2">{taxAmount.toFixed(2)} MAD</Typography>
                    </Grid>

                    {/* Display discount if applied */}
                    {(hasDiscount + discount > 0) && (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mb: 1 }}
                        >
                            <Typography sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'green',
                                fontWeight: 500,
                                fontSize: '0.9rem'
                            }}>
                                <DiscountIcon sx={{ mr: 1, fontSize: '1rem' }} />
                                Réduction
                            </Typography>
                            <Typography fontWeight="500" color="green" variant="body2">
                                -{discount.toFixed(2)} MAD
                            </Typography>
                        </Grid>
                    )}

                    <Divider sx={{ my: 1 }} />

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0.03)',
                            borderRadius: '8px',
                            p: 1.5
                        }}
                    >
                        <Typography variant="subtitle1" fontWeight="bold">Total TTC</Typography>
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            color={colors.primary}
                        >
                            {priceTTC.toFixed(2)} MAD
                        </Typography>
                    </Grid>
                </Box>
            )}

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="security-content"
                    id="security-header"
                    sx={{ backgroundColor: 'rgba(0,0,0,0.02)' }}
                >
                    <Typography fontWeight="bold">
                        Sécurité et protection
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        paragraph
                        sx={{ fontSize: '0.75rem', lineHeight: 1.5 }}
                    >
                        Par le biais de cette page de paiement, Vantage Payment Systems collecte des données personnelles afin de traiter vos transactions. Ce traitement a été déclaré à la CNDP sous le numéro D-CE-294/2013.
                    </Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: '0.75rem', lineHeight: 1.5 }}
                    >
                        Grâce au protocole de sécurité "Secure Socket Layer" (SSL), vos données sont entièrement cryptées et totalement protégées. Notre certificat SSL a été délivré par la société RapidSSL.
                    </Typography>
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        flexWrap="wrap"
                        sx={{ gap: 1 }}
                    >
                        <img src="https://juridia.ma/wp-content/uploads/2025/04/verified_by_visa.jpeg" alt="Verified by Visa" style={{ width: 40, height: 'auto' }} />
                        <img src="https://juridia.ma/wp-content/uploads/2025/04/RapidSSL.png" alt="RapidSSL" style={{ width: 40, height: 'auto' }} />
                        <img src="https://juridia.ma/wp-content/uploads/2025/04/mastercard_secure.png" alt="MasterCard" style={{ width: 40, height: 'auto' }} />
                        <img src="https://juridia.ma/wp-content/uploads/2025/04/payzone.png" alt="PayZone" style={{ width: 40, height: 'auto' }} />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};

export default MobilePlanSummary;