import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Typography, Box, Card, CardContent } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/logo.svg'
// import cdgLogo from '../../assets/subdomains/cdg_prevoyance.png'
// import mtlLogo from '../../assets/subdomains/mtl.jpeg'
import CustomButton from '../../components/CustomButton'
import { useDispatch } from 'react-redux'
import { createConversation } from '../../redux/actions/conversationAction'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { domainConfig, windowHeight, windowWidth } from '../../utils/utils'
import { setDocument, setSelectedCitation } from '../../redux/actions/documentAction'

const WelcomeScreen = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.auth?.user?.user)

  const dispatch = useDispatch()

  const { loadingCreate } = useSelector(state => state.conversations)

  const domain = user?.email?.split('@')[1]
  const config = domainConfig[domain]

  const handleCreateConversation = async () => {
    try {
      const newConversation = await dispatch(createConversation(user.id)) // Dispatch create conversation
      if (newConversation) {
        dispatch(setDocument(null))
        dispatch(setSelectedCitation(null))
        enqueueSnackbar('Conversation créée.', {
          variant: 'success'
        })
        navigate(`/conversation/${newConversation.id}`) // Navigate to the new conversation

        // Google Analytics event for creating conversation
        ReactGA.event({
          category: 'Conversation',
          action: 'User created a new conversation',
          label: `User ID: ${user.id}`
        })
      }
    } catch (error) {
      enqueueSnackbar('Failed to create conversation', { variant: 'error' })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
        // minHeight: '100vh'
      }}
    >
      <Container maxWidth='sm'>
        <Card
          sx={{
            width: '100%',
            textAlign: 'center',
            p: 3,
            boxShadow: 3,
            borderRadius: 3
          }}
        >
          <CardContent>
            {/* Logo Container */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                //   backgroundColor: 'background.default',
                gap: 2, // Adds spacing between the logos
                mb: 3 // Adds margin below the logos
              }}
            >
              <img
                src={config.logo}
                alt='Domain Logo'
                style={{ height: '100px' }}
              />
              {/* <Logo style={{ height: '80px' }} /> */}
            </Box>

            <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 1 }}>
              {config.title}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {config.paragraph}
            </Typography>

            <CustomButton
              text={t('create_conversation')}
              outlined={false} // Filled button
              loading={loadingCreate} // Pass loading state
              onClick={handleCreateConversation} // Call the create conversation function
              sx={{
                marginTop: windowHeight / 200, // Adds margin above the button
                whiteSpace: 'nowrap', // Prevents text wrapping inside the button
                // padding: "8px 16px", // Adds padding for better spacing
                minWidth: 'auto', // Ensures the button width adjusts to text
                marginRight: currentLang === 'ar' ? '16px' : ''
              }}
            />

            <Typography
              variant='body2'
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                mt: 3,
                fontWeight: ''
              }}
              onClick={() => navigate('/', { state: { openModal: true } })}
            >
              Voir la vidéo de démonstration
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default WelcomeScreen
