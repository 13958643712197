import React from 'react';
import { List, Typography, Box, Fade } from '@mui/material';
import RenderConversations from '../RenderConversations';
import PropTypes from 'prop-types';
import { fontSize } from '../../../../utils/fonts';

const MessagesList = ({
  currentLang,
  t,
  filteredMessages,
  searchText,
  messages,
  isFetchingConversation,
  setSuggestedMessage
}) => {
  return (
    <List
      sx={{
        overflowY: 'auto',
        flexGrow: 1,
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Fade in={true} timeout={800}>
        <Box>
          <Typography
            variant='body1'
            component='div'
            sx={{
              textAlign: 'center',
              py: 3,
              px: 2,
              fontWeight: 500,
              fontSize: fontSize.small,
              color: 'text.secondary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              '::before': {
                content: '""',
                flex: 1,
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
                marginRight: currentLang === 'ar' ? '16px' : '0',
                marginLeft: currentLang === 'ar' ? '0' : '16px'
              },
              '::after': {
                content: '""',
                flex: 1,
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
                marginLeft: currentLang === 'ar' ? '16px' : '0',
                marginRight: currentLang === 'ar' ? '0' : '16px'
              }
            }}
          >
            {t('conversation_start')}
          </Typography>
        </Box>
      </Fade>
      
      <Box sx={{ px: 1, flexGrow: 1, overflowY: 'auto' }}>
        {filteredMessages?.length > 0 ? (
          <RenderConversations
            conversationId={messages[0]?.conversation_id}
            messages={filteredMessages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        ) : searchText?.length > 0 ? (
          <Typography
            variant='subtitle1'
            sx={{
              p: 3,
              textAlign: 'center',
              color: 'text.secondary',
              fontStyle: 'italic'
            }}
          >
            {t('no_messages')}
          </Typography>
        ) : (
          <RenderConversations
            conversationId={messages[0]?.conversation_id}
            messages={messages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        )}
      </Box>
    </List>
  );
};

MessagesList.propTypes = {
  currentLang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  filteredMessages: PropTypes.array,
  searchText: PropTypes.string,
  messages: PropTypes.array.isRequired,
  isFetchingConversation: PropTypes.bool.isRequired,
  setSuggestedMessage: PropTypes.func.isRequired
};

export default MessagesList;
