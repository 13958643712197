import React from 'react';
import { Box, Typography, Paper, alpha } from '@mui/material';
import { People, SearchOff } from '@mui/icons-material';

const EmptyState = ({
  title,
  description,
  icon,
  colors
}) => {
  const renderIcon = () => {
    switch (icon) {
      case 'people':
        return <People sx={{ fontSize: 48 }} />;
      case 'search':
        return <SearchOff sx={{ fontSize: 48 }} />;
      default:
        return <People sx={{ fontSize: 48 }} />;
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 6,
        borderRadius: '16px',
        backgroundColor: colors.surface,
        border: `1px dashed ${colors.border}`,
        textAlign: 'center',
        minHeight: '300px'
      }}
    >
      <Box 
        sx={{ 
          backgroundColor: alpha(colors.primary, 0.1),
          width: 100,
          height: 100,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: colors.primary,
          mb: 3
        }}
      >
        {renderIcon()}
      </Box>
      <Typography variant="h5" sx={{ fontWeight: 600, color: colors.text, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: colors.textSecondary, maxWidth: '500px' }}>
        {description}
      </Typography>
    </Paper>
  );
};

export default EmptyState;