import { backendClient } from '../../api/backend'
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  FETCH_RECENT_SEARCHES,
  FETCH_AUTOCOMPLETE_RESULTS,
  RESET_SEARCH_STORE,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOADING_DOCUMENT,
  SET_SEARCH_DOCUMENT,
  SET_SEARCH_IS_MODAL_MOBILE_VISIBLE,
  SEARCH_CLEAR_DOCUMENT,
  SET_SEARCH_DOCUMENT_SOURCE_URL,
  SET_SEARCH_SELECTED_CITATION,
  SET_SELECTED_DOC_TYPES,
  SET_AVAILABLE_DOC_TYPES,
  SET_FILTERED_CITATIONS,
  SET_IS_LOADING_SEARCH_CITATIONS,
  SET_IS_LOADING_AUTO_COMPLETE_SEARCH,
  UPDATE_FROM_AUTO_SELECT,
  RESET_AVAILABLE_DOC_TYPES,
  CANCEL_SEARCH
} from './types'

// Create a variable to store the current search controller
let searchController = null;

export const setFilteredCitations = citations => ({
  type: SET_FILTERED_CITATIONS,
  payload: citations
})

export const setSelectedDocTypes = docTypes => ({
  type: SET_SELECTED_DOC_TYPES,
  payload: docTypes
})

export const resetAvailableDocTypes = docTypes => ({
  type: RESET_AVAILABLE_DOC_TYPES,
  payload: docTypes
})

export const setAvailableDocTypes = docTypes => ({
  type: SET_AVAILABLE_DOC_TYPES,
  payload: docTypes
})

export const setSearchLoadingDocument = isLoading => ({
  type: SET_SEARCH_LOADING_DOCUMENT,
  payload: isLoading
})

export const setSearchDocument = document => ({
  type: SET_SEARCH_DOCUMENT,
  payload: document
})

export const setSearchSelectedCitation = citation => ({
  type: SET_SEARCH_SELECTED_CITATION,
  payload: citation
})

export const setSearchIsModalMobileVisible = isVisible => ({
  type: SET_SEARCH_IS_MODAL_MOBILE_VISIBLE,
  payload: isVisible
})

export const setIsLoadingSearchCitations = value => ({
  type: SET_IS_LOADING_SEARCH_CITATIONS,
  payload: value
})

export const setIsLoadingAutoCompleteSearch = value => ({
  type: SET_IS_LOADING_AUTO_COMPLETE_SEARCH,
  payload: value
})

export const setUpdateFromAutoSelect = value => ({
  type: UPDATE_FROM_AUTO_SELECT,
  payload: value
})

export const setSearchDocumentSourceUrl = url => ({
  type: SET_SEARCH_DOCUMENT_SOURCE_URL,
  payload: url
})

export const clearSearchDocument = () => ({
  type: SEARCH_CLEAR_DOCUMENT
})

export const resetSearchStore = () => ({ type: RESET_SEARCH_STORE })
export const searchRequest = () => ({ type: SEARCH_REQUEST })
export const searchSuccess = documents => ({
  type: SEARCH_SUCCESS,
  payload: documents
})
export const searchFailure = error => ({
  type: SEARCH_FAILURE,
  payload: error
})

export const cancelSearch = () => {
  // If there's an active search controller, abort it
  if (searchController) {
    searchController.abort()
    searchController = null
  }
  
  return { type: CANCEL_SEARCH }
}

export const fetchRecentSearchesSuccess = searches => ({
  type: FETCH_RECENT_SEARCHES,
  payload: searches
})

export const fetchAutocompleteResultsSuccess = results => ({
  type: FETCH_AUTOCOMPLETE_RESULTS,
  payload: results
})

export const searchCitations = (query, mode) => async dispatch => {
  dispatch(searchRequest())
  
  try {
    // Create a new AbortController for this search request
    if (searchController) {
      searchController.abort() // Abort any existing search
    }
    searchController = new AbortController()
    const signal = searchController.signal
    
    const response = await backendClient.search(query, mode, signal)
    
    // If the status is 499, it means the request was cancelled by the client
    if (response.status === 499) {
      dispatch({ type: CANCEL_SEARCH })
      searchController = null
      return
    }
    
    // Try to parse response as JSON, handling errors if it's not valid JSON
    let documents
    try {
      documents = await response.json()

    } catch (e) {
      throw new Error('Failed to parse search results')
    }
    
    if (!response.ok) {
      // Response is not OK, throw an error
      throw new Error(
        documents.detail || `Failed to search: HTTP status ${response.status}`
      )
    }

    dispatch(searchSuccess(documents))
    searchController = null // Clear the controller after successful completion
  } catch (error) {
    // Don't dispatch failure for aborted requests
    if (error.name === 'AbortError') {
      dispatch(setIsLoadingSearchCitations(false))
    } else {
      dispatch(
        searchFailure(error.message || 'An error occurred during the search')
      )
    }
    searchController = null // Clear the controller after failure
  }
}

export const setSearchQuery = searchQuery => ({
  type: SET_SEARCH_QUERY,
  payload: searchQuery
})

export const fetchRecentSearches =
  (userId = null, offset = 0) =>
  async dispatch => {
    try {
      const response = await backendClient.getRecentSearches(userId, offset)
      const searches = await response.json()
      dispatch(fetchRecentSearchesSuccess(searches))
      return searches // Return searches to determine if more results are available
    } catch (error) {
      console.error('Error fetching recent searches:', error)
      return [] // Return empty array to prevent undefined errors
    }
  }

export const fetchAutocompleteResults = query => async dispatch => {
  try {
    const response = await backendClient.getAutocompleteResults(query)
    const results = await response.json()
    dispatch(fetchAutocompleteResultsSuccess(results))
  } catch (error) {
    console.error('Error fetching autocomplete results:', error)
    dispatch(fetchAutocompleteResultsSuccess([]))
  }
}