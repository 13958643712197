export const RESET_STORE = 'RESET_STORE'

// AUTH ACTION TYPES
export const FETCH_USER = 'FETCH_USER'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE'
export const CLEAR_MESSAGE_ERROR = 'CLEAR_MESSAGE_ERROR'

// USER ACTION TYPES
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const UPDATE_PROMPT_REQUEST = 'UPDATE_PROMPT_REQUEST'
export const UPDATE_PROMPT_SUCCESS = 'UPDATE_PROMPT_SUCCESS'
export const UPDATE_PROMPT_FAILURE = 'UPDATE_PROMPT_FAILURE'
export const FETCH_PROMPTS_REQUEST = 'FETCH_PROMPTS_REQUEST'
export const FETCH_PROMPTS_SUCCESS = 'FETCH_PROMPTS_SUCCESS'
export const FETCH_PROMPTS_FAILURE = 'FETCH_PROMPTS_FAILURE'
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const RESET_ACTIVE_STEP = 'RESET_ACTIVE_STEP'
export const SET_LOADING_ONBOARDING = 'SET_LOADING_ONBOARDING'
export const SET_IS_BOARDED = 'SET_IS_BOARDED'

// DOCUMENT
export const SET_LOADING_DOCUMENT = 'SET_LOADING_DOCUMENT'
export const SET_DOCUMENT = 'SET_DOCUMENT'
export const SET_IS_MODAL_MOBILE_VISIBLE = 'SET_IS_MODAL_MOBILE_VISIBLE'
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT'
export const SET_SELECTED_CITATION = 'SET_SELECTED_CITATION'
export const SET_TRANSLATION_PROGRESS = 'SET_TRANSLATION_PROGRESS'
export const SET_TRANSLATION_IN_PROGRESS = 'SET_TRANSLATION_IN_PROGRESS'
export const SET_DOCUMENT_SOURCE_URL = 'SET_DOCUMENT_SOURCE_URL'

// SEARCH
export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'
export const FETCH_RECENT_SEARCHES = 'FETCH_RECENT_SEARCHES'
export const FETCH_AUTOCOMPLETE_RESULTS = 'FETCH_AUTOCOMPLETE_RESULTS'
export const RESET_SEARCH_STORE = 'RESET_SEARCH_STORE'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const RESET_AVAILABLE_DOC_TYPES = 'RESET_AVAILABLE_DOC_TYPES'
export const SET_SEARCH_SELECTED_CITATION = 'SET_SEARCH_SELECTED_CITATION'
export const SET_SEARCH_DOCUMENT_SOURCE_URL = 'SET_SEARCH_DOCUMENT_SOURCE_URL'
export const SET_SEARCH_LOADING_DOCUMENT = 'SET_SEARCH_LOADING_DOCUMENT'
export const SET_SEARCH_DOCUMENT = 'SET_SEARCH_DOCUMENT'
export const SET_SEARCH_IS_MODAL_MOBILE_VISIBLE =
  'SET_SEARCH_IS_MODAL_MOBILE_VISIBLE'
export const SEARCH_CLEAR_DOCUMENT = 'SEARCH_CLEAR_DOCUMENT'
export const SET_SELECTED_DOC_TYPES = 'SET_SELECTED_DOC_TYPES'
export const SET_AVAILABLE_DOC_TYPES = 'SET_AVAILABLE_DOC_TYPES'
export const SET_FILTERED_CITATIONS = 'SET_FILTERED_CITATIONS'
export const SET_IS_LOADING_SEARCH_CITATIONS = 'SET_IS_LOADING_SEARCH_CITATIONS'
export const SET_IS_LOADING_AUTO_COMPLETE_SEARCH = 'SET_IS_LOADING_AUTO_COMPLETE_SEARCH'
export const UPDATE_FROM_AUTO_SELECT = 'UPDATE_FROM_AUTO_SELECT'
export const CANCEL_SEARCH = 'CANCEL_SEARCH'


// CONVERSATION ACTION TYPES
export const FETCH_CONVERSATIONS_REQUEST = 'FETCH_CONVERSATIONS_REQUEST'
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS'
export const FETCH_CONVERSATIONS_FAILURE = 'FETCH_CONVERSATIONS_FAILURE'
export const FETCH_CONVERSATION_REQUEST = 'FETCH_CONVERSATION_REQUEST'
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS'
export const FETCH_CONVERSATION_FAILURE = 'FETCH_CONVERSATION_FAILURE'
export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE'
export const SEARCH_CONVERSATIONS_REQUEST = 'SEARCH_CONVERSATIONS_REQUEST'
export const SEARCH_CONVERSATIONS_SUCCESS = 'SEARCH_CONVERSATIONS_SUCCESS'
export const SEARCH_CONVERSATIONS_FAILURE = 'SEARCH_CONVERSATIONS_FAILURE'
export const RESET_CONVERSATION = 'RESET_CONVERSATION'
export const UPDATE_MESSAGE_LIKE = 'UPDATE_MESSAGE_LIKE'
export const UPDATE_MESSAGE_CONTENT = 'UPDATE_MESSAGE_CONTENT';

export const SET_CONVERSATION = 'SET_CONVERSATION'
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const SHARE_CONVERSATION_SUCCESS = 'SHARE_CONVERSATION_SUCCESS'

export const FETCH_FILTERED_CONVERSATIONS_REQUEST = 'FETCH_FILTERED_CONVERSATIONS_REQUEST';
export const FETCH_FILTERED_CONVERSATIONS_SUCCESS = 'FETCH_FILTERED_CONVERSATIONS_SUCCESS';
export const FETCH_FILTERED_CONVERSATIONS_FAILURE = 'FETCH_FILTERED_CONVERSATIONS_FAILURE';



// MESSAGE ACTION TYPES
export const SET_MESSAGES = 'SET_MESSAGES'
export const USER_SEND_MESSAGE = 'USER_SEND_MESSAGE'
export const SYSTEM_SEND_MESSAGE = 'SYSTEM_SEND_MESSAGE'

// NAVBAR ACTION TYPES
// Action Types
export const OPEN_NAVBAR = 'OPEN_NAVBAR'
export const SET_OPEN_NAVBAR = 'SET_OPEN_NAVBAR'


// CONTRACT
export const UPDATE_CONTRACT_TEMPLATE = 'UPDATE_CONTRACT_TEMPLATE';
export const SET_HIGHLIGHT_ERROR = 'SET_HIGHLIGHT_ERROR';
export const UPDATE_CONTRACT_CONTENT = 'UPDATE_CONTRACT_CONTENT';
export const SET_CONTRACT_LOADING = 'SET_CONTRACT_LOADING';
export const SET_CONTRACT_DATA = 'SET_CONTRACT_DATA';
export const SET_CONTRACT_ERROR = 'SET_CONTRACT_ERROR';
export const RESET_CONTRACT = 'RESET_CONTRACT';
// ///
export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'FETCH_CONTRACT_FAILURE';
export const SAVE_CONTRACT_REQUEST = 'SAVE_CONTRACT_REQUEST';
export const SAVE_CONTRACT_SUCCESS = 'SAVE_CONTRACT_SUCCESS';
export const SAVE_CONTRACT_FAILURE = 'SAVE_CONTRACT_FAILURE';
export const FETCH_CONTRACT_HISTORY_REQUEST = 'FETCH_CONTRACT_HISTORY_REQUEST';
export const FETCH_CONTRACT_HISTORY_SUCCESS = 'FETCH_CONTRACT_HISTORY_SUCCESS';
export const FETCH_CONTRACT_HISTORY_FAILURE = 'FETCH_CONTRACT_HISTORY_FAILURE';
export const ADD_HISTORY_ENTRY = 'ADD_HISTORY_ENTRY';
export const SHARE_CONTRACT_REQUEST = 'SHARE_CONTRACT_REQUEST';
export const SHARE_CONTRACT_FAILURE = 'SHARE_CONTRACT_FAILURE';
export const SHARE_CONTRACT_SUCCESS = 'SHARE_CONTRACT_SUCCESS';
//
export const FETCH_CONTRACTS_REQUEST = 'FETCH_CONTRACTS_REQUEST';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILURE = 'FETCH_CONTRACTS_FAILURE';
export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';
export const SEARCH_CONTRACTS_REQUEST = 'SEARCH_CONTRACTS_REQUEST';
export const SEARCH_CONTRACTS_SUCCESS = 'SEARCH_CONTRACTS_SUCCESS';
export const SEARCH_CONTRACTS_FAILURE = 'SEARCH_CONTRACTS_FAILURE';
export const DELETE_CONTRACTS_REQUEST = 'DELETE_CONTRACTS_REQUEST';
export const DELETE_CONTRACTS_SUCCESS = 'DELETE_CONTRACTS_SUCCESS';
export const DELETE_CONTRACTS_FAILURE = 'DELETE_CONTRACTS_FAILURE';
////
export const CONTRACT_MESSAGE_CLEAR = 'CONTRACT_MESSAGE_CLEAR';
export const CONTRACT_MESSAGE_SET = 'CONTRACT_MESSAGE_SET';
export const CONTRACT_MESSAGE_ADD_USER = 'CONTRACT_MESSAGE_ADD_USER';
export const CONTRACT_MESSAGE_STREAM_UPDATE = 'CONTRACT_MESSAGE_STREAM_UPDATE';
export const CONTRACT_MESSAGE_STREAM_DONE = 'CONTRACT_MESSAGE_STREAM_DONE';
export const CONTRACT_MESSAGE_ERROR = 'CONTRACT_MESSAGE_ERROR';
//
export const CLEAR_CHAT_REQUEST = 'CLEAR_CHAT_REQUEST';
export const CLEAR_CHAT_SUCCESS = 'CLEAR_CHAT_SUCCESS';
export const CLEAR_CHAT_FAILURE = 'CLEAR_CHAT_FAILURE';

// Create contract conversation types
export const CREATE_CONTRACT_CONVERSATION_REQUEST = 'CREATE_CONTRACT_CONVERSATION_REQUEST';
export const CREATE_CONTRACT_CONVERSATION_SUCCESS = 'CREATE_CONTRACT_CONVERSATION_SUCCESS';
export const CREATE_CONTRACT_CONVERSATION_FAILURE = 'CREATE_CONTRACT_CONVERSATION_FAILURE';

//
export const RISK_ANALYSIS_REQUEST = 'RISK_ANALYSIS_REQUEST';
export const RISK_ANALYSIS_RESET = 'RISK_ANALYSIS_RESET';
export const RISK_ANALYSIS_ADD = 'RISK_ANALYSIS_ADD';
export const RISK_ANALYSIS_ERROR = 'RISK_ANALYSIS_ERROR';
export const RISK_ANALYSIS_COMPLETE = 'RISK_ANALYSIS_COMPLETE';
export const SET_CURRENT_CONVERSATION_ID = 'SET_CURRENT_CONVERSATION_ID';
//
export const UPDATE_CONTRACT_STATUS = 'UPDATE_CONTRACT_STATUS';
export const UPDATE_CONTRACT_STATUS_SUCCESS = 'UPDATE_CONTRACT_STATUS_SUCCESS';
export const UPDATE_CONTRACT_STATUS_FAILURE = 'UPDATE_CONTRACT_STATUS_FAILURE';

//
export const SIGNATURE_REQUEST_LOADING = 'SIGNATURE_REQUEST_LOADING';
export const SIGNATURE_REQUEST_SUCCESS = 'SIGNATURE_REQUEST_SUCCESS';
export const SIGNATURE_REQUEST_FAILURE = 'SIGNATURE_REQUEST_FAILURE';
// Signature list fetching
export const SIGNATURE_LIST_LOADING = 'SIGNATURE_LIST_LOADING';
export const SIGNATURE_LIST_SUCCESS = 'SIGNATURE_LIST_SUCCESS';
export const SIGNATURE_LIST_FAILURE = 'SIGNATURE_LIST_FAILURE';
// Signature cancellation
export const SIGNATURE_CANCEL_LOADING = 'SIGNATURE_CANCEL_LOADING';
export const SIGNATURE_CANCEL_SUCCESS = 'SIGNATURE_CANCEL_SUCCESS';
export const SIGNATURE_CANCEL_FAILURE = 'SIGNATURE_CANCEL_FAILURE';
// Signature reminder
export const SIGNATURE_REMIND_LOADING = 'SIGNATURE_REMIND_LOADING';
export const SIGNATURE_REMIND_SUCCESS = 'SIGNATURE_REMIND_SUCCESS';
export const SIGNATURE_REMIND_FAILURE = 'SIGNATURE_REMIND_FAILURE';
// Add
export const SIGNATURE_ADD_LOADING = 'SIGNATURE_ADD_LOADING';
export const SIGNATURE_ADD_SUCCESS = 'SIGNATURE_ADD_SUCCESS';
export const SIGNATURE_ADD_FAILURE = 'SIGNATURE_ADD_FAILURE';
// Delete
export const SIGNATURE_DELETE_LOADING = 'SIGNATURE_DELETE_LOADING'
export const SIGNATURE_DELETE_SUCCESS = 'SIGNATURE_DELETE_SUCCESS'
export const SIGNATURE_DELETE_FAILURE = 'SIGNATURE_DELETE_FAILURE'
// Clauses
export const CLAUSES_REQUEST = 'CLAUSES_REQUEST';
export const CLAUSES_RESET = 'CLAUSES_RESET';
export const CLAUSES_ADD = 'CLAUSES_ADD';
export const CLAUSES_ERROR = 'CLAUSES_ERROR';
export const CLAUSES_COMPLETE = 'CLAUSES_COMPLETE';
// Suggested Clauses
export const SUGGESTED_CLAUSES_REQUEST = 'SUGGESTED_CLAUSES_REQUEST';
export const SUGGESTED_CLAUSES_RESET = 'SUGGESTED_CLAUSES_RESET';
export const SUGGESTED_CLAUSES_ADD = 'SUGGESTED_CLAUSES_ADD';
export const SUGGESTED_CLAUSES_ERROR = 'SUGGESTED_CLAUSES_ERROR';
export const SUGGESTED_CLAUSES_COMPLETE = 'SUGGESTED_CLAUSES_COMPLETE';
// Filter
export const FILTER_CONTRACTS_REQUEST = 'FILTER_CONTRACTS_REQUEST';
export const FILTER_CONTRACTS_SUCCESS = 'FILTER_CONTRACTS_SUCCESS';
export const FILTER_CONTRACTS_FAILURE = 'FILTER_CONTRACTS_FAILURE';
//
export const UPDATE_CONTRACT_OPTIMISTIC = 'UPDATE_CONTRACT_OPTIMISTIC';