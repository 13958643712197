import { Extension } from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import { FontFamily } from '@tiptap/extension-font-family'
import { FontSize } from '@tiptap/extension-font-size'
import { TextStyle } from '@tiptap/extension-text-style'

/**
 * TextColor Extension - Provides text color functionality
 */
export const TextColor = Extension.create({
    name: 'textColor',

    addExtensions() {
        return [
            TextStyle.configure(),
            Color.configure()
        ]
    },
})

/**
 * TypographyExtension - Provides font size and font family functionality
 */
export const TypographyExtension = Extension.create({
    name: 'typography',

    addExtensions() {
        return [
            TextStyle.configure(),
            FontSize.configure({
                types: ['textStyle'],
            }),
            FontFamily.configure({
                types: ['textStyle'],
            }),
        ]
    },
})

/**
 * Sets text color and updates toolbar state
 * @param {Editor} editor - TipTap editor instance
 * @param {string} color - Color value (hex, rgb, etc.)
 * @returns {boolean} - Success status
 */
export const setTextColor = (editor, color) => {
    if (!editor || !color) return false

    // Set the color on the selected text
    const result = editor.chain().focus().setColor(color).run()

    return result
}


/**
 * Gets all text formatting attributes from current selection
 * @param {Editor} editor - TipTap editor instance
 * @returns {Object} - Object with color, fontSize, and fontFamily values
 */
export const getFormattingAttributes = (editor) => {
    if (!editor) return { color: '#000000', fontSize: '16px', fontFamily: 'Arial, sans-serif' }

    try {
        // Get current selection
        const { from, to, empty } = editor.state.selection
        let attributes = { color: null, fontSize: null, fontFamily: null }

        // For empty selection (cursor), check marks at cursor
        if (empty) {
            // Get marks at cursor position
            const marks = editor.state.selection.$from.marks()

            // Look for textStyle mark
            for (const mark of marks) {
                if (mark.type.name === 'textStyle') {
                    // Extract all relevant attributes
                    if (mark.attrs.color) attributes.color = mark.attrs.color
                    if (mark.attrs.fontSize) attributes.fontSize = mark.attrs.fontSize
                    if (mark.attrs.fontFamily) attributes.fontFamily = mark.attrs.fontFamily
                }
            }

            // If no attributes at cursor, check previous position
            if (!attributes.color && !attributes.fontSize && !attributes.fontFamily && from > 0) {
                const prevPos = Math.max(0, from - 1)
                const prevNode = editor.state.doc.nodeAt(prevPos)

                if (prevNode && prevNode.marks) {
                    for (const mark of prevNode.marks) {
                        if (mark.type.name === 'textStyle') {
                            // Extract attributes from previous node
                            if (mark.attrs.color && !attributes.color) attributes.color = mark.attrs.color
                            if (mark.attrs.fontSize && !attributes.fontSize) attributes.fontSize = mark.attrs.fontSize
                            if (mark.attrs.fontFamily && !attributes.fontFamily) attributes.fontFamily = mark.attrs.fontFamily
                        }
                    }
                }
            }
        }
        // For text selection, scan through selected content
        else {
            // Start with the first position
            let pos = from

            // Iterate through positions in the selection
            while (pos < to) {
                const node = editor.state.doc.nodeAt(pos)

                if (node && node.marks) {
                    for (const mark of node.marks) {
                        if (mark.type.name === 'textStyle') {
                            // Extract attributes
                            if (mark.attrs.color && !attributes.color) attributes.color = mark.attrs.color
                            if (mark.attrs.fontSize && !attributes.fontSize) attributes.fontSize = mark.attrs.fontSize
                            if (mark.attrs.fontFamily && !attributes.fontFamily) attributes.fontFamily = mark.attrs.fontFamily

                            // If we found all attributes, exit early
                            if (attributes.color && attributes.fontSize && attributes.fontFamily) {
                                return {
                                    color: attributes.color || '#000000',
                                    fontSize: attributes.fontSize || '16px',
                                    fontFamily: attributes.fontFamily || 'Arial, sans-serif'
                                }
                            }
                        }
                    }
                }

                // Move to next position
                pos += node ? node.nodeSize : 1
            }
        }

        // Return attributes with defaults for missing values
        return {
            color: attributes.color || '#000000',
            fontSize: attributes.fontSize || '16px',
            fontFamily: attributes.fontFamily || 'Arial, sans-serif'
        }
    } catch (err) {
        console.error('Error getting text formatting attributes:', err)
        return { color: '#000000', fontSize: '16px', fontFamily: 'Arial, sans-serif' }
    }
}

/**
 * Gets current text color from selection
 * @param {Editor} editor - TipTap editor instance
 * @returns {string} - Current text color
 */
export const getCurrentTextColor = (editor) => {
    if (!editor) return '#000000'
    return getFormattingAttributes(editor).color
}



/**
 * Sets up event listeners for text formatting detection and updates
 * @param {Editor} editor - TipTap editor instance
 * @param {Object} setters - Object with setter functions for state updates
 * @returns {Function} - Cleanup function to remove event listeners
 */
export const setupFormattingDetection = (editor, setters) => {
    if (!editor || !setters) return null

    const { setCurrentTextColor, setCurrentFontSize, setCurrentFontFamily } = setters

    // Function to update all formatting values from current selection
    const updateFormattingFromSelection = () => {
        try {
            // Get all formatting attributes at once for better performance
            const attrs = getFormattingAttributes(editor)

            // Update state only if setter is provided
            if (setCurrentTextColor) setCurrentTextColor(attrs.color)
            if (setCurrentFontSize) setCurrentFontSize(attrs.fontSize)
            if (setCurrentFontFamily) setCurrentFontFamily(attrs.fontFamily)

            // Debug logging (can be removed in production)
            if (process.env.NODE_ENV === 'development') {
                // console.log('Current formatting updated:', attrs)
            }
        } catch (err) {
            console.error('Error updating formatting from selection:', err)
        }
    }

    // Define event handlers
    const onSelectionUpdate = () => updateFormattingFromSelection()
    const onFocus = () => updateFormattingFromSelection()
    const onUpdate = () => updateFormattingFromSelection()
    const onTransaction = () => updateFormattingFromSelection()

    // Register event listeners
    editor.on('selectionUpdate', onSelectionUpdate)
    editor.on('focus', onFocus)
    editor.on('update', onUpdate)
    editor.on('transaction', onTransaction)

    // Initial update
    updateFormattingFromSelection()

    // Return cleanup function
    return () => {
        editor.off('selectionUpdate', onSelectionUpdate)
        editor.off('focus', onFocus)
        editor.off('update', onUpdate)
        editor.off('transaction', onTransaction)
    }
}