import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Tooltip,
  Paper,
  Box
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const UsersTable = ({
  filteredUsers,
  page,
  rowsPerPage,
  currentUser,
  onEditRoles,
  onDeleteUser,
  getRoleDisplayName,
  colors,
  onChangePage,
  onChangeRowsPerPage,
  t
}) => {
  // Gets the role color based on role name
  const getUserManagementRoleColor = (role) => {
    switch (role) {
      case 'admin':
        return colors.secondary;
      case 'billing_admin':
        return colors.info;
      default:
        return '#e0e0e0';
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        border: `1px solid ${colors.border}`,
        mb: 3
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.backgroundAlt }}>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('name', 'Name')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('email', 'Email')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('phone', 'Phone')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('roles', 'Roles')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('status', 'Status')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('last_login', 'Last Login')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.text,
                  py: 2.5
                }}
              >
                {t('actions', 'Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id} sx={{
                  '&:hover': { backgroundColor: colors.backgroundAlt },
                  transition: 'background-color 0.2s'
                }}>
                  <TableCell sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {user.first_name} {user.last_name}
                      {user.id === currentUser.id && (
                        <Chip
                          label={t('you', 'You')}
                          size="small"
                          sx={{
                            ml: 1,
                            backgroundColor: colors.primary,
                            color: 'white',
                            fontWeight: 500,
                            fontSize: '0.7rem'
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 2, color: colors.textSecondary }}>
                    {user.email}
                  </TableCell>
                  <TableCell sx={{ py: 2, color: colors.textSecondary }}>
                    {user.phone_number || '-'}
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {user.roles.map((role) => (
                        <Chip
                          key={role}
                          label={getRoleDisplayName(role)}
                          size="small"
                          sx={{
                            backgroundColor: getUserManagementRoleColor(role),
                            color: role === 'user' ? colors.text : 'white',
                            fontWeight: 500,
                            fontSize: '0.7rem'
                          }}
                        />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Chip
                      label={user.is_active ? t('active', 'Active') : t('inactive', 'Inactive')}
                      color={user.is_active ? 'success' : 'default'}
                      size="small"
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.7rem',
                        backgroundColor: user.is_active ? colors.secondaryLight : colors.backgroundAlt,
                        color: user.is_active ? colors.secondary : colors.textSecondary
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ py: 2, color: colors.textSecondary }}>
                    {user.last_login
                      ? new Date(user.last_login).toLocaleString()
                      : t('never', 'Never')}
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title={t('edit_roles', 'Edit Roles')}>
                        <span>
                          <IconButton
                            onClick={() => onEditRoles(user)}
                            disabled={user.id === currentUser.id}
                            size="small"
                            sx={{
                              mr: 1,
                              color: colors.primary,
                              backgroundColor: colors.primaryLight,
                              '&:hover': {
                                backgroundColor: colors.primary,
                                color: 'white'
                              },
                              '&.Mui-disabled': {
                                opacity: 0.4,
                                backgroundColor: colors.backgroundAlt
                              }
                            }}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={t('delete_user', 'Delete User')}>
                        <span>
                          <IconButton
                            onClick={() => onDeleteUser(user)}
                            disabled={user.id === currentUser.id}
                            size="small"
                            sx={{
                              color: colors.error,
                              backgroundColor: 'rgba(239, 68, 68, 0.1)',
                              '&:hover': {
                                backgroundColor: colors.error,
                                color: 'white'
                              },
                              '&.Mui-disabled': {
                                opacity: 0.4,
                                backgroundColor: colors.backgroundAlt
                              }
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={t('rows_per_page', 'Rows per page:')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t('of', 'of')} ${count}`
        }
        sx={{
          borderTop: `1px solid ${colors.border}`,
          color: colors.textSecondary,
          '& .MuiTablePagination-select': {
            borderRadius: '8px',
          },
          '& .MuiTablePagination-selectIcon': {
            color: colors.textSecondary
          }
        }}
      />
    </Paper>
  );
};

export default UsersTable;