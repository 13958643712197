import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Typography
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, selectedCount }) => {
  const { t } = useTranslation();
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
          width: '100%',
          maxWidth: 400
        }
      }}
    >
      <DialogTitle 
        id="alert-dialog-title"
        sx={{ 
          pt: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Box 
          sx={{ 
            backgroundColor: 'error.light', 
            borderRadius: '50%',
            width: 60, 
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <DeleteOutlineIcon sx={{ fontSize: 32, color: 'error.main' }} />
        </Box>
        <Typography variant="h6" component="div" fontWeight="bold">
          {t("confirm_delete_title")}
        </Typography>
      </DialogTitle>
      
      <DialogContent sx={{ px: 4, pb: 2 }}>
        <DialogContentText 
          id="alert-dialog-description"
          sx={{ textAlign: 'center' }}
        >
          {selectedCount > 1
            ? t("confirm_delete_multiple_conversations", { count: selectedCount })
            : t("confirm_delete_conversation")}
        </DialogContentText>
      </DialogContent>
      
      <DialogActions sx={{ px: 3, pb: 3, justifyContent: 'center', gap: 1 }}>
        <Button 
          onClick={onClose} 
          color="primary"
          variant="outlined"
          sx={{ 
            minWidth: 100,
            borderRadius: 1.5,
            textTransform: 'none'
          }}
        >
          {t("cancel")}
        </Button>
        
        <Button 
          onClick={onConfirm} 
          color="error" 
          variant="contained"
          autoFocus
          sx={{ 
            minWidth: 100,
            borderRadius: 1.5,
            textTransform: 'none'
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;