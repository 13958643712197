import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import { fetchUser, logout } from '../redux/actions/authAction'
import DashboardScreen from '../screens/DashboardScreen/DashboardScreen'
import SearchScreen from '../screens/SearchScreen/SearchScreen'
import ChatScreen from '../screens/ChatScreen/ChatScreen'
import ChatHistory from '../screens/ChatScreen/ChatHistory'
import SettingsScreen from '../screens/SettingsScreen/SettingsScreen'
import LoginScreen from '../screens/LoginScreen/LoginScreen'
import Navbar from '../components/Navbar'
import NotFoundScreen from '../screens/NotFoundScreen/NotFoundScreen'
import SignupScreen from '../screens/SingupScreen/SignupScreen'
import SignupConfirmationScreen from '../screens/SingupScreen/SignupConfirmationScreen'
import ForgotPasswordScreen from '../screens/LoginScreen/ForgotPasswordScreen'
import ResetPasswordScreen from '../screens/LoginScreen/ResetPasswordScreen'
import ConfirmAccount from '../screens/SingupScreen/ConfirmAccount'
import SignupConfirmationDomainScreen from '../screens/SingupScreen/SignupConfirmationDomainScreen'
import ContractHistory from '../screens/ContractScreen/ContractHistory'
import ContractEditionScreen from '../screens/ContractScreen/ContractEditionScreen'
import WelcomeScreen from '../screens/LoginScreen/WelcomeScreen'
import CheckoutScreen from '../screens/PaymentScreen/CheckoutScreen'
import PaymentSuccessScreen from '../screens/PaymentScreen/PaymentSuccessScreen'
import PaymentScreen from '../screens/PaymentScreen/PaymentScreen'
import PaymentFailureScreen from '../screens/PaymentScreen/PaymentFailureScreen'
import PaymentCancelScreen from '../screens/PaymentScreen/PaymentCancelScreen'
import PasswordResetSuccessScreen from '../screens/LoginScreen/PasswordResetSuccessScreen'
import { backendClient } from '../api/backend'
import PricingScreen from '../screens/SettingsScreen/PricingScreen'
import { CircularProgress } from '@mui/material'
import InvitationScreen from '../screens/SingupScreen/InvitationScreen'

// Subscription validation component that checks if user has valid subscription access
const SubscriptionRoute = ({ children }) => {
  const user = useSelector(state => state.auth.user)
  const [subscription, setSubscription] = useState(null)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  // Define payment-related pages
  const paymentRelatedPages = [
    '/settings',
    '/pricing',
    '/checkout',
    '/payment',
    '/payment-success',
    '/payment-failure',
    '/payment-cancel'
  ];

  // Check if current path is a payment page
  const isPaymentRelatedPage = paymentRelatedPages.some(page =>
    location.pathname === page || location.pathname.startsWith(page + '/')
  );

  useEffect(() => {
    const checkSubscription = async () => {
      if (!user?.user?.id) return

      try {
        const subscriptionData = await backendClient.getSubscriptionInfo(user?.user?.organization_id)
        setSubscription(subscriptionData)
      } catch (error) {
        console.error('Error fetching subscription:', error)
      } finally {
        setLoading(false)
      }
    }

    checkSubscription()
  }, [user?.user?.id])

  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size={42} />
    </div>
  }

  // SIMPLIFIED LOGIC:
  // 1. Always allow access to payment-related pages
  if (isPaymentRelatedPage) {
    return children;
  }

  // 2. Check if subscription is valid
  const hasValidSubscription = () => {
    if (!subscription) return false;

    return ['active', 'SUPER', 'trial'].includes(subscription.status) ||
      (subscription.status === 'cancelled' &&
        subscription.next_billing_date &&
        new Date(subscription.next_billing_date) > new Date());
  }

  // 3. Allow access if subscription is valid
  if (hasValidSubscription()) {
    return children;
  }

  // 4. Redirect to settings for all other cases
  return <Navigate to="/settings" />;
};


// PrivateRoute component to protect routes based on user authentication
const PrivateRoute = ({ children }) => {
  const user = useSelector(state => state.auth.user)

  return user ? <SubscriptionRoute>{children}</SubscriptionRoute> : <Navigate to='/login' />
}

// CheckoutRoute component to prevent access to checkout on dev.juridia.ma
const CheckoutRoute = ({ children }) => {
  const isDev = window.location.hostname.includes('dev.juridia.ma')

  if (isDev) {
    return <Navigate to="/not-found-screen" />
  }

  return children
}

// AuthWrapper component to handle token expiration checks
const AuthWrapper = ({ children }) => {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // Function to check token expiration
  const isTokenExpired = token => {
    if (!token) return true
    const { exp } = jwtDecode(token)
    return Date.now() >= exp * 1000
  }

  // Check token version match
  const isTokenVersionValid = (storedTokenVersion, backendTokenVersion) => {
    return Number(storedTokenVersion) === backendTokenVersion
  }

  useEffect(() => {
    const fetch_fn = async () => {
      console.log('fetching user...', user?.user.id)
      dispatch(fetchUser(user?.user.id))
    }
    if (!user) {
      fetch_fn()
    }
  }, [])

  useEffect(() => {
    // List of allowed public routes that don't require authentication
    const publicRoutes = [
      '/payment',
      '/payment-success',
      '/payment-failure',
      '/payment-cancel',
      '/login',
      '/signup',
      '/signup/confirmation',
      '/signup/confirmation-dm',
      '/forgot-password',
      '/password-reset-success',
      '/reset-password',
      '/invitation',
      '/not-found-screen',
      '/confirm'
    ]

    // Special routes that can be accessed by both logged-in and logged-out users
    const specialRoutes = [
      '/checkout',
      '/payment',
      '/payment-success',
      '/payment-failure',
      '/payment-cancel',
    ]

    const isPublicRoute = publicRoutes.some(route =>
      location.pathname.startsWith(route)
    )

    const isSpecialRoute = specialRoutes.some(route =>
      location.pathname.startsWith(route)
    )

    if (isPublicRoute && user && !isSpecialRoute) {
      // Redirect logged-in users away from public routes
      navigate('/');
    } else if (!isPublicRoute && !isSpecialRoute) {
      // Redirect to login if user does not exist
      if (!user) {
        navigate('/login')
        return
      }

      // Check token expiration, logout, and redirect if expired
      if (isTokenExpired(user?.token?.access_token)) {
        dispatch(logout())
        navigate('/login')
      }

      const tokenVersion = user?.user?.token_version
      const storedTokenVersion = localStorage.getItem('token_version')

      if (!isTokenVersionValid(storedTokenVersion, tokenVersion)) {
        dispatch(logout())
        navigate('/login')
      }
    }

  }, [user, dispatch, navigate, location.pathname])

  return children
}

const AppRouter = () => {
  const user = useSelector(state => state.auth.user)

  return (
    <Router>
      <AuthWrapper>
        <div style={{ display: 'flex' }}>
          {user && <Navbar />}
          <div style={{ flexGrow: 1, padding: '20px' }}>
            <Routes>
              {/* Public Routes */}
              <Route path='/payment-success' element={<PaymentSuccessScreen />} />
              <Route path='/payment-failure' element={<PaymentFailureScreen />} />
              <Route path='/payment-cancel' element={<PaymentCancelScreen />} />
              <Route path='/payment' element={<PaymentScreen />} />
              
              <Route path='/login' element={<LoginScreen />} />
              <Route
                path='/forgot-password'
                element={<ForgotPasswordScreen />}
              />
              <Route
                path='/password-reset-success'
                element={<PasswordResetSuccessScreen />}
              />
              <Route path='/reset-password' element={<ResetPasswordScreen />} />

              <Route path="/invitation" element={<InvitationScreen />} />

              <Route path='/signup' element={<SignupScreen />} />
              <Route path='/confirm/:token' element={<ConfirmAccount />} />
              <Route
                path='/signup/confirmation'
                element={<SignupConfirmationScreen />}
              />
              <Route
                path='/signup/confirmation-dm'
                element={<SignupConfirmationDomainScreen />}
              />

              {/* Routes accessible to both logged-in and non-logged-in users */}
              <Route
                path='/checkout'
                element={
                  <CheckoutRoute>
                    <CheckoutScreen />
                  </CheckoutRoute>
                }
              />

              {/* Private Routes */}
              <Route
                path='/'
                element={
                  <PrivateRoute>
                    <DashboardScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/search'
                element={
                  <PrivateRoute>
                    <SearchScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/conversation/:conversationId'
                element={
                  <PrivateRoute>
                    <ChatScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/conversation'
                element={
                  <PrivateRoute>
                    <ChatHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path='/contract'
                element={
                  <PrivateRoute>
                    <ContractHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path='/contract/:contractId'
                element={
                  <PrivateRoute>
                    <ContractEditionScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/settings'
                element={
                  <PrivateRoute>
                    <SettingsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/pricing'
                element={
                  <PrivateRoute>
                    <PricingScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path='/welcome'
                element={
                  <PrivateRoute>
                    <WelcomeScreen />
                  </PrivateRoute>
                }
              />

              {/* Not Found Route */}
              <Route path='/not-found-screen' element={<NotFoundScreen />} />
              {/* Catch-All Route */}
              <Route path='*' element={<Navigate to='/not-found-screen' />} />
            </Routes>
          </div>
        </div>
      </AuthWrapper>
    </Router>
  )
}

export default AppRouter