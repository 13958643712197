import React, { useEffect, useMemo, useState } from 'react'
import usePDFViewer from './usePdfViewer'
import { PDFOptionsBar } from './PdfOptionsBar'
import MemoizedVirtualizedPDF from './VirtualizedPdf'
import { useSelector } from 'react-redux'

// Memoizing the ViewPdf component to prevent unnecessary re-renders
const ViewPdf = React.memo(({ document, selectedCitation, documentSourceUrl, setDocument }) => {
  const [mounted, setMounted] = useState(false)
  const isSearchScreen = window.location.pathname.includes('/search')

  // Get the viewport dimensions to help with debugging
  const {
    scrolledIndex,
    setCurrentPageNumber,
    scale,
    setScaleFit,
    numPages,
    setNumPages,
    handleZoomIn,
    handleZoomOut,
    nextPage,
    prevPage,
    scaleText,
    pdfFocusRef,
    goToPage,
    setZoomLevel,
    zoomInEnabled,
    zoomOutEnabled
  } = usePDFViewer(document)

  // Make sure component is fully mounted before rendering controls
  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  // Memoizing derived data for better performance
  const memoizedDocument = useMemo(() => document, [document])
  const memoizedScaleText = useMemo(() => scaleText, [scaleText])

  // Force update when numPages changes
  useEffect(() => {
    if (numPages > 0) {
      // This empty effect ensures we re-render when numPages is set
      console.log(`PDF loaded with ${numPages} pages`)
    }
  }, [numPages])

  console.log('documentXXX', document)
  return (
    <div className='relative w-full h-full'>
      {/* Always render the options bar as long as we have a document */}
      {mounted && memoizedDocument && (
        <PDFOptionsBar
          documentSourceUrl={documentSourceUrl}
          documentDoc={memoizedDocument}
          selectedCitation={selectedCitation}
          scrolledIndex={scrolledIndex}
          numPages={numPages}
          scaleText={memoizedScaleText || '100%'} // Provide default value
          nextPage={nextPage}
          prevPage={prevPage}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          goToPage={goToPage}
          setZoomLevel={setZoomLevel}
          zoomInEnabled={zoomInEnabled}
          zoomOutEnabled={zoomOutEnabled}
          setDocument={setDocument}
        />
      )}

      {memoizedDocument && (
        <MemoizedVirtualizedPDF
          key={`${memoizedDocument?.id}`}
          ref={pdfFocusRef}
          file={memoizedDocument}
          setIndex={setCurrentPageNumber}
          scale={scale}
          setScaleFit={setScaleFit}
          setNumPages={setNumPages}
        />
      )}
    </div>
  )
})

export default ViewPdf