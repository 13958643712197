import { ROLE } from "./constants";
import _ from "lodash";
import { documentColors } from "./colors"; // Import the document colors
import moment from "moment";
import i18n from "../i18n";
import { alpha } from '@mui/material/styles';

export const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

// export const getCitations = (message) => {

//   let citations = [];
//   if (message.role === ROLE.ASSISTANT) {
//     const sub_processes = message.sub_processes;
//     if (sub_processes && sub_processes.length > 0) {
//       const metadata_maps = sub_processes
//         .map((sp) => sp.metadata_map)
//         .filter((m) => !!m);
//       const sub_questions = metadata_maps
//         .map((m) => m?.sub_question)
//         .filter((sq) => !!sq);
//       const uniqueIds = {};
//       const citationsList = sub_questions.map((sq) => sq?.citations || []);
//       citations = citationsList
//         .reduce((acc, curr) => [...acc, ...curr], [])
//         .filter((obj) => {
//           if (!uniqueIds[obj?.file_name]) {
//             uniqueIds[obj?.file_name] = true;
//             return true;
//           }
//           return false;
//         });
//     }
//   }
//   return citations;
// };

// Helper function to get color based on role
// export const getRoleColor = (role) => {
//   switch (role) {
//     case 'viewer':
//       return '#78909c'; // Cool gray
//     case 'editor':
//       return '#66bb6a'; // Soft green
//     case 'reviewer':
//       return '#ba68c8'; // Soft purple
//     case 'creator':
//       return '#42a5f5'; // Sky blue
//     case 'signer':
//       return '#ffa726'; // Vibrant orange
//     default:
//       return '#e0e0e0'; // Light gray
//   }
// };

export const getRoleColor = (role) => {
  switch (role) {
    case 'viewer':
      return '#6B7280'; // Pink
    case 'editor':
      return '#10B981'; // Green
    case 'reviewer':
      return '#F59E0B'; // Amber
    case 'creator':
      return '#3B82F6'; // Blue
    case 'signer':
      return '#8B5CF6'; // Purple
    default:
      return '#6B7280'; // Gray for default
  }
};


export const statusColors = {
  // Status keys
  "SIGNED": "#10B981", // Green
  "IN_REVIEW": "#8B5CF6", // Purple
  "DRAFT": "#F59E0B", // Amber
  "PENDING_SIGNATURE": "#EF4444", // Red

  // For direct matching if needed
  "signé": "#10B981",
  "en revu": "#8B5CF6",
  "brouillon": "#F59E0B",
  "en attente de signature": "#EF4444",

  "Signed": "#10B981",
  "In Review": "#8B5CF6",
  "Draft": "#F59E0B",
  "Pending Signature": "#EF4444",

  "موقّع": "#10B981",
  "قيد المراجعة": "#8B5CF6",
  "مسودة": "#F59E0B",
  "في انتظار التوقيع": "#EF4444"
};

// Helper function for status styling
export const getStatusStyle = (status) => {
  const color = statusColors[status] || '#94A3B8';

  return {
    backgroundColor: alpha(color, 0.1),
    color: color,
    fontWeight: 600,
    fontSize: '0.75rem',
    border: `1px solid ${alpha(color, 0.2)}`
  };
};


// Helper function for date formatting
export const formatDate = (dateString, language) => {
  if (!dateString) return '';
  const date = new Date(dateString);

  // Use the language parameter to format date according to user's locale
  return date.toLocaleDateString(language === 'ar' ? 'ar-EG' :
    language === 'fr' ? 'fr-FR' : 'en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};


// Get translated status key
export const getStatusKey = (status) => {
  // Map the status string to its key
  const statusMap = {
    "signé": "SIGNED",
    "Signed": "SIGNED",
    "Signé": "SIGNED",
    "موقّع": "SIGNED",

    "en revu": "IN_REVIEW",
    "In Review": "IN_REVIEW",
    "En revue": "IN_REVIEW",
    "قيد المراجعة": "IN_REVIEW",

    "brouillon": "DRAFT",
    "Draft": "DRAFT",
    "Brouillon": "DRAFT",
    "مسودة": "DRAFT",

    "en attente de signature": "PENDING_SIGNATURE",
    "Pending Signature": "PENDING_SIGNATURE",
    "En attente de signature": "PENDING_SIGNATURE",
    "في انتظار التوقيع": "PENDING_SIGNATURE"
  };

  return statusMap[status] || status;
};



export const getCitationSectionTitle = (section_title) => {
  if (section_title.toLowerCase() === 'titre') {
    return 'Document';
  } else {
    return section_title
      ?.replace(':', '')
      .replace('N/A ', '')
      .trim()
      .toLowerCase();
  }
};


// Format HTML to display nicely in accordions
export function formatHtmlForDisplay(html) {
  if (!html) return '';

  // Replace <br> tags with a bullet points for display
  let text = html.replace(/<br\s*\/?>/gi, ' • ');

  // Remove all other HTML tags
  text = text.replace(/<[^>]*>/g, '');

  // Decode HTML entities
  text = text.replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

  // Clean up whitespace
  text = text.replace(/\s+/g, ' ').trim();

  return text;
}


export const getToolbarWidth = (canScrollLeft, canScrollRight) => {
  // Base width when both arrows are visible
  const baseWidth = windowWidth / 2.15;

  // Each arrow takes approximately this much space
  const arrowWidth = 30; // Adjust this value based on your actual arrow button width

  if (canScrollLeft && canScrollRight) {
    // Both arrows visible - use base width
    return baseWidth;
  } else if (canScrollLeft || canScrollRight) {
    // Only one arrow visible - add space for one arrow
    return baseWidth + arrowWidth;
  } else {
    // No arrows visible - add space for both arrows
    return baseWidth + (arrowWidth * 2);
  }
};



const parseTableToHTML = (text) => {
  const cleanedText = text.replace(/<br\/?>/g, "\n");
  const lines = cleanedText.trim().split("\n");

  if (lines.length < 2 || !lines[1].match(/^\|[-|]+\|$/)) {
    return null;
  }

  const headers = lines[0]
    .split("|")
    .slice(1, -1)
    .map((header) => header.trim());

  const rows = lines
    .slice(2)
    .filter((line) => line.includes("|"))
    .map((line) =>
      line
        .split("|")
        .slice(1, -1)
        .map((cell) => cell.trim())
    );

  let tableHTML =
    "<table style='width: 100%; border-collapse: collapse; margin-top: 16px;'>";
  tableHTML += "<thead><tr>";
  headers.forEach(
    (header) =>
      (tableHTML += `<th style='border: 1px solid #ddd; padding: 8px; text-align: left;'>${header}</th>`)
  );
  tableHTML += "</tr></thead><tbody>";
  rows.forEach((row) => {
    tableHTML += "<tr>";
    row.forEach(
      (cell) =>
        (tableHTML += `<td style='border: 1px solid #ddd; padding: 8px;'>${cell}</td>`)
    );
    tableHTML += "</tr>";
  });
  tableHTML += "</tbody></table>";

  return tableHTML;
};

export const formatTextToHTML = (text) => {
  const cleanedText = text.replace(/<br\/?>/g, "\n");
  const sections = cleanedText.split("\n\n");

  let htmlContent = "";

  sections.forEach((section) => {
    const tableHTML = parseTableToHTML(section);
    if (tableHTML) {
      htmlContent += tableHTML;
    } else {
      let formattedText = section.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>"
      );
      formattedText = formattedText.replace(/\*(.*?)\*/g, "<em>$1</em>");
      formattedText = formattedText.replace(/\n/g, "<br/>");
      htmlContent += `<pgit add >${formattedText}</p>`;
    }
  });

  return htmlContent;
};

export const getDomainName = (email) => {
  // Split the email by '@' to get the domain part
  const domain = email.toLowerCase().split('@')[1];

  // Check if the domain exists in the domainConfig
  if (domainConfig[domain]) {
    return domainConfig[domain].domainName;
  } else {
    return 'app';
  }
};




export const domainConfig = {
  'cdgbep.ma': {
    domainName: 'cdgbep',
    logo: 'https://juridia.ma/wp-content/uploads/2025/03/cdg_prevoyance.jpg',
    organizationName: "Branche Épargne - Prévoynace de la CDG",
    title:
      'Bienvenue dans l’interface dédiée aux collaborateurs de la Branche Épargne - Prévoyance de la CDG',
    paragraph:
      "Juridia est votre plateforme juridique de référence pour la recherche d'informations juridiques, l'analyse de textes de loi, la rédaction de documents juridiques et bien plus."
  },
  'mtpnet.gov.ma': {
    domainName: 'mtl',
    logo: 'https://juridia.ma/wp-content/uploads/2025/03/mtl.jpeg',
    organizationName: "Ministère du Transport et de la Logistique",
    title:
      'Bienvenue dans l’interface dédiée aux collaborateurs du Ministère du Transport et de la Logistique.',
    paragraph:
      "Juridia est votre plateforme juridique de référence pour la recherche d'informations juridiques, l'analyse de textes de loi, la rédaction de documents juridiques et bien plus."
  },
  'aviationcivile.gov.ma': {
    domainName: 'mtl',
    organizationName: "Ministère du Transport et de la Logistique",
    logo: 'https://juridia.ma/wp-content/uploads/2025/03/mtl.jpeg',
    title:
      'Bienvenue dans l’interface dédiée aux collaborateurs du Ministère du Transport et de la Logistique.',
    paragraph:
      "Juridia est votre plateforme juridique de référence pour la recherche d'informations juridiques, l'analyse de textes de loi, la rédaction de documents juridiques et bien plus."
  },
  'tgr.gov.ma': {
    domainName: 'mtl',
    organizationName: "Ministère du Transport et de la Logistique",
    logo: 'https://juridia.ma/wp-content/uploads/2025/03/mtl.jpeg',
    title:
      'Bienvenue dans l’interface dédiée aux collaborateurs du Ministère du Transport et de la Logistique.',
    paragraph:
      "Juridia est votre plateforme juridique de référence pour la recherche d'informations juridiques, l'analyse de textes de loi, la rédaction de documents juridiques et bien plus."
  },
  "juridia.ma": {
    domainName: 'app',
    logo: 'https://juridia.ma/wp-content/uploads/2025/03/logo_full.png',
    title:
      'Bienvenue dans l’interface dédiée aux collaborateurs de Juridia.',
    paragraph:
      "Interface pre-production pour développer, tester et intégrer des nouvelles fonctionnalités."
  },
}




export const translate = (key) => {
  return i18n.t(key);
};

export const docTypes = [
  { label: translate('Décret'), color: '#388e3c' },
  { label: translate('Dahir'), color: '#d32f2f' },
  { label: translate('Arrêté'), color: '#1976d2' },
  { label: translate('Décret-loi'), color: '#0288d1' },
  { label: translate('Décision'), color: '#f57c00' },
  { label: translate('Code'), color: '#8e24aa' },
  { label: translate('Annexe'), color: '#0288d1' },
  { label: translate('Avis'), color: '#ffb300' },
  { label: translate('Instruction'), color: '#43a047' },
  { label: translate('Loi'), color: '#f57c00' },
  { label: translate('Déclaration'), color: '#9c27b0' },
  { label: translate('Article'), color: '#0277bd' },
  { label: translate('Convention'), color: '#0288d1' },
  { label: translate('Contribution'), color: '#795548' },
  { label: translate('Dispositions'), color: '#388e3c' },
  { label: translate('Ordonnance'), color: '#1976d2' },
  { label: translate('Ordre'), color: '#8e24aa' },
  { label: translate('Régime'), color: '#ff7043' },
  { label: translate('Circulaire'), color: '#9c27b0' },
  { label: translate('Incitation'), color: '#ffeb3b' },
  { label: translate('Régularisation'), color: '#43a047' },
  { label: translate('Annulation'), color: '#d32f2f' },
  { label: translate('Liste'), color: '#2196f3' },
  { label: translate('Prime'), color: '#fbc02d' },
  { label: translate('Jurisprudence'), color: '#ff5722' }
];

export const getDocTypeColor = docType => {
  switch (docType) {
    case 'Décret':
      return '#388e3c' // Green
    case 'Dahir':
      return '#d32f2f' // Red
    case 'Arrêté':
      return '#1976d2' // Blue
    case 'Décret-loi':
      return '#0288d1' // Light Blue
    case 'Décision':
      return '#f57c00' // Orange
    case 'Code':
      return '#8e24aa' // Purple
    case 'Annexe':
      return '#0288d1' // Light Blue
    case 'Avis':
      return '#ffb300' // Yellow
    case 'Instruction':
      return '#43a047' // Dark Green
    case 'Loi':
      return '#f57c00' // Orange
    case 'Déclaration':
      return '#9c27b0' // Violet
    case 'Article':
      return '#0277bd' // Dark Blue
    case 'Convention':
      return '#0288d1' // Light Blue
    case 'Contribution':
      return '#795548' // Brown
    case 'Dispositions':
      return '#388e3c' // Green
    case 'Ordonnance':
      return '#1976d2' // Blue
    case 'Ordre':
      return '#8e24aa' // Purple
    case 'Régime':
      return '#ff7043' // Light Red
    case 'Circulaire':
      return '#9c27b0' // Violet
    case 'Incitation':
      return '#ffeb3b' // Yellow
    case 'Régularisation':
      return '#43a047' // Dark Green
    case 'Annulation':
      return '#d32f2f' // Red
    case 'Liste':
      return '#2196f3' // Blue
    case 'Prime':
      return '#fbc02d' // Amber
    case 'Jurisprudence':
      return '#ff5722' // Deep Orange
    default:
      return '#555' // Default color if not found
  }
}


export const checkAndMoveToStep = async (
  targetSelector,
  setCurrentStepIndex,
  nextStepIndex
) => {
  console.log(`Target selector: ${targetSelector}`);

  const waitForTarget = async (selector, maxAttempts = 10, delay = 500) => {
    let attempts = 0;

    while (attempts < maxAttempts) {
      const target = document.querySelector(selector);
      console.log(`Checking for target (${attempts + 1}/${maxAttempts}):`, target);

      if (target) {
        const rect = target.getBoundingClientRect();
        console.log("Bounding rect:", rect);

        // Ensure target is visible and has proper dimensions
        if (rect.width > 0 && rect.height > 0) {
          return target; // Target found and visible
        }
      }

      await new Promise((resolve) => setTimeout(resolve, delay)); // Wait for delay
      attempts++;
    }

    return null; // Target not found after maxAttempts
  };

  let target = null;

  if (targetSelector === ".assistant-message-container") {
    console.log("Waiting for .assistant-message-container...");
    target = await waitForTarget(".assistant-message-container", 30, 500); // Max 15 seconds
  } else {
    target = document.querySelector(targetSelector); // Immediate check for other targets
  }

  console.log("Final Target:", target);

  if (target) {
    const rect = target.getBoundingClientRect();
    console.log(`Target bounding rect:`, rect);

    if (rect.width > 0 && rect.height > 0) {
      console.log(`Moving to step ${nextStepIndex}...`);
      setTimeout(() => setCurrentStepIndex(nextStepIndex), 200); // Add stabilization delay
    } else {
      console.error(
        `Target for step ${nextStepIndex} exists but is not visible or has invalid dimensions.`
      );
    }
  } else {
    console.error(`Target for step ${nextStepIndex} is not mounted or visible.`);
  }
};



export const getCitations = (message) => {
  let citations = [];

  if (message.role === ROLE.ASSISTANT) {
    const sub_processes = message.sub_processes;

    if (sub_processes && sub_processes.length > 0) {
      const metadata_maps = sub_processes
        .map((sp) => sp.metadata_map)
        .filter((m) => !!m);

      const sub_questions = metadata_maps
        .map((m) => m?.sub_question)
        .filter((sq) => !!sq);

      const uniqueIds = {}; // Dictionary to keep track of unique citations
      const citationsList = sub_questions.map((sq) => sq?.citations || []);

      // Merge all citation lists into one array and remove duplicates
      citations = citationsList
        .reduce((acc, curr) => [...acc, ...curr], [])
        .filter((obj) => {
          const uniqueKey = `${obj?.file_name}_${obj?.section_title}`;
          if (!uniqueIds[uniqueKey]) {
            uniqueIds[uniqueKey] = true;
            return true;
          }
          return false;
        })
        // Sort by score in descending order
        .sort((a, b) => Number(b?.score || 0) - Number(a?.score || 0)); // Ensure score is a number
    }
  }

  return citations;
};

export const formatDateWithTimezoneOffset = (dateString) => {
  // Create a moment object from the date string
  const date = moment(dateString);

  // Format the date including the timezone offset
  // 'Z' in the format string includes the offset from UTC in +hh:mm or -hh:mm format
  return date.format("DD-MM-YYYY HH:mm:ss");
};

export const fromBackendDocumentToFrontend = (backendDocuments) => {
  // Sort by 'created_at' so that the de-dupe filter later keeps the oldest duplicate docs
  backendDocuments = _.sortBy(backendDocuments, "created_at");

  let frontendDocs = backendDocuments
    .filter((backendDoc) => "sec_document" in backendDoc.metadata_map)
    .map((backendDoc, index) => {
      // Define the color index, ensuring a maximum of 10 colors
      const colorIndex = index < 10 ? index : 0;

      return {
        id: backendDoc.id,
        url: backendDoc.url,
        short_title: backendDoc.metadata_map.adv_document.short_title,
        long_title: backendDoc.metadata_map.adv_document.long_title,
        file_name: backendDoc.metadata_map.adv_document.file_name,
        new_name: backendDoc.metadata_map.adv_document.new_name,
        date: backendDoc.metadata_map.adv_document.date,
        translated: backendDoc.metadata_map.adv_document.translated,
        ar_pr: backendDoc.metadata_map.adv_document.ar_pr,
        fr_pr: backendDoc.metadata_map.adv_document.fr_pr,
        doc_type: backendDoc.metadata_map.adv_document.doc_type,
        color: documentColors[colorIndex],
        // Add any additional fields like 'quarter' if needed
      };
    });

  // De-duplication logic to avoid duplicate documents based on a custom key
  const getDocDeDupeKey = (doc) => `${doc.ticker}`;

  frontendDocs = _.chain(frontendDocs)
    .sortBy(getDocDeDupeKey)
    .sortedUniqBy(getDocDeDupeKey)
    .value();

  return frontendDocs;
};
