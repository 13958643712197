import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';

// Add translations to i18n resources


const DeleteConfirmationDialog = ({ open, onClose, onConfirm, selectedCount, isDeleting }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const currentLang = i18n.language;

    // Helper function to handle pluralization for the custom message
    const getPluralizedText = () => {
        const isPlural = selectedCount > 1;

        if (isPlural) {
            return t("permanent_delete_warning_plural");
        } else {
            return t("permanent_delete_warning");
        }
    };

    return (
        <Dialog
            open={open}
            onClose={isDeleting ? undefined : onClose}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            p: 1,
                            mr: 2,
                            borderRadius: '50%',
                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                            color: theme.palette.error.main
                        }}
                    >
                        <WarningAmberIcon fontSize="large" />
                    </Box>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                        {t("confirm_delete_title")}
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent>
                <DialogContentText sx={{ color: theme.palette.text.primary, mb: 2 }}>
                    {selectedCount > 1
                        ? t("confirm_delete_multiple_contracts", { count: selectedCount })
                        : t("confirm_delete_contract")}
                </DialogContentText>

                <DialogContentText sx={{ color: theme.palette.text.secondary, fontSize: '0.9rem' }}>
                    {getPluralizedText()}
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    onClick={onClose}
                    color="inherit"
                    disabled={isDeleting}
                    sx={{
                        mr: 1,
                        textTransform: 'none',
                        fontWeight: 600,
                        color: theme.palette.text.secondary
                    }}
                >
                    {t("cancel")}
                </Button>
                <Button
                    onClick={onConfirm}
                    color="error"
                    disabled={isDeleting}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 1.5,
                        bgcolor: theme.palette.error.main,
                        '&:hover': {
                            bgcolor: theme.palette.error.dark
                        }
                    }}
                >
                    {isDeleting ? (
                        <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                    ) : null}
                    {isDeleting ? t("deleting") : t("delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;