import React from 'react';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { colors } from '../../../utils/colors';

const UserTypeSelector = ({ userType, handleUserTypeChange, disabled }) => {

    // If the selector is disabled (user is logged in), show a non-interactive display
    if (disabled) {
        return (
            <FormControl
                component="fieldset"
                sx={{
                    marginBottom: 3,
                    backgroundColor: 'rgba(0,0,0,0.02)',
                    borderRadius: '12px',
                    padding: 2
                }}
            >
                <FormLabel
                    component="legend"
                    sx={{
                        color: "#555",
                        fontWeight: 500,
                        fontSize: '0.9rem',
                        mb: 1
                    }}
                >
                    Type de souscripteur
                </FormLabel>
                <Box sx={{ mt: 1, ml: 1 }}>
                    {userType === 'physique' ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.primary
                            }}
                        >
                            <PersonIcon sx={{ mr: 1 }} /> Personne Physique
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.primary
                            }}
                        >
                            <BusinessIcon sx={{ mr: 1 }} /> Personne Morale
                        </Box>
                    )}
                </Box>
            </FormControl>
        );
    }

    // Regular interactive radio selector for non-logged in users
    return (
        <FormControl
            component="fieldset"
            sx={{
                marginBottom: 3,
                backgroundColor: 'rgba(0,0,0,0.02)',
                borderRadius: '12px',
                padding: 2
            }}
        >
            <FormLabel
                component="legend"
                sx={{
                    color: "#555",
                    fontWeight: 500,
                    fontSize: '0.9rem',
                    mb: 1
                }}
            >
                Choix du type de souscripteur
            </FormLabel>
            <RadioGroup
                row
                value={userType}
                onChange={handleUserTypeChange}
            >
                <FormControlLabel
                    value="physique"
                    control={
                        <Radio
                            sx={{
                                color: colors.primary,
                                '&.Mui-checked': {
                                    color: colors.primary,
                                }
                            }}
                        />
                    }
                    label={
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                fontWeight: userType === 'physique' ? 'bold' : 'normal',
                                color: userType === 'physique' ? colors.primary : 'inherit'
                            }}
                        >
                            <PersonIcon sx={{ mr: 1 }} /> Personne Physique
                        </Box>
                    }
                    sx={{ mr: 4 }}
                />
                <FormControlLabel
                    value="moral"
                    control={
                        <Radio
                            sx={{
                                color: colors.primary,
                                '&.Mui-checked': {
                                    color: colors.primary,
                                }
                            }}
                        />
                    }
                    label={
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                fontWeight: userType === 'moral' ? 'bold' : 'normal',
                                color: userType === 'moral' ? colors.primary : 'inherit'
                            }}
                        >
                            <BusinessIcon sx={{ mr: 1 }} /> Personne Morale
                        </Box>
                    }
                />
            </RadioGroup>
        </FormControl>
    );
};

export default UserTypeSelector;