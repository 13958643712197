import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Paper,
    Typography,
    Chip,
    CircularProgress,
    Tooltip,
    Alert,
    Snackbar,
    IconButton,
    Divider,
    Avatar,
    useMediaQuery,
    useTheme
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import RightPanel from "./RightPanel/RightPanel";
import DocumentEditor from "./DocumentEditor/DocumentEditor";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchContract } from "../../redux/actions/contractAction";
import EditorContext from "../../contexts/EditorContext";
import { useTranslation } from "react-i18next";

// Import CSS for proper rendering of contract content
import BackButton from "../../components/BackButton";
import { colors } from "../../utils/colors";

const ContractEditionScreen = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const { contractId } = useParams();

    const contract = useSelector((state) => state.contract.contract);
    const loading = useSelector((state) => state.contract.loading);
    const currentUser = useSelector((state) => state.auth.user.user);

    const [userRole, setUserRole] = useState('viewer');
    const [canEdit, setCanEdit] = useState(false);
    const [rightPanelOpen, setRightPanelOpen] = useState(!isMobile);
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    // Fetch contract data when component mounts
    useEffect(() => {
        if (contractId) {
            dispatch(fetchContract(contractId));
        }
    }, [contractId, dispatch]);

    // Determine user permissions when contract data loads
    useEffect(() => {
        if (contract && currentUser) {
            const collaborators = contract.collaborators || [];
            const userCollaborator = collaborators.find(
                collab => collab.id === currentUser.id
            );

            const role = userCollaborator?.role || 'viewer';
            setUserRole(role);

            // Only editor, reviewer, and creator can edit
            const hasEditPermission = ['editor', 'reviewer', 'creator'].includes(role);
            setCanEdit(hasEditPermission);

            // Show notification for view-only users
            if (!hasEditPermission) {
                setNotification({
                    open: true,
                    message: t("view_only_access_message"),
                    severity: 'info'
                });
            }
        }
    }, [contract, currentUser, t]);

    // Update right panel visibility on mobile/desktop change
    useEffect(() => {
        setRightPanelOpen(!isMobile);
    }, [isMobile]);

    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    const toggleRightPanel = () => {
        setRightPanelOpen(!rightPanelOpen);
    };

    const getRoleColor = (role) => {
        switch (role) {
            case 'editor':
                return '#1976d2';
            case 'reviewer':
                return '#9c27b0';
            case 'creator':
                return '#2e7d32';
            case 'signer':
                return '#ed6c02';
            default:
                return '#757575';
        }
    };

    if (loading) {
        return (
            <Box sx={{
                backgroundColor: "#f5f5f5",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>{t("loading_contract")}</Typography>
            </Box>
        );
    }

    // For editors, render only DocumentEditor without header/footer
    if (canEdit) {
        return (
            <Box sx={{ backgroundColor: colors.bg_color, height: "95vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Paper elevation={3} sx={{
                    height: "95vh",
                    width: "100%",
                    padding: 0,
                    pr: 0,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative", // Add position relative
                    overflow: "hidden" // Prevent content from overflowing
                }}>
                    <EditorContext.Provider value={editorRef}>
                        <Box sx={{
                            display: "flex",
                            bgcolor: "#fff",
                            borderRadius: "8px",
                            height: "100%", // Fix the height
                            overflow: "hidden",
                        }}>
                            <Box sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                position: "relative"
                            }}>
                                <DocumentEditor ref={editorRef} />
                            </Box>

                            {rightPanelOpen && (
                                <>
                                    {/* <Divider orientation="vertical" flexItem /> */}

                                    <Box sx={{
                                        flexShrink: 0,
                                        height: "100%", // Match height of parent
                                        overflow: "hidden"
                                    }}>
                                        <RightPanel
                                            contractId={contractId}
                                            editor={editorRef.current?.getEditor()}
                                        />
                                    </Box>
                                </>
                            )}

                            {/* Floating toggle button for right panel */}
                            {!rightPanelOpen && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        zIndex: 100
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={toggleRightPanel}
                                        color="primary"
                                        sx={{
                                            bgcolor: 'white',
                                            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                            '&:hover': { bgcolor: 'white', boxShadow: '0px 3px 6px rgba(0,0,0,0.15)' }
                                        }}
                                    >
                                        <MenuIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </EditorContext.Provider>

                    <Snackbar
                        open={notification.open}
                        autoHideDuration={6000}
                        onClose={handleCloseNotification}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handleCloseNotification}
                            severity={notification.severity}
                            sx={{ width: '100%' }}
                        >
                            {notification.message}
                        </Alert>
                    </Snackbar>
                </Paper>
            </Box>
        );
    }

    // For viewers, render the complete interface with document display
    return (
        <Box sx={{
            backgroundColor: "#f5f5f5",
            height: "95vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Paper
                elevation={3}
                sx={{
                    height: "95vh",
                    width: "100%",
                    padding: 0,
                    pr: 0,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    overflow: "hidden"
                }}
            >
                {/* Header for viewers */}
                <Box sx={{
                    p: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    zIndex: 10
                }}>
                    <BackButton navigate_to={'/contract'} />

                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        {contract?.title || t("untitled_contract")}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Chip
                            icon={<VisibilityIcon fontSize="small" />}
                            label={t("read_only")}
                            size="small"
                            color="default"
                            variant="outlined"
                        />
                    </Box>
                </Box>

                {/* Contract content for viewers */}
                <Box sx={{
                    flex: 1,
                    overflow: "auto",
                    p: 3,
                    bgcolor: "#fff"
                }}>
                    <Paper
                        elevation={0}
                        sx={{
                            maxWidth: 800,
                            mx: 'auto',
                            p: 4,
                            minHeight: '100%'
                        }}
                    >
                        <div
                            className="contract-content read-only"
                            dangerouslySetInnerHTML={{ __html: contract?.content || '' }}
                        />
                    </Paper>
                </Box>

                {/* Collaborators footer for viewers */}
                {contract?.collaborators && contract.collaborators.length > 0 && (
                    <Box sx={{
                        p: 1,
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: 'background.paper'
                    }}>
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                            {t("collaborators")}:
                        </Typography>

                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                            {contract.collaborators.map((collab, index) => (
                                <Tooltip
                                    key={collab.id || index}
                                    title={`${collab.first_name} ${collab.last_name} (${collab.role})`}
                                >
                                    <Avatar
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            fontSize: '0.875rem',
                                            bgcolor: getRoleColor(collab.role)
                                        }}
                                    >
                                        {collab.first_name?.charAt(0) || '?'}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </Box>
                    </Box>
                )}
            </Paper>

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification.severity}
                    sx={{ width: '100%' }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ContractEditionScreen;