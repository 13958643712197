import Image from '@tiptap/extension-image';
import { NodeView } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

// Custom Image Extension with resizing capabilities
export const ResizableImage = Image.extend({
    name: 'resizableImage',

    addAttributes() {
        return {
            ...this.parent?.(),
            // Core attributes
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            // Sizing attributes
            width: {
                default: null,
                parseHTML: element => element.getAttribute('width'),
                renderHTML: attributes => {
                    if (!attributes.width) {
                        return {};
                    }
                    return { width: attributes.width };
                },
            },
            height: {
                default: null,
                parseHTML: element => element.getAttribute('height'),
                renderHTML: attributes => {
                    if (!attributes.height) {
                        return {};
                    }
                    return { height: attributes.height };
                },
            },
            // Style attributes
            display: {
                default: 'block',
                parseHTML: element => element.style.display,
                renderHTML: attributes => {
                    return { style: `display: ${attributes.display || 'block'}` };
                },
            },
            float: {
                default: null,
                parseHTML: element => element.style.float,
                renderHTML: attributes => {
                    if (!attributes.float) {
                        return {};
                    }
                    return { style: `float: ${attributes.float}` };
                },
            },
            margin: {
                default: '0 auto',
                parseHTML: element => element.style.margin,
                renderHTML: attributes => {
                    return { style: `margin: ${attributes.margin || '0 auto'}` };
                },
            },
            borderRadius: {
                default: null,
                parseHTML: element => element.style.borderRadius,
                renderHTML: attributes => {
                    if (!attributes.borderRadius) {
                        return {};
                    }
                    return { style: `border-radius: ${attributes.borderRadius}` };
                },
            },
            border: {
                default: null,
                parseHTML: element => element.style.border,
                renderHTML: attributes => {
                    if (!attributes.border) {
                        return {};
                    }
                    return { style: `border: ${attributes.border}` };
                },
            },
            // For keeping track of original dimensions
            originalWidth: {
                default: null,
                parseHTML: element => element.dataset.originalWidth,
                renderHTML: attributes => {
                    if (!attributes.originalWidth) {
                        return {};
                    }
                    return { 'data-original-width': attributes.originalWidth };
                },
            },
            originalHeight: {
                default: null,
                parseHTML: element => element.dataset.originalHeight,
                renderHTML: attributes => {
                    if (!attributes.originalHeight) {
                        return {};
                    }
                    return { 'data-original-height': attributes.originalHeight };
                },
            },
        };
    },

    addNodeView() {
        return ({ node, editor, getPos }) => {
            const dom = document.createElement('div');
            dom.classList.add('resizable-image-wrapper');

            // Create the image element
            const img = document.createElement('img');
            img.src = node.attrs.src;
            if (node.attrs.alt) img.alt = node.attrs.alt;
            if (node.attrs.title) img.title = node.attrs.title;

            // Apply dimensions if they exist
            if (node.attrs.width) img.style.width = node.attrs.width;
            if (node.attrs.height) img.style.height = node.attrs.height;

            // Apply style attributes
            img.style.display = node.attrs.display || 'block';
            if (node.attrs.float) img.style.float = node.attrs.float;
            img.style.margin = node.attrs.margin || '0 auto';
            if (node.attrs.borderRadius) img.style.borderRadius = node.attrs.borderRadius;
            if (node.attrs.border) img.style.border = node.attrs.border;

            // Store original dimensions when the image loads
            img.onload = () => {
                if (!node.attrs.originalWidth) {
                    editor.commands.updateAttributes('resizableImage', {
                        originalWidth: img.naturalWidth,
                        originalHeight: img.naturalHeight,
                    });
                }
            };

            // Add the image to the wrapper
            dom.appendChild(img);

            // Add resize handles (8 handles for 8 directions)
            const directions = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'];
            directions.forEach(direction => {
                const handle = document.createElement('div');
                handle.classList.add('resize-handle', `handle-${direction}`);
                handle.dataset.direction = direction;
                dom.appendChild(handle);
            });

            // Apply some base styling to the wrapper
            dom.style.position = 'relative';
            dom.style.display = 'block'; // Change from 'inline-block' to 'block'
            // dom.style.textAlign = 'center'; // Add text-align center


            // Set up event listeners for resizing
            let startX, startY, startWidth, startHeight, isResizing = false;
            let currentDirection = '';

            // Find all handles
            const handles = dom.querySelectorAll('.resize-handle');

            handles.forEach(handle => {
                handle.addEventListener('mousedown', (e) => {
                    e.preventDefault();
                    isResizing = true;
                    currentDirection = handle.dataset.direction;

                    // Store starting position and dimensions
                    startX = e.clientX;
                    startY = e.clientY;
                    startWidth = img.offsetWidth;
                    startHeight = img.offsetHeight;

                    // Add event listeners for resizing
                    document.addEventListener('mousemove', handleResize);
                    document.addEventListener('mouseup', stopResize);

                    // Add resizing class to wrapper
                    dom.classList.add('resizing');
                });
            });

            const handleResize = (e) => {
                if (!isResizing) return;

                const deltaX = e.clientX - startX;
                const deltaY = e.clientY - startY;

                let newWidth = startWidth;
                let newHeight = startHeight;

                // Calculate new dimensions based on resize direction
                if (currentDirection.includes('e')) newWidth = startWidth + deltaX;
                if (currentDirection.includes('w')) newWidth = startWidth - deltaX;
                if (currentDirection.includes('s')) newHeight = startHeight + deltaY;
                if (currentDirection.includes('n')) newHeight = startHeight - deltaY;

                // Maintain aspect ratio if shift key is pressed
                if (e.shiftKey) {
                    const aspectRatio = startWidth / startHeight;
                    if (Math.abs(deltaX) > Math.abs(deltaY)) {
                        newHeight = newWidth / aspectRatio;
                    } else {
                        newWidth = newHeight * aspectRatio;
                    }
                }

                // Apply new dimensions
                img.style.width = `${Math.max(20, newWidth)}px`;
                img.style.height = `${Math.max(20, newHeight)}px`;
            };

            const stopResize = () => {
                if (!isResizing) return;

                isResizing = false;
                currentDirection = '';

                // Remove event listeners
                document.removeEventListener('mousemove', handleResize);
                document.removeEventListener('mouseup', stopResize);

                // Remove resizing class
                dom.classList.remove('resizing');

                // Update the node attributes with new dimensions
                if (getPos && editor.isEditable) {
                    editor.commands.command(({ tr }) => {
                        tr.setNodeMarkup(getPos(), null, {
                            ...node.attrs,
                            width: `${img.offsetWidth}px`,
                            height: `${img.offsetHeight}px`,
                        });
                        return true;
                    });
                }
            };

            // Double click to reset to original dimensions
            img.addEventListener('dblclick', () => {
                if (node.attrs.originalWidth && node.attrs.originalHeight) {
                    if (getPos && editor.isEditable) {
                        editor.commands.command(({ tr }) => {
                            tr.setNodeMarkup(getPos(), null, {
                                ...node.attrs,
                                width: `${node.attrs.originalWidth}px`,
                                height: `${node.attrs.originalHeight}px`,
                            });
                            return true;
                        });
                    }
                }
            });

            return {
                dom,
                update: (updatedNode) => {
                    if (updatedNode.type !== node.type) return false;

                    // Update attributes
                    img.src = updatedNode.attrs.src;
                    if (updatedNode.attrs.alt) img.alt = updatedNode.attrs.alt;
                    if (updatedNode.attrs.title) img.title = updatedNode.attrs.title;

                    // Update dimensions
                    if (updatedNode.attrs.width) img.style.width = updatedNode.attrs.width;
                    if (updatedNode.attrs.height) img.style.height = updatedNode.attrs.height;

                    // Update style attributes
                    img.style.display = updatedNode.attrs.display || 'block';
                    if (updatedNode.attrs.float) img.style.float = updatedNode.attrs.float;
                    img.style.margin = updatedNode.attrs.margin || '0 auto';
                    if (updatedNode.attrs.borderRadius) img.style.borderRadius = updatedNode.attrs.borderRadius;
                    if (updatedNode.attrs.border) img.style.border = updatedNode.attrs.border;

                    return true;
                },
                destroy: () => {
                    // Clean up event listeners
                    img.onload = null;
                    img.removeEventListener('dblclick', null);
                    handles.forEach(handle => {
                        handle.removeEventListener('mousedown', null);
                    });
                },
            };
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('resizableImagePlugin'),
                props: {
                    handleDOMEvents: {
                        mouseover(view, event) {
                            // Show resize handles when hovering over images
                            if (event.target.tagName === 'IMG' || event.target.closest('.resizable-image-wrapper')) {
                                const wrapper = event.target.closest('.resizable-image-wrapper');
                                if (wrapper) {
                                    wrapper.classList.add('hovered');
                                }
                            }
                        },
                        mouseout(view, event) {
                            // Hide resize handles when not hovering over images
                            const relatedTarget = event.relatedTarget;
                            if (
                                event.target.tagName === 'IMG' ||
                                event.target.closest('.resizable-image-wrapper')
                            ) {
                                if (!relatedTarget || !relatedTarget.closest('.resizable-image-wrapper')) {
                                    const wrapper = event.target.closest('.resizable-image-wrapper');
                                    if (wrapper && !wrapper.classList.contains('resizing')) {
                                        wrapper.classList.remove('hovered');
                                    }
                                }
                            }
                        },
                    },
                },
            }),
        ];
    },
});