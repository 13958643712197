import { fromBackendDocumentToFrontend } from '../utils/utils'
import { backendUrl } from '../config'
import { BrowserRouter as Navigate } from 'react-router-dom'
import { store } from '../redux/store'
import { logout } from '../redux/actions/authAction'
import { enqueueSnackbar } from 'notistack'

class BackendClient {
  constructor(dispatch) {
    this.dispatch = dispatch // Store the dispatch function
    this.isSnackbarVisible = false
  }

  saveUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser() {
    const userString = localStorage.getItem('user')
    return userString ? JSON.parse(userString) : null
  }

  removeUser() {
    localStorage.removeItem('user')
  }

  saveToken(token) {
    localStorage.setItem('authToken', JSON.stringify(token))
  }

  deleteToken() {
    localStorage.removeItem('authToken')
  }

  getToken() {
    // const tokenString = localStorage.getItem("authToken");
    // if (!tokenString) return null;
    // return JSON.parse(tokenString);
    const state = store.getState()
    const token = state.auth.user.token
    return token
  }

  authHeader() {
    try {
      const tokenPayload = this.getToken()
      if (!tokenPayload) return undefined
      return {
        Authorization: `${tokenPayload.token_type
          .charAt(0)
          .toUpperCase()}${tokenPayload.token_type.slice(1)} ${tokenPayload.access_token
          }`,

        'Content-Type': 'application/json'
      }
    } catch {
      return {
        'Content-Type': 'application/json'
      }
    }
  }

  // Show a snackbar if one isn't already visible
  showSnackbar(message, variant = 'error') {
    console.log('showing snackbar', this.isSnackbarVisible)

    if (!this.isSnackbarVisible) {
      this.isSnackbarVisible = true // Set flag to true
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: null, // Don't auto-hide
        persist: true, // Keep the snackbar visible
        onClose: () => {
          this.isSnackbarVisible = false // Reset flag when snackbar is closed
        }
      })
    }
  }

  async handleResponse(res) {
    if (res.status === 503) {
      // Server is in maintenance
      this.showSnackbar(
        'Le serveur est actuellement en maintenance. Veuillez réessayer plus tard.'
      )
      throw new Error('Le serveur est en maintenance')
    }

    if (res.status === 403) {
      console.log(
        "Ce compte n'est pas autorisé par les administrateurs, veuillez contacter le support"
      )
      this.dispatch(logout())
      window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.')
    }

    if (res.status === 401) {
      console.log(
        'La session a expiré ou le jeton est invalide. Déconnexion en cours.'
      )
      this.dispatch(logout())
    }

    if (!res.ok) {
      const errorData = await res.json()
      throw {
        status: res.status,
        message: errorData.detail || `HTTP error! status: ${res.status}`
      }
    }

    return res
  }

  async get(endpoint) {
    const url = backendUrl + endpoint
    const headers = this.authHeader()

    try {
      const res = await fetch(url, { headers })
      return await this.handleResponse(res)
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        // Network error or server down
        this.showSnackbar(
          'Impossible de se connecter. Veuillez vérifier votre connexion Internet ou réessayer plus tard.'
        )
      }
      throw error
    }
  }

  async post(endpoint, body) {
    const url = backendUrl + endpoint
    const headers = this.authHeader()

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: headers || { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
      return await this.handleResponse(res)
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        // Network error or server down
        this.showSnackbar(
          'Impossible de se connecter. Veuillez vérifier votre connexion Internet ou réessayer plus tard.'
        )
      }
      throw error
    }
  }

  async delete(endpoint, body) {
    const url = backendUrl + endpoint;
    const headers = this.authHeader();

    try {
      const res = await fetch(url, {
        method: 'DELETE',
        headers: headers || { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      return await this.handleResponse(res);
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        // Network error or server down
        this.showSnackbar(
          'Impossible de se connecter. Veuillez vérifier votre connexion Internet ou réessayer plus tard.'
        );
      }
      throw error;
    }
  }

  async put(endpoint, body) {
    const url = backendUrl + endpoint;
    const headers = this.authHeader();

    try {
      const res = await fetch(url, {
        method: 'PUT',
        headers: headers || { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      return await this.handleResponse(res);
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        // Network error or server down
        this.showSnackbar(
          'Impossible de se connecter. Veuillez vérifier votre connexion Internet ou réessayer plus tard.'
        );
      }
      throw error;
    }
  }

  // async sendMessage(conversationId, messageContent) {
  //   const endpoint = `api/conversation/${conversationId}/message`;
  //   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   const payload = { user_message: messageContent, timezone };
  //   const res = await this.post(endpoint, payload);

  //   if (!res.ok) {
  //     throw new Error(
  //       `Failed to send message: HTTP error! status: ${res.status}`
  //     );
  //   }
  //   return res.json();
  // }

  async updateMessage(conversationId, messageId, content) {
    const endpoint = `api/conversation/${conversationId}/message/${messageId}`; // Updated endpoint URL

    const headers = this.authHeader();
    try {
      const res = await fetch(backendUrl + endpoint, {
        method: 'PUT',
        headers: headers || { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content }),
      });

      if (res.status === 403) {
        console.log('Unauthorized access');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to update message: HTTP status ${res.status}`
        );
      }

      return await res.json();  // Assuming you need the response from the server
    } catch (error) {
      console.error('Error updating message:', error.message);
      throw error;
    }
  }

  async updateBoarded(userId, isBoarded) {
    const endpoint = 'api/user/update_is_boarded' // API endpoint
    const payload = {
      user_id: userId,
      is_boarded: isBoarded
    }

    try {
      const res = await this.post(endpoint, payload)

      if (!res.ok) {
        const errorData = await res.json()

        // Handle unexpected response formats
        if (Array.isArray(errorData)) {
          throw new Error(
            `Backend returned errors: ${JSON.stringify(errorData)}`
          )
        } else {
          throw new Error(
            errorData.detail ||
            `Failed to update 'is_boarded': HTTP status ${res.status}`
          )
        }
      }

      return await res.json() // Return the success response
    } catch (error) {
      console.error("Error while updating 'is_boarded':", error.message)
      throw error
    }
  }

  async login(email, password) {
    const endpoint = 'api/auth/login'
    const payload = { email, password }

    const res = await this.post(endpoint, payload)
    const data = await res.json()
    return data
  }

  async logout() {
    this.removeUser()
    this.deleteToken()
    // await Router.replace("/login"); // Redirige vers la page de connexion
    Navigate('/login')
  }

  // Add to BackendClient class in backendClient.js
  async forgotPassword(email, domainName) {
    const endpoint = 'api/auth/forgot-password'
    const payload = { email, domain_name: domainName }

    const res = await this.post(endpoint, payload)

    if (!res.ok) {
      const errorData = await res.json()
      throw new Error(
        errorData.detail || `Failed to send password reset email: ${res.status}`
      )
    }

    return res
  }

  async resetPassword(token, newPassword) {
    const endpoint = 'api/auth/reset-password'
    const payload = { token, new_password: newPassword }

    const res = await this.post(endpoint, payload)

    if (!res.ok) {
      const errorData = await res.json()
      throw new Error(
        errorData.detail ||
        `Failed to reset password: HTTP status ${res.status}`
      )
    }

    return res
  }

  async signUp(
    last_name,
    first_name,
    organization_name,
    phone_number,
    email,
    password,
    userType
  ) {
    const endpoint = 'api/auth/register'
    const payload = {
      last_name,
      first_name,
      organization_name,
      phone_number,
      email,
      password,
      user_type: userType
    }

    try {
      const res = await this.post(endpoint, payload)
      console.log('res', res)

      if (!res.ok) {
        const errorData = await res.json()

        // Create structured error object with both status and message
        const error = new Error(errorData.detail || "Erreur lors de l'inscription.")
        error.status = res.status
        error.data = errorData

        // Handle specific error cases for UI feedback
        if (res.status === 409) {
          // User already exists
          this.showSnackbar(
            'Un utilisateur avec cet email existe déjà.',
            'error'
          )
        } else if (res.status === 400) {
          // Bad request (e.g., invalid input)
          this.showSnackbar(
            "Données d'inscription invalides. Veuillez vérifier vos informations.",
            'error'
          )
        } else if (res.status === 403) {
          // Forbidden - User not in organization with active subscription
          this.showSnackbar(
            "L'inscription n'est disponible que pour les membres d'organisations approuvées avec un abonnement actif.",
            'error'
          )
        } else if (res.status === 500) {
          // Server error
          this.showSnackbar(
            'Une erreur interne est survenue. Veuillez réessayer plus tard.',
            'error'
          )
        } else {
          // Other errors
          this.showSnackbar(
            errorData.detail || "Erreur lors de l'inscription.",
            'error'
          )
        }

        throw error // Throw the enhanced error object
      }

      const data = await res.json()
      return data
    } catch (error) {
      console.log('Error in signUp:', error)

      // If this is a network error and not a response error we handled above
      if (error.message === 'Failed to fetch') {
        // Network error or server down
        this.showSnackbar(
          'Impossible de se connecter au serveur. Veuillez vérifier votre connexion Internet ou réessayer plus tard.',
          'error'
        )

        // Create a structured error for Redux
        const networkError = new Error('Impossible de se connecter au serveur.')
        networkError.status = 0 // Use 0 to indicate network error
        throw networkError
      }

      // Re-throw the error (either our enhanced error or original error)
      throw error
    }
  }

  async createConversation(user_id) {
    const endpoint = 'api/conversation/create'
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const payload = { user_id, timezone }

    const res = await this.post(endpoint, payload)

    if (!res.ok) {
      const errorData = await res.json()
      throw new Error(
        errorData.detail ||
        `Échec de la création de la conversation : statut HTTP ${res.status}`
      )
    }

    const data = await res.json()
    return data
  }

  async fetchUser(userId) {
    const endpoint = 'api/auth/fetch-user' // API endpoint for fetching user
    const payload = { user_id: userId }

    try {
      const res = await this.post(endpoint, payload) // Reusing the POST method

      if (!res.ok) {
        const errorData = await res.json()

        // Handle unauthorized or token issues
        if (res.status === 401 || res.status === 403) {
          console.error('Token is invalid or expired. Logging out...')
          this.dispatch(logout()) // Dispatch logout action
          throw new Error(errorData.detail || 'Invalid or expired token.')
        }

        // Handle other errors
        throw new Error(
          errorData.detail || `Failed to fetch user: HTTP status ${res.status}`
        )
      }

      // Parse and return the successful response
      const data = await res.json()

      return data
    } catch (error) {
      console.error('Error while fetching user:', error.message)
      // throw error; // Re-throw the error for further handling
    }
  }

  async fetchUsersByOrganization(organizationId) {
    const endpoint = `api/user/organization/${organizationId}`
    const res = await this.get(endpoint)

    if (!res.ok) {
      throw new Error(`Failed to fetch users: HTTP status ${res.status}`)
    }

    const data = await res.json()
    return data
  }

  async shareConversation(conversationId, userIds, userDomain) {
    const endpoint = `api/conversation/share`
    const payload = { conversation_id: conversationId, users_ids: userIds, domain_name: userDomain }
    console.log('payload', payload)
    const res = await this.post(endpoint, payload)

    if (!res.ok) {
      throw new Error(`Failed to share conversation: ${res.status}`)
    }
    return res
  }

  async deleteConversations(conversationIds) {
    const endpoint = `api/conversation/delete`
    const payload = { conversation_ids: conversationIds }
    const res = await this.post(endpoint, payload) // Assuming you have a POST method
    console.log('res', res)
    if (!res.ok) {
      throw new Error(`Failed to delete conversations: ${res.status}`)
    }
    return res
  }

  async fetchUserConversations(user_id, limit = 5, offset = 0) {
    const endpoint = `api/conversation/${user_id}/conversations?limit=${limit}&offset=${offset}`
    try {
      const res = await this.get(endpoint)

      if (!res.ok) {
        // Handle the error by throwing an exception
        throw new Error(
          `Échec de la récupération des conversations : statut HTTP ${res.status}`
        )
      }

      const data = await res.json()

      return data.map(conversation => ({
        id: conversation.id,
        title: conversation.title,
        users: conversation.users,
        updated_at: conversation.updated_at,
        created_at: conversation.created_at
        // messages: conversation.messages,
        // documents: fromBackendDocumentToFrontend(conversation.documents),
      }))
    } catch (error) {
      console.error(`Erreur : ${error.message}`)
      throw error // Propagate the error to be handled by the caller
    }
  }

  async fetchConversation(id) {
    const endpoint = `api/conversation/${id}`
    const res = await this.get(endpoint)

    if (!res.ok) {
      // throw new Error(
      //   `Failed to fetch conversation: HTTP status ${res.status}`
      // );
      return []
    }

    const data = await res.json()

    // Reverse messages only if first message is from assistant
    const messages =
      data.messages.length > 0 && data.messages[0].role === 'assistant'
        ? data.messages.reverse()
        : data.messages

    return {
      ...data,
      messages,
      // messages: data.messages.reverse(),
      // messages: data.messages,
      documents: fromBackendDocumentToFrontend(data.documents)
    }
  }

  async getDocumentSas(containerName, blobName) {
    const endpoint = `api/document/generate-sas`
    const body = { containerName, blobName }
    const res = await this.post(endpoint, body)
    const data = await res.json()
    return data
  }

  async translateHtml(fileUrl, targetLanguage) {
    const endpoint = 'api/document/translate-html' // Updated endpoint path
    console.log('fileUrl', fileUrl)
    const payload = {
      file_url: fileUrl,
      target_language: targetLanguage
    }

    try {
      const res = await this.post(endpoint, payload)

      if (res.status === 403) {
        console.log(
          'This account is not allowed by admins, please contact support'
        )
        this.dispatch(logout())
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.')
        throw new Error('Unauthorized access')
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.')
        this.dispatch(logout())
        window.alert(
          'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
        )
        throw new Error('Session expired')
      }

      if (!res.ok) {
        const errorData = await res.json()
        throw new Error(
          errorData.detail ||
          `Failed to translate document: HTTP status ${res.status}`
        )
      }

      return await res.json()
    } catch (error) {
      console.error('Translation error:', error)
      throw error
    }
  }

  async fetchDocuments() {
    const endpoint = `api/document/`
    const res = await this.get(endpoint)
    const data = await res.json()
    const docs = fromBackendDocumentToFrontend(data)
    return docs
  }

  async post_form(endpoint, formData) {
    const url = backendUrl + endpoint
    const res = await fetch(url, {
      method: 'POST',
      body: formData
    })

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }
    return res
  }

  async upload_documents(files) {
    const endpoint = `api/document/upload`
    const formData = new FormData()

    files.forEach(file => {
      formData.append('files', file)
    })

    const res = await this.post_form(endpoint, formData)
    const data = await res.json()

    const docs = fromBackendDocumentToFrontend(data)
    return docs
  }

  async exportDocument(content, fileType, filename) {

    try {
      if (fileType === 'pdf') {
        // For direct PDF downloads
        const response = await fetch(content)
        if (!response.ok) {
          throw new Error('Failed to fetch PDF content')
        }
        return await response.blob()
      } else {
        // For HTML to PDF conversion
        const url = backendUrl + 'api/document/export'
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const response = await fetch(url, {
          method: 'POST',
          headers: this.authHeader(),
          body: JSON.stringify({
            html_content: content,
            export_to: 'pdf',
            timezone,
            filename
          })
        })

        if (response.status === 403) {
          console.log(
            'This account is not allowed by admins, please contact support'
          )
          this.dispatch(logout())
          throw new Error('unauthorized_access')
        }

        if (response.status === 401) {
          console.log('Session has expired or the token is invalid')
          this.dispatch(logout())
          throw new Error('session_expired')
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        return await response.blob()
      }
    } catch (error) {
      console.error('Export error:', error)
      throw error
    }
  }

  async sendSupport(
    supportObject,
    supportDescription,
    attachedFile,
    userEmail
  ) {
    const url = `${backendUrl}api/user/submit_support`

    try {
      // Prepare FormData for file upload
      const formData = new FormData()
      formData.append('supportObject', supportObject)
      formData.append('supportDescription', supportDescription)
      formData.append('userEmail', userEmail)

      if (attachedFile) {
        formData.append('attachedFile', attachedFile)
      }

      console.log('formData', formData)

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`)
      }

      const headers = this.authHeader()

      console.log('headers', headers)
      const res = await fetch(url, {
        method: 'POST',
        // headers: headers, // Ensure appropriate headers for authentication
        body: formData
      })

      console.log('res', res)

      if (res.status === 403) {
        console.log(
          'This account is not allowed by admins, please contact support'
        )
        this.dispatch(logout())
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.')
        throw new Error('Unauthorized access')
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.')
        this.dispatch(logout())
        window.alert(
          'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
        )
        throw new Error('Session expired')
      }

      if (!res.ok) {
        const errorData = await res.json()
        throw new Error(
          errorData.detail ||
          `Failed to send support: HTTP status ${res.status}`
        )
      }

      const data = await res.json()
      return data
    } catch (error) {
      console.error('Error while sending support:', error.message)
      throw error
    }
  }

  async exportMessages(messages, export_to, email) {
    const url = backendUrl + 'api/document/export-messages';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = this.authHeader();
    const body = {
      messages,
      export_to,
      timezone,
    };

    if (email) {
      body.email = email; // Add email only if it's present
    }

    const res = await fetch(url, {
      method: 'POST',
      headers: headers || { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (res.status === 403) {
      console.log('This account is not allowed by admins, please contact support');
      this.dispatch(logout());
      window.alert(
        'Utilisateur non autorisé. Veuillez contacter le support au +212610133995.'
      );
      return;
    }

    if (res.status === 401) {
      console.log('Session has expired or the token is invalid. Logging out.');
      this.dispatch(logout());
      window.alert(
        'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
      );
      return;
    }

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const blob = await res.blob();
    return blob;
  }

  async downloadContract(contractId, format = 'pdf') {
    // Validate format parameter
    if (!['pdf', 'docx'].includes(format)) {
      throw new Error('Invalid format. Must be "pdf" or "docx"');
    }

    try {
      const url = backendUrl + `api/document/download-contract/${contractId}?format=${format}`;
      const headers = this.authHeader();

      const res = await fetch(url, {
        method: 'GET',
        headers: headers,
        responseType: 'blob'
      });

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (res.status === 404) {
        throw new Error('Contract not found');
      }

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const blob = await res.blob();
      return blob;
    } catch (error) {
      console.error('Error downloading contract:', error);
      throw error;
    }
  }


  async exportContract(content, export_to, layoutInfo = null) {
    const url = backendUrl + 'api/document/export-contract';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = this.authHeader();

    // Create the request body with layout information
    const body = {
      content,
      export_to,
      timezone,
    };

    // Add layout information if provided
    if (layoutInfo) {
      body.layoutInfo = layoutInfo;
    }

    const res = await fetch(url, {
      method: 'POST',
      headers: headers || { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (res.status === 403) {
      console.log('This account is not allowed by admins, please contact support');
      this.dispatch(logout());
      window.alert(
        'Utilisateur non autorisé. Veuillez contacter le support au +212610133995.'
      );
      return;
    }

    if (res.status === 401) {
      console.log('Session has expired or the token is invalid. Logging out.');
      this.dispatch(logout());
      window.alert(
        'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
      );
      return;
    }

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const blob = await res.blob();
    return blob;
  }

  async downloadDocument(documentPath) {
    const headers = this.authHeader()
    const url =
      backendUrl +
      `api/document/gcp/{document_id}?document_path=${documentPath}`

    const res = await fetch(url, {
      method: 'POST',
      headers: headers || { 'Content-Type': 'application/json' },
      body: ''
    })

    if (res.status === 403) {
      console.log(
        'This account is not allowed by admins, please contact support'
      )
      this.dispatch(logout())
      window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.')
    }

    if (res.status === 401) {
      console.log('Session has expired or the token is invalid. Logging out.')
      // Dispatch the Redux logout action here
      this.dispatch(logout())
      window.alert(
        'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
      )
    }

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }

    const blob = await res.blob()
    return blob
  }

  //   DASHBOARD
  async getConversationsAndUsers(organization_name = null) {
    const endpoint = `api/conversation/conversations-and-users`

    const body = {
      organization_name: organization_name || null // Send null if no organization name
    }

    const res = await this.post(endpoint, body) // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations and users: HTTP status ${res.status}`
      )
    }

    const data = await res.json()
    return {
      totalConversations: data.total_conversations,
      totalUsers: data.total_users
    }
  }

  async getConversationsCountByUser(user_id = null) {
    const endpoint = `api/conversation/conversations-count`

    const body = {
      user_id: user_id || null // Send null if no organization name
    }

    const res = await this.post(endpoint, body) // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations and users: HTTP status ${res.status}`
      )
    }

    const data = await res.json()
    return {
      totalConversations: data.total_conversations
    }
  }

  // Fetch conversations over time
  async getConversationsOverTime(organization_name = null) {
    const endpoint = 'api/conversation/conversations-over-time'

    const body = {
      organization_name: organization_name || null // Send null if no organization name
    }

    const res = await this.post(endpoint, body) // Use POST method now

    if (!res.ok) {
      throw new Error(
        `Failed to fetch conversations over time: HTTP status ${res.status}`
      )
    }

    const data = await res.json()
    return data.map(conversationData => ({
      id: conversationData.id,
      data: conversationData.data
    }))
  }

  // Fetch total searches and users
  async getSearchesAndUsers(organization_name = null) {
    const endpoint = `api/search/searches-and-users`

    const body = {
      organization_name: organization_name || null // Send null if no organization name
    }

    const res = await this.post(endpoint, body) // Use POST method

    if (!res.ok) {
      throw new Error(
        `Failed to fetch searches and users: HTTP status ${res.status}`
      )
    }

    const data = await res.json()
    return {
      totalSearchs: data.total_searches,
      totalUsersSearchs: data.total_users
    }
  }

  // Fetch searches over time
  async getSearchesOverTime(organization_name = null) {
    const endpoint = 'api/search/searches-over-time'

    const body = {
      organization_name: organization_name || null // Send null if no organization name
    }

    const res = await this.post(endpoint, body) // Use POST method

    if (!res.ok) {
      throw new Error(
        `Failed to fetch searches over time: HTTP status ${res.status}`
      )
    }

    const data = await res.json()
    return data.map(searchData => ({
      id: searchData.id,
      data: searchData.data
    }))
  }

  // Serarch

  async searchUserConversations(user_id, searchTerm) {
    const endpoint = `api/conversation/${user_id}/conversations/search?search_term=${encodeURIComponent(
      searchTerm
    )}`
    const res = await this.get(endpoint)

    if (!res.ok) {
      throw new Error(
        `Failed to search conversations: HTTP status ${res.status}`
      )
    }

    return res
  }

  async search(query, mode, signal) {
    const endpoint = 'api/search/' // Update the endpoint as needed
    const payload = { query, mode: mode } // Add user_id to the payload

    try {
      // const res = await this.post(endpoint, payload, { signal });
      const res = await fetch(backendUrl + endpoint, {
        method: 'POST',
        headers: this.authHeader(),
        body: JSON.stringify(payload),
        signal: signal
      });

      if (!res.ok) {
        // Handle specific HTTP status codes
        if (res.status === 404) {
          const error = new Error('Aucun résultat trouvé.');
          error.status = 404;
          throw error;
        } else {
          const errorData = await res.json();
          const error = new Error(errorData.detail || `Failed to search: HTTP status ${res.status}`);
          error.status = res.status;
          throw error;
        }
      }

      return res;
    } catch (error) {
      // If it's an AbortError, we don't want to treat it as a normal error
      if (error.name === 'AbortError') {
        console.log('Search was cancelled');
        // Create a custom response for aborted requests
        const abortResponse = new Response(JSON.stringify({ detail: 'Search cancelled' }), {
          status: 499, // Client Closed Request (non-standard but commonly used)
          headers: { 'Content-Type': 'application/json' }
        });
        return abortResponse;
      }

      console.error('Search error:', error);
      // Re-throw the error to be handled by the caller
      throw error;
    }
  }




  async updateMessageLike(message_id, value) {
    const endpoint = `api/conversation/update-message-like/` // Adjust the endpoint as per your API route

    const payload = {
      message_id: message_id,
      value: value
    }
    const res = await this.post(endpoint, payload)
    const data = await res.json()
    return data
  }

  async getRecentSearches(userId, offset = 0) {
    const endpoint = `api/search/recent?offset=${offset}&user_id=${userId}`
    const res = await this.get(endpoint)
    if (!res.ok)
      throw new Error(`Failed to fetch recent searches: ${res.status}`)
    return res
  }

  async getAutocompleteResults(query) {
    const endpoint = `api/search/autocomplete?query=${encodeURIComponent(
      query
    )}`
    const res = await this.get(endpoint)
    if (!res.ok)
      throw new Error(`Failed to fetch autocomplete results: ${res.status}`)
    return res
  }

  async updatePrompt(userId, type, prompt_text) {
    const endpoint = `api/prompt/update`
    const payload = { user_id: userId, type, prompt_text } // Use user_id and type in the payload

    const headers = this.authHeader()
    const res = await fetch(backendUrl + endpoint, {
      method: 'PUT',
      headers: headers || { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })

    if (res.status === 403) {
      console.log(
        'This account is not allowed by admins, please contact support'
      )
      this.dispatch(logout())
      window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.')
    }

    if (res.status === 401) {
      console.log('Session has expired or the token is invalid. Logging out.')
      // Dispatch the Redux logout action here
      this.dispatch(logout())
      window.alert(
        'Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.'
      )
    }

    if (!res.ok) {
      const errorData = await res.json()
      throw {
        status: res.status,
        message:
          errorData.detail ||
          `Failed to update prompt: HTTP status ${res.status}`
      }
    }

    return res.json() // Return the updated prompt
  }

  async getPrompt(userId, promptType = null) {
    const endpoint = `api/prompt/get?user_id=${userId}${promptType ? `&prompt_type=${encodeURIComponent(promptType)}` : ''
      }`

    const res = await this.get(endpoint)

    if (!res.ok) {
      throw new Error(`Failed to fetch prompt: HTTP status ${res.status}`)
    }

    return res.json() // Parse and return the JSON data
  }

  async getSubscriptionInfo(organizationId) {
    const endpoint = `api/organization/subscription/${organizationId}`

    try {
      const res = await this.get(endpoint)

      if (!res.ok) {
        // Handle specific error cases
        if (res.status === 404) {
          throw new Error('Subscription not found for the specified user')
        } else {
          const errorData = await res.json()
          throw new Error(errorData.detail || `Failed to fetch subscription: HTTP status ${res.status}`)
        }
      }

      const data = await res.json()

      return data
    } catch (error) {
      console.error('Error while fetching subscription info:', error.message)
      throw error
    }
  }


  async downloadInvoice(billingId) {
    const endpoint = `api/payment/download-invoice/${billingId}`;

    try {
      // Get authentication headers
      const headers = this.authHeader();

      // Create the URL with the backend URL and endpoint
      const url = backendUrl + endpoint;

      // Use fetch API to download the file
      const response = await fetch(url, {
        method: 'GET',
        headers: headers
      });

      if (response.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (response.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || `Failed to download invoice: HTTP status ${response.status}`
        );
      }

      // Get the filename from the Content-Disposition header if available
      let filename = 'facture-juridia.pdf';
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;

      // Append the link to the body, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the blob URL
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
      }, 100);

      return true;
    } catch (error) {
      console.error('Error while downloading invoice:', error.message);
      throw error;
    }
  }



  async getContractTemplates() {
    const endpoint = 'api/contract/contract-templates/';

    try {
      const res = await this.get(endpoint);

      if (!res.ok) {
        // Handle specific error cases
        if (res.status === 404) {
          throw new Error('No contract templates found');
        } else {
          const errorData = await res.json();
          throw new Error(errorData.detail || `Failed to fetch contract templates: HTTP status ${res.status}`);
        }
      }

      return await res.json();
    } catch (error) {
      console.error('Error while fetching contract templates:', error.message);
      throw error;
    }
  }


  async createContract(title, templateId, creatorId, description = null, isEmptyContract = false) {
    const endpoint = 'api/contract/';
    const payload = {
      title: title,
      template_id: isEmptyContract ? null : templateId,
      creator_id: creatorId,
      description: description,
      is_empty_contract: isEmptyContract
    };

    try {
      const res = await fetch(backendUrl + endpoint, {
        method: 'POST',
        headers: this.authHeader() || { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });


      console.log('XX', res)

      return res.json()
    } catch (error) {
      console.error('Error creating contract:', error);
      throw error;
    }
  }

  async getContract(contractId) {
    const endpoint = `api/contract/${contractId}`;

    try {
      const res = await this.get(endpoint);

      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('Contract not found');
        } else {
          const errorData = await res.json();
          throw new Error(errorData.detail || `Failed to fetch contract: HTTP status ${res.status}`);
        }
      }

      return await res.json();
    } catch (error) {
      console.error('Error fetching contract:', error);
      throw error;
    }
  }

  async updateContract(contractId, contractData) {
    const endpoint = `api/contract/${contractId}`;

    try {
      const res = await fetch(backendUrl + endpoint, {
        method: 'PUT',
        headers: this.authHeader() || { 'Content-Type': 'application/json' },
        body: JSON.stringify(contractData)
      });

      await this.handleResponse(res);
      return await res.json();
    } catch (error) {
      console.error('Error updating contract:', error);
      throw error;
    }
  }

  async shareContract(contractId, users, userDomain = 'app') {
    const endpoint = `api/contract/share`;
    const payload = {
      contract_id: contractId,
      users: users,
      domain_name: userDomain
    };

    try {
      const res = await this.post(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to share contract: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error while sharing contract:', error.message);
      throw error;
    }
  }

  async updateCollaboratorRole(contractId, userId, role) {
    const endpoint = `api/contract/roles/update`;
    const payload = {
      contract_id: contractId,
      user_id: userId,
      role: role
    };

    try {
      const res = await this.put(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed to update collaborator roles');
        window.alert('Vous n\'avez pas l\'autorisation de modifier les rôles des collaborateurs.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to update collaborator role: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error updating collaborator role:', error.message);
      throw error;
    }
  }



  async deleteContracts(contractIds) {
    const endpoint = `api/contract/delete`;
    const payload = { contract_ids: contractIds };

    try {
      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to delete contracts: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error deleting contracts:', error.message);
      throw error;
    }
  }

  // Get user contracts with pagination
  async getUserContracts(userId, limit = null, offset = null) {
    try {
      let url = `api/contract/?user_id=${userId}`;
      if (limit !== null && offset !== null) {
        url += `&limit=${limit}&offset=${offset}`;
      }
      const res = await this.get(url);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to fetch user contracts: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error getting user contracts:', error);
      throw error;
    }
  }

  // Search contracts by term
  async searchContracts(userId, searchTerm) {
    try {
      const res = await this.get(`api/contract/search?user_id=${userId}&search_term=${encodeURIComponent(searchTerm)}`);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to search contracts: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error searching contracts:', error);
      throw error;
    }
  }

  // Get contract count by user
  async getContractsCountByUser(userId) {
    try {
      const res = await this.get(`api/contract/count?user_id=${userId}`);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to get contracts count: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error getting contracts count:', error);
      throw error;
    }
  }
  async getContractsCountUniqueByUser(userId) {
    try {
      const res = await this.get(`api/contract/count-unique?user_id=${userId}`);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to get contracts count: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error getting contracts count:', error);
      throw error;
    }
  }

  /**
 * Clear all messages from a contract conversation
 * @param {string} conversationId - The UUID of the conversation to clear
 * @returns {Promise<Object>} - Response object
 */
  async clearContractConversation(conversationId) {
    try {
      const endpoint = `api/contract/conversation/clear/${conversationId}`;
      const res = await fetch(backendUrl + endpoint, {
        method: 'DELETE',
        headers: this.authHeader()
      });

      await this.handleResponse(res);
      return await res.json();
    } catch (error) {
      console.error('Error clearing contract conversation:', error);
      throw error;
    }
  }


  async createContractConversation(contractId, userId, timezone = null) {
    try {
      const endpoint = 'api/contract/conversation/create';
      const userTz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

      const payload = {
        contract_id: contractId,
        user_id: userId,
        timezone: userTz
      };

      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to create contract conversation: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error creating contract conversation:', error);
      throw error;
    }
  }

  async processPrompt(text, operation, customPrompt = null, contractId = null) {
    const endpoint = 'api/contract/process-prompt';

    const payload = {
      text: text,
      operation: operation,
      custom_prompt: customPrompt,
      contract_id: contractId
    };

    try {
      const res = await this.post(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to process text with AI: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error processing text with AI:', error.message);
      throw error;
    }
  }


  // Add these methods to your existing backendClient class


  // Get contracts over time
  async getContractsOverTime(userId) {
    try {
      const endpoint = 'api/contract/over-time';
      const payload = { user_id: userId };

      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to fetch contracts over time: HTTP status ${res.status}`);
      }

      const data = await res.json();
      return data.map(contractData => ({
        id: contractData.id,
        data: contractData.data
      }));
    } catch (error) {
      console.error('Error fetching contracts over time:', error);
      throw error;
    }
  }

  // Get contracts by status
  async getContractsByStatus(userId) {
    try {
      const endpoint = 'api/contract/by-status';
      const payload = { user_id: userId };

      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to fetch contracts by status: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error fetching contracts by status:', error);
      throw error;
    }
  }

  // Get contracts by type
  async getContractsByType(userId) {
    try {
      const endpoint = 'api/contract/by-type';
      const payload = { user_id: userId };

      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to fetch contracts by type: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error fetching contracts by type:', error);
      throw error;
    }
  }

  async removeCollaborator(contractId, userId) {
    const endpoint = `api/contract/collaborator`;
    const payload = {
      contract_id: contractId,
      user_id: userId
    };

    try {
      const res = await this.delete(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed to remove collaborators');
        window.alert('Vous n\'avez pas l\'autorisation de supprimer des collaborateurs.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to remove collaborator: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error removing collaborator:', error.message);
      throw error;
    }
  }


  /**
   * Add a signatory to a contract
   */
  async addSignatory(contractId, email, firstName, lastName) {
    try {
      const response = await this.post(`api/contract/signature/add-signatory?contract_id=${contractId}`, {
        email: email,
        first_name: firstName || "",
        last_name: lastName || ""
      });
      return response;
    } catch (error) {
      console.error('Error adding signatory:', error);
      throw error;
    }
  }

  /**
   * Delete a signatory from a contract
   */
  async deleteSignatory(contractId, email) {
    try {
      const response = await this.delete(`api/contract/signature/${contractId}/signatory/${encodeURIComponent(email)}`);
      return response;
    } catch (error) {
      console.error('Error deleting signatory:', error);
      throw error;
    }
  }

  /**
   * Create a signature request
   */
  async createSignatureRequest(contractId, signers, callbackUrl, expirationDays = 7, reminderDays = 3) {
    try {
      const response = await this.post('api/contract/signature/create', {
        contract_id: contractId,
        signers: signers,
        callback_url: callbackUrl,
        expiration_days: expirationDays,
        reminder_days: reminderDays
      });
      return response;
    } catch (error) {
      console.error('Error creating signature request:', error);
      throw error;
    }
  }

  /**
   * Get signature status for a contract
   */
  async getSignatureStatus(contractId) {
    try {
      const response = await this.get(`api/contract/signature/${contractId}/status`);
      return response;
    } catch (error) {
      console.error('Error fetching signature status:', error);
      throw error;
    }
  }

  /**
   * Cancel a signature request
   */
  async cancelSignatureRequest(contractId) {
    try {
      const response = await this.post(`api/contract/signature/${contractId}/cancel`);
      return response;
    } catch (error) {
      console.error('Error cancelling signature request:', error);
      throw error;
    }
  }

  /**
   * Send signature reminder
   */
  async sendSignatureReminder(contractId, message) {
    try {
      const response = await this.post(`api/contract/signature/${contractId}/remind`, {
        message: message
      });
      return response;
    } catch (error) {
      console.error('Error sending signature reminder:', error);
      throw error;
    }
  }

  async checkUserExists(email) {
    const endpoint = 'api/auth/check-user';
    const payload = { email };

    try {
      const res = await this.post(endpoint, payload);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to check user: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error checking if user exists:', error.message);
      throw error;
    }
  }

  async initPayment(paymentData) {
    try {
      const endpoint = 'api/payment/init-payment';

      // Create headers manually since this might be called before user authentication
      const headers = {
        'Content-Type': 'application/json'
      };

      // Custom fetch call for payment initialization
      const response = await fetch(backendUrl + endpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(paymentData),
      });

      // Handle common error responses
      if (response.status === 503) {
        this.showSnackbar(
          'Le serveur est actuellement en maintenance. Veuillez réessayer plus tard.'
        );
        throw new Error('Le serveur est en maintenance');
      }

      if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Données de paiement invalides');
      }

      if (!response.ok) {
        throw new Error(`Échec de l'initialisation du paiement: ${response.status}`);
      }

      // For payment initialization, we need the raw HTML content
      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Erreur lors de l\'initialisation du paiement:', error);
      if (error.message === 'Failed to fetch') {
        this.showSnackbar(
          'Impossible de se connecter au serveur de paiement. Veuillez vérifier votre connexion Internet ou réessayer plus tard.'
        );
      }
      throw error;
    }
  }




  async getPaymentProfile() {
    const endpoint = 'api/payment/payment-profile';

    try {
      const res = await this.get(endpoint);

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (res.status === 404) {
        // No payment profile found - this is a valid response, not an error
        // Return null to indicate no profile exists
        return null;
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to retrieve payment profile: HTTP status ${res.status}`
        );
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error while fetching payment profile:', error.message);
      // Only throw if it's not a 404 (no profile) error
      if (error.message !== 'Not Found') {
        throw error;
      }
      return null;
    }
  }



  async resetUserPassword(userId, currentPassword, newPassword) {
    const endpoint = 'api/auth/user/reset-password';
    const payload = {
      user_id: userId,
      current_password: currentPassword,
      new_password: newPassword
    };

    try {
      const res = await this.post(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or token is invalid, or the current password is incorrect.');

        // Only logout if it's a token issue, not a password validation issue
        if (res.statusText !== 'Current password is incorrect') {
          this.dispatch(logout());
          window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        }

        const errorData = await res.json();
        throw new Error(errorData.detail || 'Unauthorized');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || `Failed to reset password: HTTP status ${res.status}`);
      }

      return await res.json();
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  }


  async getOrganizationBillingInfo(organizationId) {
    const endpoint = `api/organization/billing/${organizationId}`;

    try {
      const res = await this.get(endpoint);

      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('Billing information not found for the specified organization');
        } else {
          const errorData = await res.json();
          throw new Error(errorData.detail || `Failed to fetch organization billing information: HTTP status ${res.status}`);
        }
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error while fetching organization billing info:', error.message);
      throw error;
    }
  }



  async getOrganizationBillingHistory(organizationId) {
    const endpoint = `api/organization/billing-history/${organizationId}`;

    try {
      const res = await this.get(endpoint);

      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('Billing history not found for the specified organization');
        } else {
          const errorData = await res.json();
          throw new Error(errorData.detail || `Failed to fetch organization billing history: HTTP status ${res.status}`);
        }
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error while fetching organization billing history:', error.message);
      throw error;
    }
  }



  async updateOrganizationBillingInfo(organizationId, billingData) {
    const endpoint = `api/organization/update-billing/${organizationId}`;

    try {
      const headers = this.authHeader();
      const url = backendUrl + endpoint;

      const res = await fetch(url, {
        method: 'PUT',
        headers: headers || { 'Content-Type': 'application/json' },
        body: JSON.stringify(billingData)
      });

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to update organization billing information: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error while updating organization billing information:', error.message);
      throw error;
    }
  }


  async cancelOrganizationSubscription(organizationId) {
    const endpoint = `api/organization/cancel-organization-subscription`;

    // The API is expecting an organization_id field
    const payload = {
      organization_id: organizationId
    };

    try {
      const res = await this.post(endpoint, payload);

      if (res.status === 403) {
        console.log('This account is not allowed by admins, please contact support');
        this.dispatch(logout());
        window.alert('Utilisateur non autorisé. Veuillez contacter le support au +212610133995.');
        throw new Error('Unauthorized access');
      }

      if (res.status === 401) {
        console.log('Session has expired or the token is invalid. Logging out.');
        this.dispatch(logout());
        window.alert('Une nouvelle session a été détectée dans un autre onglet ou appareil. Vous serez déconnecté.');
        throw new Error('Session expired');
      }

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to cancel organization subscription: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error while cancelling organization subscription:', error.message);
      throw error;
    }
  }

  async getSubscriptionLimits(organizationId) {
    try {
      const endpoint = `api/organization/subscription-limits/${organizationId}`;
      const res = await this.get(endpoint);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to fetch subscription limits: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error("Error fetching subscription limits:", error);
      throw error;
    }
  }
  async filter(filterParams, limit = 10, offset = 0) {
    const endpoint = 'api/conversation/filter';

    // Prepare the request body, removing the user_id since it's now taken from the auth token
    const requestBody = {
      filter_params: filterParams,
      limit,
      offset
    };

    try {
      const res = await this.post(endpoint, requestBody);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to filter conversations: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error filtering conversations:', error.message);
      throw error;
    }
  }

  async filterContracts(filterParams, limit = 10, offset = 0) {
    const endpoint = 'api/contract/filter';

    // Prepare the request body, removing the user_id since it's now taken from the auth token
    const requestBody = {
      filter_params: filterParams,
      limit,
      offset
    };

    try {
      const res = await this.post(endpoint, requestBody);

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(
          errorData.detail || `Failed to filter conversations: HTTP status ${res.status}`
        );
      }

      return await res.json();
    } catch (error) {
      console.error('Error filtering conversations:', error.message);
      throw error;
    }
  }

  async validatePromoCode(validateData) {
    try {
      const endpoint = 'api/checkout/validate-promo-code/';

      // Use the object that's passed in directly
      const payload = {
        code: validateData.code,
        plan_type: validateData.plan_type,
        subscription_type: validateData.subscription_type,
        amount: parseFloat(validateData.amount)
      };

      // Make the API call using our existing post method
      const response = await this.post(endpoint, payload);

      // Parse and return the response data
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `Failed to validate promo code: HTTP status ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error validating promo code:', error);
      throw error;
    }
  }


}

export const backendClient = new BackendClient(store.dispatch)