import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography,
  alpha
} from '@mui/material';
import { WarningAmber } from '@mui/icons-material';

const DeleteUserDialog = ({
  open,
  onClose,
  onDeleteUser,
  selectedUser,
  colors,
  t
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          maxWidth: '450px'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1, 
        pt: 3,
        px: 3,
        color: colors.error,
        fontWeight: 600,
        fontSize: '1.25rem',
        display: 'flex',
        alignItems: 'center'
      }}>
        <WarningAmber sx={{ mr: 1 }} />
        {t('confirm_deletion', 'Confirm Deletion')}
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2 }}>
        <DialogContentText sx={{ color: colors.textSecondary }}>
          {t('delete_user_confirmation', 'Are you sure you want to delete this user?')}
        </DialogContentText>
        {selectedUser && (
          <Box 
            sx={{ 
              mt: 2, 
              p: 2, 
              backgroundColor: colors.backgroundAlt,
              borderRadius: '8px',
              borderLeft: `4px solid ${colors.error}`
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
              {selectedUser.first_name} {selectedUser.last_name}
            </Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary }}>
              {selectedUser.email}
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ 
            width: 40, 
            height: 40, 
            borderRadius: '50%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: alpha(colors.error, 0.1),
            color: colors.error,
            mr: 2
          }}>
            <WarningAmber />
          </Box>
          <Typography variant="body2" sx={{ color: colors.error, fontWeight: 500 }}>
            {t('delete_user_warning', 'This action cannot be undone.')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 1 }}>
        <Button 
          onClick={onClose}
          sx={{ 
            color: colors.textSecondary,
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: colors.backgroundAlt,
            }
          }}
        >
          {t('cancel', 'Cancel')}
        </Button>
        <Button 
          onClick={onDeleteUser} 
          variant="contained" 
          color="error"
          disableElevation
          sx={{ 
            backgroundColor: colors.error,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: alpha(colors.error, 0.8),
            }
          }}
        >
          {t('delete', 'Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;