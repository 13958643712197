import React from 'react';
import SubProcessDisplay from './SubProcessDisplay';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Paper, Typography, Grid, Chip, Avatar } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { DescriptionOutlined as DocIcon } from '@mui/icons-material';

const UserDisplay = ({ message, showLoading }) => {
  // Convert both \n and <br/> to React <br /> elements
  const formattedContent = message.content
    .replace(/<br\s*\/?>/gi, '\n') // Normalize <br/> tags to \n
    .split('\n') // Split by newline character
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  return (
    <>
      <Box 
        sx={{
          display: 'flex',
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: '#f5f5f5',
          px: 2,
          py: 2,
          mb: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            pr: 2,
            mt: 0.5 // Adjust to align with first line of text
          }}
        >
          <Avatar 
            sx={{ 
              width: 32, 
              height: 32, 
              bgcolor: '#e0e0e0',
              color: '#757575'
            }}
          >
            <PersonIcon fontSize="small" />
          </Avatar>
        </Box>

        <Box 
          sx={{ 
            flex: 1,
            display: 'flex',
            alignItems: 'center' // Center align vertically
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Nunito', sans-serif",
              fontWeight: 500,
              color: '#333',
              lineHeight: 1.5,
              fontSize: '0.875rem'
            }}
          >
            {formattedContent}
          </Typography>
        </Box>
      </Box>

      {message.sources?.length > 0 && (
        <Paper
          elevation={0}
          sx={{
            mx: 8,
            p: 2,
            mb: 2,
            backgroundColor: '#f9f9f9',
            borderRadius: 2,
            border: '1px solid #ebebeb'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <FolderIcon fontSize="small" sx={{ mr: 1, color: '#757575' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#555' }}>
              Documents attachés
            </Typography>
          </Box>
          
          <Grid container spacing={1}>
            {message.sources.map((source, index) => (
              <Grid item xs={12} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#ffffff',
                    border: '1px solid #eeeeee',
                    borderRadius: 1
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DocIcon sx={{ mr: 1.5, color: '#607d8b', fontSize: 20 }} />
                    <Box>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          fontWeight: 500, 
                          color: '#333',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {source.metadata.original_file_name}
                      </Typography>
                      <Box sx={{ display: 'flex', mt: 0.5 }}>
                        <Chip
                          label={source.metadata.language || 'N/A'}
                          size="small"
                          sx={{ 
                            mr: 1, 
                            fontSize: '0.625rem',
                            height: 20,
                            backgroundColor: '#e3f2fd',
                            color: '#1976d2'
                          }}
                        />
                        <Chip
                          label={source.metadata.doc_type || 'Unknown'}
                          size="small"
                          sx={{ 
                            fontSize: '0.625rem',
                            height: 20,
                            backgroundColor: '#e8f5e9',
                            color: '#2e7d32'
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {showLoading && (
        <Box sx={{ display: 'flex', pb: 2 }}>
          <Box sx={{ pl: 5, width: 'max-content' }}>
            <SubProcessDisplay
              key={`${message.id}-loading-sub-process`}
              messageId={message.id}
              subProcesses={[]}
              isOpen={true}
              toggleOpen={() => null}
              showSpinner={true}
              documents={[]}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserDisplay;