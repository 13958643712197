import React from 'react';
import { 
  TableContainer, Table, TableHead, TableBody, 
  TableRow, TableCell, IconButton, Chip, 
  Typography, Paper, CircularProgress, Box 
} from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const InvoiceTable = ({ 
  billing_history, 
  formatDate, 
  getStatusColor, 
  handleDownloadInvoice,
  downloadingInvoices,
  colors
}) => {
  const { t } = useTranslation();

  const getChipStyles = (status) => {
    const colorMap = {
      'success': {
        bgcolor: colors.secondaryLight,
        color: colors.secondary,
        borderColor: colors.secondary
      },
      'error': {
        bgcolor: '#FEE2E2',
        color: colors.error,
        borderColor: colors.error
      },
      'warning': {
        bgcolor: '#FEF3C7',
        color: colors.warning,
        borderColor: colors.warning
      },
      'info': {
        bgcolor: '#DBEAFE',
        color: colors.info,
        borderColor: colors.info
      },
      'default': {
        bgcolor: colors.backgroundAlt,
        color: colors.textSecondary,
        borderColor: colors.border
      }
    };

    return colorMap[status] || colorMap.default;
  };

  // Table headers
  const headers = [
    { id: 'transaction_id', label: t("transaction_id") },
    { id: 'amount', label: t("amount") },
    { id: 'date', label: t("date") },
    { id: 'payment_method', label: t("payment_method") },
    { id: 'status', label: t("status") },
    { id: 'action', label: t("action") },
  ];

  return (
    <TableContainer 
      component={Paper} 
      elevation={0} 
      sx={{ 
        borderRadius: '12px',
        border: `1px solid ${colors.border}`,
        overflow: 'hidden'
      }}
    >
      <Table>
        <TableHead sx={{ bgcolor: colors.backgroundAlt }}>
          <TableRow>
            {headers.map(header => (
              <TableCell 
                key={header.id}
                sx={{ 
                  fontWeight: 600, 
                  color: colors.text,
                  py: 2
                }}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {billing_history && billing_history.length > 0 ? (
            billing_history.map((bill, index) => (
              <TableRow key={bill.id || index} hover>
                <TableCell>{bill.transaction_id || "-"}</TableCell>
                <TableCell>{bill.amount} {bill.currency}</TableCell>
                <TableCell>{formatDate(bill.created_at)}</TableCell>
                <TableCell>{bill.payment_method || "-"}</TableCell>
                <TableCell>
                  <Chip
                    label={bill.status}
                    variant="outlined"
                    size="small"
                    sx={{
                      ...getChipStyles(getStatusColor(bill.status)),
                      fontWeight: 500,
                      px: 1,
                    }}
                  />
                </TableCell>
                <TableCell>
                  {(bill.status === "CHARGED" || bill.status === "paid" || bill.status === "PAID") && (
                    <IconButton
                      onClick={() => handleDownloadInvoice(bill.id)}
                      disabled={downloadingInvoices[bill.id]}
                      sx={{ 
                        color: colors.primary,
                        '&:hover': {
                          bgcolor: colors.primaryLight
                        },
                        '&.Mui-disabled': {
                          color: colors.disabled
                        }
                      }}
                    >
                      {downloadingInvoices[bill.id] ? (
                        <CircularProgress size={24} sx={{ color: colors.primary }} />
                      ) : (
                        <GetAppIcon />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center" sx={{ py: 4 }}>
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography color={colors.textSecondary}>
                    {t("no_invoices_found")}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;