import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SendIcon from '../../../../assets/chat/send.png'
import DisabledSendIcon from '../../../../assets/chat/disabledSend.png'
import StopIcon from '../../../../assets/chat/stop.png'
import { Box, Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConversation } from '../../../../redux/actions/conversationAction'
import { getCitations } from '../../../../utils/utils'
import { messageActions } from '../../../../redux/actions/messageAction'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { setSelectedCitation } from '../../../../redux/actions/documentAction'
import { v4 as uuidv4 } from 'uuid'
import ReactGA from 'react-ga4'
import ChatHeader from './ChatHeader'
import MessagesList from './MessagesList'
import MessageInput from './MessageInput'
import MessageHandler from './MessageHandler'
import { windowWidth } from '../../../../utils/utils'
import { backendClient } from '../../../../api/backend'
import { backendUrl } from '../../../../config'
import { fontSize } from '../../../../utils/fonts'

const Chat = props => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const { conversationId } = props
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user?.user)
  const user_email = useSelector(state => state.auth.user?.user?.email)
  const user_id = useSelector(state => state.auth.user?.user?.id)
  const textFocusRef = useRef()
  const [userMessage, setUserMessage] = useState('')
  const [isMessagePending, setIsMessagePending] = useState(false)
  const messages = useSelector((state) => state.messages.messages)
  const [filteredMessages, setfilteredMessages] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [isExporting, setIsExporting] = useState(false)
  const [mode, setMode] = useState('loi')
  const [textInputRows, setTextInputRows] = useState(1)
  const [isFetchingConversation, setIsFetchingConversation] = useState(false)
  const [hasCheckedMessages, setHasCheckedMessages] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const fileInputRef = useRef()
  const [uploadController, setUploadController] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const isFirstFetch = useRef(true)
  const hasFetchedMessages = useRef(false)
  const location = useLocation()
  const prevLocationRef = useRef(location.pathname)
  const { enqueueSnackbar } = useSnackbar()
  const eventSourceRef = useRef(null)
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false)

  const handleTextChange = event => {
    const sanitizedMessage = event.target.value.replace(/<br\s*\/?>/gi, '\n')
    setUserMessage(sanitizedMessage)
  }

  const handleTextSearchChange = event => {
    const searchText = event.target.value
    setSearchText(searchText)
    const filteredMessages_ = messages.filter(message => {
      const contentMatch =
        message.content &&
        message.content.toLowerCase().includes(searchText.toLowerCase())
      const subQuestionMatch = message.sub_processes?.some(
        subProcess =>
          subProcess.metadata_map?.sub_question?.answer &&
          subProcess.metadata_map.sub_question?.answer
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
      )

      ReactGA.event({
        category: 'Search',
        action: 'User searched in conversation',
        label: searchText
      })

      return contentMatch || subQuestionMatch
    })

    setfilteredMessages(filteredMessages_)
  }

  const setSuggestedMessage = text => {
    setUserMessage(text)
    if (textFocusRef.current) {
      textFocusRef.current.focus()
    }
  }

  useEffect(() => {
    dispatch(setSelectedCitation(null))
    dispatch(messageActions.setMessages([]))
    setSelectedFiles([])
    setIsUploadingDocuments(false)
  }, [location.pathname, dispatch])

  useEffect(() => {
    const fetchConversationData = async () => {
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false
        setIsFetchingConversation(true)
        const conversationData = await dispatch(
          fetchConversation(conversationId, user_id, true)
        )
        hasFetchedMessages.current = true
        if (conversationData?.messages?.length) {
          dispatch(messageActions.setMessages(conversationData.messages))
        }
        setIsFetchingConversation(false)
      }
    }

    fetchConversationData()
  }, [dispatch, conversationId, user_id])

  useEffect(() => {
    const anyChecked = messages.some(message => message.checked)
    setHasCheckedMessages(anyChecked)
  }, [messages])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/chat', title: 'Chat Page' })
  }, [])

  useEffect(() => {
    let currentPathname = location.pathname
    const cleanup = () => {
      if (eventSourceRef.current) {
        console.log('Closing EventSource connection...')
        eventSourceRef.current.close()
        eventSourceRef.current = null
        setIsMessagePending(false)
      }
    }
    const handleRouteChange = () => {
      if (location.pathname !== currentPathname) {
        cleanup()
        currentPathname = location.pathname
      }
    }
    return () => {
      cleanup()
      handleRouteChange()
    }
  }, [location.pathname])

  const { submit } = MessageHandler({
    userMessage,
    setUserMessage,
    selectedFiles,
    setSelectedFiles,
    conversationId,
    eventSourceRef,
    backendClient,
    backendUrl,
    user,
    mode,
    setfilteredMessages,
    setIsMessagePending,
    setIsUploadingDocuments,
    setUploadController,
    dispatch
  });

  useEffect(() => {
    return () => {
      if (uploadController) {
        uploadController.abort()
        setUploadController(null)
      }
      if (eventSourceRef.current) {
        eventSourceRef.current.close()
        setIsMessagePending(false)
        setIsUploadingDocuments(false)
        setSelectedFiles([])
      }
    }
  }, [uploadController])

  const handleExport = (format) => {
    setIsExporting(true)

    const checkedMessages = messages.reduce((result, message, index) => {
      if (message.checked) {
        if (index > 0 && messages[index - 1].role === 'user') {
          result.push({
            id: messages[index - 1].id,
            content: `<strong>Utilisateur:</strong> ${messages[index - 1].content}`
          })
        }
        result.push({
          id: message.id,
          content: `<strong>Réponse Juridia:</strong> ${message.content}`
        })

        const citations = getCitations(message)

        console.log('citations', citations)

        if (citations.length > 0) {
          const formattedCitations = citations.map(citation => {
            return `<br/><strong>Référence :</strong><br/>
            <strong>Titre:</strong> ${citation.long_title}<br/>
            <strong>Section:</strong> ${citation.section_title}<br/>
            <strong>Text:</strong> ${citation.text}<br/><br/>`
          })

          result.push({
            id: `${uuidv4()}`,
            content: formattedCitations.join('')
          })

          ReactGA.event({
            category: 'Export',
            action: 'User exported messages',
            label: format
          })
        }
      }
      return result
    }, [])

    console.log('checkedMessages', checkedMessages)
    backendClient
      .exportMessages(checkedMessages, format, user_email)
      .then(async blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        if (format === 'email') {
          a.download = 'export.eml'
        } else {
          a.download = `export.${format}`
        }
        a.click()
        enqueueSnackbar(`Messages exportés en format ${format} !`, {
          variant: 'success'
        })
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsExporting(false)
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode)
    }
  }

  const handleFileChange = event => {
    const files = Array.from(event.target.files)
    const MAX_FILES = 5
    const MAX_FILE_SIZE_MB = 5

    if (files.length > MAX_FILES) {
      enqueueSnackbar(t('max_files_limit', { count: MAX_FILES }), {
        variant: 'error'
      })
      return
    }

    const invalidFiles = files.filter(
      file => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
    )

    if (invalidFiles.length > 0) {
      enqueueSnackbar(
        t('file_size_limit_exceeded', { size: MAX_FILE_SIZE_MB }),
        { variant: 'error' }
      )
      return
    }

    setSelectedFiles(files)
    enqueueSnackbar(t('files_selected', { count: files.length }), {
      variant: 'info'
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh', // Match parent container height
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {/* Chat Header */}
      <Box sx={{ 
        flexShrink: 0,
        zIndex: 10
      }}>
        <ChatHeader
          currentLang={currentLang}
          mode={mode}
          handleModeChange={handleModeChange}
          handleClick={handleClick}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          isExporting={isExporting}
          handleExport={handleExport}
          hasCheckedMessages={hasCheckedMessages}
          user_email={user_email}
          handleTextSearchChange={handleTextSearchChange}
          t={t}
          fontSize={fontSize}
        />
        <Divider />
      </Box>

      {/* Messages List */}
      <Box 
        sx={{ 
          flexGrow: 1, 
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          // paddingBottom: '16px', // Space between messages and input
          height: 'calc(100% - 160px)' // Reserve height for header and input
        }}
      >
        <MessagesList
          currentLang={currentLang}
          t={t}
          filteredMessages={filteredMessages}
          searchText={searchText}
          messages={messages}
          isFetchingConversation={isFetchingConversation}
          setSuggestedMessage={setSuggestedMessage}
        />
      </Box>

      {/* Message Input - Fixed to bottom */}
      <Box 
        sx={{ 
          // position: 'absolute',
          // bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'background.paper',
          zIndex: 10
        }}
      >
        <MessageInput
          setUserMessage={setUserMessage}
          setUploadController={setUploadController}
          setIsUploadingDocuments={setIsUploadingDocuments}
          setIsMessagePending={setIsMessagePending}
          currentLang={currentLang}
          selectedFiles={selectedFiles}
          isUploadingDocuments={isUploadingDocuments}
          isMessagePending={isMessagePending}
          userMessage={userMessage}
          handleTextChange={handleTextChange}
          submit={submit}
          uploadController={uploadController}
          eventSourceRef={eventSourceRef}
          windowWidth={windowWidth}
          enqueueSnackbar={enqueueSnackbar}
          t={t}
          setSelectedFiles={setSelectedFiles}
          textInputRows={textInputRows}
          textFocusRef={textFocusRef}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          SendIcon={SendIcon}
          StopIcon={StopIcon}
          DisabledSendIcon={DisabledSendIcon}
        />
      </Box>
    </Box>
  )
}

export default Chat;