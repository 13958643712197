import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton';

const NoConversationsPlaceholder = ({ handleCreateConversation }) => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        py: 6,
        px: 2,
        backgroundColor: 'rgba(0,0,0,0.02)',
        borderRadius: 2,
        border: '1px dashed rgba(0,0,0,0.1)'
      }}
    >
      <Box
        sx={{
          width: 80,
          height: 80,
          borderRadius: '50%',
          backgroundColor: 'action.hover',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2
        }}
      >
        <ChatIcon style={{ fontSize: 36, color: 'text.secondary' }} />
      </Box>
      
      <Typography variant="h6" color="text.primary" gutterBottom>
        {t('no_conversations_title')}
      </Typography>
      
      <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 500, mb: 3 }}>
        {t('no_conversations_description')}
      </Typography>
      
      <CustomButton
        text={t('create_conversation')}
        outlined={false}
        startIcon={<AddIcon />}
        onClick={handleCreateConversation}
        sx={{ 
          borderRadius: 2,
          px: 3,
          py: 1,
          boxShadow: '0 4px 10px rgba(0,0,0,0.08)',
          textTransform: 'none'
        }}
      />
    </Box>
  );
};

export default NoConversationsPlaceholder;