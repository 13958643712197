import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Drawer,
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  alpha,
  Card,
  CardContent,
  Tabs,
  Tab,
  InputAdornment,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Settings as SettingsIcon,
  ChatBubbleOutline,
  CreditCard,
  PeopleAlt,
  HelpOutline,
  ExitToApp,
  AttachFile,
  MenuRounded,
  PersonOutlined,
  LockOutlined,
  LanguageOutlined,
  FormatColorTextOutlined,
  ArrowBackIosNew,
  CheckCircleOutline,
  FileUploadOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import { fetchPrompts, updatePrompt } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/CustomButton";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../api/backend";
import BillingInfo from "./components/BillingInfo/BillingInfo";
import UserManagement from "./components/UserManagement/UserManagement";
import SubscriptionUpgradeBanner from "../PaymentScreen/components/SubscriptionUpgradeBanner";
import { useSubscription } from "../../contexts/SubscriptionProvider";

// Modern color palette
const colors = {

  primary: "#0EA5E9",
  primaryLight: "#EAECFF",
  primaryDark: "#3730A3",
  secondary: "#10B981", // Emerald
  secondaryLight: "#D1FAE5",
  background: "#F9FAFB",
  backgroundAlt: "#F3F4F6",
  surface: "#FFFFFF",
  error: "#EF4444",
  warning: "#F59E0B",
  info: "#3B82F6",
  text: "#1F2937",
  textSecondary: "#6B7280",
  border: "#E5E7EB",
  borderDark: "#D1D5DB",
  disabled: "#9CA3AF",

};

const SettingsScreen = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { subscription } = useSubscription()
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [promptText, setPromptText] = useState("");
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const user = useSelector((state) => state.auth?.user?.user);
  const organization_prompt = useSelector(
    (state) => state.user?.organization_prompt
  );
  const [language, setLanguage] = useState(currentLang);


  // Password reset states
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({});
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  // Support states
  const [supportObject, setSupportObject] = useState("");
  const [supportDescription, setSupportDescription] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [isSendingSupport, setIsSendingSupport] = useState(false);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  // Responsive drawer state
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Check user roles
  const isRegularUser = user && user.roles && user.roles.length === 1 && user.roles[0] === "user";
  const isAdmin = user && user.roles && user.roles.includes("admin");

  // Handle drawer toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Tab configuration
  const getTabs = () => [
    { id: 0, label: t("general_parameters"), icon: <SettingsIcon /> },
    { id: 1, label: t("prompts"), icon: <ChatBubbleOutline /> },
    ...(isRegularUser ? [] : [{ id: 2, label: t("billing_tab"), icon: <CreditCard /> }]),
    ...(isAdmin ? [{ id: isRegularUser ? 2 : 3, label: t("user_management"), icon: <PeopleAlt /> }] : []),
    {
      id: isRegularUser ? (isAdmin ? 3 : 2) : (isAdmin ? 4 : 3),
      label: t("support"),
      icon: <HelpOutline />,
    },
    {
      id: isRegularUser ? (isAdmin ? 4 : 3) : (isAdmin ? 5 : 4),
      label: t("logout"),
      icon: <ExitToApp />,
    },
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    if (isMobile) {
      setMobileOpen(false);
    }

    // Google Analytics event for tab changes
    ReactGA.event({
      category: "Settings",
      action: "Tab Change",
      label: `Tab Index: ${tabId}`,
    });
  };

  const handleLogout = () => {
    dispatch(logout());

    // Google Analytics event for logging out
    ReactGA.event({
      category: "Settings",
      action: "Logged Out",
      label: `User ID: ${user.id}`,
    });
  };

  const handlePromptChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleSavePrompt = async () => {
    setIsSavingPrompt(true); // Start loading
    try {
      await dispatch(updatePrompt(user.id, "organization_prompt", promptText));
      enqueueSnackbar(t("prompt_updated_success"), {
        variant: "success",
      });

      // Google Analytics event for saving prompt
      ReactGA.event({
        category: "Settings",
        action: "Saved Prompt",
        label: `User ID: ${user.id}`,
      });
    } catch (error) {
      console.error("Failed to update prompt:", error);
      enqueueSnackbar(t("error_saving"), {
        variant: "error",
      });
    }
    setIsSavingPrompt(false); // Stop loading
  };

  useEffect(() => {
    dispatch(fetchPrompts(user.id, "organization_prompt"));
  }, [user, dispatch]);

  useEffect(() => {
    setPromptText(organization_prompt?.prompt_text);
  }, [organization_prompt]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage); // Update the state for the selected language
    i18n.changeLanguage(newLanguage); // Change language in i18next
    enqueueSnackbar(
      t("language_changed_message", {
        language:
          newLanguage === "fr"
            ? t("language_french")
            : newLanguage === "ar"
              ? t("language_arabic")
              : t("language_english"),
      }),
      {
        variant: "info",
      }
    );

    // Google Analytics event for language change
    ReactGA.event({
      category: "Settings",
      action: "Language Changed",
      label: `New Language: ${newLanguage}`,
    });
  };

  // Handle password reset
  const validatePasswordReset = () => {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = t("current_password_required");
    }

    if (!newPassword) {
      errors.newPassword = t("new_password_required");
    } else if (newPassword.length < 8) {
      errors.newPassword = t("password_length");
    }

    if (!confirmPassword) {
      errors.confirmPassword = t("confirm_password_required");
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = t("passwords_dont_match");
    }

    setPasswordErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleResetPassword = async () => {
    if (!validatePasswordReset()) {
      return;
    }

    setIsResettingPassword(true);

    try {
      // Call your API to reset the password
      await backendClient.resetUserPassword(user.id, currentPassword, newPassword);

      // Clear the password fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      enqueueSnackbar(t("password_reset_success"), {
        variant: "success",
      });

      // Google Analytics event
      ReactGA.event({
        category: "Settings",
        action: "Password Reset",
        label: `User ID: ${user.id}`,
      });
    } catch (error) {
      console.error("Failed to reset password:", error);
      enqueueSnackbar(
        error.response?.data?.detail || t("password_reset_error"),
        { variant: "error" }
      );
    }

    setIsResettingPassword(false);
  };

  const handleSupportSubmit = async () => {
    setIsSendingSupport(true);
    const newErrors = {};

    // Validate fields
    if (!supportObject) {
      newErrors.supportObject = t("error_support_object_required");
    }
    if (!supportDescription) {
      newErrors.supportDescription = t("error_support_description_required");
    }

    setErrors(newErrors);

    // Stop submission if there are errors
    if (Object.keys(newErrors).length > 0) {
      setIsSendingSupport(false);
      return;
    }

    const userEmail = user.email;

    try {
      const response = await backendClient.sendSupport(
        supportObject,
        supportDescription,
        attachedFile,
        userEmail
      );

      enqueueSnackbar(t("support_request_submitted"), {
        variant: "success",
      });

      // Reset form on successful submission
      setSupportObject("");
      setSupportDescription("");
      setAttachedFile(null);
      setErrors({});
    } catch (error) {
      console.error("Error while submitting support request:", error);
      enqueueSnackbar(t("error_support_submission_failed"), {
        variant: "error",
      });
    }
    setIsSendingSupport(false);
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    setAttachedFile(e.target.files[0]);
  };

  // Section header component
  const SectionHeader = ({ icon, title, description }) => (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon && (
          <Box
            sx={{
              mr: 2,
              p: 1.5,
              borderRadius: '12px',
              bgcolor: colors.primaryLight,
              color: colors.primary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Box>
        )}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: colors.text,
            fontSize: { xs: '1.5rem', sm: '1.75rem' }
          }}
        >
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography
          variant="body1"
          sx={{
            color: colors.textSecondary,
            ml: icon ? 7 : 0,
            mb: 2
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );

  // Redesigned Sidebar component
  const sidebarContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* User profile section */}
      <Box
        sx={{
          p: 3,
          pb: 4,
          background: `linear-gradient(to bottom, ${colors.primaryLight}, ${colors.surface})`,
          borderRadius: isSmall ? 0 : '0 0 24px 24px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Avatar
          sx={{
            width: 100,
            height: 100,
            bgcolor: colors.primary,
            fontSize: 36,
            fontWeight: 'bold',
            border: `4px solid ${colors.surface}`,
            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
            mb: 2
          }}
        >
          {user?.first_name?.[0]}{user?.last_name?.[0]}
        </Avatar>

        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: '1.25rem',
            color: colors.text,
            mb: 0.5
          }}
        >
          {user?.first_name} {user?.last_name}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: colors.textSecondary,
            mb: 2
          }}
        >
          {user?.email}
        </Typography>

        <Chip
          icon={isAdmin ? <CheckCircleOutline fontSize="small" /> : null}
          label={isAdmin ? t("admin_account") : t("user_account")}
          size="small"
          sx={{
            bgcolor: isAdmin ? colors.secondaryLight : colors.primaryLight,
            color: isAdmin ? colors.secondary : colors.primary,
            fontWeight: 500,
            '& .MuiChip-icon': {
              color: isAdmin ? colors.secondary : colors.primary,
            }
          }}
        />
      </Box>

      {/* Navigation menu */}
      <List
        sx={{
          px: 2,
          py: 1,
          flexGrow: 1,
        }}
      >
        {getTabs().map((tab) => (
          <ListItem
            key={tab.id}
            button
            disableRipple
            onClick={() => handleTabChange(tab.id)}
            sx={{
              borderRadius: '12px',
              mb: 1,
              py: 1.5,
              transition: 'all 0.2s',
              bgcolor: activeTab === tab.id ? colors.primaryLight : 'transparent',
              color: activeTab === tab.id ? colors.primary : colors.textSecondary,
              '&:hover': {
                bgcolor: activeTab === tab.id ? colors.primaryLight : alpha(colors.border, 0.5),
                color: activeTab === tab.id ? colors.primary : colors.text,
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: 'inherit',
                minWidth: 40,
                '& svg': {
                  fontSize: '1.35rem'
                }
              }}
            >
              {tab.icon}
            </ListItemIcon>
            <ListItemText
              primary={tab.label}
              primaryTypographyProps={{
                fontSize: '1rem',
                fontWeight: activeTab === tab.id ? 600 : 500
              }}
            />
            {activeTab === tab.id && (
              <Box
                sx={{
                  width: 4,
                  height: 24,
                  backgroundColor: colors.primary,
                  borderRadius: '4px',
                }}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* Footer */}
      <Box
        sx={{
          p: 3,
          borderTop: `1px solid ${colors.border}`,
          mt: 'auto'
        }}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            textAlign: 'center',
            color: colors.textSecondary
          }}
        >
          © {new Date().getFullYear()} {t("company_name")}
        </Typography>
      </Box>
    </Box>
  );

  // Fixed sidebar width
  const SIDEBAR_WIDTH = 320;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? 'column' : 'row',
        backgroundColor: colors.background,
        minHeight: "100vh",
        direction: currentLang === "ar" ? "rtl" : "ltr",
        overflow: 'hidden',

      }}
    >
      {/* Mobile App Bar */}
      {isMobile && (
        <AppBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: colors.surface,
            color: colors.text,
            borderBottom: `1px solid ${colors.border}`
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuRounded />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              {t("settings")}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar - Responsive Drawer for mobile, fixed for desktop */}
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better mobile performance
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              width: SIDEBAR_WIDTH,
              minWidth: SIDEBAR_WIDTH,
              maxWidth: SIDEBAR_WIDTH,
              bgcolor: colors.surface,
              borderRight: `1px solid ${colors.border}`,
              boxSizing: 'border-box',
              flexShrink: 0
            },
          }}
        >
          {sidebarContent}
        </Drawer>
      ) : (
        <Paper
          elevation={0}
          sx={{
            width: SIDEBAR_WIDTH,
            minWidth: SIDEBAR_WIDTH,
            maxWidth: SIDEBAR_WIDTH,
            flexShrink: 0,
            bgcolor: colors.surface,
            borderRight: `1px solid ${colors.border}`,
            display: { xs: 'none', md: 'block' },
            position: 'sticky',
            top: 0,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {sidebarContent}
        </Paper>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3, md: 2 },
          bgcolor: colors.background,
          minHeight: isMobile ? `calc(100vh - 64px)` : '100vh',
          width: isMobile ? '100%' : `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}
      >
        <Box
          // maxWidth="lg"
          sx={{
            height: '100%',
          }}
        >
          {subscription && subscription.status !== "active" && (
            <SubscriptionUpgradeBanner />
          )}

          {/* General Settings Tab */}
          {activeTab === 0 && (
            <Box>
              {/* User Information Section */}
              <Card
                elevation={0}
                sx={{
                  mb: 4,
                  borderRadius: '16px',
                  border: `1px solid ${colors.border}`,
                  overflow: 'visible'
                }}
              >
                <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                  <SectionHeader
                    icon={<PersonOutlined />}
                    title={t("user_information")}
                  />

                  {/* Information Fields */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        label={t("last_name")}
                        variant="outlined"
                        fullWidth
                        value={user?.last_name || ""}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            bgcolor: colors.backgroundAlt,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            }
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        label={t("first_name")}
                        variant="outlined"
                        fullWidth
                        value={user?.first_name || ""}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            bgcolor: colors.backgroundAlt,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            }
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        label={t("email")}
                        variant="outlined"
                        fullWidth
                        value={user?.email || ""}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            bgcolor: colors.backgroundAlt,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            }
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        label={t("phone_number")}
                        variant="outlined"
                        fullWidth
                        value={user?.phone_number || ""}
                        InputProps={{
                          readOnly: true,
                          sx: {
                            bgcolor: colors.backgroundAlt,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* Language Settings Section */}
              <Card
                elevation={0}
                sx={{
                  mb: 4,
                  borderRadius: '16px',
                  border: `1px solid ${colors.border}`
                }}
              >
                <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                  <SectionHeader
                    icon={<LanguageOutlined />}
                    title={t("language_label")}
                    description={t("language_description")}
                  />

                  <Box sx={{ maxWidth: 400 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="language-select-label">{t("language_label")}</InputLabel>
                      <Select
                        labelId="language-select-label"
                        value={language}
                        label={t("language_label")}
                        onChange={handleLanguageChange}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.border,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors.primaryDark,
                          },
                        }}
                      >
                        <MenuItem value="fr">🇫🇷 {t("language_french")}</MenuItem>
                        <MenuItem value="ar">🇲🇦 {t("language_arabic")}</MenuItem>
                        <MenuItem value="en">🇺🇸 {t("language_english")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>

              {/* Password Reset Section */}
              <Card
                elevation={0}
                sx={{
                  mb: 4,
                  borderRadius: '16px',
                  border: `1px solid ${colors.border}`
                }}
              >
                <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                  <SectionHeader
                    icon={<LockOutlined />}
                    title={t("reset_password")}
                    description={t("reset_password_description")}
                  />

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("current_password")}
                        type={showCurrentPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        error={!!passwordErrors.currentPassword}
                        helperText={passwordErrors.currentPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                edge="end"
                                sx={{ color: colors.textSecondary }}
                              >
                                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.primaryDark,
                            },
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("new_password")}
                        type={showNewPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        error={!!passwordErrors.newPassword}
                        helperText={passwordErrors.newPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                edge="end"
                                sx={{ color: colors.textSecondary }}
                              >
                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.primaryDark,
                            },
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("confirm_password")}
                        type={showNewPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!passwordErrors.confirmPassword}
                        helperText={passwordErrors.confirmPassword}
                        InputProps={{
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.border,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: colors.primaryDark,
                            },
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                      <Button
                        variant="contained"
                        disableElevation
                        disabled={!currentPassword || !newPassword || !confirmPassword}
                        onClick={handleResetPassword}
                        sx={{
                          bgcolor: colors.primary,
                          color: '#fff',
                          px: 4,
                          py: 1.5,
                          borderRadius: '10px',
                          fontWeight: 600,
                          textTransform: 'none',
                          '&:hover': {
                            bgcolor: colors.primaryDark,
                          },
                          '&.Mui-disabled': {
                            bgcolor: alpha(colors.primary, 0.4),
                            color: '#fff',
                          }
                        }}
                      >
                        {isResettingPassword ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ display: 'inline-block', width: 24, height: 24, borderRadius: '50%', border: '3px solid rgba(255,255,255,0.3)', borderTopColor: '#fff', animation: 'spin 1s linear infinite', marginRight: 8 }}></span>
                            {t("updating")}...
                          </Box>
                        ) : (
                          t("reset_password_button")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}

          {/* Prompts Tab */}
          {activeTab === 1 && (
            <Card
              elevation={0}
              sx={{
                mb: 4,
                borderRadius: '16px',
                border: `1px solid ${colors.border}`
              }}
            >
              <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                <SectionHeader
                  icon={<FormatColorTextOutlined />}
                  title={t("prompt_organization")}
                  description={t("prompt_description")}
                />

                {/* Multiline Text Input for the Prompt */}
                <TextField
                  label={t("prompt_organization")}
                  multiline
                  rows={8}
                  variant="outlined"
                  fullWidth
                  value={promptText || ""}
                  onChange={handlePromptChange}
                  InputProps={{
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.border,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.primaryDark,
                      },
                    }
                  }}
                  sx={{ mb: 4 }}
                />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSavePrompt}
                    sx={{
                      bgcolor: colors.primary,
                      color: '#fff',
                      px: 4,
                      py: 1.5,
                      borderRadius: '10px',
                      fontWeight: 600,
                      textTransform: 'none',
                      '&:hover': {
                        bgcolor: colors.primaryDark,
                      }
                    }}
                  >
                    {isSavingPrompt ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ display: 'inline-block', width: 24, height: 24, borderRadius: '50%', border: '3px solid rgba(255,255,255,0.3)', borderTopColor: '#fff', animation: 'spin 1s linear infinite', marginRight: 8 }}></span>
                        {t("saving")}
                      </Box>
                    ) : (
                      t("save_prompt")
                    )}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}

          {/* Only render billing tab if the user is not a regular user */}
          {!isRegularUser && activeTab === 2 && <BillingInfo />}

          {/* User Management Tab - Only visible to admin users */}
          {isAdmin && activeTab === (isRegularUser ? 2 : 3) && <UserManagement />}

          {/* Support tab content */}
          {activeTab === (isRegularUser ? (isAdmin ? 3 : 2) : (isAdmin ? 4 : 3)) && (
            <Card
              elevation={0}
              sx={{
                mb: 4,
                borderRadius: '16px',
                border: `1px solid ${colors.border}`
              }}
            >
              <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                <SectionHeader
                  icon={<HelpOutline />}
                  title={t("support_tab")}
                  description={t("support_description")}
                />

                {/* Dropdown to Select Support Object */}
                <FormControl fullWidth sx={{ mt: 1, mb: errors.supportObject ? 0 : 3 }}>
                  <InputLabel id="support-object-label">
                    {t("support_object")}
                  </InputLabel>
                  <Select
                    labelId="support-object-label"
                    value={supportObject}
                    onChange={(e) => setSupportObject(e.target.value)}
                    label={t("support_object")}
                    error={!!errors.supportObject}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.supportObject ? colors.error : colors.border,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.supportObject ? colors.error : colors.primaryDark,
                      },
                    }}
                  >
                    <MenuItem value="Documents manquants">
                      {t("missing_documents")}
                    </MenuItem>
                    <MenuItem value="Problème technique">
                      {t("technical_issue")}
                    </MenuItem>
                    <MenuItem value="Conseil juridique">
                      {t("legal_advice")}
                    </MenuItem>
                    <MenuItem value="Amélioration">
                      {t("ameleration_support")}
                    </MenuItem>
                    <MenuItem value="Guide des procédures">
                      {t("procedure_guidance")}
                    </MenuItem>
                    <MenuItem value="Retour d'information sur le système">
                      {t("system_feedback")}
                    </MenuItem>
                    <MenuItem value="Autre">{t("other")}</MenuItem>
                  </Select>
                  {errors.supportObject && (
                    <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 2 }}>
                      {errors.supportObject}
                    </Typography>
                  )}
                </FormControl>

                {/* Multiline Text Area for Description */}
                <TextField
                  label={t("support_description")}
                  multiline
                  rows={6}
                  variant="outlined"
                  fullWidth
                  value={supportDescription}
                  onChange={(e) => setSupportDescription(e.target.value)}
                  error={!!errors.supportDescription}
                  helperText={errors.supportDescription}
                  sx={{ mb: 3 }}
                  InputProps={{
                    sx: {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.supportDescription ? colors.error : colors.border,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: errors.supportDescription ? colors.error : colors.primaryDark,
                      },
                    }
                  }}
                />

                {/* Attached file information */}
                {attachedFile && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 3,
                      p: 2,
                      borderRadius: '8px',
                      bgcolor: colors.primaryLight
                    }}
                  >
                    <FileUploadOutlined sx={{ color: colors.primary, mr: 1.5 }} />
                    <Typography variant="body2" sx={{ color: colors.primary, fontWeight: 500 }}>
                      {t("selected_file")}: <span style={{ fontWeight: 'normal' }}>{attachedFile.name}</span>
                    </Typography>
                  </Box>
                )}

                {/* Attach File and Send Buttons */}
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexDirection: isSmall ? "column" : "row",
                  "& > *": {
                    width: isSmall ? "100%" : "auto"
                  }
                }}>
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    onClick={handleFileClick}
                    disabled={isSendingSupport}
                    sx={{
                      borderColor: colors.border,
                      color: colors.textSecondary,
                      textTransform: 'none',
                      borderRadius: '8px',
                      py: 1.25,
                      px: 3,
                      '&:hover': {
                        borderColor: colors.primary,
                        bgcolor: alpha(colors.primary, 0.05),
                      }
                    }}
                  >
                    {t("attach_file")}
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSupportSubmit}
                    disabled={!supportObject || !supportDescription || isSendingSupport}
                    sx={{
                      bgcolor: colors.primary,
                      color: '#fff',
                      px: 4,
                      py: 1.25,
                      borderRadius: '8px',
                      fontWeight: 600,
                      ml: isSmall ? 0 : "auto",
                      mt: isSmall ? 2 : 0,
                      textTransform: 'none',
                      '&:hover': {
                        bgcolor: colors.primaryDark,
                      },
                      '&.Mui-disabled': {
                        bgcolor: alpha(colors.primary, 0.4),
                        color: '#fff',
                      }
                    }}
                  >
                    {isSendingSupport ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ display: 'inline-block', width: 20, height: 20, borderRadius: '50%', border: '3px solid rgba(255,255,255,0.3)', borderTopColor: '#fff', animation: 'spin 1s linear infinite', marginRight: 8 }}></span>
                        {t("sending")}
                      </Box>
                    ) : (
                      t("send")
                    )}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}

          {/* Logout tab content */}
          {activeTab === (isRegularUser ? (isAdmin ? 4 : 3) : (isAdmin ? 5 : 4)) && (
            <Card
              elevation={0}
              sx={{
                borderRadius: '16px',
                border: `1px solid ${colors.border}`,
                overflow: 'hidden'
              }}
            >
              <Box
                sx={{
                  p: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  bgcolor: colors.surface
                }}
              >
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    bgcolor: alpha(colors.error, 0.1),
                    color: colors.error,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3
                  }}
                >
                  <ExitToApp sx={{ fontSize: 36 }} />
                </Box>

                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    color: colors.text,
                    mb: 2
                  }}
                >
                  {t("logout")}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    textAlign: "center",
                    color: colors.textSecondary,
                    maxWidth: 500
                  }}
                >
                  {t("disconnect_confirmation")}
                </Typography>

                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<ExitToApp />}
                  onClick={handleLogout}
                  sx={{
                    bgcolor: colors.error,
                    color: '#fff',
                    px: { xs: 4, sm: 6 },
                    py: 1.5,
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                    '&:hover': {
                      bgcolor: alpha(colors.error, 0.8),
                    }
                  }}
                >
                  {t("logout_ver")}
                </Button>
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsScreen;