// src/redux/actions/contractActions.js
import { backendClient } from "../../api/backend";
import { EventSourcePolyfill } from 'event-source-polyfill';

import {
    SET_CONTRACT_LOADING,
    UPDATE_CONTRACT_CONTENT,
    SET_CONTRACT_DATA,
    SET_CONTRACT_ERROR,
    SET_HIGHLIGHT_ERROR,
    RESET_CONTRACT,
    SAVE_CONTRACT_REQUEST,
    SAVE_CONTRACT_SUCCESS,
    SAVE_CONTRACT_FAILURE,
    FETCH_CONTRACT_HISTORY_REQUEST,
    FETCH_CONTRACT_HISTORY_SUCCESS,
    FETCH_CONTRACT_HISTORY_FAILURE,
    ADD_HISTORY_ENTRY,
    SHARE_CONTRACT_REQUEST,
    SHARE_CONTRACT_SUCCESS,
    SHARE_CONTRACT_FAILURE,
    DELETE_CONTRACTS_REQUEST,
    DELETE_CONTRACTS_SUCCESS,
    DELETE_CONTRACTS_FAILURE,
    FETCH_CONTRACTS_REQUEST,
    FETCH_CONTRACTS_SUCCESS,
    FETCH_CONTRACTS_FAILURE,
    SEARCH_CONTRACTS_REQUEST,
    SEARCH_CONTRACTS_SUCCESS,
    SEARCH_CONTRACTS_FAILURE,
    // Contract message action types
    CONTRACT_MESSAGE_CLEAR,
    CONTRACT_MESSAGE_SET,
    CONTRACT_MESSAGE_ADD_USER,
    CONTRACT_MESSAGE_STREAM_UPDATE,
    CONTRACT_MESSAGE_STREAM_DONE,
    CONTRACT_MESSAGE_ERROR,

    // New action types for conversation management
    CLEAR_CHAT_REQUEST,
    CLEAR_CHAT_SUCCESS,
    CLEAR_CHAT_FAILURE,
    CREATE_CONTRACT_CONVERSATION_REQUEST,
    CREATE_CONTRACT_CONVERSATION_SUCCESS,
    CREATE_CONTRACT_CONVERSATION_FAILURE,
    RISK_ANALYSIS_RESET,
    RISK_ANALYSIS_ADD,
    RISK_ANALYSIS_ERROR,
    RISK_ANALYSIS_COMPLETE,
    RISK_ANALYSIS_REQUEST,
    UPDATE_CONTRACT_STATUS,
    UPDATE_CONTRACT_STATUS_SUCCESS,
    UPDATE_CONTRACT_STATUS_FAILURE,
    CLAUSES_REQUEST,
    CLAUSES_RESET,
    CLAUSES_ADD,
    CLAUSES_ERROR,
    CLAUSES_COMPLETE,
    SUGGESTED_CLAUSES_REQUEST,
    SUGGESTED_CLAUSES_RESET,
    SUGGESTED_CLAUSES_ADD,
    SUGGESTED_CLAUSES_ERROR,
    SUGGESTED_CLAUSES_COMPLETE,
    FILTER_CONTRACTS_REQUEST,
    FILTER_CONTRACTS_SUCCESS,
    FILTER_CONTRACTS_FAILURE,
    UPDATE_CONTRACT_OPTIMISTIC,

} from "./types";
import { backendUrl } from "../../config";
import { Description } from "@mui/icons-material";

// Action Creators
export const resetContract = () => async (dispatch) => {
    dispatch({ type: RESET_CONTRACT })
}

export const updateContractContent = (content) => ({
    type: UPDATE_CONTRACT_CONTENT,
    payload: content
});

export const setContractData = (contract) => ({
    type: SET_CONTRACT_DATA,
    payload: contract
});

export const setLoading = (isLoading) => ({
    type: SET_CONTRACT_LOADING,
    payload: isLoading
});

export const setError = (error) => ({
    type: SET_CONTRACT_ERROR,
    payload: error
});




// Fetch contracts
export const fetchContracts = (userId, limit, offset, forceRefresh = false) => async (dispatch, getState) => {
    // Get current state
    const state = getState();
    const cacheKey = `${offset}_${limit}`;

    // Safely check if contractsCache exists and has the data we need
    const contractsCache = state.contract?.contractsCache || {};
    const cachedContracts = contractsCache[cacheKey];

    // Use cache only if available AND not forcing refresh
    if (cachedContracts && !forceRefresh) {
        // Use cached data if available
        dispatch({
            type: FETCH_CONTRACTS_SUCCESS,
            payload: cachedContracts,
            meta: { offset, limit }
        });
        return cachedContracts;
    }

    // Otherwise fetch from API
    dispatch({ type: FETCH_CONTRACTS_REQUEST });
    try {
        const contracts = await backendClient.getUserContracts(userId, limit, offset);
        dispatch({
            type: FETCH_CONTRACTS_SUCCESS,
            payload: contracts,
            meta: { offset, limit }
        });
        return contracts;
    } catch (error) {
        dispatch({
            type: FETCH_CONTRACTS_FAILURE,
            payload: error.message
        });
        return [];
    }
};


// Async Actions
export const fetchContract = (contractId) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const contractData = await backendClient.getContract(contractId);
            console.log('contract_fetched', contractData)
            dispatch(setContractData(contractData));
        } catch (error) {
            console.error('Error fetching contract:', error);
            dispatch(setError(error.message || 'Failed to load contract'));
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const saveContract = (contractId, content) => {
    return async (dispatch) => {
        try {
            await backendClient.updateContract(contractId, { content });
            return true;
        } catch (error) {
            console.error('Error saving contract:', error);
            dispatch(setError(error.message || 'Failed to save contract'));
            return false;
        }
    };
};


export const fetchContractHistory = (contractId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_CONTRACT_HISTORY_REQUEST });

        // Using backendClient instead of api
        const response = await backendClient.get(`api/contract/history/${contractId}`);
        const data = await response.json();

        dispatch({
            type: FETCH_CONTRACT_HISTORY_SUCCESS,
            payload: data
        });

        return data;
    } catch (error) {
        dispatch({
            type: FETCH_CONTRACT_HISTORY_FAILURE,
            payload: error.message || 'Failed to fetch contract history'
        });
        throw error;
    }
};

// Action to add a new history entry (for real-time updates)
export const addHistoryEntry = (entry) => ({
    type: ADD_HISTORY_ENTRY,
    payload: entry
});

// Action to restore from a historical snapshot
export const restoreFromSnapshot = (contractId, snapshotId) => async (dispatch) => {
    try {
        dispatch({ type: SAVE_CONTRACT_REQUEST });

        // Using backendClient instead of api
        const payload = { snapshot_id: snapshotId };
        const response = await backendClient.post(`api/contract/restore/${contractId}`, payload);
        const data = await response.json();

        dispatch({
            type: SAVE_CONTRACT_SUCCESS,
            payload: data
        });

        // If the status property exists in the response, update the status in the UI
        if (data && data.status) {
            // Dispatch action to update contract status in the UI
            dispatch({
                type: UPDATE_CONTRACT_STATUS_SUCCESS,
                payload: data.status
            });
        }

        // Important: Fetch the contract and its history to update the UI completely
        await Promise.all([
            dispatch(fetchContract(contractId)),
            dispatch(fetchContractHistory(contractId))
        ]);

        return data;
    } catch (error) {
        dispatch({
            type: SAVE_CONTRACT_FAILURE,
            payload: error.message || 'Failed to restore from snapshot'
        });
        throw error;
    }
};


export const shareContract = (contractId, selectedUsers, userDomain) => async (dispatch) => {
    try {
        dispatch({ type: SHARE_CONTRACT_REQUEST });


        const users = selectedUsers.map(user => ({
            user_id: user.id,
            role: user.role,
        }));
        // Using backendClient to share the contract
        const response = await backendClient.shareContract(contractId, users, userDomain);

        console.log('responsxxxe', response)

        dispatch({
            type: SHARE_CONTRACT_SUCCESS,
            payload: {
                contractId,
                collaborators: selectedUsers,
            }
        });

        return response;
    } catch (error) {
        dispatch({
            type: SHARE_CONTRACT_FAILURE,
            payload: error.message || 'Failed to share contract'
        });
        throw error;
    }
};


export const saveContractWithRetry = (contractId, content) => async (dispatch) => {
    let attempts = 0;
    const maxAttempts = 3;
    const backoffMs = 1000; // Start with 1 second

    const attemptSave = async () => {
        try {
            attempts++;
            return await dispatch(saveContract(contractId, content));
        } catch (error) {
            console.error(`Save attempt ${attempts} failed:`, error);

            if (attempts < maxAttempts) {
                // Calculate backoff with jitter
                const jitter = Math.random() * 0.3 * backoffMs;
                const delay = backoffMs * Math.pow(1.5, attempts - 1) + jitter;

                console.log(`Retrying in ${Math.round(delay / 1000)}s...`);

                // Wait and retry
                return new Promise(resolve => {
                    setTimeout(() => resolve(attemptSave()), delay);
                });
            } else {
                // All attempts failed, rethrow the error
                throw error;
            }
        }
    };

    return attemptSave();
};

export const deleteContracts = (contractIds, userId) => async (dispatch) => {

    dispatch({ type: DELETE_CONTRACTS_REQUEST });

    try {
        // Make sure your backendClient.deleteContracts accepts both params
        const response = await backendClient.deleteContracts(contractIds, userId);

        // Pass both the contractIds and userId to the success action
        dispatch({
            type: DELETE_CONTRACTS_SUCCESS,
            payload: {
                contractIds,
                userId
            }
        });

        return response;
    } catch (error) {
        console.error('Error deleting contracts:', error);

        dispatch({
            type: DELETE_CONTRACTS_FAILURE,
            payload: error.message || 'Failed to delete contracts'
        });

        throw error;
    }
};
// Search contracts
export const searchContracts = (userId, searchTerm) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_CONTRACTS_REQUEST });

        const contracts = await backendClient.searchContracts(userId, searchTerm);

        dispatch({
            type: SEARCH_CONTRACTS_SUCCESS,
            payload: contracts
        });

        return contracts;
    } catch (error) {
        console.error('Error searching contracts:', error);
        dispatch({
            type: SEARCH_CONTRACTS_FAILURE,
            payload: error.message || 'Failed to search contracts'
        });
        throw error;
    }
};



////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////// CONVERSATION ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const clearContractMessages = () => ({
    type: CONTRACT_MESSAGE_CLEAR
});

export const setContractMessages = (messages) => ({
    type: CONTRACT_MESSAGE_SET,
    payload: messages
});


export const addUserMessage = (content) => {
    return {
        type: CONTRACT_MESSAGE_ADD_USER,
        payload: {
            content: content,
            role: 'user',
            status: 'success',
            created_at: new Date().toISOString(),
        },
    };
};

export const streamAssistantUpdate = (message) => ({
    type: CONTRACT_MESSAGE_STREAM_UPDATE,
    payload: {
        id: message.id,
        content: convertNewlinesToBreaks(message.content),
        sender: "bot",
        status: message.status,
        created_at: message.created_at,
    },
});


export const finalizeAssistantMessage = (message) => ({
    type: CONTRACT_MESSAGE_STREAM_DONE,
    payload: {
        id: message.id,
        content: convertNewlinesToBreaks(message.content),
        sender: "bot",
        status: message.status,
        created_at: message.created_at,
    },
});

export const contractMessageError = (error) => ({
    type: CONTRACT_MESSAGE_ERROR,
    payload: error,
});


/**
 * Thunk to send a user message to the backend and stream back the assistant response via SSE.
 */
export const sendContractMessage = (
    conversationId,
    contractId,
    userId,
    userMessage,
) => async (dispatch) => {
    try {
        const tokenPayload = backendClient.getToken();
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // 1) Immediately dispatch the user's message locally
        dispatch(addUserMessage(userMessage));

        const token = `${tokenPayload.token_type
            .charAt(0)
            .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
                tokenPayload.access_token
            )}`;

        // 2) Build the SSE URL
        const fullUrl = `${backendUrl}api/contract/conversation/message/${conversationId}?`
            + `contract_id=${contractId}`
            + `&user_id=${userId}`
            + `&user_message=${encodeURIComponent(userMessage.replace(/<br\s*\/?>/gi, '\n').trim())}`
            + `&timezone=${encodeURIComponent(timezone)}`;

        console.log("SSE connection initialized.");

        // 3) Open the SSE connection
        const eventSource = new EventSourcePolyfill(fullUrl, {
            headers: {
                Authorization: token,
                Connection: 'keep-alive'
            },
            heartbeatTimeout: 1200000
        });

        eventSource.onmessage = (event) => {
            console.log("SSE message received:", event);

            if (!event.data) {
                console.warn("Received empty event data.");
                return;
            }

            try {
                const msgData = JSON.parse(event.data);
                console.log("Parsed message data:", msgData);

                if (msgData.status === "PENDING") {
                    console.log("   :", msgData);
                    dispatch(streamAssistantUpdate(msgData));
                }
                else if (msgData.status === "SUCCESS" || msgData.status === "ERROR") {
                    console.log("Finalizing message with status:", msgData.status);
                    dispatch(finalizeAssistantMessage(msgData));

                    console.log("Closing SSE connection.");
                    eventSource.close();
                }
                else {
                    console.warn("Unhandled SSE event status:", msgData.status);
                }
            } catch (err) {
                console.error("Error parsing message data:", err);
            }
        };

        eventSource.onopen = () => {
            console.log("SSE connection opened successfully.");
        };

        eventSource.onerror = (error) => {
            console.error("SSE error:", error);
        };
    } catch (err) {
        console.error("sendContractMessage error", err);
        dispatch(contractMessageError(err.message || 'An error occurred while sending message'));
    }
};

/**
 * Create a new conversation for chat
 */
export const createContractConversation = (contractId, userId) => async (dispatch) => {
    dispatch({ type: CREATE_CONTRACT_CONVERSATION_REQUEST });

    try {
        const response = await backendClient.createContractConversation(contractId, userId);


        if (response && response.conversation_id) {
            dispatch({
                type: CREATE_CONTRACT_CONVERSATION_SUCCESS,
                payload: response.conversation_id
            });

            return response
        } else {
            throw new Error('Invalid response format. Missing conversation ID.');
        }
    } catch (error) {
        console.error('Error creating conversation:', error);
        dispatch({
            type: CREATE_CONTRACT_CONVERSATION_FAILURE,
            payload: error.message || 'Failed to create conversation'
        });
        throw error;
    }
};

/**
 * Clear all messages from a conversation
 */
export const clearChat = (conversationId) => async (dispatch) => {
    dispatch({ type: CLEAR_CHAT_REQUEST });

    try {
        await backendClient.delete(`api/contract/conversation/clear/${conversationId}`);

        dispatch({ type: CLEAR_CHAT_SUCCESS });
        // Also clear messages in the UI
        dispatch(clearContractMessages());

        return true;
    } catch (error) {
        console.error('Error clearing chat:', error);
        dispatch({
            type: CLEAR_CHAT_FAILURE,
            payload: error.message || 'Failed to clear chat'
        });
        throw error;
    }
};

///////// adapt payload


// Helper functions
const convertNewlinesToBreaks = (text) => {
    if (!text) return "";
    return text.replace(/\n/g, "<br/>");
};

// Action to set contract highlight for risk analysis
export const setHighlightError = (errorText) => ({
    type: SET_HIGHLIGHT_ERROR,
    payload: errorText
});

// Action to reset risk analysis state
export const resetRiskAnalysis = () => ({
    type: RISK_ANALYSIS_RESET
});

// Action to add a risk to the state
export const addRiskAnalysis = (risk) => ({
    type: RISK_ANALYSIS_ADD,
    payload: risk
});

// Action to set risk analysis error
export const riskAnalysisError = (error) => ({
    type: RISK_ANALYSIS_ERROR,
    payload: error
});

// Action to mark risk analysis as complete
export const riskAnalysisComplete = () => ({
    type: RISK_ANALYSIS_COMPLETE
});

// Action to start risk analysis and set loadingRisk to true
export const riskAnalysisRequest = () => ({
    type: RISK_ANALYSIS_REQUEST
});

// Async action to get risk analysis via SSE
export const getRiskAnalysis = (contractId) => async (dispatch) => {
    try {
        // Start loading state
        dispatch(riskAnalysisRequest());

        // Clear any existing risks before starting a new stream
        dispatch(resetRiskAnalysis());

        const tokenPayload = backendClient.getToken();
        const token =
            `${tokenPayload.token_type.charAt(0).toUpperCase()}${tokenPayload.token_type.slice(1)} ` +
            `${encodeURIComponent(tokenPayload.access_token)}`;

        // Build the SSE URL
        const fullUrl = `${backendUrl}api/contract/risk-analysis/${contractId}`;

        const eventSource = new EventSourcePolyfill(fullUrl, {
            headers: {
                Authorization: token,
                Connection: 'keep-alive',
            },
            heartbeatTimeout: 1200000, // 20 minutes
        });

        eventSource.onmessage = (event) => {
            if (!event.data) {
                console.warn('Received empty risk analysis data');
                return;
            }

            try {
                const riskPayload = JSON.parse(event.data);

                // Build the risk object; note the inclusion of risk_id
                const risk = {
                    risk_id: riskPayload.risk_id,
                    // Determine risk level if provided; default to 'Low Risk'
                    level: riskPayload.risk_level
                        ? (riskPayload.risk_level === 'High' ? 'High Risk'
                            : (riskPayload.risk_level === 'Moderate' ? 'Medium Risk' : 'Low Risk'))
                        : 'Low Risk',
                    // Only update fields if they exist in the payload
                    erreur: riskPayload.excerpt ? convertNewlinesToBreaks(riskPayload.excerpt) : undefined,
                    details: convertNewlinesToBreaks(riskPayload.observations) || null,
                    suggestion: convertNewlinesToBreaks(riskPayload.mitigation_suggestion) || null,
                    status: riskPayload.status,
                };

                console.log('Dispatching risk analysis update:', risk);
                // Dispatch the same action; the reducer will handle merging
                dispatch(addRiskAnalysis(risk));
            } catch (err) {
                console.error('Error parsing risk analysis data:', err);
            }
        };

        eventSource.onopen = () => {
            console.log('Risk analysis SSE connection opened successfully.');
        };

        eventSource.onerror = (error) => {
            console.error('Risk analysis SSE error:', error);
            dispatch(riskAnalysisError(error.message || 'Failed to connect to risk analysis service'));
            eventSource.close();
            dispatch(riskAnalysisComplete());
        };

        // Add event handler for when the stream is done
        eventSource.addEventListener('done', () => {
            console.log('Risk analysis stream complete');
            eventSource.close();
            dispatch(riskAnalysisComplete());
        });

    } catch (err) {
        console.error('Risk analysis error:', err);
        dispatch(riskAnalysisError(err.message || 'An error occurred while fetching risk analysis.'));
        dispatch(riskAnalysisComplete());
    }
};


// Action to update contract status
export const updateContractStatus = (contractId, status, content) => async (dispatch) => {
    try {
        // Dispatch request action to show loading state if needed
        dispatch({ type: UPDATE_CONTRACT_STATUS });

        // Update contract status via backend client
        const updatedContract = await backendClient.updateContract(contractId, { status, content });

        // Dispatch success action with updated contract
        dispatch({
            type: UPDATE_CONTRACT_STATUS_SUCCESS,
            payload: status
        });

        return;

    } catch (error) {
        console.error('Failed to update contract status', error);

        // Dispatch failure action
        dispatch({
            type: UPDATE_CONTRACT_STATUS_FAILURE,
            payload: error.message || 'Failed to update contract status'
        });

        throw error;
    }
};



////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export const clausesRequest = () => ({
    type: CLAUSES_REQUEST
});
// Action to reset risk analysis state
export const resetClauses = () => ({
    type: CLAUSES_RESET
});

// Action to add a risk to the state
export const addClause = (clause) => ({
    type: CLAUSES_ADD,
    payload: clause
});

// Action to set risk analysis error
export const clauseError = (error) => ({
    type: CLAUSES_ERROR,
    payload: error
});

// Action to mark risk analysis as complete
export const clauseComplete = () => ({
    type: CLAUSES_COMPLETE
});



// Async action to get risk analysis via SSE
export const getClauses = (contractId) => async (dispatch) => {
    try {
        // Start loading state
        dispatch(clausesRequest());

        // Clear any existing risks before starting a new stream
        dispatch(resetClauses());

        const tokenPayload = backendClient.getToken();
        const token =
            `${tokenPayload.token_type.charAt(0).toUpperCase()}${tokenPayload.token_type.slice(1)} ` +
            `${encodeURIComponent(tokenPayload.access_token)}`;

        // Build the SSE URL
        const fullUrl = `${backendUrl}api/contract/extract-clause/${contractId}`;

        const eventSource = new EventSourcePolyfill(fullUrl, {
            headers: {
                Authorization: token,
                Connection: 'keep-alive',
            },
            heartbeatTimeout: 1200000, // 20 minutes
        });

        eventSource.onmessage = (event) => {
            if (!event.data) {
                console.warn('Received empty clause explanation data');
                return;
            }

            try {
                const clausePayload = JSON.parse(event.data);

                const clause = {
                    title: convertNewlinesToBreaks(clausePayload.title),
                    description: convertNewlinesToBreaks(clausePayload.description),
                    text: convertNewlinesToBreaks(clausePayload.text),
                };

                console.log('Dispatching clause:', clause);
                // Dispatch the risk to add it to the state
                dispatch(addClause(clause));
            } catch (err) {
                console.error('Error parsing risk analysis data:', err);
            }
        };

        eventSource.onopen = () => {
            console.log('Risk analysis SSE connection opened successfully.');
        };

        eventSource.onerror = (error) => {
            console.error('Risk analysis SSE error:', error);
            dispatch(clauseError(error.message || 'Failed to connect to risk analysis service'));
            eventSource.close();
            dispatch(clauseComplete());
        };

        // Add event handler for when the stream is done
        eventSource.addEventListener('done', () => {
            console.log('Risk analysis stream complete');
            eventSource.close();
            dispatch(clauseComplete());
        });

    } catch (err) {
        console.error('Clause error:', err);
        dispatch(clauseError(err.message || 'An error occurred while fetching risk analysis.'));
        dispatch(clauseComplete());
    }
};


/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////

export const SuggestedClausesRequest = () => ({
    type: SUGGESTED_CLAUSES_REQUEST
});
// Action to reset risk analysis state
export const resetSuggestedClauses = () => ({
    type: SUGGESTED_CLAUSES_RESET
});

// Action to add a risk to the state
export const addSuggestedClause = (clause) => ({
    type: SUGGESTED_CLAUSES_ADD,
    payload: clause
});

// Action to set risk analysis error
export const SuggestedClauseError = (error) => ({
    type: SUGGESTED_CLAUSES_ERROR,
    payload: error
});

// Action to mark risk analysis as complete
export const SuggestedClauseComplete = () => ({
    type: SUGGESTED_CLAUSES_COMPLETE
});



// Async action to get risk analysis via SSE
export const getSuggestedClauses = (contractId) => async (dispatch) => {
    try {
        // Start loading state
        dispatch(SuggestedClausesRequest());

        // Clear any existing risks before starting a new stream
        dispatch(resetSuggestedClauses());

        const tokenPayload = backendClient.getToken();
        const token =
            `${tokenPayload.token_type.charAt(0).toUpperCase()}${tokenPayload.token_type.slice(1)} ` +
            `${encodeURIComponent(tokenPayload.access_token)}`;

        // Build the SSE URL
        const fullUrl = `${backendUrl}api/contract/suggestions/${contractId}`;

        const eventSource = new EventSourcePolyfill(fullUrl, {
            headers: {
                Authorization: token,
                Connection: 'keep-alive',
            },
            heartbeatTimeout: 1200000, // 20 minutes
        });

        eventSource.onmessage = (event) => {
            if (!event.data) {
                console.warn('Received empty clause explanation data');
                return;
            }

            try {
                const clausePayload = JSON.parse(event.data);

                const clause = {
                    title: convertNewlinesToBreaks(clausePayload.title),
                    description: convertNewlinesToBreaks(clausePayload.description),
                    definition: convertNewlinesToBreaks(clausePayload.definition),
                };

                console.log('Dispatching clause:', clause);
                // Dispatch the risk to add it to the state
                dispatch(addSuggestedClause(clause));
            } catch (err) {
                console.error('Error parsing suggested clause data:', err);
            }
        };

        eventSource.onopen = () => {
            console.log('clause recommandation SSE connection opened successfully.');
        };

        eventSource.onerror = (error) => {
            console.error('clause recommandation SSE error:', error);
            dispatch(SuggestedClauseError(error.message || 'Failed to connect to clause recommandation service'));
            eventSource.close();
            dispatch(SuggestedClauseComplete());
        };

        // Add event handler for when the stream is done
        eventSource.addEventListener('done', () => {
            console.log('clause recommandation stream complete');
            eventSource.close();
            dispatch(SuggestedClauseComplete());
        });

    } catch (err) {
        console.error('Clause error:', err);
        dispatch(SuggestedClauseError(err.message || 'An error occurred while fetching clause recommandations.'));
        dispatch(SuggestedClauseComplete());
    }
};



export const filterContracts = (filterParams, limit = 10, offset = 0) => async (dispatch) => {
    try {
        dispatch({ type: FILTER_CONTRACTS_REQUEST });

        const filteredContracts = await backendClient.filterContracts(filterParams, limit, offset);

        dispatch({
            type: FILTER_CONTRACTS_SUCCESS,
            payload: filteredContracts
        });

        return filteredContracts;
    } catch (error) {
        console.error('Error filtering contracts:', error);
        dispatch({
            type: FILTER_CONTRACTS_FAILURE,
            payload: error.message || 'Failed to filter contracts'
        });
        throw error;
    }
};

// Action to update a contract with optimistic UI updates
export const handleUpdateContract = (contractId, contractData) => async (dispatch, getState) => {
    try {
        // Get current state
        const state = getState();
        const originalContract = state.contract.contracts.find(c => c.id === contractId);

        // Dispatch optimistic update action
        dispatch({
            type: UPDATE_CONTRACT_OPTIMISTIC,
            payload: {
                id: contractId,
                contractData
            }
        });

        // Perform the actual API call in the background
        const updatedContract = await backendClient.updateContract(contractId, contractData);

        // Refresh contracts in the background to ensure consistency
        // Only if we're updating more than just the title
        if (Object.keys(contractData).length > 1 || !contractData.hasOwnProperty('title')) {
            const userId = state.auth?.user?.user?.id;
            if (userId) {
                // We don't need to await this, let it happen in the background
                dispatch(fetchContracts(userId, 10, 0, true));
            }
        }

        return updatedContract;
    } catch (error) {
        console.error('Error updating contract:', error);

        // If the update fails, revert the optimistic update
        const state = getState();
        const originalContract = state.contract.contracts.find(c => c.id === contractId);

        if (originalContract) {
            // Create a revert action with the original data
            // Only revert the properties that were attempted to be updated
            const revertData = {};
            Object.keys(contractData).forEach(key => {
                revertData[key] = originalContract[key];
            });

            dispatch({
                type: UPDATE_CONTRACT_OPTIMISTIC,
                payload: {
                    id: contractId,
                    contractData: revertData
                }
            });
        }

        throw error;
    }
};