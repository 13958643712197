import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  TextField,
  CircularProgress,
  Typography,
  Tooltip,
  Paper,
  Chip,
  Fade
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
  Close as CloseIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    fontFamily: "'Nunito', sans-serif",
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2B3175',
      borderWidth: '1px',
    }
  }
}));

const MessageInput = ({
  setUserMessage,
  setUploadController,
  setIsUploadingDocuments,
  setIsMessagePending,
  currentLang,
  selectedFiles,
  isUploadingDocuments,
  isMessagePending,
  userMessage,
  handleTextChange,
  submit,
  uploadController,
  eventSourceRef,
  windowWidth,
  enqueueSnackbar,
  t,
  setSelectedFiles,
  textInputRows,
  textFocusRef,
  fileInputRef,
  handleFileChange,
  SendIcon,
  StopIcon,
  DisabledSendIcon
}) => {
  const [inputHeight, setInputHeight] = useState(80);

  useEffect(() => {
    if (textFocusRef.current) {
      textFocusRef.current.style.height = '22px';

      const lineHeight = 22;
      const maxLines = 5; // Reduced to prevent too much growth
      const maxHeight = lineHeight * maxLines;

      const calculatedHeight = Math.min(textFocusRef.current.scrollHeight, maxHeight);

      const baseHeight = 40;
      const newInputHeight = baseHeight + calculatedHeight;

      setInputHeight(newInputHeight);

      textFocusRef.current.style.height = `${calculatedHeight}px`;
      textFocusRef.current.style.overflowY = calculatedHeight >= maxHeight ? 'auto' : 'hidden';
    }
  }, [userMessage]);

  console.log('toto', eventSourceRef.current)

  return (
    <Paper
      elevation={2}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        // minHeight: '100px',
        maxHeight: '180px',
        px: 1,
        pb: 0,
        backgroundColor: 'white',
      }}
    >
      {(isUploadingDocuments || selectedFiles?.length > 0) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            borderRadius: '8px',
            marginBottom: '8px',
            height: 'auto',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#bbbbbb',
              borderRadius: '4px',
            }
          }}
        >
          {isUploadingDocuments ? (
            <Fade in={true}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  p: 1
                }}
              >
                <CircularProgress size={20} thickness={4} sx={{ color: '#2B3175' }} />
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: '12px',
                    color: 'text.secondary',
                    fontWeight: 500
                  }}
                >
                  {t('uploading_documents')}
                </Typography>
              </Box>
            </Fade>
          ) : (
            selectedFiles?.length > 0 && (
              <Fade in={true}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                    backgroundColor: 'transparent',
                    borderRadius: '8px'
                  }}
                >
                  {selectedFiles?.map((file, index) => (
                    <Chip
                      key={index}
                      icon={<DescriptionIcon sx={{ color: '#616161', fontSize: 16 }} />}
                      label={
                        <Tooltip title={file.name} arrow placement='top'>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: '100px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: 500
                            }}
                          >
                            {file.name}
                          </Typography>
                        </Tooltip>
                      }
                      onDelete={() => {
                        const updatedFiles = selectedFiles?.filter(
                          (_, i) => i !== index
                        );
                        setSelectedFiles(updatedFiles);
                      }}
                      deleteIcon={<CloseIcon sx={{ fontSize: 16 }} />}
                      sx={{
                        mx: 0.5,
                        mb: 1,
                        backgroundColor: '#f1f1f1',
                        border: '1px solid #e0e0e0',
                        borderRadius: '6px',
                        '& .MuiChip-deleteIcon': {
                          color: '#ff5252',
                          '&:hover': {
                            color: '#d32f2f'
                          }
                        }
                      }}
                    />
                  ))}
                </Box>
              </Fade>
            )
          )}
        </Box>
      )}

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-start',
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
          overflow: 'hidden'
        }}
      >
        <StyledTextField
          className='message-input'
          fullWidth
          variant='outlined'
          placeholder={t('write_message_placeholder')}
          multiline
          disabled={isUploadingDocuments || isMessagePending}
          minRows={1}
          maxRows={5}
          value={userMessage}
          onChange={handleTextChange}
          onKeyDown={event => {
            if (event.key === 'Enter' && !event.altKey && !event.shiftKey) {
              event.preventDefault();
              if (!isMessagePending && userMessage.trim()) submit();
            } else if (
              (event.altKey || event.shiftKey) &&
              event.key === 'Enter'
            ) {
              setUserMessage(prev => `${prev}\n`);
            }
          }}
          inputRef={textFocusRef}
          dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
          sx={{
            '& .MuiOutlinedInput-root': {
              paddingRight: currentLang === 'ar' ? '10px' : '100px',
              paddingLeft: currentLang === 'ar' ? '100px' : '10px'
            }
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            right: currentLang === 'ar' ? 'unset' : '8px',
            left: currentLang === 'ar' ? '8px' : 'unset',
            display: 'flex',
            alignItems: 'center',
            top: '10px'
          }}
        >
          <IconButton
            disabled={isUploadingDocuments || isMessagePending}
            onClick={() => fileInputRef.current.click()}
            sx={{
              color: isUploadingDocuments || isMessagePending ? '#bdbdbd' : '#2B3175',
              backgroundColor: 'transparent',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(43, 49, 117, 0.04)'
              }
            }}
            size="small"
          >
            <UploadFileIcon sx={{ fontSize: 20 }} />
          </IconButton>

          <IconButton
            onClick={() => {
              if (isUploadingDocuments && uploadController) {
                uploadController.abort();
                setUploadController(null);
                setIsUploadingDocuments(false);
                setSelectedFiles([]);
                setIsMessagePending(false);
                enqueueSnackbar(t('conversation_stopped'), {
                  variant: 'info'
                });
              } else if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
                setIsMessagePending(false);
                setIsUploadingDocuments(false);
                setSelectedFiles([]);
                setUserMessage('');
                enqueueSnackbar(t('conversation_stopped'), { variant: 'info' });
              } else {
                submit();
              }
            }}
            disabled={Boolean(!userMessage.trim() && !eventSourceRef.current)}
            sx={{
              ml: 1,
              color: !userMessage.trim() && !eventSourceRef.current ? '#bdbdbd' : '#2B3175',
              backgroundColor: !userMessage.trim() && !eventSourceRef.current ? 'transparent' : 'rgba(43, 49, 117, 0.08)',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: !userMessage.trim() && !eventSourceRef.current ? 'transparent' : 'rgba(43, 49, 117, 0.12)'
              }
            }}
            size="small"
          >
            <img
              src={
                !userMessage.trim() && !eventSourceRef.current
                  ? DisabledSendIcon
                  : isMessagePending
                    ? StopIcon
                    : SendIcon
              }
              alt='Send'
              style={{ height: 20 }}
            />
          </IconButton>
        </Box>

        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept='application/pdf, text/plain'
          multiple
        />
      </Box>
    </Paper>
  );
};

MessageInput.propTypes = {
  setUserMessage: PropTypes.func.isRequired,
  setUploadController: PropTypes.func.isRequired,
  setIsUploadingDocuments: PropTypes.func.isRequired,
  setIsMessagePending: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  selectedFiles: PropTypes.array,
  isUploadingDocuments: PropTypes.bool.isRequired,
  isMessagePending: PropTypes.bool.isRequired,
  userMessage: PropTypes.string.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  uploadController: PropTypes.object,
  eventSourceRef: PropTypes.object.isRequired,
  windowWidth: PropTypes.number.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  textInputRows: PropTypes.number.isRequired,
  textFocusRef: PropTypes.object.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  SendIcon: PropTypes.any.isRequired,
  StopIcon: PropTypes.any.isRequired,
  DisabledSendIcon: PropTypes.any.isRequired
};

export default MessageInput;