import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { CheckCircleOutline as CheckCircleIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

// Colors from SettingsScreen
const colors = {
  primary: "#0EA5E9",
  surface: "#FFFFFF",
  border: "#E5E7EB",
  text: "#1F2937",
  secondary: "#10B981",
};

const PaymentMethodCard = ({ loadingPaymentProfile, paymentProfile, subscription, formatCardNumber }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2.5,
        border: `1px solid ${colors.border}`,
        borderRadius: 2,
        bgcolor: colors.surface,
        flexWrap: { xs: 'wrap', sm: 'nowrap' }
      }}
    >
      {loadingPaymentProfile ? (
        <CircularProgress size={24} sx={{ mr: 2, color: colors.primary }} />
      ) : (
        <>
          <CheckCircleIcon sx={{ color: colors.secondary, mr: 2, fontSize: 28 }} />

          {/* Card logos */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              mr: { xs: 2, sm: 4 }
            }}
          >
            <img
              src="https://juridia.ma/wp-content/uploads/2025/04/visa.png"
              alt="Visa"
              style={{ width: "auto", height: 21, marginRight: 8 }}
            />
            <img
              src="https://juridia.ma/wp-content/uploads/2025/04/mastercard.png"
              alt="Mastercard"
              style={{ width: 48, height: 'auto' }}
            />
          </Box>

          {/* Card number */}
          <Typography
            sx={{
              flexGrow: 1,
              fontWeight: 500,
              color: colors.text,
              my: { xs: 2, sm: 0 },
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            {paymentProfile
              ? formatCardNumber(paymentProfile.last_four_digits)
              : formatCardNumber(subscription?.token && subscription.token.slice(-4))
            }
          </Typography>

          {/* Expires */}
          <Typography
            sx={{
              color: colors.text,
              fontSize: '0.9rem',
              ml: { xs: 'auto', sm: 0 }
            }}
          >
            {t("expires")}: {paymentProfile?.expiry_date || 'MM/YYYY'}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default PaymentMethodCard;