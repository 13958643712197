import React from 'react';
import { Box, Typography, Button, Paper, useMediaQuery, useTheme } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

// Using the same color palette from the main file
const colors = {
  primary: "#0EA5E9",
  primaryLight: "#EAECFF",
  warning: "#F59E0B",
  warningLight: "#FEF3C7",
  text: "#1F2937",
  textSecondary: "#6B7280",
  surface: "#FFFFFF",
  border: "#E5E7EB",
};

const SubscriptionUpgradeBanner = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUpgradeClick = () => {

    // Track banner click in analytics
    ReactGA.event({
      category: "Subscription",
      action: "Upgrade Banner Click",
      label: "Settings Page",
    });
    // Navigate to subscription page or open modal
    // This would be implemented based on your app's navigation system
    navigate('/pricing')
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 4,
        borderRadius: '12px',
        border: `1px solid ${colors.border}`,
        overflow: 'hidden',
        bgcolor: colors.warningLight,
      }}
    >
      <Box
        sx={{
          p: { xs: 2, sm: 3 },
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 0 }}>
          <Box
            sx={{
              mr: 2,
              p: 1,
              borderRadius: '8px',
              bgcolor: colors.warning,
              color: colors.surface,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WarningAmber />
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: colors.text,
              }}
            >
              {t("subscription_inactive")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: colors.textSecondary,
              }}
            >
              {t("subscription_upgrade_message")}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          disableElevation
          onClick={handleUpgradeClick}
          sx={{
            bgcolor: colors.warning,
            color: colors.surface,
            px: 3,
            py: 1,
            borderRadius: '8px',
            fontWeight: 600,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            '&:hover': {
              bgcolor: '#E97F06', // Slightly darker version of warning color
            },
          }}
        >
          {t("upgrade_now")}
        </Button>
      </Box>
    </Paper>
  );
};

export default SubscriptionUpgradeBanner;