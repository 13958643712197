import React from 'react';
import {
    Typography,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    Box,
    Divider
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { colors } from '../../../utils/colors';

const AddressFields = ({ formValues, handleInputChange, countries }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography 
                    variant="subtitle1" 
                    fontWeight="bold" 
                    sx={{ 
                        mt: 1, 
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <LocationOnIcon color="primary" fontSize="small" />
                    Adresse de facturation
                </Typography>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Adresse ligne 1"
                    name="addressLine1"
                    value={formValues.addressLine1 || ''}
                    required
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Adresse ligne 2"
                    name="addressLine2"
                    value={formValues.addressLine2 || ''}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="Ville" 
                    name="city" 
                    value={formValues.city || ''}
                    required 
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOnIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl 
                    fullWidth 
                    variant="outlined" 
                    required
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                >
                    <InputLabel id="country-select-label">Pays</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        value={formValues.country || 'MA'}
                        name="country"
                        onChange={handleInputChange}
                        label="Pays"
                        defaultValue="MA"
                        renderValue={(code) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ReactCountryFlag
                                    countryCode={code}
                                    svg
                                    style={{ marginRight: 8, width: '1.5em', height: '1.5em' }}
                                />
                                {countries.find(country => country.value === code)?.label || code}
                            </Box>
                        )}
                    >
                        {/* Put Morocco at the top of the list */}
                        <MenuItem key="MA" value="MA">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ReactCountryFlag
                                    countryCode="MA"
                                    svg
                                    style={{ marginRight: 8, width: '1.5em', height: '1.5em' }}
                                />
                                <ListItemText primary="Morocco" />
                            </Box>
                        </MenuItem>
                        <Divider />
                        {countries
                            .filter(country => country.value !== 'MA')
                            .map((country) => (
                                <MenuItem key={country.value} value={country.value}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ReactCountryFlag
                                            countryCode={country.value}
                                            svg
                                            style={{ marginRight: 8, width: '1.5em', height: '1.5em' }}
                                        />
                                        <ListItemText primary={country.label} />
                                    </Box>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="Code Postal" 
                    name="zipCode" 
                    value={formValues.zipCode || ''}
                    required 
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MarkunreadMailboxIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
            </Grid>
        </>
    );
};

export default AddressFields;