import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Chip,
    Grid,
    Paper,
    Button,
    Avatar,
    Divider,
    useTheme,
    AvatarGroup,
    TextField,
    IconButton,
    Tooltip
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
    Share as ShareIcon,
    GetApp as GetAppIcon,
    Delete as DeleteIcon,
    Launch as OpenIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon
} from '@mui/icons-material';
import { getStatusKey, getStatusStyle } from '../../../utils/utils';

// Translation keys used in this component:
// contract_type, created, last_modified, collaborators, no_collaborators,
// content_preview, no_content_available, not_specified, delete, share,
// download, open_contract, edit_title, save_changes, cancel_edit

// Format date helper
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

// Create a safe HTML renderer component
const HtmlContent = ({ content }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: content }}
            style={{
                fontFamily: 'inherit',
                fontSize: '0.875rem',
                lineHeight: 1.6
            }}
        />
    );
};

const ContractDetailDialog = ({ open, onOpen, onClose, contract, onDownload, onShare, onDelete, onUpdateContract }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');

    if (!contract) return null;

    // Determine if content is HTML or plain text
    const isHtml = contract.content &&
        (contract.content.includes('<') &&
            contract.content.includes('</') &&
            contract.content.includes('>'));

    // Truncate long content for preview
    const truncatedContent = contract.content;

    const handleEditTitle = () => {
        setEditedTitle(contract.title);
        setIsEditingTitle(true);
    };

    const handleSaveTitle = async () => {
        if (editedTitle.trim() !== contract.title) {
            try {
                await onUpdateContract(contract.id, { title: editedTitle.trim() });
            } catch (error) {
                console.error('Error updating contract title:', error);
                // You might want to add error handling here
            }
        }
        setIsEditingTitle(false);
    };

    const handleCancelEdit = () => {
        setIsEditingTitle(false);
        setEditedTitle(contract.title);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {isEditingTitle ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, mr: 1 }}>
                            <TextField
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                                autoFocus
                                sx={{ mr: 1 }}
                            />
                            <Tooltip title={t("save_changes")}>
                                <IconButton onClick={handleSaveTitle} color="primary" size="small">
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("cancel")}>
                                <IconButton onClick={handleCancelEdit} size="small">
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                {contract.title}
                            </Typography>
                            <Tooltip title={t("modify")}>
                                <IconButton onClick={handleEditTitle} size="small" sx={{ ml: 1 }}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    <Chip
                        label={t(getStatusKey(contract.status).toLowerCase()) || contract.status}
                        sx={{
                            ...getStatusStyle(contract.status),
                            fontWeight: 600,
                            ml: 1
                        }}
                        size="small"
                    />
                </Box>
            </DialogTitle>

            <DialogContent
                dividers
                sx={{
                    px: 3,
                    py: 2,
                    overflowY: 'visible', // Disable vertical scrolling on DialogContent
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5, fontWeight: 500 }}>
                                {t("contract_type")}
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {contract.type || t("not_specified")}
                            </Typography>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5, fontWeight: 500 }}>
                                {t("created")}
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {formatDate(contract.created_at)}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5, fontWeight: 500 }}>
                                {t("last_modified")}
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {formatDate(contract.updated_at)}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1.5, fontWeight: 500 }}>
                            {t("collaborators")}
                        </Typography>



                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                            <AvatarGroup
                                max={3}
                                sx={{
                                    '& .MuiAvatar-root': {
                                        width: 28,
                                        height: 28,
                                        fontSize: '0.8rem',
                                        fontWeight: 600,
                                        border: `2px solid ${theme.palette.background.paper}`
                                    }
                                }}
                            >
                                {contract.collaborators &&
                                    contract.collaborators.map((collaborator, idx) => (
                                        <Chip
                                            key={idx}

                                            avatar={
                                                <Avatar
                                                    sx={{
                                                        bgcolor: [
                                                            '#3B82F6', // Blue
                                                            '#10B981', // Green
                                                            '#F59E0B', // Amber
                                                            '#8B5CF6', // Purple
                                                            '#EC4899'  // Pink
                                                        ][idx % 5]
                                                    }}
                                                >
                                                    {collaborator.first_name[0]}{collaborator.last_name[0]}
                                                </Avatar>
                                            }
                                            label={`${collaborator.first_name} ${collaborator.last_name} (${t(collaborator.role)})`}
                                            variant="outlined"
                                            sx={{
                                                fontWeight: 500,
                                                borderRadius: 1.5,
                                            }}
                                        />
                                    ))
                                }</AvatarGroup>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5, fontWeight: 500 }}>
                                {t("description")}
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {contract.description || t("not_specified")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Box>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1.5, fontWeight: 500 }}>
                        {t("content_preview")}
                    </Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            bgcolor: alpha(theme.palette.background.default, 0.7),
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                            flex: 1, // Take available space
                            minHeight: 200, // Set minimum height
                            overflow: 'hidden', // Hide overflow on container level
                            position: 'relative' // For proper scrolling container positioning
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                p: 3,
                                overflowY: 'auto', // Keep content scrollable inside this box
                                // Add styling for HTML content
                                '& img': { maxWidth: '100%', height: 'auto' },
                                '& table': {
                                    borderCollapse: 'collapse',
                                    width: '100%',
                                    margin: '16px 0'
                                },
                                '& td, & th': {
                                    border: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
                                    padding: '8px',
                                    textAlign: 'left'
                                },
                                '& ul, & ol': { paddingLeft: '20px' }
                            }}
                        >
                            {contract.content ? (
                                isHtml ? (
                                    <HtmlContent content={truncatedContent} />
                                ) : (
                                    <Typography
                                        variant="body2"
                                        component="pre"
                                        sx={{
                                            whiteSpace: 'pre-wrap',
                                            fontFamily: 'inherit',
                                            m: 0
                                        }}
                                    >
                                        {truncatedContent}
                                    </Typography>
                                )
                            ) : (
                                <Typography variant="body2" color="text.secondary">
                                    {t("no_content_available")}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </DialogContent>

            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    onClick={onDelete}
                    color="error"
                    startIcon={<DeleteIcon />}
                    sx={{ textTransform: 'none', fontWeight: 600 }}
                >
                    {t("delete")}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    onClick={onShare}
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    sx={{
                        mr: 1.5,
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 1.5
                    }}
                >
                    {t("share")}
                </Button>
                <Button
                    onClick={onDownload}
                    variant="outlined"
                    startIcon={<GetAppIcon />}
                    sx={{
                        mr: 1.5,
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 1.5
                    }}
                >
                    {t("download")}
                </Button>
                <Button
                    onClick={onOpen}
                    variant="contained"
                    endIcon={<OpenIcon />}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        borderRadius: 1.5,
                        px: 3
                    }}
                >
                    {t("open_contract")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContractDetailDialog;