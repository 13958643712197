import React from 'react';
import { 
  Box, 
  Paper, 
  Grid, 
  Typography, 
  Chip, 
  IconButton, 
  Button, 
  Avatar, 
  AvatarGroup,
  Tooltip as MuiTooltip,
  Divider,
  useTheme 
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { 
  Share as ShareIcon,
  GetApp as GetAppIcon,
  Delete as DeleteIcon,
  Launch as OpenIcon,
  Star as StarIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDate, getStatusKey, getStatusStyle, getRoleColor } from '../../../utils/utils';

// Helper function to get role icon
const getRoleIcon = (role) => {
  const roleIcons = {
    OWNER: <StarIcon fontSize="inherit" />,
    // Add more role icons as needed
  };
  
  return roleIcons[role] || null;
};

const ContractList = ({ 
  filteredContracts, 
  handleContractClick, 
  handleShareContract, 
  handleDownloadContract, 
  handleOpenDeleteDialog, 
  handleOpenContract,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  
  // Get current user from Redux state
  const currentUser = useSelector((state) => state.auth.user.user);
  
  return (
    <Paper 
      variant="outlined" 
      sx={{ 
        borderRadius: 2,
        borderColor: alpha(theme.palette.divider, 0.1),
        overflow: 'hidden'
      }}
    >
      {filteredContracts.map((contract, index) => {
        // Get the status key for translation
        const statusKey = getStatusKey(contract.status);
        
        // Get the translated status text
        const statusText = t(`contractStatus.${statusKey}`);
        
        // Determine if we have multiple collaborators
        const hasMultipleCollaborators = contract.collaborators && contract.collaborators.length > 1;
        
        // Find current user's role in collaborators
        const collaborators = contract.collaborators || [];
        const userCollaborator = collaborators.find(
          collab => collab.id === currentUser?.id
        );
        const userRole = userCollaborator?.role;
        
        // Get translated role name
        const currentUserRole = userRole ? t(`${userRole}`.toUpperCase()) : '';
        
        // Get role color
        const roleColor = getRoleColor(userRole, theme);
        
        // Get role icon
        const roleIcon = getRoleIcon(userRole);
        
        return (
          <React.Fragment key={contract.id}>
            {index > 0 && <Divider />}
            <Box
              sx={{
                p: 2.5,
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.04) }
              }}
              onClick={() => handleContractClick(contract)}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={7} md={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Chip
                      label={statusText}
                      size="small"
                      sx={getStatusStyle(statusKey)}
                    />
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <span style={{ fontWeight: 500 }}>{contract.type || t('not_specified')}</span> • {t('created')}: {formatDate(contract.created_at, i18n.language)}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{ fontWeight: 600, fontSize: '1.1rem' }}
                  >
                    {contract.title}
                  </Typography>
                </Grid>
                
                <Grid item xs={6} sm={3} md={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* {hasMultipleCollaborators && userRole && (
                      <MuiTooltip title={t("you")}>
                        <Chip
                          icon={roleIcon}
                          label={currentUserRole}
                          size="small"
                          sx={{
                            backgroundColor: alpha(roleColor, 0.15),
                            color: roleColor,
                            fontWeight: 600,
                            borderRadius: '12px',
                            border: `1px solid ${alpha(roleColor, 0.25)}`,
                            mr: 1.5,
                            '& .MuiChip-icon': {
                              color: roleColor
                            }
                          }}
                        />
                      </MuiTooltip>
                    )} */}
                    <AvatarGroup 
                      max={3} 
                      sx={{ 
                        '& .MuiAvatar-root': { 
                          width: 28, 
                          height: 28, 
                          fontSize: '0.8rem',
                          fontWeight: 600,
                          border: `2px solid ${theme.palette.background.paper}`
                        } 
                      }}
                    >
                      {contract.collaborators && contract.collaborators.map((collaborator, idx) => (
                        <MuiTooltip
                          key={idx}
                          title={`${collaborator.first_name} ${collaborator.last_name} (${t(`${collaborator.role}`.toUpperCase())})`}
                        >
                          <Avatar 
                            sx={{ 
                              bgcolor: getRoleColor(collaborator.role, theme),
                              border: collaborator.id === currentUser?.id ? 
                                `2px solid ${theme.palette.common.white}` : 
                                `2px solid ${theme.palette.background.paper}`,
                              boxShadow: collaborator.id === currentUser?.id ? 
                                `0 0 0 2px ${roleColor}` : 'none'
                            }}
                          >
                            {collaborator.first_name[0]}{collaborator.last_name[0]}
                          </Avatar>
                        </MuiTooltip>
                      ))}
                    </AvatarGroup>
                  </Box>
                </Grid>
                
                <Grid item xs={6} sm={2} md={2}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShareContract(contract.id);
                      }}
                      sx={{ 
                        color: theme.palette.text.secondary,
                        bgcolor: alpha(theme.palette.background.default, 0.6)
                      }}
                      aria-label={t('share_contract')}
                    >
                      <ShareIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadContract(contract.id);
                      }}
                      sx={{ 
                        color: theme.palette.text.secondary,
                        bgcolor: alpha(theme.palette.background.default, 0.6)
                      }}
                      aria-label={t('download')}
                    >
                      <GetAppIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDeleteDialog([contract.id]);
                      }}
                      sx={{ 
                        color: theme.palette.error.main,
                        bgcolor: alpha(theme.palette.background.default, 0.6),
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.error.main, 0.1)
                        }
                      }}
                      aria-label={t('delete')}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<OpenIcon fontSize="small" />}
                      sx={{ 
                        borderRadius: 1.5,
                        textTransform: 'none',
                        fontWeight: 600,
                        ml: 1
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenContract(contract.id);
                      }}
                    >
                      {t('open')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        );
      })}
    </Paper>
  );
};

export default ContractList;