import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as SuccessIcon } from "../../assets/signup/confirmation.svg"; // Update path as necessary
import { colors } from "../../utils/colors";
import { windowHeight, windowWidth } from "../../utils/utils";

const PasswordResetSuccessScreen = () => {
    const navigate = useNavigate();

    const handleConfirmation = () => {
        navigate("/login");
    };

    return (
        <Grid
            container
            sx={{
                height: "90vh",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.bg_color,
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    borderRadius: "20px",
                    width: "60%",
                    padding: "40px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                }}
            >
                <SuccessIcon
                    style={{
                        width: windowHeight / 2.5,
                        height: windowHeight / 2.5,
                        marginRight: windowWidth / 40,
                        marginBottom: "20px",
                    }}
                />

                <Typography variant="h4" sx={{ mb: 2 }}>
                    {"Réinitialisation du Mot de Passe Réussie !"}
                </Typography>

                <Typography variant="body1" sx={{ mb: 4 }}>
                    {"Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe."}
                </Typography>

                <CustomButton
                    text="Aller à la page de connexion"
                    outlined={false}
                    onClick={handleConfirmation}
                    sx={{ width: "50%", padding: "10px 0" }}
                />
            </Paper>
        </Grid>
    );
};

export default PasswordResetSuccessScreen;