import React, { useEffect } from "react";

export const ZoomProvider = ({ children, zoomLevel = "85%" }) => {
  useEffect(() => {
    const zoomValue = zoomLevel;
    const scaleValue = parseInt(zoomValue) / 100;
    
    // Function to detect browser type
    const getBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      
      if (userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1) {
        return "ie";
      } else if (userAgent.indexOf("firefox") !== -1) {
        return "firefox";
      } else if (userAgent.indexOf("opr") !== -1 || userAgent.indexOf("opera") !== -1) {
        return "opera";
      } else if (userAgent.indexOf("chrome") !== -1) {
        return "chrome";
      } else {
        return "other";
      }
    };
    
    const browser = getBrowser();
    
    // Apply zoom based on browser
    switch (browser) {
      case "ie":
        // Internet Explorer uses zoom property
        document.body.style.zoom = zoomValue;
        break;
      case "firefox":
        // Firefox doesn't support zoom property, use transform instead
        document.body.style.transform = `scale(${scaleValue})`;
        document.body.style.transformOrigin = "0 0";
        // Adjust the container height to account for the scaling
        document.body.style.height = `${100 / scaleValue}%`;
        document.body.style.width = `${100 / scaleValue}%`;
        break;
      case "opera":
      case "chrome":
      default:
        // Chrome and Opera support zoom property
        document.body.style.zoom = zoomValue;
        break;
    }
    
    // Cleanup function
    return () => {
      document.body.style.zoom = "100%";
      document.body.style.transform = "none";
      document.body.style.height = "";
      document.body.style.width = "";
    };
  }, [zoomLevel]);
  
  return <>{children}</>;
};