import React from 'react';
import { Box, Paper, Typography, Button, Alert } from '@mui/material';
import { InfoOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// Colors from SettingsScreen
const colors = {
  primary: "#0EA5E9",
  primaryLight: "#EAECFF", 
  primaryDark: "#3730A3",
  secondary: "#10B981",
  surface: "#FFFFFF",
  info: "#3B82F6",
  text: "#1F2937",
  textSecondary: "#6B7280",
};

const UserSpecificMessage = ({ userType }) => {
  const { t } = useTranslation();
  const isTrial = userType === 'trial';

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '60vh' 
    }}>
      <Paper
        elevation={0}
        sx={{
          p: { xs: 3, md: 5 },
          textAlign: 'center',
          maxWidth: 600,
          width: '100%',
          borderRadius: 3,
          background: isTrial 
            ? 'linear-gradient(145deg, #f9f9f9 0%, #ffffff 100%)'
            : '#ffffff',
          border: `1px solid ${colors.border}`,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)'
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 100,
            height: 100,
            borderRadius: '50%',
            background: isTrial
              ? 'linear-gradient(135deg, #e0f7fa 0%, #bbdefb 100%)'
              : colors.primaryLight,
            margin: '0 auto 24px'
          }}
        >
          {isTrial ? (
            <InfoOutlined sx={{ fontSize: 50, color: colors.info }} />
          ) : (
            <CheckCircleOutlined sx={{ fontSize: 50, color: colors.primary }} />
          )}
        </Box>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            mb: 2,
            color: colors.text
          }}
        >
          {isTrial 
            ? t("trial_user_message_title") 
            : t("regular_user_message_title")}
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            color: colors.textSecondary,
            mb: 4,
            fontSize: '1.05rem',
            lineHeight: 1.6
          }}
        >
          {isTrial 
            ? t("trial_user_message_body") 
            : t("regular_user_message_body")}
        </Typography>

        {isTrial ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => window.location.href = '/pricing'}
            sx={{
              mt: 2,
              py: 1.5,
              px: 4,
              fontSize: '1rem',
              fontWeight: 600,
              borderRadius: 2,
              textTransform: 'none',
              background: 'linear-gradient(90deg, #1976d2 0%, #2196f3 100%)',
              boxShadow: '0 4px 12px rgba(33, 150, 243, 0.3)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(33, 150, 243, 0.4)',
              }
            }}
          >
            {t("upgrade_now")}
          </Button>
        ) : (
          <Alert 
            severity="info" 
            icon={<InfoOutlined />}
            sx={{ 
              mt: 2, 
              mb: 2, 
              borderRadius: 2,
              '& .MuiAlert-message': {
                fontSize: '0.95rem'
              }
            }}
          >
            {t("contact_admin_message")}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default UserSpecificMessage;