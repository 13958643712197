import React from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import {
  Description as AllIcon,
  Update as RecentIcon,
  PeopleAlt as SharedIcon,
  Edit as DraftIcon,
  Pending as PendingIcon,
  CheckCircle as SignedIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const TabIcon = ({ Icon, selected }) => {
  const theme = useTheme();
  
  return (
    <Icon
      fontSize="small"
      sx={{
        mr: 1,
        color: selected ? theme.palette.primary.main : theme.palette.text.secondary
      }}
    />
  );
};

const ContractTabs = ({ activeTab, handleTabChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        sx={{
          px: 2,
          '& .MuiTab-root': {
            minHeight: 56,
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 500,
            color: theme.palette.text.secondary,
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              fontWeight: 600
            }
          }
        }}
      >
        <Tab 
          icon={<TabIcon Icon={AllIcon} selected={activeTab === 0} />}
          iconPosition="start"
          label={t('contractTabs.allContracts')}
        />
        <Tab 
          icon={<TabIcon Icon={RecentIcon} selected={activeTab === 1} />}
          iconPosition="start"
          label={t('contractTabs.recent')}
        />
        <Tab 
          icon={<TabIcon Icon={SharedIcon} selected={activeTab === 2} />}
          iconPosition="start"
          label={t('contractTabs.sharedWithMe')}
        />
        <Tab 
          icon={<TabIcon Icon={DraftIcon} selected={activeTab === 3} />}
          iconPosition="start"
          label={t('contractTabs.drafts')}
        />
        <Tab 
          icon={<TabIcon Icon={PendingIcon} selected={activeTab === 4} />}
          iconPosition="start"
          label={t('contractTabs.pendingSignature')}
        />
        <Tab 
          icon={<TabIcon Icon={SignedIcon} selected={activeTab === 5} />}
          iconPosition="start"
          label={t('contractTabs.signed')}
        />
      </Tabs>
    </Box>
  );
};

export default ContractTabs;