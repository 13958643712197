import React, { useEffect, useRef } from "react";
import MessageComponent from "./Message";
import {
  ChatBubbleOutline as ChatIcon,
  ArrowDownward as ArrowDownwardIcon
} from "@mui/icons-material";
import { ROLE, TextDirection } from "../../../utils/constants";
import { CircularProgress, Box, Typography, Paper, Fade, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const RenderConversations = ({ conversationId, messages, documents, isFetchingConversation }) => {
  const lastElementRef = useRef(null);
  const conversationsRef = useRef(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages?.length]);

  const showLoading = messages
    ? messages[messages?.length - 1]?.role === ROLE.USER
    : messages;

  const scrollToBottom = () => {
    lastElementRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box
      className="conversation-container"
      ref={conversationsRef}
      sx={{
        height: "60vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        position: "relative",
        px: 2,
        fontFamily: "'Nunito', sans-serif",
        fontSize: "14px",
        color: "#2B3175",
        direction: currentLang === "ar" ? "rtl" : "ltr"
      }}
    >
      {messages?.length > 0 && (
        <IconButton
          onClick={scrollToBottom}
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            zIndex: 10,
            bgcolor: 'background.paper',
            boxShadow: 2,
            '&:hover': {
              bgcolor: 'background.paper',
              opacity: 0.9
            }
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}

      {messages?.map((message, index) => (
        <MessageComponent
          conversationId={conversationId}
          key={message.id}
          message={message}
          index={index}
          messagesLength={messages?.length}
          documents={documents}
          showLoading={showLoading}
        />
      ))}

      {messages?.length === 0 && (
        <Fade in={true} timeout={800}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent"
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: 4
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f0f0f0",
                  borderRadius: "50%",
                  p: 2,
                  mb: 2
                }}
              >
                <ChatIcon sx={{ fontSize: 48, color: "#DE3336" }} />
              </Box>

              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  width: "75%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#333",
                  direction: currentLang === "ar" ? "rtl" : "ltr",
                }}
              >
                {t("conversation_slogan")}
              </Typography>

              {isFetchingConversation && (
                <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                  <CircularProgress size={24} thickness={4} />
                  <Typography variant="body2" sx={{ ml: 1, color: "text.secondary" }}>
                    {t("loading")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Fade>
      )}

      <div ref={lastElementRef} style={{ height: 20 }}></div>
    </Box>
  );
};

export default RenderConversations;