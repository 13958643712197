import { Extension } from '@tiptap/core';

// Custom extension to handle tab key in the editor
export const TabKeyHandler = Extension.create({
  name: 'tabKeyHandler',

  addKeyboardShortcuts() {
    return {
      // Handle the Tab key
      'Tab': ({ editor }) => {
        // Default tab spacing - can be customized
        const tabSize = 4;

        // Insert spaces instead of a real tab character
        editor.commands.insertContent(' '.repeat(tabSize));

        // Return true to indicate we've handled the key
        return true;
      },

      // Optionally handle Shift+Tab to remove indentation
      'Shift-Tab': ({ editor }) => {
        // For shift+tab, we could implement outdenting or backward tab
        // This is optional and depends on your use case
        // For now, let's prevent the default behavior
        return true;
      }
    };
  },
});