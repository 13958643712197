import { backendClient } from '../../api/backend'
import { domainConfig } from '../../utils/utils'
import { clearStore } from '../store'
import { clearDocument } from './documentAction'
import { clearSearchDocument } from './searchAction'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  CLEAR_AUTH_STATE,
  CLEAR_MESSAGE_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  SET_IS_BOARDED,
  FETCH_USER
} from './types'

export const updateIsBoarded = (user_id, isBoarded) => dispatch => {
  backendClient.updateBoarded(user_id, isBoarded)

  dispatch({
    type: SET_IS_BOARDED,
    payload: isBoarded
  })
}

// Login
export const loginRequest = () => ({
  type: LOGIN_REQUEST
})

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  payload: user
})

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
})

export const fetchUser = userId => async dispatch => {
  try {
    const data = await backendClient.fetchUser(userId)

    dispatch({
      type: FETCH_USER,
      payload: data
    })
  } catch (error) {
    console.log('fetchUser Error', error)
  }
}

export const login = (email, password, navigate, newDomain) => {
  return async dispatch => {
    dispatch(loginRequest()) // Dispatch the request action to indicate the login process has started
    try {
      // Call the backend login function
      const data = await backendClient.login(email, password)

      // Store token and token version in localStorage or Redux
      localStorage.setItem('token_version', data?.user?.token_version) // Store token version as well

      // Dispatch success action with the response data
      dispatch(loginSuccess(data))

      //  Redirect to the appropriate domain
      const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
      const domain = data.user.email.split('@')[1]

      // Check if the domain exists in the domainConfig
      if (isProduction) {
        if (domainConfig[domain]) {
          console.log('replcaing location')
          // Redirect the app to domain.juridia.ma
          window.location.href = `https://${newDomain}.juridia.ma/welcome`
        } else {
          window.location.href = `https://app.juridia.ma/`
          // window.location.href = window.location.hostname

        }
      } else {
        if (domainConfig[domain]) {
          navigate('/welcome')
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      let errorMessage = "Une erreur inattendue s'est produite.";

      // Handle specific error status codes
      if (error.status === 401) {
        errorMessage = "L'email ou le mot de passe est incorrect.";
      } else if (error.status === 403) {
        errorMessage = "Vous n'êtes pas autorisé à vous connecter.";
      } else if (error.status === 404) {
        errorMessage = "Utilisateur non trouvé.";
      }
      // Dispatch failure action in case of error
      dispatch(loginFailure(errorMessage))
    }
  }
}

export const loginWithToken = (token, navigate) => {
  return async dispatch => {
    dispatch(loginRequest());
    try {
      const response = await backendClient.post('api/auth/confirm', { token });

      // Check if response is ok before parsing JSON
      if (!response.ok) {
        const errorData = await response.json();
        const errorStatus = response.status;

        // Handle different error types based on status codes
        switch (errorStatus) {
          case 400:
            throw new Error(`Erreur 400: ${errorData.detail || 'Jeton invalide ou expiré.'}`);
          case 403:
            throw new Error(`Erreur 403: ${errorData.detail || 'Accès refusé. Votre organisation n\'a pas d\'abonnement actif.'}`);
          case 404:
            throw new Error(`Erreur 404: ${errorData.detail || 'Utilisateur non trouvé.'}`);
          default:
            throw new Error(`Erreur ${errorStatus}: ${errorData.detail || 'Une erreur est survenue lors de la confirmation.'}`);
        }
      }

      const data = await response.json();

      // Save user data to local storage
      localStorage.setItem('token_version', data.user.token_version);
      localStorage.setItem('access_token', data.token.access_token);

      // Update auth state
      dispatch(loginSuccess(data));

      // Add a small delay before navigating
      setTimeout(() => {
        navigate('/');
      }, 2000);

      return data;
    } catch (error) {

      // Check if error message contains status code indicators
      const errorMessage = error.message || 'Une erreur est survenue lors de la confirmation.';

      // Format error message for better user experience
      let formattedErrorMessage = errorMessage;
      if (errorMessage.includes("403")) {
        formattedErrorMessage = "Votre organisation n'a pas d'abonnement actif. Veuillez contacter votre administrateur.";
      } else if (errorMessage.includes("404")) {
        formattedErrorMessage = "Utilisateur non trouvé. Veuillez vérifier votre email ou créer un nouveau compte.";
      } else if (errorMessage.includes("400")) {
        formattedErrorMessage = "Lien de confirmation invalide ou expiré. Veuillez demander un nouveau lien.";
      }

      // Dispatch login failure with appropriate error message
      dispatch(loginFailure(formattedErrorMessage));

      // Re-throw for component-level handling
      throw formattedErrorMessage;
    }
  };
}



// Sign Up
export const signUpRequest = () => ({
  type: SIGNUP_REQUEST
})

export const signUpSuccess = user => ({
  type: SIGNUP_SUCCESS,
  payload: user
})

export const signUpFailure = error => ({
  type: SIGNUP_FAILURE,
  payload: error
})


export const signUp = (
  last_name,
  first_name,
  organization_name,
  phone_number,
  email,
  password,
  userType,
  navigate,
  isDomain = null,
) => {
  return async dispatch => {
    dispatch(signUpRequest()) // Dispatch the request action to indicate the signup process has started

    try {
      // Call the backend signup function
      const data = await backendClient.signUp(
        last_name,
        first_name,
        organization_name,
        phone_number,
        email,
        password,
        userType
      )

      // Dispatch success action with the response data
      dispatch(signUpSuccess(data))

      if (data?.registered) {
        if (data?.user_type === 'organization_member' || isDomain) {
          // Store token and token version in localStorage or Redux
          localStorage.setItem('token_version', data?.user?.token_version) // Store token version as well

          navigate('/signup/confirmation-dm')
        } else {
          navigate('/signup/confirmation')
        }
      }
    } catch (error) {
      console.log('Error in signUp action:', error)

      let errorMessage = "Une erreur inattendue s'est produite lors de l'inscription."

      // Extract the error message from the error object
      if (error.message) {
        errorMessage = error.message
      }

      // Handle specific error status codes - these should match backend client errors
      if (error.status === 409) {
        errorMessage = 'Un utilisateur avec cet email existe déjà.'
      } else if (error.status === 400) {
        errorMessage = "Données d'inscription invalides. Veuillez vérifier vos informations."
      } else if (error.status === 403) {
        errorMessage = "L'inscription n'est disponible que pour les membres d'organisations approuvées avec un abonnement actif."
      } else if (error.status === 500) {
        errorMessage = 'Une erreur interne est survenue. Veuillez réessayer plus tard.'
      } else if (error.status === 0) {
        errorMessage = 'Impossible de se connecter au serveur. Veuillez vérifier votre connexion Internet.'
      }

      // If we have detailed error data from backend
      if (error.data && error.data.detail) {
        errorMessage = error.data.detail
      }

      // Dispatch failure action with the error message
      dispatch(signUpFailure(errorMessage))
    }
  }
}


// Sign Out
export const logout = () => {
  return dispatch => {
    // dispatch(clearDocument())
    // dispatch(clearSearchDocument())
    dispatch(clearAuthState())
    clearStore()
    dispatch({ type: LOGOUT })
  }
}

// Forgot Password
export const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST
})

export const forgotPasswordSuccess = message => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: message
})

export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error
})

export const forgotPassword = (email, domainName) => {
  return async dispatch => {
    dispatch(forgotPasswordRequest())
    try {
      const res = await backendClient.forgotPassword(email, domainName)

      // Success case - no need for additional parsing if already handled in backendClient
      dispatch(
        forgotPasswordSuccess(
          'Un email de réinitialisation de mot de passe vous a été envoyé.'
        )
      )
    } catch (error) {
      // Improved error message handling
      let errorMessage = 'Une erreur inattendue s\'est produite.'

      // First try to get status from error object if available
      const errorStatus = error.status ||
        (error.response && error.response.status) ||
        (error.message && parseInt(error.message.match(/^(\d{3})/)?.[1]));

      // Check for known status codes
      if (errorStatus) {
        switch (errorStatus) {
          case 404:
            errorMessage = 'Aucun utilisateur avec cette adresse email n\'existe.';
            break;
          case 401:
            errorMessage = 'Requête non autorisée.';
            break;
          case 403:
            errorMessage = 'Accès interdit. Veuillez contacter l\'administrateur.';
            break;
          case 429:
            errorMessage = 'Trop de tentatives. Veuillez réessayer plus tard.';
            break;
          case 500:
          case 502:
          case 503:
            errorMessage = 'Erreur serveur. Veuillez réessayer plus tard.';
            break;
          default:
            // For other status codes, try to extract detail from message
            if (error.message && error.message.includes('detail')) {
              try {
                const detailMatch = error.message.match(/detail: (.+)$/);
                if (detailMatch && detailMatch[1]) {
                  errorMessage = detailMatch[1].replace(/["']/g, '');
                }
              } catch (e) {
                // If parsing fails, use the original error message
                errorMessage = error.message;
              }
            }
            break;
        }
      } else if (error.message && error.message.includes('detail')) {
        // Fallback for when we can't determine status but have detail
        try {
          const detailMatch = error.message.match(/detail: (.+)$/);
          if (detailMatch && detailMatch[1]) {
            errorMessage = detailMatch[1].replace(/["']/g, '');
          }
        } catch (e) {
          // If parsing fails, use the original error message
          errorMessage = error.message;
        }
      }

      dispatch(forgotPasswordFailure(errorMessage))
    }
  }
}




// Reset Password
export const resetPassword = (token, newPassword) => {
  return async dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST })
    try {
      const res = await backendClient.resetPassword(token, newPassword)
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: 'Mot de passe réinitialisé avec succès.'
      })
    } catch (error) {
      const errorMessage =
        error.message || 'An unexpected error occurred during password reset.'
      dispatch({ type: RESET_PASSWORD_FAILURE, payload: errorMessage })
    }
  }
}

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE
})
export const clearMessageError = () => ({
  type: CLEAR_MESSAGE_ERROR
})
