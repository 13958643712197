import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Tooltip,
  Typography,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress
} from '@mui/material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingIcon from '@mui/icons-material/Pending';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CropFreeIcon from '@mui/icons-material/CropFree';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import HighlightIcon from '@mui/icons-material/Highlight';
import CodeIcon from '@mui/icons-material/Code';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import TitleIcon from '@mui/icons-material/Title';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';
import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuIcon from '@mui/icons-material/Menu';
import PageIcon from '@mui/icons-material/Pages';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';

import BackButton from '../../../../components/BackButton';
import { getToolbarWidth, windowWidth } from '../../../../utils/utils';
import AiToolbarButton from './AiToolbarButton';

// Import required utility functions
import { setFontSize, setFontFamily, getCurrentFontSize, getCurrentFontFamily } from '../extensions/CustomFont';
import { setTextColor, getCurrentTextColor, setupFormattingDetection } from '../extensions/CustomColor';
import { backendClient } from '../../../../api/backend';
import { updateContractStatus } from '../../../../redux/actions/contractAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ToolbarPlugin = ({ editor, contractId, saveContract, contractTitle, selectedImageNode, hasUnsavedChanges, aiButtonRef }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // State for menu
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const [anchorEl, setAnchorEl] = useState(null);
  // const [contractStatus, setContractStatus] = useState('brouillon');
  const contractStatus = useSelector(state => state?.contract?.contract?.status)
  const loadingStatus = useSelector(state => state?.contract?.loadingStatus)


  // Track current formatting
  const [currentTextColor, setCurrentTextColor] = useState('#000000');
  const [currentFontSize, setCurrentFontSize] = useState('16px');
  const [currentFontFamily, setCurrentFontFamily] = useState('Arial, sans-serif');


  // Reference for image input and menu button
  const imageInputRef = useRef(null);
  const menuButtonRef = useRef(null);
  // Add these refs to the component
  const toolbarScrollRef = useRef(null);


  // Submenu state
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [currentSubmenu, setCurrentSubmenu] = useState(null);

  const [pageLayout, setPageLayout] = useState('default'); // Options: default, wide, narrow, column-2, column-3
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);



  // Set up formatting detection
  useEffect(() => {
    if (!editor) return;

    const cleanup = setupFormattingDetection(editor, {
      setCurrentTextColor,
      setCurrentFontSize,
      setCurrentFontFamily
    });

    return cleanup;
  }, [editor]);

  const handlePageLayoutChange = (layout) => {
    if (!editor) return;

    // Apply the selected layout via editor commands
    // This assumes you have a custom extension for handling layouts
    editor.chain().focus().setPageLayout(layout).run();

    // Update the state
    setPageLayout(layout);
    handleSubmenuClose();
  };

  // Add this function to handle scrolling
  const handleToolbarScroll = (direction) => {
    if (!toolbarScrollRef.current) return;

    const scrollAmount = 150; // Adjust this value as needed
    const currentScroll = toolbarScrollRef.current.scrollLeft;

    if (direction === 'left') {
      toolbarScrollRef.current.scrollTo({
        left: Math.max(0, currentScroll - scrollAmount),
        behavior: 'smooth'
      });
    } else {
      toolbarScrollRef.current.scrollTo({
        left: currentScroll + scrollAmount,
        behavior: 'smooth'
      });
    }
  };



  const handleContractStatusChange = (status) => {
    if (!editor || status === contractStatus) {
      handleSubmenuClose();
      return;
    }
    const HtmlContent = editor.getHTML()
    // Dispatch Redux action to update contract status
    dispatch(updateContractStatus(contractId, status, HtmlContent))
    handleSubmenuClose();
  };

  // Add this function to check scroll possibilities
  const checkScrollability = () => {
    if (!toolbarScrollRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = toolbarScrollRef.current;

    // Can scroll left if we're not at the beginning
    setCanScrollLeft(scrollLeft > 0);

    // Can scroll right if there's more content to the right
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };

  // Add this scroll event handler
  const handleScroll = () => {
    checkScrollability();
  };

  // Add this useEffect to check scrollability on render and window resize
  useEffect(() => {
    if (!toolbarScrollRef.current) return;

    // Initial check
    checkScrollability();

    // Set up scroll event listener
    toolbarScrollRef.current.addEventListener('scroll', handleScroll);

    // Set up resize observer to check when size changes
    const resizeObserver = new ResizeObserver(() => {
      checkScrollability();
    });

    resizeObserver.observe(toolbarScrollRef.current);

    return () => {
      if (toolbarScrollRef.current) {
        toolbarScrollRef.current.removeEventListener('scroll', handleScroll);
        resizeObserver.unobserve(toolbarScrollRef.current);
      }
    };
  }, [toolbarScrollRef.current])

  // Safe handlers for undo/redo
  const handleUndo = () => {
    if (!editor) return;

    try {
      editor.chain().focus().undo().run();
    } catch (error) {
      console.error("Undo error:", error);
    }
  };

  const handleRedo = () => {
    if (!editor) return;

    try {
      editor.chain().focus().redo().run();
    } catch (error) {
      console.error("Redo error:", error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    saveContract();
  };

  // Menu handlers
  const handleMenuButtonClick = (e) => {
    setAnchorEl(menuButtonRef.current);
    setMenuOpen(prev => !prev);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenu(menuItem);
    setMenuOpen(false);
  };

  // Submenu handlers
  const handleSubmenuOpen = (event, menuName) => {
    setSubmenuAnchorEl(event.currentTarget);
    setCurrentSubmenu(menuName);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
    setCurrentSubmenu(null);
  };

  // Apply heading
  const setHeading = (level) => {
    editor.chain().focus().toggleHeading({ level }).run();
    handleSubmenuClose();
  };

  // Apply text color
  const applyTextColor = (color) => {
    setTextColor(editor, color);
    setCurrentTextColor(color);
    handleSubmenuClose();
  };

  // Apply typography style
  const applyTypographyStyle = (fontSize, fontFamily) => {
    if (fontSize) {
      setFontSize(editor, fontSize);
      setCurrentFontSize(fontSize);
    }

    if (fontFamily) {
      setFontFamily(editor, fontFamily);
      setCurrentFontFamily(fontFamily);
    }

    handleSubmenuClose();
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const src = e.target.result;
        const img = new Image();

        img.onload = () => {
          editor.chain().focus().insertContent({
            type: 'resizableImage',
            attrs: {
              src,
              alt: file.name,
              title: file.name,
              width: `${img.naturalWidth}px`,
              height: `${img.naturalHeight}px`,
              originalWidth: img.naturalWidth,
              originalHeight: img.naturalHeight
            }
          }).run();
        };

        img.src = src;
      };

      reader.readAsDataURL(file);
      event.target.value = '';
    }
  };

  // Table operations
  const handleInsertTable = () => {
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
    handleSubmenuClose();
  };

  // Render toolbar based on active menu
  const renderToolbar = () => {
    if (!editor) return null;

    switch (activeMenu) {
      case 'Home':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
            {canScrollLeft && (
              <IconButton
                size="small"
                onClick={() => handleToolbarScroll('left')}
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 2,
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.05)' }
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            )}
            <Box
              ref={toolbarScrollRef}
              sx={{
                display: 'flex',
                gap: 1,
                overflowX: 'auto',
                overflowY: 'hidden',
                px: 1,
                alignItems: 'center',
                height: '100%',
                width: getToolbarWidth(canScrollLeft, canScrollRight),
                flexWrap: 'nowrap',
                msOverflowStyle: 'none', /* for Internet Explorer, Edge */
                scrollbarWidth: 'none',  /* for Firefox */
                '&::-webkit-scrollbar': {
                  display: 'none'  /* for Chrome, Safari, and Opera */
                }
              }}>
              {/* Text Formatting */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Tooltip title="Bold">
                  <Button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    color={editor.isActive('bold') ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatBoldIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Italic">
                  <Button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    color={editor.isActive('italic') ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatItalicIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Underline">
                  <Button
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    color={editor.isActive('underline') ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatUnderlinedIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Divider orientation="vertical" flexItem />

              {/* Heading Dropdown */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Button
                  onClick={(e) => handleSubmenuOpen(e, 'heading')}
                  color={(editor.isActive('heading') || editor.isActive('paragraph')) ? 'primary' : 'default'}
                  sx={{ textTransform: 'none' }}
                  startIcon={<TitleIcon fontSize="small" />}
                  endIcon={<ArrowDropDownIcon fontSize="small" />}
                >
                  {editor.isActive('paragraph') ? 'Texte' :
                    editor.isActive({ level: 1 }) ? 'Heading 1' :
                      editor.isActive({ level: 2 }) ? 'Heading 2' :
                        editor.isActive({ level: 3 }) ? 'Heading 3' :
                          editor.isActive({ level: 4 }) ? 'Heading 4' :
                            editor.isActive({ level: 5 }) ? 'Heading 5' :
                              'Heading'}
                </Button>
              </ButtonGroup>

              <Divider orientation="vertical" flexItem />

              {/* Text Color */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Button
                  onClick={(e) => handleSubmenuOpen(e, 'textColor')}
                  sx={{ textTransform: 'none', pr: 1 }}
                // startIcon={<TextFormatIcon fontSize="small" sx={{ color: currentTextColor }} />}
                >
                  <TextFormatIcon fontSize="small" sx={{ color: currentTextColor }} />
                </Button>

                <Tooltip title="Highlight">
                  <Button
                    onClick={() => editor.chain().focus().toggleHighlight().run()}
                    color={editor.isActive('highlight') ? 'primary' : 'default'}
                    sx={{ pl: 1 }}
                  >
                    <HighlightIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Divider orientation="vertical" flexItem />

              {/* Text Size Controls */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Tooltip title="Decrease Font Size">
                  <Button
                    onClick={() => {
                      const currentSize = parseInt(getCurrentFontSize(editor).replace('px', ''));
                      const newSize = Math.max(8, currentSize - 2) + 'px';
                      applyTypographyStyle(newSize);
                    }}
                    sx={{ px: 1 }}
                  >
                    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>A-</Typography>
                  </Button>
                </Tooltip>

                <Button
                  onClick={(e) => handleSubmenuOpen(e, 'fontSize')}
                  sx={{
                    textTransform: 'none',
                    minWidth: '50px'
                  }}
                >
                  {currentFontSize.replace('px', '')}
                </Button>

                <Tooltip title="Increase Font Size">
                  <Button
                    onClick={() => {
                      const currentSize = parseInt(getCurrentFontSize(editor).replace('px', ''));
                      const newSize = Math.min(72, currentSize + 2) + 'px';
                      applyTypographyStyle(newSize);
                    }}
                    sx={{ px: 1 }}
                  >
                    <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>A+</Typography>
                  </Button>
                </Tooltip>
              </ButtonGroup>


              <Divider orientation="vertical" flexItem />

              {/* Lists */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Tooltip title="Bullet List">
                  <Button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    color={editor.isActive('bulletList') ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatListBulletedIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Numbered List">
                  <Button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    color={editor.isActive('orderedList') ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatListNumberedIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Divider orientation="vertical" flexItem />

              {/* Alignment */}
              <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                <Tooltip title="Align Left">
                  <Button
                    onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    color={editor.isActive({ textAlign: 'left' }) ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatAlignLeftIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Align Center">
                  <Button
                    onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    color={editor.isActive({ textAlign: 'center' }) ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatAlignCenterIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Align Right">
                  <Button
                    onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    color={editor.isActive({ textAlign: 'right' }) ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatAlignRightIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Align Justify">
                  <Button
                    onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                    color={editor.isActive({ textAlign: 'justify' }) ? 'primary' : 'default'}
                    sx={{ px: 1 }}
                  >
                    <FormatAlignJustifyIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
            {canScrollRight && (
              <IconButton
                size="small"
                onClick={() => handleToolbarScroll('right')}
                sx={{
                  position: 'sticky',
                  right: 0,
                  zIndex: 2,
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.05)' }
                }}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        );
      case 'Insert':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            {/* Image Upload */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Insert Image">
                <Button onClick={() => imageInputRef.current.click()} sx={{ px: 1 }}>
                  <ImageIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Divider orientation="vertical" flexItem />

            {/* Insert Quote */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Insert Blockquote">
                <Button
                  onClick={() => editor.chain().focus().toggleBlockquote().run()}
                  color={editor.isActive('blockquote') ? 'primary' : 'default'}
                  sx={{ px: 1 }}
                >
                  <FormatQuoteIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>

            {/* Insert Horizontal Rule */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Insert Horizontal Line">
                <Button
                  onClick={() => editor.chain().focus().setHorizontalRule().run()}
                  sx={{ px: 1 }}
                >
                  <HorizontalRuleIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        );
      case 'Table':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            {/* Insert Table (always shown in Table menu) */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Insert Table">
                <Button onClick={handleInsertTable} sx={{ px: 1 }}>
                  <TableChartIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Divider orientation="vertical" flexItem />

            {/* Table Operations - only shown if a table is selected */}
            {editor.isActive('table') ? (
              <>
                <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                  <Tooltip title="Add Column Before">
                    <Button onClick={() => editor.chain().focus().addColumnBefore().run()} sx={{ px: 1 }}>
                      <BorderAllIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add Column After">
                    <Button onClick={() => editor.chain().focus().addColumnAfter().run()} sx={{ px: 1 }}>
                      <BorderAllIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                  <Tooltip title="Add Row Before">
                    <Button onClick={() => editor.chain().focus().addRowBefore().run()} sx={{ px: 1 }}>
                      <BorderAllIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add Row After">
                    <Button onClick={() => editor.chain().focus().addRowAfter().run()} sx={{ px: 1 }}>
                      <BorderAllIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
                  <Tooltip title="Delete Table">
                    <Button onClick={() => editor.chain().focus().deleteTable().run()} sx={{ px: 1, color: 'error.main' }}>
                      Delete Table
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', height: '100%', px: 2 }}>
                Insert a table or select an existing table to see more table options
              </Typography>
            )}
          </Box>
        );

      case 'PageLayout':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            {/* Page Layout Options */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Default Layout">
                <Button
                  onClick={() => handlePageLayoutChange('default')}
                  color={pageLayout === 'default' ? 'primary' : 'default'}
                  sx={{ px: 1 }}
                >
                  <ViewDayIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Wide Layout">
                <Button
                  onClick={() => handlePageLayoutChange('wide')}
                  color={pageLayout === 'wide' ? 'primary' : 'default'}
                  sx={{ px: 1 }}
                >
                  <CropLandscapeIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Narrow Layout">
                <Button
                  onClick={() => handlePageLayoutChange('narrow')}
                  color={pageLayout === 'narrow' ? 'primary' : 'default'}
                  sx={{ px: 1 }}
                >
                  <CropPortraitIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Divider orientation="vertical" flexItem />

            {/* Margins and Spacing */}
            <ButtonGroup size="small" variant="text" sx={{ mr: 0.5 }}>
              <Tooltip title="Page Margins">
                <Button
                  onClick={(e) => handleSubmenuOpen(e, 'pageMargins')}
                  sx={{ textTransform: 'none' }}
                >
                  <CropFreeIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        );
      case 'Tools':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', height: '100%', px: 2 }}>
              Tools options will appear here
            </Typography>
          </Box>
        );
      case 'Page':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', height: '100%', px: 2 }}>
              Page options will appear here
            </Typography>
          </Box>
        );
      case 'Export':
        return (
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            px: 1,
            alignItems: 'center',
            height: '100%',
            width: windowWidth / 2,
            flexWrap: 'nowrap',
            msOverflowStyle: 'none', /* for Internet Explorer, Edge */
            scrollbarWidth: 'none',  /* for Firefox */
            '&::-webkit-scrollbar': {
              display: 'none'  /* for Chrome, Safari, and Opera */
            }
          }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', height: '100%', px: 2 }}>
              Export options will appear here
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  // 7. Add a function to handle page margin changes:
  const handlePageMarginsChange = (marginType) => {
    if (!editor) return;

    let margins;
    switch (marginType) {
      case 'narrow':
        margins = { top: '0.5in', right: '0.5in', bottom: '0.5in', left: '0.5in' };
        break;
      case 'wide':
        margins = { top: '1in', right: '1.5in', bottom: '1in', left: '1.5in' };
        break;
      case 'custom':
        // This would typically open a dialog for custom input
        // For now, just use some default custom values
        margins = { top: '0.75in', right: '1in', bottom: '0.75in', left: '1in' };
        break;
      default: // normal
        margins = { top: '1in', right: '1in', bottom: '1in', left: '1in' };
    }

    // Apply the margins (this would typically be a custom extension method)
    editor.chain().focus().setPageMargins(margins).run();

    handleSubmenuClose();
  };

  return (
    <Paper elevation={1} sx={{ width: '100%', overflow: 'hidden', borderRadius: '4px', zIndex: 200000 }}>
      {/* First row: Back button, title, undo/redo, save */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 1.5,
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          bgcolor: 'white'
        }}
      >
        <BackButton navigate_to={"/contract"} />

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            maxWidth: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 1
          }}
        >
          {contractTitle || 'Untitled Contract'}
        </Typography>

        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>

          <ButtonGroup size="small" variant="text">
            <Tooltip title={t('toolContractStatus')}>
              <Button
                onClick={(e) => handleSubmenuOpen(e, 'contractStatus')}
                sx={{
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                {loadingStatus ? (

                  <CircularProgress size={18} />
                ) : (
                  <>
                    {contractStatus === 'brouillon' && <EditNoteIcon fontSize="small" />}
                    {contractStatus === 'en revu' && <PendingIcon fontSize="small" />}
                    {contractStatus === 'en attente de signature' && <SendTimeExtensionIcon fontSize="small" />}
                    {contractStatus === 'signé' && <AssignmentTurnedInIcon fontSize="small" />}
                  </>
                )}
                <Typography variant="caption">
                  {contractStatus?.charAt(0).toUpperCase() + contractStatus?.slice(1)}
                </Typography>
              </Button>
            </Tooltip>
          </ButtonGroup>


          <ButtonGroup size="small" variant="text">
            <Tooltip title={t('undo')}>
              <Button onClick={handleUndo} sx={{ px: 1 }} disabled={!editor}>
                <UndoIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title={t('redo')}>
              <Button onClick={handleRedo} sx={{ px: 1 }} disabled={!editor}>
                <RedoIcon fontSize="small" />
              </Button>
            </Tooltip>
          </ButtonGroup>

          <Tooltip title={hasUnsavedChanges ? t('saveChanges') : t('allChangesSaved')}>
            <Button
              variant="text"
              size="small"
              onClick={handleSave}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                borderColor: hasUnsavedChanges ? 'warning.main' : 'success.main',
                color: hasUnsavedChanges ? 'warning.main' : 'success.main',
              }}
            >
              {hasUnsavedChanges ? (
                <>
                  <SaveIcon fontSize="small" />
                  <Typography variant="caption">{t('save')}</Typography>
                </>
              ) : (
                <>
                  <CheckCircleIcon fontSize="small" />
                  <Typography variant="caption">{t('saved')}</Typography>
                </>
              )}
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {/* Second row: Menu button, vertical divider, contextual actions */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          bgcolor: 'white',
          height: '42px'
        }}
      >
        {/* Menu Button */}
        <Button
          ref={menuButtonRef}
          variant="text"
          onClick={handleMenuButtonClick}
          sx={{
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            px: 2,
            height: '100%',
            borderRadius: 0,
            bgcolor: menuOpen ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.08)'
            }
          }}
          endIcon={menuOpen ? <KeyboardArrowUpIcon /> : <ArrowDropDownIcon />}
        >
          {activeMenu}
        </Button>

        {/* Vertical Divider */}
        <Divider orientation="vertical" flexItem />

        {/* Contextual Tools based on active menu */}
        <Box sx={{ flexGrow: 1, height: '100%', overflow: 'auto', }}>
          {renderToolbar()}
        </Box>

        {/* Vertical Divider */}
        <Divider orientation="vertical" flexItem />

        <Box sx={{ px: 1 }}>
          {/* AI Toolbar Button (placed after menu) */}
          <AiToolbarButton ref={aiButtonRef} editor={editor} contractId={contractId} />
        </Box>

        {/* Hidden file input for image uploads */}
        <input
          type="file"
          ref={imageInputRef}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </Box>

      {/* Menus */}

      <Menu
        anchorEl={submenuAnchorEl}
        open={currentSubmenu === 'contractStatus'}
        onClose={handleSubmenuClose}
      >
        <MenuItem
          onClick={() => handleContractStatusChange('brouillon')}
          selected={contractStatus === 'brouillon'}
        >
          <ListItemIcon><EditNoteIcon fontSize="small" /></ListItemIcon>
          <ListItemText>{t('draft')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleContractStatusChange('en revu')}
          selected={contractStatus === 'en revu'}
        >
          <ListItemIcon><PendingIcon fontSize="small" /></ListItemIcon>
          <ListItemText>{t('in_review')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleContractStatusChange('en attente de signature')}
          sx={{
            pointerEvents: 'none',
            '& .MuiListItemIcon-root': { opacity: 0.5 },
            '& .MuiTypography-root': { color: 'text.disabled' }
          }}
        >
          <ListItemIcon><SendTimeExtensionIcon fontSize="small" /></ListItemIcon>
          <ListItemText>{t('pending_signature')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleContractStatusChange('signé')}
          sx={{
            pointerEvents: 'none',
            '& .MuiListItemIcon-root': { opacity: 0.5 },
            '& .MuiTypography-root': { color: 'text.disabled' }
          }}
        >
          <ListItemIcon><AssignmentTurnedInIcon fontSize="small" /></ListItemIcon>
          <ListItemText>{t('signed')}</ListItemText>
        </MenuItem>
      </Menu>



      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            width: 180,
            mt: 0.5,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1.5,
              borderRadius: 0,
              '&.Mui-selected': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
              },
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick('Home')}
          selected={activeMenu === 'Home'}
          sx={{
            bgcolor: activeMenu === 'Home' ? 'rgba(66, 133, 244, 0.08)' : 'transparent',
            color: activeMenu === 'Home' ? 'rgb(66, 133, 244)' : 'inherit'
          }}
        >
          <ListItemIcon sx={{ color: activeMenu === 'Home' ? 'rgb(66, 133, 244)' : 'inherit' }}>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('home')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('Insert')}
          selected={activeMenu === 'Insert'}
        >
          <ListItemIcon>
            <InsertDriveFileIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('insert')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('Table')}
          selected={activeMenu === 'Table'}
        >
          <ListItemIcon>
            <TableChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('table')}</ListItemText>
        </MenuItem>

        {/* <MenuItem
          onClick={() => handleMenuItemClick('PageLayout')}
          selected={activeMenu === 'PageLayout'}
        >
          <ListItemIcon>
            <PageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Page</ListItemText>
        </MenuItem> */}

      </Menu>

      {/* Submenus */}
      <Menu
        anchorEl={submenuAnchorEl}
        open={currentSubmenu === 'heading'}
        onClose={handleSubmenuClose}
      >
        <MenuItem
          onClick={() => editor.chain().focus().setParagraph().run()}
          selected={editor.isActive('paragraph')}
        >
          <ListItemIcon>
            <TextFormatIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Text</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setHeading(1)}>
          <ListItemIcon><LooksOneIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Heading 1</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setHeading(2)}>
          <ListItemIcon><LooksTwoIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Heading 2</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setHeading(3)}>
          <ListItemIcon><Looks3Icon fontSize="small" /></ListItemIcon>
          <ListItemText>Heading 3</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setHeading(4)}>
          <ListItemIcon><Looks4Icon fontSize="small" /></ListItemIcon>
          <ListItemText>Heading 4</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setHeading(5)}>
          <ListItemIcon><Looks5Icon fontSize="small" /></ListItemIcon>
          <ListItemText>Heading 5</ListItemText>
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={currentSubmenu === 'fontSize'}
        onClose={handleSubmenuClose}
        PaperProps={{
          style: {
            maxHeight: '300px',
            overflowY: 'auto'
          }
        }}
      >
        {[8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 60, 72].map((size) => (
          <MenuItem
            key={size}
            onClick={() => applyTypographyStyle(`${size}px`)}
            selected={currentFontSize === `${size}px`}
          >
            <ListItemText>
              <Typography
                variant="body2"
                sx={{
                  fontSize: `${size}px`,
                  ...(currentFontSize === `${size}px` ? { fontWeight: 'bold' } : {})
                }}
              >
                {size}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>


      <Menu
        anchorEl={submenuAnchorEl}
        open={currentSubmenu === 'textColor'}
        onClose={handleSubmenuClose}
      >
        <MenuItem onClick={() => applyTextColor('#000000')}>
          <ListItemIcon>
            <Box sx={{ width: 20, height: 20, bgcolor: '#000000', borderRadius: '50%' }} />
          </ListItemIcon>
          <ListItemText>Black</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => applyTextColor('#1976D2')}>
          <ListItemIcon>
            <Box sx={{ width: 20, height: 20, bgcolor: '#1976D2', borderRadius: '50%' }} />
          </ListItemIcon>
          <ListItemText>Blue</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => applyTextColor('#D32F2F')}>
          <ListItemIcon>
            <Box sx={{ width: 20, height: 20, bgcolor: '#D32F2F', borderRadius: '50%' }} />
          </ListItemIcon>
          <ListItemText>Red</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => applyTextColor('#388E3C')}>
          <ListItemIcon>
            <Box sx={{ width: 20, height: 20, bgcolor: '#388E3C', borderRadius: '50%' }} />
          </ListItemIcon>
          <ListItemText>Green</ListItemText>
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={submenuAnchorEl}
        open={currentSubmenu === 'pageMargins'}
        onClose={handleSubmenuClose}
      >
        <MenuItem onClick={() => handlePageMarginsChange('normal')}>
          <ListItemText>Normal Margins</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handlePageMarginsChange('narrow')}>
          <ListItemText>Narrow Margins</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handlePageMarginsChange('wide')}>
          <ListItemText>Wide Margins</ListItemText>
        </MenuItem>
      </Menu>
    </Paper >
  );
};

export default ToolbarPlugin;