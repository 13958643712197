import React, { useContext } from "react";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Card, CardContent, CircularProgress, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditorContext from "../../../../contexts/EditorContext";
import { normalizeText, splitIntoParagraphs } from "../../DocumentEditor/extensions/CustomHighlight";

const DetailsCard = ({ details, suggestion, errorText, onClose }) => {
    const { t } = useTranslation();
    const editorRef = useContext(EditorContext);

    const handleAddSuggestion = () => {
        if (!suggestion || !errorText) return;

        // Get editor instance
        const editor = editorRef?.current?.getEditor();
        if (!editor) {
            console.error("Editor instance not available");
            return;
        }

        try {
            // First clear any existing highlights
            editor.commands.clearRiskHighlight();

            // Get the current document state
            const { state } = editor.view;
            const { doc } = state;
            const content = editor.getHTML();

            // Extract paragraphs using the same method as in CustomHighlight extension
            const paragraphs = splitIntoParagraphs(errorText);
            console.log('paragraphs', paragraphs);

            if (paragraphs.length > 0) {
                // For multi-paragraph replacement, we need a different approach
                if (paragraphs.length > 1) {
                    // Try to handle multiple paragraphs as a complete replacement
                    const result = handleMultiParagraphReplacement(editor, paragraphs, suggestion);
                    if (result) {
                        if (onClose) onClose();
                        return;
                    }
                }

                // If multi-paragraph replacement failed or we only have one paragraph,
                // fall back to individual paragraph replacement
                // Track positions where we found matches
                const matchPositions = [];

                // Find matches for each paragraph
                for (const paragraph of paragraphs) {
                    if (paragraph.length < 10) continue; // Skip very short paragraphs

                    const normalizedSearchText = normalizeText(paragraph);
                    const searchRegex = new RegExp(escapeRegExp(normalizedSearchText), 'i');

                    // Find text nodes matching this paragraph
                    doc.descendants((node, pos) => {
                        if (node.isText) {
                            const normalizedNodeText = normalizeText(node.text);

                            if (searchRegex.test(normalizedNodeText)) {
                                // Found a match, get precise position
                                const match = normalizedNodeText.match(searchRegex);
                                if (match && match.index !== undefined) {
                                    const startPos = pos + match.index;
                                    const endPos = startPos + match[0].length;

                                    matchPositions.push({ from: startPos, to: endPos, text: match[0], paragraph });
                                }
                            }
                        }
                        return true; // Continue traversing
                    });
                }

                // If we found matches, replace them all from end to beginning
                if (matchPositions.length > 0) {
                    console.log(`Found ${matchPositions.length} matches for replacement`);

                    // Sort by position to ensure we replace from end of doc to beginning
                    // (to avoid position shifts affecting other replacements)
                    matchPositions.sort((a, b) => b.from - a.from);

                    // Get a new transaction
                    let tr = state.tr;
                    
                    // Replace each match
                    for (const { from, to } of matchPositions) {
                        // If we have one paragraph but multiple matches, replace one at a time
                        if (paragraphs.length === 1 && matchPositions.length > 1) {
                            tr.deleteRange(from, to).insertText(suggestion, from);
                        } 
                        // If we have multiple paragraphs with one match each, replace just the first one
                        else {
                            tr.deleteRange(from, to).insertText(suggestion, from);
                            break; // Only replace the first one in this case
                        }
                    }

                    // Apply the transaction
                    editor.view.dispatch(tr);

                    // Close the details card after replacement
                    if (onClose) onClose();
                    return;
                }
            }

            // If paragraph matching failed, try content-based approaches
            // First try using direct HTML replacement for the entire content
            let replacementMade = false;
            
            if (paragraphs.length > 1) {
                // Try to replace the entire multi-paragraph text
                const normalizedFullText = normalizeText(errorText.replace(/<br\s*\/?>/gi, ' ').replace(/<[^>]*>/g, ''));
                if (content.includes(normalizedFullText)) {
                    const newContent = content.replace(normalizedFullText, suggestion);
                    editor.commands.setContent(newContent);
                    replacementMade = true;
                }
            }
            
            if (!replacementMade) {
                // Try HTML-based replacement for each paragraph
                for (const paragraph of paragraphs) {
                    const normalizedPara = normalizeText(paragraph);
                    if (content.includes(normalizedPara)) {
                        const newContent = content.replace(normalizedPara, suggestion);
                        editor.commands.setContent(newContent);
                        replacementMade = true;
                        break;
                    }
                }
            }
            
            if (replacementMade) {
                if (onClose) onClose();
                return;
            }

            // Last resort: try fuzzy replacement
            console.warn("Could not find exact match for text replacement, trying fuzzy replacement");
            const normalizedErrorText = normalizeText(errorText.replace(/<[^>]*>/g, ''));
            const fuzzyReplacement = fuzzyReplace(content, normalizedErrorText, suggestion);
            if (fuzzyReplacement) {
                editor.commands.setContent(fuzzyReplacement);
                if (onClose) onClose();
            } else {
                console.error("Failed to replace text - no match found");
            }
        } catch (err) {
            console.error("Error replacing text:", err);
        }
    };

    // Function to handle multi-paragraph replacement by looking for consecutive paragraphs
    const handleMultiParagraphReplacement = (editor, paragraphs, suggestion) => {
        try {
            // Get current document state
            const { state } = editor.view;
            const { doc } = state;

            // Find positions of all paragraphs
            const paragraphPositions = [];

            // For each paragraph, find all positions in the document
            for (const paragraph of paragraphs) {
                if (paragraph.length < 10) continue;
                
                const positions = [];
                const normalizedSearchText = normalizeText(paragraph);
                const searchRegex = new RegExp(escapeRegExp(normalizedSearchText), 'i');

                doc.descendants((node, pos) => {
                    if (node.isText) {
                        const normalizedNodeText = normalizeText(node.text);
                        
                        if (searchRegex.test(normalizedNodeText)) {
                            const match = normalizedNodeText.match(searchRegex);
                            if (match && match.index !== undefined) {
                                const startPos = pos + match.index;
                                const endPos = startPos + match[0].length;
                                positions.push({ from: startPos, to: endPos, text: match[0] });
                            }
                        }
                    }
                    return true;
                });

                paragraphPositions.push(positions);
            }

            // Check if we found all paragraphs
            if (paragraphPositions.some(positions => positions.length === 0)) {
                return false; // Not all paragraphs found
            }

            // Find consecutive paragraph positions
            let rangeStart = null;
            let rangeEnd = null;

            // Try to find consecutive positions for the first two paragraphs
            if (paragraphPositions.length >= 2) {
                // For each position of the first paragraph
                for (const pos1 of paragraphPositions[0]) {
                    // Try to find a position of the second paragraph that comes right after
                    for (const pos2 of paragraphPositions[1]) {
                        // Check if they're in sequence (with some margin for spaces/line breaks)
                        const distance = pos2.from - pos1.to;
                        if (distance > 0 && distance < 50) { // Allow some distance for formatting/spaces
                            rangeStart = pos1.from;
                            rangeEnd = pos2.to;
                            break;
                        }
                    }
                    if (rangeStart !== null) break;
                }
            }

            // If we found consecutive paragraphs, replace the entire range
            if (rangeStart !== null && rangeEnd !== null) {
                const tr = state.tr;
                tr.deleteRange(rangeStart, rangeEnd).insertText(suggestion, rangeStart);
                editor.view.dispatch(tr);
                return true;
            }

            return false;
        } catch (err) {
            console.error("Error in multi-paragraph replacement:", err);
            return false;
        }
    };

    // Helper function to escape special regex characters
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    // Helper function to try a fuzzy replacement when exact match fails
    const fuzzyReplace = (content, searchText, replacement) => {
        // Try to find a close match by reducing the search text
        if (searchText.length < 20) return null; // Too short to safely reduce

        // Try with the first 80% of the text
        const reducedLength = Math.floor(searchText.length * 0.8);
        const reducedText = searchText.substring(0, reducedLength);

        if (content.includes(reducedText)) {
            // Found a partial match, use it for replacement
            return content.replace(reducedText, replacement);
        }

        return null; // No fuzzy match found
    };

    return (
        <Card
            sx={{
                backgroundColor: "white",
                borderLeft: "4px solid #ddd", // Bordure uniquement à gauche
                borderTop: "none",
                borderBottom: "none",
                borderRight: "none",
                borderRadius: 0,
                mb: 2,
            }}
        >
            <CardContent>
                {/* Observation avec étoile */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <AutoAwesomeIcon sx={{ color: "#555", mr: 1 }} />

                    <Typography variant="subtitle2" fontWeight="bold">
                        {t("riskAnalysis.detailsCard.details")}
                    </Typography>
                </Box>

                {details ? (<Typography variant="body2" sx={{ ml: 0 }} align="left">
                    {details}
                </Typography>
                )   : (
                        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                        <CircularProgress size={14} sx={{ mr: 1 }} />
                        <Typography variant="body2">{t("riskAnalysis.generatingObservations")}</Typography>
                        </Box>
                    )}

                {/* Sous-carte Suggestion d'amélioration */}
                <Box
                    sx={{
                        border: "1px solid #388e3c",
                        mt: 2,
                        pl: 1,
                        pr: 1,
                        py: 1,
                        borderRadius: 1,
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CheckCircleIcon sx={{ color: "#388e3c", mr: 1 }} />
                            <Typography variant="subtitle2" fontWeight="bold" sx={{ color: "#388e3c" }}>
                                {t("riskAnalysis.detailsCard.suggestion")}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            size="small"
                            disabled={!suggestion}
                            sx={{ background: "#000", color: "#fff", '&:hover': { background: "#333" } }}
                            onClick={handleAddSuggestion}
                        >
                            {t("riskAnalysis.detailsCard.add")}
                        </Button>
                    </Box>

                    {suggestion ?(                   
                    <Typography variant="body2" sx={{ mt: 1 }} align="left">
                                        {suggestion}
                    </Typography>
                    ) : (
                        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                          <CircularProgress size={14} sx={{ mr: 1 }} />
                          <Typography variant="body2">{t("riskAnalysis.generatingSuggestions")}</Typography>
                        </Box>
                      )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default DetailsCard;