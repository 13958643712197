import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoadingState = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    // Get the current language direction (rtl for Arabic)
    const isRTL = i18n.dir() === 'rtl';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                py: 8,
                direction: isRTL ? 'rtl' : 'ltr' // Handle RTL languages like Arabic
            }}
        >
            <CircularProgress size={48} thickness={4} />
            <Typography
                variant="h7"
                color="text.secondary"
                sx={{
                    mt: 3,
                    fontWeight: 500
                }}
            >
                {t('loading_contracts')}
            </Typography>
        </Box>
    );
};

export default LoadingState;