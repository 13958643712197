import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography,
  alpha
} from '@mui/material';
import { Email, Phone } from '@mui/icons-material';

const ContactDialog = ({
  open,
  onClose,
  colors,
  t
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          maxWidth: '450px'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1, 
        pt: 3,
        px: 3,
        color: colors.text,
        fontWeight: 600,
        fontSize: '1.25rem'
      }}>
        {t('contact_us', 'Contact Us')}
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2 }}>
        <DialogContentText sx={{ color: colors.textSecondary, mb: 3 }}>
          {t('contact_billing_message', 'For questions about your subscription or to upgrade your plan, please contact our billing department:')}
        </DialogContentText>
        
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'flex-start', 
            mb: 3,
            p: 2,
            backgroundColor: alpha(colors.primary, 0.05),
            borderRadius: '12px',
            border: `1px solid ${alpha(colors.primary, 0.1)}`
          }}
        >
          <Box sx={{ 
            mr: 2, 
            mt: 0.5,
            backgroundColor: colors.primaryLight,
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: colors.primary
          }}>
            <Email />
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ color: colors.text, fontWeight: 600, mb: 0.5 }}>
              {t('email', 'Email')}
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href="mailto:facturation@juridia.ma"
              sx={{
                color: colors.primary,
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': { 
                  textDecoration: 'underline',
                  color: colors.primaryDark
                }
              }}
            >
              facturation@juridia.ma
            </Typography>
          </Box>
        </Box>
        
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'flex-start',
            p: 2,
            backgroundColor: alpha(colors.primary, 0.05),
            borderRadius: '12px',
            border: `1px solid ${alpha(colors.primary, 0.1)}`
          }}
        >
          <Box sx={{ 
            mr: 2, 
            mt: 0.5,
            backgroundColor: colors.primaryLight,
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: colors.primary
          }}>
            <Phone />
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ color: colors.text, fontWeight: 600, mb: 0.5 }}>
              {t('phone', 'Phone')}
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href="tel:+212610133995"
              sx={{
                color: colors.primary,
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': { 
                  textDecoration: 'underline',
                  color: colors.primaryDark
                }
              }}
            >
              +212 610 133 995
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 1 }}>
        <Button 
          onClick={onClose} 
          variant="contained"
          disableElevation
          sx={{ 
            backgroundColor: colors.primary,
            textTransform: 'none',
            fontWeight: 600,
            px: 4,
            py: 1,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: colors.primaryDark,
            }
          }}
        >
          {t('close', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;