import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#f0f0f0',
  color: 'rgba(0, 0, 0, 0.87)',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: 'black'
  },
  padding: '6px 12px',
  '& .MuiToggleButton-label': {
    paddingTop: '6px'
  },
  '&:not(:first-of-type)': {
    borderRadius: theme.shape.borderRadius
  },
  '&:first-of-type': {
    borderRadius: theme.shape.borderRadius
  },
  border: 'none',
  '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRight: 'none'
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      marginLeft: 0
    }
  },
  '&:hover': {
    backgroundColor: '#d6d6d6'
  }
}))