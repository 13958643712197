import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BillingInfoForm = ({ 
  updatedBillingInfo, 
  editingBillingInfo, 
  handleBillingInfoChange,
  colors 
}) => {
  const { t } = useTranslation();
  
  // Define form fields configuration for easy maintenance
  const formFields = [
    { name: 'first_name', label: t("first_name"), xs: 12, sm: 6, md: 4 },
    { name: 'last_name', label: t("last_name"), xs: 12, sm: 6, md: 4 },
    { name: 'organization_name', label: t("organization_name"), xs: 12, sm: 6, md: 4 },
    { name: 'user_email', label: "Email", xs: 12, sm: 6, md: 4 },
    { name: 'phone', label: t("phone"), xs: 12, sm: 6, md: 4 },
    { name: 'ice', label: "ICE", xs: 12, sm: 6, md: 4 },
    { name: 'tva_number', label: t("tva_number"), xs: 12, sm: 6, md: 4 },
    { name: 'address_line1', label: t("address_line1"), xs: 12, sm: 6, md: 4 },
    { name: 'address_line2', label: t("address_line2"), xs: 12, sm: 6, md: 4 },
    { name: 'city', label: t("city"), xs: 12, sm: 6, md: 4 },
    { name: 'zip_code', label: t("postal_code"), xs: 12, sm: 6, md: 4 },
    { name: 'country', label: t("country"), xs: 12, sm: 6, md: 4 },
  ];
  
  const getTextFieldStyles = (editing) => ({
    bgcolor: editing ? 'transparent' : colors.backgroundAlt, 
    borderRadius: '8px',
    transition: 'all 0.2s ease',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.border,
      },
      '&:hover fieldset': {
        borderColor: editing ? colors.primary : colors.border,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary,
      },
    },
  });

  return (
    <Grid container spacing={3}>
      {formFields.map((field) => (
        <Grid item xs={field.xs} sm={field.sm} md={field.md} key={field.name}>
          <TextField
            label={field.label}
            variant="outlined"
            fullWidth
            value={updatedBillingInfo?.[field.name] || ""}
            onChange={handleBillingInfoChange(field.name)}
            InputProps={{
              readOnly: !editingBillingInfo,
            }}
            sx={getTextFieldStyles(editingBillingInfo)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BillingInfoForm;