import {
  SET_LOADING_DOCUMENT,
  SET_DOCUMENT,
  SET_IS_MODAL_MOBILE_VISIBLE,
  CLEAR_DOCUMENT,
  SET_SELECTED_CITATION,
  SET_TRANSLATION_PROGRESS,
  SET_TRANSLATION_IN_PROGRESS,
  SET_DOCUMENT_SOURCE_URL
} from '../actions/types'

export const setLoadingDocument = isLoading => ({
  type: SET_LOADING_DOCUMENT,
  payload: isLoading
})

export const setDocument = document => ({
  type: SET_DOCUMENT,
  payload: document
})

export const setIsModalMobileVisible = isVisible => ({
  type: SET_IS_MODAL_MOBILE_VISIBLE,
  payload: isVisible
})

export const clearDocument = () => ({
  type: CLEAR_DOCUMENT
})

export const setSelectedCitation = citation => ({
  type: SET_SELECTED_CITATION,
  payload: citation
})

export const setTranslationProgress = value => ({
  type: SET_TRANSLATION_PROGRESS,
  payload: value
})
export const setIsTranslationInProgress = value => ({
  type: SET_TRANSLATION_IN_PROGRESS,
  payload: value
})

export const setDocumentSourceUrl = url => ({
  type: SET_DOCUMENT_SOURCE_URL,
  payload: url
})
