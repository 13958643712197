import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import * as Y from 'yjs'
import { Decoration, DecorationSet } from 'prosemirror-view'

// Create a custom plugin key to ensure our plugin is unique
const cursorPluginKey = new PluginKey('custom-collaboration-cursor')

export const CustomCollaborationCursor = Extension.create({
    name: 'customCollaborationCursor',

    // High priority to ensure it loads after Collaboration extension
    priority: 1000,

    addOptions() {
        return {
            provider: null,
            user: {
                name: null,
                color: null,
                id: null
            },
            render: user => {
                // Create cursor element with modern styling
                const cursor = document.createElement('span')
                cursor.classList.add('collaboration-cursor__caret')
                cursor.style.cssText = `
                    border-left: 3px solid ${user.color};
                    margin-left: -1px;
                    margin-right: -1px;
                    pointer-events: none;
                    word-break: normal;
                    height: 1.2em;
                    display: inline-block;
                    position: relative;
                    z-index: 50;
                    animation: blink 1s infinite;
                `

                // Create label with modern styling
                const label = document.createElement('div')
                label.classList.add('collaboration-cursor__label')
                label.style.cssText = `
                    position: absolute;
                    top: -1.4em;
                    left: -1px;
                    border-radius: 4px;
                    padding: 2px 6px;
                    font-size: 11px;
                    font-weight: 600;
                    line-height: normal;
                    white-space: nowrap;
                    color: white;
                    background-color: ${user.color};
                    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
                    user-select: none;
                    pointer-events: none;
                    z-index: 51;
                    transform-origin: bottom left;
                    animation: fadeIn 0.3s ease-out;
                `
                label.textContent = user.name || 'Anonymous'
                cursor.appendChild(label)

                return cursor
            },
            selectionRender: user => {
                // Generate RGBA color with transparency (increased opacity)
                const rgbaColor = hexToRgba(user.color, 0.35);
                
                return {
                    nodeName: 'span',
                    class: 'collaboration-cursor__selection',
                    style: `
                        background-color: ${rgbaColor};
                        position: relative;
                        z-index: 20;
                        border-radius: 2px;
                        mix-blend-mode: multiply;
                    `,
                    'data-user-id': user.id || '',
                    'data-user-name': user.name || 'Anonymous',
                }
            }
        }
    },

    onCreate() {
        if (!this.options.provider) {
            console.error('Missing provider for collaboration cursor');
            throw new Error('Provider is required for CustomCollaborationCursor')
        }
        
        // Add necessary CSS animations to document if not present
        addCursorAnimationsToDocument();
    },

    addStorage() {
        return {
            users: [],
        }
    },

    onBeforeCreate() {
        // Ensure the Collaboration extension is loaded
        if (!this.editor.extensionManager.extensions.find(ext => ext.name === 'collaboration')) {
            console.error('The collaboration extension must be loaded before the customCollaborationCursor extension');
        }
    },

    addCommands() {
        return {
            updateUser: attributes => () => {
                // Update user data safely without spread operator
                const newUser = {};
                // Copy existing properties
                if (this.options.user) {
                    Object.keys(this.options.user).forEach(key => {
                        newUser[key] = this.options.user[key];
                    });
                }
                // Copy new properties
                if (attributes) {
                    Object.keys(attributes).forEach(key => {
                        newUser[key] = attributes[key];
                    });
                }
                this.options.user = newUser;

                // Update awareness state if provider exists
                if (this.options.provider && this.options.provider.awareness) {
                    const currentState = this.options.provider.awareness.getLocalState() || {};

                    this.options.provider.awareness.setLocalState({
                        user: this.options.user,
                        selection: currentState.selection || null
                    });
                }

                return true
            }
        }
    },

    // Custom implementation of cursor rendering
    addProseMirrorPlugins() {
        const extension = this;
        const { provider, user } = this.options

        if (!provider || !provider.awareness) {
            console.error('Invalid provider for collaboration cursor');
            return []
        }

        // Create a direct reference to the awareness instance
        const awareness = provider.awareness;

        // Initialize awareness with current user
        awareness.setLocalState({
            user,
            selection: null
        });

        // Safe emit function that avoids spread operator issues in Y.js
        function safeEmitAwarenessUpdate(awarenessInstance, removedClientIds) {
            try {
                // Don't use the emit method directly with objects that might use spread
                // Instead, manually call the handlers
                if (awarenessInstance._observers && awarenessInstance._observers.update) {
                    const handlers = awarenessInstance._observers.update;
                    if (Array.isArray(handlers)) {
                        // Create a simple event object with arrays
                        const event = {
                            added: [],
                            updated: [],
                            removed: removedClientIds
                        };
                        
                        // Manually call each handler
                        for (let i = 0; i < handlers.length; i++) {
                            try {
                                handlers[i](event);
                            } catch (err) {
                                console.error('Error in awareness handler:', err);
                            }
                        }
                    }
                }
            } catch (err) {
                console.error('Error in safeEmitAwarenessUpdate:', err);
            }
        }

        // Create custom cursor plugin
        const customCursorPlugin = new Plugin({
            key: cursorPluginKey,

            state: {
                init() {
                    return {
                        decorations: DecorationSet.empty,
                        cursors: new Map()
                    }
                },

                apply(tr, oldState) {
                    // Create a new state object without using spread
                    const newState = {
                        decorations: oldState.decorations,
                        cursors: oldState.cursors
                    };

                    // Update decorations when the document changes
                    if (tr.docChanged) {
                        newState.decorations = DecorationSet.empty
                    }

                    return newState
                }
            },

            view(editorView) {
                // Initial selection update to register cursor
                setTimeout(() => {
                    updateCursorPosition(editorView)
                }, 300)

                // Interval to ensure cursor positions stay updated
                const cursorUpdateInterval = setInterval(() => {
                    // Get all awareness states and update cursor positions
                    if (awareness && typeof awareness.getStates === 'function') {
                        const states = awareness.getStates();
                        renderRemoteCursors(editorView, states);
                    }
                }, 1000)

                // Add cleanup interval that checks for stale cursors
                const cursorCleanupInterval = setInterval(() => {
                    try {
                        // Create a safe Set of active user IDs
                        const activeUserIds = new Set();
                        
                        // Check if editorProps and activeUsers exist and are valid
                        if (extension.options.editorProps && 
                            extension.options.editorProps.activeUsers) {
                            
                            // Check if activeUsers is an array
                            const activeUsers = extension.options.editorProps.activeUsers;
                            if (Array.isArray(activeUsers)) {
                                // Use traditional for loop to avoid any spread-related issues
                                for (let i = 0; i < activeUsers.length; i++) {
                                    const activeUser = activeUsers[i];
                                    if (activeUser && 
                                        typeof activeUser === 'object' && 
                                        activeUser.id !== undefined && 
                                        activeUser.id !== null) {
                                        activeUserIds.add(activeUser.id);
                                    }
                                }
                            }
                        }

                        // Find client IDs whose users are no longer active
                        const staleClientIds = [];
                        
                        // Check if awareness exists and has getStates method
                        if (awareness && typeof awareness.getStates === 'function') {
                            const states = awareness.getStates();
                            
                            // Check if states is valid and iterable
                            if (states && typeof states.forEach === 'function') {
                                states.forEach((state, clientId) => {
                                    // Skip if this is our own cursor
                                    if (clientId === provider.doc.clientID) {
                                        return;
                                    }
                                    
                                    // Check if user state exists and has an ID
                                    if (state && 
                                        state.user && 
                                        state.user.id !== undefined && 
                                        state.user.id !== null) {
                                        // Only add to stale list if not in active users
                                        if (!activeUserIds.has(state.user.id)) {
                                            staleClientIds.push(clientId);
                                        }
                                    }
                                });
                            }
                        }

                        // Remove stale awareness states
                        if (staleClientIds.length > 0 && staleClientIds.length < 100) { // Sanity check
                            // Check if awareness and states exist
                            if (awareness && awareness.states && typeof awareness.states.delete === 'function') {
                                // Use traditional for loop
                                for (let i = 0; i < staleClientIds.length; i++) {
                                    awareness.states.delete(staleClientIds[i]);
                                }
                                
                                // Use our safe emit function instead of the built-in one
                                safeEmitAwarenessUpdate(awareness, staleClientIds);
                                
                                // Force rerender if possible
                                if (typeof awareness.getStates === 'function') {
                                    renderRemoteCursors(editorView, awareness.getStates());
                                }
                            }
                        }
                    } catch (err) {
                        console.error('Error in cursor cleanup interval:', err);
                    }
                }, 10000);

                return {
                    update(view) {
                        updateCursorPosition(view)
                    },

                    destroy() {
                        clearInterval(cursorUpdateInterval)
                        clearInterval(cursorCleanupInterval)
                    }
                }
            },

            props: {
                decorations(state) {
                    return this.getState(state).decorations
                }
            }
        })

        // Helper to update our cursor position in awareness
        function updateCursorPosition(view) {
            try {
                if (!view || !view.state) return;
                
                const { state } = view;
                if (!state.selection) return;
                
                const { selection } = state;
                const { from, to } = selection;

                // Update local awareness with current selection
                const currentState = awareness.getLocalState() || {};
                const currentUser = currentState.user || user;
                const currentSelection = currentState.selection;
                
                // Check if selection has changed
                const needsUpdate = !currentSelection || 
                                    currentSelection.anchor !== from || 
                                    currentSelection.head !== to;
                
                if (needsUpdate) {
                    awareness.setLocalState({
                        user: currentUser,
                        selection: {
                            anchor: from,
                            head: to
                        }
                    });
                }
            } catch (error) {
                console.error('Error updating cursor position:', error);
            }
        }

        // Helper to render remote cursors
        function renderRemoteCursors(view, states) {
            try {
                // Skip if editor not ready
                if (!view || !view.state || !view.state.doc) return;
                if (!states || typeof states.forEach !== 'function') return;

                const { doc } = view.state;
                const decorations = [];
                const localClientId = provider.doc.clientID;

                // Process each remote state
                states.forEach((state, clientId) => {
                    // Skip local user
                    if (clientId === localClientId) return;

                    // Skip users without selection or user info
                    if (!state || !state.user || !state.selection) return;

                    // Get cursor position
                    const { anchor, head } = state.selection;
                    if (anchor === undefined || head === undefined) return;

                    // Skip invalid positions
                    if (anchor < 0 || head < 0 || 
                        anchor > doc.content.size || 
                        head > doc.content.size) return;

                    // Create decoration at position
                    try {
                        // Create cursor element using the extension's render function
                        const cursor = extension.options.render(state.user);
                        if (!cursor) return;

                        // Add cursor decoration
                        decorations.push(
                            Decoration.widget(head, cursor, {
                                key: `cursor-${clientId}`,
                                side: 0
                            })
                        );

                        // Add selection decoration if range exists
                        if (anchor !== head) {
                            const selectionProps = extension.options.selectionRender(state.user);
                            if (!selectionProps) return;

                            const from = Math.min(anchor, head);
                            const to = Math.max(anchor, head);

                            decorations.push(
                                Decoration.inline(from, to, selectionProps, {
                                    key: `selection-${clientId}`
                                })
                            );
                        }
                    } catch (err) {
                        console.error('Error creating decoration:', err);
                    }
                });

                // Get current plugin state
                const pluginState = cursorPluginKey.getState(view.state);
                if (!pluginState) return;

                // Update plugin state with new decoration
                pluginState.decorations = DecorationSet.create(doc, decorations);

                // Force view update
                view.updateState(view.state);
            } catch (error) {
                console.error('Error rendering remote cursors:', error);
            }
        }

        return [customCursorPlugin];
    },
});

// Helper functions

// Convert hex color to rgba for selections
function hexToRgba(hex, alpha = 1) {
    // Default to a light blue if hex is invalid
    if (!hex || typeof hex !== 'string') {
        return `rgba(104, 206, 248, ${alpha})`;
    }
    
    // Remove # if present
    hex = hex.replace('#', '');
    
    // Handle shorthand hex
    if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('');
    }
    
    // Parse hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Check if parsing worked
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return `rgba(104, 206, 248, ${alpha})`;
    }
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Add needed animations to document head
function addCursorAnimationsToDocument() {
    // Check if the animations are already in the document
    if (document.getElementById('collaboration-cursor-animations')) return;
    
    // Create style element
    const style = document.createElement('style');
    style.id = 'collaboration-cursor-animations';
    style.textContent = `
        @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.5; }
            100% { opacity: 1; }
        }
        
        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-5px); }
            to { opacity: 1; transform: translateY(0); }
        }
    `;
    
    // Add to document head
    document.head.appendChild(style);
}