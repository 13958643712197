import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    useTheme,
    Paper,
    alpha
} from '@mui/material';
import {
    AddCircleOutline as AddIcon,
    Description as DocumentIcon,
    Update as RecentIcon,
    Share as SharedIcon,
    Edit as DraftIcon,
    HourglassEmpty as PendingIcon,
    CheckCircleOutline as SignedIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import CreateContractDialog from '../dialogs/CreateContractDialog';
import { useTranslation } from 'react-i18next';

// Reusable Empty State component with customizable icon, message and action
const EmptyStateBase = ({
    icon,
    title,
    description,
    actionText,
    onAction,
    color
}) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 3,
                border: `1px dashed ${alpha(theme.palette.divider, 0.5)}`,
                bgcolor: alpha(theme.palette.background.default, 0.5),
                py: 5
            }}
        >
            <Box
                sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: '50%',
                    bgcolor: alpha(color || theme.palette.primary.main, 0.1),
                    color: color || theme.palette.primary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {React.cloneElement(icon, { style: { fontSize: 50 } })}
            </Box>

            <Typography
                variant="h5"
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    mb: 1,
                    color: theme.palette.text.primary
                }}
            >
                {title}
            </Typography>

            <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                    textAlign: 'center',
                    maxWidth: 500,
                    mb: 3
                }}
            >
                {description}
            </Typography>

            {actionText && onAction && (
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={onAction}
                    sx={{
                        borderRadius: 2,
                        py: 1,
                        px: 3,
                        textTransform: 'none',
                        fontWeight: 600,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
                        bgcolor: color || theme.palette.primary.main,
                        '&:hover': {
                            bgcolor: color ? alpha(color, 0.9) : theme.palette.primary.dark,
                            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)'
                        }
                    }}
                >
                    {actionText}
                </Button>
            )}
        </Paper>
    );
};

// Wrapper component for empty states that need a create contract button
const EmptyStateWithCreateAction = ({ icon, title, description, actionText, color }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleContractCreated = (contractId) => {
        // Contract creation success - dialog will handle navigation
    };

    return (
        <>
            <EmptyStateBase
                icon={icon}
                title={title}
                description={description}
                actionText={actionText}
                onAction={handleOpenDialog}
                color={color}
            />

            <CreateContractDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onSuccess={handleContractCreated}
            />
        </>
    );
};

// Main component that displays the appropriate empty state based on the active tab
const EmptyState = ({
    activeTab = 0,
    searchTerm = '',
    isSearching = false
}) => {
    const { t } = useTranslation();

    // Translation keys for all components
    const translations = {
        // All contracts
        allTitle: t('no_contracts_found'),
        allDescription: t('no_contracts_description'),
        allAction: t('create_your_first_contract'),

        // Recent contracts
        recentTitle: t('no_recent_activity'),
        recentDescription: t('no_recent_contracts_description'),
        recentAction: t('create_new_contract'),

        // Shared contracts
        sharedTitle: t('no_shared_contracts'),
        sharedDescription: t('no_shared_contracts_description'),

        // Drafts
        draftsTitle: t('no_draft_contracts'),
        draftsDescription: t('no_draft_contracts_description'),
        draftsAction: t('create_new_draft'),

        // Pending signatures
        pendingTitle: t('no_pending_signatures'),
        pendingDescription: t('no_pending_signatures_description'),
        pendingAction: t('create_contract_for_signature'),

        // Signed contracts
        signedTitle: t('no_signed_contracts'),
        signedDescription: t('no_signed_contracts_description'),
        signedAction: t('create_contract_to_sign'),

        // Search results
        searchTitle: t('no_matching_contracts'),
        searchDescription: t('no_matching_contracts_description')
    };

    // Empty state for when there are no contracts of any type
    const EmptyAllContracts = () => (
        <EmptyStateWithCreateAction
            icon={<DocumentIcon />}
            title={translations.allTitle}
            description={translations.allDescription}
            actionText={translations.allAction}
            color="#3B82F6" // Blue
        />
    );

    // Empty state for when there are no recent contracts
    const EmptyRecentContracts = () => (
        <EmptyStateWithCreateAction
            icon={<RecentIcon />}
            title={translations.recentTitle}
            description={translations.recentDescription}
            actionText={translations.recentAction}
            color="#8B5CF6" // Purple
        />
    );

    // Empty state for when there are no shared contracts
    const EmptySharedContracts = () => (
        <EmptyStateBase
            icon={<SharedIcon />}
            title={translations.sharedTitle}
            description={translations.sharedDescription}
            color="#EC4899" // Pink
        />
    );

    // Empty state for when there are no draft contracts
    const EmptyDrafts = () => (
        <EmptyStateWithCreateAction
            icon={<DraftIcon />}
            title={translations.draftsTitle}
            description={translations.draftsDescription}
            actionText={translations.draftsAction}
            color="#F59E0B" // Amber
        />
    );

    // Empty state for when there are no pending signature contracts
    const EmptyPendingSignatures = () => (
        <EmptyStateWithCreateAction
            icon={<PendingIcon />}
            title={translations.pendingTitle}
            description={translations.pendingDescription}
            actionText={translations.pendingAction}
            color="#EF4444" // Red
        />
    );

    // Empty state for when there are no signed contracts
    const EmptySignedContracts = () => (
        <EmptyStateWithCreateAction
            icon={<SignedIcon />}
            title={translations.signedTitle}
            description={translations.signedDescription}
            actionText={translations.signedAction}
            color="#10B981" // Green
        />
    );

    // Empty state for when search returns no results
    const EmptySearchResults = ({ searchTerm }) => (
        <EmptyStateBase
            icon={<SearchIcon />}
            title={translations.searchTitle}
            description={t('no_search_results_description', { searchTerm })}
            color="#6B7280" // Gray
        />
    );

    // If searching, show search empty state
    if (isSearching && searchTerm) {
        return <EmptySearchResults searchTerm={searchTerm} />;
    }

    // Otherwise show tab-specific empty states
    switch (activeTab) {
        case 0: // All Contracts
            return <EmptyAllContracts />;
        case 1: // Recent Contracts
            return <EmptyRecentContracts />;
        case 2: // Shared Contracts
            return <EmptySharedContracts />;
        case 3: // Drafts
            return <EmptyDrafts />;
        case 4: // Pending Signatures
            return <EmptyPendingSignatures />;
        case 5: // Signed Contracts
            return <EmptySignedContracts />;
        default:
            return <EmptyAllContracts />;
    }
};

export default EmptyState;