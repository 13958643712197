import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  Chip,
  IconButton,
  Button,
  Avatar,
  AvatarGroup,
  Tooltip as MuiTooltip,
  useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Share as ShareIcon,
  GetApp as GetAppIcon,
  Delete as DeleteIcon,
  Launch as OpenIcon,
  Star as StarIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDate, getRoleColor, getStatusKey, getStatusStyle } from '../../../utils/utils';



// Helper function to get role icon
const getRoleIcon = (role) => {
  const roleIcons = {
    OWNER: <StarIcon fontSize="inherit" />,
    // Add more role icons as needed
  };
  
  return roleIcons[role] || null;
};

const ContractCard = ({
  contract,
  handleClick,
  handleShare,
  handleDownload,
  handleDelete,
  handleOpen,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // Get the status key for translation
  const statusKey = getStatusKey(contract.status);

  // Get the translated status text
  const statusText = t(`contractStatus.${statusKey}`);

  // Determine if we have multiple collaborators
  const hasMultipleCollaborators = contract.collaborators && contract.collaborators.length > 1;

  // Get current user from Redux state
  const currentUser = useSelector((state) => state.auth.user.user);

  // Find current user's role in collaborators
  const collaborators = contract.collaborators || [];
  const userCollaborator = collaborators.find(
    collab => collab.id === currentUser?.id
  );
  const userRole = userCollaborator?.role;

  // Get translated role name
  const currentUserRole = userRole ? t(`${userRole}`.toUpperCase()) : '';
  
  // Get role color
  const roleColor = getRoleColor(userRole, theme);
  
  // Get role icon
  const roleIcon = getRoleIcon(userRole);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.08)'
        },
        cursor: 'pointer',
        overflow: 'hidden'
      }}
      onClick={() => handleClick(contract)}
    >
      <CardContent sx={{ flexGrow: 1, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Chip
            label={statusText}
            size="small"
            sx={getStatusStyle(statusKey)}
          />
          
          {/* {hasMultipleCollaborators && userRole ? (
            <MuiTooltip title={`You are ${currentUserRole.toLowerCase()} of this contract`}>
              <Chip
                icon={roleIcon}
                label={currentUserRole}
                size="small"
                sx={{
                  backgroundColor: alpha(roleColor, 0.15),
                  color: roleColor,
                  fontWeight: 600,
                  borderRadius: '12px',
                  border: `1px solid ${alpha(roleColor, 0.25)}`,
                  '& .MuiChip-icon': {
                    color: roleColor
                  }
                }}
              />
            </MuiTooltip>
          ) : (
            <MuiTooltip title={contract.description || t('not_specified')}>
              <Typography
                variant="caption"
                color="text.secondary"
                noWrap
                sx={{
                  backgroundColor: alpha(theme.palette.background.default, 0.7),
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 5,
                  fontWeight: 500
                }}
              >
                {contract.description}
              </Typography>
            </MuiTooltip>
          )} */}
        </Box>

        <Typography
          variant="h6"
          component="div"
          gutterBottom
          noWrap
          sx={{
            fontWeight: 600,
            fontSize: '1.1rem',
            mb: 2
          }}
        >
          {contract.title}
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {t('created')}: {formatDate(contract.created_at, i18n.language)}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {t('last_modified')}: {formatDate(contract.updated_at, i18n.language)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 'auto' }}>
          <AvatarGroup
            max={3}
            sx={{
              '& .MuiAvatar-root': {
                width: 28,
                height: 28,
                fontSize: '0.8rem',
                fontWeight: 600,
                border: `2px solid ${theme.palette.background.paper}`
              }
            }}
          >
            {contract.collaborators && contract.collaborators.map((collaborator, idx) => (
              <MuiTooltip
                key={idx}
                title={`${collaborator.first_name} ${collaborator.last_name} (${t(`${collaborator.role}`.toUpperCase())})`}
              >
                <Avatar
                  sx={{
                    bgcolor: getRoleColor(collaborator.role, theme),
                    border: collaborator.id === currentUser?.id ? 
                      `2px solid ${theme.palette.common.white}` : 
                      `2px solid ${theme.palette.background.paper}`,
                    boxShadow: collaborator.id === currentUser?.id ? 
                      `0 0 0 2px ${roleColor}` : 'none'
                  }}
                >
                  {collaborator.first_name[0]}{collaborator.last_name[0]}
                </Avatar>
              </MuiTooltip>
            ))}
          </AvatarGroup>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ ml: 1, fontWeight: 500 }}
          >
            {contract.collaborators
              ? `${contract.collaborators.length} ${contract.collaborators.length === 1
                ? t('collaborator')
                : t('collaborators')}`
              : t('no_collaborators')}
          </Typography>
        </Box>
      </CardContent>

      <CardActions
        sx={{
          px: 2,
          py: 1.5,
          borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          bgcolor: alpha(theme.palette.background.default, 0.5)
        }}
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleShare(contract.id);
          }}
          sx={{ color: theme.palette.text.secondary }}
          aria-label={t('share_contract')}
        >
          <ShareIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(contract.id);
          }}
          sx={{ color: theme.palette.text.secondary }}
          aria-label={t('download')}
        >
          <GetAppIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete([contract.id]);
          }}
          sx={{
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.main, 0.1)
            }
          }}
          aria-label={t('delete')}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <Button
          size="small"
          variant="outlined"
          startIcon={<OpenIcon fontSize="small" />}
          sx={{
            ml: 'auto',
            borderRadius: 1.5,
            textTransform: 'none',
            fontWeight: 600
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleOpen(contract.id);
          }}
        >
          {t('open')}
        </Button>
      </CardActions>
    </Card>
  );
};

const ContractGrid = ({
  filteredContracts,
  handleContractClick,
  handleShareContract,
  handleDownloadContract,
  handleOpenDeleteDialog,
  handleOpenContract,
}) => {
  return (
    <Grid container spacing={3}>
      {filteredContracts.map((contract) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={contract.id}>
          <ContractCard
            contract={contract}
            handleClick={handleContractClick}
            handleShare={handleShareContract}
            handleDownload={handleDownloadContract}
            handleDelete={handleOpenDeleteDialog}
            handleOpen={handleOpenContract}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ContractGrid;