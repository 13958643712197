import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as ConfirmationIcon } from "../../assets/signup/confirmation.svg";
import { colors } from "../../utils/colors";
import { windowHeight, windowWidth } from "../../utils/utils";

const SignupConfirmationScreen = () => {
  const navigate = useNavigate();

  const handleConfirmation = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          borderRadius: "20px",
          width: "60%",
          padding: "40px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh", // Adjust height to better center content
        }}
      >
        {/* Confirmation Icon */}
        <ConfirmationIcon
          style={{
            width: windowHeight / 2.5,
            height: windowHeight / 2.5,
            marginRight: windowWidth / 40,
            marginBottom: "20px",
          }}
        />

        {/* Welcome Message */}
        <Typography variant="h4" sx={{ mb: 2 }}>
          Bienvenue chez Juridia !
        </Typography>

        {/* Description Text */}
        <Typography variant="body1" sx={{ mb: 1 }}>
          Votre demande d'enregistrement est en cours de traitement, vous serez
          bientôt contacté par nos équipes pour finaliser votre inscription.
        </Typography>

        <Typography variant="body1" sx={{ mb: 0, fontWeight: "bold" }}>
          {"Si vous souhaitez activer immédiatement votre accès contactez-nous par"}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, fontWeight: "bold" }}>
          {"téléphone au +212 6 10 133 995."}
        </Typography>

        {/* Confirmation Button */}
        <CustomButton
          text="C’est compris !"
          outlined={false}
          onClick={handleConfirmation}
          sx={{ width: "50%", padding: "10px 0" }}
        />
      </Paper>
    </Grid>
  );
};

export default SignupConfirmationScreen;