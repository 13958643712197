import React from 'react';
import { Box, Typography, Button, Paper, LinearProgress, alpha } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

const UserLimitInfo = ({
    maxUsers,
    currentUsers,
    colors,
    onContactClick,

}) => {
    const { t } = useTranslation();
    // Calculate percentage of user limit used
    const percentage = Math.min(Math.round((currentUsers / maxUsers) * 100), 100);

    // Determine color based on usage percentage
    const getProgressColor = () => {
        if (percentage < 70) return colors.secondary;
        if (percentage < 90) return colors.warning;
        return colors.error;
    };

    return (
        <Paper
            elevation={0}
            sx={{
                mb: 3,
                p: 3,
                backgroundColor: colors.surface,
                borderRadius: '16px',
                border: `1px solid ${colors.border}`
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box
                    sx={{
                        mr: 2,
                        backgroundColor: alpha(colors.info, 0.1),
                        color: colors.info,
                        width: 36,
                        height: 36,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Info />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1, color: colors.text }}>
                        {t('subscription_info', 'Subscription Information')}
                    </Typography>

                    <Box sx={{ mb: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                            <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                                {t('user_limit_info', 'Your current subscription allows {{maxUsers}} users. You currently have {{currentUsers}} users.',
                                    { maxUsers, currentUsers })}
                            </Typography>
                            <Typography variant="body2" sx={{
                                fontWeight: 600,
                                color: getProgressColor(),
                                ml: 2
                            }}>
                                {percentage}%
                            </Typography>
                        </Box>

                        <LinearProgress
                            variant="determinate"
                            value={percentage}
                            sx={{
                                height: 8,
                                borderRadius: 4,
                                backgroundColor: alpha(getProgressColor(), 0.2),
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: getProgressColor(),
                                    borderRadius: 4
                                }
                            }}
                        />
                    </Box>

                    {currentUsers >= maxUsers && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: 2,
                            p: 2,
                            backgroundColor: alpha(colors.error, 0.1),
                            borderRadius: '8px',
                            border: `1px solid ${alpha(colors.error, 0.2)}`
                        }}>
                            <Typography variant="body2" sx={{ color: colors.error, fontWeight: 500 }}>
                                {t('user_limit_reached', "You've reached your user limit. Upgrade your plan to add more users.")}
                            </Typography>
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={onContactClick}
                                sx={{
                                    ml: 2,
                                    borderColor: colors.primary,
                                    color: colors.primary,
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    '&:hover': {
                                        borderColor: colors.primaryDark,
                                        backgroundColor: alpha(colors.primary, 0.05)
                                    }
                                }}
                            >
                                {t('contact_us', 'Contact Us')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Paper>
    );
};

export default UserLimitInfo;