import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import SectionHeader from './SectionHeader';

// Cancel Confirmation Dialog Component
const CancelConfirmationDialog = ({ open, onClose, onConfirm, isCancelling, colors }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={isCancelling ? undefined : onClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              p: 1,
              mr: 2,
              borderRadius: '50%',
              backgroundColor: alpha(colors.error, 0.1),
              color: colors.error
            }}
          >
            <WarningAmberIcon fontSize="large" />
          </Box>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {t("confirm_cancel_title", "Cancel Subscription")}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ color: colors.text, mb: 2 }}>
          {t("confirm_cancel_subscription", "Are you sure you want to cancel your subscription?")}
        </DialogContentText>

        <DialogContentText sx={{ color: colors.textSecondary, fontSize: '0.9rem' }}>
          {t("cancel_subscription_warning", "You will still have access until the end of your current billing period, but your subscription will not renew automatically.")}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={onClose}
          color="inherit"
          disabled={isCancelling}
          sx={{
            mr: 1,
            textTransform: 'none',
            fontWeight: 600,
            color: colors.textSecondary
          }}
        >
          {t("back")}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isCancelling}
          variant="contained"
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            borderRadius: 1.5,
            bgcolor: colors.error,
            '&:hover': {
              bgcolor: alpha(colors.error, 0.8)
            }
          }}
        >
          {isCancelling ? (
            <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
          ) : null}
          {isCancelling ? t("cancelling") : t("cancel_subscription")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SubscriptionDetails = ({
  subscription,
  subscriptionPayload,
  billingInfo,
  formatDate,
  handleCancelSubscription,
  colors
}) => {
  const { t } = useTranslation();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  console.log('subscriptionPayload', subscriptionPayload)
  // Subscription field configs
  const subscriptionFields = [
    {
      name: 'status',
      label: t("status"),
      value: subscription?.status ? (subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)) : "",
      xs: 12, sm: 6, md: 4
    },
    {
      name: 'type',
      label: t("subscription_type"),
      value: subscriptionPayload?.subscription_type === "annual" ? t("yearly") : t("monthly"),
      xs: 12, sm: 6, md: 4
    },
    {
      name: 'price',
      label: t("price"),
      value: `${billingInfo?.amount || "0"} ${billingInfo?.currency || "MAD"}`,
      xs: 12, sm: 6, md: 4
    },
    {
      name: 'start_date',
      label: t("start_date"),
      value: formatDate(subscription?.start_date),
      xs: 12, sm: 6, md: 4
    },
    {
      name: 'next_billing',
      label: t("next_billing_date"),
      value: formatDate(subscription?.next_billing_date),
      xs: 12, sm: 6, md: 4
    },
    {
      name: 'payment_method',
      label: t("payment_method"),
      value: billingInfo?.payment_method || "",
      xs: 12, sm: 6, md: 4
    },
  ];

  const getRemainingDays = () => {
    if (subscription?.next_billing_date) {
      const endDate = new Date(subscription.next_billing_date);
      const today = new Date();
      const diffTime = endDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 0) {
        return `${t("subscription_ends_in", { days: diffDays })}`;
      }
      return t("subscription_ended");
    }
    return t("subscription_cancelled");
  };

  const openCancelDialog = () => {
    setCancelDialogOpen(true);
  };

  const closeCancelDialog = () => {
    setCancelDialogOpen(false);
  };

  const confirmCancelSubscription = async () => {
    setIsCancelling(true);
    try {
      await handleCancelSubscription();
    } finally {
      setIsCancelling(false);
      closeCancelDialog();
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <SectionHeader
          icon={<AccountBalanceWalletIcon />}
          title={t("subscription_details")}
        />

        {subscription?.status === "cancelled" ? (
          <Typography
            component="span"
            sx={{
              color: colors.textSecondary,
              fontSize: '0.9rem',
              fontWeight: 500,
              py: 0.75,
              px: 1.5,
              borderRadius: '6px',
              bgcolor: colors.backgroundAlt,
            }}
          >
            {getRemainingDays()}
          </Typography>
        ) : (
          <>
            {subscription.status === "active" && (
              <Typography
                component="span"
                sx={{
                  color: colors.error,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: 500,
                  transition: 'all 0.2s',
                  '&:hover': {
                    color: colors.error,
                    opacity: 0.8
                  }
                }}
                onClick={openCancelDialog}
              >
                {t("cancel_subscription")}
              </Typography>
            )}
          </>
        )}
      </Box>

      <Grid container spacing={3}>
        {subscriptionFields.map((field) => (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} key={field.name}>
            <TextField
              label={field.label}
              variant="outlined"
              fullWidth
              value={field.value}
              InputProps={{
                readOnly: true,
                sx: {
                  bgcolor: colors.backgroundAlt,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.border,
                  },
                }
              }}
              sx={{ borderRadius: '8px' }}
            />
          </Grid>
        ))}
      </Grid>

      {/* Cancel Confirmation Dialog */}
      <CancelConfirmationDialog
        open={cancelDialogOpen}
        onClose={closeCancelDialog}
        onConfirm={confirmCancelSubscription}
        isCancelling={isCancelling}
        colors={colors}
      />
    </>
  );
};

export default SubscriptionDetails;