import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PricingScreen.css';
import { useSubscription } from '../../contexts/SubscriptionProvider';

const PricingScreen = () => {
    const navigate = useNavigate();
    const { 
        subscription, 
        planType: currentPlanType, 
        subscriptionType: currentSubscriptionType,
        loading
    } = useSubscription();

    // Set initial toggle state based on user's current subscription
    const [isAnnual, setIsAnnual] = useState(
        currentSubscriptionType === 'annual'
    );

    // Effect to sync toggle with subscription when loaded
    useEffect(() => {
        if (!loading && currentSubscriptionType) {
            setIsAnnual(currentSubscriptionType === 'annual');
        }
    }, [loading, currentSubscriptionType]);

    // Pricing data
    const pricingData = {
        monthly: {
            individual: {
                price: '590',
                equivalent: 'Résiliable en un clic',
                features: [
                    'Accès à la base documentaire complète',
                    'Recherche juridique avancée',
                    'Génération de contracts simples',
                    'Traduction dans 5 langues',
                    'Support de base',
                    '1 utilisateur'
                ]
            },
            professional: {
                price: '1190',
                equivalent: 'Résiliable en un clic',
                features: [
                    'Tout ce qui est inclus dans Individuel',
                    'Rédaction assistée avancée',
                    'Traduction dans 15+ langues',
                    'Support premium',
                    'Espace collaboratif sécurisé',
                    'Jusqu\'à 5 utilisateurs'
                ]
            },
            enterprise: {
                price: 'Personnalisée',
                equivalent: 'Contactez-nous pour une offre sur mesure',
                features: [
                    'Tout ce qui est inclus dans Professionnel',
                    'Intégration de vos données propriétaires',
                    'API pour intégration avec vos outils',
                    'Formation et support personnalisés',
                    'Tableaux de bord personnalisés',
                    "Nombre d'utilisateurs à définir"
                ]
            }
        },
        annual: {
            individual: {
                price: '5580',
                equivalent: '490',
                features: [
                    'Accès à la base documentaire complète',
                    'Recherche juridique avancée',
                    'Génération de documents simples',
                    'Traduction dans 5 langues',
                    'Support de base',
                    '1 utilisateur'
                ]
            },
            professional: {
                price: '11880',
                equivalent: '990',
                features: [
                    'Tout ce qui est inclus dans Individuel',
                    'Génération de contracts avancés',
                    'Traduction dans 15+ langues',
                    'Support premium',
                    'Espace collaboratif sécurisé',
                    'Jusqu\'à 5 utilisateurs'
                ]
            },
            enterprise: {
                price: 'Personnalisée',
                equivalent: 'Contactez-nous pour une offre sur mesure',
                features: [
                    'Tout ce qui est inclus dans Professionnel',
                    'Intégration de vos données propriétaires',
                    'API pour intégration avec vos outils',
                    'Formation et support personnalisés',
                    'Tableaux de bord personnalisés',
                    'Utilisateurs à définir'
                ]
            }
        }
    };

    const handleToggleChange = () => {
        setIsAnnual(!isAnnual);
    };

    const handleSelectPlan = (planType) => {
        const subscriptionType = isAnnual ? 'annual' : 'monthly';
        
        // Check if this is the current plan and subscription type
        if (planType === currentPlanType && subscriptionType === currentSubscriptionType) {
            return; // No need to change, this is already the active plan
        }
        
        const selectedPlan = {
            planType,
            subscriptionType,
            price: pricingData[subscriptionType][planType].price,
            features: pricingData[subscriptionType][planType].features
        };

        // Determine if this is an upgrade or downgrade
        const planHierarchy = ['individual', 'professional', 'enterprise'];
        const currentPlanIndex = planHierarchy.indexOf(currentPlanType);
        const newPlanIndex = planHierarchy.indexOf(planType);
        const action = newPlanIndex > currentPlanIndex ? 'upgrade' : 'downgrade';

        // Create URL with query parameters
        const checkoutUrl = `/checkout?plan=${planType}&billing=${subscriptionType}&action=${action}`;

        // Navigate to checkout with both URL parameters and state
        navigate(checkoutUrl, {
            state: {
                selectedPlan,
                subscriptionType,
                action,
                fromPlan: currentPlanType
            }
        });
    };

    // Get current pricing based on toggle
    const currentPricing = isAnnual ? pricingData.annual : pricingData.monthly;

    // Function to determine if a plan is the current plan
    const isCurrentPlan = (planType) => {
        return !loading && planType === currentPlanType && 
               ((isAnnual && currentSubscriptionType === 'annual') || 
               (!isAnnual && currentSubscriptionType === 'monthly'));
    };

    // Function to get appropriate button text
    const getButtonText = (planType) => {
        if (isCurrentPlan(planType)) {
            return 'Plan actuel';
        }

        const planHierarchy = ['individual', 'professional', 'enterprise'];
        const currentPlanIndex = planHierarchy.indexOf(currentPlanType);
        const newPlanIndex = planHierarchy.indexOf(planType);

        if (newPlanIndex > currentPlanIndex) {
            return 'Mettre à niveau';
        } else if (newPlanIndex < currentPlanIndex) {
            return 'Rétrograder';
        } else {
            // Same plan level but different billing cycle
            return 'Changer de cycle';
        }
    };

    // Function to determine if a plan should be highlighted as current
    const getPlanCardClassName = (planType) => {
        let className = 'pricing-card';
        
        if (planType === 'professional') {
            className += ' popular';
        }
        
        if (isCurrentPlan(planType)) {
            className += ' current-plan';
        }
        
        return className;
    };

    return (
        <section id="pricing" className="pricing section-padding">
            <div className="container">
                <h2 className="section-title">Tarifs transparents, valeur maximale</h2>
                <p className="section-subtitle">
                    {loading 
                        ? 'Chargement de votre abonnement...'
                        : `Votre plan actuel: ${currentPlanType.charAt(0).toUpperCase() + currentPlanType.slice(1)} (${currentSubscriptionType === 'annual' ? 'Annuel' : 'Mensuel'})`
                    }
                </p>

                <div className="pricing-toggle">
                    <span className="toggle-label">Mensuel</span>
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            id="pricing-toggle"
                            checked={isAnnual}
                            onChange={handleToggleChange}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                    <span className="toggle-label">Annuel</span>
                    <span className="discount-label">-20%</span>
                </div>

                <div className="pricing-grid">
                    {/* Individual Plan */}
                    <div className={getPlanCardClassName('individual')}>
                        {isCurrentPlan('individual') && <div className="current-plan-badge">Plan actuel</div>}
                        <div className="pricing-header">
                            <h3 className="pricing-title">Individuel</h3>
                            <p className="pricing-subtitle">Pour les avocats indépendants et les petits cabinets</p>
                            <div className="pricing-price-container">
                                <div className="pricing-price">
                                    {isAnnual ? pricingData.annual.individual.equivalent : pricingData.monthly.individual.price} MAD
                                </div>
                                <div className="pricing-period">/mois</div>
                            </div>
                            <div className="pricing-monthly">
                                Soit {isAnnual 
                                    ? `${pricingData.annual.individual.price} MAD par an (économisez 20%)`
                                    : `${pricingData.monthly.individual.equivalent}`}
                            </div>
                        </div>
                        <ul className="pricing-features">
                            {currentPricing.individual.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                        <div className="pricing-footer">
                            <button
                                className={`btn ${isCurrentPlan('individual') ? 'btn-disabled' : ''}`}
                                onClick={() => handleSelectPlan('individual')}
                                disabled={isCurrentPlan('individual')}
                            >
                                {getButtonText('individual')}
                            </button>
                        </div>
                    </div>

                    {/* Professional Plan */}
                    <div className={getPlanCardClassName('professional')}>
                        {isCurrentPlan('professional') && <div className="current-plan-badge">Plan actuel</div>}
                        <div className="pricing-header">
                            <h3 className="pricing-title">Professionnel</h3>
                            <p className="pricing-subtitle">Pour les cabinets et les services juridiques d'entreprise</p>
                            <div className="pricing-price-container">
                                <div className="pricing-price">
                                    {isAnnual ? pricingData.annual.professional.equivalent : pricingData.monthly.professional.price} MAD
                                </div>
                                <div className="pricing-period">/mois</div>
                            </div>
                            <div className="pricing-monthly">
                                {isAnnual 
                                    ? `Soit ${pricingData.annual.professional.price} MAD par an (économisez 20%)`
                                    : `${pricingData.monthly.professional.equivalent}`}
                            </div>
                        </div>
                        <ul className="pricing-features">
                            {currentPricing.professional.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                        <div className="pricing-footer">
                            <button
                                className={`btn ${isCurrentPlan('professional') ? 'btn-disabled' : ''}`}
                                onClick={() => handleSelectPlan('professional')}
                                disabled={isCurrentPlan('professional')}
                            >
                                {getButtonText('professional')}
                            </button>
                        </div>
                    </div>

                    {/* Enterprise Plan */}
                    <div className={getPlanCardClassName('enterprise')}>
                        {isCurrentPlan('enterprise') && <div className="current-plan-badge">Plan actuel</div>}
                        <div className="pricing-header">
                            <h3 className="pricing-title">Entreprise</h3>
                            <p className="pricing-subtitle">Pour les grandes organisations et institutions</p>
                            <div className="pricing-price-container">
                                <div className="pricing-price">{currentPricing.enterprise.price}</div>
                            </div>
                            <div className="pricing-monthly">{currentPricing.enterprise.equivalent}</div>
                        </div>
                        <ul className="pricing-features">
                            {currentPricing.enterprise.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                        <div className="pricing-footer">
                            <button
                                className={`btn ${isCurrentPlan('enterprise') ? 'btn-disabled' : ''}`}
                                onClick={() => handleSelectPlan('enterprise')}
                                disabled={isCurrentPlan('enterprise')}
                            >
                                {getButtonText('enterprise')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricingScreen;