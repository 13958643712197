import React from 'react';
import { getCitationSectionTitle, getDocTypeColor, isMobile } from '../../../utils/utils';
import { Chip, Tooltip, Box, Typography, Paper } from '@mui/material';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLoadingDocument,
  setIsModalMobileVisible,
  setSelectedCitation,
  setDocument,
  setDocumentSourceUrl
} from '../../../redux/actions/documentAction';
import { backendClient } from '../../../api/backend';
import { useSnackbar } from 'notistack';

// Import the context/hook or create a dummy if not available
let usePdfFocus;
try {
  const pdfModule = require('../../../components/pdf-viewer/pdfContext');
  usePdfFocus = pdfModule.usePdfFocus;
} catch (error) {
  // Create a mock hook that returns an object with expected methods
  usePdfFocus = () => ({
    setPdfFocusState: () => console.log('Mock setPdfFocusState called')
  });
}


const CitationDisplay = ({ citation }) => {
  const { setPdfFocusState } = usePdfFocus();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const dispatch = useDispatch();
  const selectedCitation = useSelector(state => state.document.selectedCitation);
  const document = useSelector(state => state.document.document);
  const { enqueueSnackbar } = useSnackbar();

  const handleCitationClick = async (document_id, page_number) => {
    console.log('Clicked on citation', citation);
    dispatch(setSelectedCitation(citation));

    if (selectedCitation?.metadata?.document_id !== document_id) {
      if (document_id !== document?.id) {
        const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
        const blobName = citation?.metadata?.file_name;
        try {
          dispatch(setLoadingDocument(true));
          const data = await backendClient.getDocumentSas(
            containerName,
            blobName
          );
          const response = await fetch(data.blob_url_with_sas);
          const url = response.url;

          dispatch(
            setDocument({
              id: citation?.metadata?.document_id,
              url: url,
              language: citation?.metadata?.language
            })
          );
          dispatch(setDocumentSourceUrl(url));
        } catch (error) {
          console.error('Error fetching or processing the document:', error);
          dispatch(setLoadingDocument(false));
          enqueueSnackbar(t('document_fetch_error'), {
            variant: 'error'
          });
        }
      }

      // Use our local mock function instead of the hook function
      setPdfFocusState({
        citation: citation,
        document_id: citation?.metadata?.document_id,
        page_number: citation?.metadata?.page_number
      });

      dispatch(setLoadingDocument(false));
    } else {
      // Use our local mock function instead of the hook function
      setPdfFocusState({
        citation: citation,
        document_id: citation?.metadata?.document_id,
        page_number: citation?.metadata?.page_number
      });

      dispatch(setLoadingDocument(false));
    }

    if (isMobile()) {
      dispatch(setIsModalMobileVisible(true));
    }

    ReactGA.event({
      category: 'Citations',
      action: 'Clicked Citation',
      label: `Document ID: ${citation.metadata.document_id}, Section Title: ${citation.metadata.section_title}`
    });
  };


  const isSelected = selectedCitation?.page_content === citation?.page_content;

  return (
    <Paper
      elevation={0}
      sx={{
        mx: 0.5,
        mb: 1.5,
        minHeight: '25px',
        minWidth: '160px',
        width: '100%',
        cursor: 'pointer',
        border: isSelected ? '1px solid #4caf50' : '1px solid #e0e0e0',
        borderLeftColor: isSelected ? '#4caf50' : '#dddddd',
        borderLeftWidth: isSelected ? '1px' : '4px',
        backgroundColor: '#f9f9f9',
        p: 1,
        borderRadius: 1,
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: '#f0f0f0',
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
          position: 'relative',
          zIndex: 2
        }
      }}
      onClick={async () => {
        await handleCitationClick(
          citation?.metadata?.document_id,
          citation?.metadata?.page_number
        );
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Document Title */}
        <Tooltip title={citation?.metadata?.long_title} arrow>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              lineHeight: 1.3,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              color: '#333'
            }}
          >
            {citation?.metadata?.long_title}
          </Typography>
        </Tooltip>

        {/* Section title and document type */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 0.5
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '60%'
            }}
          >
            {getCitationSectionTitle(citation?.metadata?.section_title)}
          </Typography>

          <Chip
            label={t(citation?.metadata?.doc_type)}
            variant="outlined"
            size="small"
            sx={{
              height: 20,
              fontSize: '0.65rem',
              color: getDocTypeColor(citation?.metadata?.doc_type),
              borderColor: getDocTypeColor(citation?.metadata?.doc_type),
              fontWeight: 500,
              '& .MuiChip-label': {
                px: 1
              }
            }}
          />
        </Box>
      </Box>

      <Tooltip title={citation?.page_content} arrow>
        <Typography
          variant="caption"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '10px',
            fontWeight: 300,
            lineHeight: 1.2,
            mt: 0.5,
            color: 'text.secondary'
          }}
        >
          {citation?.page_content}
        </Typography>
      </Tooltip>
    </Paper>
  );
};

export default CitationDisplay;