import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React from 'react';
import ReactDOM from 'react-dom';
import { IconButton, Tooltip } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

// Selection bubble plugin key
const TextSelectionBubblePluginKey = new PluginKey('textSelectionBubble');

class TextSelectionBubbleView {
  constructor(view, aiButtonRef) {
    this.view = view;
    this.aiButtonRef = aiButtonRef;
    
    // Get the editor DOM element
    this.editorDOM = view.dom;
    
    // Create our bubble container - Important: use position fixed for viewport positioning
    this.dom = document.createElement('div');
    this.dom.className = 'text-selection-bubble';
    this.dom.style.position = 'fixed'; // Changed from absolute to fixed
    this.dom.style.zIndex = 1000; // Increased z-index
    this.dom.style.background = 'white';
    this.dom.style.borderRadius = '4px';
    this.dom.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
    this.dom.style.padding = '4px';
    this.dom.style.display = 'none';
    this.dom.style.transition = 'opacity 0.2s';
    this.dom.style.opacity = '0';
    
    // Store current selection coordinates
    this.selectionCoords = null;
    
    // Render React component in the DOM node
    ReactDOM.render(
      <Tooltip title="AI Assistant (⌘K)">
        <IconButton
          size="small"
          color="primary"
          onClick={() => this.handleAiButtonClick()}
          sx={{ p: 0.5 }}
        >
          <AutoAwesomeIcon fontSize="small" />
        </IconButton>
      </Tooltip>,
      this.dom
    );
    
    // Important: Add to document body since we're using fixed positioning
    document.body.appendChild(this.dom);
    
    // Bind the update position method to this instance
    this.boundUpdatePosition = this.updatePosition.bind(this);
    
    // Add scroll listeners to various possible scrollable containers
    window.addEventListener('scroll', this.boundUpdatePosition, true); // Capture phase to catch all scrolls
    
    // Also update on resize
    window.addEventListener('resize', this.boundUpdatePosition);
    
    // Register globally
    window.textSelectionBubble = this;
  }
  
  updatePosition() {
    // Only update if we have selection coordinates and bubble is visible
    if (!this.selectionCoords || this.dom.style.display === 'none') return;
    
    // Get current selection
    const { from, to } = this.view.state.selection;
    if (from === to) return; // No selection
    
    try {
      // Get new coordinates based on current viewport position
      const start = this.view.coordsAtPos(from);
      const end = this.view.coordsAtPos(to);
      
      // Calculate position for bubble
      const left = Math.min(start.left, end.left);
      const right = Math.max(start.right, end.right);
      const bottom = Math.max(start.bottom, end.bottom);
      
      // Position is viewport-relative since we're using position:fixed
      this.selectionCoords = {
        left: left + (right - left) / 2,
        top: bottom + 8
      };
      
      // Update bubble position - these are viewport coordinates
      this.dom.style.left = `${this.selectionCoords.left - this.dom.offsetWidth / 2}px`;
      this.dom.style.top = `${this.selectionCoords.top}px`;
      
      // Make sure it's visible
      this.dom.style.opacity = '1';
    } catch (e) {
      console.error('Error updating bubble position:', e);
      this.hide();
    }
  }
  
  update(view, lastState) {
    this.view = view; // Ensure we always have the current view
    const { state } = view;
    const { selection } = state;
    
    // Check if there's any selection at all
    const { from, to } = selection;
    
    // Hide if selection is empty
    if (from === to) {
      this.hide();
      this.selectionCoords = null;
      return;
    }
    
    // Check if selection has changed
    const sameSelection = lastState && lastState.selection.eq(selection);
    
    // Update position if selection changed
    if (!sameSelection) {
      try {
        // Get coordinates for bubble placement
        const start = view.coordsAtPos(from);
        const end = view.coordsAtPos(to);
        
        // Calculate position for bubble (viewport coordinates)
        const left = Math.min(start.left, end.left);
        const right = Math.max(start.right, end.right);
        const bottom = Math.max(start.bottom, end.bottom);
        
        // Store these coordinates for positioning
        this.selectionCoords = {
          left: left + (right - left) / 2,
          top: bottom + 8
        };
        
        // Position bubble (viewport coordinates since we use position:fixed)
        this.dom.style.left = `${this.selectionCoords.left - this.dom.offsetWidth / 2}px`;
        this.dom.style.top = `${this.selectionCoords.top}px`;
        
        // Show bubble with animation
        this.dom.style.display = 'block';
        setTimeout(() => {
          this.dom.style.opacity = '1';
        }, 10);
      } catch (e) {
        console.error('Error updating bubble on selection change:', e);
        this.hide();
      }
    }
  }
  
  handleAiButtonClick() {
    // First, hide this bubble
    this.hide();
    
    // Then trigger AI button
    if (this.aiButtonRef && this.aiButtonRef.current && this.selectionCoords) {
      setTimeout(() => {
        if (typeof this.aiButtonRef.current.openAtSelectionPosition === 'function') {
          this.aiButtonRef.current.openAtSelectionPosition();
        } else {
          this.aiButtonRef.current.click();
        }
      }, 50);
    }
  }
  
  hide() {
    this.dom.style.opacity = '0';
    setTimeout(() => {
      this.dom.style.display = 'none';
    }, 200);
  }
  
  destroy() {
    // Remove all event listeners
    window.removeEventListener('scroll', this.boundUpdatePosition, true);
    window.removeEventListener('resize', this.boundUpdatePosition);
    
    // Clean up DOM
    if (this.dom) {
      ReactDOM.unmountComponentAtNode(this.dom);
      this.dom.remove();
      
      // Remove global reference
      if (window.textSelectionBubble === this) {
        delete window.textSelectionBubble;
      }
    }
  }
}

export const TextSelectionBubble = Extension.create({
  name: 'textSelectionBubble',
  
  addOptions() {
    return {
      aiButtonRef: null
    };
  },
  
  addProseMirrorPlugins() {
    const { aiButtonRef } = this.options;
    
    return [
      new Plugin({
        key: TextSelectionBubblePluginKey,
        view: (editorView) => {
          return new TextSelectionBubbleView(editorView, aiButtonRef);
        }
      })
    ];
  }
});

// Export a utility function to hide all text selection bubbles
export function hideTextSelectionBubbles() {
  if (window.textSelectionBubble) {
    window.textSelectionBubble.hide();
  }
}