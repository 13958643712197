import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
// import { useJoyride } from "../../../contexts/JoyrideContext";
import { checkAndMoveToStep } from "../../../utils/utils";
import { useSubscription } from "../../../contexts/SubscriptionProvider";

const languages = [
  { code: "en", name: "English", flag: "🇬🇧", countryCode: "gb" },
  { code: "fr", name: "Français", flag: "🇫🇷", countryCode: "fr" },
  { code: "ar", name: "العربية", flag: "🇸🇦", countryCode: "sa" },
  { code: "es", name: "Español", flag: "🇪🇸", countryCode: "es" },
  { code: "de", name: "Deutsch", flag: "🇩🇪", countryCode: "de" },
  { code: "zh", name: "中文", flag: "🇨🇳", countryCode: "cn" },
  { code: "ja", name: "日本語", flag: "🇯🇵", countryCode: "jp" },
  { code: "ko", name: "한국어", flag: "🇰🇷", countryCode: "kr" },
  { code: "ru", name: "Русский", flag: "🇷🇺", countryCode: "ru" },
  { code: "pt", name: "Português", flag: "🇵🇹", countryCode: "pt" },
  { code: "it", name: "Italiano", flag: "🇮🇹", countryCode: "it" },
  { code: "nl", name: "Nederlands", flag: "🇳🇱", countryCode: "nl" },
  { code: "pl", name: "Polski", flag: "🇵🇱", countryCode: "pl" },
  { code: "tr", name: "Türkçe", flag: "🇹🇷", countryCode: "tr" },
  { code: "hi", name: "हिन्दी", flag: "🇮🇳", countryCode: "in" },
];

const LanguageSelector = ({
  disabled = false,
  currentLang,
  onLanguageChange,
}) => {
  const { isLanguageAvailable, allowedLanguages } = useSubscription();

  // Filter languages based on subscription
  const availableLanguages = languages.filter(lang =>
    isLanguageAvailable(lang.code)
  );

  // const { startTour, isStepActive, setCurrentStepIndex, active } = useJoyride();

  const handleChange = (event) => {
    if (!disabled) {
      onLanguageChange(event.target.value);
    }
  };

  const currentLanguage =
    languages.find((lang) => lang.code === currentLang) || languages[0];


  // Show language selection is limited by subscription
  const isLimitedBySubscription = languages.length !== availableLanguages.length;


  const selectContent = (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <LanguageIcon
        sx={{
          fontSize: 20,
          opacity: disabled ? 0.1 : 1,
        }}
      />
      <Typography
        sx={{
          opacity: disabled ? 0.1 : 1,
          color: disabled ? "text.disabled" : "text.primary",
        }}
      >
        {currentLanguage.flag} {currentLanguage.name}
      </Typography>
    </Box>
  );

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: 200,
        opacity: disabled ? 0.1 : 1,
      }}
      className="htmlviewer-translate-select"
    >
      <div>
        <Select
          value={currentLang}
          onChange={handleChange}
          displayEmpty
          disabled={disabled}
          renderValue={() => selectContent}
          MenuProps={{
            disableScrollLock: true,
            disablePortal: true,
          }}
          sx={{
            zIndex: 10002,
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
            "&.Mui-disabled": {
              backgroundColor: "action.disabledBackground",
              cursor: "not-allowed",
            },
          }}
        >
          {availableLanguages.map((lang) => (
            <MenuItem
              key={lang.code}
              value={lang.code}
              dir={lang.code === "ar" ? "rtl" : "ltr"}
              disabled={disabled}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <Typography
                  fontSize="20px"
                  sx={{ opacity: disabled ? 0.5 : 1 }}
                >
                  {lang.flag}
                </Typography>
              </ListItemIcon>
              <ListItemText
                primary={lang.name}
                primaryTypographyProps={{
                  sx: {
                    fontFamily:
                      lang.code === "ar"
                        ? "Noto Sans Arabic"
                        : lang.code === "zh" ||
                          lang.code === "ja" ||
                          lang.code === "ko"
                          ? "Noto Sans CJK"
                          : "inherit",
                    opacity: disabled ? 0.5 : 1,
                  },
                }}
              />
            </MenuItem>
          ))}

          {isLimitedBySubscription && (
            <MenuItem disabled divider>
              <ListItemText
                primary="Upgrade your plan for more languages"
                primaryTypographyProps={{
                  sx: { fontSize: '0.8rem', color: 'text.secondary' }
                }}
              />
            </MenuItem>
          )}
        </Select>
      </div>
    </FormControl>
  );
};

export default LanguageSelector;