import React, { useState, useMemo, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    Box,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { isMobile } from '../../utils/utils';
import { backendClient } from '../../api/backend';
import countryList from 'react-select-country-list';
import { colors } from '../../utils/colors';

// Import subcomponents
import PlanSummary from './components/PlanSummary';
import BillingForm from './components/BillingForm';
import { pricingData } from './data/pricingData';

const CheckoutScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const isMobileView = isMobile();
    
    // Get user from Redux
    const user = useSelector(state => state.auth.user?.user);

    // Get the plan info from navigation state
    const [planInfo, setPlanInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const countries = useMemo(() => countryList().getData(), []);

    // State for promo code
    const [promoCode, setPromoCode] = useState(null);
    const [appliedDiscount, setAppliedDiscount] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);

    // Initialize form values
    const [formValues, setFormValues] = useState({
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        companyName: '',
        tvaNumber: '',
        ice: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: 'MA',
        zipCode: ''
    });

    // Initialize user_type to 'physique' (physical person) by default
    const [userType, setUserType] = useState('physique');
    const [acceptTerms, setAcceptTerms] = useState(false);

    // Pre-fill form with user data from Redux if available and determine user type
    useEffect(() => {
        if (user) {
            // Determine user type from organization data if available
            // If user is part of an organization, set type to 'moral', otherwise 'physique'
            // Could be adjusted based on actual data structure in your Redux store
            const type = user.user_type ? user.user_type : 'physique';
            setUserType(type);
            
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                firstName: user.first_name || user.firstName || '',
                lastName: user.last_name || user.lastName || '',
                email: user.email || '',
                phone: user.phone || '',
                // If moral/business, set company name from organization
                companyName: type === 'moral' ? (user.organization_name || '') : ''
            }));
        }
    }, [user]);

    useEffect(() => {
        // First try to get plan info from URL parameters
        const searchParams = new URLSearchParams(location.search);
        const planFromUrl = searchParams.get('plan');
        const billingFromUrl = searchParams.get('billing');
        const priceFromUrl = searchParams.get('price');

        // Support for deep linking from external URLs
        if (planFromUrl && billingFromUrl) {
            // Validate that the plan and billing type exist in pricingData
            const isValidPlan = pricingData?.[billingFromUrl]?.[planFromUrl] !== undefined;

            if (!isValidPlan) {
                enqueueSnackbar('Plan non valide. Redirection vers les forfaits.', { variant: "error" });
                navigate('/pricing');
                return;
            }

            // Get features from pricing data based on URL parameters
            const features = pricingData?.[billingFromUrl]?.[planFromUrl]?.features || [];

            // Get price from URL or pricing data
            const price = priceFromUrl || pricingData?.[billingFromUrl]?.[planFromUrl]?.price || 'Personnalisée';

            // Store original price for potential promo code calculations
            if (price !== 'Personnalisée') {
                setOriginalPrice(parseFloat(price.replace(',', '.')));
            }

            // Create plan info object from URL parameters
            const planInfoFromUrl = {
                planType: planFromUrl,
                subscriptionType: billingFromUrl,
                price: price,
                features: features,
                description: pricingData?.[billingFromUrl]?.[planFromUrl]?.description || ''
            };

            setPlanInfo(planInfoFromUrl);
        }
        // If URL parameters are not available, try to get from state
        else if (location.state && location.state.selectedPlan) {
            setPlanInfo(location.state.selectedPlan);

            // Store original price for promo code calculations
            if (location.state.selectedPlan.price !== 'Personnalisée') {
                setOriginalPrice(parseFloat(location.state.selectedPlan.price.replace(',', '.')));
            }
        }
        // If neither URL parameters nor state has plan info, redirect back to pricing
        else {
            enqueueSnackbar('Veuillez sélectionner un forfait avant de procéder au paiement.', { variant: "warning" });
            navigate('/pricing');
        }
    }, [location, navigate, enqueueSnackbar]);

    // Calculate pricing based on the selected plan and any applied promo code
    const getPriceInfo = () => {
        if (!planInfo) return { priceHT: 0, taxAmount: 0, priceTTC: 0, displayPeriod: '' };

        // Convert string price to number for calculations
        // Handle the case where enterprise price is "Personnalisée"
        let monthlyPriceTTC = 0;
        if (planInfo.price !== 'Personnalisée') {
            monthlyPriceTTC = parseFloat(planInfo.price.replace(',', '.'));
        }

        // Adjust the price based on subscription type
        // If yearly, multiply by 12 since pricingData prices are monthly
        let priceTTC = monthlyPriceTTC;
        if (planInfo.subscriptionType === 'annual') {
            priceTTC = monthlyPriceTTC * 12;
        }

        // Apply promo code discount if available
        if (promoCode && promoCode.valid && appliedDiscount > 0) {
            priceTTC = Math.max(0, priceTTC - appliedDiscount);
        }

        // Calculate price before tax (HT) - assuming 20% VAT
        const priceHT = priceTTC / 1.2;
        const taxAmount = priceTTC - priceHT;

        // Set display period based on subscription type
        const displayPeriod = planInfo.subscriptionType === 'annual' ? 'an' : 'mois';

        // Store the monthly price for reference
        const monthlyEquivalent = planInfo.subscriptionType === 'annual' ? monthlyPriceTTC : null;

        return {
            priceHT,
            taxAmount,
            priceTTC,
            displayPeriod,
            monthlyEquivalent: monthlyEquivalent && monthlyEquivalent !== 0 ? monthlyEquivalent.toFixed(2).replace('.', ',') : null,
            originalPrice: originalPrice,
            discount: appliedDiscount
        };
    };

    const priceInfo = getPriceInfo();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleUserTypeChange = (event) => {
        // Only allow changing user type if user is not logged in
        if (!user) {
            const type = event.target.value === 'moral' ? 'moral' : 'physique';
            setUserType(type);
        }
    };

    const validateFields = () => {
        // Define required fields based on user type and whether user exists in Redux
        const requiredFields = {
            physique: user 
                ? ['addressLine1', 'city', 'country', 'zipCode']
                : ['lastName', 'firstName', 'phone', 'email', 'addressLine1', 'city', 'country', 'zipCode'],
            moral: user
                ? ['companyName', 'addressLine1', 'city', 'country', 'zipCode', 'ice']
                : ['companyName', 'lastName', 'firstName', 'email', 'addressLine1', 'city', 'country', 'zipCode', 'ice']
        };

        const fieldsToValidate = requiredFields[userType];
        for (const field of fieldsToValidate) {
            if (formValues[field].trim() === '') {
                return false;
            }
        }

        return true;
    };

    // Handle promo code validation
    const handlePromoCodeValidate = async (code) => {
        if (!code || code.trim() === '') {
            return { valid: false, message: 'Veuillez saisir un code promo valide' };
        }

        try {
            // Calculate current price based on plan
            let currentPrice = 0;
            if (planInfo.price !== 'Personnalisée') {
                currentPrice = parseFloat(planInfo.price.replace(',', '.'));
                if (planInfo.subscriptionType === 'annual') {
                    currentPrice *= 12;
                }
            } else {
                return { valid: false, message: 'Les codes promo ne sont pas applicables aux forfaits personnalisés' };
            }

            // Call the API to validate the promo code
            const data = await backendClient.validatePromoCode({
                code: code,
                plan_type: planInfo.planType,
                subscription_type: planInfo.subscriptionType,
                amount: currentPrice
            });

            if (data.valid) {
                // Update state with the validated promo code
                setPromoCode(data);
                setAppliedDiscount(data.discount_amount || 0);

                return {
                    valid: true,
                    message: data.message || 'Code promo appliqué avec succès !',
                    discount_amount: data.discount_amount
                };
            } else {
                return {
                    valid: false,
                    message: data.message || 'Code promo invalide'
                };
            }
        } catch (error) {
            console.error("Error validating promo code:", error);
            return {
                valid: false,
                message: 'Erreur lors de la validation du code promo. Veuillez réessayer.'
            };
        }
    };

    const handleProcessPayment = async (event) => {
        event.preventDefault();

        if (!validateFields()) {
            enqueueSnackbar('Veuillez remplir tous les champs obligatoires', { variant: "error" });
            return;
        }

        if (!acceptTerms) {
            enqueueSnackbar('Vous devez accepter les conditions générales de vente pour continuer', { variant: "error" });
            return;
        }

        try {
            setIsLoading(true);

            // Prepare payment data
            const paymentData = {
                user_type: userType, // This is already correctly set to 'physique' or 'moral'
                subscription_type: planInfo?.subscriptionType || 'monthly', // annual, monthly
                plan_type: planInfo?.planType || 'individual',
                ...formValues,
            };

            // Add promo code if one was successfully validated
            if (promoCode && promoCode.valid) {
                // paymentData.promo_code = promoCode.code;
                paymentData.promo_code_id = promoCode.code_id;
            }

            // Use the initPayment method
            const htmlContent = await backendClient.initPayment(paymentData);

            setIsLoading(false);
            navigate('/payment', { state: { htmlContent } });
        } catch (error) {
            setIsLoading(false);
            console.error("handleProcessPayment error:", error);
            enqueueSnackbar('Une erreur est survenue lors du traitement du paiement. Veuillez réessayer plus tard.', { variant: "error" });
        }
    };

    if (!planInfo) {
        return (
            <Container
                component="main"
                maxWidth="lg"
                sx={{
                    backgroundColor: colors.bg_color,
                    padding: 4,
                    textAlign: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box>
                    <Typography variant="h5" fontWeight="bold" color="#333">Chargement des informations...</Typography>
                    <Typography variant="body1" color="#666" mt={2}>Veuillez patienter pendant le chargement de votre forfait</Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container
            component="main"
            maxWidth="lg"
            sx={{
                backgroundColor: colors.bg_color,
                padding: isMobileView ? 0 : 4,
                minHeight: '100vh',
                py: isMobileView ? 2 : 3,
            }}
        >
            <Grid container spacing={2}>
                {/* Plan Summary Section */}
                <Grid item xs={12} md={6}>
                    <PlanSummary
                        planInfo={planInfo}
                        priceInfo={priceInfo}
                        isMobileView={isMobileView}
                        handlePromoCodeValidate={handlePromoCodeValidate}
                    />
                </Grid>

                {/* Billing Form Section */}
                <Grid item xs={12} md={6}>
                    <BillingForm
                        userType={userType}
                        formValues={formValues}
                        acceptTerms={acceptTerms}
                        isLoading={isLoading}
                        countries={countries}
                        planInfo={planInfo}
                        handleUserTypeChange={handleUserTypeChange}
                        handleInputChange={handleInputChange}
                        setFormValues={setFormValues}
                        setAcceptTerms={setAcceptTerms}
                        handleProcessPayment={handleProcessPayment}
                        navigate={navigate}
                        isMobileView={isMobileView}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default CheckoutScreen;