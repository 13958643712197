import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { Error as ErrorIcon } from "@mui/icons-material";

const ErrorMessageDisplay = () => {
  return (
    <Alert
      severity="error"
      variant="outlined"
      icon={<ErrorIcon />}
      sx={{
        mt: 2,
        display: 'flex',
        alignItems: 'center',
        borderColor: 'rgba(211, 47, 47, 0.3)',
        backgroundColor: 'rgba(211, 47, 47, 0.04)',
        borderRadius: 2,
        '& .MuiAlert-icon': {
          color: '#d32f2f',
          alignItems: 'center',
        }
      }}
    >
      <Typography variant="body2" sx={{ color: 'rgba(211, 47, 47, 0.9)' }}>
        Error: unable to load chat response
      </Typography>
    </Alert>
  );
};

export default ErrorMessageDisplay;