import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  CircularProgress,
  Chip
} from "@mui/material";


import { Info } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useDispatch, useSelector } from "react-redux";
import { getClauses } from "../../../../redux/actions/contractAction";
import EditorContext from "../../../../contexts/EditorContext";
import { useTranslation } from "react-i18next";
import { formatHtmlForDisplay } from "../../../../utils/utils";

const Explanation = ({ contractId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const clauses = useSelector((state) => state.contract.clauses || []);
  const loadingClause = useSelector((state) => state.contract.loadingClause);
  const hasClauses = clauses && clauses.length > 0;
  const [expandedClauseIndex, setExpandedClauseIndex] = useState(null);
  const isRTL = i18n.language === 'ar';
  // No highlight status

  // Get access to the editor through EditorContext
  const editorRef = useContext(EditorContext);

  // Define clause highlight color
  const clauseHighlightColor = "#1976d2"; // Using a blue color for clauses

  const handleExtractClauses = () => {
    if (contractId) {
      // Clear any existing highlights when starting new extraction
      clearHighlights();
      dispatch(getClauses(contractId));
    }
  };

  // Clear highlights helper function
  const clearHighlights = () => {
    const editor = editorRef?.current?.getEditor();
    if (editor && editor.commands && typeof editor.commands.clearRiskHighlight === 'function') {
      editor.commands.clearRiskHighlight();
    }
  };

  // Find the first instance of a highlighted element and scroll to it
  const scrollToHighlight = () => {
    setTimeout(() => {
      const highlightedElements = document.querySelectorAll('.risk-highlight');
      if (highlightedElements.length > 0) {
        highlightedElements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 300); // Wait for highlights to be applied
  };

  // Extract paragraphs from HTML for more reliable matching
  const extractParagraphs = (html) => {
    if (!html) return [];

    // Replace <br> tags with paragraph markers
    let text = html.replace(/<br\s*\/?>/gi, '|PARAGRAPH_BREAK|');

    // Remove other HTML tags
    text = text.replace(/<[^>]*>/g, '');

    // Split by paragraph markers
    const paragraphs = text.split('|PARAGRAPH_BREAK|');

    // Clean each paragraph and filter out empty ones
    return paragraphs
      .map(p => p.replace(/\s+/g, ' ').trim())
      .filter(p => p.length > 0);
  };



  // Function to handle accordion expansion and highlighting
  const handleAccordionChange = (clause, index) => (event, isExpanded) => {
    // Set expanded state
    setExpandedClauseIndex(isExpanded ? index : null);

    // Get the editor instance
    const editor = editorRef?.current?.getEditor();

    if (editor) {
      // Clear existing highlights
      clearHighlights();

      // If we're expanding this clause, apply the highlight
      if (isExpanded && clause.text) {
        try {
          console.log("Attempting to highlight clause text:", clause.text.substring(0, 50) + "...");

          // Apply the highlight with the blue color for clauses
          editor.commands.highlightRiskText(clause.text, clauseHighlightColor);

          // Look for highlighted elements and scroll to the first one
          scrollToHighlight();
        } catch (err) {
          console.error("Error applying highlight:", err);
        }
      }
    }
  };

  // Clear highlights when component unmounts
  useEffect(() => {
    return () => {
      clearHighlights();
    };
  }, []);



  return (
    <Box
      sx={{
        width: "100%",
        height: "100%", // Full height
        overflowY: "auto",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        direction: isRTL ? 'rtl' : 'ltr',
        textAlign: isRTL ? 'right' : 'left'
      }}
    >
      {/* Title and subtitle in a Box */}
      <Box sx={{ mb: 1 }}>
        <Chip
          label={t("riskAnalysis.chip")}
          size="small"
          sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            fontWeight: 500,
            fontSize: '0.75rem',
            mb: 0.5
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#212121", mb: 0 }}>
          {t("clause_extract")}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575" }}>
          {t("clause_extract_subtitle")}
        </Typography>
      </Box>

      <Button
        variant="contained"
        startIcon={hasClauses ? <RefreshIcon /> : <Info />}
        onClick={handleExtractClauses}
        disabled={loadingClause}
        sx={{
          background: "#1a1a1a",
          color: "#fff",
          borderRadius: 2,
          mb: 2,
          width: "100%",
          '&:hover': {
            background: "#333333"
          }
        }}
      >
        {clauses.length > 0 ? t("reextract_clauses") : t("extract_clauses")}
      </Button>

      {loadingClause && (
        <Box sx={{ textAlign: 'center', py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          <Typography variant="body2">{t("analyzingDocument")}</Typography>
        </Box>
      )}



      {clauses.length > 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingX: 0.5, }}>
          {clauses.map((clause, index) => (
            <Accordion
              key={index}
              disableGutters
              expanded={expandedClauseIndex === index}
              onChange={handleAccordionChange(clause, index)}
              sx={{
                width: "100%",
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
                transition: "box-shadow 0.2s ease-in-out",
                '&:before': {
                  display: 'none'
                },
                '&:hover': {
                  boxShadow: "0 4px 12px rgba(0,0,0,0.12)"
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ px: 2 }}
              >
                <Typography sx={{ flex: 1, fontWeight: "600" }}>{clause.title}</Typography>
                <Tooltip title={clause.description} arrow placement="top">
                  <IconButton size="small" sx={{ ml: 1, color: "text.secondary" }}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0, backgroundColor: "rgba(0,0,0,0.01)" }}>
                <Typography variant="body2" sx={{ lineHeight: 1.6, textAlign: "left" }}>
                  {formatHtmlForDisplay(clause.text)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ) : !loadingClause && (
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 6,
          color: "text.secondary"
        }}>
          <DescriptionIcon sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
          <Typography variant="body1">{t("click_button_extract_clauses")}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Explanation;