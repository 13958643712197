import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, TextField, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Icône de retour

const AddSignature = ({ onAdd, onCancel }) => {
  const [email, setEmail] = useState(""); // État pour le champ email
  const [firstName, setFirstName] = useState(""); // État pour le prénom
  const [lastName, setLastName] = useState(""); // État pour le nom
  const [error, setError] = useState(""); // État pour l'erreur

  // Fonction pour valider l'email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Fonction pour ajouter le signataire
  const handleAdd = () => {
    if (!validateEmail(email)) {
      setError("Adresse email invalide"); // Affiche le message d'erreur
      return;
    }
    setError(""); // Efface l'erreur si l'email est valide
    onAdd(email, firstName, lastName); // Ajoute l'email et les noms
    setEmail(""); // Réinitialise les champs après l'ajout
    setFirstName("");
    setLastName("");
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: 2,
        background: "#fff",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 0,
        margin: 0,
      }}
    >
      {/* Titre de la section avec le bouton de retour */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          mb: 2,
        }}
      >
        <IconButton onClick={onCancel} sx={{ color: "#1a1a1a" }}>
          <ArrowBackIcon />
        </IconButton>
        <h3>Information du signataire</h3>
      </Box>

      {/* Champ d'entrée pour l'email */}
      <TextField
        fullWidth
        label="Email du signataire"
        placeholder="Entrez l'email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!error} // Active le style d'erreur si error n'est pas vide
        helperText={error} // Affiche le message d'erreur
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "12px",
            },
          },
        }}
      />

      {/* Champ d'entrée pour le prénom */}
      <TextField
        fullWidth
        label="Prénom du signataire"
        placeholder="Entrez le prénom"
        variant="outlined"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "12px",
            },
          },
        }}
      />

      {/* Champ d'entrée pour le nom */}
      <TextField
        fullWidth
        label="Nom du signataire"
        placeholder="Entrez le nom"
        variant="outlined"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "12px",
            },
          },
        }}
      />

      {/* Bouton pour ajouter le signataire */}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAdd} // Utilise la fonction avec validation
        sx={{
          background: "#1a1a1a",
          color: "#fff",
          borderRadius: 2,
          mt: 2,
          width: "100%",
        }}
      >
        Ajouter
      </Button>
    </Box>
  );
};

export default AddSignature;