// src/redux/reducers/contractReducer.js
import {
    // Existing contract action types
    UPDATE_CONTRACT_CONTENT,
    SET_CONTRACT_DATA,
    SET_CONTRACT_LOADING,
    SET_CONTRACT_ERROR,
    SET_HIGHLIGHT_ERROR,
    RESET_CONTRACT,
    FETCH_CONTRACT_HISTORY_REQUEST,
    FETCH_CONTRACT_HISTORY_SUCCESS,
    FETCH_CONTRACT_HISTORY_FAILURE,
    ADD_HISTORY_ENTRY,
    FETCH_CONTRACT_REQUEST,
    FETCH_CONTRACT_SUCCESS,
    FETCH_CONTRACT_FAILURE,
    SAVE_CONTRACT_REQUEST,
    SAVE_CONTRACT_SUCCESS,
    SAVE_CONTRACT_FAILURE,
    SHARE_CONTRACT_REQUEST,
    SHARE_CONTRACT_SUCCESS,
    SHARE_CONTRACT_FAILURE,
    DELETE_CONTRACTS_SUCCESS,
    DELETE_CONTRACTS_REQUEST,
    DELETE_CONTRACTS_FAILURE,
    FETCH_CONTRACTS_REQUEST,
    FETCH_CONTRACTS_SUCCESS,
    FETCH_CONTRACTS_FAILURE,
    SEARCH_CONTRACTS_REQUEST,
    SEARCH_CONTRACTS_SUCCESS,
    SEARCH_CONTRACTS_FAILURE,
    CREATE_CONTRACT_REQUEST,
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILURE,

    // Chat message action types
    CONTRACT_MESSAGE_CLEAR,
    CONTRACT_MESSAGE_SET,
    CONTRACT_MESSAGE_ADD_USER,
    CONTRACT_MESSAGE_STREAM_UPDATE,
    CONTRACT_MESSAGE_STREAM_DONE,
    CONTRACT_MESSAGE_ERROR,

    // Conversation management types
    CLEAR_CHAT_REQUEST,
    CLEAR_CHAT_SUCCESS,
    CLEAR_CHAT_FAILURE,
    CREATE_CONTRACT_CONVERSATION_REQUEST,
    CREATE_CONTRACT_CONVERSATION_SUCCESS,
    CREATE_CONTRACT_CONVERSATION_FAILURE,
    RISK_ANALYSIS_RESET,
    RISK_ANALYSIS_ADD,
    RISK_ANALYSIS_ERROR,
    RISK_ANALYSIS_COMPLETE,
    RISK_ANALYSIS_REQUEST,
    SET_CURRENT_CONVERSATION_ID,
    UPDATE_CONTRACT_STATUS,
    UPDATE_CONTRACT_STATUS_SUCCESS,
    UPDATE_CONTRACT_STATUS_FAILURE,
    CLAUSES_REQUEST,
    CLAUSES_RESET,
    CLAUSES_ADD,
    CLAUSES_ERROR,
    CLAUSES_COMPLETE,
    SUGGESTED_CLAUSES_REQUEST,
    SUGGESTED_CLAUSES_RESET,
    SUGGESTED_CLAUSES_ADD,
    SUGGESTED_CLAUSES_ERROR,
    SUGGESTED_CLAUSES_COMPLETE,

    FILTER_CONTRACTS_REQUEST,
    FILTER_CONTRACTS_SUCCESS,
    FILTER_CONTRACTS_FAILURE,
    UPDATE_CONTRACT_OPTIMISTIC,

} from '../actions/types';

const initialState = {
    // Existing contract state
    contracts: [],
    contractsCache: {},
    contract: null,
    contractContent: null,
    loading: false,
    error: null,
    highlightError: null,
    saveLoading: false,
    saveError: null,
    history: [],
    historyLoading: false,
    historyError: null,
    loadingCreate: false,
    errorCreate: null,
    loadingSearch: false,
    errorSearch: null,
    loadingShare: false,
    errorShare: null,
    loadingDelete: false,
    errorDelete: null,
    totalContracts: 0,

    // Chat state
    messages: [],
    chatError: null,
    currentConversationId: null,
    creatingConversation: false,
    clearingChat: false,

    // risks
    risks: [],
    loadingRisk: false,
    riskAnalysisError: null,

    clauses: [],
    loadingClause: false,
    clausesError: null,

    suggestedClauses: [],
    loadingSuggestedClause: false,
    suggestedClausesError: null,

    loadingStatus: false,

    loadingFilter: false,
    errorFilter: null,
    filteredContracts: [],

};

const contractReducer = (state = initialState, action) => {
    switch (action.type) {
        // Existing contract cases remain unchanged
        case UPDATE_CONTRACT_CONTENT:
            return {
                ...state,
                contractContent: action.payload,
                contract: {
                    ...state.contract,
                    content: action.payload
                }
            };

        case SET_CONTRACT_DATA:
            return {
                ...state,
                contract: action.payload,
                contractContent: action.payload?.content || null
            };

        case SET_CONTRACT_LOADING:
            return {
                ...state,
                loading: action.payload
            };

        case SET_CONTRACT_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case SET_HIGHLIGHT_ERROR:
            return {
                ...state,
                highlightError: action.payload
            };

        case RESET_CONTRACT:
            return initialState;

        case FETCH_CONTRACT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: action.payload,
                contractContent: action.payload.content,
                loading: false
            };

        case FETCH_CONTRACT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case SAVE_CONTRACT_REQUEST:
            return {
                ...state,
                saveLoading: true,
                saveError: null
            };

        case SAVE_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: action.payload,
                saveLoading: false
            };

        case SAVE_CONTRACT_FAILURE:
            return {
                ...state,
                saveLoading: false,
                saveError: action.payload
            };

        case FETCH_CONTRACT_HISTORY_REQUEST:
            return {
                ...state,
                historyLoading: true,
                historyError: null
            };

        case FETCH_CONTRACT_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload,
                historyLoading: false
            };

        case FETCH_CONTRACT_HISTORY_FAILURE:
            return {
                ...state,
                historyLoading: false,
                historyError: action.payload
            };

        case ADD_HISTORY_ENTRY:
            const updatedHistory = [...state.history, action.payload]
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

            return {
                ...state,
                history: updatedHistory
            };

        case FETCH_CONTRACTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CONTRACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                contracts: action.payload,
                error: null,
                contractsCache: {
                    ...state.contractsCache,
                    [`${action.meta.offset}_${action.meta.limit}`]: action.payload
                }
            };

        case FETCH_CONTRACTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CREATE_CONTRACT_REQUEST:
            return {
                ...state,
                loadingCreate: true,
                errorCreate: null
            };

        case CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                loadingCreate: false,
                contracts: [action.payload, ...state.contracts],
                errorCreate: null
            };

        case CREATE_CONTRACT_FAILURE:
            return {
                ...state,
                loadingCreate: false,
                errorCreate: action.payload
            };

        case SEARCH_CONTRACTS_REQUEST:
            return {
                ...state,
                loadingSearch: true,
                errorSearch: null
            };

        case SEARCH_CONTRACTS_SUCCESS:
            return {
                ...state,
                loadingSearch: false,
                contracts: action.payload,
                errorSearch: null
            };

        case SEARCH_CONTRACTS_FAILURE:
            return {
                ...state,
                loadingSearch: false,
                errorSearch: action.payload
            };

        case SHARE_CONTRACT_REQUEST:
            return {
                ...state,
                loadingShare: true,
                errorShare: null
            };

        // In src/redux/reducers/contractReducer.js

        case SHARE_CONTRACT_SUCCESS:
            const { contractId, collaborators } = action.payload;

            // Update the contract in the contracts array
            const updatedContracts = state.contracts.map(contract => {
                if (contract.id === contractId) {
                    // Combine existing collaborators with new ones
                    const existingCollaborators = contract.collaborators || [];
                    const newCollaboratorIds = collaborators.map(c => c.id);

                    // Filter out duplicates (collaborators already in the list)
                    const uniqueExistingCollaborators = existingCollaborators.filter(
                        ec => !newCollaboratorIds.includes(ec.id)
                    );

                    return {
                        ...contract,
                        collaborators: [...uniqueExistingCollaborators, ...collaborators]
                    };
                }
                return contract;
            });

            // Update the filtered contracts array with the same logic
            const updatedFilteredContracts = state.filteredContracts.map(contract => {
                if (contract.id === contractId) {
                    const existingCollaborators = contract.collaborators || [];
                    const newCollaboratorIds = collaborators.map(c => c.id);

                    const uniqueExistingCollaborators = existingCollaborators.filter(
                        ec => !newCollaboratorIds.includes(ec.id)
                    );

                    return {
                        ...contract,
                        collaborators: [...uniqueExistingCollaborators, ...collaborators]
                    };
                }
                return contract;
            });

            // Update the currently selected contract if it matches
            const updatedCurrentContract = state.contract && state.contract.id === contractId
                ? {
                    ...state.contract,
                    collaborators: [
                        ...(state.contract.collaborators || []).filter(
                            ec => !collaborators.map(c => c.id).includes(ec.id)
                        ),
                        ...collaborators
                    ]
                }
                : state.contract;

            return {
                ...state,
                loadingShare: false,
                contracts: updatedContracts,
                filteredContracts: updatedFilteredContracts,
                contract: updatedCurrentContract,
                errorShare: null
            };

        case SHARE_CONTRACT_FAILURE:
            return {
                ...state,
                loadingShare: false,
                errorShare: action.payload
            };

        case DELETE_CONTRACTS_REQUEST:
            return {
                ...state,
                loadingDelete: true,
                errorDelete: null
            };

        case DELETE_CONTRACTS_SUCCESS:
            return {
                ...state,
                loadingDelete: false,
                contractsCache: {},
                contracts: state.contracts.filter(contract => !action.payload.contractIds.includes(contract.id)),
                errorDelete: null
            };

        case DELETE_CONTRACTS_FAILURE:
            return {
                ...state,
                loadingDelete: false,
                errorDelete: action.payload
            };

        // Chat message cases
        case CONTRACT_MESSAGE_CLEAR:
            return {
                ...state,
                messages: [],
                chatError: null
            };

        case CONTRACT_MESSAGE_SET:
            return {
                ...state,
                messages: (action.payload || []).map(msg => ({
                    text: msg.content,
                    sender: msg.role === "assistant" ? "bot" : "user"
                })),
                chatError: null
            };

        case CONTRACT_MESSAGE_ADD_USER: {
            const newMessage = {
                id: crypto.randomUUID(),
                text: action.payload.content,
                sender: "user",
                status: action.payload.status,
                created_at: action.payload.created_at
            };

            return {
                ...state,
                messages: [...state.messages, newMessage],
                chatError: null
            };
        }

        case CONTRACT_MESSAGE_STREAM_UPDATE: {
            const incomingMsg = action.payload;

            if (!incomingMsg || typeof incomingMsg !== "object") {
                return {
                    ...state,
                    chatError: "Invalid message format received"
                };
            }

            const updatedMessages = [...state.messages];

            // Find the last bot message
            let lastAssistantIndex = -1;
            for (let i = updatedMessages.length - 1; i >= 0; i--) {
                if (updatedMessages[i].sender === "bot") {
                    lastAssistantIndex = i;
                    break;
                }
            }

            // Determine if we need to update the existing message or append a new one
            if (lastAssistantIndex !== -1 && updatedMessages[lastAssistantIndex].id === incomingMsg.id) {
                updatedMessages[lastAssistantIndex] = {
                    ...updatedMessages[lastAssistantIndex],
                    text: incomingMsg.content,
                    status: incomingMsg.status
                };
            } else {
                updatedMessages.push({
                    id: incomingMsg.id,
                    text: incomingMsg.content,
                    sender: "bot",
                    status: incomingMsg.status,
                    created_at: incomingMsg.created_at
                });
            }

            return {
                ...state,
                messages: updatedMessages,
                chatError: null
            };
        }


        case CONTRACT_MESSAGE_STREAM_DONE: {
            const incomingMsg = action.payload;

            if (!incomingMsg || typeof incomingMsg !== "object") {
                return {
                    ...state,
                    chatError: "Invalid message format received"
                };
            }

            const updatedMessages = [...state.messages];

            // Find the last bot message
            let lastAssistantIndex = -1;
            for (let i = updatedMessages.length - 1; i >= 0; i--) {
                if (updatedMessages[i].sender === "bot") {
                    lastAssistantIndex = i;
                    break;
                }
            }

            // Update the existing message or append a new one
            if (lastAssistantIndex !== -1 && updatedMessages[lastAssistantIndex].id === incomingMsg.id) {
                updatedMessages[lastAssistantIndex] = {
                    ...updatedMessages[lastAssistantIndex],
                    text: incomingMsg.content,
                    status: incomingMsg.status
                };
            } else {
                updatedMessages.push({
                    id: incomingMsg.id,
                    text: incomingMsg.content,
                    sender: "bot",
                    status: incomingMsg.status,
                    created_at: incomingMsg.created_at
                });
            }

            return {
                ...state,
                messages: updatedMessages,
                chatError: null
            };
        }

        case CONTRACT_MESSAGE_ERROR:
            return {
                ...state,
                chatError: action.payload
            };

        // Conversation management cases
        case CREATE_CONTRACT_CONVERSATION_REQUEST:
            return {
                ...state,
                creatingConversation: true,
                chatError: null
            };

        case CREATE_CONTRACT_CONVERSATION_SUCCESS:
            return {
                ...state,
                currentConversationId: action.payload,
                creatingConversation: false
            };

        case CREATE_CONTRACT_CONVERSATION_FAILURE:
            return {
                ...state,
                creatingConversation: false,
                chatError: action.payload
            };

        case CLEAR_CHAT_REQUEST:
            return {
                ...state,
                clearingChat: true,
                chatError: null
            };

        case CLEAR_CHAT_SUCCESS:
            return {
                ...state,
                clearingChat: false,
                messages: []
            };

        case CLEAR_CHAT_FAILURE:
            return {
                ...state,
                clearingChat: false,
                chatError: action.payload
            };


        case RISK_ANALYSIS_REQUEST:
            return {
                ...state,
                loadingRisk: true,
                riskAnalysisError: null
            };

        case RISK_ANALYSIS_RESET:
            return {
                ...state,
                risks: [],
                riskAnalysisError: null
            };

        case RISK_ANALYSIS_ADD: {
            const updatedRisk = action.payload;
            const riskIndex = state.risks.findIndex(risk => risk.risk_id === updatedRisk.risk_id);
            if (riskIndex !== -1) {
                // Merge new data with the existing risk
                const mergedRisk = {
                    ...state.risks[riskIndex],
                    ...updatedRisk
                };
                return {
                    ...state,
                    risks: [
                        ...state.risks.slice(0, riskIndex),
                        mergedRisk,
                        ...state.risks.slice(riskIndex + 1)
                    ]
                };
            }
            // If no risk exists with that id, add it to the list
            return {
                ...state,
                risks: [...state.risks, updatedRisk]
            };
        }

        case RISK_ANALYSIS_ERROR:
            return {
                ...state,
                riskAnalysisError: action.payload
            };

        case RISK_ANALYSIS_COMPLETE:
            return {
                ...state,
                loadingRisk: false
            };



        // clauses identification
        case CLAUSES_REQUEST:
            return {
                ...state,
                loadingClause: true,
                clausesError: null
            };

        case CLAUSES_RESET:
            return {
                ...state,
                clauses: [],
                clausesError: null
            };

        case CLAUSES_ADD:
            return {
                ...state,
                clauses: [...state.clauses, action.payload]
            };

        case CLAUSES_ERROR:
            return {
                ...state,
                clausesError: action.payload
            };

        case CLAUSES_COMPLETE:
            return {
                ...state,
                loadingClause: false
            };

        case SUGGESTED_CLAUSES_REQUEST:
            return {
                ...state,
                loadingSuggestedClause: true,
                suggestedClausesError: null
            };

        case SUGGESTED_CLAUSES_RESET:
            return {
                ...state,
                suggestedClauses: [],
                suggestedClausesError: null
            };

        case SUGGESTED_CLAUSES_ADD:
            return {
                ...state,
                suggestedClauses: [...state.suggestedClauses, action.payload]
            };

        case SUGGESTED_CLAUSES_ERROR:
            return {
                ...state,
                suggestedClausesError: action.payload
            };

        case SUGGESTED_CLAUSES_COMPLETE:
            return {
                ...state,
                loadingSuggestedClause: false
            };


        // Add a case in your reducer to handle the new action type
        case SET_CURRENT_CONVERSATION_ID:
            return {
                ...state,
                currentConversationId: action.payload
            };

        // Update the CREATE_CONTRACT_CONVERSATION_SUCCESS case:
        case CREATE_CONTRACT_CONVERSATION_SUCCESS:
            return {
                ...state,
                currentConversationId: action.payload
            };

        // Add these cases to the contractReducer switch statement
        case UPDATE_CONTRACT_STATUS:
            return {
                ...state,
                loadingStatus: true,
                error: null
            };

        case UPDATE_CONTRACT_STATUS_SUCCESS:
            return {
                ...state,
                contract: { ...state.contract, status: action.payload },
                loadingStatus: false,
                error: null
            };

        case UPDATE_CONTRACT_STATUS_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                error: action.payload
            };


        case FILTER_CONTRACTS_REQUEST:
            return {
                ...state,
                loadingFilter: true,
                errorFilter: null
            };

        case FILTER_CONTRACTS_SUCCESS:
            return {
                ...state,
                loadingFilter: false,
                filteredContracts: action.payload,
                errorFilter: null
            };

        case FILTER_CONTRACTS_FAILURE:
            return {
                ...state,
                loadingFilter: false,
                errorFilter: action.payload
            };

        case UPDATE_CONTRACT_OPTIMISTIC:
            return {
                ...state,
                contracts: state.contracts.map(contract =>
                    contract.id === action.payload.id
                        ? { ...contract, ...action.payload.contractData }
                        : contract
                ),
                // If the contract detail is loaded, update it too
                contract: state.contract && state.contract.id === action.payload.id
                    ? { ...state.contract, ...action.payload.contractData }
                    : state.contract,
                // Update filtered contracts if they exist
                filteredContracts: state.filteredContracts.map(contract =>
                    contract.id === action.payload.id
                        ? { ...contract, ...action.payload.contractData }
                        : contract
                )
            };


        default:
            return state;
    }
};

export default contractReducer;