import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Typography,
  alpha
} from '@mui/material';
import { Shield } from '@mui/icons-material';

const EditRolesDialog = ({
  open,
  onClose,
  selectedUser,
  selectedRoles,
  onRoleChange,
  onUpdateRoles,
  availableRoles,
  getRoleDisplayName,
  colors,
  t
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          maxWidth: '500px'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1, 
        pt: 3,
        px: 3,
        color: colors.text,
        fontWeight: 600,
        fontSize: '1.25rem',
        display: 'flex',
        alignItems: 'center'
      }}>
        <Shield sx={{ mr: 1, color: colors.primary }} />
        {t('edit_user_roles', 'Edit User Roles')}
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2 }}>
        <DialogContentText sx={{ color: colors.textSecondary }}>
          {t('edit_roles_description', 'Update the roles for this user:')}
        </DialogContentText>
        {selectedUser && (
          <Box 
            sx={{ 
              mt: 2, 
              p: 2, 
              backgroundColor: colors.backgroundAlt,
              borderRadius: '8px',
              borderLeft: `4px solid ${colors.primary}`,
              mb: 3
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.text }}>
              {selectedUser.first_name} {selectedUser.last_name}
            </Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary }}>
              {selectedUser.email}
            </Typography>
          </Box>
        )}

        <FormControl sx={{ width: '100%' }}>
          <InputLabel 
            sx={{ 
              color: colors.textSecondary,
              '&.Mui-focused': {
                color: colors.primary
              }
            }}
          >{t('roles', 'Roles')}</InputLabel>
          <Select
            multiple
            value={selectedRoles}
            onChange={onRoleChange}
            input={
              <OutlinedInput 
                label={t('roles', 'Roles')} 
                sx={{ 
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.primary,
                  },
                }}
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip 
                    key={value} 
                    label={getRoleDisplayName(value)} 
                    sx={{ 
                      backgroundColor: 
                        value === 'admin' ? colors.secondary :
                        value === 'billing_admin' ? colors.info :
                        colors.backgroundAlt,
                      color: 
                        value === 'admin' || value === 'billing_admin' ? 'white' : colors.text
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {availableRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {getRoleDisplayName(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ color: colors.textSecondary }}>
            <strong>{t('note', 'Note')}:</strong> {t('role_change_explanation', 'Changing user roles will affect their permissions within the system.')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 1 }}>
        <Button 
          onClick={onClose}
          sx={{ 
            color: colors.textSecondary,
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: colors.backgroundAlt,
            }
          }}
        >
          {t('cancel', 'Cancel')}
        </Button>
        <Button 
          onClick={onUpdateRoles} 
          variant="contained"
          disableElevation
          disabled={selectedRoles.length === 0}
          sx={{ 
            backgroundColor: colors.primary,
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: colors.primaryDark,
            },
            '&.Mui-disabled': {
              backgroundColor: alpha(colors.primary, 0.6),
              color: 'white',
            }
          }}
        >
          {t('update_roles', 'Update Roles')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRolesDialog;