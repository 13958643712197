import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers'; // Import your combined reducers
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

// Define the Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  blackList: [] // Specify reducers you want to persist here
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer and middleware
export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk) // Apply thunk middleware
);

export const clearStore = () => {
  persistor.purge().then(() => {
    store.dispatch({ type: 'RESET_STORE' });
  });
};


// Create a persistor for your store
export const persistor = persistStore(store);
