// Updated RiskAnalysis.js
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Chip
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import AnalysisIcon from "@mui/icons-material/Analytics";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DetailsCard from "./DetailsCard";
import { useDispatch, useSelector } from "react-redux";
import { setHighlightError, getRiskAnalysis } from "../../../../redux/actions/contractAction";
import EditorContext from "../../../../contexts/EditorContext";
import { useTranslation } from "react-i18next";

const RiskAnalysis = ({ contractId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const risks = useSelector((state) => state.contract.risks || []);
  const loadingRisk = useSelector((state) => state.contract.loadingRisk);
  const [expandedRiskIndex, setExpandedRiskIndex] = useState(null);
  const [highlightStatus, setHighlightStatus] = useState({
    attempted: false,
    success: false,
    message: ''
  });
  const isRTL = i18n.language === 'ar';

  // Get access to the editor through EditorContext
  const editorRef = useContext(EditorContext);

  // Define risk colors with translated labels
  const riskColors = {
    "High Risk": { border: "#d32f2f", text: "#d32f2f", icon: <WarningIcon /> },
    "Medium Risk": { border: "#fbc02d", text: "#fbc02d", icon: <ReportProblemIcon /> },
    "Low Risk": { border: "#388e3c", text: "#388e3c", icon: <CheckCircleIcon /> },
  };

  // Format HTML to display nicely in risk cards
  function formatHtmlForDisplay(html) {
    if (!html) return '';

    // Replace <br> tags with a bullet points for display
    let text = html.replace(/<br\s*\/?>/gi, ' • ');

    // Remove all other HTML tags
    text = text.replace(/<[^>]*>/g, '');

    // Decode HTML entities
    text = text.replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");

    // Clean up whitespace
    text = text.replace(/\s+/g, ' ').trim();

    return text;
  }

  const RiskCard = ({ level, description, onClick, expanded }) => {
    const { border, text, icon } = riskColors[level] || riskColors[t("riskAnalysis.riskLevels.lowRisk")];
    const formattedDescription = formatHtmlForDisplay(description);

    return (
      <Card
        sx={{
          borderLeft: `8px solid ${border}`,
          backgroundColor: "white",
          mb: 1,
          cursor: "pointer",
          transition: "0.3s",
          borderRadius: 2,
          boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: "0 4px 12px rgba(0,0,0,0.12)"
          },
        }}
        onClick={onClick}
      >
        <CardContent
          sx={{
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 1, color: text }}>{icon}</Box>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: text, textAlign: "left" }}
            >
              {level}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              textAlign: "left",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: expanded ? 'none' : 2, // No clamping if expanded
              overflow: "hidden",
            }}
          >
            {formattedDescription}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  // Extract paragraphs from HTML for more reliable matching
  const extractParagraphs = (html) => {
    if (!html) return [];

    // Replace <br> tags with paragraph markers
    let text = html.replace(/<br\s*\/?>/gi, '|PARAGRAPH_BREAK|');

    // Remove other HTML tags
    text = text.replace(/<[^>]*>/g, '');

    // Split by paragraph markers
    const paragraphs = text.split('|PARAGRAPH_BREAK|');

    // Clean each paragraph and filter out empty ones
    return paragraphs
      .map(p => p.replace(/\s+/g, ' ').trim())
      .filter(p => p.length > 0);
  };

  const handleExtractRisks = () => {
    if (contractId) {
      // Clear any existing highlights when starting new analysis
      clearHighlights();
      dispatch(getRiskAnalysis(contractId));
    }
  };

  const hasRisks = risks && risks.length > 0;

  // Clear highlights helper function
  const clearHighlights = () => {
    setHighlightStatus({
      attempted: false,
      success: false,
      message: ''
    });

    const editor = editorRef?.current?.getEditor();
    if (editor && editor.commands && typeof editor.commands.clearRiskHighlight === 'function') {
      editor.commands.clearRiskHighlight();
    }
  };

  // Find the first instance of a highlighted element and scroll to it
  const scrollToHighlight = () => {
    setTimeout(() => {
      const highlightedElements = document.querySelectorAll('.risk-highlight');
      if (highlightedElements.length > 0) {
        setHighlightStatus(prev => ({
          ...prev,
          success: true,
          message: t("riskAnalysis.foundMatches", { count: highlightedElements.length })
        }));

        highlightedElements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      } else {
        setHighlightStatus(prev => ({
          ...prev,
          success: false,
          message: t("riskAnalysis.noMatching")
        }));
      }
    }, 300); // Wait for highlights to be applied
  };

  // Function to handle risk card click
  const handleRiskClick = (risk, index) => {
    // Toggle expanded state
    const wasExpanded = expandedRiskIndex === index;
    setExpandedRiskIndex(wasExpanded ? null : index);

    // Get the editor instance
    const editor = editorRef?.current?.getEditor();

    if (editor) {
      // Clear existing highlights
      clearHighlights();

      // If we're expanding this risk (not collapsing it), apply the highlight
      if (!wasExpanded && risk.erreur) {
        try {
          console.log("Attempting to highlight text:", risk.erreur.substring(0, 50) + "...");
          setHighlightStatus({
            attempted: true,
            success: false,
            message: t("riskAnalysis.searchingText")
          });

          // Get the color information based on risk level
          const riskColor = riskColors[risk.level]?.text;

          // Apply the highlight with error handling and pass the color
          editor.commands.highlightRiskText(risk.erreur, riskColor);

          // Look for highlighted elements and scroll to the first one
          scrollToHighlight();
        } catch (err) {
          console.error("Error applying highlight:", err);
          setHighlightStatus({
            attempted: true,
            success: false,
            message: t("riskAnalysis.errorHighlighting", { message: err.message })
          });
        }
      }
    }
  };

  // Clear highlights when component unmounts
  useEffect(() => {
    return () => {
      clearHighlights();
    };
  }, [editorRef]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%", // Full height
        overflowY: "auto",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        direction: isRTL ? 'rtl' : 'ltr',
        textAlign: isRTL ? 'right' : 'left'
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Chip
          label={t("riskAnalysis.chip")}
          size="small"
          sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            fontWeight: 500,
            fontSize: '0.75rem',
            mb: 0.5
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#212121", mb: 0 }}>
          {t("riskAnalysis.title")}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575" }}>
          {t("riskAnalysis.subtitle")}
        </Typography>
      </Box>

      <Button
        variant="contained"
        startIcon={hasRisks ? <RefreshIcon /> : <AnalysisIcon />}
        onClick={handleExtractRisks}
        disabled={loadingRisk}
        sx={{
          background: "#1a1a1a",
          color: "#fff",
          borderRadius: 2,
          mb: 2,
          width: "100%",
          '&:hover': {
            background: "#333333"
          }
        }}
      >
        {hasRisks ? t("riskAnalysis.reExtractRisks") : t("riskAnalysis.extractRisks")}
      </Button>

      {loadingRisk && (
        <Box sx={{ textAlign: 'center', py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          <Typography variant="body2">{t("analyzingDocument")}</Typography>
        </Box>
      )}

      {hasRisks ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingX: 0.5, }}>
          {risks.map((risk, index) => (
            <React.Fragment key={index}>
              <RiskCard
                level={risk.level}
                description={risk.erreur}
                onClick={() => handleRiskClick(risk, index)}
                expanded={expandedRiskIndex === index} // Pass expanded status
              />

              {expandedRiskIndex === index && (
                <DetailsCard
                  details={risk.details}
                  suggestion={risk.suggestion}
                  errorText={risk.erreur} // Pass the error text to DetailsCard
                  onClose={() => {
                    setExpandedRiskIndex(null);
                    clearHighlights();
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      ) : !loadingRisk && (
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 6,
          color: "text.secondary"
        }}>
          <DescriptionIcon sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
          <Typography variant="body1">{t("riskAnalysis.extractClauses")}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RiskAnalysis;