import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Tooltip,
  IconButton,
  Avatar,
  Paper,
  AvatarGroup,
  CircularProgress,
  Chip,
  Fade
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { formatDateWithTimezoneOffset } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import NoConversationsPlaceholder from './NoConversationsPlaceholder';
import ConversationGrid from './ConversationGrid';

// ListView component for traditional table layout
const ListView = ({ 
  conversations, 
  loading, 
  selected, 
  handleSelectAllClick, 
  handleCheckboxClick, 
  handleOpenConversation, 
  handleShareConversation,
  handleCreateConversation
}) => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);
  
  // Trigger animation after component mounts
  useEffect(() => {
    setAnimate(true);
  }, []);

  if (loading) {
    return (
      <Fade in={true} timeout={800}>
        <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center', padding: '2rem 0' }}>
                  <CircularProgress size={48} thickness={4} />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Fade>
    );
  }

  if (!conversations.length) {
    return (
      <Fade in={animate} timeout={800}>
        <Box>
          <NoConversationsPlaceholder handleCreateConversation={handleCreateConversation} />
        </Box>
      </Fade>
    );
  }

  return (
    <Fade in={animate} timeout={800}>
      <TableContainer component={Paper} elevation={0} sx={{ 
        borderRadius: '16px', 
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
                padding='checkbox'
              >
                <Checkbox
                  sx={{
                    color: '#fff',
                    '&.Mui-checked': {
                      color: '#fff'
                    },
                    '&.MuiCheckbox-indeterminate': {
                      color: '#fff'
                    }
                  }}
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < conversations.length
                  }
                  checked={
                    conversations.length > 0 &&
                    selected.length === conversations.length
                  }
                  onChange={event => {
                    event.stopPropagation()
                    handleSelectAllClick(event)
                  }}
                  inputProps={{ 'aria-label': 'select all conversations' }}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
              >
                {t('title')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
              >
                {t('creation_date')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
              >
                {t('last_modified')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
              >
                {t('users')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  color: '#fff'
                }}
              >
                {t('action')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations.map((row, index) => {
              const isItemSelected = selected.indexOf(row.id) !== -1;
              const hasDocuments = row.conversation_documents && row.conversation_documents.length > 0;
              
              return (
                <TableRow
                  hover
                  onClick={() => handleOpenConversation(row.id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.04)' },
                    '&.Mui-selected': { backgroundColor: 'rgba(25, 118, 210, 0.08)' },
                    animation: `fadeIn 0.5s ease-in-out forwards`,
                    animationDelay: `${index * 0.05}s`,
                    opacity: 0,
                    '@keyframes fadeIn': {
                      '0%': {
                        opacity: 0,
                        transform: 'translateY(10px)'
                      },
                      '100%': {
                        opacity: 1,
                        transform: 'translateY(0)'
                      }
                    }
                  }}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleCheckboxClick(event, row.id)}
                      inputProps={{ 'aria-labelledby': `conversation-${row.id}` }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        width: '100%'
                      }}>
                        <Typography sx={{ fontWeight: 500 }}>
                          {row.title || t('untitled_conversation')}
                        </Typography>
                        
                        {hasDocuments && (
                          <Chip 
                            size="small" 
                            icon={<DescriptionOutlinedIcon style={{ fontSize: '0.85rem' }} />}
                            label={t('with_documents', { count: row.conversation_documents.length })} 
                            color="primary" 
                            variant="outlined"
                            sx={{ mt: 0.5, height: '20px', fontSize: '0.7rem', maxWidth: 'fit-content' }}
                          />
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CalendarTodayIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary', fontSize: '1rem' }} />
                      {formatDateWithTimezoneOffset(row.created_at)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary', fontSize: '1rem' }} />
                      {formatDateWithTimezoneOffset(row.updated_at)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      {row.users && row.users.length > 0 ? (
                        <AvatarGroup
                          max={4}
                          total={row.users.length}
                          sx={{
                            '& .MuiAvatar-root': {
                              width: 28,
                              height: 28,
                              fontSize: '0.75rem',
                              border: '1.5px solid #fff',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
                            },
                            '& .MuiAvatarGroup-avatar': {
                              borderColor: 'white',
                            }
                          }}
                        >
                          {row.users.map((user, idx) => (
                            <Tooltip
                              key={idx}
                              title={
                                <Box sx={{ p: 0.5 }}>
                                  <Typography variant="body2" fontWeight="medium">
                                    {`${user.first_name} ${user.last_name}`}
                                  </Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    {user.email}
                                  </Typography>
                                </Box>
                              }
                              arrow
                              placement="top"
                            >
                              <Avatar
                                alt={`${user.first_name} ${user.last_name}`}
                                sx={{
                                  bgcolor: [
                                    '#3B82F6', // Blue
                                    '#10B981', // Green
                                    '#F59E0B', // Amber
                                    '#8B5CF6', // Purple
                                    '#EC4899'  // Pink
                                  ][idx % 5]
                                }}
                              >
                                {user.first_name[0]}
                                {user.last_name[0]}
                              </Avatar>
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontStyle: 'italic' }}
                        >
                          {t('no_users')}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      '& .MuiIconButton-root': {
                        transition: 'all 0.2s ease-in-out',
                        color: 'text.secondary',
                        '&:hover': {
                          color: 'primary.main',
                          backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                      }
                    }}
                  >
                    <Tooltip
                      title={t('share_conversation')}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        size="small"
                        onClick={event => {
                          event.stopPropagation(); 
                          event.preventDefault();
                          handleShareConversation(row.id);
                        }}
                        sx={{
                          borderRadius: '8px'
                        }}
                      >
                        <ShareIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
};

const ConversationTable = ({
  conversations,
  loading,
  selected,
  handleSelectAllClick,
  handleCheckboxClick,
  handleOpenConversation,
  handleShareConversation,
  handleCreateConversation,
  viewMode,
  currentLang
}) => {
  return (
    <Box sx={{ mb: 4 }}>
      
      {viewMode === 'grid' ? (
        <Fade in={true} timeout={800}>
          <Box>
            <ConversationGrid
              conversations={conversations}
              loading={loading}
              selected={selected}
              handleCheckboxClick={handleCheckboxClick}
              handleOpenConversation={handleOpenConversation}
              handleShareConversation={handleShareConversation}
              handleCreateConversation={handleCreateConversation}
              currentLang={currentLang}
            />
          </Box>
        </Fade>
      ) : (
        <ListView
          conversations={conversations}
          loading={loading}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleCheckboxClick={handleCheckboxClick}
          handleOpenConversation={handleOpenConversation}
          handleShareConversation={handleShareConversation}
          handleCreateConversation={handleCreateConversation}
        />
      )}
    </Box>
  );
};

export default ConversationTable;