import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  Box,
  Alert,
  Fade
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { clearMessageError, forgotPassword } from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";
import { getDomainName, isMobile } from "../../utils/utils";

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, message } = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const isMobileView = isMobile();



  const handleForgotPassword = () => {
    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(!isValidEmail);

    const domainName = getDomainName(email);

    if (isValidEmail) {
      dispatch(forgotPassword(email, domainName));
    }
  };

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError());
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{
        minHeight: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
        padding: isMobileView ? "16px" : "32px"
      }}
    >
      <Paper
        elevation={8}
        sx={{
          borderRadius: isMobileView ? "12px" : "24px",
          width: isMobileView ? "100%" : "90%",
          maxWidth: "1200px",
          overflow: "hidden",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)"
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: "linear-gradient(135deg, #000000 0%, #2C2C2C 100%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: 4,
              minHeight: isMobileView ? "200px" : "600px"
            }}
          >
            {/* Background pattern */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                backgroundImage: "radial-gradient(circle, #FFFFFF 1px, transparent 1px)",
                backgroundSize: "20px 20px"
              }}
            />

            <Box
              sx={{
                width: isMobileView ? "100px" : "160px",
                height: isMobileView ? "100px" : "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                zIndex: 2,
                mb: 3
              }}
            >
              <Logo
                style={{
                  width: isMobileView ? "80px" : "140px",
                  height: isMobileView ? "80px" : "140px"
                }}
              />
            </Box>

            <Typography
              variant={isMobileView ? "h5" : "h4"}
              sx={{
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
                zIndex: 2
              }}
            >
              Bienvenue sur Juridia
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                textAlign: "center",
                maxWidth: "80%",
                zIndex: 2
              }}
            >
              Simplifiez votre pratique juridique au quotidien avec l'intelligence artificielle..
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: isMobileView ? 3 : 6,
              backgroundColor: "#FFFFFF"
            }}
          >
            <Container maxWidth="sm">
              <Typography
                variant={isMobileView ? "h5" : "h4"}
                sx={{
                  mb: 1,
                  fontWeight: "bold",
                  color: "#333333"
                }}
              >
                Réinitialiser le mot de passe
              </Typography>

              {error && (
                <Fade in={!!error}>
                  <Alert
                    severity="error"
                    sx={{
                      mb: 2,
                      borderRadius: "8px"
                    }}
                  >
                    {error}
                  </Alert>
                </Fade>
              )}

              {message && (
                <Fade in={!!message}>
                  <Alert
                    severity="success"
                    sx={{
                      mb: 2,
                      borderRadius: "8px"
                    }}
                  >
                    {message}
                  </Alert>
                </Fade>
              )}

              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={
                  emailError ? "Veuillez entrer une adresse e-mail valide." : ""
                }
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&.Mui-focused fieldset': {
                      borderColor: colors.primary,
                    },
                  }
                }}
              />

              <CustomButton
                text="Envoyer le lien de réinitialisation"
                outlined={false}
                loading={loading}
                onClick={handleForgotPassword}
                sx={{
                  width: "100%",
                  py: 1.5,
                  borderRadius: "10px",
                  boxShadow: "0 4px 14px rgba(0, 0, 0, 0.1)",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "bold",
                  mb: 3
                }}
              />

              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{ color: colors.primary, cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate("/login")}
                >
                  Retour à la connexion
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ForgotPasswordScreen;