import React, { useState, useEffect } from 'react'
import { Box, Grid, Paper, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import {
  setIsModalMobileVisible,
  setSelectedCitation
} from '../../redux/actions/documentAction'
import { clearSearchDocument, setSearchDocument } from '../../redux/actions/searchAction'
import SearchHeader from './components/SearchHeader'
import SearchFilter from './components/SearchFilter'
import SearchCitations from './components/SearchCitations'
import { SearchPagination } from './components/SearchPagination'
import DocumentViewer from '../../components/document-viewer/DocumentViewer'
import { PdfFocusProvider } from '../../components/pdf-viewer/pdfContext'
import { useTranslation } from 'react-i18next'
import { isMobile } from '../../utils/utils'

const SearchScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Redux state
  const open = useSelector(state => state.navbar.isOpen)
  const searchDocumentSourceUrl = useSelector(state => state.search.searchDocumentSourceUrl)
  const searchSelectedCitation = useSelector(state => state.search.searchSelectedCitation)
  const searchDocument = useSelector(state => state.search.searchDocument)
  const isSearchLoadingDocument = useSelector(state => state.search.isSearchLoadingDocument)
  const isSearchModalVisible = useSelector(state => state.search.isSearchModalVisible)
  const filteredCitations = useSelector(state => state.search.filteredCitations || [])

  // Local state
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/search',
      title: 'Search Screen'
    })
  }, [])

  // Handlers
  const handleCloseModal = () => {
    dispatch(setIsModalMobileVisible(false))
    dispatch(setSelectedCitation(null))
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <PdfFocusProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '95vh',
          backgroundColor: '#ffffff',
          maxWidth: open ? 'calc(100vw - 279.5px)' : '93.1vw',
          padding: { xs: 0, md: 0.5 },
          overflow: 'hidden', // Prevent overflow issues
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            {/* Left Column - SearchHeader and Citations */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                height: '100%',
                borderRight: '1px solid rgba(0, 0, 0, 0.08)',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: { xs: '4px', md: '8px' },
                paddingRight: { xs: '4px', md: '8px' },
                paddingLeft: { xs: '4px', md: '8px' },
                paddingBottom: 0,
                backgroundColor: '#ffffff',
                position: 'relative', // Added to ensure stable positioning
                overflow: 'hidden', // Prevent content overflow
              }}
            >
              <Typography
                variant="h5"
                component="h1"
                sx={{
                  fontWeight: 700,
                  mb: 2,
                  display: { xs: 'none', md: 'block' },
                  flexShrink: 0, // Prevent shrinking
                }}
              >
                {t('legal_search_engine')}
              </Typography>

              <Box sx={{ 
                mb: 1,
                flexShrink: 0, // Prevent shrinking
              }}>
                <SearchHeader />
              </Box>

              <Box sx={{
                flexGrow: 1,
                overflow: 'hidden', // Changed from 'auto' to 'hidden'
                borderRadius: '8px',
                border: '1px solid rgba(0, 0, 0, 0.05)',
                backgroundColor: '#fafafa',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0, // Ensure content can shrink properly
              }}>
                <Box sx={{ 
                  p: 1,
                  flexShrink: 0, // Prevent filter from shrinking
                }}>
                  <SearchFilter />
                </Box>

                <Box sx={{ 
                  flexGrow: 1, 
                  overflow: 'auto', // Keep 'auto' for content scrolling
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 0, // Allow box to shrink
                }}>
                  <SearchCitations
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                  />
                </Box>

                <Box sx={{ 
                  p: 1,
                  flexShrink: 0, // Prevent pagination from shrinking
                }}>
                  <SearchPagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    containerStyle={{
                      padding: '8px 16px',
                      backgroundColor: '#f7f7f7',
                      borderRadius: '8px'
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Right Column - Document Viewer */}
            {!isMobile() && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  position: 'relative', // Added to ensure stable positioning
                  overflow: 'hidden', // Prevent content overflow
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    padding: '8px',
                    position: 'relative', // Added for proper child positioning
                  }}
                >
                  <DocumentViewer
                    document={searchDocument}
                    isLoadingDocument={isSearchLoadingDocument}
                    documentSourceUrl={searchDocumentSourceUrl}
                    selectedCitation={searchSelectedCitation}
                    setDocument={setSearchDocument}
                  />
                </Box>
              </Grid>
            )}

            {/* Mobile Modal */}
            <Modal
              open={isSearchModalVisible}
              onClose={handleCloseModal}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  width: '90%',
                  height: '80vh',
                  backgroundColor: 'white',
                  padding: '16px',
                  borderRadius: '12px',
                  overflow: 'hidden', // Changed from 'auto' to 'hidden'
                  display: 'flex', // Added for proper child layout
                  flexDirection: 'column', // Added for proper child layout
                }}
              >
                <DocumentViewer
                  document={searchDocument}
                  isLoadingDocument={isSearchLoadingDocument}
                  documentSourceUrl={searchDocumentSourceUrl}
                  selectedCitation={searchSelectedCitation}
                  setDocument={setSearchDocument}
                />
              </Box>
            </Modal>
          </Grid>
        </Paper>
      </Box>
    </PdfFocusProvider>
  )
}

export default SearchScreen