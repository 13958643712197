import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Divider,
  Chip,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterListIcon from '@mui/icons-material/FilterList';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton';

const SearchAndActions = ({
  searchTerm,
  handleSearch,
  selected,
  loadingDelete,
  loadingCreate,
  handleOpenDeleteDialog,
  handleCreateConversation,
  viewMode,
  onViewModeChange,
  sortBy,
  sortDirection,
  onSortChange,
  currentLang,
  conversations,
  onFilterChange,
  activeFilters = [],
  onFiltersUpdate,
  isFiltering = false
}) => {
  const { t } = useTranslation();
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [filteredConversations, setFilteredConversations] = useState(conversations);

  // Effect to filter conversations when active filters change
  useEffect(() => {
    if (!conversations) return;

    // If onFiltersUpdate is provided, we're using server-side filtering
    // so we don't need to filter locally
    if (onFiltersUpdate) {
      setFilteredConversations(conversations);
      if (onFilterChange) {
        onFilterChange(conversations);
      }
      return;
    }
    
    let filtered = [...conversations];
    
    if (activeFilters.length === 0) {
      // No filters, return all conversations
      if (onFilterChange) {
        onFilterChange(conversations);
      }
      setFilteredConversations(conversations);
      return;
    }
    
    // Apply each active filter
    if (activeFilters.includes('thisWeek')) {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      
      filtered = filtered.filter(convo => {
        const updatedDate = new Date(convo.updated_at);
        return updatedDate >= oneWeekAgo;
      });
    }
    
    if (activeFilters.includes('thisMonth')) {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      
      filtered = filtered.filter(convo => {
        const updatedDate = new Date(convo.updated_at);
        return updatedDate >= oneMonthAgo;
      });
    }
    
    if (activeFilters.includes('multipleUsers')) {
      filtered = filtered.filter(convo => 
        convo.users && convo.users.length > 1
      );
    }
    
    // Update the filtered conversations
    setFilteredConversations(filtered);
    
    // Call the parent component's filter handler
    if (onFilterChange) {
      onFilterChange(filtered);
    }
  }, [activeFilters, conversations, onFilterChange, onFiltersUpdate]);

  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };

  const handleFilterMenuOpen = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handleSortMenuClick = (field) => {
    onSortChange(field);
    handleSortMenuClose();
  };

  const handleFilterChange = (filter) => {
    let newFilters = [...activeFilters];
    
    // Special handling for date filters (they are mutually exclusive)
    if (filter === 'thisWeek' || filter === 'thisMonth') {
      // Remove any existing date filters
      newFilters = newFilters.filter(f => f !== 'thisWeek' && f !== 'thisMonth');
      
      // Add the new filter if it wasn't removed (toggle behavior)
      if (!(activeFilters.includes(filter))) {
        newFilters.push(filter);
      }
    } else {
      // For other filters, just toggle
      if (activeFilters.includes(filter)) {
        newFilters = newFilters.filter(f => f !== filter);
      } else {
        newFilters.push(filter);
      }
    }
    
    // Update filters in parent component
    if (onFiltersUpdate) {
      onFiltersUpdate(newFilters);
    } else {
      // If no onFiltersUpdate provided, we're handling filters locally
      onFilterChange(newFilters);
    }
  };

  const handleClearFilters = () => {
    if (onFiltersUpdate) {
      onFiltersUpdate([]);
    }
    handleFilterMenuClose();
  };

  // Helper to get count of conversations after filtering
  const getFilterSummary = () => {
    if (activeFilters.length === 0) return null;
    
    if (isFiltering) {
      return t('loading');
    }
    
    return `${filteredConversations.length} / ${conversations.length}`;
  };

  // Helper to render the active filter chips
  const renderFilterChips = () => {
    if (activeFilters.length === 0) return null;
    
    return (
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 1, 
        mt: 1,
        alignItems: 'center'
      }}>
        <Box component="span" sx={{ fontSize: '0.75rem', color: 'text.secondary' }}>
          {t('active_filters')}:
        </Box>
        {activeFilters.includes('thisWeek') && (
          <Chip 
            size="small" 
            label={t('filter_this_week')} 
            onDelete={() => handleFilterChange('thisWeek')}
            deleteIcon={<ClearIcon fontSize="small" />}
          />
        )}
        {activeFilters.includes('thisMonth') && (
          <Chip 
            size="small" 
            label={t('filter_this_month')} 
            onDelete={() => handleFilterChange('thisMonth')}
            deleteIcon={<ClearIcon fontSize="small" />}
          />
        )}
        {activeFilters.includes('multipleUsers') && (
          <Chip 
            size="small" 
            label={t('filter_shared_conversations')} 
            onDelete={() => handleFilterChange('multipleUsers')}
            deleteIcon={<ClearIcon fontSize="small" />}
          />
        )}
        <Chip 
          size="small" 
          label={t('clear_all')} 
          onClick={handleClearFilters}
          color="primary"
          variant="outlined"
        />
      </Box>
    );
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}>
      <Box sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'stretch', sm: 'center' },
        gap: 2,
        mb: activeFilters.length > 0 ? 1 : 3
      }}>
        <TextField
          fullWidth
          placeholder={t('search_placeholder_chat')}
          variant='outlined'
          value={searchTerm}
          onChange={handleSearch}
          sx={{ 
            maxWidth: { sm: '60%' },
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#f5f5f5',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c4c4c4'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main'
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          alignItems: 'center',
          justifyContent: { xs: 'space-between', sm: 'flex-end' },
          flexShrink: 0,
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          <Box sx={{ 
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexShrink: 0
          }}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(_, newView) => newView && onViewModeChange(newView)}
              aria-label="view mode"
              size="small"
              sx={{ height: '40px' }}
            >
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid view">
                <GridViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>

            <Tooltip title={t('sort_by')}>
              <IconButton 
                onClick={handleSortMenuOpen}
                aria-label="sort"
                size="small"
                sx={{ 
                  height: '40px', 
                  width: '40px',
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.12)',
                  }
                }}
              >
                <SortIcon />
              </IconButton>
            </Tooltip>
            
            <Menu
              anchorEl={sortMenuAnchor}
              open={Boolean(sortMenuAnchor)}
              onClose={handleSortMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleSortMenuClick('title')}>
                {t('title')} 
                {sortBy === 'title' && (
                  sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} /> : 
                  <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
                )}
              </MenuItem>
              <MenuItem onClick={() => handleSortMenuClick('created_at')}>
                {t('creation_date')} 
                {sortBy === 'created_at' && (
                  sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} /> : 
                  <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
                )}
              </MenuItem>
              <MenuItem onClick={() => handleSortMenuClick('updated_at')}>
                {t('last_modified')} 
                {sortBy === 'updated_at' && (
                  sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} /> : 
                  <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
                )}
              </MenuItem>
            </Menu>

            {/* Filter button with menu */}
            <Box sx={{ position: 'relative' }}>
              <Tooltip title={t('filter')}>
                <IconButton 
                  onClick={handleFilterMenuOpen}
                  aria-label="filter"
                  size="small"
                  color={activeFilters.length > 0 ? "primary" : "default"}
                  sx={{ 
                    height: '40px', 
                    width: '40px',
                    backgroundColor: activeFilters.length > 0 
                      ? 'rgba(25, 118, 210, 0.08)'
                      : 'rgba(25, 118, 210, 0.04)',
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.12)',
                    }
                  }}
                >
                  {isFiltering ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <FilterListIcon />
                  )}
                  {activeFilters.length > 0 && !isFiltering && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 2,
                        right: 2,
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        fontSize: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {activeFilters.length}
                    </Box>
                  )}
                </IconButton>
              </Tooltip>

              {activeFilters.length > 0 && (
                <Chip 
                  size="small"
                  label={getFilterSummary()}
                  sx={{ 
                    position: 'absolute',
                    top: -10,
                    right: -10,
                    height: 20,
                    fontSize: '0.7rem',
                    backgroundColor: 'primary.main',
                    color: 'white'
                  }}
                />
              )}
            </Box>

            <Menu
              anchorEl={filterMenuAnchor}
              open={Boolean(filterMenuAnchor)}
              onClose={handleFilterMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem 
                onClick={() => handleFilterChange('thisWeek')}
                sx={{
                  backgroundColor: activeFilters.includes('thisWeek') 
                    ? 'rgba(25, 118, 210, 0.08)' 
                    : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <TodayIcon fontSize="small" />
                {t('filter_this_week', 'This week')}
              </MenuItem>
              <MenuItem 
                onClick={() => handleFilterChange('thisMonth')}
                sx={{
                  backgroundColor: activeFilters.includes('thisMonth') 
                    ? 'rgba(25, 118, 210, 0.08)' 
                    : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <CalendarMonthIcon fontSize="small" />
                {t('filter_this_month', 'This month')}
              </MenuItem>
              <MenuItem 
                onClick={() => handleFilterChange('multipleUsers')}
                sx={{
                  backgroundColor: activeFilters.includes('multipleUsers') 
                    ? 'rgba(25, 118, 210, 0.08)' 
                    : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <PeopleIcon fontSize="small" />
                {t('filter_shared_conversations', 'Shared conversations')}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClearFilters}>
                {t('clear_filters', 'Clear filters')}
              </MenuItem>
            </Menu>

            <Divider orientation="vertical" flexItem sx={{ height: '40px' }} />
          </Box>

          <Box sx={{ 
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            ml: { xs: 0, md: 1 }
          }}>
            {selected.length > 0 && (
              <Button
                variant="outlined"
                color="error"
                disabled={loadingDelete}
                startIcon={<DeleteIcon />}
                onClick={handleOpenDeleteDialog}
                sx={{
                  height: '40px',
                  borderRadius: '8px',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  px: 1.5
                }}
              >
                {loadingDelete ? t("deleting") : `${t("delete")} (${selected.length})`}
              </Button>
            )}

            <CustomButton
              text={t('create_conversation')}
              outlined={false}
              loading={loadingCreate}
              startIcon={<AddIcon />}
              onClick={handleCreateConversation}
              sx={{
                height: '40px',
                borderRadius: '8px',
                whiteSpace: 'nowrap',
                boxShadow: '0 2px 5px rgba(0,0,0,0.08)',
                ml: selected.length > 0 ? 1 : 0
              }}
            />
          </Box>
        </Box>
      </Box>
      
      {/* Active filters display */}
      {renderFilterChips()}
    </Box>
  );
};

export default SearchAndActions;