import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Modal,
  Container,
  Card,
  CardContent,
  Divider,
  IconButton,
  Avatar,
  useTheme,
  alpha,
  Skeleton
} from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { colors } from '../../utils/colors'
import { fontSize } from '../../utils/fonts'
import { styles } from '../../utils/styles'
import { backendClient } from '../../api/backend'
import { useSelector } from 'react-redux'
import { addMonths, format } from 'date-fns'
import { windowWidth } from '../../utils/utils'
import { clearStore } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ContractCharts from './components/ContractCharts'
// Import icons - you'll need to install these if not already available
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import DescriptionIcon from '@mui/icons-material/Description'
import SearchIcon from '@mui/icons-material/Search'
import PeopleIcon from '@mui/icons-material/People'
import CloseIcon from '@mui/icons-material/Close'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import CustomButton from '../../components/CustomButton'

const DashboardScreen = () => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const location = useLocation()
  const theme = useTheme()

  // Custom colors - you can adjust these or use your theme colors
  const customColors = {
    primary: '#3a86ff',
    secondary: '#ff006e',
    success: '#38b000',
    danger: '#e63946', // Added explicit danger color for negative growth
    info: '#3f8efc',
    warning: '#ffbe0b',
    chartColors: ['#3a86ff', '#ff006e', '#8338ec', '#fb5607', '#ffbe0b']
  }

  const user = useSelector(state => state.auth.user?.user)

  // Separate loading states for different data sections
  const [summaryLoading, setSummaryLoading] = useState(true)
  const [conversationsLoading, setConversationsLoading] = useState(true)
  const [searchesLoading, setSearchesLoading] = useState(true)

  // User data states
  const [totalConversations, setTotalConversations] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [conversationsOverTime, setConversationsOverTime] = useState([])
  const [conversationGrowth, setConversationGrowth] = useState({ value: 0, percentage: '+0%', isPositive: true })

  // Search data states
  const [totalSearchs, setTotalSearchs] = useState(0)
  const [totalUsersSearchs, setTotalUsersSearchs] = useState(0)
  const [searchsOverTime, setSearchOverTime] = useState([])
  const [searchGrowth, setSearchGrowth] = useState({ value: 0, percentage: '+0%', isPositive: true })

  const [openModal, setOpenModal] = useState(location?.state?.openModal || false)

  // Helper function to generate the last 4 months with 0 conversations as defaults
  const getLastFourMonths = () => {
    const currentDate = new Date()
    const months = []

    for (let i = 0; i < 4; i++) {
      const monthDate = addMonths(currentDate, -i)
      months.unshift({ x: format(monthDate, 'MMM'), y: 0 })
    }

    return months
  }

  // Combine the fetched data with default months if necessary
  const ensureFourMonths = fetchedData => {
    const lastFourMonths = getLastFourMonths()

    const filledData = [...lastFourMonths]

    fetchedData.forEach(item => {
      const index = filledData.findIndex(month => month.x === item.x)
      if (index !== -1) {
        filledData[index].y = item.y // Replace 0 with actual data if available
      }
    })

    return filledData
  }

  // Function to calculate the monthly growth
  const calculateMonthlyGrowth = (data) => {
    if (!data?.length || !data[0]?.data?.length) {
      return { value: 0, percentage: '+0%', isPositive: true };
    }

    const chartData = data[0].data;

    // We need at least two months of data to calculate growth
    if (chartData.length < 2) {
      return { value: 0, percentage: '+0%', isPositive: true };
    }

    // Get the current and previous month values
    const currentMonth = chartData[chartData.length - 1].y;
    const previousMonth = chartData[chartData.length - 2].y;

    // Calculate the growth
    const growthValue = currentMonth - previousMonth;

    // Handle special cases for growth calculation
    let growthPercentage = 0;
    let growthText = '';
    let isPositive = true;

    // Case 1: Previous month is 0
    if (previousMonth === 0) {
      if (currentMonth > 0) {
        // If we went from 0 to something, that's "New" or "∞" growth
        growthText = currentMonth === 0 ? '+0%' : 'New';
        isPositive = true;
      } else {
        // Both months are 0, no change
        growthText = '+0%';
        isPositive = true;
      }
    }
    // Case 2: Normal calculation when previous month is not 0
    else {
      growthPercentage = (growthValue / previousMonth) * 100;
      growthText = `${growthPercentage > 0 ? '+' : ''}${growthPercentage.toFixed(1)}%`;
      isPositive = growthPercentage >= 0;
    }

    return {
      value: growthValue,
      percentage: growthText,
      isPositive: isPositive
    };
  };

  // Monthly growth display component
  const MonthlyGrowthDisplay = ({ growth, color }) => {
    const growthColor = growth.isPositive ? customColors.success : customColors.danger;
    const growthIcon = growth.isPositive ?
      <TrendingUpIcon sx={{ ml: 0.5, fontSize: 24 }} /> :
      <TrendingDownIcon sx={{ ml: 0.5, fontSize: 24 }} />;

    return (
      <Box sx={{
        p: 2,
        borderRadius: 2,
        bgcolor: alpha(growthColor, 0.05),
        border: `1px dashed ${alpha(growthColor, 0.3)}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: growthColor,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {growth.percentage}
            {growthIcon}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {t('monthly_growth')} <span style={{ fontStyle: 'italic' }}>vs last month</span>
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    const modalClosed = JSON.parse(localStorage.getItem('modalClosed'))
    if (!modalClosed) {
      setOpenModal(true) // Don't show the modal if it was closed previously
    }
  }, [])

  const handleCloseModal = () => {
    setOpenModal(false)
    localStorage.setItem('modalClosed', true) // Store flag in localStorage
  }

  // Separate data fetching into individual functions
  const fetchSummaryData = async () => {
    try {
      setSummaryLoading(true)
      // This would be real API calls to fetch summary data
      // For now, simulating with a delay
      await new Promise(resolve => setTimeout(resolve, 500))
      setSummaryLoading(false)
    } catch (error) {
      console.error('Error fetching summary data', error)
      setSummaryLoading(false)
    }
  }

  const fetchConversationsData = async () => {
    try {
      setConversationsLoading(true)

      // Fetch conversations data
      const { totalConversations, totalUsers } =
        await backendClient.getConversationsAndUsers(user?.organization_name)
      setTotalConversations(totalConversations)
      setTotalUsers(totalUsers)

      // Fetch conversation timeline data
      const conversationsData = await backendClient.getConversationsOverTime(
        user?.organization_name
      )
      if (conversationsData && Array.isArray(conversationsData)) {
        // Ensure the data contains at least 4 months
        const filledData = ensureFourMonths(conversationsData[0].data)
        const formattedData = [{ id: 'conversation', data: filledData }];
        setConversationsOverTime(formattedData)

        // Calculate conversation growth
        setConversationGrowth(calculateMonthlyGrowth(formattedData));
      }

      setConversationsLoading(false)
    } catch (error) {
      console.error('Error fetching conversations data', error)
      setConversationsLoading(false)
    }
  }

  const fetchSearchesData = async () => {
    try {
      setSearchesLoading(true)

      // Fetch search stats
      const { totalSearchs, totalUsersSearchs } =
        await backendClient.getSearchesAndUsers(user?.organization_name)
      setTotalSearchs(totalSearchs)
      setTotalUsersSearchs(totalUsersSearchs)

      // Fetch search timeline data
      const searchsData = await backendClient.getSearchesOverTime(
        user?.organization_name
      )

      if (searchsData && Array.isArray(searchsData)) {
        // Ensure the data contains at least 4 months
        const filledData = ensureFourMonths(searchsData[0].data)
        const formattedData = [{ id: 'search', data: filledData }];
        setSearchOverTime(formattedData)

        // Calculate search growth
        setSearchGrowth(calculateMonthlyGrowth(formattedData));
      }

      setSearchesLoading(false)
    } catch (error) {
      console.error('Error fetching searches data', error)
      setSearchesLoading(false)
    }
  }

  // Trigger the progressive data loading
  useEffect(() => {
    // Fetch the data in parallel but display as they become available
    fetchSummaryData()
    fetchConversationsData()
    fetchSearchesData()
  }, [user?.organization_name])

  const dataConversation = conversationsOverTime.length
    ? conversationsOverTime
    : [
      {
        id: 'conversation',
        data: getLastFourMonths()
      }
    ]
  const dataSearch = searchsOverTime.length
    ? searchsOverTime
    : [
      {
        id: 'search',
        data: getLastFourMonths()
      }
    ]

  const overviewData = [
    { value: '+16k', label: t('documents_available'), icon: <DescriptionIcon sx={{ fontSize: 36, color: customColors.primary }} /> },
    { value: '+35k', label: t('jurisprudence_cases'), icon: <DescriptionIcon sx={{ fontSize: 36, color: customColors.secondary }} /> },
    { value: '+300k', label: t('legal_articles'), icon: <DescriptionIcon sx={{ fontSize: 36, color: customColors.success }} /> },
    { value: '+1m', label: t('graph_nodes'), icon: <TrendingUpIcon sx={{ fontSize: 36, color: customColors.info }} /> }
  ]

  const chartTheme = {
    axis: {
      domain: {
        line: {
          stroke: theme.palette.text.secondary,
          strokeWidth: 1
        }
      },
      ticks: {
        line: {
          stroke: theme.palette.text.secondary,
          strokeWidth: 1
        },
        text: {
          fill: theme.palette.text.secondary,
          fontSize: 12
        }
      },
      legend: {
        text: {
          fill: theme.palette.text.primary,
          fontSize: 13,
          fontWeight: 500
        }
      }
    },
    grid: {
      line: {
        stroke: alpha(theme.palette.divider, 0.3),
        strokeWidth: 1
      }
    },
    tooltip: {
      container: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 12,
        borderRadius: 4,
        boxShadow: theme.shadows[3],
        padding: 8
      }
    }
  }

  // Create loading skeleton
  const LoadingSkeleton = () => (
    <Box sx={{ width: '100%' }}>
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="40%" height={60} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={250} sx={{ borderRadius: 1 }} />
    </Box>
  )


  return (
    <Box sx={{ py: 2, px: 1 }}>
      <Grid container spacing={3}>
        {/* Page Title and Welcome Message */}
        <Grid item xs={12}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography component="h1" variant="h5" sx={{ fontWeight: 700 }}>
                {t('dashboard')}
              </Typography>
              {/* <Typography variant="body1" color="text.secondary">
                {t('welcome_back')}, {user?.name || t('user')}
              </Typography> */}
            </Box>
            <Box>
              <CustomButton
                text={t('tutorial_video')}
                variant="contained"
                startIcon={<PlayCircleIcon />}
                onClick={() => setOpenModal(true)}
                sx={{
                  // '&:hover': {
                  //   backgroundColor: alpha(customColors.primary, 0.8)
                  // }
                }}
              />
            </Box>
          </Box>
        </Grid>


        {/* Data Summary Cards */}
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              border: `1px solid ${theme.palette.divider}`,
              background: 'linear-gradient(145deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,1) 100%)',

            }}
            dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
          >
            <Typography
              variant='h6'
              sx={{
                fontWeight: 600,
                mb: { xs: 2, sm: 3 },
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                color: theme.palette.text.primary
              }}
            >
              {t('data_summary')}
            </Typography>

            <Grid container spacing={3}>
              {summaryLoading ? (
                // Show skeleton cards when loading
                Array(4).fill().map((_, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card
                      elevation={0}
                      sx={{
                        height: '100%',
                        borderRadius: 2,
                        background: 'white',
                        transition: 'all 0.3s ease',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                      }}
                    >
                      <CardContent sx={{ p: 3 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // mb: 2
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: '50%',
                              p: 1.5,
                              mr: 2,
                              mb: 4.4,
                              bgcolor: alpha(theme.palette.primary.main, 0.05),
                              width: 68,
                              height: 68,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Skeleton variant="circular" width={36} height={36} />
                          </Box>
                          <Box>
                            <Skeleton variant="text" width={80} height={18} sx={{ mb: 0.5 }} />
                            <Skeleton variant="text" width={80} height={18} sx={{ mb: 0.5 }} />
                            <Skeleton variant="text" width={60} height={32} />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                // Show actual data when loaded
                overviewData.map((item, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card
                      elevation={0}
                      sx={{
                        height: '100%',
                        borderRadius: 2,
                        background: 'white',
                        transition: 'all 0.3s ease',
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 10px 30px rgba(0,0,0,0.08)'
                        }
                      }}
                    >
                      <CardContent sx={{ p: 3 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: '50%',
                              p: 1.5,
                              mr: 2,
                              bgcolor: alpha(theme.palette.primary.main, 0.1)
                            }}
                          >
                            {item.icon}
                          </Box>
                          <Box>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              {item.label}
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                              {item.value}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </Paper>
        </Grid>

        {/* Search Breakdown */}
        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              border: `1px solid ${theme.palette.divider}`,
              background: 'white',
              direction: currentLang === 'ar' ? 'rtl' : 'ltr'
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    sx={{
                      bgcolor: alpha(customColors.info, 0.1),
                      color: customColors.info,
                      mr: 2
                    }}
                  >
                    <SearchIcon />
                  </Avatar>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {t('searches')}
                  </Typography>
                </Box>
              </Box>

              {searchesLoading ? (
                <Box sx={{ p: 3 }}>
                  <LoadingSkeleton />
                </Box>
              ) : (
                <>
                  <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={6}>
                      <Box sx={{
                        p: 2,
                        borderRadius: 2,
                        bgcolor: alpha(customColors.info, 0.05),
                        border: `1px dashed ${alpha(customColors.info, 0.3)}`
                      }}>
                        <Typography variant="h4" sx={{ fontWeight: 700, color: customColors.info }}>
                          {totalSearchs}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {t('total_searches')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      {/* Replace users count with monthly growth */}
                      <MonthlyGrowthDisplay growth={searchGrowth} color={customColors.info} />
                    </Grid>
                  </Grid>

                  <Box sx={{ height: 250 }}>
                    <ResponsiveLine
                      data={dataSearch}
                      theme={chartTheme}
                      margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
                      xScale={{ type: 'point' }}
                      yScale={{
                        type: 'linear',
                        min: 0,
                        max: totalSearchs + 20,
                        stacked: true
                      }}
                      curve="cardinal"
                      enableArea={true}
                      areaOpacity={0.1}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t('tooltip_month'),
                        legendOffset: 45,
                        legendPosition: 'middle'
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t('tooltip_search'),
                        legendOffset: -50,
                        legendPosition: 'middle'
                      }}
                      colors={[customColors.info]}
                      lineWidth={3}
                      pointSize={10}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      gridYValues={5}
                      enableGridX={false}
                      enableSlices="x"
                      sliceTooltip={({ slice }) => (
                        <div
                          style={{
                            background: 'white',
                            padding: '12px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                            border: `1px solid ${theme.palette.divider}`
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            {slice.points[0].data.x}
                          </Typography>
                          {slice.points.map(point => (
                            <Box key={point.id} sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                              <Box
                                sx={{
                                  width: 12,
                                  height: 12,
                                  borderRadius: '50%',
                                  bgcolor: point.serieColor,
                                  mr: 1
                                }}
                              />
                              <Typography variant="body2">
                                {t('tooltip_search')}: <strong>{point.data.yFormatted}</strong>
                              </Typography>
                            </Box>
                          ))}
                        </div>
                      )}
                    />
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Conversation Breakdown */}
        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
              border: `1px solid ${theme.palette.divider}`,
              background: 'white',
              direction: currentLang === 'ar' ? 'rtl' : 'ltr'
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    sx={{
                      bgcolor: alpha(customColors.primary, 0.1),
                      color: customColors.primary,
                      mr: 2
                    }}
                  >
                    <PeopleIcon />
                  </Avatar>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {t('conversations')}
                  </Typography>
                </Box>
              </Box>

              {conversationsLoading ? (
                <Box sx={{ p: 3 }}>
                  <LoadingSkeleton />
                </Box>
              ) : (
                <>
                  <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={6}>
                      <Box sx={{
                        p: 2,
                        borderRadius: 2,
                        bgcolor: alpha(customColors.primary, 0.05),
                        border: `1px dashed ${alpha(customColors.primary, 0.3)}`
                      }}>
                        <Typography variant="h4" sx={{ fontWeight: 700, color: customColors.primary }}>
                          {totalConversations}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {t('total_conversations')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      {/* Replace users count with monthly growth */}
                      <MonthlyGrowthDisplay growth={conversationGrowth} color={customColors.primary} />
                    </Grid>
                  </Grid>

                  <Box sx={{ height: 250 }}>
                    <ResponsiveLine
                      data={dataConversation}
                      theme={chartTheme}
                      margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
                      xScale={{ type: 'point' }}
                      yScale={{
                        type: 'linear',
                        min: 0,
                        max: totalConversations + 20,
                        stacked: true
                      }}
                      curve="cardinal"
                      enableArea={true}
                      areaOpacity={0.1}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t('tooltip_month'),
                        legendOffset: 45,
                        legendPosition: 'middle'
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t('conversations'),
                        legendOffset: -50,
                        legendPosition: 'middle'
                      }}
                      colors={[customColors.primary]}
                      lineWidth={3}
                      pointSize={10}
                      pointColor={{ theme: 'background' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      gridYValues={5}
                      enableGridX={false}
                      enableSlices="x"
                      sliceTooltip={({ slice }) => (
                        <div
                          style={{
                            background: 'white',
                            padding: '12px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                            border: `1px solid ${theme.palette.divider}`
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            {slice.points[0].data.x}
                          </Typography>
                          {slice.points.map(point => (
                            <Box key={point.id} sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                              <Box
                                sx={{
                                  width: 12,
                                  height: 12,
                                  borderRadius: '50%',
                                  bgcolor: point.serieColor,
                                  mr: 1
                                }}
                              />
                              <Typography variant="body2">
                                {t('conversations')}: <strong>{point.data.yFormatted}</strong>
                              </Typography>
                            </Box>
                          ))}
                        </div>
                      )}
                    />
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Contract Charts Section - Loaded independently */}
        <Grid item xs={12}>
          <ContractCharts />
        </Grid>
      </Grid>

      {/* Tutorial Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='video-modal-title'
        aria-describedby='video-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            bgcolor: 'background.paper',
            borderRadius: 3,
            boxShadow: '0 10px 40px rgba(0,0,0,0.2)',
            overflow: 'hidden'
          }}
        >
          <Box sx={{
            position: 'relative',
            p: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}>
            <Typography id='video-modal-title' variant='h6' sx={{ fontWeight: 600 }}>
              {t('tutorial_video')}
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              size="small"
              sx={{
                bgcolor: alpha(theme.palette.grey[500], 0.1),
                '&:hover': {
                  bgcolor: alpha(theme.palette.grey[500], 0.2)
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box sx={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0
              }}
              src='https://www.youtube.com/embed/qsyVx8lhty8'
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </Box>

          <Box sx={{
            p: 3, display: "flex", alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CustomButton
              text={t('close')}
              // fullWidth
              variant='contained'
              onClick={handleCloseModal}
              sx={{
                color: 'white',
                py: 1.5,
                borderRadius: 2,
                width: "80%",
                // fontWeight: 600,
                // '&:hover': {
                //   bgcolor: alpha(customColors.primary, 0.9)
                // }
              }}
            />

          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DashboardScreen