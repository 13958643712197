// src/components/RightPanel/History/History.jsx
import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
    Chip
} from "@mui/material";
import {
    History as HistoryIcon,
    Restore as RestoreIcon,
    Download as DownloadIcon,
    Info as InfoIcon,
    Close as CloseIcon,
    ArrowForward as ArrowForwardIcon
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import { fetchContractHistory, restoreFromSnapshot } from "../../../../redux/actions/contractAction";
import SnapshotViewer from "./SnapshotViewer";
import { useTranslation } from "react-i18next"; // Import for i18n

// Action mapping dictionary for all supported languages
const ACTION_LABELS = {
    "status_change": {
        en: "Contract status change",
        fr: "Modification du statut du contrat",
        ar: "تغيير حالة العقد"
    },
    "update": {
        en: "Content update",
        fr: "Mise à jour du contenu",
        ar: "تحديث المحتوى"
    },
    "save": {
        en: "Save",
        fr: "Sauvegarde",
        ar: "حفظ"
    },
    "restore": {
        en: "Restore from snapshot",
        fr: "Restauration d'une version",
        ar: "استعادة من نسخة سابقة"
    },
    "create": {
        en: "Contract creation",
        fr: "Création du contrat",
        ar: "إنشاء العقد"
    }
};

// Status dictionary for all supported languages
const STATUS_LABELS = {
    "brouillon": {
        en: "Draft",
        fr: "Brouillon",
        ar: "مسودة"
    },
    "en revu": {
        en: "In Review",
        fr: "En revue",
        ar: "قيد المراجعة"
    },
    "en attente de signature": {
        en: "Pending Signature",
        fr: "En attente de signature",
        ar: "في انتظار التوقيع"
    },
    "signé": {
        en: "Signed",
        fr: "Signé",
        ar: "موقع"
    }
};

// Status transition phrases
const STATUS_TRANSITION = {
    "changed": {
        en: "Status changed from",
        fr: "Statut modifié de",
        ar: "تغيرت الحالة من"
    },
    "to": {
        en: "to",
        fr: "à",
        ar: "إلى"
    }
};

const History = () => {
    const dispatch = useDispatch();
    const { contractId } = useParams();
    const history = useSelector(state => state.contract.history || []);
    const loading = useSelector(state => state.contract.historyLoading);
    const [selectedSnapshot, setSelectedSnapshot] = useState(null);
    const [showRawHtml, setShowRawHtml] = useState(false);
    const [parsedSnapshot, setParsedSnapshot] = useState(null);
    const { i18n } = useTranslation(); // Get i18n instance
    const currentLanguage = i18n.language.split('-')[0] || 'en'; // Extract language code

    const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

    // Add a restoringSnapshot state to track the restore operation
    const [restoringSnapshot, setRestoringSnapshot] = useState(false);

    // Function to handle closing the notification
    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    // Helper function to get localized text
    const getLocalizedText = (dictionary, key) => {
        if (!dictionary || !key) return key;
        
        // If the key exists in the dictionary and has a translation for the current language
        if (dictionary[key] && dictionary[key][currentLanguage]) {
            return dictionary[key][currentLanguage];
        }
        
        // Fallback to English if translation doesn't exist
        if (dictionary[key] && dictionary[key].en) {
            return dictionary[key].en;
        }
        
        // Return the key itself as last resort
        return key;
    };

    // Get localized action label
    const getActionLabel = (action) => {
        return getLocalizedText(ACTION_LABELS, action);
    };

    // Get localized status label
    const getStatusLabel = (status) => {
        return getLocalizedText(STATUS_LABELS, status);
    };

    useEffect(() => {
        if (contractId) {
            dispatch(fetchContractHistory(contractId));
        }
    }, [contractId, dispatch]);

    // Parse snapshot data when a snapshot is selected
    useEffect(() => {
        if (selectedSnapshot) {
            try {
                // Check if content_snapshot is JSON
                if (selectedSnapshot.content_snapshot && 
                    (selectedSnapshot.content_snapshot.startsWith('{') || 
                     selectedSnapshot.content_snapshot.startsWith('['))) {
                    const parsed = JSON.parse(selectedSnapshot.content_snapshot);
                    setParsedSnapshot(parsed);
                } else {
                    setParsedSnapshot(null);
                }
            } catch (error) {
                console.error("Error parsing snapshot data:", error);
                setParsedSnapshot(null);
            }
        } else {
            setParsedSnapshot(null);
        }
    }, [selectedSnapshot]);

    const handleDownloadHistory = () => {
        if (history.length === 0) return;

        const historyText = history.map(entry => {
            const date = new Date(entry.timestamp).toLocaleString();
            return `${date} - ${entry.user_name} - ${getActionLabel(entry.action)}\n${entry.content_snapshot || ""}`;
        }).join("\n\n");

        const blob = new Blob([historyText], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `contract-history-${contractId}.txt`;
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleViewSnapshot = (entry) => {
        setSelectedSnapshot(entry);
        setShowRawHtml(false); // Reset to rendered view when opening a new snapshot
    };

    const handleRestore = (entry) => {
        console.log("Restore from snapshot:", entry);
        setRestoringSnapshot(true); // Set loading state

        // Show restoring notification
        setNotification({
            open: true,
            message: "Restoring document...",
            severity: "info"
        });

        dispatch(restoreFromSnapshot(contractId, entry.id))
            .then(() => {
                // Success handling
                setRestoringSnapshot(false);
                setSelectedSnapshot(null);

                // Show success notification
                setNotification({
                    open: true,
                    message: "Document restored successfully!",
                    severity: "success"
                });
            })
            .catch((error) => {
                // Error handling
                setRestoringSnapshot(false);
                console.error("Error restoring snapshot:", error);

                // Show error notification
                setNotification({
                    open: true,
                    message: `Failed to restore document: ${error.message || 'Unknown error'}`,
                    severity: "error"
                });
            });
    };

    const handleCloseDialog = () => {
        // Only allow closing dialog if not currently restoring
        if (!restoringSnapshot) {
            setSelectedSnapshot(null);
        }
    };

    const handleToggleViewMode = () => {
        setShowRawHtml(!showRawHtml);
    };

    // Get status info if it's a status change entry
    const getStatusChangeInfo = () => {
        if (!parsedSnapshot || !selectedSnapshot || selectedSnapshot.action !== 'status_change') {
            return null;
        }

        return {
            oldStatus: parsedSnapshot.old_status,
            newStatus: parsedSnapshot.new_status
        };
    };

    const statusChangeInfo = getStatusChangeInfo();

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    // Helper to get color based on status
    const getStatusColor = (status) => {
        if (!status) return 'default';
        
        switch(status.toLowerCase()) {
            case 'brouillon':
                return 'primary';
            case 'en revu':
                return 'warning';
            case 'en attente de signature':
                return 'info';
            case 'signé':
                return 'success';
            default:
                return 'default';
        }
    };

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", p: 2, borderBottom: "1px solid #eee" }}>
                <HistoryIcon sx={{ mr: 1 }} />
                <Typography variant="h6" component="div">
                    Document History
                </Typography>

                <Box sx={{ ml: "auto" }}>
                    <Tooltip title="Download History">
                        <IconButton
                            onClick={handleDownloadHistory}
                            disabled={history.length === 0}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <Box sx={{ overflow: "auto", flexGrow: 1, overflowY: 'auto', maxHeight: '500px', }}>
                {history.length === 0 ? (
                    <Box sx={{ p: 3, textAlign: "center" }}>
                        <Typography color="text.secondary">No history available</Typography>
                    </Box>
                ) : (
                    <List sx={{ width: "100%" }}>
                        {history.map((entry, index) => (
                            <React.Fragment key={entry.id}>
                                <ListItem
                                    alignItems="flex-start"
                                    secondaryAction={
                                        <Tooltip title="View Snapshot">
                                            <IconButton edge="end" onClick={() => handleViewSnapshot(entry)}>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>{entry.user_name.charAt(0)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {entry.user_name}
                                                {entry.action === 'status_change' && (
                                                    <Chip 
                                                        size="small" 
                                                        label={getActionLabel('status_change')} 
                                                        color="primary" 
                                                        sx={{ height: 20 }}
                                                    />
                                                )}
                                            </Box>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {getActionLabel(entry.action)}
                                                </Typography>
                                                {" — "}
                                                {formatDistanceToNow(new Date(entry.timestamp), { addSuffix: true })}
                                            </>
                                        }
                                    />
                                </ListItem>
                                
                                {index < history.length - 1 && <Divider variant="inset" component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Box>

            {/* Snapshot Dialog */}
            <Dialog
                open={!!selectedSnapshot}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Document Snapshot
                    <IconButton
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                        disabled={restoringSnapshot}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {selectedSnapshot && (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="subtitle1">
                                    {new Date(selectedSnapshot.timestamp).toLocaleString()} by {selectedSnapshot.user_name}
                                </Typography>
                                
                                {/* Display action type chip */}
                                <Chip 
                                    label={getActionLabel(selectedSnapshot.action)} 
                                    color={selectedSnapshot.action === 'status_change' ? 'primary' : 'default'} 
                                    size="small"
                                />
                            </Box>
                            
                            {/* Status change information */}
                            {statusChangeInfo && (
                                <Box sx={{ 
                                    mb: 2, 
                                    p: 2, 
                                    bgcolor: 'rgba(0, 0, 0, 0.04)', 
                                    borderRadius: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    flexWrap: 'wrap'
                                }}>
                                    <Typography variant="body2" fontWeight="bold">
                                        {getLocalizedText(STATUS_TRANSITION, 'changed')}:
                                    </Typography>
                                    
                                    <Chip 
                                        label={getStatusLabel(statusChangeInfo.oldStatus)} 
                                        size="small"
                                        color={getStatusColor(statusChangeInfo.oldStatus)}
                                        sx={{ minWidth: 100 }}
                                    />
                                    
                                    <ArrowForwardIcon fontSize="small" />
                                    
                                    <Chip 
                                        label={getStatusLabel(statusChangeInfo.newStatus)} 
                                        size="small"
                                        color={getStatusColor(statusChangeInfo.newStatus)}
                                        sx={{ minWidth: 100 }}
                                    />
                                </Box>
                            )}
                            
                            <Box sx={{ mt: 0 }}>
                                <SnapshotViewer
                                    content={
                                        statusChangeInfo 
                                            ? parsedSnapshot.content 
                                            : selectedSnapshot.content_snapshot
                                    }
                                    showRaw={showRawHtml}
                                    onToggleView={handleToggleViewMode}
                                />
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        disabled={restoringSnapshot}
                    >
                        Close
                    </Button>
                    <Button
                        startIcon={restoringSnapshot ? <CircularProgress size={20} /> : <RestoreIcon />}
                        onClick={() => handleRestore(selectedSnapshot)}
                        variant="contained"
                        disabled={restoringSnapshot}
                    >
                        {restoringSnapshot ? 'Restoring...' : getActionLabel('restore')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={notification.open}
                autoHideDuration={4000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification.severity}
                    sx={{ width: '100%' }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default History;