import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  Paper,
  Box,
  TextField,
  CircularProgress,
  Stack,
  Divider,
  Typography,
  Button,
  ClickAwayListener
} from '@mui/material';

// Icons
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import TranslateIcon from '@mui/icons-material/Translate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { backendClient } from '../../../../api/backend';
import { useTranslation } from 'react-i18next';

const AiToolbarButton = forwardRef(({ editor, contractId }, ref) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [aiInput, setAiInput] = useState('');
  const [isAiProcessing, setIsAiProcessing] = useState(false);
  const [aiError, setAiError] = useState(null);
  const [showValidation, setShowValidation] = useState(false);
  const [lastChangeRange, setLastChangeRange] = useState(null);
  const [lastOperation, setLastOperation] = useState(null);
  const [lastPrompt, setLastPrompt] = useState(null);
  const [originalText, setOriginalText] = useState(null);
  const [selectionCoords, setSelectionCoords] = useState(null);

  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const toolbarRef = useRef(null);

  const isTextSelected = editor && editor.state.selection && !editor.state.selection.empty;

  // Effect to update toolbar position on scroll
  useEffect(() => {
    if (!isOpen || !editor) return;

    const handleScroll = () => {
      if (selectionCoords) {
        updateToolbarPosition();
      }
    };

    // Find and attach scroll listeners to all scrollable containers
    const scrollContainers = findScrollContainers(editor.view.dom);
    scrollContainers.forEach(container => {
      container.addEventListener('scroll', handleScroll, { passive: true });
    });

    // Also handle window resize
    window.addEventListener('resize', handleScroll, { passive: true });

    return () => {
      // Clean up event listeners
      scrollContainers.forEach(container => {
        container.removeEventListener('scroll', handleScroll);
      });
      window.removeEventListener('resize', handleScroll);
    };
  }, [isOpen, editor, selectionCoords]);

  // Helper to find all scrollable containers
  const findScrollContainers = (element) => {
    const containers = [];
    let current = element;

    while (current && current !== document.body) {
      const style = window.getComputedStyle(current);
      const overflow = style.overflow + style.overflowY + style.overflowX;

      if (/(auto|scroll)/.test(overflow)) {
        containers.push(current);
      }

      current = current.parentElement;
    }

    // Always add window for global scrolling
    containers.push(window);

    return containers;
  };

  // Update toolbar position based on selection
  const updateToolbarPosition = () => {
    if (!editor || !editor.view || !selectionCoords) return;

    try {
      const { state, view } = editor;
      const { selection } = state;

      if (!selection.empty) {
        // Get coordinates for text selection
        const { from, to } = selection;
        const start = view.coordsAtPos(from);
        const end = view.coordsAtPos(to);

        // Calculate position centered under the selection
        const left = Math.min(start.left, end.left);
        const right = Math.max(start.right, end.right);
        const bottom = Math.max(start.bottom, end.bottom);

        // Store selection coordinates for future updates
        const newCoords = {
          left: left + (right - left) / 2,
          bottom: bottom
        };

        // Calculate toolbar width for centering
        const toolbarWidth = toolbarRef.current ? toolbarRef.current.offsetWidth : 320;

        // Set position with viewport-relative coordinates
        setToolbarPosition({
          left: newCoords.left - (toolbarWidth / 2),
          top: newCoords.bottom + 8
        });

        // Update stored coordinates
        setSelectionCoords(newCoords);
      }
    } catch (error) {
      console.error('Error updating toolbar position:', error);
    }
  };

  // Handle clicking outside to close toolbar
  const handleClickAway = (event) => {
    // Don't close if clicking the button itself
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return;
    }

    // If we're in validation state, automatically accept changes when clicking away
    if (showValidation) {
      handleAcceptChanges();
    } else {
      // Otherwise just close normally
      handleClose();
    }
  };


  // Expose methods via ref for external control
  useImperativeHandle(ref, () => ({
    click: () => handleButtonClick(),
    openAtSelectionPosition: () => openAtSelection()
  }));

  if (!editor) return null;

  // Function to open toolbar at current text selection
  const openAtSelection = () => {
    if (!editor || !editor.view) return;

    const { state, view } = editor;
    const { selection } = state;

    // Only proceed if there's a text selection
    if (selection.empty) return;

    // Get coordinates for toolbar placement
    const { from, to } = selection;
    const start = view.coordsAtPos(from);
    const end = view.coordsAtPos(to);

    // Calculate position for toolbar (centered under selection)
    const left = Math.min(start.left, end.left);
    const right = Math.max(start.right, end.right);
    const bottom = Math.max(start.bottom, end.bottom);

    // Store selection coordinates
    setSelectionCoords({
      left: left + (right - left) / 2,
      bottom: bottom
    });

    // Initial toolbar position
    setToolbarPosition({
      left: left + (right - left) / 2 - 160, // Assuming 320px width, center it
      top: bottom + 8
    });

    // Open the toolbar
    setIsOpen(true);

    // Focus input after a brief delay
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 10);
  };

  // Get cursor coordinates for positioning
  const getCursorCoordinates = () => {
    if (!editor || !editor.view) return null;

    const { state, view } = editor;
    const { selection } = state;
    const { to } = selection;

    try {
      const coords = view.coordsAtPos(to);
      return {
        left: coords.left,
        bottom: coords.bottom
      };
    } catch (error) {
      console.error('Error getting cursor coordinates:', error);
      return null;
    }
  };

  // Handle AI button click
  const handleButtonClick = () => {
    // Toggle toolbar state if already open
    if (isOpen) {
      handleClose();
      return;
    }

    // Get position from selection or cursor
    const cursorCoords = getCursorCoordinates();

    if (cursorCoords) {
      // Store selection coordinates
      setSelectionCoords(cursorCoords);

      // Initial toolbar position
      setToolbarPosition({
        left: cursorCoords.left - 160, // Assuming 320px width, center it
        top: cursorCoords.bottom + 8
      });

      // Open the toolbar
      setIsOpen(true);

      // Focus input after opening
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 10);
    }
  };

  // Close the toolbar and reset state
  const handleClose = () => {
    setIsOpen(false);
    setAiInput('');
    setAiError(null);
    setShowValidation(false);
    setLastChangeRange(null);
    setLastOperation(null);
    setLastPrompt(null);
    setOriginalText(null);
    setSelectionCoords(null);
  };

  // Handle AI prompt submission
  const handleAiSubmit = async () => {
    if (!aiInput.trim()) return;
    await processAiOperation('custom', aiInput);
  };

  // Highlight added text
  const highlightAddedText = (from, to) => {
    // Save the range of the change
    setLastChangeRange({ from, to });

    try {
      // Get the text to highlight
      const addedText = editor.state.doc.textBetween(from, to);

      // Use the extension to highlight if it exists
      if (editor.commands.highlightRiskText) {
        editor.commands.highlightRiskText(addedText, '#8e24aa'); // Purple color for highlight
      } else {
        console.log('RiskHighlight extension not found.');
      }
    } catch (error) {
      console.error('Error highlighting added text:', error);
    }
  };

  // Handle accepting AI changes
  const handleAcceptChanges = () => {
    // Clear any highlight
    if (editor.commands.clearRiskHighlight) {
      editor.commands.clearRiskHighlight();
    }

    // Reset validation state
    setShowValidation(false);

    // Close the toolbar
    handleClose();
  };

  // Handle rejecting AI changes
  const handleRejectChanges = () => {
    try {
      // Restore original text if we have the information
      if (lastChangeRange && originalText !== null) {
        const { from, to } = lastChangeRange;

        // Remove the AI-generated content
        editor.chain().focus()
          .deleteRange({ from, to })
          .run();

        // Then insert the original text at the same position
        editor.chain().focus()
          .insertContentAt(from, originalText)
          .run();
      } else {
        // Fallback to general undo
        editor.commands.undo();
      }

      // Clear any highlight
      if (editor.commands.clearRiskHighlight) {
        editor.commands.clearRiskHighlight();
      }

      // Reset validation state
      setShowValidation(false);

      // Close the toolbar
      handleClose();
    } catch (error) {
      console.error('Error rejecting changes:', error);
    }
  };

  // Generate a new AI response
  const handleRefreshChanges = async () => {
    try {
      // Indicate processing is starting
      setIsAiProcessing(true);

      // Clear previous highlight
      if (editor.commands.clearRiskHighlight) {
        editor.commands.clearRiskHighlight();
      }

      // If we have the original text and operation, generate a new response
      if (originalText && lastOperation) {
        // Call the backend API with the original text to generate a new response
        const response = await backendClient.processPrompt(
          originalText,
          lastOperation,
          lastOperation === 'custom' ? lastPrompt : null,
          contractId
        );

        // Get the newly processed text
        const result = response.processed_text;

        // Only replace the content with the new result after we have it
        if (lastChangeRange) {
          const { from, to } = lastChangeRange;

          // Replace the existing content with the new result
          // (instead of deleting first and then inserting)
          editor.chain().focus()
            .deleteRange({ from, to })
            .insertContentAt(from, result)
            .run();

          // Highlight the newly added text
          highlightAddedText(from, from + result.length);
        }
      }
    } catch (error) {
      console.error('Error refreshing changes:', error);
      setAiError(`Error: ${error.message || 'Failed to generate new response'}`);
    } finally {
      setIsAiProcessing(false);
    }
  };

  // Process AI operation
  const processAiOperation = async (operationType, customPrompt = '') => {
    setIsAiProcessing(true);
    setAiError(null);
    setShowValidation(false);

    // Store operation type and prompt for potential refresh/reject
    setLastOperation(operationType);
    setLastPrompt(customPrompt);

    try {
      // Get selected text
      const { from, to, empty } = editor.state.selection;
      let text = '';
      let selectedFrom = from;
      let selectedTo = to;

      if (!empty) {
        text = editor.state.doc.textBetween(from, to);
      } else {
        const $from = editor.state.selection.$from;
        selectedFrom = $from.start();
        selectedTo = $from.end();
        text = editor.state.doc.textBetween(selectedFrom, selectedTo);
      }

      // Store the original text so we can restore it if rejected
      const originalTextValue = text;
      setOriginalText(originalTextValue);

      // Don't process empty text
      if (!text.trim()) {
        setAiError('Please select text to process or position your cursor in a paragraph.');
        setIsAiProcessing(false);
        return;
      }

      // Call the backend API
      const response = await backendClient.processPrompt(
        text,
        operationType,
        operationType === 'custom' ? customPrompt : null,
        contractId
      );

      // Get the processed text from the response
      const result = response.processed_text;

      // Replace text and track the positions for highlighting
      let insertFrom, insertTo;

      if (!empty) {
        // When text is selected:
        editor.chain().focus().deleteSelection().insertContent(result).run();
        insertFrom = from;
        insertTo = from + result.length;
      } else {
        // When cursor is in a paragraph:
        editor.chain().focus()
          .deleteRange({ from: selectedFrom, to: selectedTo })
          .insertContentAt(selectedFrom, result)
          .run();
        insertFrom = selectedFrom;
        insertTo = selectedFrom + result.length;
      }

      // Highlight the added text
      highlightAddedText(insertFrom, insertTo);

      // Update position after text changes
      updateToolbarPosition();

      // Show validation options
      setShowValidation(true);
    } catch (error) {
      console.error('AI operation failed:', error);
      setAiError(`Error: ${error.message || 'Failed to process text'}`);
    } finally {
      setIsAiProcessing(false);
    }
  };

  // AI action button with tooltip
  const AiAction = ({ tooltip, icon, action, disabled }) => (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          size="small"
          onClick={() => processAiOperation(action)}
          disabled={disabled || isAiProcessing}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              color: 'primary.main',
              bgcolor: 'action.hover'
            }
          }}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Tooltip title="AI Assistant (⌘K)">
        <IconButton
          disabled={!isTextSelected}
          ref={buttonRef}
          onClick={handleButtonClick}
          size="small"
          color="primary"
          sx={{ p: 0.5 }}
        >
          <AutoAwesomeIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{
            position: 'fixed',
            zIndex: 900,
            left: `${toolbarPosition.left}px`,
            top: `${toolbarPosition.top}px`,
            width: 320,
            borderRadius: '8px',
            border: '1px solid #f3e5f5', // Very visible border
            backgroundColor: 'white',
            overflow: 'hidden',
            padding: '2px'
          }}>
            <Paper
              ref={toolbarRef}
              elevation={3}
              sx={{
                zIndex: 900,
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
                border: '1px solid rgba(0, 0, 0, 0.12)'
              }}
            >
              {showValidation ? (
                // Show validation UI after AI response
                <Box sx={{
                  p: 1.5,
                  borderRadius: 2,
                  bgcolor: 'rgba(250, 250, 252, 0.8)',
                  backdropFilter: 'blur(8px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
                  maxWidth: '100%'
                }}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1.5,
                      fontWeight: 500,
                      fontSize: '0.875rem',
                      color: '#1A1A1D',
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {t('changes_ai_msg')}
                  </Typography>
                  <Stack direction="row" spacing={1.5} justifyContent="space-between">
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<CloseIcon fontSize="small" />}
                      onClick={handleRejectChanges}
                      sx={{
                        flex: "1 1 0",
                        borderColor: 'rgba(0, 0, 0, 0.08)',
                        color: '#1A1A1D',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontSize: '0.8125rem',
                        fontWeight: 500,
                        padding: '4px 10px',
                        '&:hover': {
                          borderColor: 'rgba(0, 0, 0, 0.15)',
                          bgcolor: 'rgba(0, 0, 0, 0.02)'
                        }
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<RefreshIcon fontSize="small" />}
                      onClick={handleRefreshChanges}
                      disabled={isAiProcessing}
                      sx={{
                        flex: "1 1 0",
                        borderColor: 'rgba(0, 0, 0, 0.08)',
                        color: '#1A1A1D',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontSize: '0.8125rem',
                        fontWeight: 500,
                        padding: '4px 10px',
                        '&:hover': {
                          borderColor: 'rgba(0, 0, 0, 0.15)',
                          bgcolor: 'rgba(0, 0, 0, 0.02)'
                        },
                        '&.Mui-disabled': {
                          borderColor: 'rgba(0, 0, 0, 0.05)',
                          color: 'rgba(0, 0, 0, 0.3)'
                        }
                      }}
                    >
                      Refresh
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<CheckIcon fontSize="small" />}
                      onClick={handleAcceptChanges}
                      sx={{
                        flex: "1 1 0",
                        bgcolor: 'black',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontSize: '0.8125rem',
                        fontWeight: 500,
                        padding: '4px 10px',
                        '&:hover': {
                          bgcolor: '#333333'
                        },
                        boxShadow: 'none',
                        transition: 'all 0.2s ease'
                      }}
                    >
                      Accept
                    </Button>
                  </Stack>
                </Box>
              ) : (
                // Show original input UI when no AI response yet
                <Box sx={{ p: 1 }}>
                  <TextField
                    fullWidth
                    inputRef={inputRef}
                    variant="outlined"
                    placeholder="Ask AI..."
                    value={aiInput}
                    onChange={(e) => setAiInput(e.target.value)}
                    disabled={isAiProcessing}
                    size="small"
                    InputProps={{
                      sx: { pr: 0.5, fontSize: '0.875rem' },
                      endAdornment: (
                        <IconButton
                          color="primary"
                          size="small"
                          disabled={!aiInput.trim() || isAiProcessing}
                          onClick={handleAiSubmit}
                          sx={{ ml: 0.5 }}
                        >
                          <SendIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleAiSubmit();
                      }
                    }}
                  />

                  {aiError && (
                    <Typography variant="caption" color="error" sx={{ display: 'block', mt: 0.5 }}>
                      {aiError}
                    </Typography>
                  )}
                </Box>
              )}

              {!showValidation && (
                <>
                  <Divider />
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ p: 0.5, flexWrap: 'wrap', justifyContent: 'space-between' }}
                  >
                    <AiAction
                      tooltip={t('improve')}
                      icon={<AutoFixHighIcon fontSize="small" />}
                      action="improve"
                      disabled={!isTextSelected}
                    />
                    <AiAction
                      tooltip={t('spelling')}
                      icon={<SpellcheckIcon fontSize="small" />}
                      action="spelling"
                      disabled={!isTextSelected}
                    />
                    <AiAction
                      tooltip={t('shorter')}
                      icon={<CompressIcon fontSize="small" />}
                      action="shorter"
                      disabled={!isTextSelected}
                    />
                    <AiAction
                      tooltip={t('longer')}
                      icon={<ExpandIcon fontSize="small" />}
                      action="longer"
                      disabled={!isTextSelected}
                    />
                    <AiAction
                      tooltip={t('translate')}
                      icon={<TranslateIcon fontSize="small" />}
                      action="translate"
                      disabled={!isTextSelected}
                    />
                    <AiAction
                      tooltip={t('summarize')}
                      icon={<SummarizeIcon fontSize="small" />}
                      action="summarize"
                      disabled={!isTextSelected}
                    />
                  </Stack>
                </>
              )}

              {isAiProcessing && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  <Typography variant="caption">{t('processingText')}</Typography>
                </Box>
              )}
            </Paper>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
});

export default AiToolbarButton;