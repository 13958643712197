import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Chip
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { useDispatch, useSelector } from "react-redux";
import { getSuggestedClauses } from "../../../../redux/actions/contractAction";
import { useTranslation } from "react-i18next";
import { formatHtmlForDisplay } from "../../../../utils/utils";
import EditorContext from "../../../../contexts/EditorContext";

const Suggestion = ({ contractId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const clauses = useSelector((state) => state.contract.suggestedClauses || []);
  const loadingSuggestedClause = useSelector((state) => state.contract.loadingSuggestedClause);
  const hasSuggestions = clauses && clauses.length > 0;
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const isRTL = i18n.language === 'ar';
  // Get access to the editor through EditorContext
  const editorRef = useContext(EditorContext);

  const handleGetSuggestions = () => {
    if (contractId) {
      dispatch(getSuggestedClauses(contractId));
    }
  };

  const handleAddClause = (clause) => {
    try {
      // Get the editor instance from the ref
      const editor = editorRef?.current?.getEditor();

      if (!editor) {
        console.error("Editor reference not available");
        setNotification({
          open: true,
          message: t("suggestions.editorNotAvailable"),
          severity: 'error'
        });
        return;
      }

      // We'll create the content in the simpler approach below

      // Create content to append with a newline at the beginning
      const clauseContent = `
        <h3>${clause.title}</h3>
        <p>${clause.description}</p>
      `;

      // A simpler approach to add content to the end of the document
      const currentContent = editor.getHTML();

      // Add a paragraph break if the document is not empty
      const contentToInsert = currentContent && currentContent.trim() !== ''
        ? `${currentContent}<p></p>${clauseContent}`
        : clauseContent;

      // Set the entire content
      editor.commands.setContent(contentToInsert);

      // Move cursor to the end and focus
      editor.commands.focus('end');

      // Show success notification
      setNotification({
        open: true,
        message: t("suggestions.clauseAdded"),
        severity: 'success'
      });
    } catch (error) {
      console.error("Error adding clause to editor:", error);
      setNotification({
        open: true,
        message: t("suggestions.errorAddingClause"),
        severity: 'error'
      });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%", // Full height
        overflowY: "auto",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        direction: isRTL ? 'rtl' : 'ltr',
        textAlign: isRTL ? 'right' : 'left'
      }}
    >
      {/* Added Section Title and Subtitle */}
      <Box sx={{ mb: 1 }}>
        <Chip
          label={t("riskAnalysis.chip")}
          size="small"
          sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            fontWeight: 500,
            fontSize: '0.75rem',
            mb: 0.5
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#212121", mb: 0 }}>
          {t("suggestions.title")}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575" }}>
          {t("suggestions.subtitle")}
        </Typography>
      </Box>

      <Button
        variant="contained"
        startIcon={hasSuggestions ? <RefreshIcon /> : <LightbulbOutlinedIcon />}
        onClick={handleGetSuggestions}
        disabled={loadingSuggestedClause}
        sx={{
          background: "#1a1a1a",
          color: "#fff",
          borderRadius: 2,
          mb: 2,
          width: "100%",
          '&:hover': {
            background: "#333333"
          }
        }}
      >
        {hasSuggestions ? t("suggestions.refresh") : t("suggestions.getClauseSuggestions")}
      </Button>

      {loadingSuggestedClause && (
        <Box sx={{ textAlign: 'center', py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          <Typography variant="body2">{t("analyzingDocument")}</Typography>
        </Box>
      )}

      {clauses.length > 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingX: 0.5, }}>
          {clauses.map((clause, index) => (
            <Accordion
              key={index}
              disableGutters
              sx={{
                width: "100%",
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
                transition: "box-shadow 0.2s ease-in-out",
                '&:before': {
                  display: 'none'
                },
                '&:hover': {
                  boxShadow: "0 4px 12px rgba(0,0,0,0.12)"
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ px: 2 }}
              >
                <Typography sx={{ flex: 1, fontWeight: "600" }}>{clause.title}</Typography>
                <Tooltip title={t("suggestions.addClause")} arrow placement="top">
                  <IconButton
                    size="small"
                    sx={{ color: "primary.main", mr: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();  // Prevent accordion from toggling
                      handleAddClause(clause);
                    }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={clause.definition} arrow placement="top">
                  <IconButton
                    size="small"
                    sx={{ color: "text.secondary" }}
                    onClick={(e) => e.stopPropagation()}  // Prevent accordion from toggling
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0, backgroundColor: "rgba(0,0,0,0.01)" }}>
                <Typography variant="body2" sx={{ lineHeight: 1.6, textAlign: "left" }}>
                  {formatHtmlForDisplay(clause.description)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ) : !loadingSuggestedClause && (
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 6,
          color: "text.secondary"
        }}>
          <LightbulbOutlinedIcon sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
          <Typography variant="body1">{t("suggestions.clickAboveGetClauseSuggestions")}</Typography>
        </Box>
      )}

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Suggestion;