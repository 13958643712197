import React from 'react';
import { Box, Typography } from '@mui/material';

// Colors from SettingsScreen
const colors = {
  primary: "#0EA5E9",
  primaryLight: "#EAECFF",
  text: "#1F2937",
  textSecondary: "#6B7280",
};

const SectionHeader = ({ icon, title, description }) => {
  return (
    <Box sx={{ mb: description ? 3 : 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon && (
          <Box
            sx={{
              mr: 2,
              p: 1.5,
              borderRadius: '12px',
              bgcolor: colors.primaryLight,
              color: colors.primary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Box>
        )}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: colors.text,
            fontSize: { xs: '1.25rem', sm: '1.5rem' }
          }}
        >
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography
          variant="body1"
          sx={{
            color: colors.textSecondary,
            ml: icon ? 7 : 0,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default SectionHeader;