import { MESSAGE_STATUS, ROLE, languages } from "../../utils/constants";

// Helper functions
const convertNewlinesToBreaks = (text) => {
  if (!text) return "";
  return text.replace(/\n/g, "<br/>");
};

const saveLanguage = (languageCode) => {
  localStorage.setItem("languageCode", languageCode);
};

// Action types
export const CLEAR_MESSAGES = "messages/CLEAR_MESSAGES";
export const EDIT_MESSAGE = "messages/EDIT_MESSAGE";
export const CHECK_MESSAGE = "messages/CHECK_MESSAGE";
export const SET_MESSAGES = "messages/SET_MESSAGES";
export const USER_SEND_MESSAGE = "messages/USER_SEND_MESSAGE";
export const SYSTEM_SEND_MESSAGE = "messages/SYSTEM_SEND_MESSAGE";
export const SET_LANGUAGE = "messages/SET_LANGUAGE";

// Action creators
export const messageActions = {

  clearMessages: () => ({ type: CLEAR_MESSAGES }),


  editMessage: (messageId, content) => ({
    type: EDIT_MESSAGE,
    payload: { messageId, content },
  }),

  checkMessage: (messageId) => ({
    type: CHECK_MESSAGE,
    payload: messageId,
  }),

  setMessages: (messages) => ({
    type: SET_MESSAGES,
    payload: messages.map(msg => ({
      ...msg,
      content: convertNewlinesToBreaks(msg.content)
    })),
  }),

  userSendMessage: (content, files = []) => {
    const fileSources = files?.length > 0
      ? files.map((file) => ({
        metadata: {
          file_name: file.fileName,
          original_file_name: file.originalFileName,
          url: file.fileUrl,
          doc_type: file.docType || "unknown",
          language: file.fileLanguage || "unknown",
        },
      }))
      : [];

    return {
      type: USER_SEND_MESSAGE,
      payload: {
        message: {
          id: Math.random().toString(),
          content: convertNewlinesToBreaks(content),
          role: ROLE.USER,
          status: MESSAGE_STATUS.PENDING,
          created_at: new Date(),
          sources: fileSources,
        }
      }
    };
  },




  systemSendMessage: (message) => ({
    type: SYSTEM_SEND_MESSAGE,
    payload: {
      ...message,
      content: convertNewlinesToBreaks(message.content)
    },
  }),

  setLanguage: (language) => {
    let langCode = "fr";
    if (["ar", "en", "fr"].includes(language)) {
      langCode = language;
    }
    saveLanguage(langCode);

    return {
      type: SET_LANGUAGE,
      payload: languages[langCode]
    };
  }
};

