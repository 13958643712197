import React, { memo, useRef, useState, useEffect } from 'react'
import { CircularProgress, Box, Typography } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ViewPdf from '../pdf-viewer/ViewPdf'
import TranslationProgress from './components/TranslationProgress'
import ViewHtml from '../html-viewer/ViewHtml'

const DocumentViewer = memo(
  ({ document, documentSourceUrl, isLoadingDocument, selectedCitation, setDocument }) => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language

    const isTranslationInProgress = useSelector(
      state => state.document.isTranslationInProgress
    )
    const translationProgress = useSelector(
      state => state.document.translationProgress
    )

    const iframeRef = useRef(null)

    const [fileType, setFileType] = useState('pdf')
    const [htmlContent, setHtmlContent] = useState('')

    useEffect(() => {
      const fetchHtmlDocumentContent = async () => {
        const response = await fetch(document?.url)
        const content = await response.text()
        setHtmlContent(content)
      }

      if (document?.url) {
        // Get the file extension
        const extension = document?.url.split('?')[0].endsWith('pdf')
          ? 'pdf'
          : 'html'

        setFileType(extension)

        if (extension === 'html') {
          fetchHtmlDocumentContent()
        }
      }
    }, [document?.url])

    return (
      <Box
        dir={currentLang === 'ar' ? 'rtl' : 'ltr'} // Dynamically set direction
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        {/* Document Header - Added as requested */}
        <Box sx={{
          p: 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          backgroundColor: '#ffffff'
        }}>
          <Typography variant="h8" fontWeight={600} color="#334e68">
          {document ? selectedCitation?.metadata?.long_title : "-"}
          </Typography>
        </Box>

        {/* Document Content */}
        <Box
          sx={{
            flexGrow: 1,
            px: '8px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {isLoadingDocument ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 10
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 4
                }}
              >
                <CircularProgress size={50} color="primary" />
                {isTranslationInProgress && (
                  <TranslationProgress
                    currentProgress={translationProgress}
                  />
                )}
              </Box>
            </Box>
          ) : (
            <>
              {document?.url ? (
                fileType === 'pdf' ? (
                  <div key={document?.id} className='w-[100%]' style={{ height: '100%' }}>
                    <ViewPdf
                      key={document?.id}
                      document={document}
                      documentSourceUrl={documentSourceUrl}
                      selectedCitation={selectedCitation}
                      setDocument={setDocument}
                    />
                  </div>
                ) : (
                  <ViewHtml
                    currentLang={currentLang}
                    htmlContent={htmlContent}
                    iframeRef={iframeRef}
                    selectedCitation={selectedCitation}
                    document={document}
                    setDocument={setDocument}
                  />
                )
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    bgcolor: '#f8fafc',
                    borderRadius: '8px'
                  }}
                  dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
                >
                  <Logo
                    style={{
                      width: '120px',
                      height: '120px',
                      opacity: 0.8
                    }}
                  />
                  <Box
                    sx={{
                      marginTop: 2,
                      fontSize: '16px',
                      color: '#4a5568',
                      textAlign: 'center',
                      maxWidth: '80%'
                    }}
                  >
                    {t('choose_document')}
                    {/* <br />
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        mt: 1, 
                        color: '#718096',
                        fontSize: '0.9rem' 
                      }}
                    >
                      {t('choose_document_details')}
                    </Typography> */}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    // Prevent re-render if props haven't changed
    return (
      prevProps.document === nextProps.document &&
      prevProps.documentSourceUrl === nextProps.documentSourceUrl &&
      prevProps.isLoadingDocument === nextProps.isLoadingDocument &&
      prevProps.selectedCitation === nextProps.selectedCitation
    )
  }
)

export default DocumentViewer