// Updated RightPanel.js
import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip
} from "@mui/material";
import {
  PictureAsPdf as PdfIcon,
  Description as DocxIcon,
  History as HistoryIcon
} from "@mui/icons-material";
import { useSelector } from 'react-redux';
import {
  Assessment,
  Info,
  Lightbulb,
  Group,
} from "@mui/icons-material";
import DrawIcon from '@mui/icons-material/Draw';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { windowWidth } from "../../../utils/utils";
import RiskAnalysis from "./RiskAnalysis/RiskAnalysis";
import Explanation from "./Explanation/Explanation";
import Collaboration from "./Collaborator/Collaboration";
import Suggestion from "./Suggestion/Suggestion";
import Signature from "./Signature/Signature";
import ChatBot from "./ChatBot/ChatBot";
import HistoryComponent from "./History/History";
import { backendClient } from "../../../api/backend";
import EditorContext from "../../../contexts/EditorContext";
import DownloadDialog from "../dialogs/DownloadDialog";



const RightPanel = ({ contractId }) => {
  const [activeView, setActiveView] = useState("chatBot");
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  // Grab userId from auth slice
  const userId = useSelector(state => state.auth.user?.user?.id);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        pb: 1,
        pt: 1,
        textAlign: "center"
      }}
    >
      {/* Buttons for History and Download */}
      <Box sx={{
        display: "flex",
        alignItems: "center",
        gap: windowWidth / 100,
        justifyContent: "center",
        mb: 1
      }}>
        <Button
          variant="text"
          startIcon={<HistoryIcon />}
          onClick={() => setActiveView("history")}
          sx={{
            color: activeView === "history" ? "primary.main" : "black",
            fontWeight: "bold",
            textTransform: "none",
            width: "-webkit-fill-available",
          }}
        >
          History
        </Button>

        <Button
          variant="contained"
          onClick={() => setDownloadDialogOpen(true)}
          sx={{
            backgroundColor: "black",
            width: "-webkit-fill-available",
            color: "white",
            textTransform: "none",
            borderRadius: 1,
            "&:hover": { backgroundColor: "#333" }
          }}
        >
          Télécharger
        </Button>
      </Box>

      <Box sx={{
        p: 2,
        borderRadius: 2,
        boxShadow: 2,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 48px)' // Subtracting the height of the top buttons area
      }}>
        {/* Buttons to change view */}
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          mb: 2
        }}>
          <Tooltip title="Conversation">
            <IconButton
              sx={{ color: activeView === "chatBot" ? "#000" : "gray" }}
              onClick={() => setActiveView("chatBot")}
            >
              <AutoAwesomeIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Analyse des risques">
            <IconButton
              sx={{ color: activeView === "riskAnalysis" ? "#000" : "gray" }}
              onClick={() => setActiveView("riskAnalysis")}
            >
              <Assessment />
            </IconButton>
          </Tooltip>

          <Tooltip title="Extraction des clauses">
            <IconButton
              sx={{ color: activeView === "explanation" ? "#000" : "gray" }}
              onClick={() => setActiveView("explanation")}
            >
              <Info />
            </IconButton>
          </Tooltip>

          <Tooltip title="Suggestion des clauses">
            <IconButton
              sx={{ color: activeView === "suggestion" ? "#000" : "gray" }}
              onClick={() => setActiveView("suggestion")}
            >
              <Lightbulb />
            </IconButton>
          </Tooltip>

          <Tooltip title="Collaboration">
            <IconButton
              sx={{ color: activeView === "collaboration" ? "#000" : "gray" }}
              onClick={() => setActiveView("collaboration")}
            >
              <Group />
            </IconButton>
          </Tooltip>

          <Tooltip title="Signature">
            <IconButton
              sx={{ color: activeView === "signature" ? "#000" : "gray" }}
              onClick={() => setActiveView("signature")}
            >
              <DrawIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Display content */}
        <Box
          sx={{
            flexGrow: 1,
            width: "420px", // Fixed width
            minWidth: "420px",
            p: 0,
            display: "flex",
            flexDirection: "column",
            height: 'calc(100% - 48px)', // Subtracting the height of the view buttons
            overflow: 'hidden' // Changed from 'auto' to 'hidden' to let children handle scrolling
          }}
        >
          {activeView === "chatBot" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <ChatBot
                contractId={contractId}
                userId={userId}
                isVisible={activeView === "chatBot"}
              />
            </Box>
          )}
          {activeView === "riskAnalysis" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <RiskAnalysis contractId={contractId} />
            </Box>
          )}
          {activeView === "explanation" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <Explanation contractId={contractId} />
            </Box>
          )}
          {activeView === "suggestion" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <Suggestion contractId={contractId}/>
            </Box>
          )}
          {activeView === "collaboration" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <Collaboration contractId={contractId} />
            </Box>
          )}
          {activeView === "signature" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <Signature contractId={contractId} />
            </Box>
          )}
          {activeView === "history" && (
            <Box sx={{ height: '100%', overflow: 'hidden' }}>
              <HistoryComponent />
            </Box>
          )}
        </Box>
      </Box>

      {/* Download Dialog */}
      <DownloadDialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        contractId={contractId}
      />
    </Box>
  );
};

export default RightPanel;