import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./router/AppRouter";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import the initialized i18n instance
import { SubscriptionProvider } from "./contexts/SubscriptionProvider";
// import { JoyrideProvider } from "./contexts/JoyrideContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ZoomProvider } from "./contexts/ZoomProvider";


const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <ZoomProvider> */}
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Provider store={store}>
            <SubscriptionProvider>
              <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18n}>
                  {/* <JoyrideProvider> */} <AppRouter /> {/* </JoyrideProvider> */}
                </I18nextProvider>
              </PersistGate>
            </SubscriptionProvider>
          </Provider>
        </SnackbarProvider>
      {/* </ZoomProvider> */}
    </LocalizationProvider>
  );
};

export default App;
