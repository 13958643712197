import React from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConversationPagination = ({ 
  totalCount, 
  page, 
  rowsPerPage, 
  handlePageChange,
  currentLang
}) => {
  const { t } = useTranslation();
  
  const pageCount = totalCount && Math.max(1, Math.ceil(parseInt(totalCount?.replace(/,/g, '')) / rowsPerPage));
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 3,
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 2, sm: 0 }
      }}
    >
      <Typography
        variant='body2'
        color='text.secondary'
        sx={{ 
          textAlign: { xs: 'center', sm: currentLang === 'ar' ? 'right' : 'left' }
        }}
      >
        {t('results_count_with_total', { count: totalCount })}
      </Typography>
      
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color='primary'
          shape="rounded"
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: '8px',
            },
            alignSelf: { xs: 'center', sm: currentLang === 'ar' ? 'flex-start' : 'flex-end' },
            ...(currentLang === 'ar' && {
              transform: 'scaleX(-1)',
              '& .MuiPaginationItem-root': {
                transform: 'scaleX(-1)'
              }
            })
          }}
        />
      )}
    </Box>
  );
};

export default ConversationPagination;