// src/components/RightPanel/History/SnapshotViewer.jsx
import React, { useRef, useEffect } from 'react';
import {
  Box, 
  Paper, 
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material';

const SnapshotViewer = ({ content, showRaw = false, onToggleView }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Only attempt to render HTML if we're not in raw mode and have content
    if (!showRaw && content && containerRef.current) {
      // Clear previous content
      containerRef.current.innerHTML = '';
      
      try {
        // Create an iframe to safely render the HTML
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '500px';
        iframe.style.border = 'none';
        
        containerRef.current.appendChild(iframe);
        
        // Access the iframe's document and write the HTML content
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
        
        // Adjust iframe height based on content
        iframe.onload = () => {
          const height = iframeDoc.body.scrollHeight;
          iframe.style.height = `${height + 20}px`;
        };
      } catch (error) {
        console.error('Error rendering HTML:', error);
        containerRef.current.innerHTML = '<div style="color: red;">Error rendering HTML content</div>';
      }
    }
  }, [content, showRaw]);

  if (!content) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography color="text.secondary">No content snapshot available</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <FormControlLabel
          control={
            <Switch 
              checked={showRaw} 
              onChange={onToggleView} 
              name="viewMode" 
            />
          }
          label="View Raw HTML"
        />
      </Box>
      
      {showRaw ? (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            bgcolor: '#f5f5f5',
            borderRadius: 1,
            maxHeight: '500px',
            overflow: 'auto'
          }}
        >
          <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
            {content}
          </Typography>
        </Paper>
      ) : (
        <Paper
          elevation={1}
          sx={{ 
            p: 1, 
            border: '1px solid #e0e0e0',
            borderRadius: 1
          }}
        >
          <Box ref={containerRef} />
        </Paper>
      )}
    </>
  );
};

export default SnapshotViewer;