import React, { useState, useEffect } from "react";
import {
  Box, Typography, Grid, TextField,
  CircularProgress, Button, Alert,
  Paper, IconButton, Tooltip, Chip,
  alpha, Card, CardContent, TableContainer,
  Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { backendClient } from "../../../../api/backend";
import { useSnackbar } from "notistack";

// Icons
import {
  CreditCard as CreditCardIcon,
  Receipt as ReceiptIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  InfoOutlined as InfoOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  GetApp as GetAppIcon
} from "@mui/icons-material";

// Components
import SectionHeader from "./SectionHeader";
import PaymentMethodCard from "./PaymentMethodCard";
import SubscriptionDetails from "./SubscriptionDetails";
import BillingInfoForm from "./BillingInfoForm";
import InvoiceTable from "./InvoiceTable";
import UserSpecificMessage from "./UserSpecificMessage";

// Colors from SettingsScreen
const colors = {
  primary: "#0EA5E9",
  primaryLight: "#EAECFF", 
  primaryDark: "#3730A3",
  secondary: "#10B981",
  secondaryLight: "#D1FAE5",
  background: "#F9FAFB",
  backgroundAlt: "#F3F4F6",
  surface: "#FFFFFF",
  error: "#EF4444",
  warning: "#F59E0B",
  info: "#3B82F6",
  text: "#1F2937",
  textSecondary: "#6B7280",
  border: "#E5E7EB",
  borderDark: "#D1D5DB",
  disabled: "#9CA3AF",
};

const BillingInfo = () => {
  const { t } = useTranslation();
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingBillingInfo, setEditingBillingInfo] = useState(false);
  const [updatedBillingInfo, setUpdatedBillingInfo] = useState({});
  const [savingBillingInfo, setSavingBillingInfo] = useState(false);
  const [downloadingInvoices, setDownloadingInvoices] = useState({});
  const [paymentProfile, setPaymentProfile] = useState(null);
  const [loadingPaymentProfile, setLoadingPaymentProfile] = useState(false);

  const user = useSelector((state) => state.auth.user.user);
  const { enqueueSnackbar } = useSnackbar();

  // Check user type and subscription status
  const isBillingAdmin = user?.roles?.includes("billing_admin");
  const isTrialUser = billingData?.subscription?.status === "trial";
  const isRegularUser = !isBillingAdmin && !isTrialUser && user?.roles?.includes("user");
  const showBillingAdmin = isBillingAdmin && !isTrialUser;

  const fetchPaymentProfile = async () => {
    if (!user || !user.organization_id || !isBillingAdmin) return;

    setLoadingPaymentProfile(true);
    try {
      const profile = await backendClient.getPaymentProfile();
      setPaymentProfile(profile);
      console.log("Payment profile loaded:", profile);
    } catch (err) {
      console.error("Failed to fetch payment profile:", err);
    } finally {
      setLoadingPaymentProfile(false);
    }
  };

  useEffect(() => {
    fetchPaymentProfile();
  }, [user]);

  const fetchData = async () => {
    try {
      if (user && user.organization_id) {
        if (isBillingAdmin) {
          const response = await backendClient.getOrganizationBillingInfo(user.organization_id);
          setBillingData(response);
          if (response.billing_info) {
            setUpdatedBillingInfo(response.billing_info);
          } else if (response.billing_history && response.billing_history.length > 0) {
            setUpdatedBillingInfo(response.billing_history[0]);
          }
        } else {
          const subscriptionData = await backendClient.getSubscriptionInfo(user.organization_id);
          setBillingData({ subscription: subscriptionData });
        }
      } else {
        setError("User is not associated with an organization");
      }
    } catch (err) {
      console.error("Failed to fetch billing data:", err);
      setError(err.message || "Failed to load billing information");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  // Format date to display in a user-friendly way
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  // Format credit card - show only last 4 digits
  const formatCardNumber = (token) => {
    if (!token) return "**** **** **** ****";
    return `**** **** **** ${token}`;
  };

  // Get status color
  const getStatusColor = (status) => {
    const statusMap = {
      'CHARGED': 'success',
      'PAID': 'success',
      'paid': 'success',
      'ACTIVE': 'success',
      'active': 'success',
      'SUPER': 'success',
      'DECLINED': 'error',
      'CANCELLED': 'warning',
      'PENDING': 'warning',
      'pending': 'warning',
      'trial': 'info'
    };

    return statusMap[status?.toLowerCase()] || 'default';
  };

  // Handle billing info field changes
  const handleBillingInfoChange = (field) => (event) => {
    setUpdatedBillingInfo({
      ...updatedBillingInfo,
      [field]: event.target.value
    });
  };

  // Toggle edit mode for billing info
  const toggleBillingInfoEdit = () => {
    if (editingBillingInfo) {
      // Save changes
      handleSaveBillingInfo();
    } else {
      // Enter edit mode
      setEditingBillingInfo(true);
    }
  };

  const handleDownloadInvoice = async (billingId) => {
    try {
      setDownloadingInvoices(prev => ({ ...prev, [billingId]: true }));
      await backendClient.downloadInvoice(billingId);
      enqueueSnackbar('Facture téléchargée avec succès', { variant: 'success' })
    } catch (error) {
      console.error('Failed to download invoice:', error);
      enqueueSnackbar('Échec du téléchargement de la facture', { variant: 'error' })
    } finally {
      setDownloadingInvoices(prev => ({ ...prev, [billingId]: false }));
    }
  };

  // Save billing info changes
  const handleSaveBillingInfo = async () => {
    try {
      setSavingBillingInfo(true);
      await backendClient.updateOrganizationBillingInfo(user.organization_id, updatedBillingInfo);
      
      // Update local state
      const updatedBillingData = { ...billingData };
      updatedBillingData.billing_info = updatedBillingInfo;
      setBillingData(updatedBillingData);
      
      // Exit edit mode
      setEditingBillingInfo(false);
      
      // Show success message
      enqueueSnackbar(t("billing_info_updated_success"), { variant: 'success' });
    } catch (err) {
      console.error("Failed to update billing information:", err);
      setError("Failed to update billing information. Please try again.");
      enqueueSnackbar(t("billing_info_update_failed"), { variant: 'error' });
    } finally {
      setSavingBillingInfo(false);
    }
  };

  // Cancel subscription
  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      const subscription = billingData?.subscription;

      if (subscription) {
        await backendClient.cancelOrganizationSubscription(user.organization_id);

        // Update local state - set subscription status to cancelled
        const updatedBillingData = { ...billingData };
        if (updatedBillingData.subscription) {
          updatedBillingData.subscription.status = "cancelled";
          setBillingData(updatedBillingData);
        }

        // Show success message
        enqueueSnackbar(t("subscription_cancelled_success"), { variant: 'success' });
      } else {
        throw new Error("Subscription not found");
      }
    } catch (err) {
      console.error("Failed to cancel subscription:", err);
      setError("Failed to cancel subscription. Please try again.");
      enqueueSnackbar(t("subscription_cancellation_failed"), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '50vh' 
      }}>
        <CircularProgress size={40} sx={{ color: colors.primary }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Card
        elevation={0}
        sx={{
          borderRadius: '16px',
          border: `1px solid ${colors.error}`,
          mb: 4,
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ p: 4, textAlign: 'center' }}>
          <Box
            sx={{
              width: 60,
              height: 60,
              borderRadius: '50%',
              bgcolor: alpha(colors.error, 0.1),
              color: colors.error,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
              mb: 2
            }}
          >
            <ErrorOutlineIcon fontSize="large" />
          </Box>
          
          <Typography color="error" variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
            {error}
          </Typography>
          
          <Button
            variant="contained"
            disableElevation
            sx={{
              bgcolor: colors.primary,
              color: '#fff',
              px: 4,
              py: 1.5,
              borderRadius: '10px',
              fontWeight: 600,
              textTransform: 'none',
              '&:hover': {
                bgcolor: colors.primaryDark,
              }
            }}
            onClick={() => {
              setError(null);
              setLoading(true);
              fetchData();
            }}
          >
            {t("retry")}
          </Button>
        </CardContent>
      </Card>
    );
  }

  // Show specific messages based on user type
  if (isTrialUser || isRegularUser) {
    return <UserSpecificMessage userType={isTrialUser ? "trial" : "regular"} />;
  }

  // Destructure data for easier access
  const { subscription, billing_info, billing_history } = billingData || {};
  const subscriptionPayload = subscription?.payload || {};

  // Get the billing info - either from the dedicated field or the first billing history entry
  const billingInfo = billing_info || (billing_history?.length > 0 ? billing_history[0] : {});

  // Organization name display
  const organizationName = user?.organization_name || billingInfo?.organization_name || '';

  return (
    <Box>
      {/* Payment Method Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          borderRadius: '16px', 
          border: `1px solid ${colors.border}`,
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <SectionHeader
            icon={<CreditCardIcon />}
            title={t("payment_information")}
          />
          
          <PaymentMethodCard
            loadingPaymentProfile={loadingPaymentProfile}
            paymentProfile={paymentProfile}
            subscription={subscription}
            formatCardNumber={formatCardNumber}
          />
        </CardContent>
      </Card>

      {/* Billing Information Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          borderRadius: '16px', 
          border: `1px solid ${colors.border}`,
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <SectionHeader
              icon={<ReceiptIcon />}
              title={t("billing_information")}
            />

            <Tooltip title={editingBillingInfo ? t("save") : t("modify")} arrow>
              <IconButton
                size="small"
                onClick={toggleBillingInfoEdit}
                disabled={savingBillingInfo}
                sx={{ 
                  bgcolor: editingBillingInfo ? alpha(colors.secondary, 0.1) : 'transparent',
                  '&:hover': {
                    bgcolor: editingBillingInfo ? alpha(colors.secondary, 0.2) : alpha(colors.primary, 0.1)
                  }
                }}
              >
                {editingBillingInfo ? (
                  <SaveIcon sx={{ color: savingBillingInfo ? colors.disabled : colors.secondary }} />
                ) : (
                  <EditIcon sx={{ color: colors.primary }} />
                )}
              </IconButton>
            </Tooltip>
          </Box>

          <BillingInfoForm
            updatedBillingInfo={updatedBillingInfo}
            editingBillingInfo={editingBillingInfo}
            handleBillingInfoChange={handleBillingInfoChange}
            colors={colors}
          />

          {/* Save button for mobile view */}
          {editingBillingInfo && (
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', mt: 3 }}>
              <Button
                variant="contained"
                disableElevation
                startIcon={<SaveIcon />}
                onClick={handleSaveBillingInfo}
                disabled={savingBillingInfo}
                sx={{
                  bgcolor: colors.primary,
                  color: '#fff',
                  px: 4,
                  py: 1.5,
                  borderRadius: '10px',
                  fontWeight: 600,
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: colors.primaryDark,
                  },
                  '&.Mui-disabled': {
                    bgcolor: alpha(colors.primary, 0.4),
                    color: '#fff',
                  }
                }}
              >
                {savingBillingInfo ? t("saving") : t("save_changes")}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Subscription Details Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          borderRadius: '16px', 
          border: `1px solid ${colors.border}`,
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <SubscriptionDetails
            subscription={subscription}
            subscriptionPayload={subscriptionPayload}
            billingInfo={billingInfo}
            formatDate={formatDate}
            handleCancelSubscription={handleCancelSubscription}
            colors={colors}
          />
        </CardContent>
      </Card>

      {/* Invoices Section */}
      <Card 
        elevation={0}
        sx={{ 
          mb: 4, 
          borderRadius: '16px', 
          border: `1px solid ${colors.border}`,
          overflow: 'hidden'
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <SectionHeader
            icon={<AccountBalanceWalletIcon />}
            title={t("invoices")}
          />
          
          <InvoiceTable
            billing_history={billing_history}
            formatDate={formatDate}
            getStatusColor={getStatusColor}
            handleDownloadInvoice={handleDownloadInvoice}
            downloadingInvoices={downloadingInvoices}
            colors={colors}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default BillingInfo;