// src/redux/reducers/signatureReducer.js
import {
    SIGNATURE_REQUEST_LOADING,
    SIGNATURE_REQUEST_SUCCESS,
    SIGNATURE_REQUEST_FAILURE,
    SIGNATURE_LIST_LOADING,
    SIGNATURE_LIST_SUCCESS,
    SIGNATURE_LIST_FAILURE,
    SIGNATURE_CANCEL_LOADING,
    SIGNATURE_CANCEL_SUCCESS,
    SIGNATURE_CANCEL_FAILURE,
    SIGNATURE_REMIND_LOADING,
    SIGNATURE_REMIND_SUCCESS,
    SIGNATURE_REMIND_FAILURE,
    SIGNATURE_ADD_LOADING,
    SIGNATURE_ADD_SUCCESS,
    SIGNATURE_ADD_FAILURE,
    SIGNATURE_DELETE_LOADING,
    SIGNATURE_DELETE_SUCCESS,
    SIGNATURE_DELETE_FAILURE
  } from '../actions/types';
  
  const initialState = {
    loading: false,
    error: null,
    successMessage: null,
    signatures: [],
    transactionId: null
  };
  
  const signatureReducer = (state = initialState, action) => {
    switch (action.type) {
      case SIGNATURE_ADD_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_ADD_SUCCESS:
        return {
          ...state,
          loading: false,
          signatures: [...state.signatures, action.payload],
          successMessage: "Signataire ajouté avec succès",
          error: null
        };
      
      case SIGNATURE_ADD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          successMessage: null
        };
      
      case SIGNATURE_DELETE_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_DELETE_SUCCESS:
        return {
          ...state,
          loading: false,
          signatures: state.signatures.filter(sig => 
            sig.signer?.email !== action.payload
          ),
          successMessage: "Signataire supprimé avec succès",
          error: null
        };
      
      case SIGNATURE_DELETE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          successMessage: null
        };
        
      case SIGNATURE_REQUEST_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          successMessage: "Demande de signature créée avec succès",
          transactionId: action.payload?.transaction_id
        };
      
      case SIGNATURE_REQUEST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          successMessage: null
        };
      
      case SIGNATURE_LIST_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          signatures: action.payload,
          error: null
        };
      
      case SIGNATURE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      
      case SIGNATURE_CANCEL_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_CANCEL_SUCCESS:
        return {
          ...state,
          loading: false,
          successMessage: action.payload,
          error: null
        };
      
      case SIGNATURE_CANCEL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      
      case SIGNATURE_REMIND_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
          successMessage: null
        };
      
      case SIGNATURE_REMIND_SUCCESS:
        return {
          ...state,
          loading: false,
          successMessage: action.payload,
          error: null
        };
      
      case SIGNATURE_REMIND_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      
      default:
        return state;
    }
  };
  
  export default signatureReducer;