import React from 'react';
import { Grid, Box, Typography, Paper, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import TotalContractsIcon from '@mui/icons-material/Description';
import SignedContractsIcon from '@mui/icons-material/CheckCircle';
import DraftContractsIcon from '@mui/icons-material/Edit';
import PendingContractsIcon from '@mui/icons-material/Pending';
import { useTranslation } from 'react-i18next';

const StatCard = ({ title, value, icon, color }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        borderRadius: 2,
        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)'
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography 
            variant="subtitle2"
            color="text.secondary"
            sx={{ fontWeight: 500, mb: 0.5, textTransform: 'uppercase', fontSize: '0.75rem' }}
          >
            {title}
          </Typography>
          <Typography 
            variant="h3"
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary,
              lineHeight: 1.2
            }}
          >
            {value}
          </Typography>
        </Box>
        <Box 
          sx={{
            p: 1.5,
            borderRadius: 2,
            bgcolor: alpha(color, 0.1),
            color: color
          }}
        >
          {icon}
        </Box>
      </Box>
    </Paper>
  );
};

const StatsSummary = ({ totalContracts, contracts }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  
  // Get status translations for the current language
  const SIGNED_STATUS = t('statsSummary.status.signed');
  const DRAFT_STATUS = t('statsSummary.status.draft');
  const PENDING_STATUS = t('statsSummary.status.pendingSignature');
  
  // Calculate counts
  const signedCount = contracts.filter(c => {
    return c.status === SIGNED_STATUS || 
           c.status === 'SIGNED' || 
           c.status === 'signé' || 
           c.status === 'موقعة';
  }).length;
  
  const draftCount = contracts.filter(c => {
    return c.status === DRAFT_STATUS || 
           c.status === 'DRAFT' || 
           c.status === 'brouillon' || 
           c.status === 'مسودة';
  }).length;
  
  const pendingCount = contracts.filter(c => {
    return c.status === PENDING_STATUS || 
           c.status === 'PENDING_SIGNATURE' || 
           c.status === 'en attente de signature' || 
           c.status === 'في انتظار التوقيع';
  }).length;
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard 
          title={t('statsSummary.totalContracts')}
          value={totalContracts}
          icon={<TotalContractsIcon fontSize="large" />}
          color={theme.palette.primary.main}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard 
          title={t('statsSummary.signedContracts')}
          value={signedCount}
          icon={<SignedContractsIcon fontSize="large" />}
          color="#10B981" // Green
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard 
          title={t('statsSummary.draftContracts')}
          value={draftCount}
          icon={<DraftContractsIcon fontSize="large" />}
          color="#F59E0B" // Amber
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard 
          title={t('statsSummary.pendingSignature')}
          value={pendingCount}
          icon={<PendingContractsIcon fontSize="large" />}
          color="#EF4444" // Red
        />
      </Grid>
    </Grid>
  );
};

export default StatsSummary;