import React from 'react';
import {
    Typography,
    Box,
    Paper,
    Grid,
    Divider
} from '@mui/material';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DiscountIcon from '@mui/icons-material/Discount'; // Add discount icon
import { colors } from '../../../utils/colors';
import MobilePlanSummary from './MobilePlanSummary';
import PromoCodeInput from './PromoCodeInput';

const PlanSummary = ({ planInfo, priceInfo, isMobileView, handlePromoCodeValidate }) => {
    const { priceHT, taxAmount, priceTTC, discount, originalPrice } = priceInfo;
    const hasDiscount = discount && discount > 0 && originalPrice > 0;

    if (isMobileView) {
        // render MobilePlanSummary component with updated props
        return (
            <MobilePlanSummary
                planInfo={planInfo}
                priceInfo={{ priceHT, taxAmount, priceTTC, discount, originalPrice }}
                isMobileView={isMobileView}
                handlePromoCodeValidate={handlePromoCodeValidate}
            />
        );
    }

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 3,
                borderRadius: '16px',
                height: '100%',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)'
            }}
        >

            <Box
                justifyContent="space-between"
                mb={3}
                display="flex"
                alignItems="center"
            >
                <Box>
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="#333"
                    >
                        {planInfo.price === 'Personnalisée'
                            ? 'Prix personnalisé'
                            : `${priceTTC.toFixed(2)} MAD`
                        }
                    </Typography>

                    {(hasDiscount + discount > 0) && (
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontSize: '0.9rem',
                                textDecoration: 'line-through',
                                color: '#999'
                            }}
                        >
                            Prix initial: {(priceTTC + discount).toFixed(2)} MAD
                        </Typography>
                    )}

                    {planInfo.subscriptionType === "annual" &&
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontSize: '1rem',
                                mt: 0.5,
                                color: '#555'
                            }}
                        >
                            Pour 12 mois (soit {(priceTTC / 12).toFixed(2)} MAD/mois)
                        </Typography>
                    }
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                    >
                        {planInfo.subscriptionType === "annual" ? "Abonnement Annuel" : "Abonnement Mensuel"}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                    >
                        {planInfo.description}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            display: 'inline-block',
                            backgroundColor: colors.primary,
                            color: 'white',
                            padding: '4px 12px',
                            borderRadius: '12px',
                            fontSize: '0.8rem',
                            mt: 1
                        }}
                    >
                        Forfait {planInfo.planType === 'individual' ? 'Individuel' :
                            planInfo.planType === 'professional' ? 'Professionnel' : 'Entreprise'}
                    </Typography>
                </Box>
                {!isMobileView && (
                    <img
                        src="https://juridia.ma/wp-content/uploads/2025/03/logo_full.png"
                        alt="Logo"
                        style={{
                            width: 200,
                            height: 'auto'
                        }}
                    />
                )}
            </Box>



            {/* Add the promo code component if price is not "Personnalisée" */}
            {planInfo.price !== 'Personnalisée' && (
                <Box mt={2}>
                    <PromoCodeInput
                        onApply={handlePromoCodeValidate}
                        isMobileView={isMobileView}
                    />
                </Box>
            )}

            <Box
                sx={{
                    backgroundColor: 'rgba(0,0,0,0.02)',
                    borderRadius: '12px',
                    padding: 2,
                    mb: 3
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    mb={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <FeaturedPlayListIcon color="primary" />
                    Fonctionnalités incluses:
                </Typography>
                <Box component="ul" sx={{ paddingLeft: '20px', marginBottom: '4px' }}>
                    {planInfo.features && planInfo.features.map((feature, index) => (
                        <Box
                            component="li"
                            key={index}
                            sx={{
                                padding: '4px 0',
                                color: '#555'
                            }}
                        >
                            {feature}
                        </Box>
                    ))}
                </Box>
            </Box>

            <Divider sx={{ mb: 2 }} />

            {planInfo.price !== 'Personnalisée' && (
                <>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            mb: 1.5,
                            px: 1
                        }}
                    >
                        <Typography color="#555">Total hors taxes</Typography>
                        <Typography fontWeight="500">{priceHT.toFixed(2) + " MAD"}</Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            mb: 1.5,
                            px: 1
                        }}
                    >
                        <Typography color="#555">TVA (20%)</Typography>
                        <Typography fontWeight="500">{taxAmount.toFixed(2) + " MAD"}</Typography>
                    </Grid>

                    {/* Display discount if applied */}
                    {(hasDiscount + discount > 0) && (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                mb: 1.5,
                                px: 1,
                                color: 'green'
                            }}
                        >
                            <Typography sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'green',
                                fontWeight: 500
                            }}>
                                <DiscountIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                                Réduction
                            </Typography>
                            <Typography fontWeight="500" color="green">
                                -{discount.toFixed(2) + " MAD"}
                            </Typography>
                        </Grid>
                    )}

                    <Divider sx={{ mb: 1.5 }} />
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0.03)',
                            borderRadius: '8px',
                            p: 1.5,
                            mb: 2
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold" color="#333">Total TTC</Typography>
                        <Box>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={colors.primary}
                            >
                                {`${priceTTC.toFixed(2)} MAD`}
                            </Typography>
                            {planInfo.subscriptionType === "annual" && (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    sx={{ display: 'block', textAlign: 'right' }}
                                >
                                    Pour 12 mois
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </>
            )}

            <Divider sx={{ my: 3 }} />

            <SecurityInfo />
        </Paper>
    );
};

const SecurityInfo = () => (
    <Box>
        <Typography
            variant="subtitle2"
            fontWeight="bold"
            color="#555"
            gutterBottom
        >
            Protection des droits des consommateurs
        </Typography>
        <Typography
            variant="caption"
            color="textSecondary"
            paragraph
            sx={{ fontSize: '0.75rem', lineHeight: 1.5 }}
        >
            Par le biais de cette page de paiement, Vantage Payment Systems collecte des données personnelles afin de traiter vos transactions. Ce traitement a été déclaré à la CNDP sous le numéro D-CE-294/2013.
        </Typography>
        <Typography
            variant="caption"
            color="textSecondary"
            sx={{ fontSize: '0.75rem', lineHeight: 1.5 }}
        >
            Grâce au protocole de sécurité "Secure Socket Layer" (SSL), vos données sont entièrement cryptées et totalement protégées. Notre certificat SSL a été délivré par la société RapidSSL.
        </Typography>
        <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundColor: 'rgba(0,0,0,0.02)',
                borderRadius: '12px',
                py: 2,
                px: 1
            }}
        >
            <img src="https://juridia.ma/wp-content/uploads/2025/04/verified_by_visa.jpeg" alt="Verified by Visa" style={{ width: 50, height: 'auto', margin: '0 8px' }} />
            <img src="https://juridia.ma/wp-content/uploads/2025/04/RapidSSL.png" alt="RapidSSL" style={{ width: 50, height: 'auto', margin: '0 8px' }} />
            <img src="https://juridia.ma/wp-content/uploads/2025/04/mastercard_secure.png" alt="MasterCard" style={{ width: 50, height: 'auto', margin: '0 8px' }} />
            <img src="https://juridia.ma/wp-content/uploads/2025/04/payzone.png" alt="PayZone" style={{ width: 50, height: 'auto', margin: '0 8px' }} />
        </Box>
    </Box>
);

export default PlanSummary;