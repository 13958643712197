import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  CircularProgress,
  alpha,
  FormHelperText
} from '@mui/material';

const InviteUserDialog = ({
  open,
  onClose,
  inviteEmail,
  setInviteEmail,
  selectedRoles,
  setSelectedRoles, // Added this prop to handle role changes internally
  validationError,
  setValidationError, // Added this prop to handle validation errors internally
  onInviteUser,
  invitingUser,
  availableRoles,
  getRoleDisplayName,
  colors,
  t
}) => {
  // Local state for role validation error
  const [roleError, setRoleError] = useState('');

  // Validate role selection whenever it changes
  const validateRoleSelection = (roles) => {
    const hasUser = roles.includes('user');
    const hasAdmin = roles.includes('admin');
    const hasBillingAdmin = roles.includes('billing_admin');
    
    if (hasUser && (hasAdmin || hasBillingAdmin)) {
      return t('role_combination_error', 'User role cannot be combined with Admin or Billing Admin roles');
    }
    
    return '';
  };

  // Handle role change with validation
  const handleRoleChange = (event) => {
    const newRoles = event.target.value;
    const error = validateRoleSelection(newRoles);
    
    if (error) {
      setRoleError(error);
      // Don't update roles if there's an error
    } else {
      setRoleError('');
      setSelectedRoles(newRoles);
    }
  };

  // Alternative approach: auto-remove incompatible roles
  const handleRoleChangeWithAutoFix = (event) => {
    const newRoles = event.target.value;
    const lastAddedRole = newRoles.find(role => !selectedRoles.includes(role));
    
    // If user is being added and admin/billing_admin exists, remove those roles
    if (lastAddedRole === 'user') {
      const filteredRoles = newRoles.filter(r => r !== 'admin' && r !== 'billing_admin');
      setRoleError('');
      setSelectedRoles(filteredRoles);
    } 
    // If admin/billing_admin is being added and user exists, remove user role
    else if ((lastAddedRole === 'admin' || lastAddedRole === 'billing_admin') && newRoles.includes('user')) {
      const filteredRoles = newRoles.filter(r => r !== 'user');
      setRoleError('');
      setSelectedRoles(filteredRoles);
    } 
    // No conflicts
    else {
      setRoleError('');
      setSelectedRoles(newRoles);
    }
  };

  // Clear role error when dialog closes
  useEffect(() => {
    if (!open) {
      setRoleError('');
    }
  }, [open]);

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1, 
        pt: 3,
        px: 3,
        color: colors.text,
        fontWeight: 600,
        fontSize: '1.25rem'
      }}>
        {t('invite_new_user', 'Invite New User')}
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: 2 }}>
        <DialogContentText sx={{ mb: 3, color: colors.textSecondary }}>
          {t('invite_description', 'Enter the email address of the person you want to invite to your organization.')}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={t('email_address', 'Email Address')}
          type="email"
          fullWidth
          variant="outlined"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          error={!!validationError}
          helperText={validationError}
          sx={{ 
            mt: 2,
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: validationError ? colors.error : colors.border,
              },
              '&:hover fieldset': {
                borderColor: validationError ? colors.error : colors.primary,
              },
              '&.Mui-focused fieldset': {
                borderColor: validationError ? colors.error : colors.primary,
              },
            }
          }}
        />
        <FormControl sx={{ width: '100%' }} error={!!roleError}>
          <InputLabel 
            sx={{ 
              color: roleError ? colors.error : colors.textSecondary,
              '&.Mui-focused': {
                color: roleError ? colors.error : colors.primary
              }
            }}
          >{t('roles', 'Roles')}</InputLabel>
          <Select
            multiple
            value={selectedRoles}
            onChange={handleRoleChangeWithAutoFix} // Using the auto-fix version
            input={
              <OutlinedInput 
                label={t('roles', 'Roles')} 
                sx={{ 
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: roleError ? colors.error : colors.border,
                  },
                  '&:hover fieldset': {
                    borderColor: roleError ? colors.error : colors.primary,
                  },
                }}
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip 
                    key={value} 
                    label={getRoleDisplayName(value)} 
                    sx={{ 
                      backgroundColor: 
                        value === 'admin' ? colors.secondary :
                        value === 'billing_admin' ? colors.info :
                        colors.backgroundAlt,
                      color: 
                        value === 'admin' || value === 'billing_admin' ? 'white' : colors.text
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {availableRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {getRoleDisplayName(role)}
              </MenuItem>
            ))}
          </Select>
          {roleError && <FormHelperText>{roleError}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 2 }}>
        <Button 
          onClick={onClose}
          sx={{ 
            color: colors.textSecondary,
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: colors.backgroundAlt,
            }
          }}
        >
          {t('cancel', 'Cancel')}
        </Button>
        <Button
          onClick={onInviteUser}
          variant="contained"
          disableElevation
          disabled={!inviteEmail || invitingUser || !!roleError || selectedRoles.length === 0}
          sx={{ 
            backgroundColor: colors.primary,
            color: 'white',
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            py: 1,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: colors.primaryDark,
            },
            '&.Mui-disabled': {
              backgroundColor: alpha(colors.primary, 0.6),
              color: 'white',
            }
          }}
        >
          {invitingUser ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
              {t('sending', 'Sending...')}
            </Box>
          ) : (
            t('send_invitation', 'Send Invitation')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;