import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
  Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import AddSignature from "./AddSignature";

import {
  addSignatory,
  deleteSignatory,
  createSignatureRequest,
  getSignatureStatus,
  cancelSignatureRequest,
  sendSignatureReminder
} from "../../../../redux/actions/signatureAction";

const Signature = ({ contractId }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Redux state
  const { loading, error, successMessage, signatures } = useSelector(state => state.signature || {});
  const contract = useSelector(state => state.contract.contract);

  // Local state
  const [collaborators, setCollaborators] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [reminderMessage, setReminderMessage] = useState(
    t("signature.reminderDialog.placeholder")
  );

  // Load existing signatures when component mounts or contractId changes
  useEffect(() => {
    if (contractId) {
      fetchSignatureStatus();
    }
  }, [contractId]);

  // Update reminder message when language changes
  useEffect(() => {
    setReminderMessage(t("signature.reminderDialog.placeholder"));
  }, [i18n.language, t]);

  // Fetch signature status from API
  const fetchSignatureStatus = () => {
    if (!contractId) return;

    dispatch(getSignatureStatus(contractId))
      .then(response => {
        if (Array.isArray(response)) {
          // Convert API response to match our local state format
          const signersFromAPI = response.map(sig => ({
            email: sig.signer?.email || sig.signer_email || "Unknown",
            first_name: sig.signer?.first_name || sig.signer_first_name || "",
            last_name: sig.signer?.last_name || sig.signer_last_name || "",
            status: sig.status,
            signed_at: sig.signed_at
          }));

          console.log('signersFromAPI', signersFromAPI)
          setCollaborators(signersFromAPI);
        }
      })
      .catch(error => {
        console.error("Failed to fetch signatures:", error);
        setAlertMessage(t("signature.alerts.error"));
        setShowErrorAlert(true);
      });
  };

  // Handle success message from Redux
  useEffect(() => {
    if (successMessage) {
      setAlertMessage(successMessage);
      setShowSuccessAlert(true);

      // Refresh signature status after successful operations
      fetchSignatureStatus();
    }
  }, [successMessage]);

  // Handle error from Redux
  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setShowErrorAlert(true);
    }
  }, [error]);

  // Add a new signer
  const handleAdd = (email, firstName, lastName) => {
    if (email && !collaborators.some((c) => c.email === email)) {
      // Save to backend
      dispatch(addSignatory(contractId, email, firstName, lastName))
        .then(() => {
          // Success is handled by the useEffect for successMessage
        })
        .catch(error => {
          console.error("Failed to add signatory:", error);
          setAlertMessage(t("signature.alerts.error"));
          setShowErrorAlert(true);
        });
    }
    setIsAdding(false);
  };

  // Delete a signer (only for signers not yet sent for signature)
  const handleDelete = (email) => {
    dispatch(deleteSignatory(contractId, email))
      .then(() => {
        // Success is handled by the useEffect for successMessage
      })
      .catch(error => {
        console.error("Failed to delete signatory:", error);
        setAlertMessage(t("signature.alerts.error"));
        setShowErrorAlert(true);
      });
  };

  // Open confirmation dialog for sending signatures
  const handleSendSignatures = () => {
    setShowConfirm(true);
  };

  // Confirm and send for signature
  const confirmSendSignatures = () => {
    setShowConfirm(false);

    // Get current domain for callback
    const callbackUrl = window.location.origin;

    // Send the request - we don't need to send signers again as they're already in the database
    dispatch(createSignatureRequest(
      contractId,
      [], // No need to send signers again
      callbackUrl,
      7,    // expires in 7 days
      3     // reminder after 3 days
    ))
      .then(() => {
        // Refresh the signatures from API after a short delay
        setTimeout(() => {
          fetchSignatureStatus();
        }, 1000);
      })
      .catch(error => {
        console.error("Failed to send for signature:", error);
      });
  };

  // Cancel signature request
  const handleCancelSignature = () => {
    if (window.confirm(t("signature.alerts.confirmCancel"))) {
      dispatch(cancelSignatureRequest(contractId))
        .then(() => {
          // Refresh signatures from API
          fetchSignatureStatus();
          setAlertMessage(t("signature.alerts.cancelSuccess"));
          setShowSuccessAlert(true);
        })
        .catch(error => {
          console.error("Failed to cancel signature:", error);
          setAlertMessage(t("signature.alerts.error"));
          setShowErrorAlert(true);
        });
    }
  };

  // Show reminder dialog
  const handleOpenReminderDialog = (email) => {
    setSelectedEmail(email);
    setReminderDialogOpen(true);
  };

  // Send reminder
  const handleSendReminder = () => {
    setReminderDialogOpen(false);

    dispatch(sendSignatureReminder(contractId, reminderMessage))
      .then(() => {
        setAlertMessage(t("signature.alerts.reminderSent", { email: selectedEmail }));
        setShowSuccessAlert(true);
      })
      .catch(error => {
        console.error("Failed to send reminder:", error);
        setAlertMessage(t("signature.alerts.error"));
        setShowErrorAlert(true);
      });
  };

  // Map API status to display status
  const mapStatusText = (status) => {
    switch (status) {
      case "signed": return t("signature.statuses.signed");
      case "pending": return t("signature.statuses.pending");
      case "refused": return t("signature.statuses.refused");
      case "expired": return t("signature.statuses.expired");
      case "cancelled": return t("signature.statuses.cancelled");
      case "vide": return t("signature.statuses.notSent");
      default: return status;
    }
  };

  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case "signed":
        return { bg: "#E8F5E9", text: "#2E7D32" };
      case "pending":
        return { bg: "#FFF8E1", text: "#F57F17" };
      case "vide":
        return { bg: "#F5F5F5", text: "#757575" };
      case "refused":
        return { bg: "#FFEBEE", text: "#C62828" };
      case "expired":
        return { bg: "#ECEFF1", text: "#546E7A" };
      case "cancelled":
        return { bg: "#FFEBEE", text: "#C62828" };
      default:
        return { bg: "#F5F5F5", text: "#757575" };
    }
  };

  // Render status icon
  const renderStatusIcon = (status) => {
    switch (status) {
      case "signed":
        return <CheckCircleIcon sx={{ fontSize: 18, mr: 0.5 }} />;
      case "pending":
        return <HourglassEmptyIcon sx={{ fontSize: 18, mr: 0.5 }} />;
      case "vide":
        return <HourglassEmptyIcon sx={{ fontSize: 18, mr: 0.5 }} />;
      case "refused":
      case "cancelled":
        return <CancelIcon sx={{ fontSize: 18, mr: 0.5 }} />;
      case "expired":
        return <CancelIcon sx={{ fontSize: 18, mr: 0.5 }} />;
      default:
        return null;
    }
  };

  // Determine if the contract can be sent for signature
  const canSendForSignature = () => {
    // Check if contract status allows for signature
    if (contract && contract.status === "en attente de signature") {
      return false; // Already in signature process
    }

    // Check if there are any signers to be added
    return collaborators.some(c => c.status === "vide");
  };

  // Determine text direction based on current language
  const isRTL = i18n.language === 'ar';

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
      direction: isRTL ? 'rtl' : 'ltr',
      textAlign: isRTL ? 'right' : 'left'
    }}>

      {isAdding ? (
        // Display the form to add a signatory
        <AddSignature onAdd={handleAdd} onCancel={() => setIsAdding(false)} />
      ) : (
        // Display the list of signatories and buttons
        <>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#212121", mb: 0.5 }}>
              {t("signature.title")}
            </Typography>
            <Typography variant="body2" sx={{ color: "#757575" }}>
              {t("signature.subtitle")}
            </Typography>
          </Box>

          <Box sx={{
            flexGrow: 1,
            overflowY: "auto",
            mb: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            {collaborators.length === 0 ? (
              <Box sx={{
                textAlign: "center",
                color: "#9E9E9E",
                py: 4,
                bgcolor: "#F5F5F5",
                borderRadius: 2
              }}>
                <Typography>{t("signature.signatoryPlaceholder")}</Typography>
              </Box>
            ) : (
              collaborators.map(({ email, first_name, last_name, status, signed_at }) => {
                const statusStyle = getStatusColor(status);

                return (
                  <Box
                    key={email}
                    sx={{
                      p: 2.5,
                      bgcolor: "#FAFAFA",
                      borderRadius: 2,
                      border: "1px solid #EEEEEE",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        boxShadow: "0 2px 8px rgba(0,0,0,0.05)"
                      }
                    }}
                  >
                    <Box>
                      <Typography sx={{
                        fontWeight: 500,
                        color: "#212121",
                        mb: 0.5
                      }}>
                        {first_name} {last_name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#757575" }}>
                        {email}
                      </Typography>
                      {signed_at && (
                        <Typography variant="caption" sx={{ color: "#9E9E9E", display: "block", mt: 0.5 }}>
                          {t("signature.signedOn")} {new Date(signed_at).toLocaleDateString(i18n.language)}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: statusStyle.bg,
                        color: statusStyle.text,
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 10,
                        fontSize: "0.75rem",
                        fontWeight: 500
                      }}>
                        {renderStatusIcon(status)}
                        {mapStatusText(status)}
                      </Box>

                      {status === "pending" && (
                        <Button
                          onClick={() => handleOpenReminderDialog(email)}
                          startIcon={!isRTL ? <SendIcon /> : null}
                          endIcon={isRTL ? <SendIcon /> : null}
                          sx={{
                            minWidth: 'auto',
                            color: "#424242",
                            borderRadius: 2,
                            p: "6px",
                            ml: 1
                          }}
                        />
                      )}

                      {status === "vide" && (
                        <Button
                          onClick={() => handleDelete(email)}
                          startIcon={!isRTL ? <DeleteIcon /> : null}
                          endIcon={isRTL ? <DeleteIcon /> : null}
                          sx={{
                            minWidth: 'auto',
                            color: "#F44336",
                            borderRadius: 2,
                            p: "6px",
                            ml: 1
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                );
              })
            )}

            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={!isRTL ? <AddIcon /> : null}
              endIcon={isRTL ? <AddIcon /> : null}
              onClick={() => setIsAdding(true)}
              sx={{
                p: 1.5,
                borderRadius: 2,
                borderColor: '#E0E0E0',
                color: '#424242',
                textTransform: 'none',
                fontWeight: 500,
                '&:hover': {
                  borderColor: '#BDBDBD',
                  bgcolor: 'rgba(0, 0, 0, 0.02)'
                }
              }}
            >
              {t("signature.addButton")}
            </Button>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Tooltip title={t("signature.featureComingSoon")} placement="top">
                <Button
                  variant="contained"
                  // onClick={handleSendSignatures}
                  disabled={loading || !canSendForSignature()}
                  startIcon={!isRTL ? <SendIcon /> : null}
                  endIcon={isRTL ? <SendIcon /> : null}
                  sx={{
                    bgcolor: canSendForSignature() ? '#1976D2' : '#BDBDBD',
                    color: '#FFF',
                    p: 1.5,
                    borderRadius: 2,
                    flexGrow: 1,
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: canSendForSignature() ? '#1565C0' : '#BDBDBD',
                      boxShadow: canSendForSignature() ? '0 2px 6px rgba(0,0,0,0.2)' : 'none'
                    }
                  }}
                >
                  {t("signature.sendButton")}
                </Button>
              </Tooltip>

              {collaborators.some(c => c.status === "pending") && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCancelSignature}
                  disabled={loading}
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 500,
                    borderColor: '#F44336',
                    color: '#F44336',
                    '&:hover': {
                      bgcolor: 'rgba(244, 67, 54, 0.04)',
                      borderColor: '#D32F2F'
                    }
                  }}
                >
                  {t("signature.cancelButton")}
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '400px',
            direction: isRTL ? 'rtl' : 'ltr',
            textAlign: isRTL ? 'right' : 'left'
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600, pt: 3 }}>{t("signature.confirmationDialog.title")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("signature.confirmationDialog.message")}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: "#757575" }}>
            {t("signature.confirmationDialog.details")}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button
            onClick={() => setShowConfirm(false)}
            sx={{
              color: "#616161",
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1.5
            }}
          >
            {t("signature.cancelButton")}
          </Button>
          <Button
            onClick={confirmSendSignatures}
            variant="contained"
            sx={{
              bgcolor: "#1976D2",
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1.5,
              boxShadow: 'none'
            }}
          >
            {t("signature.confirmButton")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reminder Dialog */}
      <Dialog
        open={reminderDialogOpen}
        onClose={() => setReminderDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '400px',
            direction: isRTL ? 'rtl' : 'ltr',
            textAlign: isRTL ? 'right' : 'left'
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600, pt: 3 }}>{t("signature.reminderDialog.title")}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {t("signature.reminderDialog.message")} <strong>{selectedEmail}</strong>
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={reminderMessage}
            onChange={(e) => setReminderMessage(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#BDBDBD',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button
            onClick={() => setReminderDialogOpen(false)}
            sx={{
              color: "#616161",
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1.5
            }}
          >
            {t("signature.cancelButton")}
          </Button>
          <Button
            onClick={handleSendReminder}
            variant="contained"
            sx={{
              bgcolor: "#1976D2",
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1.5,
              boxShadow: 'none'
            }}
          >
            {t("signature.sendButton")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Alert */}
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={6000}
        onClose={() => setShowSuccessAlert(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSuccessAlert(false)}
          severity="success"
          sx={{
            width: '100%',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '1.25rem'
            },
            direction: isRTL ? 'rtl' : 'ltr',
            textAlign: isRTL ? 'right' : 'left'
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Error Alert */}
      <Snackbar
        open={showErrorAlert}
        autoHideDuration={6000}
        onClose={() => setShowErrorAlert(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowErrorAlert(false)}
          severity="error"
          sx={{
            width: '100%',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '1.25rem'
            },
            direction: isRTL ? 'rtl' : 'ltr',
            textAlign: isRTL ? 'right' : 'left'
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Signature;