import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
  Button,
  useTheme,
  alpha,
  Divider,
  FormControlLabel,
  Switch,
  Tooltip,
  InputAdornment
} from "@mui/material";
import {
  Description as DescriptionIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Search as SearchIcon,
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { backendClient } from "../../../api/backend";
import CustomButton from '../../../components/CustomButton';
import Autocomplete from '@mui/material/Autocomplete';

const CreateContractDialog = ({ open, onClose, onSuccess }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user?.user);
  const theme = useTheme();

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [contractName, setContractName] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isEmptyContract, setIsEmptyContract] = useState(false);

  // Fetch contract templates when dialog opens
  useEffect(() => {
    if (open) {
      fetchContractTemplates();
      setContractName("");
      setDescription("");
      setError("");
      setSearchTerm("");
      setSelectedTemplate(null);
      setIsEmptyContract(false);
    }
  }, [open]);

  const fetchContractTemplates = async () => {
    try {
      setLoading(true);
      setError("");
      const data = await backendClient.getContractTemplates();
      setTemplates(data);
    } catch (err) {
      console.error("Failed to fetch contract templates:", err);
      setError(t("failed_load_templates") || "Failed to load contract templates");
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    if (!isEmptyContract && !selectedTemplate) {
      setError(t("select_template") || "Please select a contract template");
      return;
    }
    if (!contractName.trim()) {
      setError(t("enter_contract_name") || "Please enter a contract name");
      return;
    }

    try {
      setCreating(true);
      setError("");

      // Create the contract in the backend
      const newContract = await backendClient.createContract(
        contractName.trim(),
        isEmptyContract ? null : selectedTemplate.id,
        user.id,
        description.trim() || null, // Pass description if provided
        isEmptyContract // Pass flag for empty contract
      );

      console.log('newContract', newContract)

      // Close the dialog
      onClose();

      // Call success callback if provided
      if (onSuccess) {
        onSuccess(newContract.id);
      }

      // Navigate to contract edition with the newly created contract ID
      navigate(`/contract/${newContract.id}`, { replace: true });

    } catch (err) {
      console.error("Failed to create contract:", err);
      setError(err.message || t("contract_creation_error") || "Failed to create contract. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const handleClose = () => {
    // Reset form state
    setError("");
    setContractName("");
    setDescription("");
    setSelectedTemplate(null);
    setSearchTerm("");
    setIsEmptyContract(false);

    // Call the onClose prop
    onClose();
  };

  const handleEmptyContractToggle = (event) => {
    setIsEmptyContract(event.target.checked);
    if (event.target.checked) {
      setSelectedTemplate(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!creating ? handleClose : undefined}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '500px'
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <InsertDriveFileIcon color="primary" />
          <Typography variant="h6" fontWeight={600}>
            {t("new_contract") || "New Contract"}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pb: 2, pt: 2 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          {t("create_contract_description") || "Provide the details below to create a new contract. You can edit the content after creation."}
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : templates.length === 0 && !isEmptyContract ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 3,
            px: 2,
            textAlign: 'center',
            backgroundColor: alpha(theme.palette.error.light, 0.1),
            borderRadius: 2
          }}>
            <DescriptionIcon color="error" sx={{ fontSize: 40, mb: 1, opacity: 0.7 }} />
            <Typography color="error.main">
              {t("no_templates") || "No templates available"}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {t("failed_load_templates") || "Failed to load contract templates"}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEmptyContract}
                    onChange={handleEmptyContractToggle}
                    color="primary"
                    disabled={creating}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      {t("empty_contract") || "Create empty contract"}
                    </Typography>
                    <Tooltip title={t("empty_contract_tooltip") || "Start with a blank contract instead of using a template"}>
                      <HelpOutlineIcon sx={{ fontSize: 16, ml: 0.5, color: 'text.secondary' }} />
                    </Tooltip>
                  </Box>
                }
              />
            </Box>

            {!isEmptyContract && (
              <Autocomplete
                value={selectedTemplate}
                onChange={(event, newValue) => {
                  setSelectedTemplate(newValue);
                }}
                disabled={creating || isEmptyContract}
                options={templates}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("contract_type") || "Contract Type"}
                    placeholder={t("search_templates") || "Search templates..."}
                    error={!!error && !selectedTemplate && !isEmptyContract}
                    helperText={!!error && !selectedTemplate && !isEmptyContract ? t("template_required") || "Template selection required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <SearchIcon color="action" />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} sx={{ py: 1.5 }}>
                    <Typography variant="body2">{option.name}</Typography>
                  </MenuItem>
                )}
                noOptionsText=""
                sx={{ width: '100%' }}
                disablePortal
                ListboxProps={{
                  style: { maxHeight: '200px' }
                }}
                forcePopupIcon
              />
            )}

            <TextField
              fullWidth
              label={t("contract_title") || "Contract Title"}
              value={contractName}
              onChange={(e) => setContractName(e.target.value)}
              error={!!error && !contractName.trim()}
              helperText={!contractName.trim() && error ? t("name_required") || "Contract name required" : ""}
              disabled={creating}
              InputProps={{
                startAdornment: (
                  <InsertDriveFileIcon color="action" sx={{ mr: 1 }} />
                ),
              }}
            />

            <TextField
              fullWidth
              label={t("description_optional") || "Description (Optional)"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={2}
              disabled={creating}
              InputProps={{
                startAdornment: (
                  <DescriptionIcon color="action" sx={{ mr: 1, mt: 1 }} />
                ),
              }}
            />

            {error && !(!selectedTemplate && !isEmptyContract || !contractName.trim()) && (
              <Box sx={{
                color: 'error.main',
                mt: 1,
                p: 1.5,
                backgroundColor: alpha(theme.palette.error.main, 0.05),
                borderRadius: 1,
                border: `1px solid ${alpha(theme.palette.error.main, 0.1)}`
              }}>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={handleClose}
          color="inherit"
          disabled={creating}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            color: theme.palette.text.secondary
          }}
        >
          {t("cancel") || "Cancel"}
        </Button>

        <CustomButton
          text={creating ? (t("creating") || "Creating...") : (t("create") || "Create")}
          outlined={false}
          loading={creating}
          onClick={handleCreate}
          disabled={loading || creating || (templates.length === 0 && !isEmptyContract) || (!isEmptyContract && !selectedTemplate)}
          startIcon={!creating && <AddIcon />}
          sx={{ ml: 1 }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateContractDialog;