import React, { useCallback, useState, useEffect, useRef, memo } from 'react'
import { forwardRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import { useWindowWidth, useWindowHeight } from '@wojtekmaj/react-hooks'
import { useInView } from 'react-intersection-observer'
import debounce from 'lodash.debounce'

import {
  HORIZONTAL_GUTTER_SIZE_PX,
  OBSERVER_THRESHOLD_PERCENTAGE,
  PAGE_HEIGHT,
  PDF_HEADER_SIZE_PX,
  PDF_SIDEBAR_SIZE_PX,
  PDF_WIDTH_PERCENTAGE,
  VERTICAL_GUTTER_SIZE_PX
} from './pdfDisplayConstants'

import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
// import { usePdfFocus } from "~/context/pdf";
// import { multiHighlight } from "~/utils/multi-line-highlight";
import { usePdfFocus } from './pdfContext'
import { multiHighlight } from './multi-line-highlight'
import { Box, CircularProgress } from '@mui/material'
import { isMobile } from '../../utils/utils'

// Configure the PDF worker
// const pdfjsOptions = pdfjs.GlobalWorkerOptions;
// const pdfjsVersion = pdfjs.version;
// pdfjsOptions.workerSrc =
//   "//unpkg.com/pdfjs-dist@" + String(pdfjsVersion) + "/legacy/build/pdf.worker.min.js";

const pdfjsOptions = pdfjs.GlobalWorkerOptions
const pdfjsVersion = pdfjs.version
// console.log('pdfjsVersion', pdfjsVersion)
// pdfjsOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf-worker/pdf.worker.min.mjs'

// pdfjsOptions.workerSrc = "//unpkg.com/pdfjs-dist@" + String(pdfjsVersion) + "/legacy/build/pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsVersion}/legacy/build/pdf.worker.min.js`;

// PageRenderer component
const PageRenderer = ({
  file,
  page_number,
  style,
  scale,
  listWidth,
  setPageInView
}) => {
  const { pdfFocusState } = usePdfFocus()
  // console.log('pdfFocusState', pdfFocusState)

  const [shouldCenter, setShouldCenter] = useState(false)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const hasScrolledToHighlight = useRef(false) // ✅ Persistent flag (Fixes re-scrolling issue)
  const userScrolled = useRef(false) // ✅ Tracks manual scrolling

  const { ref: inViewRef, inView } = useInView({
    threshold: OBSERVER_THRESHOLD_PERCENTAGE * Math.min(1 / scale, 1)
  })

  const containerRef = useRef(null)

  const setRefs = useCallback(
    node => {
      containerRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    // Attach a scroll listener to detect manual scrolls
    const handleScroll = () => {
      userScrolled.current = true;
    };

    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (inView) {
      setPageInView(page_number)
    }
  }, [inView, page_number, setPageInView])

  const hidePageCanvas = useCallback(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector('canvas')
      if (canvas) canvas.style.visibility = 'hidden'
    }
  }, [containerRef])

  const showPageCanvas = useCallback(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector('canvas')
      if (canvas) canvas.style.visibility = 'visible'
    }
  }, [containerRef])

  const onPageLoadSuccess = useCallback(() => {
    hidePageCanvas()
  }, [hidePageCanvas])

  const onPageRenderError = useCallback(() => {
    showPageCanvas()
  }, [showPageCanvas])

  const onPageRenderSuccess = useCallback(
    page => {
      showPageCanvas()
      // maybeHighlight();
      // Add a 2-second delay before calling maybeHighlight
      setTimeout(() => {
        maybeHighlight()
      }, 100) // 2000 milliseconds = 2 seconds
      if (listWidth > page.width) {
        setShouldCenter(true)
      } else {
        setShouldCenter(false)
      }
    },
    [showPageCanvas, listWidth]
  )

  const documentFocused = pdfFocusState.document_id === file.id

  const maybeHighlight = useCallback(
    debounce(() => {
      if (
        documentFocused &&
        pdfFocusState.citation?.metadata?.page_number === page_number + 1 &&
        !isHighlighted
      ) {
        const citationId = pdfFocusState.citation?.metadata._id // Ensure citations are uniquely identifiable
        // console.log('STARTHIGHLIGHTLanguage', pdfFocusState.citation.metadata.language)
        // console.log('STARTHIGHLIGHTCitationId', citationId)
        // console.log('STARTHIGHLIGHTCurrent', hasScrolledToHighlight.current)

        // if (citationId && hasScrolledToHighlight.current === citationId) {
        //   return // Exit if the same citation was already highlighted
        // }

        // const textToHighlight = pdfFocusState.citation.metadata.section_title + " " + pdfFocusState.citation.page_content
        const textToHighlight = pdfFocusState.citation.page_content

        const firstHighlightedElement = multiHighlight(
          textToHighlight,
          page_number + 1,
          pdfFocusState?.citation?.metadata?.language
        )

        if (firstHighlightedElement) {
          console.log('serolling first high')
          firstHighlightedElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          hasScrolledToHighlight.current = citationId // Store the citation ID to prevent re-triggering
        }

        setIsHighlighted(true)
      }
    }, 50),
    [pdfFocusState.citation, isHighlighted, hasScrolledToHighlight]
  )

  return (
    <div
      key={`${file.id}-${page_number}`}
      ref={setRefs}
      style={{
        ...style,
        padding: '10px',
        backgroundColor: 'WhiteSmoke',
        // backgroundColor: 'red',
        display: `${shouldCenter ? 'flex' : ''}`,
        justifyContent: 'center'
      }}
    >
      <Page
        scale={scale}
        onRenderSuccess={onPageRenderSuccess}
        onLoadSuccess={onPageLoadSuccess}
        onRenderError={onPageRenderError}
        pageIndex={page_number}
        renderAnnotationLayer
      />
    </div>
  )
}

// VirtualizedPDF component
const VirtualizedPDF = forwardRef(
  ({ file, scale, setIndex, setScaleFit, setNumPages }, ref) => {
    const { pdfFocusState } = usePdfFocus()

    const windowWidth = useWindowWidth()
    const windowHeight = useWindowHeight()
    const height = (windowHeight || 0) - PDF_HEADER_SIZE_PX

    const newWidthPx = isMobile() ? windowWidth * 0.79 :
      // PDF_WIDTH_PERCENTAGE * 0.01 * (windowWidth || 0) -
      (windowWidth || 0) * 0.435;
    // 0.0303 * windowWidth * 0.01 * (windowWidth || 0)
    // - PDF_SIDEBAR_SIZE_PX -
    // HORIZONTAL_GUTTER_SIZE_PX

    const [pdf, setPdf] = useState(null)
    const listRef = useRef(null)

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0)
      }
    }, [scale])

    function onDocumentLoadSuccess(nextPdf) {
      setPdf(nextPdf)
    }

    function getPageHeight() {
      const actualHeight = (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale
      return actualHeight
    }

    useEffect(() => {
      if (!pdf) return

      async function loadFirstPage() {
        if (pdf) {
          await pdf.getPage(1).then(page => {
            const pageViewport = page.getViewport({ scale: 1 })
            const pageWidth = pageViewport.width
            const computedScaleFit = newWidthPx / pageWidth
            setScaleFit(computedScaleFit)
          })
        }
      }

      loadFirstPage().catch(() => console.log('page load error'))
      setNumPages(pdf.numPages)
    }, [pdf, setNumPages, setScaleFit, newWidthPx])

    React.useImperativeHandle(ref, () => ({
      scrollToPage: page => {
        onItemClick({ page_number: page })
      }
    }))

    const onItemClick = ({ page_number }) => {
      const fixedPosition =
        page_number * (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale
      if (listRef.current) {
        listRef.current.scrollTo(fixedPosition)
      }
    }

    useEffect(() => {
      if (pdfFocusState?.page_number !== undefined && listRef.current) {
        const fixedPosition =
          (pdfFocusState?.page_number - 1) *
          (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) *
          scale
        listRef.current.scrollTo(fixedPosition)
      }
    }, [pdfFocusState?.page_number, scale])

    const loadingDiv = () => {
      return (
        <div className='flex h-[calc(100vh-44px)] w-[56vw] items-center justify-center'>
          <CircularProgress />
        </div>
      )
    }

    return (
      // <div className='h-[calc(100vh-44px)] w-[84vh] border-gray-pdf bg-gray-pdf overflow-hidden'>
      <Box
        sx={{
          height: isMobile() ? windowHeight - windowHeight * 0.3 : windowHeight - windowHeight * 0.2,
          // height: '80%',
          // width: isMobile() ? "55vh" : '84vh',
          width: "100%",
          border: '1px solid',
          borderColor: 'grey.200',
          backgroundColor: 'grey.200',
          overflow: 'auto' // or 'visible'
        }}
      >
        <Document
          key={file.url}
          onItemClick={onItemClick}
          file={file.url}
          onLoadError={error => console.error('PDF load error:', error)}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={loadingDiv}
        >
          {pdf ? (
            <List
              ref={listRef}
              // width={Math.min(newWidthPx, windowWidth * 0.6)}
              width={newWidthPx + HORIZONTAL_GUTTER_SIZE_PX}
              height={height}
              itemCount={pdf.numPages}
              itemSize={getPageHeight}
              estimatedItemSize={
                (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale
              }
            >
              {({ index, style }) => (
                <PageRenderer
                  file={file}
                  key={`page-${index}`}
                  page_number={index}
                  style={style}
                  scale={scale}
                  listWidth={newWidthPx}
                  setPageInView={setIndex}
                />
              )}
            </List>
          ) : null}
        </Document>
      </Box>
      // </div>
    )
  }
)

const MemoizedVirtualizedPDF = memo(VirtualizedPDF)
MemoizedVirtualizedPDF.displayName = 'VirtualizedPDF'

export default MemoizedVirtualizedPDF
