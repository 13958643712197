import Fuse from 'fuse.js'
import { DocumentColorEnum, highlightColors } from './pdfColors'

function normalizeText(text) {
  if (!text) return ''
  return text
    .normalize('NFD') // Normalize to decomposed form (e.g., é -> e + ́)
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .replace(/(\r\n|\n|\r)/g, ' ') // Replace line breaks with a space
    .toLowerCase()
    .trim() // Remove leading and trailing whitespace
}

function normalizeTextArabic(text) {
  if (!text) return '';

  return text
    .normalize('NFC') // Normalize to composed form to prevent character fragmentation
    .replace(/[\u064B-\u0652]/g, '') // Remove Arabic diacritics (Tashkeel)
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .replace(/(\r\n|\n|\r)/g, ' ') // Replace line breaks with a space
    .trim(); // Remove leading and trailing whitespace
}

function groupArabicWords(spans) {
  const ARABIC_LETTER_REGEX = /^[\u0600-\u06FF]+$/; // Arabic letters range
  const words = [];
  let currentWord = '';
  let wordSpans = [];
  let spanIdxStart = null;
  let previousLeft = null; // Track the left position of the previous span

  spans.forEach((span, idx) => {
    const letter = span.textContent.trim();
    if (!letter) return;

    // Retrieve the direction and left position of the span
    const direction = span.getAttribute('dir');
    const leftPosition = parseFloat(span.style.left) || null;

    // Check if the letter is Arabic
    if (ARABIC_LETTER_REGEX.test(letter)) {
      // Check if we are starting a new word or need to continue
      if (spanIdxStart === null ||
        (previousLeft !== null && leftPosition !== null &&
          Math.abs(leftPosition - previousLeft) > 2) // Use a smaller value for finer adjustment
      ) {
        // If a word was being formed, push it to the words list
        if (currentWord) {
          words.push({ text: currentWord, spans: wordSpans, spanIdx: spanIdxStart });
        }
        currentWord = '';
        wordSpans = [];
        spanIdxStart = idx;
      }

      // Add the letter to the current word
      currentWord = direction === 'rtl' ? letter + currentWord : currentWord + letter;
      wordSpans.push(span);
      previousLeft = leftPosition;
    } else {
      // If a non-Arabic letter is encountered, reset current tracking
      if (currentWord) {
        words.push({ text: currentWord, spans: wordSpans, spanIdx: spanIdxStart });
        currentWord = '';
        wordSpans = [];
        spanIdxStart = null;
        previousLeft = null;
      }
    }
  });

  // Push any remaining word after the loop
  if (currentWord) {
    words.push({ text: currentWord, spans: wordSpans, spanIdx: spanIdxStart });
  }

  return words;
}


function multiHighlight(
  textToHighlight,
  page_number,
  language,
  color = DocumentColorEnum.yellow
) {
  const highlightColor = highlightColors[color]


  const spans = document.querySelectorAll(
    `div[data-page-number='${page_number}'] .react-pdf__Page__textContent.textLayer span`
  )

  let words = []

  if (language.toLowerCase() === 'ar') {
    words = groupArabicWords([...document.querySelectorAll(
      `div[data-page-number='${page_number}'] .react-pdf__Page__textContent.textLayer span`
    )]);
  } else {
    spans.forEach((span, spanIdx) => {
      const htmlSpan = span
      const spanWords = normalizeText(htmlSpan.textContent || '')
      spanWords.split(' ').forEach((text, wordIdx) => {
        words.push({ text, spanIdx, wordIdx })
      })
    })
  }

  let searchString = normalizeText(textToHighlight)

  searchString = searchString.replace(/\s{2,}/g, ' ')
  searchString = searchString.replace(/\t/g, ' ')
  searchString = searchString
    .toString()
    .replace(/(\r\n|\n|\r)/g, '')
    .replace('"', '')
    .trim()

  const searchWords = searchString.split(' ')
  let lenSearchString = searchWords.length
  if (!lenSearchString) {
    return
  }

  const firstWord = searchWords[0]

  if (!firstWord) {
    return
  }

  let searchData;
  if (language.toLowerCase() === 'ar') {
    searchData = generateDirectSearchDataArabic(firstWord, words, lenSearchString)
  } else {
    searchData = generateDirectSearchData(firstWord, words, lenSearchString)
  }

  const options = {
    includeScore: true,
    threshold: 0.8, // Adjust this threshold according to your requirement.
    minMatchCharLength: 3, // You might want to increase this for sentences.
    ignoreDiacritics: true,
    isCaseSensitive: false,
    shouldSort: true,
    findAllMatches: true,
    includeMatches: true,
    keys: ['text'] // This tells Fuse.js to search in the `text` property of the items in your list
  }

  const fuse = new Fuse(searchData, options)
  const result = fuse.search(searchString)

  if (result.length > 0) {
    const searchResult = result[0]?.item
    const startSpan = searchResult?.startSpan || 0
    const endSpan = searchResult?.endSpan || 0
    const startWordIdx = searchResult?.startWordIdx || 0
    const endWordIdx = searchResult?.endWordIdx || 0

    let firstHighlightedElement = null

    for (let i = startSpan; i <= endSpan; i++) {
      const spanToHighlight = spans[i]

      if (i === startSpan) {
        if (startWordIdx === 0) {
          highlightHtmlElement(spanToHighlight, highlightColor)
          if (!firstHighlightedElement)
            firstHighlightedElement = spanToHighlight
        } else {
          partialHighlight(startWordIdx, spanToHighlight, DIRECTION.START)
          if (!firstHighlightedElement)
            firstHighlightedElement = spanToHighlight
        }
      } else if (i === endSpan) {
        if (endWordIdx !== 0) {
          partialHighlight(endWordIdx, spanToHighlight, DIRECTION.END)
        }
      } else {
        highlightHtmlElement(spanToHighlight, highlightColor)
        if (!firstHighlightedElement) firstHighlightedElement = spanToHighlight
      }
    }

    // **Return first highlighted span for scrolling**
    return firstHighlightedElement
  }

  return null
}

const HIGHLIGHT_CLASSNAME = 'opacity-40 saturate-[3] highlighted-by-llama '

function highlightHtmlElement(div, color) {
  const text = div.textContent || ''
  const newSpan = document.createElement('span')
  newSpan.className = HIGHLIGHT_CLASSNAME + color
  newSpan.innerText = text
  div.innerText = ''
  div.appendChild(newSpan)
}

const DIRECTION = {
  START: 0,
  END: 1
}

function partialHighlight(idx, span, direction = DIRECTION.START) {
  const text = span.textContent
  if (!text) {
    return
  }
  const test = text.split(' ')[idx - 1] || ''
  const substringToHighlight = test

  // Remove existing content in the span
  span.textContent = ''

  // Split the text into pieces by the substring
  const parts = text.split(substringToHighlight)

  // For each piece, append it and the highlighted substring (except for the last piece)
  parts.forEach((part, index) => {
    if (direction === DIRECTION.START) {
      if (index === 0) {
        span.appendChild(document.createTextNode(part))
      } else {
        span.appendChild(document.createTextNode(test))
        const highlightSpan = document.createElement('span')
        highlightSpan.className = HIGHLIGHT_CLASSNAME
        highlightSpan.textContent = part
        span.appendChild(highlightSpan)
      }
    }

    if (direction === DIRECTION.END) {
      if (index === 0) {
        const highlightSpan = document.createElement('span')
        highlightSpan.className = HIGHLIGHT_CLASSNAME
        highlightSpan.textContent = part
        span.appendChild(highlightSpan)
        span.appendChild(document.createTextNode(part))
      } else {
        span.appendChild(document.createTextNode(test))
        span.appendChild(document.createTextNode(part))
      }
    }
  })
}

function generateFuzzySearchData(arr, n) {
  const searchStrings = []

  for (let i = 0; i <= arr.length - n; i++) {
    const text = arr
      .slice(i, i + n)
      .reduce((acc, val) => acc + ' ' + val.text, '')

    const startSpan = arr[i]?.spanIdx || 0
    const endSpan = arr[i + n]?.spanIdx || 0
    const startWordIdx = arr[i]?.wordIdx || 0
    const endWordIdx = arr[i + n]?.wordIdx || 0
    searchStrings.push({ text, startSpan, endSpan, startWordIdx, endWordIdx })
  }

  return searchStrings
}

function generateDirectSearchData(startString, words, n) {
  const searchStrings = []

  // for (let i = 0; i <= words.length - n; i++) {
  for (let i = 0; i <= words.length; i++) {
    if (words[i]?.text === "les") {
      // console.log('xxxx', words[i])
    }
    if (words[i]?.text === startString) {
      const text = words
        .slice(i, i + n)
        .map(w => w.text)
        .join(' ')

      const startSpan = words[i]?.spanIdx || 0
      const endSpan = words[Math.min(i + n - 1, words.length - 1)]?.spanIdx || 0 // Ensure within bounds
      const startWordIdx = words[i]?.wordIdx || 0
      const endWordIdx =
        words[Math.min(i + n - 1, words.length - 1)]?.wordIdx || 0

      searchStrings.push({ text, startSpan, endSpan, startWordIdx, endWordIdx })
    }
  }

  return searchStrings
}

function generateDirectSearchDataArabic(startString, words, n) {
  const searchStrings = [];

  for (let i = 0; i <= words.length - n; i++) {
    if (words[i]?.text.includes(startString)) { // Allow partial matches
      const text = words
        .slice(i, i + n)
        .map(w => w.text)
        .join(' ');

      const startSpan = words[i]?.spanIdx || 0;
      const endSpan = words[Math.min(i + n - 1, words.length - 1)]?.spanIdx || 0;
      const startWordIdx = words[i]?.wordIdx || 0;
      const endWordIdx =
        words[Math.min(i + n - 1, words.length - 1)]?.wordIdx || 0;

      searchStrings.push({ text, startSpan, endSpan, startWordIdx, endWordIdx });
    }
  }

  return searchStrings;
}


// function generateDirectSearchData (startString, words, n) {
//   const searchStrings = []

//   for (let i = 0; i <= words.length - n; i++) {
//     if (words[i]?.text === startString) {
//       const text = words
//         .slice(i, i + n)
//         .reduce((acc, val) => acc + ' ' + val.text, '')

//       const startSpan = words[i]?.spanIdx || 0
//       const endSpan = words[i + n]?.spanIdx || 0
//       const startWordIdx = words[i]?.wordIdx || 0
//       const endWordIdx = words[i + n]?.wordIdx || 0
//       searchStrings.push({
//         text,
//         startSpan,
//         endSpan,
//         startWordIdx,
//         endWordIdx
//       })
//     }
//   }

//   return searchStrings
// }

// function generateDirectSearchData(startString, words, n) {
//   const searchStrings = [];

//   for (let i = 0; i <= words.length - n; i++) {
//     const slicedWords = words.slice(i, i + n);
//     const text = slicedWords.map((w) => w.text).join(" ");

//     const startSpan = slicedWords[0]?.spanIdx || 0;
//     const endSpan = slicedWords[slicedWords.length - 1]?.spanIdx || 0;
//     const startWordIdx = slicedWords[0]?.wordIdx || 0;
//     const endWordIdx = slicedWords[slicedWords.length - 1]?.wordIdx || 0;

//     searchStrings.push({ text, startSpan, endSpan, startWordIdx, endWordIdx });
//   }

//   return searchStrings;
// }

export { multiHighlight, generateFuzzySearchData, generateDirectSearchData, generateDirectSearchDataArabic }
