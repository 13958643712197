import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Box,
    Typography,
    Chip,
    IconButton,
    Checkbox,
    Avatar,
    AvatarGroup,
    Tooltip,
    CircularProgress,
    useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
    Share as ShareIcon,
    AccessTime as AccessTimeIcon,
    CalendarToday as CalendarTodayIcon,
    People as PeopleIcon,
    DescriptionOutlined as DescriptionOutlinedIcon,
    ChatBubbleOutline as ChatIcon
} from '@mui/icons-material';
import { formatDateWithTimezoneOffset } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import NoConversationsPlaceholder from './NoConversationsPlaceholder';

// Helper function for date formatting for more consistent display
const formatDate = (dateString, language) => {
    if (!dateString) return '';
    const date = new Date(dateString);

    // Use the language parameter to format date according to user's locale
    return date.toLocaleDateString(language === 'ar' ? 'ar-EG' :
        language === 'fr' ? 'fr-FR' : 'en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

const ConversationCard = ({
    conversation,
    selected,
    handleCheckboxClick,
    handleOpenConversation,
    handleShareConversation,
    currentLang
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const hasDocuments = conversation.conversation_documents && conversation.conversation_documents.length > 0;
    const isSelected = selected.includes(conversation.id);

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                border: isSelected
                    ? `2px solid ${theme.palette.primary.main}`
                    : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                boxShadow: isSelected
                    ? `0 0 0 1px ${alpha(theme.palette.primary.main, 0.2)}`
                    : '0 1px 3px rgba(0, 0, 0, 0.05)',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.08)'
                },
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative'
            }}
            onClick={() => handleOpenConversation(conversation.id)}
        >
            {/* Checkbox in top-left corner */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 12,
                    left: 12,
                    zIndex: 2
                }}
            >
                <Checkbox
                    checked={isSelected}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCheckboxClick(e, conversation.id);
                    }}
                    sx={{
                        backgroundColor: isSelected ? 'transparent' : alpha(theme.palette.background.paper, 0.9),
                        borderRadius: 1,
                        padding: 0.5,
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.1)
                        }
                    }}
                />
            </Box>

            <CardContent sx={{ flexGrow: 1, p: 3, pt: 4 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 2
                }}>
                    {hasDocuments && (
                        <Chip
                            icon={<DescriptionOutlinedIcon style={{ fontSize: '0.85rem' }} />}
                            label={t('with_documents', { count: conversation.conversation_documents.length })}
                            size="small"
                            variant="outlined"
                            color="primary"
                            sx={{
                                height: 24,
                                '& .MuiChip-label': {
                                    px: 1,
                                    fontSize: '0.75rem'
                                }
                            }}
                        />
                    )}

                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                            backgroundColor: alpha(theme.palette.background.default, 0.7),
                            px: 1.5,
                            py: 0.5,
                            borderRadius: 5,
                            fontWeight: 500,
                            ml: 'auto'
                        }}
                    >
                        {t('conversation')}
                    </Typography>
                </Box>

                <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        mb: 2,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        lineHeight: 1.3,
                        height: '2.6rem'
                    }}
                >
                    {conversation.title || t('untitled_conversation')}
                </Typography>

                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <CalendarTodayIcon
                            fontSize="small"
                            sx={{ color: theme.palette.text.secondary, mr: 1, fontSize: '0.9rem' }}
                        />
                        <Typography variant="body2" color="text.secondary">
                            {formatDate(conversation.created_at, i18n.language)}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon
                            fontSize="small"
                            sx={{ color: theme.palette.text.secondary, mr: 1, fontSize: '0.9rem' }}
                        />
                        <Typography variant="body2" color="text.secondary">
                            {formatDate(conversation.updated_at, i18n.language)}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 'auto' }}>
                    <PeopleIcon
                        fontSize="small"
                        sx={{ color: theme.palette.text.secondary, mr: 1, fontSize: '0.9rem' }}
                    />

                    {conversation.users && conversation.users.length > 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AvatarGroup
                                max={3}
                                sx={{
                                    '& .MuiAvatar-root': {
                                        width: 28,
                                        height: 28,
                                        fontSize: '0.8rem',
                                        fontWeight: 600,
                                        border: `2px solid ${theme.palette.background.paper}`
                                    }
                                }}
                            >
                                {conversation.users.map((user, idx) => (
                                    <Tooltip
                                        key={idx}
                                        title={`${user.first_name} ${user.last_name}`}
                                    >
                                        <Avatar
                                            sx={{
                                                bgcolor: [
                                                    '#3B82F6', // Blue
                                                    '#10B981', // Green
                                                    '#F59E0B', // Amber
                                                    '#8B5CF6', // Purple
                                                    '#EC4899'  // Pink
                                                ][idx % 5]
                                            }}
                                        >
                                            {user.first_name[0]}{user.last_name[0]}
                                        </Avatar>
                                    </Tooltip>
                                ))}
                            </AvatarGroup>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{ ml: 1, fontWeight: 500 }}
                            >
                                {conversation.users.length}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                            {t('no_users')}
                        </Typography>
                    )}
                </Box>
            </CardContent>

            <CardActions
                sx={{
                    px: 2,
                    py: 1.5,
                    borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    bgcolor: alpha(theme.palette.background.default, 0.5),
                    justifyContent: 'flex-end'
                }}
            >
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleShareConversation(conversation.id);
                    }}
                    sx={{
                        color: theme.palette.text.secondary,
                        '&:hover': {
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(theme.palette.primary.main, 0.1)
                        }
                    }}
                    aria-label={t('share_conversation')}
                >
                    <ShareIcon fontSize="small" />
                </IconButton>
            </CardActions>
        </Card>
    );
};

const ConversationGrid = ({
    conversations,
    loading,
    selected,
    handleCheckboxClick,
    handleOpenConversation,
    handleShareConversation,
    handleCreateConversation,
    currentLang
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 5,
                minHeight: '300px'
            }}>
                <CircularProgress size={48} thickness={4} />
            </Box>
        );
    }

    if (!conversations.length) {
        return <NoConversationsPlaceholder handleCreateConversation={handleCreateConversation} />;
    }

    return (
        <Grid container spacing={3}>
            {conversations.map((conversation) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={conversation.id}>
                    <ConversationCard
                        conversation={conversation}
                        selected={selected}
                        handleCheckboxClick={handleCheckboxClick}
                        handleOpenConversation={handleOpenConversation}
                        handleShareConversation={handleShareConversation}
                        currentLang={currentLang}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ConversationGrid;