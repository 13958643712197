// Enhanced solution for matching text with significant paragraph spacing
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

// Create a unique plugin key
export const riskHighlightPluginKey = new PluginKey('riskHighlight');

// Helper function to escape special RegExp characters
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// Helper to normalize text for comparison
export function normalizeText(text) {
    return text.replace(/\s+/g, ' ').trim();
}

// Helper for splitting text into paragraphs
export function splitIntoParagraphs(html) {
    if (!html) return [];

    // Replace <br> tags with paragraph markers
    let text = html.replace(/<br\s*\/?>/gi, '|PARAGRAPH_BREAK|');

    // Remove all HTML tags
    text = text.replace(/<[^>]*>/g, '');

    // Split into paragraphs
    const paragraphs = text.split('|PARAGRAPH_BREAK|');

    // Clean and return non-empty paragraphs
    return paragraphs
        .map(p => normalizeText(p))
        .filter(p => p.length > 0);
}

export const RiskHighlight = Extension.create({
    name: 'riskHighlight',

    addOptions() {
        return {
            highlightClass: 'risk-highlight',
            highlightMarker: 'data-risk-highlight'
        };
    },

    addStorage() {
        return {
            errorText: '',
            isActive: false,
            paragraphs: [],
            highlightColor: '',
        };
    },

    addProseMirrorPlugins() {
        const { highlightClass, highlightMarker } = this.options;

        return [
            new Plugin({
                key: riskHighlightPluginKey,
                state: {
                    init() {
                        return DecorationSet.empty;
                    },
                    // Inside the addProseMirrorPlugins method, update the apply function
                    apply(tr, set) {
                        // Clear decorations if the document changed
                        set = set.map(tr.mapping, tr.doc);

                        // Check for custom metadata in the transaction
                        const highlightData = tr.getMeta(riskHighlightPluginKey);

                        if (highlightData === 'clear') {
                            // Clear all highlights
                            return DecorationSet.empty;
                        } else if (highlightData && typeof highlightData === 'object' && highlightData.paragraphs) {
                            // Add a new highlight using paragraphs
                            const decorations = [];
                            const paragraphs = highlightData.paragraphs;
                            const color = highlightData.color || '#d32f2f'; // Use the passed color or default to red

                            if (paragraphs.length > 0) {
                                const { doc } = tr;

                                // Process each paragraph as a separate search
                                for (const paragraph of paragraphs) {
                                    if (paragraph.length < 10) continue;

                                    // Find text nodes containing this paragraph text
                                    findParagraphInDocument(doc, paragraph, (from, to) => {
                                        decorations.push(
                                            Decoration.inline(from, to, {
                                                class: highlightClass,
                                                [highlightMarker]: 'true',
                                                style: `background-color: ${color}20; border-bottom: 1px solid ${color}` // Apply color with transparency
                                            })
                                        );
                                    });
                                }

                                console.log(`Created ${decorations.length} paragraph decorations with color ${color}`);
                            }

                            if (decorations.length > 0) {
                                return DecorationSet.create(tr.doc, decorations);
                            }
                            return DecorationSet.empty;
                        }

                        return set;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },

    addCommands() {
        return {
            highlightRiskText: (errorText, color = '#d32f2f') => ({ tr, dispatch }) => {
                try {
                    // Store the original text and color
                    this.storage.errorText = errorText;
                    this.storage.isActive = !!errorText;
                    this.storage.highlightColor = color; // Store the color

                    // Extract paragraphs
                    const paragraphs = splitIntoParagraphs(errorText);
                    this.storage.paragraphs = paragraphs;

                    console.log('Highlighting paragraphs:', paragraphs);
                    console.log('Using highlight color:', color);

                    if (dispatch) {
                        tr.setMeta(riskHighlightPluginKey, {
                            paragraphs,
                            color // Pass the color to the plugin
                        });
                        dispatch(tr);
                    }

                    return true;
                } catch (error) {
                    console.error('Error in highlightRiskText:', error);
                    return false;
                }
            },
            clearRiskHighlight: () => ({ tr, dispatch }) => {
                try {
                    // Clear stored data
                    this.storage.errorText = '';
                    this.storage.isActive = false;
                    this.storage.paragraphs = [];

                    if (dispatch) {
                        tr.setMeta(riskHighlightPluginKey, 'clear');
                        dispatch(tr);
                    }

                    return true;
                } catch (error) {
                    console.error('Error in clearRiskHighlight:', error);
                    return false;
                }
            },
        };
    },
});

// Helper function to find paragraph text in the document
function findParagraphInDocument(doc, paragraphText, callback) {
    const normalizedSearchText = normalizeText(paragraphText);
    const searchRegex = new RegExp(escapeRegExp(normalizedSearchText), 'i');

    // Check if the search text is too short
    if (normalizedSearchText.length < 10) return;

    // Find all paragraph nodes
    doc.descendants((node, pos) => {
        if (node.isText) {
            // For text nodes, check if they contain the paragraph
            const normalizedNodeText = normalizeText(node.text);

            if (searchRegex.test(normalizedNodeText)) {
                // Found a match, get precise position
                const match = normalizedNodeText.match(searchRegex);
                if (match && match.index !== undefined) {
                    const startPos = pos + match.index;
                    const endPos = startPos + match[0].length;
                    callback(startPos, endPos);
                }
            }
        }
        return true;
    });
}