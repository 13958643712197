// utils/config.js
const getBackendUrl = () => {
    if (window.location.hostname.includes('dev.juridia.ma')) {
        return 'https://api.dev.juridia.ma/';
        // return "http://back-end-dev-prod.grayflower-6e933215.westus2.azurecontainerapps.io/"
        // return "https://137.135.54.222/"
        // return "https://api.dev.juridia.ma/"
    }
      return process.env.REACT_APP_API_URL
    // return "http://13.93.229.12/"
    // return "https://137.135.54.222/"
    //   return "https://api.dev.juridia.ma/"
    //   return "http://back-end-dev-prod.westus.cloudapp.azure.com/"
    //   return "https://back-end-dev-prod.grayflower-6e933215.westus2.azurecontainerapps.io/"
};

export const KgBackendUrl = getBackendUrl();
export const backendUrl = getBackendUrl();
export const api_subscription_key = process.env.REACT_APP_API_SUBSCRIPTION_KEY;