import React, { useState, useEffect } from 'react'
import { PDFOptionsBar } from '../pdf-viewer/PdfOptionsBar'

const ViewHtml = ({
  selectedCitation,
  htmlContent,
  currentLang,
  iframeRef,
  document,
  setDocument
}) => {
  const [isLoadingDocument, setIsLoadingDocument] = useState(true)

  useEffect(() => {
    // Hide iframe until content is loaded
    setIsLoadingDocument(true)
    const timeout = setTimeout(() => {
      setIsLoadingDocument(false)
    }, 500) // Simulating load time

    return () => clearTimeout(timeout)
  }, [htmlContent])

  return (
    <div
      className='relative'
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <PDFOptionsBar
        documentDoc={document}
        selectedCitation={selectedCitation}
        iframeRef={iframeRef}
        setDocument={setDocument}
      />
      <iframe
        ref={iframeRef}
        srcDoc={htmlContent}
        width='100%'
        height='100%'
        sandbox='allow-same-origin allow-scripts allow-popups'
        style={{
          border: 'none',
          display: isLoadingDocument ? 'none' : 'block'
        }}
        dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
      />
    </div>
  )
}

export default ViewHtml
