import React, { useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, IconButton, Box, Typography, Paper, Collapse } from '@mui/material';
import CitationDisplay from './Citation';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const SubProcessDisplay = ({
  index,
  subProcesses,
  isOpen,
  toggleOpen,
  messageId,
  documents,
  showSpinner = true
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentDirection = currentLang === 'ar' ? 'rtl' : 'ltr';
  const citationsContainerRef = useRef(null);

  const subQuestions = [];

  subProcesses?.forEach(subProcess => {
    if (subProcess.metadata_map?.sub_question) {
      subQuestions.push({
        subQuestion: subProcess.metadata_map?.sub_question
      });
    } else if (subProcess.metadata_map?.sub_questions) {
      subProcess.metadata_map?.sub_questions.forEach(subQuestion => {
        subQuestions.push({
          subQuestion
        });
      });
    }
  });

  const decodeString = encodedString => {
    try {
      return JSON.parse(`${encodedString}`);
    } catch (e) {
      return encodedString;
    }
  };

  const capitalizedSentence = sentence => {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  const handleToggle = () => {
    toggleOpen();
    ReactGA.event({
      category: 'SubProcess',
      action: isOpen ? 'Collapsed SubProcess View' : 'Expanded SubProcess View',
      label: `Message ID: ${messageId}`
    });
  };

  const scrollCitations = (direction, subQuestionIndex) => {
    const container = document.querySelector(
      `.assistant-subprocess-sources-${subQuestionIndex}`
    );

    if (container) {
      const scrollAmount = 200;

      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    } else {
      console.warn(
        `Citations container for subQuestionIndex ${subQuestionIndex} is not available.`
      );
    }
  };

  return (
    <Box
      key={`${messageId}-sub-process`}
      sx={{
        mt: 2,
        borderRadius: 1,
        direction: currentDirection
      }}
    >
      <Box 
        sx={{ 
          display: "flex", 
          alignItems: "center" 
        }}
      >
        <AutoAwesomeIcon 
          sx={{ 
            mr: 1,
            color: '#000000',
            fontSize: 20
          }} 
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 1,
            p: 0.5,
            cursor: 'pointer',
            fontFamily: "'Nunito', sans-serif",
            fontSize: '14px',
            color: '#000000',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
          onClick={handleToggle}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t('view_progress')}
          </Typography>
          <Box sx={{ px: 1.5, py: 0.5 }}>
            <ExpandMoreIcon
              className={
                currentLang === 'ar'
                  ? isOpen
                    ? 'rotate-180'
                    : ''
                  : isOpen
                    ? ''
                    : '-rotate-90'
              }
              fontSize="small"
            />
          </Box>
        </Box>
      </Box>

      <Collapse in={isOpen} timeout="auto">
        <Box
          sx={{
            ml: 4,
            borderLeft: '1px solid #e0e0e0',
            maxWidth: 'xl',
            pl: 2,
            pb: 1,
            fontFamily: "'Nunito', sans-serif",
            fontSize: '11px',
            fontWeight: 'light',
            color: 'text.secondary'
          }}
        >
          <Typography variant="caption" sx={{ display: 'block', mb: 1 }}>
            {t('question_received')}
          </Typography>
          
          {subQuestions.length > 0 && (
            <Box sx={{ color: 'text.secondary' }}>
              {subQuestions.map((subQuestionData, subQuestionIndex) => {
                const { subQuestion } = subQuestionData;
                const hasCitations = !!subQuestion.citations;

                const citations = subQuestion?.citations?.map(cts => cts) || [];

                const loiCitations = citations.filter(
                  cts => cts?.doc_type?.toLowerCase() !== 'jurisprudence'
                );
                const jpCitations = citations.filter(
                  cts => cts?.doc_type?.toLowerCase() === 'jurisprudence'
                );

                const allCitations = [...loiCitations, ...jpCitations];
                const showArrows = allCitations.length > 3;

                return (
                  <Box
                    className={`assistant-subprocess-${subQuestionIndex}`}
                    key={`${messageId}-${subQuestionIndex}`}
                    sx={{ mb: 2 }}
                  >
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        display: 'block', 
                        mb: 1,
                        color: 'text.secondary'
                      }}
                    >
                      {t('generated_subquestion')} #{subQuestionIndex + 1}
                    </Typography>
                    
                    <Paper
                      elevation={0}
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid #e0e0e0',
                        borderRadius: 1,
                        overflow: 'hidden',
                        mr: 2
                      }}
                    >
                      <Box
                        className={`assistant-subprocess-question-${subQuestionIndex}`}
                        sx={{
                          borderBottom: '1px solid #e0e0e0',
                          backgroundColor: '#f5f5f5',
                          p: 1.5,
                          fontWeight: 'bold',
                          color: '#333'
                        }}
                      >
                        {decodeString(capitalizedSentence(subQuestion.question))}
                      </Box>
                      
                      <Box
                        className={`assistant-subprocess-answer-${subQuestionIndex}`}
                        sx={{
                          p: 1.5,
                          fontSize: '11px',
                          fontWeight: 'light',
                          overflowY: 'auto',
                          maxHeight: '120px'
                        }}
                      >
                        {subQuestion.answer}
                      </Box>

                      {hasCitations && (
                        <Box sx={{ position: 'relative' }}>
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              zIndex: 10,
                              pointerEvents: 'none'
                            }}
                          >
                            {showArrows && (
                              <>
                                <IconButton
                                  onClick={() => scrollCitations('left', subQuestionIndex)}
                                  sx={{
                                    backgroundColor: 'white',
                                    boxShadow: 1,
                                    pointerEvents: 'auto',
                                    '&:hover': {
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                  size="small"
                                >
                                  <ArrowBackIosIcon sx={{ fontSize: 12 }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => scrollCitations('right', subQuestionIndex)}
                                  sx={{
                                    backgroundColor: 'white',
                                    boxShadow: 1,
                                    pointerEvents: 'auto',
                                    '&:hover': {
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                  size="small"
                                >
                                  <ArrowForwardIosIcon sx={{ fontSize: 12 }} />
                                </IconButton>
                              </>
                            )}
                          </Box>
                          
                          <Box
                            ref={citationsContainerRef}
                            className={`assistant-subprocess-sources-${subQuestionIndex}`}
                            sx={{
                              display: 'flex',
                              width: '100%',
                              overflowX: 'auto',
                              pl: 1,
                              pr: 1,
                              pb: 1,
                              pt: 1, // Added padding top
                              position: 'relative', // Added position relative
                              '&::-webkit-scrollbar': {
                                height: '6px',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#bbbbbb',
                                borderRadius: '6px',
                              }
                            }}
                          >
                            {allCitations.map((citation, citationIndex) => (
                              <Box
                                key={`${messageId}-${subQuestionIndex}-${citationIndex}`}
                                sx={{
                                  minWidth: '160px',
                                  margin: '0 4px',
                                  position: 'relative' // Ensure proper stacking context
                                }}
                              >
                                <CitationDisplay
                                  citation={{
                                    page_content: citation.text,
                                    metadata: {
                                      source_content: citation.source_content,
                                      page_number: citation.page_number,
                                      count_articles: citation.count_articles,
                                      count_annexes: citation.count_annexes,
                                      count_unclassified_section: citation.count_unclassified_section,
                                      chunk_position: citation.chunk_position,
                                      creation_date: citation.creation_date,
                                      doc_type: citation.doc_type,
                                      document_id: citation.document_id,
                                      file_name: citation.file_name,
                                      language: citation.language,
                                      long_title: citation.long_title,
                                      score: citation.score,
                                      section_title: citation.section_title,
                                      short_title: citation.short_title,
                                      total_chunks: citation.total_chunks,
                                      color: 'yellow'
                                    }
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>

        {showSpinner && (
          <Box sx={{ ml: 4, mt: 1, display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={16} thickness={5} />
            <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
              {t('processing')}
            </Typography>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default SubProcessDisplay;