import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    CircularProgress,
    Container,
    Alert,
    Grid,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { backendClient } from '../../api/backend';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions/authAction';
import CustomButton from '../../components/CustomButton';
import { colors } from '../../utils/colors';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/logo.svg';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const InvitationScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = useQuery();
    const token = query.get('token');

    const [tokenInfo, setTokenInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [registering, setRegistering] = useState(false);

    useEffect(() => {
        // Verify the token and get information
        const verifyToken = async () => {
            try {
                // Decode token on the client side to extract email
                // In a real implementation, you would verify this on the server
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                        .join('')
                );

                const payload = JSON.parse(jsonPayload);

                if (!payload.invitation || !payload.email) {
                    throw new Error('Invalid invitation token');
                }

                setTokenInfo(payload);
                setFormData(prev => ({
                    ...prev,
                    email: payload.email,
                }));
            } catch (error) {
                console.error('Error verifying invitation token:', error);
                setError(t('invalid_invitation_link', 'Invalid or expired invitation link'));
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            verifyToken();
        } else {
            setError(t('missing_token', 'Invitation token is missing'));
            setLoading(false);
        }
    }, [token, t]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Clear validation error when user types
        if (validationErrors[name]) {
            setValidationErrors({
                ...validationErrors,
                [name]: '',
            });
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.first_name.trim()) {
            errors.first_name = t('first_name_required', 'First name is required');
        }

        if (!formData.last_name.trim()) {
            errors.last_name = t('last_name_required', 'Last name is required');
        }

        if (!formData.phone_number.trim()) {
            errors.phone_number = t('phone_required', 'Phone number is required');
        } else if (!/^\d{10}$/.test(formData.phone_number)) {
            errors.phone_number = t('invalid_phone', 'Please enter a valid 10-digit phone number');
        }

        if (!formData.password) {
            errors.password = t('password_required', 'Password is required');
        } else if (formData.password.length < 8) {
            errors.password = t('password_length', 'Password must be at least 8 characters');
        }

        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = t('passwords_dont_match', 'Passwords do not match');
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setRegistering(true);
        setError(''); // Clear any previous errors

        try {
            // Submit the form to register the invited user
            const response = await backendClient.post('api/user/register-invited', {
                email: formData.email,
                first_name: formData.first_name,
                last_name: formData.last_name,
                phone_number: formData.phone_number,
                organization_name: tokenInfo?.organization_name,
                password: formData.password,
                user_type: 'user', // Default user type for invited users
                token: token
            });

            console.log('tokenInfo', tokenInfo)

            // Check if the response is OK before parsing JSON
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Error registering account');
            }

            const data = await response.json();

            // Check if we have a successful response with token and user
            if (data.token && data.user) {
                // Log in the user automatically
                await dispatch(login(formData.email, formData.password, navigate, tokenInfo?.domain_name));


                // Redirect to the dashboard
                navigate('/');
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Error registering invited user:', error);

            // Handle different error types
            if (error.response) {
                // The server responded with a status code outside the 2xx range
                try {
                    const errorData = await error.response.json();

                    // Handle specific error types from backend
                    if (errorData.detail && errorData.detail.includes('already exists')) {
                        setError(t('email_already_exists', 'An account with this email already exists.'));
                    } else if (errorData.detail && errorData.detail.includes('Invalid or expired invitation token')) {
                        setError(t('expired_invitation', 'Your invitation link has expired. Please contact your administrator.'));
                    } else {
                        setError(errorData.detail || t('registration_error', 'Error registering account'));
                    }
                } catch (jsonError) {
                    // If we can't parse the error as JSON
                    setError(error.response.statusText || t('registration_error', 'Error registering account'));
                }
            } else if (error.request) {
                // The request was made but no response was received
                setError(t('network_error', 'Network error. Please check your connection and try again.'));
            } else {
                // Something happened in setting up the request that triggered an error
                setError(error.message || t('registration_error', 'Error registering account'));
            }
        } finally {
            setRegistering(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
                <Typography align="center">
                    {t('contact_admin', 'Please contact your administrator for assistance.')}
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={4} sx={{ p: 4, borderRadius: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: '50%', mb: 2 }}>
                        <Logo style={{ width: 60, height: 60 }} />
                    </Box>
                    <Typography variant="h4" gutterBottom>
                        {t('complete_registration', 'Complete Your Registration')}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
                        {t('invited_to_join', 'You have been invited to join')} <strong>{tokenInfo?.organization_name}</strong>
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={t('first_name', 'First Name')}
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                error={!!validationErrors.first_name}
                                helperText={validationErrors.first_name}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={t('last_name', 'Last Name')}
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                error={!!validationErrors.last_name}
                                helperText={validationErrors.last_name}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('email', 'Email')}
                                name="email"
                                value={formData.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('phone_number', 'Phone Number')}
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                error={!!validationErrors.phone_number}
                                helperText={validationErrors.phone_number}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={t('password', 'Password')}
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleInputChange}
                                error={!!validationErrors.password}
                                helperText={validationErrors.password}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={t('confirm_password', 'Confirm Password')}
                                name="confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                error={!!validationErrors.confirmPassword}
                                helperText={validationErrors.confirmPassword}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <CustomButton
                            text={t('complete_registration', 'Complete Registration')}
                            loading={registering}
                            type="submit"
                            sx={{ px: 4, py: 1.5 }}
                        />
                    </Box>
                </form>
            </Paper>
        </Container>
    );
};

export default InvitationScreen;