import React from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

// TranslationProgress component
const TranslationProgress = ({
  currentProgress,
}) => {
  return (
    <Box sx={{
      width: '300px',
      backgroundColor: 'white',
      padding: 2,
      borderRadius: 2,
    }}>
      <LinearProgress
        variant="determinate"
        value={currentProgress.progress}
        sx={{
          height: 6,
          borderRadius: 3,
          backgroundColor: 'rgba(0,0,0,0.1)',
          '& .MuiLinearProgress-bar': {
            borderRadius: 3,
          }
        }}
      />
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 1, textAlign: 'center' }}
      >
        {Math.round(currentProgress.progress)}%
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 1, textAlign: 'center' }}
      >
        {currentProgress.message}
      </Typography>
    </Box>
  );
};

export default TranslationProgress;