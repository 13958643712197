import React from "react";
import AssistantDisplay from "./AssistantDisplay";
import UserDisplay from "./UserDisplay";
import Citations from "./Citations";
import { ROLE } from "../../../utils/constants";
import { getCitations } from "../../../utils/utils";
import { Box, Paper } from "@mui/material";

const MemoizedAssistantDisplay = React.memo(AssistantDisplay);

const Message = ({
  conversationId,
  message,
  index,
  messagesLength,
  documents,
  showLoading,
}) => {
  const citations = message.sources ? message.sources : [];
  const role = message.role;

  return (
    <Box
      sx={{
        mb: index === messagesLength - 1 ? 2 : 0,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        mt: 1
      }}
      key={`message-${message.id}`}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          borderRadius: 2,
          overflow: "hidden"
        }}
      >
        {role === ROLE.ASSISTANT ? (
          <MemoizedAssistantDisplay
            conversationId={conversationId}
            message={message}
            key={`${message.id}-answer-${index}`}
            documents={documents}
          />
        ) : role === ROLE.USER ? (
          <UserDisplay
            conversationId={conversationId}
            message={message}
            key={`${message.id}-question-${index}-user`}
            showLoading={index === messagesLength - 1 ? showLoading : false}
          />
        ) : (
          <Box 
            sx={{ 
              p: 2, 
              color: "error.main", 
              textAlign: "center",
              fontSize: "0.875rem"
            }}
          >
            Sorry, there is a problem.
          </Box>
        )}
      </Paper>

      {citations.length > 0 && role === ROLE.ASSISTANT && (
        <Citations messageId={message.id} citations={citations} />
      )}
    </Box>
  );
};

export default Message;