import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import SubProcessDisplay from "./SubProcessDisplay";
import { Edit as EditIcon } from "@mui/icons-material";
import { messageActions } from '../../../redux/actions/messageAction';
import ErrorMessageDisplay from "./ErrorMessageDisplay";
import { TextDirection } from "../../../utils/constants";
import { Box, Tooltip, Divider, Checkbox, Typography, Paper, Button, Fade, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateMessageContent } from "../../../redux/actions/conversationAction";
import { formatTextToHTML } from "../../../utils/utils";
import SmartToyIcon from '@mui/icons-material/SmartToy';

const LazyTextEditor = lazy(() => import("../../../components/QuillEditor"));

const AssistantDisplay = ({ conversationId, message, documents }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentDirection = currentLang === "ar" ? "rtl" : "ltr";

  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(message.content);

  const DynamicTextEditor = useMemo(() => {
    return (
      <Suspense fallback={
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress size={24} />
        </Box>
      }>
        <LazyTextEditor value={content} setValue={setContent} />
      </Suspense>
    );
  }, [content, t]);

  const dispatch = useDispatch();

  const isMessageSuccessful = message.status === "SUCCESS";
  const isMessageError = message.status === "ERROR";

  const shouldOnboarding = useSelector((state) => state.user);
  const activeStep = useSelector((state) => state.user.activeStep);

  useEffect(() => {
    setContent(message.content);
  }, [message]);

  useEffect(() => {
    if (isMessageSuccessful && !activeStep) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful, activeStep]);

  const handleSave = () => {
    dispatch(updateMessageContent(conversationId, message.id, content));
    dispatch(messageActions.editMessage(message.id, content));
    setIsEditing(false);
  };

  return (
    <Fade in={true} timeout={500}>
      <Box
        sx={{
          pb: 2,
          pr: 2,
          direction: currentDirection,
          transition: "all 0.3s ease",
          borderRadius: 1,
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box className="assistant-message-container" sx={{ display: "flex" }}>
          <Box sx={{ 
            display: 'flex',
            pl: 1.5,
            pt: 1.5,
            width: "100%" 
          }}>

            
            {!isMessageError && (
              <Box sx={{ 
                display: "flex", 
                flexDirection: "column", 
                flexGrow: 1,
                width: "100%"
              }}>
                <SubProcessDisplay
                  key={`${message.id}-sub-process`}
                  subProcesses={message.sub_processes || []}
                  isOpen={isExpanded}
                  toggleOpen={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                  showSpinner={!isMessageSuccessful}
                  messageId={message.id}
                  documents={documents}
                  like={message.like}
                />
              </Box>
            )}
            
            {isMessageError && <ErrorMessageDisplay />}
          </Box>
        </Box>

        {!isMessageError && (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 2 }}>
              <Divider sx={{ width: "92%", borderColor: "rgba(0, 0, 0, 0.08)" }} />
            </Box>
            
            <Box sx={{ display: "flex", alignItems: "center", pr: 0 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!isEditing && message.status === "SUCCESS" && (
                  <Box 
                    sx={{ 
                      display: "flex", 
                      alignItems: "center",
                      cursor: "pointer",
                      color: "text.secondary"
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={message?.checked || false}
                      onChange={() => dispatch(messageActions.checkMessage(message.id))}
                      sx={{ 
                        ml: currentLang === "ar" ? 0 : -1,
                        mr: currentLang === "ar" ? -1 : 0
                      }}
                    />
                  </Box>
                )}
              </Box>

              {isEditing ? (
                <Box sx={{ width: "100%" }}>
                  {DynamicTextEditor}
                  <Box sx={{ 
                    display: "flex", 
                    justifyContent: "flex-end", 
                    gap: 2, 
                    mt: 2 
                  }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleSave}
                      sx={{ 
                        mr: 1,
                        textTransform: "none",
                        borderColor: "#2B3175",
                        color: "#2B3175",
                        "&:hover": {
                          borderColor: "#1a237e",
                          backgroundColor: "rgba(26, 35, 126, 0.04)"
                        }
                      }}
                    >
                      {t("save")}
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => setIsEditing(false)}
                      sx={{ 
                        textTransform: "none",
                        color: "text.secondary"
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ width: "100%" }}>
                  {!isEditing && message.status === "SUCCESS" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                        cursor: "pointer",
                        mb: 1,
                        opacity: isHovering ? 1 : 0,
                        transition: "opacity 0.2s ease",
                        color: "text.secondary",
                        "&:hover": {
                          color: "primary.main"
                        }
                      }}
                      onClick={() => setIsEditing(true)}
                    >
                      <EditIcon fontSize="small" />
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {t("edit")}
                      </Typography>
                    </Box>
                  )}
                  
                  {message.content && (
                    <Box 
                      className="assistant-message-content"
                      sx={{
                        width: "100%",
                        fontFamily: "'Nunito', sans-serif",
                        fontSize: "14px",
                        color: "#333",
                        pb: 1
                      }}
                    >
                      <Box
                        className="ql-editor"
                        sx={{
                          width: "100%",
                          ml: 0.5,
                          whiteSpace: "pre-wrap",
                          fontFamily: "'Nunito', sans-serif",
                          fontSize: "14px",
                          color: "#333",
                          direction: currentDirection,
                          textAlign: currentLang === "ar" ? "right" : "left",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formatTextToHTML(message.content),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}

        <Typography
          variant="caption"
          sx={{
            display: "flex",
            ml: 5,
            mt: 2,
            justifyContent: "flex-start",
            p: 1,
            color: "text.disabled",
            fontStyle: "italic",
            direction: currentDirection
          }}
        >
          {t("disclaimer")}
        </Typography>
      </Box>
    </Fade>
  );
};

export default AssistantDisplay;