import { Extension } from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import { FontFamily } from '@tiptap/extension-font-family'
import { FontSize } from '@tiptap/extension-font-size'
import { TextStyle } from '@tiptap/extension-text-style'

/**
 * TextColor Extension - Provides text color functionality
 */
export const TextColor = Extension.create({
    name: 'textColor',

    addExtensions() {
        return [
            TextStyle.configure(),
            Color.configure()
        ]
    },
})

/**
 * TypographyExtension - Provides font size and font family functionality
 */
export const TypographyExtension = Extension.create({
    name: 'typography',

    addExtensions() {
        return [
            TextStyle.configure(),
            FontSize.configure({
                types: ['textStyle'],
            }),
            FontFamily.configure({
                types: ['textStyle'],
            }),
        ]
    },
})


/**
 * Sets font size and updates toolbar state
 * @param {Editor} editor - TipTap editor instance
 * @param {string} size - Font size value (e.g., '16px')
 * @returns {boolean} - Success status
 */
export const setFontSize = (editor, size) => {
    if (!editor || !size) return false
    return editor.chain().focus().setFontSize(size).run()
}

/**
 * Sets font family and updates toolbar state
 * @param {Editor} editor - TipTap editor instance
 * @param {string} fontFamily - Font family value
 * @returns {boolean} - Success status
 */
export const setFontFamily = (editor, fontFamily) => {
    if (!editor || !fontFamily) return false
    return editor.chain().focus().setFontFamily(fontFamily).run()
}

/**
 * Gets all text formatting attributes from current selection
 * @param {Editor} editor - TipTap editor instance
 * @returns {Object} - Object with color, fontSize, and fontFamily values
 */
export const getFormattingAttributes = (editor) => {
    if (!editor) return { color: '#000000', fontSize: '16px', fontFamily: 'Arial, sans-serif' }

    try {
        // Get current selection
        const { from, to, empty } = editor.state.selection
        let attributes = { color: null, fontSize: null, fontFamily: null }

        // For empty selection (cursor), check marks at cursor
        if (empty) {
            // Get marks at cursor position
            const marks = editor.state.selection.$from.marks()

            // Look for textStyle mark
            for (const mark of marks) {
                if (mark.type.name === 'textStyle') {
                    // Extract all relevant attributes
                    if (mark.attrs.color) attributes.color = mark.attrs.color
                    if (mark.attrs.fontSize) attributes.fontSize = mark.attrs.fontSize
                    if (mark.attrs.fontFamily) attributes.fontFamily = mark.attrs.fontFamily
                }
            }

            // If no attributes at cursor, check previous position
            if (!attributes.color && !attributes.fontSize && !attributes.fontFamily && from > 0) {
                const prevPos = Math.max(0, from - 1)
                const prevNode = editor.state.doc.nodeAt(prevPos)

                if (prevNode && prevNode.marks) {
                    for (const mark of prevNode.marks) {
                        if (mark.type.name === 'textStyle') {
                            // Extract attributes from previous node
                            if (mark.attrs.color && !attributes.color) attributes.color = mark.attrs.color
                            if (mark.attrs.fontSize && !attributes.fontSize) attributes.fontSize = mark.attrs.fontSize
                            if (mark.attrs.fontFamily && !attributes.fontFamily) attributes.fontFamily = mark.attrs.fontFamily
                        }
                    }
                }
            }
        }
        // For text selection, scan through selected content
        else {
            // Start with the first position
            let pos = from

            // Iterate through positions in the selection
            while (pos < to) {
                const node = editor.state.doc.nodeAt(pos)

                if (node && node.marks) {
                    for (const mark of node.marks) {
                        if (mark.type.name === 'textStyle') {
                            // Extract attributes
                            if (mark.attrs.color && !attributes.color) attributes.color = mark.attrs.color
                            if (mark.attrs.fontSize && !attributes.fontSize) attributes.fontSize = mark.attrs.fontSize
                            if (mark.attrs.fontFamily && !attributes.fontFamily) attributes.fontFamily = mark.attrs.fontFamily

                            // If we found all attributes, exit early
                            if (attributes.color && attributes.fontSize && attributes.fontFamily) {
                                return {
                                    color: attributes.color || '#000000',
                                    fontSize: attributes.fontSize || '16px',
                                    fontFamily: attributes.fontFamily || 'Arial, sans-serif'
                                }
                            }
                        }
                    }
                }

                // Move to next position
                pos += node ? node.nodeSize : 1
            }
        }

        // Return attributes with defaults for missing values
        return {
            color: attributes.color || '#000000',
            fontSize: attributes.fontSize || '16px',
            fontFamily: attributes.fontFamily || 'Arial, sans-serif'
        }
    } catch (err) {
        console.error('Error getting text formatting attributes:', err)
        return { color: '#000000', fontSize: '16px', fontFamily: 'Arial, sans-serif' }
    }
}


/**
 * Gets current font size from selection
 * @param {Editor} editor - TipTap editor instance
 * @returns {string} - Current font size
 */
export const getCurrentFontSize = (editor) => {
    if (!editor) return '16px'
    return getFormattingAttributes(editor).fontSize
}

/**
 * Gets current font family from selection
 * @param {Editor} editor - TipTap editor instance
 * @returns {string} - Current font family
 */
export const getCurrentFontFamily = (editor) => {
    if (!editor) return 'Arial, sans-serif'
    return getFormattingAttributes(editor).fontFamily
}
