import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, Typography, useTheme, Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import ReactGA from 'react-ga4';
import _ from 'lodash';


// Import components
import DashboardHeader from './components/DashboardHeader';
import SearchFilterBar from './components/SearchFilterBar';
import StatsSummary from './components/StatsSummary';
import ChartsOverview from './components/ChartsOverview';
import ContractTabs from './components/ContractTabs';
import ContractGrid from './components/ContractGrid';
import ContractList from './components/ContractList';
import LoadingState from './components/LoadingState';
import EmptyState from './components/EmptyState';
import ContractFilterDrawer from './components/ContractFilterDrawer';

// Import dialogs
import ContractDetailDialog from './dialogs/ContractDetailDialog';
import DownloadDialog from './dialogs/DownloadDialog';
import ShareContractModal from './components/ShareContractModal';
import DeleteConfirmationDialog from './dialogs/DeleteConfirmationDialog';

// Import actions
import {
    fetchContracts,
    deleteContracts,
    searchContracts,
    shareContract,
    resetContract,
    handleUpdateContract
} from '../../redux/actions/contractAction';
import { filterContracts } from '../../redux/actions/contractAction';




// Import API client
import { backendClient } from '../../api/backend';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getDomainName, statusColors } from '../../utils/utils';

const ContractHistory = () => {
    // Redux state
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth?.user?.user);
    const loading = useSelector(state => state.contract?.loading || false);
    const contracts = useSelector(state => state.contract?.contracts || []);

    // Router
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    // Translation 
    const { t, i18n } = useTranslation();

    // Local state
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [currentContractId, setCurrentContractId] = useState(null);
    const [activeView, setActiveView] = useState('grid');
    const [activeTab, setActiveTab] = useState(0);
    const [page, setPage] = useState(1);
    const rowsPerPage = 8; // Increased for better visibility

    // Filter state
    const [activeFilter, setActiveFilter] = useState('none');
    const [sortOrder, setSortOrder] = useState('none');
    const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
    const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(0);
    const filteredContracts = useSelector(state => state.contract?.filteredContracts || []);
    const loadingFilter = useSelector(state => state.contract?.loadingFilter || false);


    // Dialog flow state
    const [dialogSource, setDialogSource] = useState(null);

    // Chart data
    const [statusData, setStatusData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [totalContracts, setTotalContracts] = useState(0);

    // Dialog states
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    // Initialize Google Analytics
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: '/contract-dashboard',
            title: 'Contract Dashboard'
        });
    }, []);

    // Fetch total count of contracts
    const fetchTotalCount = async () => {
        try {
            const { totalContracts: count } = await backendClient.getContractsCountUniqueByUser(user?.id);
            setTotalContracts(count);
        } catch (error) {
            console.error('Error fetching total contracts count:', error);
            // enqueueSnackbar(t('failed_fetch_total_contracts'), { variant: 'error' });
        }
    };

    // Fetch contracts when user or page changes
    useEffect(() => {
        if (user && user.id) {
            fetchTotalCount();
            const offset = (page - 1) * rowsPerPage;
            dispatch(fetchContracts(user.id, rowsPerPage, offset, true));
        }
    }, [user, dispatch, page, rowsPerPage]);

    // Process contract data for charts
    useEffect(() => {
        if (contracts && contracts.length > 0) {
            // Process status data
            const statuses = _.groupBy(contracts, 'status');
            const statusStats = Object.keys(statuses).map(status => ({
                name: status,
                value: statuses[status].length,
                color: statusColors[status] || '#94A3B8' // Slate color as fallback
            }));
            setStatusData(statusStats);

            // Process type data
            const types = _.groupBy(contracts, 'type');
            const typeStats = Object.keys(types).map((type, index) => ({
                name: type || 'Unspecified',
                value: types[type].length,
                color: [
                    '#3B82F6', // Blue
                    '#10B981', // Green
                    '#F59E0B', // Amber
                    '#8B5CF6', // Violet
                    '#EC4899'  // Pink
                ][index % 5]
            }));
            setTypeData(typeStats);

            // Process monthly data
            const monthlyStats = processMonthlyData(contracts);
            setMonthlyData(monthlyStats);
        }
    }, [contracts]);

    const handleAdvancedFilter = (filteredResults) => {
        // If we have filtered results, use them, otherwise show all contracts
        if (filteredResults && filteredResults.length > 0) {
            // Count the number of active filters (this could be more sophisticated based on your needs)
            setActiveAdvancedFilters(1);
        } else {
            setActiveAdvancedFilters(0);
        }
    };

    const handleOpenAdvancedFilter = () => {
        setIsAdvancedFilterOpen(true);
    };

    const handleCloseAdvancedFilter = () => {
        setIsAdvancedFilterOpen(false);
    };

    // Process monthly data for the line chart
    const processMonthlyData = (contracts) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthlyData = months.map(month => ({
            name: month,
            Created: 0,
            Updated: 0,
            Signed: 0
        }));

        contracts.forEach(contract => {
            if (!contract.created_at || !contract.updated_at) return;

            const createdDate = new Date(contract.created_at);
            const updatedDate = new Date(contract.updated_at);
            const createdMonth = createdDate.getMonth();
            const updatedMonth = updatedDate.getMonth();

            monthlyData[createdMonth].Created += 1;
            monthlyData[updatedMonth].Updated += 1;

            if (contract.status === 'SIGNED' || contract.status === 'signé') {
                monthlyData[updatedMonth].Signed += 1;
            }
        });

        return monthlyData;
    };

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce(term => {
            if (user && user.id) {
                dispatch(searchContracts(user.id, term));
            }
        }, 500),
        [dispatch, user]
    );

    const handleSearch = event => {
        const term = event.target.value;
        setSearchTerm(term);
        debouncedSearch(term);

        // Google Analytics event for search
        ReactGA.event({
            category: 'Search',
            action: 'User searched contracts',
            label: term
        });
    };

    // Handle page change
    const handlePageChange = (event, newPage) => {
        // Ensure newPage is an integer
        const pageNumber = parseInt(newPage, 10);

        setPage(pageNumber);

        // When not filtering, use server-side pagination
        if (searchTerm.trim() === '' && activeFilter === 'none' && activeTab === 0) {
            const offset = (pageNumber - 1) * rowsPerPage;
            dispatch(fetchContracts(user.id, rowsPerPage, offset));
        }
    };

    // Dialog management functions
    const handleContractClick = (contract) => {
        setSelectedContract(contract);
        setIsDetailModalOpen(true);
        setDialogSource(null); // Reset source since this is the first dialog
    };

    const handleCloseDetailDialog = () => {
        setIsDetailModalOpen(false);
    };

    const handleDownloadContract = (contractId, source = null) => {
        setCurrentContractId(contractId);
        setIsDownloadDialogOpen(true);
        setDialogSource(source); // Track which dialog opened this one

        // Google Analytics Event
        ReactGA.event({
            category: 'Contract',
            action: 'User initiated downloading contract',
            label: `Contract ID: ${contractId}`
        });
    };

    const handleCloseDownloadDialog = () => {
        setIsDownloadDialogOpen(false);

        // If opened from detail dialog, return to it
        if (dialogSource === 'detail') {
            setIsDetailModalOpen(true);
        }
    };

    const handleShareContract = (contractId, source = null) => {
        setCurrentContractId(contractId);
        setIsShareModalOpen(true);
        setDialogSource(source); // Track which dialog opened this one

        // Google Analytics Event
        ReactGA.event({
            category: 'Contract',
            action: 'User initiated sharing contract',
            label: `Contract ID: ${contractId}`
        });
    };


    const handleCloseShareDialog = () => {
        setIsShareModalOpen(false);

        // If opened from detail dialog, return to it
        if (dialogSource === 'detail') {
            setIsDetailModalOpen(true);
        }
    };

    // Handle share
    // Handle share
    const handleShare = async selectedUsers => {
        if (currentContractId && selectedUsers?.length > 0) {
            // Get user domain
            const domainName = getDomainName(user.email)

            try {
                
                await dispatch(shareContract(currentContractId, selectedUsers, domainName));
                enqueueSnackbar(t("contract_shared_success"), { variant: 'success' });

                // Refresh contracts
                // const offset = (page - 1) * rowsPerPage;
                // dispatch(fetchContracts(user.id, rowsPerPage, offset));

                // Google Analytics event for successful share
                ReactGA.event({
                    category: 'Contract',
                    action: 'Contract shared successfully',
                    label: `Contract ID: ${currentContractId}`
                });

                // Don't return to detail dialog after successful share
                // This is a deliberate choice as the action is complete
                setIsShareModalOpen(false);
            } catch (error) {
                console.error('Error sharing contract:', error);
                enqueueSnackbar(t("contract_share_error"), { variant: 'error' });

                // On error, we might want to go back to the detail dialog
                if (dialogSource === 'detail') {
                    setIsDetailModalOpen(true);
                }
            }
        } else {
            console.error('No user IDs selected for sharing or invalid contract ID.');
            enqueueSnackbar(t("invalid_share_parameters"), { variant: 'error' });
        }
    };

    const handleOpenDeleteDialog = (contractIds, source = null) => {
        if (contractIds && contractIds.length > 0) {
            setSelected(Array.isArray(contractIds) ? contractIds : [contractIds]);
            setIsDeleteDialogOpen(true);
            setDialogSource(source); // Track which dialog opened this one
        } else {
            enqueueSnackbar(t('please_select_contracts'), { variant: 'warning' });
        }
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);

        // If opened from detail dialog, return to it
        if (dialogSource === 'detail') {
            setIsDetailModalOpen(true);
        }
    };

    // Delete selected contracts
    const handleDeleteContracts = async () => {
        try {
            setLoadingDelete(true);
            await dispatch(deleteContracts(selected, user.id));
            setSelected([]);

            // Reset to page 1 if deleting all contracts on the current page
            const newPage = selected.length === viewContracts.length && page > 1 ? 1 : page;
            setPage(newPage);

            // // Force refresh by passing true as the last parameter
            // const offset = (newPage - 1) * rowsPerPage;
            // await dispatch(fetchContracts(user.id, rowsPerPage, offset, true));
            // await fetchTotalCount();

            enqueueSnackbar(t("delete_contracts_success"), {
                variant: 'success'
            });

            // Google Analytics event for deleting contracts
            ReactGA.event({
                category: 'Contract',
                action: 'User deleted contracts',
                label: `Deleted Contract IDs: ${selected.join(', ')}`
            });
        } catch (error) {
            enqueueSnackbar(t('delete_contracts_error'), { variant: 'error' });

            // On error, we might want to go back to the detail dialog
            if (dialogSource === 'detail') {
                setIsDetailModalOpen(true);
            }
        }
        setIsDeleteDialogOpen(false);
        setLoadingDelete(false);
    };

    useEffect(() => {

    }, [])



    // Open contract
    const handleOpenContract = async contractId => {
        dispatch(resetContract());
        navigate(`/contract/${contractId}`);
        enqueueSnackbar(t("contract_opened"), { variant: 'success' });

        // Google Analytics event for opening contract
        ReactGA.event({
            category: 'Contract',
            action: 'User opened a contract',
            label: `Contract ID: ${contractId}`
        });
    };

    // Tab change handler
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Filter contracts based on active tab
    const getFilteredContracts = () => {
        if (!contracts || contracts.length === 0) return [];

        switch (activeTab) {
            case 0: // All Contracts
                return contracts;
            case 1: // Recent - last 30 days
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
                return contracts.filter(c => new Date(c.updated_at) >= thirtyDaysAgo);
            case 2: // Shared with me - exclude created by me
                return contracts.filter(c =>
                    c.collaborators &&
                    c.collaborators.some(collab =>
                        collab.id === user.id && collab.role !== 'creator'
                    )
                );
            case 3: // Drafts
                return contracts.filter(c =>
                    c.status === 'DRAFT' || c.status === 'brouillon'
                );
            case 4: // Pending Signature
                return contracts.filter(c =>
                    c.status === 'PENDING_SIGNATURE' ||
                    c.status === 'en attente de signature'
                );
            case 5: // Signed
                return contracts.filter(c =>
                    c.status === 'SIGNED' || c.status === 'signé'
                );
            default:
                return contracts;
        }
    };

    const getFilteredAndSortedContracts = () => {
        // If we have filtered contracts from the advanced filter, use those
        if (activeAdvancedFilters > 0 && filteredContracts.length > 0) {
            return filteredContracts;
        }

        // Otherwise, use the existing filtering logic
        let filtered = getFilteredContracts();

        // Apply search term filter
        if (searchTerm && searchTerm.trim() !== '') {
            const term = searchTerm.toLowerCase().trim();
            filtered = filtered.filter(contract =>
                contract.title.toLowerCase().includes(term) ||
                contract.type?.toLowerCase().includes(term) ||
                contract.status?.toLowerCase().includes(term)
            );
        }

        // Apply additional filters from the filter dropdown
        if (activeFilter === 'recent') {
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
            filtered = filtered.filter(c => new Date(c.updated_at) >= thirtyDaysAgo);
        } else if (activeFilter === 'created') {
            filtered = filtered.filter(c =>
                c.collaborators?.some(collab =>
                    collab.id === user.id && collab.role === 'creator'
                )
            );
        }

        // Apply sorting
        if (sortOrder !== 'none') {
            switch (sortOrder) {
                case 'date_desc':
                    filtered = [...filtered].sort((a, b) =>
                        new Date(b.updated_at) - new Date(a.updated_at)
                    );
                    break;
                case 'date_asc':
                    filtered = [...filtered].sort((a, b) =>
                        new Date(a.updated_at) - new Date(b.updated_at)
                    );
                    break;
                case 'title_asc':
                    filtered = [...filtered].sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                    break;
                case 'title_desc':
                    filtered = [...filtered].sort((a, b) =>
                        b.title.localeCompare(a.title)
                    );
                    break;
                default:
                    break;
            }
        }

        return filtered;
    };


    const handleUpdateContract_fn = async (contractId, contractData) => {
        // Dispatch the action and handle UI feedback
        try {
            dispatch(handleUpdateContract(contractId, contractData));

            // Update the selected contract immediately in local state too
            if (selectedContract && selectedContract.id === contractId) {
                setSelectedContract(prev => ({ ...prev, ...contractData }));
            }

            // Show success message
            enqueueSnackbar(t("contract_updated_success") || "Contract updated successfully", {
                variant: 'success'
            });

            // Google Analytics event for contract update
            ReactGA.event({
                category: 'Contract',
                action: 'User updated contract',
                label: `Contract ID: ${contractId}`
            });
        } catch (error) {
            enqueueSnackbar(t("contract_update_error") || "Failed to update contract", {
                variant: 'error'
            });
        }
    }

    // Get contracts for the current view
    const viewContracts = getFilteredAndSortedContracts();

    return (
        <Box sx={{ pb: 3, px: 1 }}>
            <DashboardHeader title={t("contract_dashboard")} />

            <Box sx={{ mt: 2, mb: 3 }}>
                <StatsSummary
                    totalContracts={totalContracts}
                    contracts={contracts}
                />
            </Box>

            {/* <Box sx={{ mb: 3 }}>
                <ChartsOverview
                    statusData={statusData}
                    monthlyData={monthlyData}
                />
            </Box> */}

            <SearchFilterBar
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                activeView={activeView}
                setActiveView={setActiveView}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                currentUser={user}
                onAdvancedFilter={handleAdvancedFilter}
            />


            <Box sx={{
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
                overflow: 'hidden'
            }}>
                <ContractTabs
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                />

                <Box sx={{ py: 3, px: 2 }}>
                    {loading || loadingFilter ? (
                        <LoadingState />
                    ) : viewContracts.length === 0 ? (
                        <EmptyState
                            activeTab={activeTab}
                            searchTerm={searchTerm}
                            isSearching={searchTerm.trim() !== ''}
                        />
                    ) : (
                        <>
                            {activeView === 'grid' ? (
                                <ContractGrid
                                    filteredContracts={viewContracts}
                                    handleContractClick={handleContractClick}
                                    handleShareContract={id => handleShareContract(id)}
                                    handleDownloadContract={id => handleDownloadContract(id)}
                                    handleOpenDeleteDialog={ids => handleOpenDeleteDialog(ids)}
                                    handleOpenContract={handleOpenContract}
                                />
                            ) : (
                                <ContractList
                                    filteredContracts={viewContracts}
                                    handleContractClick={handleContractClick}
                                    handleShareContract={id => handleShareContract(id)}
                                    handleDownloadContract={id => handleDownloadContract(id)}
                                    handleOpenDeleteDialog={ids => handleOpenDeleteDialog(ids)}
                                    handleOpenContract={handleOpenContract}
                                />
                            )}

                            {/* Pagination */}
                            {(() => {
                                // Calculate total pages from either filtered results or total count
                                const filteredCount = viewContracts.length;
                                const isPaginated = searchTerm.trim() === '' && activeFilter === 'none' && activeTab === 0;

                                // If using server-side pagination, use totalContracts, otherwise use filtered count
                                const totalItems = isPaginated ? totalContracts : filteredCount;

                                // Make sure to use Math.floor or parseInt to ensure we have integers
                                const totalPages = Math.max(1, Math.ceil(totalItems / rowsPerPage));

                                // Ensure current page is a valid integer 
                                const currentPage = parseInt(page, 10);

                                // Only show pagination if we have more than one page
                                return totalPages > 1 ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                        <Pagination
                                            count={Math.floor(totalPages)} // Ensure integer
                                            page={Math.min(currentPage, totalPages)} // Ensure integer and valid page
                                            onChange={handlePageChange}
                                            color="primary"
                                            size="large"
                                            siblingCount={1}
                                            showFirstButton
                                            showLastButton
                                        />
                                    </Box>
                                ) : null;
                            })()}
                        </>
                    )}
                </Box>
            </Box>

            {/* Dialogs */}
            <ContractDetailDialog
                open={isDetailModalOpen}
                onOpen={() => {
                    setIsDetailModalOpen(false);
                    handleOpenContract(selectedContract?.id)
                }}
                onClose={handleCloseDetailDialog}
                contract={selectedContract}
                onDownload={() => {
                    setIsDetailModalOpen(false);
                    handleDownloadContract(selectedContract?.id, 'detail');
                }}
                onShare={() => {
                    setIsDetailModalOpen(false);
                    handleShareContract(selectedContract?.id, 'detail');
                }}
                onDelete={() => {
                    setIsDetailModalOpen(false);
                    handleOpenDeleteDialog([selectedContract?.id], 'detail');
                }}
                onUpdateContract={handleUpdateContract_fn}
            />

            <DownloadDialog
                open={isDownloadDialogOpen}
                onClose={handleCloseDownloadDialog}
                contractId={currentContractId}
            />

            <ShareContractModal
                open={isShareModalOpen}
                onClose={handleCloseShareDialog}
                onShare={handleShare}
                organizationName={user?.organization_name}
            />

            <DeleteConfirmationDialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleDeleteContracts}
                selectedCount={selected.length}
                isDeleting={loadingDelete}
            />
        </Box>
    );
};

export default ContractHistory;