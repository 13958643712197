import React, { useEffect, useState } from 'react'
import {
  IconButton,
  CircularProgress,
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  Tooltip,
  alpha
} from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../document-viewer/components/LanguageSelector'
import { isMobile } from '../../utils/utils'
import { fontSize } from '../../utils/fonts'
import { backendUrl } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import {
  setIsTranslationInProgress,
  setLoadingDocument,
  setTranslationProgress
} from '../../redux/actions/documentAction'
import { backendClient } from '../../api/backend'
import {
  setSearchLoadingDocument
} from '../../redux/actions/searchAction'

export const PDFOptionsBar = ({
  documentSourceUrl,
  documentDoc,
  selectedCitation,
  iframeRef,
  scrolledIndex,
  numPages,
  scaleText,
  nextPage,
  prevPage,
  handleZoomIn,
  handleZoomOut,
  goToPage,
  setZoomLevel,
  zoomInEnabled,
  zoomOutEnabled,
  setDocument
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const tokenPayload = useSelector(state => state.auth.user?.token)
  const [isDownloading, setIsDownloading] = useState(false)
  const [inputValue, setInputValue] = useState(`${scrolledIndex + 1}`)
  const [forceRender, setForceRender] = useState(0)

  // Theme colors
  const primaryColor = '#3182ce'
  const bgColor = '#ffffff'
  const borderColor = 'rgba(0,0,0,0.08)'
  const hoverBgColor = '#f7fafc'

  // Force re-render after component mount to ensure UI is updated
  useEffect(() => {
    const timer = setTimeout(() => {
      setForceRender(prev => prev + 1);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleLanguageChange = async valueTargetLanguage => {
    dispatch(setIsTranslationInProgress(true))
    dispatch(setLoadingDocument(true))
    dispatch(setSearchLoadingDocument(true))
    dispatch(setTranslationProgress({ progress: 0 }))

    // Check if selected language matches document language
    const documentLanguage = selectedCitation?.metadata?.language
    const isOriginalLanguage = documentLanguage && valueTargetLanguage === documentLanguage

    try {
      if (isOriginalLanguage) {
        // If using original language, get the original document
        const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME
        const blobName = selectedCitation?.metadata?.file_name

        const data = await backendClient.getDocumentSas(
          containerName,
          blobName
        )

        if (!data || !data.blob_url_with_sas) {
          throw new Error('Failed to get original document URL')
        }

        // Set document with original language
        await dispatch(setDocument({ ...documentDoc, url: data.blob_url_with_sas, language: valueTargetLanguage }))
        dispatch(setTranslationProgress({ progress: 100 }))
        dispatch(setIsTranslationInProgress(false))
        dispatch(setLoadingDocument(false))
        dispatch(setSearchLoadingDocument(false))
        enqueueSnackbar(t('document_loaded_success'), { variant: 'success' })
        return
      } else {
        // Get the HTML version for translation
        const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME
        const blobName = `${selectedCitation?.metadata?.document_id}.html`

        const data = await backendClient.getDocumentSas(containerName, blobName)
        if (!data || !data.blob_url_with_sas) {
          throw new Error('Failed to get HTML document for translation')
        }

        const toTranslateUrl = data.blob_url_with_sas
        console.log('toTranslateUrl', toTranslateUrl)

        // Send translation request
        const response = await fetch(`${backendUrl}api/document/translate-html`, {
          method: 'POST',
          headers: {
            Authorization: `${tokenPayload.token_type.charAt(0).toUpperCase()}${tokenPayload.token_type.slice(1)} ${tokenPayload.access_token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            file_url: toTranslateUrl,
            target_language: valueTargetLanguage
          })
        })

        if (!response.ok) {
          throw new Error(`Translation request failed with status: ${response.status}`)
        }

        if (!response.body) {
          throw new Error('No response body from translation service')
        }


        // Process the streaming response
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        let buffer = ''

        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          buffer += decoder.decode(value, { stream: true })
          const lines = buffer.split('\n\n')

          for (let i = 0; i < lines.length - 1; i++) {
            const message = lines[i]
            if (!message.trim() || !message.startsWith('data: ')) continue

            try {
              const data = JSON.parse(message.replace('data: ', ''))

              if (data.error) {
                throw new Error(`Translation error: ${data.error}`)
              }

              if (data.completed && data.translated_url) {
                // Translation completed successfully
                dispatch(setDocument({ ...documentDoc, url: data.translated_url, language: valueTargetLanguage }))
                dispatch(setTranslationProgress({ message: "Terminé!", progress: 100 }))
                dispatch(setIsTranslationInProgress(false))
                dispatch(setLoadingDocument(false))
                dispatch(setSearchLoadingDocument(false))
                enqueueSnackbar(t('translated_success'), { variant: 'success' })
                return
              }

              if (data.progress !== undefined) {
                dispatch(setTranslationProgress(data))
              }
            } catch (e) {
              console.error('Error parsing translation message:', e, message)
            }
          }

          buffer = lines[lines.length - 1]
        }
      }
    } catch (error) {
      console.error('Translation error:', error)
      enqueueSnackbar(t('translation_error'), { variant: 'error' })
      dispatch(setIsTranslationInProgress(false))
      dispatch(setLoadingDocument(false))
      dispatch(setSearchLoadingDocument(false))
      dispatch(setTranslationProgress({ progress: 0 }))
    }
  }



  const handleExport = async () => {
    setIsDownloading(true)
    const filename = selectedCitation?.metadata?.long_title
      ? `${selectedCitation?.metadata?.long_title}.pdf`
      : 'document.pdf'

    try {
      let content
      const fileType = documentDoc?.url.split('?')[0].endsWith('pdf')
        ? 'pdf'
        : 'html'

      if (fileType === 'pdf') {
        content = documentDoc?.url
      } else {
        // Access iframe content
        const iframeDocument = iframeRef.current?.contentWindow?.document
        if (!iframeDocument) {
          throw new Error('Cannot access iframe content')
        }

        // Clone and clean content
        const contentDiv = document.createElement('div')
        contentDiv.innerHTML = iframeDocument.body.innerHTML

        // Remove inline images or problematic elements
        const images = contentDiv.getElementsByTagName('img')
        Array.from(images).forEach(img => {
          if (img.src.startsWith('data:')) {
            img.remove()
          }
        })

        content = contentDiv.innerHTML
      }

      const blob = await backendClient.exportDocument(
        content,
        fileType,
        filename
      )

      // Download the blob
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      enqueueSnackbar(t('document_export_success'), {
        variant: 'success'
      })
    } catch (error) {
      console.error('Error exporting document:', error)
      if (error.message === 'unauthorized_access') {
        enqueueSnackbar(t('unauthorized_access'), { variant: 'error' })
      } else if (error.message === 'session_expired') {
        enqueueSnackbar(t('session_expired'), { variant: 'error' })
      } else {
        enqueueSnackbar(t('export_error'), { variant: 'error' })
      }
    } finally {
      setIsDownloading(false)
    }
  }

  const handleChange = e => {
    setInputValue(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      const value = parseInt(inputValue, 10)
      if (!isNaN(value) && value > 0 && value <= numPages) {
        goToPage(value - 1)
      }
    }
  }

  useEffect(() => {
    setInputValue(`${scrolledIndex + 1}`)
  }, [scrolledIndex])

  // Check if we're in mobile mode
  const isMobileView = isMobile();
  // Ensure numPages is actually a number
  const hasPages = typeof numPages === 'number' && numPages > 0;

  // Custom button style
  const controlButtonStyle = {
    width: '36px !important',
    minWidth: '36px !important',
    height: '36px',
    padding: 0,
    borderRadius: '6px',
    color: primaryColor,
    '&:hover': {
      backgroundColor: alpha(primaryColor, 0.08),
    },
    '&.Mui-disabled': {
      color: alpha(primaryColor, 0.4),
    }
  };

  return (
    <Box
      display='flex'
      height='48px'
      width='100%'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        backgroundColor: bgColor,
        borderBottom: `1px solid ${borderColor}`,
        borderRadius: '8px 8px 0 0',
        position: 'relative',
        zIndex: 2,
        boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
      }}
    >
      {/* Language Selector */}
      <Box className="language-selector-container" sx={{ flexShrink: 0 }}>
        <LanguageSelector
          disabled={false}
          currentLang={documentDoc?.language}
          onLanguageChange={handleLanguageChange}
          style={{
            flexShrink: 0,
            marginRight: 2
          }}
        />
      </Box>

      {/* Divider */}
      <Divider orientation="vertical" flexItem sx={{ mx: 1.5, display: { xs: 'none', sm: 'block' } }} />

      {/* Page Navigation - Always show when we have pages */}
      {hasPages && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            flexShrink: 1,
            display: { xs: isMobileView ? 'none' : 'flex', sm: 'flex' },
            backgroundColor: alpha(primaryColor, 0.04),
            borderRadius: '8px',
            padding: '2px',
            height: '38px'
          }}
        >
          <Tooltip title={t('previous_page')} arrow>
            <span>
              <Button
                onClick={prevPage}
                disabled={scrolledIndex === 0}
                variant='text'
                size='small'
                sx={controlButtonStyle}
              >
                <ArrowDropUpIcon />
              </Button>
            </span>
          </Tooltip>

          <TextField
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputProps={{
              style: {
                textAlign: 'center',
                fontSize: fontSize.small,
                padding: '4px 0',
                width: '36px',
                fontWeight: 600
              }
            }}
            sx={{
              width: '44px',
              margin: '0 4px',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiInputBase-root': {
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '6px',
                '&.Mui-focused': {
                  boxShadow: `0 0 0 2px ${alpha(primaryColor, 0.2)}`
                }
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(primaryColor, 0.2)
              }
            }}
          />

          <Typography
            variant='body2'
            sx={{
              fontSize: fontSize.small,
              fontWeight: 500,
              color: '#4a5568',
              marginRight: '4px'
            }}
          >
            / {numPages}
          </Typography>

          <Tooltip title={t('next_page')} arrow>
            <span>
              <Button
                onClick={nextPage}
                disabled={scrolledIndex === numPages - 1}
                variant='text'
                size='small'
                sx={controlButtonStyle}
              >
                <ArrowDropDownIcon />
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}

      {/* Zoom Controls - Always show when zoomInEnabled is true */}
      {zoomInEnabled && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            flexShrink: 1,
            marginLeft: 2,
            display: { xs: isMobileView ? 'none' : 'flex', sm: 'flex' },
            backgroundColor: alpha(primaryColor, 0.04),
            borderRadius: '8px',
            padding: '2px',
            height: '38px'
          }}
        >
          <Tooltip title={t('zoom_out')} arrow>
            <span>
              <Button
                onClick={handleZoomOut}
                disabled={!zoomOutEnabled}
                variant='text'
                size='small'
                sx={controlButtonStyle}
              >
                <ZoomOutIcon />
              </Button>
            </span>
          </Tooltip>

          <Typography
            variant='body2'
            sx={{
              mx: 2,
              fontSize: '0.875rem',
              fontWeight: 600,
              color: '#4a5568',
              minWidth: '52px',
              textAlign: 'center'
            }}
          >
            {scaleText || '100%'}
          </Typography>

          <Tooltip title={t('zoom_in')} arrow>
            <span>
              <Button
                onClick={handleZoomIn}
                disabled={!zoomInEnabled}
                variant='text'
                size='small'
                sx={controlButtonStyle}
              >
                <ZoomInIcon />
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}

      {/* Divider */}
      <Divider orientation="vertical" flexItem sx={{ mx: 1.5, display: { xs: 'none', sm: 'block' } }} />

      {/* Download Button */}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        style={{ flexShrink: 0 }}
      >
        <Tooltip title={t('download')} arrow>
          <span>
            <IconButton
              onClick={handleExport}
              disabled={isDownloading}
              sx={{
                color: primaryColor,
                backgroundColor: alpha(primaryColor, 0.04),
                borderRadius: '8px',
                padding: '8px',
                '&:hover': {
                  backgroundColor: alpha(primaryColor, 0.08),
                }
              }}
            >
              {isDownloading ? <CircularProgress size={24} /> : <GetAppIcon />}
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default PDFOptionsBar