import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { backendClient } from '../api/backend';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
    const user = useSelector(state => state.auth.user);
    const [subscription, setSubscription] = useState(null);
    const [subscriptionLimits, setSubscriptionLimits] = useState({
        max_users: 1,
        allowed_languages: ['en', 'fr', 'ar', 'es', 'de']
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSubscriptionInfo = async () => {
            if (!user?.user?.organization_id) {
                setLoading(false);
                return;
            }

            try {
                // Fetch both subscription info and limits
                const subscriptionData = await backendClient.getSubscriptionInfo(user.user.organization_id);
                const limitsData = await backendClient.getSubscriptionLimits(user.user.organization_id);

                // Ensure allowed_languages is always an array
                const allowedLanguages = Array.isArray(limitsData.limits?.allowed_languages) 
                    ? limitsData.limits.allowed_languages 
                    : ['en', 'fr', 'ar', 'es', 'de'];

                // Set subscription with tier information and limits from the backend
                setSubscription({
                    ...subscriptionData,
                    tier: limitsData.plan_type,
                    subscriptionType: limitsData.subscription_type,
                    limits: {
                        ...limitsData.limits,
                        allowed_languages: allowedLanguages
                    }
                });

                setSubscriptionLimits({
                    ...limitsData.limits,
                    allowed_languages: allowedLanguages
                });
            } catch (err) {
                console.error('Error fetching subscription:', err);
                setError(err);
                // Default to most restrictive tier
                const defaultLimits = {
                    max_users: 1,
                    allowed_languages: ['en', 'fr', 'ar', 'es', 'de']
                };
                
                setSubscription({
                    status: 'none',
                    tier: 'individual',
                    subscriptionType: 'monthly',
                    limits: defaultLimits
                });

                setSubscriptionLimits(defaultLimits);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionInfo();
    }, [user?.user?.organization_id]);

    // Check if a language is available in the current subscription
    const isLanguageAvailable = (langCode) => {
        if (!subscriptionLimits?.allowed_languages) return false;
        return Array.isArray(subscriptionLimits.allowed_languages) && 
               subscriptionLimits.allowed_languages.includes(langCode);
    };

    // Check if max users limit is reached
    const canAddMoreUsers = (currentUserCount) => {
        if (!subscriptionLimits?.max_users) return false;
        return currentUserCount < subscriptionLimits.max_users;
    };

    return (
        <SubscriptionContext.Provider
            value={{
                subscription,
                loading,
                error,
                isLanguageAvailable,
                canAddMoreUsers,
                maxUsers: subscriptionLimits?.max_users || 1,
                allowedLanguages: subscriptionLimits?.allowed_languages || ['en', 'fr'],
                planType: subscription?.tier || 'individual',
                subscriptionType: subscription?.subscriptionType || 'monthly'
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};