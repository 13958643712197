// src/components/MessageHandler.js
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoadingOnboarding } from '../../../../redux/actions/userAction'
import { messageActions } from '../../../../redux/actions/messageAction'
import { EventSourcePolyfill } from 'event-source-polyfill'

const MessageHandler = ({
  userMessage,
  setUserMessage,
  selectedFiles,
  setSelectedFiles,
  conversationId,
  eventSourceRef,
  backendClient,
  backendUrl,
  user,
  mode,
  setfilteredMessages,
  setIsMessagePending,
  setIsUploadingDocuments,
  setUploadController
}) => {
  const dispatch = useDispatch()

  const submit = useCallback(async () => {
    if (!userMessage && !selectedFiles) {
      console.error('No user message or file provided.')
      return
    }

    dispatch(setIsLoadingOnboarding(false))

    if (!conversationId) {
      console.error('No conversation ID provided.')
      return
    }

    if (eventSourceRef.current) {
      eventSourceRef.current.close()
      eventSourceRef.current = null
      setIsMessagePending(false)
    }
    setIsMessagePending(true)

    const cleanedMessage = userMessage.replace(/<br\s*\/?>/gi, '\n').trim()

    if (!selectedFiles || selectedFiles.length === 0) {
      dispatch(messageActions.userSendMessage(cleanedMessage, selectedFiles))
      setUserMessage('')
      setSelectedFiles([])
    }

    const tokenPayload = backendClient.getToken()
    if (!tokenPayload) {
      console.error('No authentication token available.')
      setIsMessagePending(false)
      setUserMessage('')
      return
    }

    const token = `${tokenPayload.token_type
      .charAt(0)
      .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
      tokenPayload.access_token
    )}`

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let fileIds = []

    if (selectedFiles && selectedFiles.length > 0) {
      setIsUploadingDocuments(true)

      const controller = new AbortController()
      setUploadController(controller)

      const uploadFormData = new FormData()
      selectedFiles.forEach(file => {
        uploadFormData.append('documents', file)
      })
      uploadFormData.append('organization_name', user?.organization_name || '')

      try {
        const uploadResponse = await fetch(
          `${backendUrl}api/conversation/${conversationId}/upload`,
          {
            method: 'POST',
            headers: {
              Authorization: token
            },
            body: uploadFormData,
            signal: controller.signal
          }
        )

        if (!uploadResponse.ok) {
          throw new Error(`File upload failed with status: ${uploadResponse.status}`)
        }

        const uploadResult = await uploadResponse.json()
        const uploadedFilesInfo = uploadResult.map(file => ({
          fileName: file.file_name,
          originalFileName: file.original_file_name,
          fileUrl: file.url,
          fileLanguage: file.language,
          docType: 'pdf'
        }))
        fileIds = uploadResult.map(file => file.file_id)

        dispatch(messageActions.userSendMessage(cleanedMessage, uploadedFilesInfo))
        setUserMessage('')
        setSelectedFiles([])
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('File upload was cancelled')
          return
        }
        console.error('Failed to upload files:', error)
        setIsMessagePending(false)
        return
      } finally {
        setIsUploadingDocuments(false)
      }
    }

    const encodedUserMessage = encodeURIComponent(userMessage)
    const encodedOrganizationName = encodeURIComponent(user?.organization_name)
    const fileIdsQuery = fileIds.length > 0 ? `&file_ids=${encodeURIComponent(fileIds.join(','))}` : ''

    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message?user_id=${user.id
      }&mode=${encodeURIComponent(mode)}&organization_name=${encodedOrganizationName}&user_message=${encodedUserMessage}&timezone=${encodeURIComponent(timezone)}${fileIdsQuery}`

    const events = new EventSourcePolyfill(messageEndpoint, {
      headers: {
        Authorization: token,
        Connection: 'keep-alive'
      },
      heartbeatTimeout: 1200000
    })

    eventSourceRef.current = events

    events.onmessage = event => {
      const parsedData = JSON.parse(event.data)
      dispatch(messageActions.systemSendMessage(parsedData))
      setfilteredMessages(parsedData)

      if (parsedData.status === 'SUCCESS' || parsedData.status === 'ERROR') {
        events.close()
        setIsMessagePending(false)
        dispatch(setIsLoadingOnboarding(false))
      }
    }

    events.onerror = event => {
      events.close()
      eventSourceRef.current = null
      setIsMessagePending(false)

      if (event.status === 403) {
        console.error('Access forbidden (403).')
        setIsMessagePending(false)
      }
    }
  }, [
    conversationId,
    userMessage,
    selectedFiles,
    dispatch,
    setfilteredMessages,
    mode
  ])

  return { submit }
}

export default MessageHandler
