import React from 'react'
import { Box, Typography, Pagination, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'

export const SearchPagination = ({
  currentPage,
  itemsPerPage = 10,
  handlePageChange,
  containerStyle = {},
  paginationStyle = {},
  showTotalResults = true
}) => {
  const { t } = useTranslation()
  const filteredCitations = useSelector(state => state.search.filteredCitations)
  const totalResultsCount = filteredCitations?.length || 0

  // Calculate total number of pages
  const totalPages = Math.ceil(totalResultsCount / itemsPerPage)

  // Ensure current page is within bounds
  const safePage = Math.min(Math.max(1, currentPage), totalPages || 1)

  // Calculate the range of items being displayed
  const startItem = totalResultsCount > 0 ? (safePage - 1) * itemsPerPage + 1 : 0
  const endItem = Math.min(safePage * itemsPerPage, totalResultsCount)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '8px',
        backgroundColor: '#f7fafc',
        ...containerStyle
      }}
    >
      {showTotalResults && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormatListNumberedIcon sx={{ color: '#718096', fontSize: '0.9rem', mr: 1 }} />
          <Typography
            variant='body2'
            sx={{
              color: '#4a5568',
              flexShrink: 0,
              fontSize: '0.85rem',
              fontWeight: 500
            }}
          >
            {totalResultsCount === 0
              ? t('no_results')
              : `${totalResultsCount} ${t('results_count')}${
                  totalResultsCount > 0 ? ` (${startItem}-${endItem})` : ''
                }`}
          </Typography>
        </Box>
      )}

      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={safePage}
          onChange={handlePageChange}
          color='primary'
          size='small'
          showFirstButton
          showLastButton
          sx={{
            '.MuiPagination-ul': {
              justifyContent: 'flex-end'
            },
            '.MuiPaginationItem-root': {
              color: '#4a5568',
              fontWeight: 500,
              borderRadius: '4px',
              minWidth: '32px',
              height: '32px'
            },
            '.MuiPaginationItem-root.Mui-selected': {
              backgroundColor: '#3182ce',
              color: '#ffffff',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: '#2b6cb0'
              }
            },
            ...paginationStyle
          }}
        />
      )}
    </Box>
  )
}

// Custom hook for pagination logic
export const usePagination = (totalItems, itemsPerPage = 10) => {
  const [currentPage, setCurrentPage] = React.useState(1)

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
    // Scroll to top when page changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // Calculate pagination values
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems)

  // Reset to first page if total items changes and current page is out of bounds
  React.useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(1)
    }
  }, [totalItems, itemsPerPage, currentPage, totalPages])

  return {
    currentPage,
    setCurrentPage,
    handlePageChange,
    startIndex,
    endIndex,
    totalPages,
    itemsPerPage
  }
}

export default SearchPagination