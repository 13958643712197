import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
  Button,
  Typography,
  Divider,
  Avatar
} from '@mui/material'
import {
  Dashboard as DashboardIcon,
  Search as SearchIcon,
  Chat as ChatIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Add as AddIcon,
  KeyboardArrowRight as ArrowIcon
} from '@mui/icons-material'
import { ReactComponent as LogoText } from '../assets/logo_text.svg'
import DescriptionIcon from '@mui/icons-material/Description'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { colors } from '../utils/colors'
import { setOpenNavBar } from '../redux/actions/navbarAction'
import { useSnackbar } from 'notistack'
import { createConversation } from '../redux/actions/conversationAction'
import { fontSize } from '../utils/fonts'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { setDocument, setSelectedCitation } from '../redux/actions/documentAction'

const Navbar = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const open = useSelector(state => state.navbar.isOpen)
  const user = useSelector(state => state.auth?.user?.user)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [hoveredItem, setHoveredItem] = useState(null)

  const menuItems = [
    { text: t('dashboard'), icon: <DashboardIcon />, link: '/' },
    { text: t('search'), icon: <SearchIcon />, link: '/search' },
    { text: t('conversation'), icon: <ChatIcon />, link: '/conversation' },
    { text: t('contracts'), icon: <DescriptionIcon />, link: '/contract' }
  ]

  const isItemSelected = path =>
    location.pathname === path ||
    location.pathname.split('/')[1] === path.split('/')[1]

  const activeStep = useSelector(state => state.user.activeStep)

  const handleMenuClick = (path, itemText) => {
    ReactGA.event({
      category: 'Navigation',
      action: `Clicked on ${itemText}`,
      label: `Path: ${path}`
    })
    navigate(path)
  }

  const toggleDrawer = () => {
    dispatch(setOpenNavBar(!open))
  }

  const handleCreateConversation = async () => {
    try {
      const newConversation = await dispatch(createConversation(user.id))
      if (newConversation) {
        dispatch(setDocument(null))
        dispatch(setSelectedCitation(null))
        enqueueSnackbar('Conversation créée.', {
          variant: 'success'
        })

        ReactGA.event({
          category: 'Conversation',
          action: 'User created a new conversation',
          label: `User ID: ${user.id}`
        })

        navigate(`/conversation/${newConversation.id}`)
      }
    } catch (error) {
      enqueueSnackbar('Failed to create conversation', { variant: 'error' })
    }
  }

  // Custom gradient background
  const navbarBackground = {
    background: '#121212',
    boxShadow: 'inset 0 0 30px rgba(0,0,0,0.5), 0 4px 10px rgba(0,0,0,0.3)',
    borderRight: '1px solid rgba(255,255,255,0.05)'
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: open ? 240 : 70,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 240 : 70,
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflowX: 'hidden',
          ...navbarBackground,
          color: colors.white,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRight: 'none',
          paddingTop: 1,
          paddingBottom: 1
        }
      }}
    >
      <Box>
        {/* Logo and Menu Toggle Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: open ? 2 : 3,
            paddingBottom: open ? 2 : 3,
            borderBottom: `1px solid rgba(255,255,255,0.08)`,
            gap: 2
          }}
        >
          <Box
            component={Link}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none',
              mb: open ? 0 : 2
            }}
          >
            <Avatar
              sx={{
                width: 44,
                height: 44,
                bgcolor: 'white',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            >
              <Logo style={{ width: '32px', height: '32px' }} />
            </Avatar>
            {open && (
              <Box
                component={LogoText}
                sx={{
                  ml: 1,
                  width: 100,
                  height: 'auto',
                  overflow: 'hidden'
                }}
              />
            )}
          </Box>

          {open && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', px: 2 }}>
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  color: colors.white,
                  bgcolor: 'rgba(255,255,255,0.1)',
                  '&:hover': {
                    bgcolor: 'rgba(255,255,255,0.2)'
                  },
                  transition: 'all 0.2s ease',
                  width: 32,
                  height: 32
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          {!open && (
            <IconButton
              onClick={toggleDrawer}
              sx={{
                color: colors.white,
                bgcolor: 'rgba(255,255,255,0.1)',
                '&:hover': {
                  bgcolor: 'rgba(255,255,255,0.2)'
                },
                transition: 'all 0.2s ease',
                width: 32,
                height: 32
              }}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
        </Box>

        {/* Menu Items */}
        <List sx={{ mt: 3, mb: 1, px: open ? 1 : 0 }}>
          {menuItems.map((item, index) => (
            <Tooltip
              title={!open ? item.text : ""}
              key={index}
              placement='right'
              arrow
            >
              <ListItem
                onClick={() => handleMenuClick(item.link, item.text)}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                sx={{
                  borderRadius: open ? '10px' : '50%',
                  mb: 2,
                  height: 48,
                  width: open ? 'auto' : 48,
                  mx: open ? 0 : 'auto',
                  justifyContent: open ? 'flex-start' : 'center',
                  p: open ? '8px 12px' : '0',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  position: 'relative',
                  overflow: 'hidden',
                  backgroundColor: isItemSelected(item.link)
                    ? 'rgba(255,255,255,0.15)'
                    : hoveredItem === index ? 'rgba(255,255,255,0.08)' : 'transparent',
                  ...(isItemSelected(item.link) && {
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: open ? '100%' : '',
                      width: open ? '4px' : '',
                      background: 'linear-gradient(180deg, #2196f3 0%, #21cbf3 100%)',
                      boxShadow: '0 0 8px rgba(33, 150, 243, 0.5)'
                    }
                  })
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 0,
                    justifyContent: 'center',
                    color: isItemSelected(item.link)
                      ? '#2196f3'
                      : 'rgba(255,255,255,0.7)'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontWeight: isItemSelected(item.link) ? 500 : 400,
                      fontSize: '0.9rem',
                      color: isItemSelected(item.link)
                        ? colors.white
                        : 'rgba(255,255,255,0.7)',
                      noWrap: true,
                      sx: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                )}
                {open && isItemSelected(item.link) && (
                  <ArrowIcon sx={{ color: 'rgba(255,255,255,0.5)', fontSize: '1rem' }} />
                )}
              </ListItem>
            </Tooltip>
          ))}

          {/* Create New Conversation Button */}
          <Box sx={{
            mt: 3,
            px: open ? 1 : 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: open ? 10 : 'auto',
              right: open ? 10 : 'auto',
              bottom: -10,
              width: open ? 'calc(100% - 20px)' : 20,
              height: 1,
              // background: 'rgba(255,255,255,0.05)'
            }
          }}>
            {!open ? (
              <Tooltip title={t('create_conversation')} placement="right" arrow>
                <Box
                  onClick={handleCreateConversation}
                  sx={{
                    width: 48,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    background: 'linear-gradient(135deg, #2196f3 0%, #4aa7ff 100%)',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(33, 150, 243, 0.4)',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 12px rgba(33, 150, 243, 0.5)',
                    }
                  }}
                >
                  <Box component="span" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 24,
                    height: 24,
                    color: 'white'
                  }}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4V20M4 12H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Box>
                </Box>
              </Tooltip>
            ) : (
              <Button
                className='create-conversation-btn'
                onClick={handleCreateConversation}
                variant="contained"
                fullWidth
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: '8px',
                  height: 48,
                  background: 'linear-gradient(90deg, #2196f3 0%, #21cbf3 100%)',
                  boxShadow: '0 4px 10px rgba(33, 150, 243, 0.3)',
                  color: colors.white,
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  px: 2,
                  '&:hover': {
                    background: 'linear-gradient(90deg, #1e88e5 0%, #21bbf3 100%)',
                    boxShadow: '0 6px 12px rgba(33, 150, 243, 0.4)',
                    transform: 'translateY(-2px)'
                  },
                  transition: 'all 0.2s ease'
                }}
              >
                <Typography
                  fontWeight={500}
                  fontSize="0.9rem"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {t('create_conversation')}
                </Typography>
              </Button>
            )}
          </Box>
        </List>
      </Box>

      {/* Bottom Section with Settings */}
      <Box sx={{ mb: 2, px: 1 }}>
        <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.08)' }} />

        {/* User Info (If needed) */}
        {open && user && (
          <Box sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            mb: 1,
            borderRadius: '10px',
            backgroundColor: 'rgba(255,255,255,0.05)'
          }}>
            <Avatar sx={{
              width: 36,
              height: 36,
              background: 'linear-gradient(135deg, #6b7bfd 0%, #8c96ff 100%)',
              fontWeight: 600
            }}>
              {user.username ? user.username.charAt(0).toUpperCase() : 'U'}
            </Avatar>
            <Box sx={{ ml: 1.5, overflow: 'hidden' }}>
              <Typography variant="body2" sx={{
                fontWeight: 500,
                color: 'white',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 150
              }}>
                {user.first_name + ' ' + user.last_name}
              </Typography>
              <Typography variant="caption" sx={{
                color: 'rgba(255,255,255,0.6)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 150,
                display: 'block'
              }}>
                {user.email || ''}
              </Typography>
            </Box>
          </Box>
        )}

        {/* Settings Button */}
        <Tooltip title={!open ? t('settings') : ""} placement='right' arrow>
          <ListItem
            onClick={() => handleMenuClick('/settings')}
            sx={{
              borderRadius: open ? '10px' : '50%',
              height: 48,
              width: open ? 'auto' : 48,
              mx: open ? 1 : 'auto',
              justifyContent: open ? 'flex-start' : 'center',
              p: open ? '8px 12px' : '0',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              position: 'relative',
              backgroundColor: isItemSelected('/settings')
                ? 'rgba(255,255,255,0.15)'
                : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.08)'
              },
              ...(isItemSelected('/settings') && {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  height: open ? '100%' : '',
                  width: open ? '4px' : '',
                  background: 'linear-gradient(180deg, #2196f3 0%, #21cbf3 100%)',
                  boxShadow: '0 0 8px rgba(33, 150, 243, 0.5)'
                }
              })
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 0,
                color: isItemSelected('/settings')
                  ? '#2196f3'
                  : 'rgba(255,255,255,0.7)'
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary={t('settings')}
                primaryTypographyProps={{
                  fontWeight: isItemSelected('/settings') ? 500 : 400,
                  fontSize: '0.9rem',
                  color: isItemSelected('/settings')
                    ? colors.white
                    : 'rgba(255,255,255,0.7)',
                  noWrap: true,
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            )}
          </ListItem>
        </Tooltip>
      </Box>
    </Drawer>
  )
}

export default Navbar