import { docTypes } from '../../utils/utils'
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  FETCH_RECENT_SEARCHES,
  FETCH_AUTOCOMPLETE_RESULTS,
  RESET_SEARCH_STORE,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOADING_DOCUMENT,
  SET_SEARCH_DOCUMENT,
  SET_SEARCH_IS_MODAL_MOBILE_VISIBLE,
  SEARCH_CLEAR_DOCUMENT,
  SET_SEARCH_DOCUMENT_SOURCE_URL,
  SET_SEARCH_SELECTED_CITATION,
  SET_SELECTED_DOC_TYPES,
  SET_AVAILABLE_DOC_TYPES,
  SET_FILTERED_CITATIONS,
  SET_IS_LOADING_SEARCH_CITATIONS,
  SET_IS_LOADING_AUTO_COMPLETE_SEARCH,
  UPDATE_FROM_AUTO_SELECT,
  RESET_AVAILABLE_DOC_TYPES,
  CANCEL_SEARCH
} from '../actions/types'

const initialState = {
  searchQuery: '',
  isLoadingSearchCitations: false,
  isLoadingAutoCompleteSearch: false,
  citations: [],
  error: null,
  recentSearches: [],
  autocompleteResults: [],

  updateFromAutoSelect: null,
  isSearchLoadingDocument: false,
  searchDocument: null, // {id (citation document_id), url (from fetchDocumentUrl)}, language
  isSearchModalVisible: false,
  searchDocumentSourceUrl: null,
  searchSelectedCitation: null, // {page_content, metadata}
  availableDocTypes: [],
  selectedDocTypes: [],
  filteredCitations: {}
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    // case SET_FILTERED_CITATIONS:
    //   return { ...state, filteredCitations: action.payload }

    case SET_FILTERED_CITATIONS:
      // If payload is already a dictionary, no need to reduce, just store it directly
      const filteredCitationsDict = action.payload
      return { ...state, filteredCitations: filteredCitationsDict }

    case SET_SEARCH_LOADING_DOCUMENT:
      return { ...state, isSearchLoadingDocument: action.payload }

    case SET_SEARCH_DOCUMENT:
      return { ...state, searchDocument: action.payload }

    case SET_SEARCH_SELECTED_CITATION:
      return { ...state, searchSelectedCitation: action.payload }

    case SET_SEARCH_IS_MODAL_MOBILE_VISIBLE:
      return { ...state, isSearchModalVisible: action.payload }

    case SET_SEARCH_DOCUMENT_SOURCE_URL:
      return { ...state, documentSourceUrl: action.payload }

    case SET_SELECTED_DOC_TYPES:
      return { ...state, selectedDocTypes: action.payload }

    case SET_AVAILABLE_DOC_TYPES:
      return { ...state, availableDocTypes: action.payload }

    case RESET_AVAILABLE_DOC_TYPES:
      return { ...state, availableDocTypes: docTypes }

    case SET_IS_LOADING_SEARCH_CITATIONS:
      return { ...state, isLoadingSearchCitations: action.payload }

    case SET_IS_LOADING_AUTO_COMPLETE_SEARCH:
      return { ...state, isLoadingAutoCompleteSearch: action.payload }

    case UPDATE_FROM_AUTO_SELECT:
      return { ...state, updateFromAutoSelect: action.payload }

    case SEARCH_CLEAR_DOCUMENT:
      return initialState

    case SEARCH_REQUEST:
      return {
        ...state,
        isLoadingSearchCitations: true,
        error: null,
        citations: []
      }
      
    case CANCEL_SEARCH:
      return {
        ...state,
        isLoadingSearchCitations: false,
        error: null
      }
      
    case SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingSearchCitations: false,
        citations: action.payload,
        filteredCitations: action.payload,
        error: null
      }
      
    case SEARCH_FAILURE:
      return {
        ...state,
        isLoadingSearchCitations: false,
        citations: [],
        filteredCitations: [],
        error: action.payload
      }
      
    case FETCH_RECENT_SEARCHES:
      return {
        ...state,
        // autocompleteResults: [...state.autocompleteResults, ...action.payload]
        autocompleteResults: action.payload
      }

    case FETCH_AUTOCOMPLETE_RESULTS:
      return { ...state, autocompleteResults: action.payload }
      
    case RESET_SEARCH_STORE:
      return initialState
      
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      }
      
    default:
      return state
  }
}

export default searchReducer