import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Button, Paper, Typography, InputBase, IconButton, Tooltip, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { blue, grey, red } from "@mui/material/colors";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as Logo } from '../../../../assets/logo.svg';
import { formatTextToHTML, windowHeight } from "../../../../utils/utils";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  sendContractMessage,
  clearContractMessages,
  createContractConversation
} from '../../../../redux/actions/contractAction';
import { backendClient } from "../../../../api/backend";
import RefreshIcon from '@mui/icons-material/Refresh';

// Styled components
const InputElement = styled("input")(({ theme }) => `
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`);

const InputContainer = styled("div")(({ theme }) => `
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[300]};
  border-radius: 8px;
  padding: 4px 8px;
  margin-top: 5px;
  box-shadow: 0 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
`);

// Loading dots component for typing indicator
const LoadingDots = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .dot': {
    backgroundColor: '#1a75d3',
    borderRadius: '50%',
    width: '6px',
    height: '6px',
    margin: '0 2px',
    display: 'inline-block',
    animation: 'loading 1.4s infinite ease-in-out both',
  },
  '& .dot:nth-of-type(1)': {
    animationDelay: '-0.32s',
  },
  '& .dot:nth-of-type(2)': {
    animationDelay: '-0.16s',
  },
  '@keyframes loading': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    },
  },
});

const ChatBot = ({ conversationId: propConversationId, contractId, userId }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Get messages from the contract reducer
  const messages = useSelector((state) => state.contract.messages || []);
  const currentConversationId = useSelector((state) => state.contract.currentConversationId);

  // Local state
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState(propConversationId || currentConversationId);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const chatEndRef = useRef(null);

  // On mount, create a conversation if we don't have one
  useEffect(() => {
    // Only initialize once
    if (isInitialized) return;

    // If propConversationId is provided, use it
    if (propConversationId) {
      setConversationId(propConversationId);
      setIsInitialized(true);
    }
    // If we already have a conversation ID in Redux, use it
    else if (currentConversationId) {
      setConversationId(currentConversationId);
      setIsInitialized(true);
    }
    // Otherwise create a new conversation
    else {
      console.log('CREATING_NEW')
      createNewConversation();
    }
  }, [dispatch, propConversationId, currentConversationId, isInitialized]);

  // Create a new conversation
  const createNewConversation = async () => {
    try {
      // setLoading(true);
      setError(null);

      const response = dispatch(createContractConversation(contractId, userId))

      if (response && response.conversation_id) {
        setConversationId(response.conversation_id);
        setIsInitialized(true);
      }
    } catch (err) {
      console.log('Failed to create conversation:', err);
      setError(t('chatbot.errorMessage.conversation'));
    } finally {
      setLoading(false);
    }
  };

  // Function to send a message
  const sendMessage = (text) => {
    if (!text.trim() || !conversationId || loading) return;

    setLoading(true);

    // Dispatch the SSE-based action
    dispatch(
      sendContractMessage(
        conversationId,
        contractId,
        userId,
        text.trim()
      )
    );

    // Clear local input
    setInput("");
    setLoading(false);
  };

  // Function to clear chat
  const handleClearChat = async () => {
    if (!conversationId || loading) return;

    try {
      setLoading(true);
      setError(null);

      await backendClient.clearContractConversation(conversationId);

      // Clear messages in Redux
      dispatch(clearContractMessages());
    } catch (err) {
      console.error('Failed to clear chat:', err);
      setError(t('chatbot.errorMessage.clearChat'));
    } finally {
      setLoading(false);
    }
  };

  // Scroll automatically to bottom
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Check if we have a bot message that's still streaming
  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
  const isStreamingBotMessage = lastMessage && lastMessage.sender === "bot" && lastMessage.status === "PENDING";

  // Check if the last message is from user and we're waiting for bot response
  const isWaitingForBotResponse = lastMessage && lastMessage.sender === "user";

  // Bot response is streaming or we're waiting for the first bot response
  const isStreaming = isStreamingBotMessage || isWaitingForBotResponse;


  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // mb: 1
        }}
      >
        {/* <Typography sx={{ fontWeight: "bold" }}></Typography> */}


        <Box>

          <Chip
            label={t("riskAnalysis.chip")}
            size="small"
            sx={{
              backgroundColor: '#1976d2',
              color: 'white',
              fontWeight: 500,
              fontSize: '0.75rem',
              mb: 0.5
            }}
          />
          <Box sx={{ flexDirection: "column", textAlign: "left", mb: 0, fontWeight: 'bold' }}>

            <Typography variant="h6" sx={{ fontWeight: 600, color: "#212121", mb: 0 }}>
              {t("chatbot.title")}
            </Typography>

          </Box>
        </Box>
        <Tooltip title={t('chatbot.clearChat')}>
          <span> {/* Wrapper span */}
            <Button
              onClick={handleClearChat}
              size="small"
              variant="contained"
              disabled={messages.length === 0 || loading || isStreaming}
              sx={{
                marginTop: 3,
                backgroundColor: red[500],
                '&:hover': {
                  backgroundColor: red[700],
                },
                '&.Mui-disabled': {
                  backgroundColor: grey[300],
                },
                padding: '2px 8px',
                fontSize: '0.75rem',
                minWidth: 'auto',
                textTransform: 'none',
              }}
              startIcon={<RefreshIcon fontSize="small" />}
            >
              {t('chatbot.refresh')}
            </Button>
          </span>
        </Tooltip>
      </Box>

      {/* Messages display area */}
      <Paper
        elevation={3}
        sx={{
          flexGrow: 1,
          p: 2,
          overflowY: "auto",
          borderRadius: 2,
          boxShadow: "none",
          paddingLeft: "0px",
          paddingRight: "0px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              opacity: 0.7
            }}
          >
            <Logo width={60} height={60} style={{ marginBottom: "16px", opacity: 0.8 }} />
            <Typography variant="body1" sx={{ textAlign: "center", fontWeight: "medium", mb: 1 }}>
              {t('chatbot.noMessages.title', 'Welcome to Contract Assistant')}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center", color: grey[600], maxWidth: "80%" }}>
              {t('chatbot.noMessages.subtitle', 'Ask questions about your contract or use the preset buttons below to get started.')}
            </Typography>
          </Box>
        ) : (
          messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  p: 1,
                  borderRadius: 2,
                  backgroundColor: msg.sender === "user" ? "black" : "#f0f0f0",
                  color: msg.sender === "user" ? "white" : "black",
                  maxWidth: "80%",
                  textAlign: msg.sender === "user" ? "right" : "left",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: formatTextToHTML(msg.text) }} />

                {msg.sender === "bot" && msg.status === "PENDING" && (
                  <LoadingDots>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </LoadingDots>
                )}
              </Typography>
            </Box>
          ))
        )}

        {/* Display a standalone loading indicator when waiting for first bot response */}
        {isWaitingForBotResponse && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                p: 1,
                borderRadius: 2,
                backgroundColor: "#f0f0f0",
                color: "black",
                maxWidth: "80%",
              }}
            >
              <LoadingDots>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </LoadingDots>
            </Typography>
          </Box>
        )}

        {error && (
          <Box
            sx={{
              color: 'error.main',
              textAlign: 'center',
              mt: 2,
              backgroundColor: 'rgba(211, 47, 47, 0.1)',
              p: 1,
              borderRadius: 1
            }}
          >
            <Typography variant="body2">{error}</Typography>
          </Box>
        )}

        {/* Warning message for long conversations */}
        {messages.length > 10 && (
          <Box
            sx={{
              textAlign: 'center',
              mt: 2,
              mb: 2,
              backgroundColor: 'rgba(255, 152, 0, 0.1)',
              p: 1,
              borderRadius: 1,
              border: '1px solid rgba(255, 152, 0, 0.3)'
            }}
          >
            <Typography variant="body2" sx={{ color: 'rgb(230, 81, 0)', fontSize: '11px' }}>
              {t('chatbot.performanceWarning')}
            </Typography>
          </Box>
        )}

        <div ref={chatEndRef} />
      </Paper>

      {/* Preset buttons */}
      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => sendMessage(t('chatbot.presetButtons.summarize'))}
          sx={{ flexGrow: 1, height: "30px", fontSize: "6px", width: "120px", padding: "0px", color: "black", border: "0", backgroundColor: "aliceblue" }}
          disabled={loading || !conversationId || isStreaming}
        >
          {t('chatbot.presetButtons.summarize')}
        </Button>
        <Button
          variant="outlined"
          onClick={() => sendMessage(t('chatbot.presetButtons.keyTerms'))}
          sx={{ flexGrow: 1, height: "30px", fontSize: "6px", width: "120px", padding: "0px", color: "black", border: "0", backgroundColor: "aliceblue" }}
          disabled={loading || !conversationId || isStreaming}
        >
          {t('chatbot.presetButtons.keyTerms')}
        </Button>
        <Button
          variant="outlined"
          onClick={() => sendMessage(t('chatbot.presetButtons.checkConformity'))}
          sx={{ flexGrow: 1, height: "30px", fontSize: "6px", width: "120px", padding: "0px", color: "black", border: "0", backgroundColor: "aliceblue" }}
          disabled={loading || !conversationId || isStreaming}
        >
          {t('chatbot.presetButtons.checkConformity')}
        </Button>
      </Box>

      {/* Input field and send button */}
      <InputContainer sx={{ backgroundColor: "#F8FAFB" }}>
        <InputBase
          fullWidth
          placeholder={t('chatbot.placeholder')}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && sendMessage(input)}
          sx={{ flex: 1, ml: 1 }}
          disabled={loading || !conversationId || isStreaming}
        />
        <IconButton
          onClick={() => sendMessage(input)}
          color="primary"
          disabled={!input.trim() || loading || !conversationId || isStreaming}
        >
          <SendIcon style={{ color: "rgb(26, 117, 211)" }} />
        </IconButton>
      </InputContainer>
    </Box>
  );
};

export default ChatBot;