import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Box, 
  Button, 
  Typography,
  CircularProgress,
  useTheme 
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  PictureAsPdf as PdfIcon,
  Description as DocxIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import ReactGA from 'react-ga4';
import { backendClient } from '../../../api/backend';
import { useTranslation } from 'react-i18next';

const DownloadDialog = ({ open, onClose, contractId }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadType, setDownloadType] = useState(null);
  const theme = useTheme();
  
  // These would typically come from your i18n implementation
  const { t } = useTranslation() // Placeholder for translation function
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async (format) => {
    if (!contractId) return;

    setIsDownloading(true);
    setDownloadType(format);
    
    try {
      // Use the direct download endpoint from your API
      const blob = await backendClient.downloadContract(contractId, format);

      // Create a blob URL and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `contract-${contractId}.${format}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      enqueueSnackbar(t("download_success", { format: format.toUpperCase() }), { variant: 'success' });

      // Google Analytics event for downloading contract
      ReactGA.event({
        category: 'Contract',
        action: 'User downloaded contract',
        label: `Contract ID: ${contractId}, Format: ${format}`
      });
    } catch (error) {
      console.error('Download failed:', error);
      enqueueSnackbar(t("download_error"), { variant: 'error' });
    } finally {
      setIsDownloading(false);
      setDownloadType(null);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={isDownloading ? undefined : onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t("download_contract")}
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mb: 3 }}
        >
          {t('download_contract_subtitle')}
        </Typography>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 2
          }}
        >
          <Button
            variant="outlined"
            startIcon={<PdfIcon />}
            onClick={() => handleDownload('pdf')}
            disabled={isDownloading}
            sx={{
              width: '100%',
              py: 1.5,
              justifyContent: 'flex-start',
              color: '#E53935',
              borderColor: alpha('#E53935', 0.3),
              backgroundColor: alpha('#E53935', 0.04),
              '&:hover': { 
                backgroundColor: alpha('#E53935', 0.08),
                borderColor: alpha('#E53935', 0.5),
              },
              textTransform: 'none',
              fontWeight: 600,
              borderRadius: 1.5
            }}
          >
            {isDownloading && downloadType === 'pdf' ? (
              <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
            ) : null}
            {t("download_as_pdf")}
          </Button>
          
          <Button
            variant="outlined"
            startIcon={<DocxIcon />}
            onClick={() => handleDownload('docx')}
            disabled={isDownloading}
            sx={{
              width: '100%',
              py: 1.5,
              justifyContent: 'flex-start',
              color: '#1E88E5',
              borderColor: alpha('#1E88E5', 0.3),
              backgroundColor: alpha('#1E88E5', 0.04),
              '&:hover': { 
                backgroundColor: alpha('#1E88E5', 0.08),
                borderColor: alpha('#1E88E5', 0.5),
              },
              textTransform: 'none',
              fontWeight: 600,
              borderRadius: 1.5
            }}
          >
            {isDownloading && downloadType === 'docx' ? (
              <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
            ) : null}
            {t("download_as_docx")}
          </Button>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button 
          disabled={isDownloading} 
          onClick={onClose} 
          color="inherit"
          startIcon={<CloseIcon />}
          sx={{ 
            textTransform: 'none', 
            fontWeight: 600,
            color: theme.palette.text.secondary
          }}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;