import { Extension } from '@tiptap/core'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'

// Configure the Table extension with all required table functionality
export const RichTable = Extension.create({
  name: 'richTable',
  
  addExtensions() {
    return [
      // Main Table extension with configurations
      Table.configure({
        resizable: true,
        allowTableNodeSelection: true,
        HTMLAttributes: {
          class: 'rich-table',
        },
      }),
      
      // Table Row extension
      TableRow,
      
      // Table Cell extension
      TableCell,
      
      // Table Header extension
      TableHeader,
    ]
  },
})

// Table utility functions
export const insertTable = (editor, options = { rows: 3, cols: 3, withHeaderRow: true }) => {
  if (!editor) return false
  return editor.chain().focus().insertTable(options).run()
}

export const addColumnBefore = (editor) => {
  if (!editor) return false
  return editor.chain().focus().addColumnBefore().run()
}

export const addColumnAfter = (editor) => {
  if (!editor) return false
  return editor.chain().focus().addColumnAfter().run()
}

export const deleteColumn = (editor) => {
  if (!editor) return false
  return editor.chain().focus().deleteColumn().run()
}

export const addRowBefore = (editor) => {
  if (!editor) return false
  return editor.chain().focus().addRowBefore().run()
}

export const addRowAfter = (editor) => {
  if (!editor) return false
  return editor.chain().focus().addRowAfter().run()
}

export const deleteRow = (editor) => {
  if (!editor) return false
  return editor.chain().focus().deleteRow().run()
}

export const deleteTable = (editor) => {
  if (!editor) return false
  return editor.chain().focus().deleteTable().run()
}

export const mergeCells = (editor) => {
  if (!editor) return false
  return editor.chain().focus().mergeCells().run()
}

export const splitCell = (editor) => {
  if (!editor) return false
  return editor.chain().focus().splitCell().run()
}

export const toggleHeaderColumn = (editor) => {
  if (!editor) return false
  return editor.chain().focus().toggleHeaderColumn().run()
}

export const toggleHeaderRow = (editor) => {
  if (!editor) return false
  return editor.chain().focus().toggleHeaderRow().run()
}

export const toggleHeaderCell = (editor) => {
  if (!editor) return false
  return editor.chain().focus().toggleHeaderCell().run()
}