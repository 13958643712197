import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  Badge,
  useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import SortIcon from '@mui/icons-material/Sort';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { filterContracts } from '../../../redux/actions/contractAction';

const SearchFilterBar = ({
  searchTerm,
  handleSearch,
  activeView,
  setActiveView,
  activeFilter,
  setActiveFilter,
  sortOrder,
  setSortOrder,
  currentUser,
  onAdvancedFilter // New prop for handling advanced filter results
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Filter menu state
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(0);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  // Advanced filter handlers
  const handleOpenAdvancedFilter = () => {
    setIsAdvancedFilterOpen(true);
  };

  const handleCloseAdvancedFilter = () => {
    setIsAdvancedFilterOpen(false);
  };

  const handleAdvancedFilter = (filteredContracts) => {
    // Update active filter count based on filter criteria
    setActiveAdvancedFilters(prevCount => prevCount > 0 ? prevCount + 1 : 1);

    // Pass filtered contracts to parent component
    if (onAdvancedFilter) {
      onAdvancedFilter(filteredContracts);
    }
  };

  // Handle the last 30 days filter using advanced filter API
  const handleLast30DaysFilter = async () => {
    // Update UI state
    setActiveFilter('recent');
    handleFilterClose();

    // Calculate the date 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    // Create filter parameters for last 30 days
    const filterParams = {
      updatedDate: {
        start: thirtyDaysAgo.toISOString()
      }
    };

    try {
      // Dispatch the filter action
      const filteredContracts = await dispatch(filterContracts(filterParams));

      // Update the badge count and notify the parent component
      setActiveAdvancedFilters(1);
      if (onAdvancedFilter) {
        onAdvancedFilter(filteredContracts);
      }
    } catch (error) {
      console.error('Error applying last 30 days filter:', error);
    }
  };

  // Handle the created by me filter using advanced filter API
  const handleCreatedByMeFilter = async () => {
    // Update UI state
    setActiveFilter('created');
    handleFilterClose();

    // Create filter parameters for contracts created by current user
    const filterParams = {
      createdByMe: true
    };

    try {
      // Dispatch the filter action
      const filteredContracts = await dispatch(filterContracts(filterParams));

      // Update the badge count and notify the parent component
      setActiveAdvancedFilters(1);
      if (onAdvancedFilter) {
        onAdvancedFilter(filteredContracts);
      }
    } catch (error) {
      console.error('Error applying created by me filter:', error);
    }
  };

  // Clear all filters
  const clearAllFilters = async () => {
    setActiveFilter('none');
    setActiveAdvancedFilters(0);
    handleFilterClose();

    // Reset all filters by passing empty filter params
    try {
      const allContracts = await dispatch(filterContracts({}));

      if (onAdvancedFilter) {
        onAdvancedFilter(allContracts);
      }
    } catch (error) {
      console.error('Error clearing filters:', error);
    }
  };

  // Apply a specific sort order
  const applySort = (sort) => {
    setSortOrder(sort);
    handleSortClose();
  };

  // Clear search input
  const clearSearch = () => {
    handleSearch({ target: { value: '' } });
  };

  // Get filter button label
  const getFilterButtonLabel = () => {
    switch (activeFilter) {
      case 'recent': return t('searchFilterBar.filters.last30Days');
      case 'created': return t('searchFilterBar.filters.createdByMe');
      default: return t('searchFilterBar.filter');
    }
  };

  // Get sort button label
  const getSortButtonLabel = () => {
    switch (sortOrder) {
      case 'date_desc': return t('searchFilterBar.sorting.newestFirst');
      case 'date_asc': return t('searchFilterBar.sorting.oldestFirst');
      case 'title_asc': return t('searchFilterBar.sorting.titleAsc');
      case 'title_desc': return t('searchFilterBar.sorting.titleDesc');
      default: return t('searchFilterBar.sort');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)',
          flexWrap: 'nowrap',
          overflow: 'visible',
          height: '64px'
        }}
      >
        <TextField
          fullWidth
          placeholder={t('searchFilterBar.searchPlaceholder')}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t('searchFilterBar.clearSearch')}
                  onClick={clearSearch}
                  edge="end"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              borderRadius: 1.5,
              bgcolor: alpha(theme.palette.common.black, 0.02)
            }
          }}
          sx={{
            flexShrink: 1,
            flexGrow: 1,
            mr: 2
          }}
        />

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
          gap: 2
        }}>
          {/* Advanced Filter Button */}
          {/* <Tooltip title={t('searchFilterBar.advancedFilter')}>
            <Badge 
              badgeContent={activeAdvancedFilters} 
              color="primary"
              overlap="circular"
              sx={{ 
                '.MuiBadge-badge': {
                  fontSize: '10px',
                  height: '18px',
                  minWidth: '18px'
                }
              }}
            >
              <IconButton
                onClick={handleOpenAdvancedFilter}
                sx={{ 
                  border: '1px solid',
                  borderColor: alpha(theme.palette.common.black, 0.1),
                  borderRadius: 1.5,
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.common.black, 0.02)
                  }
                }}
              >
                <TuneIcon />
              </IconButton>
            </Badge>
          </Tooltip> */}

          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleFilterClick}
            color={activeFilter !== 'none' ? 'primary' : 'inherit'}
            sx={{
              borderRadius: 1.5,
              py: 1,
              borderColor: activeFilter !== 'none'
                ? theme.palette.primary.main
                : alpha(theme.palette.common.black, 0.1),
              color: activeFilter !== 'none'
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: alpha(
                  activeFilter !== 'none'
                    ? theme.palette.primary.main
                    : theme.palette.common.black,
                  0.02
                )
              },
              whiteSpace: 'nowrap'
            }}
          >
            {getFilterButtonLabel()}
          </Button>

          <Button
            variant="outlined"
            startIcon={<SortIcon />}
            onClick={handleSortClick}
            color={sortOrder !== 'none' ? 'primary' : 'inherit'}
            sx={{
              borderRadius: 1.5,
              py: 1,
              borderColor: sortOrder !== 'none'
                ? theme.palette.primary.main
                : alpha(theme.palette.common.black, 0.1),
              color: sortOrder !== 'none'
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: alpha(
                  sortOrder !== 'none'
                    ? theme.palette.primary.main
                    : theme.palette.common.black,
                  0.02
                )
              },
              whiteSpace: 'nowrap'
            }}
          >
            {getSortButtonLabel()}
          </Button>

          <Box sx={{
            display: 'flex',
            ml: 1
          }}>
            <Tooltip title={t('searchFilterBar.gridView')}>
              <IconButton
                color={activeView === 'grid' ? 'primary' : 'default'}
                onClick={() => setActiveView('grid')}
                sx={{
                  borderRadius: 1.5,
                  bgcolor: activeView === 'grid' ? alpha(theme.palette.primary.main, 0.1) : 'transparent'
                }}
              >
                <GridViewIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('searchFilterBar.listView')}>
              <IconButton
                color={activeView === 'list' ? 'primary' : 'default'}
                onClick={() => setActiveView('list')}
                sx={{
                  borderRadius: 1.5,
                  bgcolor: activeView === 'list' ? alpha(theme.palette.primary.main, 0.1) : 'transparent'
                }}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            elevation: 2,
            sx: { borderRadius: 2, mt: 1, minWidth: 200 }
          }}
        >
          <MenuItem
            onClick={handleLast30DaysFilter}
            selected={activeFilter === 'recent'}
          >
            <CalendarTodayIcon fontSize="small" sx={{ mr: 1.5 }} />
            {t('searchFilterBar.filters.last30Days')}
          </MenuItem>
          <MenuItem
            onClick={handleCreatedByMeFilter}
            selected={activeFilter === 'created'}
          >
            <PersonIcon fontSize="small" sx={{ mr: 1.5 }} />
            {t('searchFilterBar.filters.createdByMe')}
          </MenuItem>
          <Divider />
          <MenuItem onClick={clearAllFilters}>
            {t('searchFilterBar.filters.clearFilters')}
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortClose}
          PaperProps={{
            elevation: 2,
            sx: { borderRadius: 2, mt: 1, minWidth: 200 }
          }}
        >
          <MenuItem
            onClick={() => applySort('date_desc')}
            selected={sortOrder === 'date_desc'}
          >
            {t('searchFilterBar.sorting.dateNewest')}
          </MenuItem>
          <MenuItem
            onClick={() => applySort('date_asc')}
            selected={sortOrder === 'date_asc'}
          >
            {t('searchFilterBar.sorting.dateOldest')}
          </MenuItem>
          <MenuItem
            onClick={() => applySort('title_asc')}
            selected={sortOrder === 'title_asc'}
          >
            {t('searchFilterBar.sorting.titleAsc')}
          </MenuItem>
          <MenuItem
            onClick={() => applySort('title_desc')}
            selected={sortOrder === 'title_desc'}
          >
            {t('searchFilterBar.sorting.titleDesc')}
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => applySort('none')}>
            {t('searchFilterBar.sorting.clearSorting')}
          </MenuItem>
        </Menu>

        {/* Advanced Filter Drawer */}
        {/* <ContractFilterDrawer 
        open={isAdvancedFilterOpen}
        onClose={handleCloseAdvancedFilter}
        onFilter={handleAdvancedFilter}
      /> */}

      </Box>
    </>
  );
};

export default SearchFilterBar;