import React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const BackButton = (props) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();

  const handleBack = () => {
    if (props.navigate_to) {
      navigate(props.navigate_to);
    } else {
      // Otherwise, navigate back in history
      navigate(-1);
    }
  };

  return (
    <IconButton
      onClick={handleBack}
      className="text-gray-800 hover:text-blue-500 focus:text-gray-500"
      size="large"
      edge="start"
      aria-label="back"
      sx={{
        "&:hover": {
          backgroundColor: "transparent", // Ensuring hover background is transparent
        },
        marginLeft: currentLang === "ar" ? "2px" : "0px", // Margin left if Arabic
      }}
    >
      <ArrowBackIcon
        sx={{
          transform: currentLang === "ar" ? "rotate(180deg)" : "none", // Rotate if Arabic
          transition: "transform 0.3s ease-in-out", // Smooth rotation transition
        }}
      />
      {/* {!isMobile() && (
        <Typography
          variant="h6"
          sx={{ fontSize: fontSize.medium, fontWeight: 16 }}
        >
          Retour
        </Typography>
      )} */}
    </IconButton>
  );
};

export default BackButton;
