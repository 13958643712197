import React, { useEffect, useMemo } from 'react'
import { Box, Grid, Paper, Modal } from '@mui/material'
import { colors } from '../../utils/colors'
import { styles } from '../../utils/styles'
import { PdfFocusProvider } from '../../components/pdf-viewer/pdfContext'
import { useParams } from 'react-router-dom'
import DocumentViewer from '../../components/document-viewer/DocumentViewer'
// import Chat from './components/Chat'
import Chat from './components/Chat/Chat'

import { messageActions } from '../../redux/actions/messageAction'; // Ensure correct path

import { isMobile } from '../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenNavBar } from '../../redux/actions/navbarAction'
import { useTranslation } from 'react-i18next'
import {
  clearDocument,
  setDocument,
  setIsModalMobileVisible,
  setSelectedCitation
} from '../../redux/actions/documentAction'
import { resetConversation } from '../../redux/actions/conversationAction'

const ChatScreen = () => {
  const { t, i18n } = useTranslation()
  const isLoadingDocument = useSelector(
    state => state.document.isLoadingDocument
  )
  const document = useSelector(state => state.document.document)
  const selectedCitation = useSelector(state => state.document.selectedCitation)
  const documentSourceUrl = useSelector(
    state => state.document.documentSourceUrl
  )
  const memoizedDocument = useMemo(() => document, [document]);
  const memoizedIsLoadingDocument = useMemo(() => isLoadingDocument, [isLoadingDocument]);
  const memoizedSelectedCitation = useMemo(() => selectedCitation, [selectedCitation]);
  const memoizedDocumentSourceUrl = useMemo(() => documentSourceUrl, [documentSourceUrl]);

  const currentLang = i18n.language
  const dispatch = useDispatch()
  const { conversationId } = useParams()
  const is_show_pdf = true

  const isModalMobileVisibile = useSelector(
    state => state.document.isModalMobileVisible
  )

  useEffect(() => {
    // dispatch(messageActions.clearConversations())
    dispatch(resetConversation());
    dispatch(messageActions.clearMessages())
    dispatch(clearDocument())
    // Don't open the modal on initial render
  }, [dispatch])

  // Handle modal close
  const handleCloseModal = () => {
    dispatch(setIsModalMobileVisible(false))
    dispatch(setSelectedCitation(null))
  }

  useEffect(() => {
    dispatch(setOpenNavBar(false))
  }, [dispatch])

  return (
    <PdfFocusProvider>
      <Box
        sx={{
          backgroundColor: colors.bg_color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '94vh',
          overflow: 'hidden'
        }}
        dir={currentLang === 'ar' ? 'rtl' : 'ltr'} // Set direction for RTL if Arabic
      >
        <Paper
          elevation={3}
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            // borderRadius: styles.main_container_radius,
            overflow: 'hidden'
          }}
        >
          <Grid container sx={{ height: '100%', width: '100%' }}>
            {/* Chat Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                height: '100%',
                width: '50%',
                maxWidth: '50%',
                borderRight:
                  is_show_pdf && currentLang !== 'ar'
                    ? '1px solid #e0e0e0'
                    : 'none', // Adjust border for RTL
                // borderLeft:
                //   is_show_pdf && currentLang === 'ar'
                //     ? '1px solid #e0e0e0'
                //     : 'none', // Add border-left for Arabic
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  // padding: '16px',
                  width: '100%',
                  overflow: 'hidden'
                }}
                aria-label={t('chat_section')} // Accessible label for chat section
              >
                <Chat
                  conversationId={conversationId}
                />
              </Box>
            </Grid>

            {/* PDF Viewer Section for Desktop */}
            {!isMobile() && is_show_pdf && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  height: '100%',
                  width: '50%',
                  maxWidth: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                aria-label={t('pdf_viewer_section')} // Accessible label for PDF viewer section
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                  }}
                >
                  <DocumentViewer
                    document={memoizedDocument}
                    documentSourceUrl={memoizedDocumentSourceUrl}
                    isLoadingDocument={memoizedIsLoadingDocument}
                    selectedCitation={memoizedSelectedCitation}
                    setDocument={setDocument}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        {/* Modal for Mobile View */}
        <Modal
          open={isModalMobileVisibile}
          onClose={handleCloseModal}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          aria-label={t('pdf_viewer_section')} // Accessible label for modal
        >
          <Box
            sx={{
              width: '90%',
              height: '80vh',
              backgroundColor: 'white',
              padding: isMobile() ? "5px" : '16px',
              borderRadius: styles.main_container_radius,
              overflow: 'auto'
            }}
          >
            <DocumentViewer
              document={memoizedDocument}
              documentSourceUrl={memoizedDocumentSourceUrl}
              isLoadingDocument={memoizedIsLoadingDocument}
              selectedCitation={memoizedSelectedCitation}
              setDocument={setDocument}
            />
          </Box>
        </Modal>
      </Box>
    </PdfFocusProvider>
  )
}

export default ChatScreen