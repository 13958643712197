import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  Divider,
  Box,
  InputAdornment,
  Alert,
  Fade
} from '@mui/material'
import { Visibility, VisibilityOff, Email, Lock } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
  clearAuthState,
  clearMessageError,
  login
} from '../../redux/actions/authAction'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import CustomButton from '../../components/CustomButton'
import { colors } from '../../utils/colors'
import { clearStore } from '../../redux/store'
import { domainConfig, isMobile } from '../../utils/utils'

const LoginScreen = () => {
  const dispatch = useDispatch()
  const { loading, error, user } = useSelector(state => state.auth)
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const isMobileView = isMobile()


  const handleLogin = async () => {
    // Validate email
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    setEmailError(!emailValid)

    // Validate password
    const isValidPassword = password !== ""
    if (!isValidPassword) {
      setPasswordError(!isValidPassword)
      return
    } else {
      setPasswordError('')
    }

    let newDomain
    // Only proceed with login if both email and password are valid
    if (emailValid) {
      // direct user to appropriate domain before login.
      // Important because cookies are related to the window url
      const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
      const domain = email.toLowerCase().split('@')[1]

      if (isProduction) {
        if (domainConfig[domain]) {
          newDomain = domainConfig[domain]['domainName']

          // if mtl user not in correct path
          if (!window.location.hostname.includes(newDomain)) {
            const currentPath =
              window.location.pathname +
              window.location.search +
              window.location.hash

            const newUrl = `https://${newDomain}.juridia.ma${currentPath}`
            window.location.href = newUrl
            window.alert(
              `Nous vous redirigeons pour que vous vous connectiez depuis votre espace personnalisé ${newUrl}.`
            )
            // if mtl user is in correct path
          } else {
            await dispatch(login(email, password, navigate, newDomain))
          }
        } else {
          // if user not mtl, registering in mtl
          if (window.location.hostname.split('.')[0] !== 'app') {
            window.location.href = 'https://app.juridia.ma/login'
            window.alert(
              `Accès réservé. Nous vous redirigeons pour que vous vous inscriviez, sur : https://app.juridia.ma.`
            )
          } else {
            await dispatch(login(email, password, navigate, newDomain))
          }
        }
      } else {
        console.log('replacing url in dev')
        await dispatch(login(email, password, navigate, newDomain))
      }
    }
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (user) {
      const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
      const domain = user.user.email.split('@')[1]

      // Check if the domain exists in the domainConfig
      if (isProduction && domainConfig[domain]) {
        console.log('replacing location')
        // Redirect the app to domain.juridia.ma
        window.location.replace(
          `https://${domainConfig[domain]['domainName']}.juridia.ma/welcome`
        )
      } else {
        navigate('/')
      }
    }
  }, [user, navigate])

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError())
  }, [dispatch])

  return (
    <Grid
      container
      sx={{
        height: '94vh', // Changed from minHeight to exact height
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.bg_color,
        margin: 0, // Removed margins
        padding: isMobileView ? '0' : '32px',
        overflow: 'visible',
      }}
    >
      <Paper
        elevation={8}
        sx={{
          borderRadius: isMobileView ? '12px' : '24px',
          width: isMobileView ? '100%' : '90%',
          maxWidth: '1200px',
          overflow: 'hidden',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Grid container sx={{ height: '100%' }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: 'linear-gradient(135deg, #000000 0%, #2C2C2C 100%)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              padding: 4,
              minHeight: isMobileView ? '200px' : '600px'
            }}
          >
            {/* Background pattern */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                backgroundImage: 'radial-gradient(circle, #FFFFFF 1px, transparent 1px)',
                backgroundSize: '20px 20px'
              }}
            />

            <Box
              sx={{
                width: isMobileView ? '100px' : '160px',
                height: isMobileView ? '100px' : '160px',
                backgroundColor: 'white',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                zIndex: 2,
                mb: 3
              }}
            >
              <Logo
                style={{
                  width: isMobileView ? '80px' : '140px',
                  height: isMobileView ? '80px' : '140px'
                }}
              />
            </Box>

            <Typography
              variant={isMobileView ? 'h5' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
                zIndex: 2
              }}
            >
              Bienvenue sur Juridia
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                textAlign: 'center',
                maxWidth: '80%',
                zIndex: 2
              }}
            >
              Simplifiez votre pratique juridique au quotidien avec l'intelligence artificielle.
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: isMobileView ? 3 : 6,
              backgroundColor: '#FFFFFF',
              overflowY: 'auto', // Allow scrolling in the login form if needed
              maxHeight: '100vh' // Limit height to viewport
            }}
          >
            <Container maxWidth="sm">
              <Typography
                variant={isMobileView ? 'h5' : 'h4'}
                sx={{
                  mb: 1,
                  fontWeight: 'bold',
                  color: '#333333'
                }}
              >
                Se connecter
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  color: '#666666'
                }}
              >
                Accédez à votre espace personnel
              </Typography>

              {error && (
                <Fade in={!!error}>
                  <Alert
                    severity="error"
                    sx={{
                      mb: 2,
                      borderRadius: '8px'
                    }}
                  >
                    {error}
                  </Alert>
                </Fade>
              )}

              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={emailError}
                helperText={
                  emailError ? 'Veuillez entrer une adresse e-mail valide.' : ''
                }
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="action" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&.Mui-focused fieldset': {
                      borderColor: colors.primary,
                    },
                  }
                }}
              />

              <TextField
                required
                fullWidth
                label="Mot de passe"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  mb: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&.Mui-focused fieldset': {
                      borderColor: colors.primary,
                    },
                  }
                }}
              />

              <Box display="flex" justifyContent="flex-end" mb={3}>
                <Typography
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    color: colors.primary,
                    fontWeight: 500,
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                  onClick={handleForgotPassword}
                >
                  Mot de passe oublié ?
                </Typography>
              </Box>

              <CustomButton
                text="Se connecter"
                outlined={false}
                loading={loading}
                onClick={handleLogin}
                sx={{
                  width: '100%',
                  py: 1.5,
                  borderRadius: '10px',
                  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  mb: 3
                }}
              />

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" color="text.secondary" sx={{ px: 1 }}>
                  OU
                </Typography>
              </Divider>

              <Box display="flex" justifyContent="center">
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  Vous n'avez pas de compte ? {' '}
                  <Typography
                    component="span"
                    variant="body2"
                    onClick={() => {
                      window.location.href = "https://juridia.ma/#pricing"
                    }}
                    sx={{
                      color: colors.primary,
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Souscrire
                  </Typography>
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  component="span"
                  variant="body2"
                  onClick={() => navigate('/signup')}
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    color: colors.primary,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  IMIST
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default LoginScreen