import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ReactGA from 'react-ga4';
import { colors } from '../../utils/colors';
import { styles } from '../../utils/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { backendClient } from '../../api/backend';
import {
  fetchConversations,
  createConversation,
  deleteConversations,
  searchConversations,
  resetConversation,
  shareConversation,
  filterConversations
} from '../../redux/actions/conversationAction';
import { messageActions } from '../../redux/actions/messageAction';
import { setSelectedCitation, setDocument } from '../../redux/actions/documentAction';
import debounce from 'lodash/debounce';

// Import components
import ConversationTable from './components/ConversationTable';
import SearchAndActions from './components/SearchAndActions';
import ConversationPagination from './components/ConversationPagination';
import ShareConversationModal from './components/ShareConversationModal';
import DeleteConfirmationDialog from './components/DeleteConfirmationDialog';
import ConversationInsights from './components/ConversationInsights';
import { useTranslation } from 'react-i18next';
import { domainConfig, getDomainName } from '../../utils/utils';

const ChatHistory = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);
  const loading = useSelector(state => state.conversations.loading);
  const conversations = useSelector(state => state.conversations.conversations);
  const loadingCreate = useSelector(state => state.conversations.loadingCreate);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 6;
  const [selected, setSelected] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [totalConversations, setTotalConversations] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'grid'
  const [sortBy, setSortBy] = useState('updated_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [activeFilters, setActiveFilters] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  // Analytics for page view
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/chat-history',
      title: 'Chat History'
    });
  }, []);

  // Fetch total conversation count
  const fetchTotalCount = async () => {
    const { totalConversations } = await backendClient.getConversationsCountByUser(user?.id);
    setTotalConversations(totalConversations);
  };

  useEffect(() => {
    fetchTotalCount();
  }, []);

  // Update total count based on search results or all conversations
  useEffect(() => {
    const totalCount_ = searchTerm.length > 0 || activeFilters.length > 0
      ? filteredRows.length.toLocaleString()
      : totalConversations.toLocaleString();
    setTotalCount(totalCount_);
  }, [searchTerm, conversations, filteredRows, totalConversations, activeFilters]);

  // Fetch conversations when user changes or page changes
  useEffect(() => {
    if (user && user.id && !isFiltering) {
      dispatch(fetchConversations(user.id, rowsPerPage, (page - 1) * rowsPerPage));
    }
  }, [user, page, dispatch, isFiltering]);

  // Apply filters when activeFilters change
  useEffect(() => {
    if (user && user.id && activeFilters.length > 0) {
      setIsFiltering(true);
      dispatch(filterConversations(activeFilters, user.id, rowsPerPage, (page - 1) * rowsPerPage))
        .then(data => {
          // Success case handled by reducer
          setIsFiltering(false);
        })
        .catch(error => {
          console.error("Error applying filters:", error);
          enqueueSnackbar(t('loading_error'), { variant: 'error' });
          setIsFiltering(false);
        });
    } else {
      setIsFiltering(false);
    }
  }, [activeFilters, user, page, rowsPerPage, dispatch, t, enqueueSnackbar]);

  // Handle filter changes from SearchAndActions component
  const handleFilterChange = useCallback((filteredConversations) => {
    setFilteredRows(filteredConversations);
    
    // Track filter usage in analytics
    if (activeFilters.length > 0) {
      ReactGA.event({
        category: 'Filter',
        action: 'User filtered conversations',
        label: `Filters: ${activeFilters.join(', ')}`
      });
    }
  }, [activeFilters]);

  // Update the filter state from SearchAndActions
  const handleFiltersUpdate = (newFilters) => {
    setActiveFilters(newFilters);
    // Reset to first page when filters change
    setPage(1);
  };

  // Update filtered rows when conversations change
  useEffect(() => {
    setFilteredRows(conversations);
  }, [conversations]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(term => {
      if (user && user.id) {
        dispatch(searchConversations(user.id, term));
      }
    }, 500),
    [dispatch, user]
  );

  // Handle search input
  const handleSearch = event => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedSearch(term);

    // Reset filters when searching
    if (term && activeFilters.length > 0) {
      setActiveFilters([]);
    }

    ReactGA.event({
      category: 'Search',
      action: 'User searched conversations',
      label: term
    });
  };

  // Handle pagination
  const handlePageChange = (event, value) => {
    setPage(value);
    const offset = (value - 1) * rowsPerPage;
    
    if (activeFilters.length > 0) {
      dispatch(filterConversations(activeFilters, user.id, rowsPerPage, offset));
    } else {
      dispatch(fetchConversations(user.id, rowsPerPage, offset));
    }
  };

  // Open conversation
  const handleOpenConversation = async chatId => {
    dispatch(resetConversation());
    dispatch(messageActions.clearMessages());
    navigate(`/conversation/${chatId}`);
    enqueueSnackbar(t("open_conversation"), { variant: 'success' });
  };

  // Create a new conversation
  const handleCreateConversation = async () => {
    try {
      const newConversation = await dispatch(createConversation(user.id));
      if (newConversation) {
        dispatch(setDocument(null));
        dispatch(setSelectedCitation(null));
        enqueueSnackbar(t("create_conversation_success"), { variant: 'success' });
        navigate(`/conversation/${newConversation.id}`);

        ReactGA.event({
          category: 'Conversation',
          action: 'User created a new conversation',
          label: `User ID: ${user.id}`
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to create conversation', { variant: 'error' });
    }
  };

  // Handle select all checkbox
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = filteredRows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // Delete selected conversations
  const handleDeleteConversations = async () => {
    try {
      setLoadingDelete(true);
      await dispatch(deleteConversations(selected, user.id));
      setSelected([]);
      const offset = (page - 1) * rowsPerPage;
      
      if (activeFilters.length > 0) {
        dispatch(filterConversations(activeFilters, user.id, rowsPerPage, offset));
      } else {
        dispatch(fetchConversations(user.id, rowsPerPage, offset));
      }
      
      await fetchTotalCount();
      enqueueSnackbar(t("delete_conversation_success"), { variant: 'success' });

      ReactGA.event({
        category: 'Conversation',
        action: 'User deleted conversations',
        label: `Deleted Conversation IDs: ${selected.join(', ')}`
      });
    } catch (error) {
      enqueueSnackbar('Failed to delete conversations', { variant: 'error' });
    }
    setIsDeleteDialogOpen(false);
    setLoadingDelete(false);
  };

  // Handle checkbox selection
  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Open share modal
  const handleShareConversation = conversationId => {
    setCurrentConversationId(conversationId);
    setShareModalOpen(true);

    console.log(`Initiating share for conversation ID: ${conversationId}`);

    ReactGA.event({
      category: 'Conversation',
      action: 'User initiated sharing conversation',
      label: `Conversation ID: ${conversationId}`
    });
  };

  // Handle share operation
  const handleShare = async selectedUserIds => {
    if (currentConversationId && selectedUserIds.length > 0) {
      // Get user domain
      const domainName = getDomainName(user.email)

      await dispatch(shareConversation(currentConversationId, selectedUserIds, domainName))
        .then(() => {
          console.log(`Conversation ${currentConversationId} shared successfully!`);
          enqueueSnackbar(t('share_success'), { variant: 'success' });
        })
        .catch(error => {
          console.error(`Failed to share conversation: ${error.message}`);
          enqueueSnackbar(t('share_error'), { variant: 'error' });
        });
    } else {
      console.error('No user IDs selected for sharing.');
      enqueueSnackbar(t('no_users_selected'), { variant: 'warning' });
    }
  };

  // Handle open delete dialog
  const handleOpenDeleteDialog = () => {
    if (selected.length > 0) {
      setIsDeleteDialogOpen(true);
    } else {
      enqueueSnackbar(t('please_select_contracts'), { variant: 'warning' });
    }
  };

  // Toggle view mode between list and grid
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  // Handle sort change
  const handleSortChange = (field) => {
    if (sortBy === field) {
      // Toggle direction if clicking the same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to descending
      setSortBy(field);
      setSortDirection('desc');
    }
  };

  // Sort conversations
  const sortedConversations = [...filteredRows].sort((a, b) => {
    if (sortBy === 'title') {
      return sortDirection === 'asc'
        ? a.title?.localeCompare(b.title || '')
        : b.title?.localeCompare(a.title || '');
    } else if (sortBy === 'created_at' || sortBy === 'updated_at') {
      const dateA = new Date(a[sortBy]);
      const dateB = new Date(b[sortBy]);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_color,
        py: 0.5, px: 0.5,
        minHeight: 'calc(100vh - 64px)'
      }}
      dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
    >
      <ShareConversationModal
        open={isShareModalOpen}
        onClose={() => setShareModalOpen(false)}
        onShare={handleShare}
        organizationName={user?.organization_name}
      />

      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteConversations}
        selectedCount={selected.length}
      />

      <Typography
        variant='h5'
        sx={{
          fontWeight: 700,
          marginBottom: '24px',
          textAlign: currentLang === 'ar' ? 'right' : 'left',
        }}
      >
        {t('chat_history')}
      </Typography>

      <Paper
        elevation={2}
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
          flexGrow: 1,
          p:1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >

        <Box sx={{ display: 'flex', gap: 3, mb: 1, flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ flex: 3 }}>
            <SearchAndActions
              searchTerm={searchTerm}
              handleSearch={handleSearch}
              selected={selected}
              loadingDelete={loadingDelete}
              loadingCreate={loadingCreate}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleCreateConversation={handleCreateConversation}
              viewMode={viewMode}
              onViewModeChange={handleViewModeChange}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSortChange}
              currentLang={currentLang}
              conversations={conversations}
              onFilterChange={handleFilterChange}
              activeFilters={activeFilters}
              onFiltersUpdate={handleFiltersUpdate}
              isFiltering={isFiltering}
            />
          </Box>
        </Box>

        <ConversationTable
          conversations={sortedConversations}
          loading={loading || isFiltering}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleCheckboxClick={handleCheckboxClick}
          handleOpenConversation={handleOpenConversation}
          handleShareConversation={handleShareConversation}
          handleCreateConversation={handleCreateConversation}
          viewMode={viewMode}
          currentLang={currentLang}
          noDataMessage={
            activeFilters.length > 0 
              ? t('no_matching_conversations_filtered') 
              : searchTerm 
                ? t('no_matching_conversations_search') 
                : t('no_conversations')
          }
        />

        <ConversationPagination
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          currentLang={currentLang}
        />

      </Paper>
    </Box>
  );
};

export default ChatHistory;