import React from 'react'
import ReactGA from 'react-ga4'
import { Box, IconButton, Chip, Typography, Divider } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CloseIcon from '@mui/icons-material/Close'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDocTypes } from '../../../redux/actions/searchAction'

export const SearchFilter = () => {
  const dispatch = useDispatch()

  const selectedDocTypes = useSelector(
    state => state.search.selectedDocTypes || []
  )
  const availableDocTypes = useSelector(state => state.search.availableDocTypes)

  const { t } = useTranslation()

  const handleScrollLeft = () => {
    const scrollContainer = document.getElementById('chips-scroll-container')
    scrollContainer.scrollLeft -= 200
  }

  const handleScrollRight = () => {
    const scrollContainer = document.getElementById('chips-scroll-container')
    scrollContainer.scrollLeft += 200
  }

  const handleChipClick = docType => {
    const updatedDocTypes = [...selectedDocTypes, docType]
    dispatch(setSelectedDocTypes(updatedDocTypes))

    ReactGA.event({
      category: 'Filter',
      action: 'User selected a filter',
      label: docType.label
    })
  }

  const handleUnselectChip = (e, docType) => {
    e.stopPropagation();
    const updatedDocTypes = selectedDocTypes.filter(
      selected => selected.label !== docType.label
    )
    dispatch(setSelectedDocTypes(updatedDocTypes))

    ReactGA.event({
      category: 'Filter',
      action: 'User removed a filter',
      label: docType.label
    })
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          background: '#f7fafc',
          borderRadius: '10px',
          p: 0.5
        }}
      >
        <FilterAltOutlinedIcon
          sx={{
            color: '#4a5568',
            mr: 1,
            fontSize: '1.1rem'
          }}
        />

        {selectedDocTypes.length > 0 && (
          <Chip
            size="small"
            label={selectedDocTypes.length}
            sx={{
              height: 20,
              fontSize: '0.7rem',
              backgroundColor: '#3182ce',
              color: 'white',
              fontWeight: 'bold',
              ml: 1,
              position: 'absolute',
              top: -5,
              left: 20,
              zIndex: 3
            }}
          />
        )}

        <IconButton
          onClick={handleScrollLeft}
          sx={{
            zIndex: 2,
            backgroundColor: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            width: 30,
            height: 30,
            '&:hover': { backgroundColor: '#f5f5f5' }
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '12px', color: '#4a5568' }} />
        </IconButton>

        <Box
          id='chips-scroll-container'
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 1,
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            flexGrow: 1,
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            px: 1,
            py: 1
          }}
        >
          {selectedDocTypes?.length > 0 && (
            <>
              {selectedDocTypes?.map((docType, index) => (
                <Chip
                  key={`selected-${index}`}
                  label={t(docType.label)}
                  sx={{
                    backgroundColor: `${docType.color}20`,
                    color: docType.color,
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    border: `1px solid ${docType.color}60`,
                    height: 32,
                    '& .MuiChip-deleteIcon': {
                      color: docType.color,
                      '&:hover': {
                        color: '#e53e3e'
                      }
                    }
                  }}
                  onDelete={(e) => handleUnselectChip(e, docType)}
                  deleteIcon={<CloseIcon style={{ fontSize: '16px' }} />}
                />
              ))}

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mx: 1,
                  backgroundColor: '#cbd5e0'
                }}
              />
            </>
          )}

          {availableDocTypes?.map((docType, index) => (
            <Chip
              key={index}
              label={t(docType.label)}
              sx={{
                borderColor: docType.color,
                backgroundColor: 'white',
                color: docType.color,
                fontWeight: 600,
                fontSize: '0.75rem',
                height: 32,
                border: `1px solid ${docType.color}60`,
                '&:hover': {
                  backgroundColor: `${docType.color}10`
                }
              }}
              onClick={() => handleChipClick(docType)}
            />
          ))}
        </Box>

        <IconButton
          onClick={handleScrollRight}
          sx={{
            zIndex: 2,
            backgroundColor: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            width: 30,
            height: 30,
            '&:hover': { backgroundColor: '#f5f5f5' }
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: '12px', color: '#4a5568' }} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default SearchFilter