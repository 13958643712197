import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  IconButton,
  Box,
  Typography,
  FormControl,
  CircularProgress,
  Paper,
  Avatar,
  Fade,
  Tooltip,
  Select,
  MenuItem,
  Snackbar,
  Alert
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { backendClient } from "../../../../api/backend";
import { useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import { getDomainName } from "../../../../utils/utils";
import ShareContractModal from "../../components/ShareContractModal";
import { useTranslation } from "react-i18next";

// Updated Enum for collaborator roles (synced with backend)
export const CollaboratorRoleEnum = {
  CREATOR: "creator",
  VIEWER: "viewer",
  EDITOR: "editor",
  REVIEWER: "reviewer",
  SIGNATOR: "signator"
};

// Updated role labels with i18n support
const roleLabels = {
  [CollaboratorRoleEnum.CREATOR]: "Creator",
  [CollaboratorRoleEnum.VIEWER]: "Viewer",
  [CollaboratorRoleEnum.EDITOR]: "Editor",
  [CollaboratorRoleEnum.REVIEWER]: "Reviewer",
  [CollaboratorRoleEnum.SIGNATOR]: "Signator"
};

// French role labels (keeping for reference)
const frenchRoleLabels = {
  [CollaboratorRoleEnum.CREATOR]: "Créateur",
  [CollaboratorRoleEnum.EDITOR]: "Éditeur",
  [CollaboratorRoleEnum.VIEWER]: "Lecteur",
  [CollaboratorRoleEnum.REVIEWER]: "Relecteur",
  [CollaboratorRoleEnum.SIGNATOR]: "Signataire"
};

// Internationalization text
const i18n = {
  sectionTitle: "Collaborators",
  noCollaborators: "No collaborator added.",
  addCollaborator: "Add a collaborator",
  successAdd: "Collaborators added successfully.",
  errorAdd: "Error adding collaborators. Please try again.",
  successRemove: "{name} successfully removed.",
  errorRemove: "Error removing {name}. Please try again.",
  successUpdate: "{name}'s role updated successfully.",
  errorUpdate: "Error updating {name}'s role. Please try again.",
  creator: "Creator" // For the creator label next to the name
};

// Function to get initials from a name
const getInitials = (name) => {
  return name
    .split(' ')
    .map(part => part.charAt(0))
    .join('')
    .toUpperCase();
};

const Collaboration = ({ contractId }) => {
  const { t, i18n } = useTranslation();
  const [collaborators, setCollaborators] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(state => state.auth?.user?.user);

  // New states for Snackbars
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (contractId) {
      fetchExistingCollaborators();
    }
  }, [contractId]);

  const fetchExistingCollaborators = async () => {
    try {
      setIsSaving(true);
      const contractData = await backendClient.getContract(contractId);
      if (contractData && contractData.collaborators) {
        const mappedCollaborators = contractData.collaborators.map(collab => ({
          id: collab.id,
          name: `${collab.first_name} ${collab.last_name}`,
          email: collab.email,
          role: collab.role
        }));
        setCollaborators(mappedCollaborators);
      }
    } catch (err) {
      console.error("Error fetching collaborators:", err);
      setError("Unable to load collaborators.");

      // Show error alert
      setAlertMessage("Unable to load collaborators.");
      setShowErrorAlert(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleShare = async (selectedUsers) => {
    if (!selectedUsers.length) return;

    setIsSaving(true);
    setError(null);

    try {
      const updatedCollaborators = [...collaborators];

      // Prepare users with appropriate format for backend API
      const users = selectedUsers.map(user => ({
        user_id: user.id,
        role: user.role
      }));

      const domainName = getDomainName(currentUser?.email);

      await backendClient.shareContract(contractId, users, domainName);

      // Add new users to collaborators list
      selectedUsers.forEach(newUser => {
        if (!updatedCollaborators.some(c => c.id === newUser.id)) {
          updatedCollaborators.push({
            id: newUser.id,
            name: `${newUser.first_name} ${newUser.last_name}`,
            email: newUser.email || "",
            role: newUser.role
          });
        }
      });

      setCollaborators(updatedCollaborators);
      setIsShareModalOpen(false);

      // Show success alert
      setAlertMessage(t('successAdd'));
      setShowSuccessAlert(true);
    } catch (err) {
      console.error("Error adding collaborators:", err);
      setError(t("errorAdd"));

      // Show error alert
      setAlertMessage(t("errorAdd"));
      setShowErrorAlert(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (collaboratorId, name) => {
    setIsSaving(true);
    setError(null);

    try {
      const filteredCollaborators = collaborators.filter(c => c.id !== collaboratorId);
      setCollaborators(filteredCollaborators);

      await backendClient.removeCollaborator(contractId, collaboratorId);

      console.log(`Collaborator ${name} successfully removed`);

      // Show success alert
      setAlertMessage(t('successRemove').replace("{name}", name));
      setShowSuccessAlert(true);
    } catch (err) {
      console.error(`Error removing collaborator ${name}:`, err);
      fetchExistingCollaborators();
      setError(t('errorRemove').replace("{name}", name));

      // Show error alert
      setAlertMessage(t('errorRemove').replace("{name}", name));
      setShowErrorAlert(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRoleChange = async (collaboratorId, name, newRole) => {
    setIsSaving(true);
    setError(null);

    try {
      const updatedCollaborators = collaborators.map(collab =>
        collab.id === collaboratorId ? { ...collab, role: newRole } : collab
      );
      setCollaborators(updatedCollaborators);

      await backendClient.updateCollaboratorRole(contractId, collaboratorId, newRole);

      console.log(`Role successfully updated for ${name} to ${newRole}`);

      // Show success alert
      setAlertMessage(t('successUpdate').replace("{name}", name));
      setShowSuccessAlert(true);
    } catch (err) {
      console.error(`Error updating role for ${name}:`, err);
      fetchExistingCollaborators();
      setError(t('errorUpdate').replace("{name}", name));

      // Show error alert
      setAlertMessage(t('errorUpdate').replace("{name}", name));
      setShowErrorAlert(true);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: '#ffffff',
      color: '#333333',
      borderRadius: '4px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
      overflow: 'hidden'
    }}>
      {/* Loading indicator - center only */}
      {isSaving && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2
        }}>
          <CircularProgress color="primary" />
        </Box>
      )}

      {/* Section title */}
      <Box sx={{ textAlign: "left", mb: 2, fontWeight: 'bold' }}>
        <h3>{t('sectionTitle')}</h3>
      </Box>

      {/* Collaborators list */}
      <List sx={{
        flexGrow: 1,
        overflowY: "auto",
        p: 0,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#c1c1c1',
          borderRadius: '4px',
        },
      }}>
        {collaborators.length === 0 ? (
          <Box sx={{
            textAlign: 'center',
            color: '#757575',
            padding: 3
          }}>
            <Typography variant="body2">
              {t('noCollaborators')}
            </Typography>
          </Box>
        ) : (
          // Sort collaborators to put creator first, then sort others
          collaborators
            .sort((a, b) => {
              if (a.role === CollaboratorRoleEnum.CREATOR) return -1;
              if (b.role === CollaboratorRoleEnum.CREATOR) return 1;
              return 0;
            })
            .map((collab) => (
              <Box
                key={collab.id}
                sx={{
                  p: 0,
                  borderBottom: '1px solid #eeeeee'
                }}
              >
                <ListItem
                  sx={{
                    py: 1.5,
                    px: 2,
                    '&:hover': {
                      backgroundColor: '#f5f5f5'
                    }
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: '#4a90e2',
                      width: 36,
                      height: 36,
                      mr: 2,
                      fontWeight: 'bold',
                      fontSize: '0.9rem'
                    }}
                  >
                    {getInitials(collab.name)}
                  </Avatar>

                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{
                        fontWeight: 500,
                        color: '#333333',
                        fontSize: '14px'
                      }}>
                        {collab.name}
                        {collab.role === CollaboratorRoleEnum.CREATOR && (
                          <Typography
                            component="span"
                            sx={{
                              ml: 1,
                              fontSize: '12px',
                              color: '#4a90e2',
                              fontWeight: 'bold'
                            }}
                          >
                            ({t('creator')})
                          </Typography>
                        )}
                      </Typography>
                    </Box>

                    <Typography variant="body2" sx={{
                      color: '#757575',
                      fontSize: '13px',
                      mt: 0.5
                    }}>
                      {collab.email}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {collab.role !== CollaboratorRoleEnum.CREATOR && (
                      <>
                        <FormControl size="small" variant="outlined" sx={{ minWidth: 110 }}>
                          <Select
                            value={collab.role}
                            onChange={(e) => handleRoleChange(collab.id, collab.name, e.target.value)}
                            sx={{
                              height: 32,
                              fontSize: '13px',
                              color: '#333333',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e0e0e0'
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#b0b0b0'
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#4a90e2'
                              }
                            }}
                            disabled={isSaving}
                          >
                            {Object.keys(CollaboratorRoleEnum).map((roleKey) => (
                              roleKey !== 'CREATOR' && (
                                <MenuItem
                                  key={roleKey}
                                  value={CollaboratorRoleEnum[roleKey]}
                                  sx={{ fontSize: '13px' }}
                                >
                                  {roleLabels[CollaboratorRoleEnum[roleKey]]}
                                </MenuItem>
                              )
                            ))}
                          </Select>
                        </FormControl>

                        <Tooltip title="Delete" placement="top">
                          <IconButton
                            onClick={() => handleDelete(collab.id, collab.name)}
                            size="small"
                            sx={{
                              color: "#757575",
                              '&:hover': {
                                color: '#d32f2f',
                                backgroundColor: 'rgba(211, 47, 47, 0.1)'
                              }
                            }}
                            disabled={isSaving}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </ListItem>
              </Box>
            ))
        )}
      </List>

      {/* Button to show add form */}
      <Box sx={{ pt: 2, borderTop: '1px solid #e0e0e0' }}>
        <CustomButton
          text={t("addCollaborator")}
          variant="contained"
          startIcon={<PersonAddAltIcon />}
          onClick={handleOpenShareModal}
          disabled={isSaving}
        />
      </Box>

      {/* ShareContractModal */}
      <ShareContractModal
        open={isShareModalOpen}
        onClose={handleCloseShareModal}
        onShare={handleShare}
        organizationName={currentUser?.organization_name}
      />

      {/* Success Alert Snackbar */}
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={6000}
        onClose={() => setShowSuccessAlert(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSuccessAlert(false)}
          severity="success"
          sx={{
            width: '100%',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '1.25rem'
            }
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Error Alert Snackbar */}
      <Snackbar
        open={showErrorAlert}
        autoHideDuration={6000}
        onClose={() => setShowErrorAlert(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowErrorAlert(false)}
          severity="error"
          sx={{
            width: '100%',
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '1.25rem'
            }
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Collaboration;