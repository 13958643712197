import React, { forwardRef, useState, useRef, useEffect } from 'react'
import {
  Box,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  ToggleButtonGroup,
  Tooltip,
  styled,
  ToggleButton,
  Typography,
  CircularProgress,
  ListItem,
  Paper
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import StopIcon from '@mui/icons-material/Stop'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  fetchAutocompleteResults,
  fetchAutocompleteResultsSuccess,
  fetchRecentSearches,
  resetAvailableDocTypes,
  searchCitations,
  searchRequest,
  searchSuccess,
  cancelSearch,
  setIsLoadingSearchCitations,
  setSearchQuery,
  setUpdateFromAutoSelect
} from '../../../redux/actions/searchAction'
import ReactGA from 'react-ga4'
import { debounce } from 'lodash'

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#f0f7ff',
  color: '#2c5282',
  transition: 'all 0.2s ease',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#3182ce',
    boxShadow: '0 2px 4px rgba(49, 130, 206, 0.3)'
  },
  padding: '8px 16px',
  fontWeight: 600,
  fontSize: '0.85rem',
  borderRadius: '8px !important',
  margin: '0 4px',
  border: '1px solid #e2e8f0',
  '&:hover': {
    backgroundColor: '#e6f0ff'
  }
}))

const CustomListboxComponent = forwardRef(function CustomListboxComponent(
  { isFetchingMore, children, ...rest },
  ref
) {
  return (
    <Paper
      {...rest}
      ref={ref}
      elevation={4}
      sx={{
        borderRadius: '8px',
        mt: 1,
        maxHeight: 300,
        overflow: 'auto'
      }}
    >
      {children}
      {isFetchingMore && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '12px'
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </Paper>
  )
})

export const SearchHeader = () => {
  const dispatch = useDispatch()
  const listboxRef = useRef()
  const searchQuery = useSelector(state => state.search.searchQuery)
  const isLoadingSearchCitations = useSelector(
    state => state.search.isLoadingSearchCitations
  )
  const autocompleteResults = useSelector(
    state => state.search.autocompleteResults,
    shallowEqual
  )

  const [offset, setOffset] = useState(0)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [localInputValue, setLocalInputValue] = useState('')
  const user = useSelector(state => state.auth.user?.user)
  const { t } = useTranslation()
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false)
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false)
  const [mode, setMode] = useState('loi')

  // Initialize local input with search query from store on first load
  useEffect(() => {
    if (searchQuery) {
      setLocalInputValue(searchQuery)
    }
  }, []) // Empty dependency array means this only runs once on mount

  // Remove duplicates from autocomplete results
  const seenTitles = new Set()
  const uniqueAutocompleteResults = autocompleteResults.filter(item => {
    if (seenTitles.has(item.title)) {
      return false // Skip duplicate
    }
    seenTitles.add(item.title)
    return true // Keep unique item
  })

  const handleScroll = event => {
    const { scrollTop, scrollHeight, clientHeight } = event.target

    if (scrollTop + clientHeight >= scrollHeight && !isFetchingMore) {
      setIsFetchingMore(true)

      const newOffset = offset + 7
      const previousScrollTop = scrollTop

      dispatch(fetchRecentSearches(user.id, newOffset)).then(results => {
        if (results.length > 0) {
          setOffset(newOffset)
          if (listboxRef.current) {
            listboxRef.current.scrollTop = previousScrollTop
          }
        }
        setIsFetchingMore(false)
      })
    }
  }

  const handleSearch = async () => {
    // Use localInputValue for search to ensure current input is used
    const queryToSearch = localInputValue.trim() || searchQuery.trim()
    
    if (queryToSearch) {
      // Update the Redux store with the current query
      dispatch(setSearchQuery(queryToSearch))
      
      // Keep the local input value in sync with what was searched
      setLocalInputValue(queryToSearch)
      
      dispatch(resetAvailableDocTypes())
      await dispatch(setIsLoadingSearchCitations(true))
      await dispatch(searchRequest())
      await dispatch(searchCitations(queryToSearch, mode))

      ReactGA.event({
        category: 'Search',
        action: 'User searched for an element',
        label: queryToSearch,
        value: mode
      })
      setOffset(0)
    }
  }
  
  const handleCancelSearch = () => {
    dispatch(cancelSearch())
    
    ReactGA.event({
      category: 'Search',
      action: 'User cancelled search',
      label: searchQuery
    })
  }

  const debouncedHandleSearchInputChange = debounce(async value => {
    if (value) {
      setIsAutocompleteLoading(true)
      await dispatch(fetchAutocompleteResults(value))
      setIsAutocompleteLoading(false)
    }
  }, 1200)

  const handleSearchInputChange = (event, value, reason) => {
    // Always update local input value
    setLocalInputValue(value)
    
    if (reason !== 'createOption' && reason !== 'reset') {
      if (reason === 'clear' || value === '') {
        dispatch(setSearchQuery(''))
      } else {
        dispatch(fetchAutocompleteResultsSuccess([]))
        debouncedHandleSearchInputChange(value)
        setOffset(0)
      }
    }
  }

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode)
    }
  }

  const handleAutocompleteSelect = async (event, value) => {
    if (value) {
      let selectedValue = ''
      
      if (typeof value === 'string') {
        selectedValue = value
      } else if (value && value.title) {
        selectedValue = value.title
      }
      
      // Update both local state and Redux store
      setLocalInputValue(selectedValue)
      dispatch(setSearchQuery(selectedValue))
      
      await dispatch(searchSuccess(value?.results))
      await dispatch(setUpdateFromAutoSelect(Math.random()))
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearch()
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2 }}>
        <Autocomplete
          key={'search-input'}
          freeSolo
          open={isAutocompleteOpen}
          onOpen={() => setAutocompleteOpen(true)}
          onClose={() => setAutocompleteOpen(false)}
          options={uniqueAutocompleteResults || []}
          getOptionLabel={option => {
            // Handle string options (from freeSolo) and object options
            return typeof option === 'string' ? option : (option.title || '')
          }}
          onInputChange={handleSearchInputChange}
          onChange={async (event, value) => {
            await handleAutocompleteSelect(event, value)
            setAutocompleteOpen(false)
          }}
          inputValue={localInputValue} // Control the input text with localInputValue
          renderInput={params => {
            // Custom render to ensure button order
            const searchButton = (
              <IconButton
                onClick={isLoadingSearchCitations ? handleCancelSearch : handleSearch}
                sx={{
                  color: isLoadingSearchCitations ? '#f56565' : '#3182ce',
                  '&:hover': {
                    backgroundColor: isLoadingSearchCitations
                      ? 'rgba(245, 101, 101, 0.08)'
                      : 'rgba(49, 130, 206, 0.08)'
                  },
                  ml: 0.5
                }}
              >
                {isLoadingSearchCitations ? <StopIcon /> : <SendIcon />}
              </IconButton>
            );

            return (
              <TextField
                {...params}
                placeholder={t('search_placeholder')}
                variant='outlined'
                fullWidth
                sx={{
                  input: {
                    fontSize: '16px',
                    padding: '14px 14px 14px 0'
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                    transition: 'box-shadow 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    },
                    '&.Mui-focused': {
                      boxShadow: '0 4px 12px rgba(49, 130, 206, 0.15)'
                    }
                  }
                }}
                onKeyDown={handleKeyDown}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start' sx={{ ml: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {isAutocompleteLoading ? (
                          <CircularProgress size={22} sx={{ color: '#3182ce' }} />
                        ) : (
                          <SearchIcon sx={{ color: '#718096', fontSize: 22 }} />
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                      {searchButton}
                    </React.Fragment>
                  )
                }}
              />
            );
          }}
          ListboxComponent={CustomListboxComponent}
          ListboxProps={{
            onScroll: handleScroll,
            isFetchingMore: isFetchingMore,
            ref: listboxRef
          }}
          renderOption={(props, option) => {
            const { key, ...rest } = props
            return (
              <ListItem
                key={option?.id}
                {...rest}
                sx={{
                  borderBottom: '1px solid #f0f0f0',
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: '#f7fafc'
                  }
                }}
              >
                <Box sx={{ width: '90%' }}>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 600,
                      fontSize: '0.9rem',
                      color: '#2d3748',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {option.title}
                  </Typography>
                </Box>
              </ListItem>
            )
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 1
        }}
      >
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="Search Mode"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            '& .MuiToggleButtonGroup-grouped': {
              border: 0
            }
          }}
        >
          <Tooltip title={t('search_type_regulation')} arrow>
            <StyledToggleButton value='loi'>
              {t('mode_reg')}
            </StyledToggleButton>
          </Tooltip>
          <Tooltip title={t('search_type_jurisprudence')} arrow>
            <StyledToggleButton value='jp'>
              {t('mode_jp')}
            </StyledToggleButton>
          </Tooltip>
          <Tooltip title={t('search_type_both')} arrow>
            <StyledToggleButton value='loi_et_jp'>
              {t('mode_both')}
            </StyledToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}
export default SearchHeader