import React, { useEffect, useState, useCallback } from 'react'
import { List, Box, Typography, CircularProgress, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchCitation from './SearchCitation'
import {
  setFilteredCitations,
  setAvailableDocTypes
} from '../../../redux/actions/searchAction'
import { docTypes } from '../../../utils/utils'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const SearchCitations = ({ currentPage, itemsPerPage }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(state => state.search.error)
  const filteredCitations = useSelector(state => state.search.filteredCitations) // Ensure this is an array
  const isLoadingSearchCitations = useSelector(
    state => state.search.isLoadingSearchCitations,
    (prev, next) => prev === next
  );

  const updateFromAutoSelect = useSelector(state => state.search.updateFromAutoSelect)

  const citations = useSelector(state => state.search.citations || []) // Fallback to empty array if undefined
  const selectedDocTypes = useSelector(
    state => state.search.selectedDocTypes || []
  )

  const [paginatedCitations, setPaginatedCitations] = useState([])
  
  // Stable reference to the filtered citations array
  const citationsRef = React.useRef(citations)
  
  // Update the ref when citations change
  useEffect(() => {
    citationsRef.current = citations;
  }, [citations]);

  // Set paginated citations in useEffect
  useEffect(() => {
    // Ensure filteredCitations is an array, not undefined
    const filteredCitationsArray = Array.isArray(filteredCitations)
      ? filteredCitations
      : []
    
    // Paginate filtered citations
    const newPaginatedCitations = filteredCitationsArray.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    )
    setPaginatedCitations(newPaginatedCitations)
  }, [filteredCitations, currentPage, itemsPerPage])

  // Memoized version of the filtering function to avoid recreation on every render
  const filterCitations = useCallback(() => {
    if (!isLoadingSearchCitations && Array.isArray(citationsRef.current)) {
      // Filter citations based on selected document types
      const filteredCitationsArray = citationsRef.current.filter(citation => {
        if (selectedDocTypes.length === 0) return true
        return selectedDocTypes.some(
          docType =>
            docType.label.toLowerCase() ===
            citation.metadata?.doc_type?.toLowerCase()
        )
      })

      // Dispatch array directly to the store
      dispatch(setFilteredCitations(filteredCitationsArray))

      if (citationsRef.current.length > 0) {
        // Handle available document types
        const citationDocTypes = new Set(
          citationsRef.current.map(citation => citation.metadata?.doc_type).filter(Boolean)
        )

        const filteredDocTypes = docTypes.filter(docType =>
          citationDocTypes.has(docType.label)
        )
        const finalDocTypes = filteredDocTypes.filter(
          docType =>
            !selectedDocTypes.some(selected => selected.label === docType.label)
        )

        dispatch(setAvailableDocTypes(finalDocTypes))
      }
    }
  }, [selectedDocTypes, dispatch, isLoadingSearchCitations])
  
  // Run the filter when dependencies change
  useEffect(() => {
    filterCitations();
  }, [filterCitations, updateFromAutoSelect, isLoadingSearchCitations])

  if (isLoadingSearchCitations) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 4
        }}
      >
        <CircularProgress size={40} thickness={4} sx={{ color: '#3182ce', mb: 2 }} />
        <Typography variant="body2" color="#4a5568" fontWeight={500}>
          {t('searching_documents')}
        </Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 4
        }}
      >
        <ErrorOutlineOutlinedIcon sx={{ color: '#e53e3e', fontSize: '2.5rem', mb: 2 }} />
        <Typography align="center" color="#4a5568">
          {t('loading_error')}
        </Typography>
      </Box>
    )
  }

  if (!citations.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 4
        }}
      >
        <DocumentScannerOutlinedIcon sx={{ color: '#a0aec0', fontSize: '3rem', mb: 2 }} />
        <Typography align="center" color="#718096" fontWeight={500}>
          {t('search_to_find_documents')}
        </Typography>
      </Box>
    )
  }

  if (paginatedCitations?.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 4
        }}
      >
        <SearchOffOutlinedIcon sx={{ color: '#a0aec0', fontSize: '3rem', mb: 2 }} />
        <Typography align="center" color="#718096" fontWeight={500}>
          {t('no_results')}
        </Typography>
        {selectedDocTypes.length > 0 && (
          <Typography variant="body2" align="center" color="#718096" mt={1}>
            {t('try_removing_filters')}
          </Typography>
        )}
      </Box>
    )
  }

  return (
    <List 
      sx={{ 
        overflowY: 'auto', 
        flexGrow: 1,
        p: 0
      }}
    >
      {paginatedCitations?.map((citation, index) => (
        <React.Fragment key={citation.id || index}>
          <SearchCitation citation={citation} />
          {index < paginatedCitations.length - 1 && (
            <Divider sx={{ opacity: 0.6 }} />
          )}
        </React.Fragment>
      ))}
    </List>
  )
}

export default SearchCitations