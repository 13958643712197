import React from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  CircularProgress,
  ToggleButtonGroup,
  Typography,
  Paper,
  Divider,
  Fade
} from '@mui/material';
import {
  Search as SearchIcon,
  GetApp as GetAppIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Description as DescriptionIcon,
  Email as EmailIcon
} from '@mui/icons-material';
import BackButton from '../../../../components/BackButton';
import { StyledToggleButton } from './StyledToggleButton';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../utils/utils';
import { fontSize } from '../../../../utils/fonts';

const ChatHeader = ({
  currentLang,
  mode,
  handleModeChange,
  handleClick,
  open,
  anchorEl,
  handleClose,
  isExporting,
  handleExport,
  hasCheckedMessages,
  user_email,
  handleTextSearchChange,
  t,
}) => {
  return (
    <Box
      sx={{
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        dir: currentLang === 'ar' ? 'rtl' : 'ltr'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <BackButton navigate_to={'/conversation'} />

        {!isMobile() && (
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: 600,
                color: '#000000',
                textAlign: currentLang === 'ar' ? 'right' : 'left'
              }}
            >
              {t("conversation")}
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" alignItems="center">
        <Fade in={true} timeout={600}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: currentLang === 'ar' ? 'flex-start' : 'flex-end',
              mr: 2
            }}
          >
            <ToggleButtonGroup
              value={mode}
              exclusive
              onChange={handleModeChange}
              aria-label='Mode selection'
              size="small"
              sx={{
                '& .MuiToggleButtonGroup-grouped': {
                  border: '1px solid #e0e0e0',
                  marginRight: '6px',
                  '&:not(:first-of-type)': {
                    borderLeft: currentLang === 'ar' ? '1px solid #e0e0e0' : 0,
                    borderRight: currentLang === 'ar' ? 0 : '1px solid #e0e0e0',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#000000',
                    color: 'white'
                  }
                }
              }}
            >
              <Tooltip
                title='Mode Réglementation: Recherches limitées aux textes réglementaires.'
                arrow
                placement="bottom"
              >
                <StyledToggleButton
                  sx={{ 
                    fontSize: fontSize.xsmall, 
                    fontWeight: 500,
                    textTransform: 'none',
                    px: 2,
                    py: 0.5,
                    color: '#000000'
                  }}
                  value='loi'
                >
                  {t('mode_reg')}
                </StyledToggleButton>
              </Tooltip>
              <Tooltip
                title='Mode Jurisprudence: Recherches limitées aux jurisprudences.'
                arrow
                placement="bottom"
              >
                <StyledToggleButton
                  sx={{
                    fontSize: fontSize.xsmall,
                    fontWeight: 500,
                    textTransform: 'none',
                    px: 2,
                    py: 0.5,
                    color: '#000000',
                    marginLeft: '6px',
                    marginRight: '6px'
                  }}
                  value='jp'
                >
                  {t('mode_jp')}
                </StyledToggleButton>
              </Tooltip>
              <Tooltip
                title='Mode Mixte: Recherches combinant textes réglmentaires et jurisprudences.'
                arrow
                placement="bottom"
              >
                <StyledToggleButton
                  sx={{
                    fontSize: fontSize.xsmall,
                    fontWeight: 500,
                    textTransform: 'none',
                    px: 2,
                    py: 0.5,
                    color: '#000000'
                  }}
                  value='loi_et_jp'
                >
                  {t('mode_both')}
                </StyledToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
        </Fade>

        <Tooltip title='Exporter' placement='bottom'>
          <span>
            <IconButton
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              color='default'
              disabled={!hasCheckedMessages}
              sx={{
                backgroundColor: hasCheckedMessages ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
                '&:hover': {
                  backgroundColor: hasCheckedMessages ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
                }
              }}
            >
              <GetAppIcon />
            </IconButton>
          </span>
        </Tooltip>
        
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
          PaperProps={{
            elevation: 2,
            sx: {
              borderRadius: 2,
              minWidth: 180
            }
          }}
        >
          {isExporting ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              p={2}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            [
              <MenuItem 
                key='pdf' 
                onClick={() => handleExport('pdf')}
                sx={{
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  }
                }}
              >
                <PictureAsPdfIcon sx={{ mr: 2, color: '#e53935' }} /> 
                <Typography variant="body2">{t('export_pdf')}</Typography>
              </MenuItem>,
              <MenuItem 
                key='docx' 
                onClick={() => handleExport('docx')}
                sx={{
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  }
                }}
              >
                <DescriptionIcon sx={{ mr: 2, color: '#1565c0' }} /> 
                <Typography variant="body2">{t('export_docx')}</Typography>
              </MenuItem>,
              <MenuItem 
                key='email' 
                onClick={() => handleExport('email')}
                sx={{
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  }
                }}
              >
                <EmailIcon sx={{ mr: 2, color: '#43a047' }} /> 
                <Typography variant="body2">{t('export_email')}</Typography>
              </MenuItem>
            ]
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default ChatHeader;