import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Avatar,
  useTheme,
  alpha,
  Skeleton
} from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from "@nivo/bar"
import { ResponsivePie } from "@nivo/pie"
import { useSelector } from 'react-redux'
import { addMonths, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { styles } from '../../../utils/styles'
import { getStatusKey, windowWidth } from '../../../utils/utils'
import { backendClient } from '../../../api/backend'
// Import icons
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import TimelineIcon from '@mui/icons-material/Timeline'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import DonutSmallIcon from '@mui/icons-material/DonutSmall'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'

const ContractCharts = () => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const theme = useTheme()

  // Custom colors - you can adjust these or use your theme colors
  const customColors = {
    primary: '#3a86ff',
    secondary: '#ff006e',
    success: '#38b000',
    danger: '#e63946', // Added explicit danger color for negative growth
    info: '#3f8efc',
    warning: '#ffbe0b',
    chartColors: ['#3a86ff', '#ff006e', '#8338ec', '#fb5607', '#ffbe0b']
  }



  const user = useSelector(state => state.auth.user?.user)
  const [totalContracts, setTotalContracts] = useState(0)
  const [contractsOverTime, setContractsOverTime] = useState([])
  const [contractsByStatus, setContractsByStatus] = useState([])
  const [contractsByType, setContractsByType] = useState([])
  const [monthlyGrowth, setMonthlyGrowth] = useState({ value: 0, percentage: '0%', isPositive: true })
  const [loading, setLoading] = useState(true)

  console.log('contractsByStatus', contractsByStatus)

  const contractStatusData = contractsByStatus.length
    ? contractsByStatus
    : [
      { status: t('draft') || 'Draft', count: 0, color: customColors.chartColors[0] },
      { status: t('in_review') || 'In Review', count: 0, color: customColors.chartColors[1] },
      { status: t('pending_signature') || 'Pending Signature', count: 0, color: customColors.chartColors[2] },
      { status: t('signed') || 'Signed', count: 0, color: customColors.chartColors[3] }
    ];

  // Helper function to generate the last 4 months with 0 contracts as defaults
  const getLastFourMonths = () => {
    const currentDate = new Date()
    const months = []

    for (let i = 0; i < 4; i++) {
      const monthDate = addMonths(currentDate, -i)
      months.unshift({ x: format(monthDate, 'MMM'), y: 0 })
    }

    return months
  }

  // Combine the fetched data with default months if necessary
  const ensureFourMonths = fetchedData => {
    const lastFourMonths = getLastFourMonths()

    const filledData = [...lastFourMonths]

    fetchedData.forEach(item => {
      const index = filledData.findIndex(month => month.x === item.x)
      if (index !== -1) {
        filledData[index].y = item.y // Replace 0 with actual data if available
      }
    })

    return filledData
  }

  // Function to calculate the monthly growth
  const calculateMonthlyGrowth = (data) => {

    if (!data?.length || !data[0]?.data?.length) {
      return { value: 0, percentage: '+0%', isPositive: true };
    }

    const contractData = data[0].data;

    // We need at least two months of data to calculate growth
    if (contractData.length < 2) {
      return { value: 0, percentage: '+0%', isPositive: true };
    }

    // Get the current and previous month values
    const currentMonth = contractData[contractData.length - 1].y;
    const previousMonth = contractData[contractData.length - 2].y;

    // Calculate the growth
    const growthValue = currentMonth - previousMonth;

    // Handle special cases for growth calculation
    let growthPercentage = 0;
    let growthText = '';
    let isPositive = true;

    // Case 1: Previous month is 0
    if (previousMonth === 0) {
      if (currentMonth > 0) {
        // If we went from 0 to something, that's "New" or "∞" growth
        growthText = currentMonth === 0 ? '+0%' : 'New';
        isPositive = true;
      } else {
        // Both months are 0, no change
        growthText = '+0%';
        isPositive = true;
      }
    }
    // Case 2: Normal calculation when previous month is not 0
    else {
      growthPercentage = (growthValue / previousMonth) * 100;
      growthText = `${growthPercentage > 0 ? '+' : ''}${growthPercentage.toFixed(1)}%`;
      isPositive = growthPercentage >= 0;
    }

    return {
      value: growthValue,
      percentage: growthText,
      isPositive: isPositive
    };
  };

  // Chart theme for consistent styling
  const chartTheme = {
    axis: {
      domain: {
        line: {
          stroke: theme.palette.text.secondary,
          strokeWidth: 1
        }
      },
      ticks: {
        line: {
          stroke: theme.palette.text.secondary,
          strokeWidth: 1
        },
        text: {
          fill: theme.palette.text.secondary,
          fontSize: 12
        }
      },
      legend: {
        text: {
          fill: theme.palette.text.primary,
          fontSize: 13,
          fontWeight: 500
        }
      }
    },
    grid: {
      line: {
        stroke: alpha(theme.palette.divider, 0.3),
        strokeWidth: 1
      }
    },
    tooltip: {
      container: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 12,
        borderRadius: 4,
        boxShadow: theme.shadows[3],
        padding: 8
      }
    },
    labels: {
      text: {
        fontSize: 12,
        fill: theme.palette.text.primary
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        if (!user?.id) return;

        // Get total contracts count
        const contractsCountResponse = await backendClient.getContractsCountUniqueByUser(user.id);
        setTotalContracts(contractsCountResponse.totalContracts || 0);

        // Get contracts over time
        try {
          const contractsOverTimeData = await backendClient.getContractsOverTime(user.id);

          if (contractsOverTimeData && Array.isArray(contractsOverTimeData)) {
            // Ensure the data contains at least 4 months
            const filledData = ensureFourMonths(contractsOverTimeData[0].data);
            const formattedData = [{ id: 'contracts', data: filledData }];
            setContractsOverTime(formattedData);

            // Calculate monthly growth based on the data
            setMonthlyGrowth(calculateMonthlyGrowth(formattedData));
          }
        } catch (error) {
          console.error('Error fetching contracts over time:', error);
          // Fallback to mock data if API fails
          const mockContractsOverTime = [
            { x: format(addMonths(new Date(), -3), 'MMM'), y: 12 },
            { x: format(addMonths(new Date(), -2), 'MMM'), y: 18 },
            { x: format(addMonths(new Date(), -1), 'MMM'), y: 24 },
            { x: format(new Date(), 'MMM'), y: 30 }
          ];
          const formattedData = [{ id: 'contracts', data: mockContractsOverTime }];
          setContractsOverTime(formattedData);

          // Calculate monthly growth based on mock data
          setMonthlyGrowth(calculateMonthlyGrowth(formattedData));
        }

        // Get contracts by status
        try {
          const statusData = await backendClient.getContractsByStatus(user.id);
          if (statusData && Array.isArray(statusData)) {
            setContractsByStatus(statusData);
          }
        } catch (error) {
          console.error('Error fetching contracts by status:', error);
          // Fallback to mock data if API fails
          const mockContractsByStatus = [
            { status: t('draft'), count: 15, color: customColors.chartColors[0] },
            { status: t('in_review'), count: 8, color: customColors.chartColors[1] },
            { status: t('pending_signature'), count: 5, color: customColors.chartColors[2] },
            { status: t('signed'), count: 12, color: customColors.chartColors[3] }
          ];
          setContractsByStatus(mockContractsByStatus);
        }

        // Get contracts by type
        try {
          const typeData = await backendClient.getContractsByType(user.id);
          if (typeData && Array.isArray(typeData)) {
            setContractsByType(typeData);
          }
        } catch (error) {
          console.error('Error fetching contracts by type:', error);
          // Fallback to mock data if API fails
          const mockContractsByType = [
            { type: t('employment'), count: 10, color: customColors.chartColors[0] },
            { type: t('nda'), count: 12, color: customColors.chartColors[1] },
            { type: t('sales'), count: 8, color: customColors.chartColors[2] },
            { type: t('lease'), count: 5, color: customColors.chartColors[3] },
            { type: t('service'), count: 5, color: customColors.chartColors[4] }
          ];
          setContractsByType(mockContractsByType);
        }
      } catch (error) {
        console.error('Error fetching contract data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [user?.id, t])

  const dataContracts = contractsOverTime.length
    ? contractsOverTime
    : [
      {
        id: 'contracts',
        data: getLastFourMonths()
      }
    ]

  const calculateDynamicMargin = (data, rotationAngle = -45) => {
    // Find the longest label
    const longestLabel = data.reduce((longest, item) => {
      return item.type.length > longest.length ? item.type : longest;
    }, '');

    // Approximate character width in pixels (this can be adjusted based on your font)
    const charWidth = 8;

    // Calculate the projected width of the rotated text
    // For 45 degree rotation, the height will be approximately sin(45) * textWidth
    const rotationRad = Math.abs(rotationAngle) * Math.PI / 180;
    const textWidth = longestLabel.length * charWidth;
    const projectedHeight = Math.sin(rotationRad) * textWidth;

    // Add some padding (25px) for comfort
    const bottomMargin = Math.ceil(projectedHeight) + 25;

    console.log('bottomMargin', bottomMargin)

    // Return the dynamic margin object with minimum of 50px for bottom
    return {
      top: 20,
      right: 20,
      bottom: Math.max(bottomMargin/4, 50),
      left: 60
    };
  };

  // Transform contracts by status for pie chart
  const pieData = contractStatusData.map(item => ({
    id: item.status,
    label: getStatusKey(item.status),
    value: item.count,
    color: item.color || customColors.chartColors[Math.floor(Math.random() * customColors.chartColors.length)]
  }));

  const contractTypeData = contractsByType.length
    ? contractsByType
    : [
      { type: t('employment') || 'Employment', count: 0, color: customColors.chartColors[0] },
      { type: t('nda') || 'NDA', count: 0, color: customColors.chartColors[1] },
      { type: t('sales') || 'Sales', count: 0, color: customColors.chartColors[2] },
      { type: t('lease') || 'Lease', count: 0, color: customColors.chartColors[3] },
      { type: t('service') || 'Service', count: 0, color: customColors.chartColors[4] }
    ];


  // Create loading skeleton
  const LoadingSkeleton = () => (
    <Box sx={{ width: '100%' }}>
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="40%" height={60} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={250} sx={{ borderRadius: 1 }} />
    </Box>
  )

  const MonthlyGrowthDisplay = () => {
    const growthColor = monthlyGrowth.isPositive ? customColors.success : customColors.danger;
    const growthIcon = monthlyGrowth.isPositive ? <TrendingUpIcon sx={{ ml: 0.5, fontSize: 24 }} /> : <TrendingDownIcon sx={{ ml: 0.5, fontSize: 24 }} />;

    return (
      <Box
        sx={{
          p: 2,
          borderRadius: 2,
          bgcolor: alpha(growthColor, 0.05),
          border: `1px dashed ${alpha(growthColor, 0.3)}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: growthColor,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {monthlyGrowth.percentage}
            {growthIcon}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {t('monthly_growth')} <span style={{ fontStyle: 'italic' }}>vs last month</span>
        </Typography>
      </Box>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* Contracts Over Time */}
      <Grid item xs={12} md={6}>
        <Card
          elevation={0}
          sx={{
            height: '100%',
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
            border: `1px solid ${theme.palette.divider}`,
            background: 'white',
            overflow: 'hidden',
            direction: currentLang === 'ar' ? 'rtl' : 'ltr'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            <Box sx={{
              p: 3,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Avatar
                sx={{
                  bgcolor: alpha(customColors.primary, 0.1),
                  color: customColors.primary,
                  mr: 2
                }}
              >
                <TimelineIcon />
              </Avatar>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t('contracts_over_time')}
              </Typography>
            </Box>

            {loading ? (
              <Box sx={{ p: 3 }}>
                <LoadingSkeleton />
              </Box>
            ) : (
              <>
                <Box sx={{ px: 3 }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          p: 2,
                          borderRadius: 2,
                          bgcolor: alpha(customColors.primary, 0.05),
                          border: `1px dashed ${alpha(customColors.primary, 0.3)}`,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{ fontWeight: 700, color: customColors.primary }}
                        >
                          {totalContracts}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {t('total_contracts')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MonthlyGrowthDisplay />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ height: 280, p: 1 }}>
                  <ResponsiveLine
                    data={dataContracts}
                    theme={chartTheme}
                    margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                      type: 'linear',
                      min: 0,
                      max: totalContracts + 10,
                      stacked: false
                    }}
                    curve="cardinal"
                    enableArea={true}
                    areaOpacity={0.1}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: t('tooltip_month'),
                      legendOffset: 45,
                      legendPosition: 'middle'
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: t('contracts'),
                      legendOffset: -50,
                      legendPosition: 'middle'
                    }}
                    colors={[customColors.primary]}
                    lineWidth={3}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    gridYValues={5}
                    enableGridX={false}
                    enableSlices="x"
                    sliceTooltip={({ slice }) => (
                      <div
                        style={{
                          background: 'white',
                          padding: '12px',
                          borderRadius: '8px',
                          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                          border: `1px solid ${theme.palette.divider}`
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          {slice.points[0].data.x}
                        </Typography>
                        {slice.points.map(point => (
                          <Box key={point.id} sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                bgcolor: point.serieColor,
                                mr: 1
                              }}
                            />
                            <Typography variant="body2">
                              {t('contracts')}: <strong>{point.data.yFormatted}</strong>
                            </Typography>
                          </Box>
                        ))}
                      </div>
                    )}
                  />
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Contracts By Status */}
      <Grid item xs={12} md={6}>
        <Card
          elevation={0}
          sx={{
            height: '100%',
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
            border: `1px solid ${theme.palette.divider}`,
            background: 'white',
            overflow: 'hidden',
            direction: currentLang === 'ar' ? 'rtl' : 'ltr'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            <Box sx={{
              p: 3,
              display: 'flex',
              alignItems: 'center',
            }}>
              <Avatar
                sx={{
                  bgcolor: alpha(customColors.secondary, 0.1),
                  color: customColors.secondary,
                  mr: 2
                }}
              >
                <DonutSmallIcon />
              </Avatar>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t('contracts_by_status')}
              </Typography>
            </Box>

            {loading ? (
              <Box sx={{ p: 3 }}>
                <LoadingSkeleton />
              </Box>
            ) : (
              <Box sx={{ height: 380, px: 3 }}>
                <ResponsivePie
                  data={pieData}
                  theme={chartTheme}
                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor={theme.palette.text.secondary}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: theme.palette.text.secondary,
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: theme.palette.text.primary
                          }
                        }
                      ]
                    }
                  ]}
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Contracts By Type - remaining unchanged */}
      <Grid item xs={12}>
        <Card
          elevation={0}
          sx={{
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
            border: `1px solid ${theme.palette.divider}`,
            background: 'white',
            overflow: 'visible', // Change from overflow: 'hidden'
            direction: currentLang === 'ar' ? 'rtl' : 'ltr'
          }}
        >
          {/* Card content remains unchanged */}
          <CardContent sx={{ p: 0 }}>
            <Box sx={{
              p: 3,
              display: 'flex',
              alignItems: 'center',

            }}>
              <Avatar
                sx={{
                  bgcolor: alpha(customColors.info, 0.1),
                  color: customColors.info,
                  mr: 2
                }}
              >
                <PieChartOutlineIcon />
              </Avatar>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t('contracts_by_type')}
              </Typography>
            </Box>

            {loading ? (
              <Box sx={{ p: 3 }}>
                <LoadingSkeleton />
              </Box>
            ) : (
              <Box sx={{
                height: 420, // minimum height
                px: 3,
              }}>
                <ResponsiveBar
                  data={contractTypeData}
                  keys={['count']}
                  indexBy="type"
                  theme={chartTheme}
                  margin={calculateDynamicMargin(contractTypeData, -45)}

                  padding={0.4}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={customColors.chartColors}
                  borderRadius={4}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: t('type'),
                    legendPosition: 'middle',
                    legendOffset: 40
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: t('count'),
                    legendPosition: 'middle',
                    legendOffset: -50
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  enableGridY={true}
                  enableLabel={false}
                  tooltip={({ data, color }) => (
                    <div
                      style={{
                        background: 'white',
                        padding: '12px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                        border: `1px solid ${theme.palette.divider}`
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            display: 'block',
                            width: '12px',
                            height: '12px',
                            backgroundColor: color,
                            borderRadius: '50%',
                            marginRight: '8px'
                          }}
                        />
                        <Typography variant="body2">
                          <strong>{data.type}</strong>: {data.count} {t('contracts')}
                        </Typography>
                      </div>
                    </div>
                  )}
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ContractCharts