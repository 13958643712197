// components/PromoCodeInput.jsx
import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    CircularProgress,
    Alert
} from '@mui/material';
import DiscountIcon from '@mui/icons-material/Discount';
import { colors } from '../../../utils/colors';
import CustomButton from '../../../components/CustomButton';

const PromoCodeInput = ({ onApply, isMobileView }) => {
    const [promoCode, setPromoCode] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [validationResult, setValidationResult] = useState(null);

    const handleApplyCode = async () => {
        if (!promoCode) return;

        setIsValidating(true);
        setValidationResult(null);

        try {
            // Call the backend to validate the code
            const result = await onApply(promoCode);
            setValidationResult(result);
        } catch (error) {
            setValidationResult({
                valid: false,
                message: error.message || "Error validating promo code"
            });
        } finally {
            setIsValidating(false);
        }
    };

    return (
        <Box
            sx={{
                mb: 3,
                p: 2,
                borderRadius: '12px',
                backgroundColor: 'rgba(0,0,0,0.02)',
                border: '1px dashed rgba(0,0,0,0.1)'
            }}
        >
            <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1.5
                }}
            >
                <DiscountIcon color="primary" />
                Vous avez un code promo ?
            </Typography>

            <Box display="flex" alignItems="center" gap={1}>
                <TextField
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value.trim())}
                    variant="outlined"
                    size="small"
                    placeholder="Entrez votre code"
                    fullWidth
                    disabled={isValidating}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                            },
                        }
                    }}
                />
                <CustomButton
                    text="Appliquer"
                    // variant="contained"
                    // color="primary"
                    loding={isValidating}
                    onClick={handleApplyCode}
                    disabled={!promoCode || isValidating}
                    sx={{
                        borderRadius: '10px',
                        minWidth: '100px',
                        height: '40px'
                    }}
                />
            </Box>

            {validationResult && (
                <Box mt={2}>
                    <Alert
                        severity={validationResult.valid ? "success" : "error"}
                        variant="filled"
                        sx={{ borderRadius: '10px' }}
                    >
                        {validationResult.message}
                    </Alert>

                    {validationResult.valid && validationResult.discount_amount > 0 && (
                        <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                            Réduction appliquée : {validationResult.discount_amount} MAD
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PromoCodeInput;