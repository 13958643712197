// ai-extension.js - Improved version with better positioning
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

// Create a plugin key that can be exported and used elsewhere
export const AiExtensionPluginKey = new PluginKey('aiExtension');

export const AiExtension = Extension.create({
  name: 'aiExtension',

  addOptions() {
    return {
      onAiRequest: () => { },
    };
  },

  addCommands() {
    return {
      toggleAiInterface: () => ({ state, dispatch, view }) => {
        if (!dispatch) return true;

        // Get current plugin state
        const pluginState = this.pluginKey.getState(state);
        
        // Get current selection and its coordinates
        const { from, to } = state.selection;
        const isCollapsed = from === to;

        // Calculate position for the AI interface
        let position;
        
        if (isCollapsed) {
          // If cursor is collapsed, use its position
          position = from;
        } else {
          // If text is selected, use the end of selection
          position = to;
        }

        // If view is available, get DOM coordinates
        let coords = null;
        if (view) {
          const domPos = view.coordsAtPos(position);
          coords = {
            left: domPos.left,
            top: domPos.bottom
          };
        }

        // Toggle AI active state with coordinates
        const transaction = state.tr.setMeta(this.pluginKey, {
          type: 'SET_AI_ACTIVE',
          value: !pluginState.isAiActive,
          position: position,
          coords: coords
        });

        dispatch(transaction);
        return true;
      },
    };
  },

  addProseMirrorPlugins() {
    const { onAiRequest } = this.options;
    const pluginKey = AiExtensionPluginKey;

    // Store the plugin key on the extension instance
    this.pluginKey = pluginKey;

    return [
      new Plugin({
        key: pluginKey,
        state: {
          init() {
            return {
              isAiActive: false,
              aiPosition: null,
              coords: null
            };
          },
          apply(tr, prev) {
            const meta = tr.getMeta(pluginKey);

            if (meta === undefined) {
              return prev;
            }

            if (meta.type === 'SET_AI_ACTIVE') {
              return {
                ...prev,
                isAiActive: meta.value,
                aiPosition: meta.position || prev.aiPosition,
                coords: meta.coords || prev.coords
              };
            }

            return prev;
          },
        },
        props: {
          decorations(state) {
            const pluginState = this.getState(state);

            if (!pluginState || !pluginState.isAiActive) {
              return null;
            }

            // Create a decoration for the AI bubble
            return DecorationSet.create(state.doc, [
              Decoration.widget(pluginState.aiPosition, () => {
                const marker = document.createElement('span');
                marker.setAttribute('data-ai-marker', 'true');
                
                // Add position data attributes for better positioning
                if (pluginState.coords) {
                  marker.setAttribute('data-pos-left', pluginState.coords.left);
                  marker.setAttribute('data-pos-top', pluginState.coords.top);
                }
                
                return marker;
              }, { side: 1 }), // Use side: 1 to position after the cursor
            ]);
          },
        },
      }),
    ];
  },
});

export const AiKeyboardShortcut = Extension.create({
  name: 'aiKeyboardShortcut',

  addKeyboardShortcuts() {
    return {
      // Trigger AI with Command/Ctrl + /
      'Mod-/': () => {
        // Use the editor's builtin commands
        this.editor.commands.toggleAiInterface();
        return true;
      },
    };
  },
});