import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEditor, EditorContent } from '@tiptap/react';
import * as Y from 'yjs';
import { io } from "socket.io-client";
import { Awareness } from 'y-protocols/awareness';

// Tiptap extensions
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Highlight from '@tiptap/extension-highlight';
import Dropcursor from '@tiptap/extension-dropcursor'

import Collaboration from '@tiptap/extension-collaboration';
import { Snackbar, Alert } from "@mui/material";

// Custom collaboration cursor extension
import { CustomCollaborationCursor } from './extensions/CustomCollaborationCursor';
import { TabKeyHandler } from './extensions/TabKeyHandler';
import { TextSelectionBubble } from './extensions/TextSelectionBubble';


import { RichTable } from './extensions/CustomTable';
import { ResizableImage } from './extensions/CustomImage';

import { TextColor } from './extensions/CustomColor';
import { TypographyExtension } from './extensions/CustomFont';
import TextStyle from '@tiptap/extension-text-style'

// MUI Components
import {
  Box,
  CircularProgress,
  Chip,
  Avatar,
  Tooltip,
  Typography,
  Button
} from "@mui/material";

// Project imports
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import "./Editor.css";
import { backendUrl } from '../../../config';
import BackButton from '../../../components/BackButton';
import { backendClient } from "../../../api/backend";
import { RiskHighlight } from "./extensions/CustomHighlight";
import { AiExtension, AiKeyboardShortcut } from "./extensions/AiExtension";
import { useTranslation } from "react-i18next";

// Random color generation
const colors = [
  '#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8',
  '#94FADB', '#B9F18D', '#C3E2C2', '#EAECCC', '#AFC8AD',
  '#EEC759', '#9BB8CD', '#FF90BC', '#FFC0D9', '#DC8686',
  '#7ED7C1', '#F3EEEA', '#89B9AD', '#D0BFFF', '#FFF8C9'
];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

// Default content for new contracts
const defaultContent = `
  <h1>Empty Contract</h1>
  <p>This is a collaborative contract document.</p>
  <p>Feel free to edit and collaborate in real-time!</p>
`;

/**
 * SocketIOProvider - A custom Y.js provider using Socket.IO
 * This provider connects the Y.js document with Socket.IO for real-time collaboration
 */
class SocketIOProvider {
  constructor(socket, roomName, doc) {
    this.socket = socket;
    this.roomName = roomName;
    this.doc = doc;
    this.awareness = new Awareness(doc);
    this.status = 'connecting';
    this.callbacks = {
      'status': [],
      'synced': [],
      'update': []
    };
    this.synced = false;
    this.initialize();
  }

  get connected() {
    return this.status === 'connected';
  }

  getDocument() {
    return this.doc;
  }

  /**
   * Listen for events
   * @param {string} name - Event name
   * @param {Function} callback - Event callback
   */
  on(name, callback) {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    if (name === 'update') {
      // For collaboration cursor updates, format data in expected structure
      const wrappedCallback = () => {
        // Get all awareness states and format them for the CustomCollaborationCursor
        const states = this._getAwarenessStates();

        // Call the original callback with properly formatted data
        callback({
          states,
          clientID: this.doc.clientID
        });
      };

      this.callbacks[name].push(wrappedCallback);

      // Initial call for existing cursors - IMPORTANT!
      setTimeout(wrappedCallback, 0);
    } else {
      this.callbacks[name].push(callback);
    }

    return this;
  }

  /**
   * Remove event listener
   * @param {string} event - Event name
   * @param {Function} callback - Event callback to remove
   */
  off(event, callback) {
    if (this.callbacks[event]) {
      this.callbacks[event] = this.callbacks[event].filter(cb => cb !== callback);
    }
    return this;
  }

  /**
   * Trigger event
   * @param {string} event - Event name
   * @param {any} data - Event data
   */
  emit(event, data) {
    if (this.callbacks[event]) {
      this.callbacks[event].forEach(callback => {
        try {
          callback(data);
        } catch (err) {
          console.error(`Error in callback for event ${event}:`, err);
        }
      });
    }
  }

  /**
   * Get a consistent, unique ID for a user
   * @param {string} userId - The user's ID
   * @returns {number} - A numeric ID for the user
   */
  getConsistentUserId(userId) {
    if (!userId) return this.doc.clientID;

    // If it's already a number, use it
    if (typeof userId === 'number') return userId;

    // Extract numeric part if present
    const numericPart = userId.replace(/[^0-9]/g, '');
    if (numericPart && numericPart.length > 0) {
      return parseInt(numericPart, 10);
    }

    // Otherwise create a hash-like number
    let hash = 0;
    for (let i = 0; i < userId.length; i++) {
      const char = userId.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  /**
   * Get awareness states formatted for collaboration cursor
   */
  _getAwarenessStates() {
    const states = {};
    this.awareness.getStates().forEach((state, clientId) => {
      // Ensure the state has the required structure for y-prosemirror
      if (state && state.user) {
        states[clientId] = {
          name: state.user.name,
          color: state.user.color,
          id: state.user.id,
          // This format matches what y-prosemirror expects
          selection: state.selection
        };

        // Log for debugging - remove in production
        // console.log(`State for client ${clientId}:`, states[clientId]);
      }
    });
    return states;
  }

  /**
   * Clean up duplicate awareness states
   */
  cleanupDuplicateAwarenessStates() {
    try {
      // Get all states
      const states = this.awareness.getStates();
      const userIds = new Map();
      const duplicates = [];

      // Find duplicates (same user ID but different client ID)
      states.forEach((state, clientId) => {
        if (state.user && state.user.id) {
          const userId = state.user.id;
          if (userIds.has(userId)) {
            // This is a duplicate - keep the first one we found
            duplicates.push(clientId);
          } else {
            userIds.set(userId, clientId);
          }
        }
      });

      // Remove duplicates
      if (duplicates.length > 0) {
        duplicates.forEach(clientId => {
          this.awareness.states.delete(clientId);
        });

        // Directly trigger our provider's update callbacks instead of using awareness.emit
        this._triggerAwarenessCallbacks();
      }
    } catch (err) {
      console.error('Error in awareness cleanup:', err);
    }
  }

  _triggerAwarenessCallbacks() {
    if (this.callbacks['update']) {
      const states = this._getAwarenessStates();
      this.callbacks['update'].forEach(callback => {
        try {
          callback({
            added: [],
            updated: [],
            removed: [],
            states: states,
            clientID: this.doc.clientID
          });
        } catch (err) {
          console.error('Error in update callback:', err);
        }
      });
    }
  }

  /**
   * Initialize the provider and set up event listeners
   */
  initialize() {
    // Socket connection handling
    this.socket.on('connect', () => {
      this.setStatus('connected');

      // Emit client_ready event
      this.socket.emit('client_ready', {
        token: this.socket.auth.token,
        contract_id: this.roomName,
        color: this.socket.auth.color
      });
    });

    this.socket.on('disconnect', () => {
      this.setStatus('disconnected');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      this.setStatus('error');
    });

    // Document sync (initial state)
    this.socket.on('sync', (data) => {
      if (data && data.data && data.data.length > 0) {
        try {
          const updateArray = new Uint8Array(data.data);
          Y.applyUpdate(this.doc, updateArray);
        } catch (err) {
          console.error('Error applying initial sync update:', err);
        }
      }

      // Mark as synced regardless of outcome to prevent editor from hanging
      this.synced = true;
      this.emit('synced', {});
    });

    // Document updates from other users
    this.socket.on('update', (data) => {
      if (data && data.data) {
        try {
          const updateArray = new Uint8Array(data.data);
          Y.applyUpdate(this.doc, updateArray);
        } catch (err) {
          console.error('Error applying update:', err);
        }
      }
    });

    // Binary updates
    this.socket.on('binary_update', (binaryData) => {
      try {
        const updateArray = new Uint8Array(binaryData);
        Y.applyUpdate(this.doc, updateArray);
      } catch (err) {
        console.error('Error applying binary update:', err);
      }
    });

    // Awareness updates (cursor positions)
    this.socket.on('awareness', (data) => {
      if (data && data.data && data.user_id) {
        try {
          const userId = data.user_id;
          const clientId = data.client_id || this.getConsistentUserId(userId);

          // Create properly formatted state for awareness
          const newState = {
            user: {
              name: data.data.user?.name || 'Unknown',
              color: data.data.user?.color || '#cccccc',
              id: userId
            },
            // Selection must be a top-level property, not nested!
            selection: data.data.selection
          };

          // Skip updates from ourselves
          if (clientId !== this.doc.clientID) {
            this.awareness.states.set(clientId, newState);
            this._triggerAwarenessCallbacks();
          }
        } catch (err) {
          console.error('Error handling awareness update:', err);
        }
      }
    });

    // Handle user leaving events
    this.socket.on('user_left', (data) => {
      if (data && data.user_id) {
        try {
          const userId = data.user_id;
          // Find all client IDs that belong to this user
          const clientIdsToRemove = [];

          this.awareness.getStates().forEach((state, clientId) => {
            if (state.user && state.user.id === userId) {
              clientIdsToRemove.push(clientId);
            }
          });

          // Remove awareness states for all found client IDs
          if (clientIdsToRemove.length > 0) {
            this.removeAwarenessStates(clientIdsToRemove, this);
            console.log(`Removed awareness states for user ${userId}, client IDs:`, clientIdsToRemove);
          }
        } catch (err) {
          console.error('Error handling user_left event:', err);
        }
      }
    });

    // Also update the user_status event handler to clean up cursors for users that are no longer active
    this.socket.on('user_status', (data) => {
      if (data && Array.isArray(data.users)) {
        try {
          // Get current list of user IDs
          const currentUserIds = new Set(data.users.map(user => user.id));

          // Find all client IDs belonging to users no longer in the room
          const clientIdsToRemove = [];

          this.awareness.getStates().forEach((state, clientId) => {
            if (state.user && state.user.id && !currentUserIds.has(state.user.id)) {
              clientIdsToRemove.push(clientId);
            }
          });

          // Remove awareness states for users no longer present
          if (clientIdsToRemove.length > 0) {
            this.removeAwarenessStates(clientIdsToRemove, this);
            console.log('Removed awareness states for disconnected users:', clientIdsToRemove);
          }
        } catch (err) {
          console.error('Error cleaning up awareness in user_status:', err);
        }
      }
    });



    // Send document updates to server when local document changes
    this.doc.on('update', (update) => {
      try {
        const array = Array.from(update);
        this.socket.emit('update', { data: array });
      } catch (err) {
        console.error('Error sending update:', err);
      }
    });

    // Handle awareness updates
    this.awareness.on('update', ({ added, updated, removed }) => {
      try {
        const localClientId = this.doc.clientID;

        // Only broadcast our own changes
        if ((added && added.includes(localClientId)) || (updated && updated.includes(localClientId))) {
          const state = this.awareness.getLocalState();

          if (state && state.user) {
            // Send to server with selection as a separate field
            this.socket.emit('awareness', {
              data: {
                user: state.user,
                selection: state.selection
              },
              user_id: state.user.id || String(localClientId),
              client_id: localClientId
            });

            // Also trigger local update
            this._triggerAwarenessCallbacks();
          }
        }

        // Also trigger on removes
        if (removed && removed.length > 0) {
          this._triggerAwarenessCallbacks();
        }
      } catch (err) {
        console.error('Error sending awareness update:', err);
      }
    });
  }

  /**
 * Remove multiple states from awareness
 * @param {Array<number>} clientIds - Array of client IDs to remove
 * @param {*} origin - Origin of the change (usually 'this')
 */
  removeAwarenessStates(clientIds, origin) {
    try {
      if (!this.awareness || !Array.isArray(clientIds) || clientIds.length === 0) {
        return;
      }

      // Create map of which clients should be removed
      const removeClientIds = new Set(clientIds);

      // Get current states
      const states = this.awareness.getStates();

      // Create a map of states that should remain
      const newStates = new Map();
      states.forEach((state, clientId) => {
        if (!removeClientIds.has(clientId)) {
          newStates.set(clientId, state);
        }
      });

      // Clear all states
      this.awareness.states.clear();

      // Add back the ones we want to keep
      newStates.forEach((state, clientId) => {
        this.awareness.states.set(clientId, state);
      });

      // Manually emit the update event
      this.awareness.emit('update', {
        added: [],
        updated: [],
        removed: Array.from(removeClientIds),
      });

      // Notify our own listeners
      this._triggerAwarenessCallbacks();

      console.log(`Removed ${clientIds.length} awareness states`, clientIds);
    } catch (err) {
      console.error('Error removing awareness states:', err);
    }
  }

  /**
   * Set connection status
   * @param {string} status - Status value
   */
  setStatus(status) {
    this.status = status;
    this.emit('status', { status });
  }

  /**
   * Disconnect from the server
   */
  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  /**
   * Clean up resources
   */
  destroy() {
    this.disconnect();
    this.callbacks = {};
    if (this.awareness) {
      this.awareness.destroy();
    }
  }
}

/**
 * DocumentEditor - A collaborative rich text editor component
 */
const DocumentEditor = React.forwardRef((props, ref) => {
  const {t} = useTranslation()
  const { contractId } = useParams();
  const loading = useSelector(state => state.contract.loading);
  const contract = useSelector(state => state.contract.contract);
  const currentUser = useSelector(state => state.auth.user.user);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState('connecting');
  const [activeUsers, setActiveUsers] = useState([]);
  const [collaborationExtensions, setCollaborationExtensions] = useState([]);
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [selectedImageNode, setSelectedImageNode] = useState(null);


  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lastSaveTime, setLastSaveTime] = useState(Date.now());
  const autosaveTimeoutRef = useRef(null);


  const [currentUserData] = useState({
    name: currentUser?.first_name || currentUser?.email || 'User',
    color: getRandomColor(),
    id: currentUser?.id
  });

  const [notificationState, setNotificationState] = useState({
    open: false,
    message: '',
    severity: 'info' // 'success', 'info', 'warning', or 'error'
  });

  const ydocRef = useRef(null);
  const providerRef = useRef(null);
  const socketRef = useRef(null);
  const aiButtonRef = useRef(null);

  const lastSavedContent = useRef('');


  const handleCloseNotification = () => {
    setNotificationState({ ...notificationState, open: false });
  };

  const saveContract = () => {
    if (socketRef.current && editor && hasUnsavedChanges) {
      // Show saving notification
      setNotificationState({
        open: true,
        message: 'Saving document...',
        severity: 'info'
      });

      const content = editor.getHTML();
      socketRef.current.emit('save', { content });

      // Reset unsaved changes flag and update last save time
      setHasUnsavedChanges(false);
      setLastSaveTime(Date.now());

      // Update notification after a short delay to simulate server response
      setTimeout(() => {
        setNotificationState({
          open: true,
          message: 'Document saved successfully',
          severity: 'success'
        });
      }, 1000);
    } else if (!hasUnsavedChanges) {
      // Optional: Notify user that no changes need to be saved
      // console.log('No changes to save');
    } else {
      // Show error notification if save cannot be performed
      setNotificationState({
        open: true,
        message: 'Cannot save document at this time',
        severity: 'error'
      });
    }
  };

  // Initialize editor with basic extensions
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: false, // Disable history as it's handled by Yjs
        bulletList: {
          HTMLAttributes: {
            class: 'bullet-list',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'ordered-list',
          },
        },
        listItem: {
          HTMLAttributes: {
            class: 'list-item',
          },
        },
      }),
      Placeholder.configure({
        placeholder: 'Start typing your contract here...'
      }),
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'orderedList', 'bulletList'], // Add list types here
        alignments: ['left', 'center', 'right', 'justify'],
      }),
      Highlight,
      Dropcursor,
      TaskList,
      TaskItem,
      CharacterCount.configure({
        limit: 100000,
      }),


      TabKeyHandler,

      RichTable,
      TextColor,
      TypographyExtension,


      ResizableImage,

      RiskHighlight,

      AiExtension.configure({
        onAiRequest: (text, prompt) => {
          // Optional callback for AI requests
          console.log('AI request:', prompt, 'for text:', text);
        }
      }),
      AiKeyboardShortcut,

      TextSelectionBubble.configure({
        aiButtonRef: aiButtonRef
      }),


      ...collaborationExtensions // Dynamic collaboration extensions
    ],

    content: '', // Will be set after sync

    // Add editor attributes to make sure lists are properly styled
    editorProps: {
      attributes: {
        class: 'focus:outline-none',
      },
    },
  }, [collaborationExtensions]);






  // Expose the editor instance to the parent component using React.useImperativeHandle
  React.useImperativeHandle(ref, () => ({
    getEditor: () => editor,
  }), [editor]);

  // Ensure cursor styles are added to the document head
  useEffect(() => {
    // Create a style element for collaboration cursors
    const styleElement = document.createElement('style');
    styleElement.id = 'collaboration-cursor-styles';
    styleElement.textContent = `
      .collaboration-cursor__caret {
        position: relative;
        border-left: 2px solid;
        border-right: none;
        margin-left: -1px;
        margin-right: -1px;
        pointer-events: none;
        word-break: normal;
        height: 1.2em;
        background-color: transparent;
        z-index: 50;
        animation: blink 1s infinite;
      }
      
      .collaboration-cursor__label {
        position: absolute;
        top: -1.4em;
        left: -1px;
        border-radius: 3px 3px 3px 0;
        padding: 0.1rem 0.3rem;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        color: white;
        user-select: none;
        pointer-events: none;
        z-index: 51;
      }
      
      .collaboration-cursor__selection {
        background-color: inherit;
        pointer-events: none;
        position: relative;
        z-index: 20;
      }
      
      @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0.3; }
        100% { opacity: 1; }
      }
    `;

    // Only add if not already present
    if (!document.getElementById('collaboration-cursor-styles')) {
      document.head.appendChild(styleElement);
    }

    // Cleanup function
    return () => {
      const existingStyles = document.getElementById('collaboration-cursor-styles');
      if (existingStyles) {
        document.head.removeChild(existingStyles);
      }
    };
  }, []);



  // Update lastSavedContent when editor is initialized
  useEffect(() => {
    if (editor) {
      lastSavedContent.current = editor.getHTML();
    }
  }, [editor]);

  // Document changes and autosave
  useEffect(() => {
    if (!editor) return;

    // Add change event listener to detect document changes
    const handleDocumentChange = () => {
      // Check if content has actually changed from last save
      const currentContent = editor.getHTML();
      if (currentContent !== lastSavedContent.current) {
        setHasUnsavedChanges(true);
      } else {
        setHasUnsavedChanges(false);
        return; // Don't set timeout if no real changes
      }

      // Clear existing timeout
      if (autosaveTimeoutRef.current) {
        clearTimeout(autosaveTimeoutRef.current);
      }

      // Set new timeout for autosave after 20 seconds
      autosaveTimeoutRef.current = setTimeout(() => {
        // Only save if there are unsaved changes
        if (hasUnsavedChanges) {
          saveContract();
        }
      }, 20000); // 20 seconds
    };

    editor.on('update', handleDocumentChange);

    return () => {
      editor.off('update', handleDocumentChange);
      if (autosaveTimeoutRef.current) {
        clearTimeout(autosaveTimeoutRef.current);
      }
    };
  }, [editor]); // Remove hasUnsavedChanges from dependencies

  // Handle page visibility changes (user leaving the screen)
  useEffect(() => {
    const handleVisibilityChange = () => {
      // Only save if there are unsaved changes when user leaves the page
      if (document.visibilityState === 'hidden' && hasUnsavedChanges) {
        saveContract();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasUnsavedChanges, saveContract]);

  // Component unmount cleanup
  useEffect(() => {
    return () => {
      // Save any unsaved changes when component unmounts
      if (hasUnsavedChanges && socketRef.current && editor) {
        const content = editor.getHTML();
        socketRef.current.emit('save', { content });
        console.log('Auto-saved on unmount');
      }

      // Clear any pending autosave timeouts
      if (autosaveTimeoutRef.current) {
        clearTimeout(autosaveTimeoutRef.current);
      }
    };
  }, [hasUnsavedChanges, editor]);

  // Update the keyboard shortcut handler in DocumentEditor.jsx

  useEffect(() => {
    // Function to handle keyboard shortcuts
    const handleKeyDown = (e) => {
      // For Mac: Cmd+K, For Windows: Ctrl+K
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        // Only proceed if editor exists
        if (!editor) return;

        // Check if there is a text selection
        const { from, to } = editor.state.selection;
        const hasSelection = from !== to;

        // Only trigger the AI button if text is selected
        if (hasSelection) {
          e.preventDefault();
          console.log('Text is selected, showing AI button');

          // First, manually hide any visible text selection bubbles
          const textSelectionBubbles = document.querySelectorAll('.text-selection-bubble');
          textSelectionBubbles.forEach(bubble => {
            bubble.style.opacity = '0';
            setTimeout(() => {
              bubble.style.display = 'none';
            }, 200);
          });

          // Then, after a short delay to ensure the bubble is hidden
          setTimeout(() => {
            // If we have a ref to the AI button, click it
            if (aiButtonRef.current) {
              aiButtonRef.current.click();
            }
          }, 210);
        } else {
          console.log('No text selected, not showing AI button');
          // Let the default browser action proceed (if any)
        }
      }
    };

    // Add the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor]);




  // Socket.IO and Yjs setup
  useEffect(() => {
    if (!contractId || !currentUser) return;

    // Create Yjs document
    const ydoc = new Y.Doc();
    ydocRef.current = ydoc;

    // Get auth token
    const token = backendClient.getToken();
    if (!token?.access_token) {
      setError('Authentication error: Invalid token');
      return;
    }

    // Socket setup
    const socketProtocol = window.location.protocol === 'https:' ? 'https://' : 'http://';
    const backendUrlWithoutProtocol = backendUrl.replace(/^https?:\/\//, '');
    const socketUrl = `${socketProtocol}${backendUrlWithoutProtocol}`;


    const socket = io(socketUrl, {
      path: '/contract/editor/socket.io',
      auth: {
        token: token.access_token,
        contract_id: contractId,
        color: currentUserData.color
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      transports: ['websocket', 'polling', "flashsocket"],
    });

    // const socket = io(socketUrl, {
    //   path: '/io/socket.io',
    //   auth: {
    //     token: token.access_token,
    //     contract_id: contractId,
    //     color: currentUserData.color
    //   },
    //   reconnection: true,
    //   reconnectionAttempts: 10,
    //   reconnectionDelay: 1000,
    //   reconnectionDelayMax: 10000,
    //   timeout: 20000,
    //   transports: ['polling', 'websocket'],
    //   transportOptions: {
    //     polling: {
    //       extraHeaders: {
    //         'Authorization': `Bearer ${token.access_token}`,
    //       }
    //     }
    //   },
    //   forceNew: true,
    //   withCredentials: true
    // });

    socketRef.current = socket;

    // Create Socket.IO provider for Y.js
    const provider = new SocketIOProvider(socket, contractId, ydoc);
    providerRef.current = provider;

    // Handle connection status
    provider.on('status', ({ status }) => {
      setStatus(status === 'connected' ? 'connected' : 'disconnected');
    });



    // Handle user updates from Socket.IO
    socket.on('user_status', (data) => {
      if (data && Array.isArray(data.users)) {
        setActiveUsers(data.users);

        // Force cleanup of stale cursors whenever user list changes
        if (providerRef.current && providerRef.current.awareness) {
          const currentUserIds = new Set(data.users.map(user => user.id));
          const staleClientIds = [];

          providerRef.current.awareness.getStates().forEach((state, clientId) => {
            if (
              clientId !== providerRef.current.doc.clientID && // Not our cursor
              state.user &&
              state.user.id &&
              !currentUserIds.has(state.user.id) // User not in active list
            ) {
              staleClientIds.push(clientId);
            }
          });

          // Remove stale awareness states
          if (staleClientIds.length > 0) {
            console.log('Removing stale cursor states on user_status update:', staleClientIds);

            if (typeof providerRef.current.removeAwarenessStates === 'function') {
              // Use our custom method if available
              providerRef.current.removeAwarenessStates(staleClientIds, null);
            } else {
              console.warn('removeAwarenessStates is not available on the provider');

              // Manual cleanup implementation
              try {
                // Directly modify the awareness states
                staleClientIds.forEach(clientId => {
                  providerRef.current.awareness.states.delete(clientId);
                });

                // Manually emit update event
                providerRef.current.awareness.emit('update', {
                  added: [],
                  updated: [],
                  removed: staleClientIds
                });

                // Trigger our callbacks
                if (providerRef.current._triggerAwarenessCallbacks) {
                  providerRef.current._triggerAwarenessCallbacks();
                }
              } catch (cleanupErr) {
                console.error('Error during manual awareness cleanup:', cleanupErr);
              }
            }
          }
        }
      }
    });

    // Create user object for collaboration with consistent ID format
    const user = {
      name: currentUserData.name,
      color: currentUserData.color,
      id: String(currentUser.id) // Ensure ID is a string
    };

    // Set initial user data in provider
    provider.awareness.setLocalState({
      user: user,
      selection: null
    });

    // Setup collaboration extensions
    const collabExtensions = [
      Collaboration.configure({
        document: ydoc,
        field: 'content'
      }),
      CustomCollaborationCursor.configure({
        provider: provider,
        user: user,
        editorProps: { activeUsers }
      })
    ];

    setCollaborationExtensions(collabExtensions);

    // Cleanup function
    return () => {
      if (socket && socket.connected) socket.disconnect();
      if (provider) provider.destroy();
      if (ydoc) ydoc.destroy();
    };
  }, [contractId, currentUser, currentUserData]);


  // Add this effect after your other useEffect hooks
  useEffect(() => {
    if (!editor) return;

    const handleSelectionUpdate = () => {
      if (!editor.isActive('resizableImage')) {
        setSelectedImageNode(null);
        return;
      }

      // An image is selected - get its position and node information
      const { state } = editor;
      const { from } = state.selection;
      let pos = from;

      // Find the exact position of the image node
      state.doc.nodesBetween(from, from, (node, nodePos) => {
        if (node.type.name === 'resizableImage') {
          pos = nodePos;
          return false; // Stop iteration
        }
        return true;
      });

      setSelectedImageNode({ node: state.doc.nodeAt(pos), pos });
    };

    // Register event listeners
    editor.on('selectionUpdate', handleSelectionUpdate);
    editor.on('focus', handleSelectionUpdate);

    return () => {
      editor.off('selectionUpdate', handleSelectionUpdate);
      editor.off('focus', handleSelectionUpdate);
    };
  }, [editor]);

  useEffect(() => {
    if (editor && providerRef.current && providerRef.current.awareness) {
      // Handle selection updates from the editor
      const unregisterSelectionUpdate = editor.on('selectionUpdate', ({ editor }) => {
        const { from, to } = editor.state.selection;

        // This is the critical part - awareness state must have user and selection as separate top-level fields
        const currentState = providerRef.current.awareness.getLocalState() || {};
        providerRef.current.awareness.setLocalState({
          // Keep existing state properties
          ...currentState,
          // User info should be a top-level 'user' property
          user: currentState.user || {
            name: currentUserData.name,
            color: currentUserData.color,
            id: String(currentUser.id)
          },
          // Selection must be a top-level property, not nested under user!
          selection: {
            anchor: from,
            head: to
          }
        });
      });

      return () => {
        if (typeof unregisterSelectionUpdate === 'function') {
          unregisterSelectionUpdate();
        }
      };
    }
  }, [editor, currentUser, currentUserData]);

  // Handle initial content after provider is synced
  useEffect(() => {
    if (!editor || !providerRef.current) return;

    // Wait for provider to be synced before setting initial content
    const handleSync = () => {
      // Check if contract content exists and is valid
      if (contract?.content) {
        try {
          const content = contract.content;

          // Set content if document is empty and we have content
          if (editor.isEmpty && content) {
            setTimeout(() => {
              editor.commands.setContent(content);
              setEditorInitialized(true);
            }, 100);
          } else if (editor.isEmpty) {
            editor.commands.setContent(defaultContent);
            setEditorInitialized(true);
          }
        } catch (error) {
          console.error('Error setting content:', error);
          editor.commands.setContent(defaultContent);
          setEditorInitialized(true);
        }
      } else if (editor.isEmpty) {
        editor.commands.setContent(defaultContent);
        setEditorInitialized(true);
      }
    };

    // Add sync event listener
    providerRef.current.on('synced', handleSync);

    // Clean up
    return () => {
      if (providerRef.current) {
        providerRef.current.off('synced', handleSync);
      }
    };
  }, [editor, contract?.content]);

  // Update editor content when contract data changes
  useEffect(() => {
    if (editor && contract?.content && !editorInitialized) {
      // Set the content if it's available
      if (contract.content.trim()) {
        editor.commands.setContent(contract.content);
        setEditorInitialized(true);
      }
    }
  }, [editor, contract?.content, editorInitialized]);

  // Clean up duplicate awareness states
  useEffect(() => {
    if (providerRef.current && providerRef.current.awareness) {
      const cleanupInterval = setInterval(() => {
        try {
          if (typeof providerRef.current.cleanupDuplicateAwarenessStates === 'function') {
            providerRef.current.cleanupDuplicateAwarenessStates();
          }
        } catch (err) {
          console.error('Error in awareness cleanup:', err);
        }
      }, 10000); // Run every 10 seconds

      return () => clearInterval(cleanupInterval);
    }
  }, []);


  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Cmd+S (Mac) or Ctrl+S (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 's') {
        e.preventDefault(); // Prevent the browser's save dialog

        // Show saving notification
        setNotificationState({
          open: true,
          message: 'Saving document...',
          severity: 'info'
        });

        // Trigger save functionality
        if (socketRef.current && editor) {
          const content = editor.getHTML();
          socketRef.current.emit('save', { content });
          setHasUnsavedChanges(false);

          // Show saved notification after a short delay
          setTimeout(() => {
            setNotificationState({
              open: true,
              message: 'Document saved successfully',
              severity: 'success'
            });
          }, 1000);
        }
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor]);




  // Render loading state
  if (loading) {
    return (
      <Box className="editor-loading" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>{t("loading_contract")}</Typography>
      </Box>
    );
  }

  // Render error state
  if (error) {
    return (
      <Box className="editor-error" sx={{ padding: 3, textAlign: 'center' }}>
        <Typography variant="h5">Error</Typography>
        <Typography sx={{ mt: 2 }}>{error}</Typography>
        <BackButton navigate_to={"/contract"} />
      </Box>
    );
  }

  return (
    <Box className="editor-container">
      {/* Toolbar */}
      <Box
        className="toolbar"
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          borderBottom: '1px solid #e0e0e0',
          flexDirection: 'column',
        }}
      >
        {/* Toolbar plugins */}
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          {editor && (
            <ToolbarPlugin
              editor={editor}
              contractTitle={contract?.title || 'Untitled'}
              saveContract={saveContract}
              selectedImageNode={selectedImageNode}
              hasUnsavedChanges={hasUnsavedChanges}
              aiButtonRef={aiButtonRef}
              contractId={contractId}

            />
          )}
        </Box>

        {/* Active users */}
        {activeUsers.length > 1 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              className="collaborators"
              sx={{
                display: 'flex',
                gap: 1.5,
                flexWrap: 'wrap',
                alignItems: 'center'
              }}
            >
              {activeUsers.map((user) => (
                <Tooltip
                  key={user.id}
                  title={`${user.name}${user.id === currentUser?.id ? ' (you)' : ''}`}
                  placement="top"
                  arrow
                >
                  <Chip
                    size="small"
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: user.id === currentUser?.id ? 'primary.main' : user.color,
                          fontWeight: 'bold',
                          boxShadow: user.id === currentUser?.id ? '0 0 0 2px #fff' : 'none'
                        }}
                      >
                        {user.name.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    label={user.name}
                    variant="filled"
                    sx={{
                      borderRadius: 6,
                      backgroundColor: user.id === currentUser?.id ? 'rgba(25, 118, 210, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                      color: user.id === currentUser?.id ? 'primary.main' : 'text.primary',
                      fontWeight: user.id === currentUser?.id ? 500 : 400,
                      '&:hover': {
                        backgroundColor: user.id === currentUser?.id ? 'rgba(25, 118, 210, 0.12)' : 'rgba(0, 0, 0, 0.08)',
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      {/* Editor content */}
      <Box className="editor-inner" sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        {editor ? (
          <EditorContent
            editor={editor}
            className="tiptap-editor"
            style={{ flex: 1 }}
          />
        ) : (
          <Box className="tiptap-editor-loading" sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <CircularProgress size={24} sx={{ mr: 1 }} />
            <Typography>Loading editor...</Typography>
          </Box>
        )}
      </Box>

      {/* Collaboration status */}
      <Box
        className="collab-status"
        sx={{
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 1,
          borderTop: '1px solid #e0e0e0',
          bgcolor: 'background.paper',
          flexShrink: 0
        }}
        data-state={status === 'connected' ? 'online' : 'offline'}
      >
        <Typography variant="caption" color="text.secondary">
          {status === 'connected'
            ? `${activeUsers.length} user${activeUsers.length === 1 ? '' : 's'} online`
            : 'Offline - reconnecting...'}
        </Typography>

        {editor && editor.storage.characterCount && (
          <Typography variant="caption" color="text.secondary">
            {editor.storage.characterCount.characters()} characters
          </Typography>
        )}
      </Box>

      {/* Notification */}
      <Snackbar
        open={notificationState.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notificationState.severity}
          sx={{ width: '100%' }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>





    </Box>
  );
});

export default DocumentEditor;