import { MESSAGE_STATUS, ROLE, languages } from "../../utils/constants";
import * as actions from "../../redux/actions/messageAction";

const getSavedLanguage = () => {
  const languageCodeString = localStorage.getItem("languageCode");
  return languages[languageCodeString] || languages.fr;
};

const initialState = {
  messages: [],
  conversations: [],
  activeConversationId: null,
  currentLanguage: getSavedLanguage(),
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.CLEAR_MESSAGES:
      return { ...state, messages: [] };

    case actions.EDIT_MESSAGE: {
      const { messageId, content } = action.payload;
      return {
        ...state,
        messages: state.messages.map(msg =>
          msg.id === messageId
            ? { ...msg, content, edited: true }
            : msg
        )
      };
    }

    case actions.CHECK_MESSAGE: {
      const messageId = action.payload;
      return {
        ...state,
        messages: state.messages.map(msg =>
          msg.id === messageId
            ? { ...msg, checked: !msg.checked }
            : msg
        )
      };
    }

    case actions.SET_MESSAGES:
      return { ...state, messages: action.payload };

    case actions.USER_SEND_MESSAGE: {
      const newMessage = {
        ...action.payload.message,
        conversationId: state.activeConversationId || ""
      };
      return {
        ...state,
        messages: [...state.messages, newMessage]
      };
    }

    case actions.SYSTEM_SEND_MESSAGE: {
      const message = action.payload;
      const existingIndex = state.messages.findIndex(msg => msg.id === message.id);

      if (existingIndex > -1) {
        const updatedMessages = [...state.messages];
        updatedMessages[existingIndex] = message;
        return { ...state, messages: updatedMessages };
      }

      return { ...state, messages: [...state.messages, message] };
    }

    case actions.SET_LANGUAGE:
      return { ...state, currentLanguage: action.payload };

    default:
      return state;
  }
};

export default messageReducer;

