export const pricingData = {
    monthly: {
        individual: {
            price: '590',
            equivalent: '590 MAD',
            description: 'Résiliable en un clic',
            features: [
                'Accès à la base documentaire complète',
                'Recherche juridique avancée',
                'Génération de documents simples',
                'Traduction dans 5 langues',
                'Veille juridique (3 thématiques)',
                '1 utilisateur'
            ]
        },
        professional: {
            price: '1190',
            equivalent: '1190 MAD',
            description: 'Résiliable en un clic',
            features: [
                'Tout ce qui est inclus dans Individuel',
                'Rédaction assistée avancée',
                'Traduction dans 15+ langues',
                'Veille juridique illimitée',
                'Espace collaboratif sécurisé',
                'Jusqu\'à 5 utilisateurs'
            ]
        },
        enterprise: {
            price: 'Personnalisée',
            equivalent: 'Personnalisée',
            description: 'Contactez-nous pour une offre sur mesure',
            features: [
                'Tout ce qui est inclus dans Professionnel',
                'Intégration de vos données propriétaires',
                'API pour intégration avec vos outils',
                'Formation et support personnalisés',
                'Tableaux de bord personnalisés',
                'Utilisateurs à définir'
            ]
        }
    },
    annual: {
        individual: {
            price: '490',
            equivalent: '490 MAD',
            description: 'Soit 5.880 MAD par an (économisez 20%)',
            features: [
                'Accès à la base documentaire complète',
                'Recherche juridique avancée',
                'Génération de documents simples',
                'Traduction dans 5 langues',
                'Veille juridique (3 thématiques)',
                '1 utilisateur'
            ]
        },
        professional: {
            price: '990',
            equivalent: '990 MAD',
            description: 'Soit 11.880 MAD par an (économisez 20%)',
            features: [
                'Tout ce qui est inclus dans Individuel',
                'Rédaction assistée avancée',
                'Traduction dans 15+ langues',
                'Veille juridique illimitée',
                'Espace collaboratif sécurisé',
                'Jusqu\'à 5 utilisateurs'
            ]
        },
        enterprise: {
            price: 'Personnalisée',
            equivalent: 'Personnalisée',
            description: 'Contactez-nous pour une offre sur mesure',
            features: [
                'Tout ce qui est inclus dans Professionnel',
                'Intégration de vos données propriétaires',
                'API pour intégration avec vos outils',
                'Formation et support personnalisés',
                'Tableaux de bord personnalisés',
                'Utilisateurs à définir'
            ]
        }
    }
};