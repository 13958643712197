// src/redux/actions/signatureAction.js
import { backendClient } from "../../api/backend";
import {
    SIGNATURE_REQUEST_LOADING,
    SIGNATURE_REQUEST_SUCCESS,
    SIGNATURE_REQUEST_FAILURE,
    SIGNATURE_LIST_LOADING,
    SIGNATURE_LIST_SUCCESS,
    SIGNATURE_LIST_FAILURE,
    SIGNATURE_CANCEL_LOADING,
    SIGNATURE_CANCEL_SUCCESS,
    SIGNATURE_CANCEL_FAILURE,
    SIGNATURE_REMIND_LOADING,
    SIGNATURE_REMIND_SUCCESS,
    SIGNATURE_REMIND_FAILURE,
    SIGNATURE_ADD_LOADING,
    SIGNATURE_ADD_SUCCESS,
    SIGNATURE_ADD_FAILURE,
    SIGNATURE_DELETE_LOADING,
    SIGNATURE_DELETE_SUCCESS,
    SIGNATURE_DELETE_FAILURE,
    UPDATE_CONTRACT_STATUS_SUCCESS
} from "./types";

// Add these action types to your types.js file:
// export const SIGNATURE_ADD_LOADING = 'SIGNATURE_ADD_LOADING';
// export const SIGNATURE_ADD_SUCCESS = 'SIGNATURE_ADD_SUCCESS';
// export const SIGNATURE_ADD_FAILURE = 'SIGNATURE_ADD_FAILURE';
// export const SIGNATURE_DELETE_LOADING = 'SIGNATURE_DELETE_LOADING';
// export const SIGNATURE_DELETE_SUCCESS = 'SIGNATURE_DELETE_SUCCESS';
// export const SIGNATURE_DELETE_FAILURE = 'SIGNATURE_DELETE_FAILURE';

// Action Creators
const signatureRequestLoading = () => ({
    type: SIGNATURE_REQUEST_LOADING
});

const signatureRequestSuccess = (response) => ({
    type: SIGNATURE_REQUEST_SUCCESS,
    payload: response
});

const signatureRequestFailure = (error) => ({
    type: SIGNATURE_REQUEST_FAILURE,
    payload: error
});

const signatureListLoading = () => ({
    type: SIGNATURE_LIST_LOADING
});

const signatureListSuccess = (signatures) => ({
    type: SIGNATURE_LIST_SUCCESS,
    payload: signatures
});

const signatureListFailure = (error) => ({
    type: SIGNATURE_LIST_FAILURE,
    payload: error
});

const signatureCancelLoading = () => ({
    type: SIGNATURE_CANCEL_LOADING
});

const signatureCancelSuccess = (message) => ({
    type: SIGNATURE_CANCEL_SUCCESS,
    payload: message
});

const signatureCancelFailure = (error) => ({
    type: SIGNATURE_CANCEL_FAILURE,
    payload: error
});

const signatureRemindLoading = () => ({
    type: SIGNATURE_REMIND_LOADING
});

const signatureRemindSuccess = (message) => ({
    type: SIGNATURE_REMIND_SUCCESS,
    payload: message
});

const signatureRemindFailure = (error) => ({
    type: SIGNATURE_REMIND_FAILURE,
    payload: error
});

const signatureAddLoading = () => ({
    type: SIGNATURE_ADD_LOADING
});

const signatureAddSuccess = (signatory) => ({
    type: SIGNATURE_ADD_SUCCESS,
    payload: signatory
});

const signatureAddFailure = (error) => ({
    type: SIGNATURE_ADD_FAILURE,
    payload: error
});

const signatureDeleteLoading = () => ({
    type: SIGNATURE_DELETE_LOADING
});

const signatureDeleteSuccess = (email) => ({
    type: SIGNATURE_DELETE_SUCCESS,
    payload: email
});

const signatureDeleteFailure = (error) => ({
    type: SIGNATURE_DELETE_FAILURE,
    payload: error
});

// Thunk Actions

/**
 * Add a potential signatory to a contract
 */
export const addSignatory = (contractId, email, firstName, lastName) => async (dispatch) => {
    dispatch(signatureAddLoading());

    try {
        const response = await backendClient.post(`api/contract/signature/add-signatory`, {
            contract_id: contractId,
            email: email,
            first_name: firstName || "",
            last_name: lastName || ""
        });

        const result = await response.json();
        console.log('responseXX', result)


        dispatch(signatureAddSuccess(result));
        return result;
    } catch (error) {
        console.error("Error adding signatory:", error);
        dispatch(signatureAddFailure(error.message || "Failed to add signatory"));
        throw error;
    }
};

/**
 * Delete a potential signatory from a contract
 */
export const deleteSignatory = (contractId, email) => async (dispatch) => {
    dispatch(signatureDeleteLoading());

    try {
        const response = await backendClient.delete(`api/contract/signature/${contractId}/signatory/${email}`);

        dispatch(signatureDeleteSuccess(email));
        return response;
    } catch (error) {
        console.error("Error deleting signatory:", error);
        dispatch(signatureDeleteFailure(error.message || "Failed to delete signatory"));
        throw error;
    }
};

/**
 * Create a signature request for a contract
 */
export const createSignatureRequest = (
    contractId,
    signers,
    callbackUrl,
    expirationDays = 7,
    reminderDays = 3
) => async (dispatch) => {
    dispatch(signatureRequestLoading());

    try {
        // Format signers data for the API, if any
        const formattedSigners = signers ? signers.map(signer => ({
            email: signer.email,
            first_name: signer.first_name || "",
            last_name: signer.last_name || ""
        })) : [];

        const response = await backendClient.post(`api/contract/signature/create`, {
            contract_id: contractId,
            signers: formattedSigners,
            callback_url: callbackUrl,
            expiration_days: expirationDays,
            reminder_days: reminderDays
        });

        // Update the contract status to pending signature
        dispatch({
            type: UPDATE_CONTRACT_STATUS_SUCCESS,
            payload: "en attente de signature"
        });

        dispatch(signatureRequestSuccess(response));
        return response;
    } catch (error) {
        console.error("Error creating signature request:", error);
        dispatch(signatureRequestFailure(error.message || "Failed to create signature request"));
        throw error;
    }
};

/**
 * Fetch the status of signatures for a contract
 */
export const getSignatureStatus = (contractId) => async (dispatch) => {
    dispatch(signatureListLoading());

    try {
        const response = await backendClient.get(`api/contract/signature/${contractId}/status`);

        const result = await response.json();
        dispatch(signatureListSuccess(result));
        return result
    } catch (error) {
        console.error("Error fetching signature status:", error);
        dispatch(signatureListFailure(error.message || "Failed to fetch signature status"));
        throw error;
    }
};

/**
 * Cancel a signature request
 */
export const cancelSignatureRequest = (contractId) => async (dispatch) => {
    dispatch(signatureCancelLoading());

    try {
        const response = await backendClient.post(`api/contract/signature/${contractId}/cancel`);

        // Update the contract status back to draft
        dispatch({
            type: UPDATE_CONTRACT_STATUS_SUCCESS,
            payload: "brouillon"
        });

        dispatch(signatureCancelSuccess(response.message || "Signature request cancelled"));
        return response;
    } catch (error) {
        console.error("Error cancelling signature request:", error);
        dispatch(signatureCancelFailure(error.message || "Failed to cancel signature request"));
        throw error;
    }
};

/**
 * Send a reminder to signers
 */
export const sendSignatureReminder = (contractId, message) => async (dispatch) => {
    dispatch(signatureRemindLoading());

    try {
        const response = await backendClient.post(`api/contract/signature/${contractId}/remind`, {
            message: message
        });

        dispatch(signatureRemindSuccess(response.message || "Reminder sent successfully"));
        return response;
    } catch (error) {
        console.error("Error sending signature reminder:", error);
        dispatch(signatureRemindFailure(error.message || "Failed to send reminder"));
        throw error;
    }
};