import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  Alert,
  Fade
} from '@mui/material'
import { Visibility, VisibilityOff, Email, Lock, Business, Phone, Person } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { clearMessageError, signUp } from '../../redux/actions/authAction'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import CustomButton from '../../components/CustomButton'
import { colors } from '../../utils/colors'
import { domainConfig, isMobile } from '../../utils/utils'

const SignupScreen = () => {
  const dispatch = useDispatch()
  const { loading, error, user } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const [loadingButton, setLoadingButton] = useState(false)

  const [userType, setUserType] = useState('')
  const [last_name, setLastName] = useState('')
  const [first_name, setFirstName] = useState('')
  const [organization_name, setOrganizationName] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const isCustomDomain = Object.values(domainConfig)
    .some(config => window.location.hostname.includes(config.domainName));

  // Error states for validation
  const [userTypeError, setUserTypeError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [organizationError, setOrganizationError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false)
  const isMobileView = isMobile()

  const handleSignup = async () => {
    setLoadingButton(true)
    // Validate required fields
    setLastNameError(last_name.trim() === '')
    setFirstNameError(first_name.trim() === '')

    // let localOrgName
    // if (isCustomDomain) {
    //   setOrganizationError("")
    //   const customDomainObject = Object.values(domainConfig)
    //     .find(config => window.location.hostname.includes(config.domainName));

    //   localOrgName = customDomainObject.organizationName
    //   setOrganizationName(prev => customDomainObject.organizationName);
    // } else {
    //   setOrganizationError(organization_name.trim() === '')
    //   setUserTypeError(userType === '');
    //   localOrgName = organization_name
    // }

    // // Extract the domain from the email
    // const emailDomain = email.split('@')[1]

    // // Check if email domain is in domainConfig - this means it's from a recognized organization
    // const isApprovedDomain = !!domainConfig[emailDomain.toLowerCase()]

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    if (!isValidEmail) {
      setEmailError('Veuillez entrer une adresse e-mail valide.')
      setLoadingButton(false)
      return
    }

    // // Reject if not from an approved domain
    // if (!isApprovedDomain && !isCustomDomain) {
    //   setEmailError("L'inscription n'est disponible que pour les membres d'organisations approuvées avec un abonnement actif.")
    //   setLoadingButton(false)
    //   return
    // }

    // Validate phone number (simple format, can be improved based on country)
    const isValidPhone = /^[0-9]{10}$/.test(phone_number)
    setPhoneError(!isValidPhone)

    // Validate password (at least 6 characters, contains at least one special character)
    const isValidPassword =
      password.length >= 6 && /[!@#$%^&*(),.+?":{}|<>]/.test(password)
    setPasswordError(!isValidPassword)

    // Check if all validations pass
    if (
      last_name.trim() !== '' &&
      first_name.trim() !== '' &&
      isValidEmail &&
      isValidPhone &&
      isValidPassword &&
      password === confirmPassword &&
      acceptTerms
    ) {
      const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
      const domain = email.toLowerCase().split('@')[1]
      let newDomain

      if (true) {
        if (domainConfig[domain]) {
          newDomain = domainConfig[domain]['domainName']

          // if user not in correct path for their organization
          if (!window.location.hostname.includes(newDomain)) {
            const currentPath =
              window.location.pathname +
              window.location.search +
              window.location.hash

            const newUrl = `https://${newDomain}.juridia.ma${currentPath}`
            window.location.href = newUrl
            window.alert(
              `Nous vous redirigeons pour que vous vous connectiez depuis votre espace personnalisé ${newUrl}.`
            )
          } else {
            // Proceed with signup
            dispatch(
              signUp(
                last_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
                first_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
                organization_name
                  .toLowerCase()
                  .replace(/^\w/, c => c.toUpperCase()),
                phone_number.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
                email.toLowerCase(),
                password,
                userType,
                navigate,
                true
              )
            )
          }
        } else {
          // Show error message if not from an approved organization
          // setEmailError(
          //   "L'inscription n'est disponible que pour les membres d'organisations approuvées avec un abonnement actif."
          // )
          // setLoadingButton(false)
          // return
          // Proceed with signup
          dispatch(
            signUp(
              last_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
              first_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
              organization_name
                .toLowerCase()
                .replace(/^\w/, c => c.toUpperCase()),
              phone_number.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
              email.toLowerCase(),
              password,
              userType,
              navigate,
              true
            )
          )
        }
      } else {
        console.log('in development environment')
        dispatch(
          signUp(
            last_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
            first_name.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
            organization_name
              .toLowerCase()
              .replace(/^\w/, c => c.toUpperCase()),
            phone_number.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
            email.toLowerCase(),
            password,
            userType,
            navigate
          )
        )
      }

      setLoadingButton(false)
    } else if (password !== confirmPassword) {
      alert('Les mots de passe ne correspondent pas')
      setLoadingButton(false)
    } else {
      setLoadingButton(false)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSignup();
    }
  };

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user, navigate])

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError())
  }, [dispatch])

  return (
    <Grid
      container
      sx={{
        height: '94vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.bg_color,
        padding: isMobileView ? '0' : '0px',
        margin: isMobileView ? '0' : '0px',
      }}
    >
      <Paper
        elevation={8}
        sx={{
          borderRadius: isMobileView ? '12px' : '24px',
          width: isMobileView ? '100%' : '90%',
          maxWidth: '1200px',
          overflow: 'hidden',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Grid container sx={{ height: '100%' }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: 'linear-gradient(135deg, #000000 0%, #2C2C2C 100%)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              padding: 4,
              minHeight: isMobileView ? '200px' : '600px'
            }}
          >
            {/* Background pattern */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                backgroundImage: 'radial-gradient(circle, #FFFFFF 1px, transparent 1px)',
                backgroundSize: '20px 20px'
              }}
            />

            <Box
              sx={{
                width: isMobileView ? '100px' : '160px',
                height: isMobileView ? '100px' : '160px',
                backgroundColor: 'white',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                zIndex: 2,
                mb: 3
              }}
            >
              <Logo
                style={{
                  width: isMobileView ? '80px' : '140px',
                  height: isMobileView ? '80px' : '140px'
                }}
              />
            </Box>

            <Typography
              variant={isMobileView ? 'h5' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
                zIndex: 2
              }}
            >
              Rejoignez Juridia
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                textAlign: 'center',
                maxWidth: '80%',
                zIndex: 2
              }}
            >
              Créez votre compte pour accéder à notre plateforme juridique sécurisée.
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: '#FFFFFF',
              padding: 0
            }}
          >
            <Container
              maxWidth="sm"
              sx={{
                py: 3,
                height: '100%',
                overflowY: 'auto'
              }}
            >
              <Typography
                variant={isMobileView ? 'h5' : 'h4'}
                sx={{
                  mb: 1,
                  fontWeight: 'bold',
                  color: '#333333'
                }}
              >
                S'inscrire
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: '#666666'
                }}
              >
                Complétez le formulaire pour créer votre compte
              </Typography>

              {error && (
                <Fade in={!!error}>
                  <Alert
                    severity="error"
                    sx={{
                      mb: 2,
                      borderRadius: '8px'
                    }}
                  >
                    {error}
                  </Alert>
                </Fade>
              )}

              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  {/* First name and Last name fields */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Nom"
                      value={last_name}
                      onChange={e => setLastName(e.target.value)}
                      error={lastNameError}
                      helperText={lastNameError ? 'Ce champ est requis.' : ''}
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person color="action" />
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Prénom"
                      value={first_name}
                      onChange={e => setFirstName(e.target.value)}
                      error={firstNameError}
                      helperText={firstNameError ? 'Ce champ est requis.' : ''}
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person color="action" />
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>

                  {/* Organization and Phone fields */}
                  {!isCustomDomain && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Organisation"
                        value={organization_name}
                        onChange={e => setOrganizationName(e.target.value)}
                        error={organizationError}
                        helperText={organizationError ? "Nom de l'organisation invalide." : ''}
                        required
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Business color="action" />
                            </InputAdornment>
                          )
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          }
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isCustomDomain ? 12 : 6}>
                    <TextField
                      fullWidth
                      label="Téléphone"
                      value={phone_number}
                      onChange={e => setPhoneNumber(e.target.value)}
                      error={phoneError}
                      helperText={phoneError ? 'Numéro de téléphone invalide (10 chiffres).' : ''}
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Phone color="action" />
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>

                  {/* User Type field */}
                  {!isCustomDomain && (
                    <Grid item xs={12}>
                      <FormControl
                        required
                        fullWidth
                        error={userTypeError}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          }
                        }}
                      >
                        <InputLabel id="user-type-label">Statut d'utilisateur</InputLabel>
                        <Select
                          labelId="user-type-label"
                          value={userType}
                          onChange={(e) => setUserType(e.target.value)}
                          label="Statut d'utilisateur"
                        >
                          <MenuItem value="étudiant">Étudiant</MenuItem>
                          <MenuItem value="professionnel">Professionnel</MenuItem>
                          <MenuItem value="particulier">Particulier</MenuItem>
                        </Select>
                        {userTypeError && (
                          <Typography sx={{ fontSize: '12px', ml: 1.5, mt: 0.5 }} color="error">
                            Veuillez sélectionner une catégorie.
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Email field */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                        setEmailError(false)
                      }}
                      error={!!emailError}
                      helperText={emailError}
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email color="action" />
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>

                  {/* Password fields */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Mot de passe"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      error={passwordError}
                      helperText={
                        passwordError
                          ? 'Le mot de passe doit comporter au moins 6 caractères et contenir un caractère spécial.'
                          : ''
                      }
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock color="action" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmer le mot de passe"
                      type={showPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      error={password !== confirmPassword && confirmPassword !== ''}
                      helperText={
                        password !== confirmPassword && confirmPassword !== ''
                          ? 'Les mots de passe ne correspondent pas.'
                          : ''
                      }
                      required
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock color="action" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        }
                      }}
                    />
                  </Grid>

                  {/* Terms and Conditions */}
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={acceptTerms}
                        onChange={e => setAcceptTerms(e.target.checked)}
                        required
                        sx={{
                          color: colors.primary,
                          '&.Mui-checked': {
                            color: colors.primary,
                          },
                        }}
                      />
                      <Typography sx={{ color: 'red', marginRight: '5px' }}>*</Typography>
                      <Typography variant="body2">
                        J'accepte les{" "}
                        <Typography
                          component="a"
                          variant="body2"
                          href="https://juridia.ma/condition-generales-de-vente/"
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: colors.primary,
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          conditions générales de vente
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <CustomButton
                      text="S'inscrire"
                      outlined={false}
                      loading={loading || loadingButton}
                      onClick={handleSignup}
                      disabled={loading || loadingButton || !acceptTerms}
                      sx={{
                        width: '100%',
                        py: 1.5,
                        borderRadius: '10px',
                        boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 'bold'
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" color="text.secondary" sx={{ px: 1 }}>
                  OU
                </Typography>
              </Divider>

              <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  Vous avez déjà un compte ? {' '}
                  <Typography
                    component="span"
                    variant="body2"
                    onClick={() => navigate('/login')}
                    sx={{
                      color: colors.primary,
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Connectez-vous
                  </Typography>
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default SignupScreen